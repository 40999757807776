import React, { Fragment, useState } from "react";
import MoreActionComponent from './moreActionComponent';
import NeedHelpComponent from './needHelpComponent';
import BackButtonComponent from './backButtonComponent';
import { getBackPathUrl } from '../../helpers';
import { integratedBusiness } from '../../../subRequests/helpers/helpers';

export default function TopButtonsComponent(props){
  const { eventData, handleSetAlert, handleUpdateEvent, business, user } = props
  const [ backPath, setBackPath ] = useState(getBackPathUrl(user) || '')

  return(
    <Fragment>
      <div className="px-8 lg:px-0 flex flex-row justify-between gap-2 flex-wrap">
        <BackButtonComponent user={user} backPath={backPath}/>
        <div className="flex gap-4">
          {(eventData?.show_more_action_button || (!integratedBusiness(business) && eventData?.canEdit)) &&
            <MoreActionComponent 
              user={user}
              eventData={eventData}
              handleSetAlert={handleSetAlert}
              handleUpdateEvent={handleUpdateEvent}
              business={business}
              backPath={backPath}
            />
          }
          <NeedHelpComponent />
        </div>
      </div>
    </Fragment>
  )
}