import React, { Fragment } from "react";
import UsersListCardComponent from '../usersListCardComponent';
import ApproveButtonComponent from '../approveButtonComponent';
import ResendInviteButtonComponent from '../resendInviteButtonComponent';
import SendInviteButtonComponent from '../sendInviteButtonComponent';

export default function UserCardComponent(props){
  const { sub, i, eventData, redirectToUrl, handleUpdateEvent, handleAlert, cardType } = props

  return(
    <Fragment>
      <div className="lg:px-5 lg:px-4 lg:py-3 lg:border-2 lg:border-gray-50 lg:rounded-2xl lg:shadow mb-3 flex justify-between items-center">
        <UsersListCardComponent 
          sub={sub} 
          redirectToUrl={redirectToUrl} 
          handleAlert={handleAlert} 
          subTimestamp={
            cardType == 'Accepted' ?
              (sub?.status === 'confirm' ?
                sub?.approved_at || ''
              :
                sub?.accepted_at || ''
              )
            :
            cardType == 'Approved' ?
              sub?.approved_at || ''
            :
            cardType == 'Declined' ?
              sub?.declined_at || ''
            :
            ''
          }
          cardType={cardType}
          eventData={eventData}
        />
        {cardType == 'Accepted' ?
          <Fragment>
            {(!eventData?.cancelled && (eventData?.canApprove || (eventData?.manager_gate_event && eventData?.mrg_reopened) || ['Approved', 'No Show'].includes(eventData?.status))) ? 

              <ApproveButtonComponent 
                sub={sub} 
                eventData={eventData} 
                handleUpdateEvent={handleUpdateEvent} 
                handleAlert={handleAlert}
              />
            :
              <div></div>
            }
          </Fragment>
          :
          cardType == 'No Responses'?
            <div className="flex flex-col gap-2 shrink-0">
              {sub?.show_resend_invite_button &&
                <div className="flex justify-end">
                  <ResendInviteButtonComponent sub={sub} eventData={eventData} updateEventData={handleUpdateEvent} handleAlert={handleAlert} eventPreview={false}/>
                </div>
              }
              {sub?.invitation_sent && sub?.no_of_invites && sub?.no_of_invites !== '' &&
                <p className="flex items-center justify-end text-sm text-gray-500">
                  {sub?.no_of_invites}
                </p>
              }
            </div>
          :
          ['not_invited_users', 'unavilable'].includes(cardType)?
            <Fragment>
              {cardType == 'not_invited_users' || (cardType == 'unavilable' && sub?.show_send_invite_button) ?
                <SendInviteButtonComponent sub={sub} eventData={eventData} handleUpdateEvent={handleUpdateEvent} handleAlert={handleAlert} type={cardType} />
                :
                <div></div>
              }
            </Fragment>
          :
          <div></div>
        }
      </div>
    </Fragment>
  )
}