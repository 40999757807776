import React, { Fragment, useEffect, useState } from "react";
import { hideItems, handleSaveFilters, handleSaveMyRequestFilters } from '../../../../helpers/helpers'
import { statusListByPageType } from '../../../../helpers/supportHelpers'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

export default function MyRequestStatusFilterComponent(props) {
  const { selectedStatus, setMyRequestStatusFilter, business, user, type, managerCount, pendingCount, acceptedCount, approvedCount, reopenCount, availableSelectedStatus, availablePendingCount, availableAcceptCount, availableDeclineCount } = props
  const [ statusValues, setStatusValues ] = useState(selectedStatus || [])
  const [ avaialbleStatusValues, setAvailableStatusValues ] = useState(availableSelectedStatus || [])
  const statusList = statusListByPageType(type, business)

  const checkTypeName = (status) => {
    return statusList.filter((item) => status.includes(item.value)).map((item) => item.label).join(', ')
  }

  const checkTypeAndShowCount = (status, type) => {
    const statusCounts = {
      pending_mgr: managerCount,
      pending: type == 'sent' ? pendingCount : availablePendingCount,
      accepted: acceptedCount,
      confirm: approvedCount,
      reopen: reopenCount,
      accept: availableAcceptCount,
      decline: availableDeclineCount
    };
    const count = statusCounts[status];
    return count > 0 ? `(${count})` : '';
  }

  const handleChangeStatus = (e, status, type) => {
    var __FOUND = getStatusValuesByType(type).includes(status)
    if(e.target.checked){
      if(!__FOUND){
        let newStatus = [...getStatusValuesByType(type), status]
        setStatusValuesByType(type, newStatus)
      }
    }else{
      if(__FOUND){
        let newStatus = getStatusValuesByType(type).filter((tag) => tag !== status)  
        setStatusValuesByType(type, newStatus)
      }      
    }
  }

  const processSetSelectedStatus = (type) => {
    let selectedStatusByType = getStatusValuesByType(type)
    setMyRequestStatusFilter(selectedStatusByType, type)
    let statusState = (selectedStatusByType.length === statusList.length ? 'all_selected' : selectedStatusByType?.length > 0 ? 'some_selected' : 'no_selected')
    let data = {
      selectedStatus: selectedStatusByType,
      statusState: statusState
    }
    handleSaveMyRequestFilters(data, true, user?.id, type)
    hideItems('statusspecial')
  }

  const cancelStatusFilter = () => {
    if(!$('.statusspecial_items').hasClass("hidden")){
      processOpenLocationsFilter()
    }
    hideItems('statusspecial')
  }
  
  const handleSelectAll = () => {
    if(checkSelectAll()){
      setStatusValuesByType(type, [])
    }else{
      let all_value = statusList.map((item) => item?.value)
      setStatusValuesByType(type, all_value)
    }
  }

  const checkSelectAll = () => {
    let all_value = statusList.map((item) => item?.value)
    return getStatusValuesByType(type).length === all_value.length
  }

  const processOpenLocationsFilter = () => {
    switch(type){
      case 'sent':
        setStatusValues(selectedStatus)
        break;
      case 'available':
        setAvailableStatusValues(availableSelectedStatus)
        break;
    }
  }

  if(type == 'sent'){
    useEffect(() => {
      if(statusValues?.length > 0 && statusList?.length !== statusValues?.length){
        $('.statusspecial_items_button').addClass('filter-apply-border')
      }else {
        $('.statusspecial_items_button').removeClass('filter-apply-border')
      }
    }, [statusValues])
  }

  if(type == 'available'){
    useEffect(() => {
      if(availableSelectedStatus?.length > 0 && statusList?.length !== availableSelectedStatus?.length){
        $('.statusspecial_items_button').addClass('filter-apply-border')
      }else {
        $('.statusspecial_items_button').removeClass('filter-apply-border')
      }
    }, [availableSelectedStatus])
  }

  const getBackGroundColour = (status) => {
    const statusCounts = {
      pending_mgr: 'status-mgr',
      pending: 'status-open',
      accepted: 'status-pending',
      confirm: 'status-approved',
      reopen: 'status-open',
      accept: 'status-pending',
      decline: 'status-canceleld'
    };
    return statusCounts[status];
  }

  const getStatusValuesByType = (type) => {
    switch(type){
      case 'sent':
        return statusValues
      case 'available':
        return avaialbleStatusValues
    }
  }

  const setStatusValuesByType = (type, data) => {
    switch(type){
      case 'sent':
        setStatusValues(data)
        break;
      case 'available':
        setAvailableStatusValues(data)
        break;
    }
  }

  const getFilterNamesByType = (type) => {
    return(
      <Fragment>
        {getStatusValuesByType(type).length === 0 ? 
          (type == 'available') ? "Select a response" : "Select a status"
        : 
          (getStatusValuesByType(type).length === statusList?.length ?
            (type == 'available') ? "All Responses" : "All Statuses"
          :
            <p className = "text-gray-900 truncate">{checkTypeName(getStatusValuesByType(type))}</p>
          )
        }
      </Fragment>
    )
  }

  return(
    <Fragment>
      <div className="relative filters_box"> 
        <div className="hidden my-status-special-div" onClick={() => processOpenLocationsFilter()}></div>
        <Menu as="div" className="relative block text-left">
          <div>
            <Menu.Button className="flex justify-center w-full rounded-xl pl-4 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 statusspecial_items_button border-2 border-transparent need-border font-medium">
              {getFilterNamesByType(type)}
              <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="origin-top-right z-30 right-0 w-full rounded-2xl bg-white ring-2 ring-gray-500 focus:outline-none mt-2 hidden statusspecial_items filter_items absolute">
              <div className="py-2">
                <div className='flex items-center justify-between px-4 py-2 mb-1'>
                  <label className="inline-flex items-center text-sm">
                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2" checked = {checkSelectAll()} onChange = {(e) => handleSelectAll(e)}/>
                    Select all
                  </label>
                  <a onClick={() => cancelStatusFilter()} className='custom-red text-sm cursor-pointer'>Cancel</a>
                </div>
                <div>
                  {statusList.map((item, i) => 
                    <Menu.Item key = {i}>
                      {({ active }) => (
                        <label className="flex items-center px-4 text-gray-900 text-sm mb-2">
                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 cursor-pointer mr-2" onChange={(e) => handleChangeStatus(e, item?.value, type)} value={item?.value} checked={(getStatusValuesByType(type)).includes(item?.value)}/>
                          <div className={`flex items-center gap-2 cursor-pointer ${getBackGroundColour(item?.value)} px-3 py-0.5 rounded-2xl`}>
                            {`${item?.label} ${checkTypeAndShowCount(item?.value, type)}`}
                          </div>
                        </label>
                      )}
                    </Menu.Item>
                  )}
                </div>
              </div>
              <div className="flex items-center p-2 border-t">
                <div className="flex justify-center items-center w-full px-3 gap-3">
                  <div className=''>
                    <a className='bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={(e) => processSetSelectedStatus(type)}>Apply</a>
                  </div>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}