import React, { Fragment } from "react";

const MessageComponent = (props) => {
  const { message } = props
  return (
    <Fragment>
      <div className="px-8 mt-5 h-56 flex justify-center items-center">
        <div className="bg-white shadow-md px-6 py-4 flex justify-center items-center text-center rounded-2xl text-sm w-full font-semibold">
          {message}
        </div>
      </div>
    </Fragment>
  )
}

export default function DisabledSubboardComponent(props) {
  const { business } = props
  
  return (
    <Fragment>
      {business?.banner_enabled ?
        <MessageComponent message={business?.banner_text}/>
      :
        null
      }
    </Fragment>
  )
}

