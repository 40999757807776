import React, { useEffect } from "react";
import { useIntercom } from 'react-use-intercom';

const IntercomManager = ({ isLoggedIn, user }) => {
  const { boot, shutdown } = useIntercom();
  
  useEffect(() => {
    if (isLoggedIn) {
      let params = {
        email: user?.email,
        createdAt: user?.created_at_unix,
        userId: user?.id,
      }
      boot(params);
    } else {
      shutdown();
    }
  }, [isLoggedIn, user, boot, shutdown]);

  return null;
};

export default IntercomManager;