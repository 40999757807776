import React, { Fragment, useEffect, useState } from "react";
import { Redirect } from 'react-router-dom';

export default function ViewHistoryComponent(props) {
  const { eventUserId, eventUserName } = props;
  const [ redirectToHistory, setRedirectToHistory ] = useState(false);
  const [ historyPath, setHistoryPath ] = useState('');

  useEffect(() => {
    if(eventUserId){
      setHistoryPath(`/analytics?page=instructor_history&id=${eventUserId}&name=${eventUserName}`)
    }
  }, [eventUserId])

  const handleViewHistory = (e) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(historyPath, '_blank');
    } else {
      setRedirectToHistory(true);
    }
  }
  
  return(
    <Fragment>
      {redirectToHistory &&
        <Redirect push to={`${historyPath}`} />
      }
      <button className="flex truncate justify-center items-center custom-blue-200 text-base font-semibold underline" onClick={(e) => handleViewHistory(e)}>
        View History
      </button>
    </Fragment>
  )
}