import React, { Component, Fragment } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Dialog, Menu, Transition } from '@headlessui/react'
import MessengerSidebar from './messengerSidebar'
import { getMessages, getMessageTypeIcone, getMessageTypeIconeWithSize, capitalizeFirstLetter, getResponce, getSenderUsers, getSenderUsersPosts, searchPost, getResponceDetail, approveUser, createGroup } from './helpers'
import Cookies from 'js-cookie';
import { getSudomain, setTitle } from '../../helpers'
import { ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { debounce } from "lodash";
import Pagination from "react-js-pagination";
import InfinitScroll from 'react-infinite-scroll-component'
import ReactTooltip from "react-tooltip";
import CustomPopup from './chatComponents/assets/customPopup'

export default class AllPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      alert_message: '',
      alert_type: '',
      show: '',
      // filterTypes: this.props.user?.role_display !== 'Fitness Instructor' && this.props.permissions?.business_messages?.write ?
      //   (this.props.path === 'messenger' &&
      //     [
      //       {
      //         label: 'Recent Activity',
      //         value: 'recent_activity'
      //       }, 
      //       {
      //         label: 'Unread',
      //         value: 'unread'
      //       }, 
      //       {
      //         label: 'Read',
      //         value: 'read'
      //       },
      //       {
      //         label: 'Acknowledgement',
      //         value: 'acknowledgement'
      //       },
      //       {
      //         label: 'Due, expiration or start date',
      //         value: 'due_expiration_start'
      //       },
      //       {
      //         label: 'Comment',
      //         value: 'latest_comment'
      //       }
      //     ]
      //   )
      //   ||
      //   (this.props.path === 'compliance' &&
      //     [
      //       // {
      //       //   label: 'Latest posted date',
      //       //   value: 'latest'
      //       // }, 
      //       // {
      //       //   label: 'Oldest posted date',
      //       //   value: 'oldest'
      //       // },
      //       {
      //         label: 'Unread',
      //         value: 'unread'
      //       }, 
      //       {
      //         label: 'Read',
      //         value: 'read'
      //       },
      //       {
      //         label: 'Acknowledgement',
      //         value: 'acknowledgement'
      //       },
      //       {
      //         label: 'Due Date',
      //         value: 'due_date'
      //       }, 
      //       // {
      //       //   label: 'Acknowledged',
      //       //   value: 'acknowledged'
      //       // },
      //       // {
      //       //   label: 'Pending Acknowledgement',
      //       //   value: 'unacknowledged'
      //       // }
      //       {
      //         label: 'Comment',
      //         value: 'latest_comment'
      //       }
      //     ] 
      //   )
      //   || 
      //   (this.props.path === 'announcement' &&
      //     [
      //       // {
      //       //   label: 'Latest posted date',
      //       //   value: 'latest'
      //       // },
      //       // {
      //       //   label: 'Oldest posted date',
      //       //   value: 'oldest'
      //       // },
      //       {
      //         label: 'Unread',
      //         value: 'unread'
      //       }, 
      //       {
      //         label: 'Read',
      //         value: 'read'
      //       },
      //       {
      //         label: 'Expiration Date',
      //         value: 'expiration_date'
      //       },
      //       {
      //         label: 'Comment',
      //         value: 'latest_comment'
      //       }
      //     ] 
      //   )
      //   || 
      //   (this.props.path === 'event' &&
      //     [
      //       // {
      //       //   label: 'Latest posted date',
      //       //   value: 'latest'
      //       // },
      //       // {
      //       //   label: 'Oldest posted date',
      //       //   value: 'oldest'
      //       // },
      //       // {
      //       //   label: 'End soon',
      //       //   value: 'end_soon'
      //       // },
      //       // {
      //       //   label: 'Expired',
      //       //   value: 'expired'
      //       // },
      //       {
      //         label: 'Unread',
      //         value: 'unread'
      //       }, 
      //       {
      //         label: 'Read',
      //         value: 'read'
      //       },
      //       {
      //         label: 'RSVP',
      //         value: 'rsvp'
      //       },
      //       {
      //         label: 'Start Date',
      //         value: 'start_date'
      //       },
      //       {
      //         label: 'Comment',
      //         value: 'latest_comment'
      //       }
      //       // {
      //       //   label: 'Comment',
      //       //   value: 'latest_comment'
      //       // },
      //       // {
      //       //   label: 'Yes',
      //       //   value: 'yes'
      //       // },
      //       // {
      //       //   label: 'No',
      //       //   value: 'no'
      //       // },
      //       // {
      //       //   label: 'Maybe',
      //       //   value: 'may_be'
      //       // },
      //     ] 
      //   )
      //   || 
      //   (this.props.path === 'permanent_class' &&
      //     [
      //       {
      //         label: 'Latest posted date',
      //         value: 'latest'
      //       },
      //       {
      //         label: 'Oldest posted date',
      //         value: 'oldest'
      //       },
      //       {
      //         label: 'Unread',
      //         value: 'unread'
      //       }, 
      //       {
      //         label: 'Read',
      //         value: 'read'
      //       },
      //       {
      //         label: 'Open',
      //         value: 'open'
      //       },
      //       {
      //         label: 'Pending',
      //         value: 'pending'
      //       },
      //       {
      //         label: 'Approved',
      //         value: 'approved'
      //       },
      //     ] 
      //   )
      // :
      //   (this.props.path === 'messenger' &&
      //     [
      //       {
      //         label: 'Unread',
      //         value: 'unread'
      //       }, 
      //       {
      //         label: 'Read',
      //         value: 'read'
      //       },
      //       {
      //         label: 'Pending Acknowledgement',
      //         value: 'unacknowledged'
      //       }
      //       // {
      //       //   label: 'Acknowledgement',
      //       //   value: 'acknowledgement'
      //       // },
      //       // {
      //       //   label: 'Due, expiration or start date',
      //       //   value: 'due_expiration_start'
      //       // },
      //       // {
      //       //   label: 'Comment',
      //       //   value: 'latest_comment'
      //       // }
      //     ] 
      //   )
      //   || 
      //   (this.props.path === 'compliance' &&
      //     [
      //       {
      //         label: 'Unread',
      //         value: 'unread'
      //       }, 
      //       {
      //         label: 'Read',
      //         value: 'read'
      //       },
      //       // {
      //       //   label: 'Acknowledgement',
      //       //   value: 'acknowledgement'
      //       // },
      //       // {
      //       //   label: 'Due Date',
      //       //   value: 'due_date'
      //       // },
      //       {
      //         label: 'Pending Acknowledgement',
      //         value: 'unacknowledged'
      //       },
      //       // {
      //       //   label: 'Comment',
      //       //   value: 'latest_comment'
      //       // }
      //     ] 
      //   )
      //   || 
      //   (this.props.path === 'announcement' &&
      //     [
      //       {
      //         label: 'Unread',
      //         value: 'unread'
      //       }, 
      //       {
      //         label: 'Read',
      //         value: 'read'
      //       },
      //       // {
      //       //   label: 'Expiration Date',
      //       //   value: 'expiration_date'
      //       // },
      //       // {
      //       //   label: 'Comment',
      //       //   value: 'latest_comment'
      //       // }
      //     ] 
      //   )
      //   || 
      //   (this.props.path === 'event' &&
      //     [
      //       // {
      //       //   label: 'Latest posted date',
      //       //   value: 'latest'
      //       // },
      //       // {
      //       //   label: 'Oldest posted date',
      //       //   value: 'oldest'
      //       // },
      //       {
      //         label: 'Unread',
      //         value: 'unread'
      //       }, 
      //       {
      //         label: 'Read',
      //         value: 'read'
      //       },
      //       // {
      //       //   label: 'End soon',
      //       //   value: 'end_soon'
      //       // },
      //       {
      //         label: 'RSVP',
      //         value: 'rsvp'
      //       },
      //       // {
      //       //   label: 'Start Date',
      //       //   value: 'start_date'
      //       // },
      //       {
      //         label: 'Comment',
      //         value: 'latest_comment'
      //       }
      //     ] 
      //   )
      //   || 
      //   (this.props.path === 'permanent_class' &&
      //     [
      //       {
      //         label: 'Latest posted date',
      //         value: 'latest'
      //       },
      //       {
      //         label: 'Oldest posted date',
      //         value: 'oldest'
      //       },
      //       {
      //         label: 'Unread',
      //         value: 'unread'
      //       }, 
      //       {
      //         label: 'Read',
      //         value: 'read'
      //       },
      //       {
      //         label: 'Open',
      //         value: 'open'
      //       },
      //       {
      //         label: 'Pending',
      //         value: 'pending'
      //       },
      //       {
      //         label: 'Approved',
      //         value: 'approved'
      //       },
      //     ] 
      //   ),
      filterTypes: this.props?.filters,
      selectedFilter: '',// this.props.user?.role_display !== 'Fitness Instructor' && this.props.permissions?.business_messages?.write ? this.props.path === 'messenger' ? 'latest' : 'latest' : 'latest',
      showMetricsModal: false,
      selectedMessage: {},
      searchData: '',
      selectMatricsButton: '',
      showUserList: [],
      message_response: {},
      users: [],
      selectedPeople: [],
      searchSenderValue: '',
      messages: this.props.messages,
      loadedData: true,
      sortName: '',
      sortFlag: 'asc',
      sortReverse: false,
      redirectToShowPage: false,
      redirectToShowPagePath: '',
      activePage: 1,
      pageSize: 20,
      total: this.props.total,
      filter_messages_count: this.props.sideBarCounts?.filter_messages_count,
      loadMore: this.props.messages?.length === this.props.sideBarCounts?.filter_messages_count ? false : true,
      loadedPostOnScroll: true,
      totalForUser: this.props.totalForUser,
      tableLoaded: true,
      permanentClassDaysList: [{label: "M", value: "Monday"},{label: "T", value: "Tuesday"},{label: "W", value: "Wednesday"},{label: "Th", value: "Thursday"},{label: "F", value: "Friday"},{label: "S", value: "Saturday"},{label: "Su", value: "Sunday"}],
      metricsActivePage: 1,
      metricsPageSize: 15,
      showUserListAllCount: 0,
      metricsDataLoaded: true,
      showApprovedConfirmPopup: false,
      approvedUser: {},
      showCreateDmPopup: false,
      createDmUserName: '',
      createDmUserId: '',
      redirectAtGroupPage: '',
      createDmBtnDisabled: false,
      redirectAfterCreateGroup: false,
    }

    this.fetchNextUsers = this.fetchNextUsers.bind(this)
    this.fetchNextMetricsUser = this.fetchNextMetricsUser.bind(this)
  }

  componentDidMount(){
    this.getUserForSearch('')
    if(this.props.loaded){
      this.scrollCommentsListToDown()
      this.setState({
        selectedFilter: this.props.sorting,
        selectedPeople: this.props.sendersFilter == '' ? [] : this.props.sendersFilter
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.loaded){
      this.scrollableDivToTop()
      this.setState({
        messages: nextProps.messages,
        filterTypes: nextProps.filters,
        total: nextProps.total,
        filter_messages_count: nextProps.sideBarCounts?.filter_messages_count,
        loadMore: nextProps.messages?.length === nextProps.sideBarCounts?.filter_messages_count ? false : true,
        totalForUser: nextProps.totalForUser,
        loadedData: nextProps.pageDataLoaded,
        tableLoaded: nextProps.pageDataLoaded,
        selectedFilter: nextProps.sorting,
        selectedPeople: nextProps.sendersFilter == '' ? [] : nextProps.sendersFilter,
        activePage: 1,
        sortName: '',
        sortFlag: 'asc',
        sortReverse: false,
      })
    }
  }

  scrollCommentsListToDown = () => {
    // $(document).load().scrollTop(0);
    var div = document.getElementById("commentsList");
      if(div !== null){
        // div.scrollTop = div.scrollHeight - div.clientHeight;
        $(document).scrollTop(0,0);
      }
  }

  scrollableDivToTop = () => {
    var div = document.getElementById("scrollableDiv");
    if(div !== null){
      // div.scrollTop = div.scrollHeight - div.clientHeight;
      $(div).scrollTop(0,0);
    }
  }

  getUserForSearch = (search) => {
    getSenderUsers(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), search).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
          users: result?.users,
				})
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  performFiltersOnPosts = (filter, search, sortName, sortFlag, userIds, page) => {
    this.setState({loadedData: false, loadMore: false, tableLoaded: false})
    getMessages(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.props.path === 'archive' ? true : false, filter, ['messenger', 'inbox', 'archive'].includes(this.props.path)  ? '' : this.props.path, search, sortName, sortFlag, userIds, page).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
        // this.props.getSideBarCounts(result)
				this.setState({
          messages: result?.messages,
          total: result?.total_count,
          totalForUser: result?.from_count,
          all_message: result?.messages,
          sideBarCounts: result,
          filter_messages_count: result?.filter_messages_count,
          loadMore: result?.messages?.length === result?.filter_messages_count ? false : true,
          activePage: parseInt(result?.page_no),
          loadedData: true,
          tableLoaded: true
				})
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
					loadedData: true,
          tableLoaded: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }
 
  handleFilter = (e, filter) => {
    this.setState({selectedFilter: filter})
    // this.props.getInboxMesseges(false, filter, this.props.path === 'messenger' ? '' : this.props.path)
    this.performFiltersOnPosts(filter, this.state.searchData, this.state.sortName, this.state.sortReverse, this.state.selectedPeople, 1)
  }

  filterName = () => {
    let filterTypes = this.state.filterTypes
    let filter = this.state.selectedFilter
    let name = ''
    for(let i = 0; i < filterTypes?.length; i++){
      if(filter === filterTypes[i]?.value){
        name = this.chekLocationNameCount(filterTypes[i]?.label)
      }
    }
    return name
  }

  chekLocationNameCount = (name) => {
		let shortName = []
		for(let i = 0; i < name?.length; i++){
			if(i < 14){
				shortName.push(name[i])
			}else{
				shortName.push('...')
				break
			}
		}
	
		return shortName.join('')
	}

  openMetricsModal = (e, message) => {
    e.preventDefault();
    this.setState({metricsDataLoaded: false, selectedMessage: message})
    getResponce(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), message?.message_id).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
          message_response: result?.response,
          metricsDataLoaded: true
				})
        this.performOpenMatrics(message, result?.response)
			}else{
				this.setState({
          metricsDataLoaded: true,
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
          selectedMessage: {}
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
    
  }

  closeMetricsModal = (e) => {
		this.setState({
			showMetricsModal: false,
      selectedMessage: {},
      selectMatricsButton: '',
      showUserList: [],
      showUserListAllCount: 0,
      metricsActivePage: 1
		})
	}

  showHeadingText = (type) => {
    switch (type) {
      case 'messenger':
        return "All"
        break;
      case 'inbox':
        return "All"
        break;
      case 'compliance':
        return "All Compliance"
        break;
      case 'announcement':
        return "All Announcements"
        break;
      case 'event':
        return "All Events"
        break;
      case 'permanent_class_opening':
        return "All Permanent Classes"
        break;
      case 'archive':
        return "Archives"
        break;
    }
  }

  handleSearch = (e) => {
		this.setState({
			searchData: e.target.value
		})
		
		this.performSearch(e.target.value)
	}

  performSearch = debounce(val => {
		// this.props.serachData(val)
    // let searchResult = searchPost(val, this.props.all_message)
    // this.setState({
    //   messages: searchResult,
    // })
    this.performFiltersOnPosts(this.state.selectedFilter, val, this.state.sortName, this.state.sortReverse, this.state.selectedPeople, 1)
	}, 800);

  performOpenMatrics = (message, message_response) => {
    let select = ''
    let userList = []
    let userListCount = 0
    if(message?.message_type === 'compliance'){
      if(message?.show_response){
        select = 'acknowledged'
        userList = message_response?.acknowledged
        userListCount = message_response?.acknowledged_count
      } else {
        select = 'read'
        userList = message_response?.read
        userListCount = message_response?.read_count
      }
    }else if(message?.message_type === 'event'){
      select = 'yes'
      userList = message_response?.yes
      userListCount = message_response?.yes_count
    }else if(message?.message_type === 'announcement'){
      select = 'read'
      userList = message_response?.read
      userListCount = message_response?.read_count
    }else if(message?.message_type === 'permanent_class_opening'){
      select = 'yes'
      userList = message_response?.yes
      userListCount = message_response?.yes_count
    }else{
      select = 'send'
      userList = message_response?.receivers
      userListCount = message_response?.receivers_count
    }
    this.setState({
      selectedMessage: message,
      showMetricsModal: true,
      selectMatricsButton: select,
      showUserList: userList,
      showUserListAllCount: userListCount,
      metricsActivePage: 1
    })
  }

  handleSelectSender = (e) => {
    let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedPeople.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newPeoples = [...this.state.selectedPeople, val]
        this.setState(state => ({ selectedPeople: newPeoples}));
        this.getSenderFilterPost(newPeoples)
      }
    }else{
      if(__FOUND){
        const { selectedPeople } = this.state;
        let newPeoples = selectedPeople.filter((tag, index) => tag !== val)  
        this.setState({
          selectedPeople: newPeoples      
        }); 
        if(newPeoples?.length > 0){
          this.getSenderFilterPost(newPeoples)
        }else{
          this.getSenderFilterPost(newPeoples)
          // this.setState({messages: this.props.all_message})
        }
      }      
    }
  }

  getSenderFilterPost = (selectedPeople) => {
    this.performFiltersOnPosts(this.state.selectedFilter, this.state.searchData, this.state.sortName, this.state.sortReverse, selectedPeople, 1)
    // this.setState({loadedData: false})
    // getSenderUsersPosts(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), selectedPeople, this.props.path === 'messenger' ? '' : this.props.path).then(      
		// 	response => response.json()
		// )
		// .then(result => {
		// 	if(result.status === 200){
		// 		this.setState({
    //       messages: result?.messages,
    //       loadedData: true
		// 		})
		// 	}else{
		// 		this.setState({
		// 			alert_message: result.error,
		// 			alert_type: 'danger', 
		// 			show: true,           
		// 		})
		// 		this.setState({show:true},()=>{
    //       window.setTimeout(()=>{
    //         this.setState({show:false})
    //       },3000)
    //     });
		// 	}
		// })
  }

  searchSenderUsers = (e) => {
    this.setState({searchSenderValue: e.target.value})
    this.serachSenderData(e.target.value)
  }

  serachSenderData = debounce(val => {
		this.getUserForSearch(val)
	}, 800);

  selectAllSender = () => {
    let users = this.state.users
    let selectedPeople = this.state.selectedPeople
    let users_ids = users.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(selectedPeople, users_ids)
    return equal
  }

  handleSelectAllSenders = (e) => {
    let users = this.state.users
    let selected_people = this.state.selectedPeople
    var result = users.map(function(a) {return a.id;});
    if(e.target.checked){
      for(var i=0; i<users?.length;i++){ 
				if(!selected_people.includes(users[i]['id'])){
          selected_people.push(users[i]['id'])  
        }  
      }
      this.setState({
        selectedPeople: selected_people
      })
      this.getSenderFilterPost(selected_people)
      
    }else{
			let new_peoples = selected_people.filter(item => !result.includes(item))    
      this.setState({
        selectedPeople: new_peoples
      })
      if(new_peoples?.length === 0){
        // this.setState({messages: this.props.all_message})
        this.getSenderFilterPost(new_peoples)
      }else{
        this.getSenderFilterPost(new_peoples)
      }
    }
  }

  setClearSenderFilter = (e) => {
    let new_peoples = []    
    this.setState({
      selectedPeople: new_peoples
    })
    if(new_peoples?.length === 0){
      // this.setState({messages: this.props.all_message})
      this.getSenderFilterPost(new_peoples)
    }else{
      this.getSenderFilterPost(new_peoples)
    }
  }

  handleClearFilterSorting = (e) => {
    let filter = this.props.user?.role_display !== 'Fitness Instructor' && this.props.permissions?.business_messages?.write ? (this.props.path === 'messenger' || this.props.path === 'inbox') ? 'latest' : 'latest' : 'latest'
    this.setState({selectedFilter: filter})
    this.performFiltersOnPosts(filter, this.state.searchData, this.state.sortName, this.state.sortReverse, this.state.selectedPeople, 1)
  }

  countNameCharectors = (name, nameLength) => {
		let len = parseInt(nameLength)
    let shortName = []
		for(let i = 0; i < name?.length; i++){
			if(i < len){
				shortName.push(name[i])
			}else{
				shortName.push('...')
				break
			}
		}
		return shortName.join('')
	}

  sortData = (e, param, type) => {
    e.preventDefault()
    let sort_desc = false
    if(type === 'asc'){
      sort_desc = false
    }else{
      let sortRev =  this.state.sortReverse
      if(sortRev === true){
        sort_desc = false
      }
      else{
        sort_desc = true
      }
    }
    this.setState({
      sortName: param,
      sortFlag: sort_desc ? 'asc' : 'desc',
      sortReverse: sort_desc
    })
    this.performFiltersOnPosts(this.state.selectedFilter, this.state.searchData, param, sort_desc, this.state.selectedPeople, 1)
  }

  handlePostTagComment = (e, type) => {
    localStorage.setItem("commentTag", type);
    this.setState({
      redirectToShowPagePath: `${location.pathname}/show-post/${this.state.selectedMessage?.id}`,
      redirectToShowPage: true
    })
  }

  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber});
    this.performFiltersOnPosts(this.state.selectedFilter, this.state.searchData, this.state.sortName, this.state.sortReverse, this.state.selectedPeople, pageNumber)
  }

  fetchNextUsers(){
    if(this.state.loadMore){
      let activePage = this.state.activePage + 1
      this.setState({activePage: activePage, loadedPostOnScroll: false})
      this.performPagination(this.state.selectedFilter, this.state.searchData, this.state.sortName, this.state.sortReverse, this.state.selectedPeople, activePage)
    }
  }

  performPagination = (filter, search, sortName, sortFlag, userIds, page) => {
    // this.setState({loadMore: false})
    getMessages(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.props.path === 'archive' ? true : false, filter, (this.props.path === 'messenger' || this.props.path === 'inbox') ? '' : this.props.path, search, sortName, sortFlag, userIds, page).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
        let messages = this.state.messages.concat(result?.messages)
				this.setState({
          messages: messages,
          total: result?.total_count,
          totalForUser: result?.from_count,
          all_message: result?.messages,
          filter_messages_count: result?.filter_messages_count,
          loadMore: (messages?.length === result?.filter_messages_count || messages?.length > result?.filter_messages_count) ? false : true,
          loadedPostOnScroll: true
				})
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
					loadedData: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

  clickOnPostStrip = (e, message) => {
    let target = $(e.target)
    let matricsTdTarget = document.getElementById("matricsTd")
    if(matricsTdTarget !== null){
      if(target.parents('div#matricsTd').length || matricsTdTarget == e.target){
        e.preventDefault();
      }else{
        this.setState({
          redirectToShowPagePath: `${location.pathname}/show-post/${message?.id}`,
          redirectToShowPage: true
        })
      }
    }else{
      this.setState({
        redirectToShowPagePath: `${location.pathname}/show-post/${message?.id}`,
        redirectToShowPage: true
      })
    }
  }

  handleClearSearch = (val) => {
    this.setState({searchData: val}) 
    this.performSearch(val)
  }

  showDaysAbbrivation = (days) => {
    const { permanentClassDaysList } = this.state
    let totalDays = permanentClassDaysList.filter((dayHash) =>  days.includes(dayHash?.value) ).map((dayHash) => dayHash?.label)
    return totalDays.join(' | ')
  }

  fetchNextMetricsUser () {
    const { selectedMessage, selectMatricsButton, metricsPageSize } = this.state
    if (this.state.showUserList?.length < this.state.showUserListAllCount){
      let activePage = this.state.metricsActivePage + 1
      this.fetchMatricsNextData(selectedMessage?.message_id, selectMatricsButton, activePage, metricsPageSize)
    }
  }

  fetchMatricsNextData = (message_id, type, activePage, pageSize) => {
    getResponceDetail(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), message_id, type, activePage, pageSize).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        if (this.state.selectMatricsButton === result?.response?.type){
          this.setState({
            showUserList: this.state.showUserList.concat(result?.response?.data),
            metricsActivePage: activePage
          })
        }
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger', 
          show: true,           
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  scrollMetricsListToTop = () => {
    var div = document.getElementsByClassName("matrics-div-infinitescroll");
    window.setTimeout(()=> {
      if(div?.length > 0){
        div[0].scrollTop = 0;
      }
    }, 2);
  }

  handleApproveUser = (user) => {
    const { showUserList, selectedMessage } = this.state
    if (selectedMessage?.approved_user_id !== "" && user?.id !== parseInt(selectedMessage?.approved_user_id)){
      this.setState({
        showApprovedConfirmPopup: true,
        approvedUser: user
      })
      return
    }
    
    this.handleProcessApproveUser(user?.id, user?.permanent_class_status)
  }

  handleProcessApproveUser = (user_id, currentStatus) => {
    const { showUserList, selectedMessage, messages } = this.state

    for (let i = 0; i < showUserList?.length; i++){
      if (showUserList[i]?.id === user_id){
        if(currentStatus === ""){
          showUserList[i] = {...showUserList[i], permanent_class_status: "approved" }
        }else if(currentStatus === "approved"){
          showUserList[i] = {...showUserList[i], permanent_class_status: "" }
        }
      }
      else{
        if (showUserList[i]?.permanent_class_status === "approved"){
          showUserList[i] = {...showUserList[i], permanent_class_status: "" }
        }
      }
    }
    this.setState({showUserList: showUserList})
    approveUser(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), user_id, selectedMessage?.message_id, currentStatus === "" ? "approved" : "").then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        for (let i = 0; i < messages?.length; i++){
          if (messages[i]?.message_id === selectedMessage?.message_id){
            messages[i] = {...messages[i], approved_user_id: result?.approved_recipient_id, status_web: result?.message_status }
          }
        }
        this.setState({
          alert_message: result?.message,
          alert_type: 'success', 
          show: true,           
          messages: messages,
          selectedMessage: {...this.state.selectedMessage, status_web: result?.message_status, approved_user_id: result?.approved_recipient_id },
          showApprovedConfirmPopup: false,
          approvedUser: {}
        })
        
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
        this.fetchMetricsData(selectedMessage)
      }else{
        this.setState({
          alert_message: result?.error,
          alert_type: 'danger', 
          show: true,           
          showApprovedConfirmPopup: false,
          approvedUser: {}
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })

  }

  fetchMetricsData = (message) => {
    this.setState({metricsDataLoaded: false})
    getResponce(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), message?.message_id).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
          message_response: result?.response,
          metricsDataLoaded: true
				})
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,
          metricsDataLoaded: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  closeCustomPopup = () => {
    this.setState({
      showApprovedConfirmPopup: false,
      showCreateDmPopup: false,
      createDmUserName: '',
      createDmUserId: '',
    })
  }

  handleCreateDmPopup = (name, id) => {
    this.setState({
      createDmUserName: name,
      createDmUserId: id,
      showCreateDmPopup: true
    })
  }

  handleProcessCreateDM = (name, id) => {
    this.setState({createDmBtnDisabled: true})
    
    if(!id || id == '' || id == 0) return
    let receivers = []
    receivers.push(id)
    const formData = new FormData()

    formData.append('group_data[message]', '');
    formData.append('group_data[type]', 'direct_message');
    formData.append('group_data[receivers]', receivers);
    formData.append('group_data[is_private]', true);
    
    createGroup(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), formData).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
          redirectAtGroupPage: `/messenger/chat-room/${result?.id}`,
          alert_message: result?.message,
          alert_type: 'success',
          show: false,
				})
        this.setState({createDmBtnDisabled: true},()=>{
          window.setTimeout(()=>{
            this.setState({createDmBtnDisabled: false, redirectAfterCreateGroup: true})
          },100)
        });
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,
          createDmBtnDisabled: false
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

  render() {
    const { alert_message, alert_type, show, selectedFilter, filterTypes, showMetricsModal, selectedMessage, searchData, selectMatricsButton, showUserList, message_response, users, selectedPeople, searchSenderValue, messages, loadedData, sortName, sortFlag, sortReverse, redirectToShowPage, redirectToShowPagePath, activePage, pageSize, total, filter_messages_count, loadedPostOnScroll, loadMore, totalForUser, tableLoaded, metricsDataLoaded, showApprovedConfirmPopup, approvedUser, showCreateDmPopup, createDmUserName, createDmUserId, redirectAtGroupPage, redirectAfterCreateGroup, createDmBtnDisabled  } = this.state
    const { loadingMessage, getInboxMesseges, path, serachData, user, all_message, permissions, loaded } = this.props
    
    return (
      <React.Fragment>
        {redirectToShowPage &&
          <Redirect push to={`${redirectToShowPagePath}`} />
        }
        {redirectAfterCreateGroup &&
          <Redirect push to={`${redirectAtGroupPage}`} />
        }
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
				{!loaded && 
					<div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
						<p className="mb-1 text-center px-5">{loadingMessage}</p>
						<img className = "w-9" src="/assets/business/loading.gif" />
					</div>
				}
        {loaded && 
          <div className='w-full'>    
            <div className='max-w-8xl mx-auto px-6 lg:px-8 w-full pt-8'>
              <div className='flex items-center mb-4'>
                {/* <div className='w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center relative'>
                  {getMessageTypeIconeWithSize(path, '5')}
                </div> */}
                <div className='text-dark-blue md:text-4xl text-2xl ml-2 font-semibold'>{`${this.showHeadingText(path)}`}</div>
              </div>
              {total === 0 ?
                <React.Fragment>
                  <div className='mt-4 flex justify-between flex-wrap sm:mt-0'>
                    <div className='bg-white rounded-2xl'>
                      <div className='border-gray-300 rounded-2xl py-1.5 px-3.5'>
                        <div className={`flex items-center ${path === 'announcement' ? 'w-260' : 'w-60'}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                          </svg>
                          <input type="text" className="ml-1.5 focus:outline-none w-full" placeholder={`Search in ${(path === 'messenger' || path === 'inbox') ? 'all' : path} posts`} value={searchData} onChange={(e) => this.handleSearch(e)}/>
                          {searchData !== '' &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-900 hover:text-gray-400" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleClearSearch('')}>
                              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                          }
                        </div>
                      </div>
                    </div>
                    {path !== 'archive' &&
                      <div className="relative inline-block text-left">
                        <Menu>
                          <div className="w-64">
                            <Menu.Button type="button" className="justify-between inline-flex w-full rounded-2xl border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
                              <div className='flex'>
                                <div>Filter by:</div>  
                                <div className='ml-1'>{this.filterName() === '' ? this.chekLocationNameCount('Select filter') : this.filterName()}</div>
                              </div>
                              <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                              </svg>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="origin-top-right absolute z-10 right-0 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                              <div className="pt-1 px-4" role="none">
                                <div className='justify-end flex border-b '>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a className="cursor-pointer sky-500 justify-end py-2 font-medium d-inline-block" onClick={(e) => this.handleClearFilterSorting(e)}>
                                        Clear Filter
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                                {filterTypes.map((filter, i) => 
                                  <Menu.Item  key={i}>
                                    {({ active }) => (
                                      <a className="flex cursor-pointer text-dark-blue justify-between py-3 text-sm border-b font-medium" role="menuitem" tabIndex="-1" id={`menu-item-${i}`} onClick={(e) => this.handleFilter(e, filter?.value)}>
                                        <div>{filter?.label}</div>
                                          {selectedFilter === filter?.value &&
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-dark-blue h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                              <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                            </svg>
                                          }
                                      </a>
                                    )}
                                  </Menu.Item>
                                )} 
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    }
                  </div>

                  <div className='mt-4 flex flex-col'>
                    <div id='commentsList' className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className='relative msg-table-height msg-table-min-height rounded-2xl' id="scrollableDiv" style={{ overflow: "auto" }}>
                          <InfinitScroll
                            className="rounded-2xl msg-table-height msg-table-min-height"
                            dataLength = {messages?.length}
                            next = {this.fetchNextUsers}
                            scrollableTarget="scrollableDiv"
                            hasMore = {true}
                          >
                            <table className="border-separate min-w-full divide-y divide-gray-300 shadow ring-1 ring-black ring-opacity-5 rounded-2xl relative border-spacing-0">
                              <thead className="bg-white">
                                <tr>
                                  <th scope="col" className="border-b bg-white py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 sm:pl-6 w-80">
                                    <div className='flex gap-3 items-center'>
                                      <span className='cursor-pointer' onClick={(e) => this.sortData(e, 'topic', 'desc')}>Subject</span> 
                                      {sortName === 'topic' ?
                                        <React.Fragment>
                                          {sortReverse &&
                                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'topic', 'asc')}>
                                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                            </svg>
                                          }
                                          {!sortReverse &&
                                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'topic', 'desc')}>
                                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                            </svg>
                                          }
                                        </React.Fragment>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'topic', 'desc')}>
                                          <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                        </svg>
                                      }
                                    </div>
                                  </th>
                                  <th id='senderList' scope="col" className="bg-white border-b px-3 py-3.5 text-left text-xs font-medium text-gray-500">
                                    {(permissions.business_messages?.write && user?.role_display !== "Fitness Instructor" && (path === 'messenger' || path === 'inbox')) ? 
                                      <div className='flex items-center'>
                                        <div className='flex gap-3 items-center'>
                                          <span className='cursor-pointer' onClick={(e) => this.sortData(e, 'sender', 'desc')}>From</span> 
                                          {sortName === 'sender' ?
                                            <React.Fragment>
                                              {sortReverse &&
                                                <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'sender', 'asc')}>
                                                  <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                                </svg>
                                              }
                                              {!sortReverse &&
                                                <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'sender', 'desc')}>
                                                  <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                                </svg>
                                              }
                                            </React.Fragment>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'sender', 'desc')}>
                                              <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                            </svg>
                                          }
                                        </div>
                                        <Menu as="div" className='relative'>
                                          <Menu.Button className='flex items-center border-gray-300 mr-8 -my-4 p-2 rounded-lg'>
                                            <div className='item-center ml-2'>
                                              <svg xmlns="http://www.w3.org/2000/svg" className="hidden text-gray-400 h-4 w-4 hover:text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                              </svg>
                                              <ChevronDownIcon className="ml-auto text-gray-500 hover:text-gray-400 h-5 w-5" aria-hidden="true" />
                                            </div>
                                          </Menu.Button>
                                          <Transition
                                            as={Fragment}

                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95">
                                            <Menu.Items className="origin-top-right top-7 z-10 absolute right-0 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none font-medium">
                                              <div className=''>
                                                <input type='text' placeholder='Search sender' className='h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-8 py-2 bg-white text-sm focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500' name="searchSenderValue" value={searchSenderValue} onChange={(e) => this.searchSenderUsers(e)}/>
                                                <button className="absolute top-2.5 right-2.5">
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                                  </svg>
                                                </button>
                                              </div>
                                              {users?.length > 0 &&
                                                <div className="flex justify-start items-center p-2 border-b">
                                                  <label className="inline-flex items-center text-gray-400 text-sm gap-1">
                                                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.selectAllSender()} onChange = {(e) => this.handleSelectAllSenders(e)}/>
                                                    Select all/Clear all
                                                  </label>
                                                  <a className='sky-500 cursor-pointer hidden' onClick={(e) => this.setClearSenderFilter(e)}>Clear all</a>
                                                </div>
                                              }
                                              <div className="py-3 px-3 max-h-60 overflow-y-auto">
                                                <div>
                                                  <div className='border-b mb-1'>
                                                    <label className="flex items-center text-gray-900 text-xs mb-1">
                                                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" name="selectedPeople" checked = {selectedPeople.includes(user?.id)} value={user?.id} onChange = {(e) => this.handleSelectSender(e)}/>
                                                      {user?.image !== '' ?
                                                        <div className="relative w-6 h-6">
                                                          <img src={`${user?.image}`} className='rounded-full' />
                                                        </div>
                                                        :
                                                        <div className='w-6 h-6 rounded-full bg-gray-500 flex items-center justify-center relative'>
                                                          <div className='text-white text-xs'>{user?.name_image}</div>
                                                        </div>
                                                      }
                                                        <div className='pl-1 text-xs'>{`${user?.full_name} (me)`}</div>
                                                    </label>
                                                  </div>
                                                  {users.map((user, i) => 
                                                    <div key = {i}>
                                                      <label className="flex items-center text-gray-900 text-sm mb-2">
                                                        <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" name="selectedPeople" checked = {selectedPeople.includes(user?.id)} value={user?.id} onChange = {(e) => this.handleSelectSender(e)}/>
                                                        {user?.image !== '' ?
                                                          <div className="relative w-6 h-6">
                                                            <img src={`${user?.image}`} className='rounded-full' />
                                                          </div>
                                                          :
                                                          <div className='w-6 h-6 min-w-24 rounded-full bg-gray-500 flex items-center justify-center relative'>
                                                            <div className='text-white text-xs'>{user?.name_image}</div>
                                                          </div>
                                                        }
                                                          <div className='pl-1 text-xs'>{user?.full_name}</div>
                                                      </label>
                                                    </div>
                                                  )}
                                                  {users?.length === 0 &&
                                                    <div className='text-gray-400 text-center'>No user found</div>
                                                  }
                                                </div>
                                              </div>
                                            </Menu.Items>
                                          </Transition>
                                        </Menu>
                                      </div>
                                    :
                                    <div className='flex gap-3 items-center'>
                                      <span className='cursor-pointer' onClick={(e) => this.sortData(e, 'sender', 'desc')}>From</span> 
                                      {sortName === 'sender' ?
                                        <React.Fragment>
                                          {sortReverse &&
                                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'sender', 'asc')}>
                                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                            </svg>
                                          }
                                          {!sortReverse &&
                                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'sender', 'desc')}>
                                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                            </svg>
                                          }
                                        </React.Fragment>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'sender', 'desc')}>
                                          <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                        </svg>
                                      }
                                    </div>
                                    }
                                  </th>
                                  {user?.role_display === "Fitness Instructor" ?
                                    <th scope="col" className="border-b px-3 bg-white py-3.5 text-left text-xs font-medium text-gray-500">
                                      <div className='flex gap-3 items-center'>
                                        <span className='cursor-pointer' onClick={(e) => this.sortData(e, 'date', 'desc')}>Sent Date</span> 
                                        {sortName === 'date' ?
                                          <React.Fragment>
                                            {sortReverse &&
                                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'asc')}>
                                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                              </svg>
                                            }
                                            {!sortReverse &&
                                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'desc')}>
                                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                              </svg>
                                            }
                                          </React.Fragment>
                                          :
                                          <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'desc')}>
                                            <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                          </svg>
                                        }
                                      </div>
                                    </th>
                                  :
                                    <React.Fragment>
                                      {!this.props.permissions?.business_messages?.write &&                         
                                        <th scope="col" className="border-b bg-white px-3 py-3.5 text-left text-xs font-medium text-gray-500">
                                          <div className='flex gap-3 items-center'>
                                            <span className='cursor-pointer' onClick={(e) => this.sortData(e, 'date', 'desc')}>Sent Date</span> 
                                            {sortName === 'date' ?
                                              <React.Fragment>
                                                {sortReverse &&
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'asc')}>
                                                    <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                                  </svg>
                                                }
                                                {!sortReverse &&
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'desc')}>
                                                    <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                                  </svg>
                                                }
                                              </React.Fragment>
                                              :
                                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'desc')}>
                                                <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                              </svg>
                                            }
                                          </div>
                                        </th> 
                                      }
                                    </React.Fragment>
                                  }
                                  <th scope="col" className="border-b bg-white px-3 py-3.5 text-left text-xs font-medium text-gray-500">
                                    {user?.role_display === "Fitness Instructor" ?
                                      <div className='flex gap-3 items-center justify-center mr-7'>
                                        <span className='cursor-pointer hidden' onClick={(e) => this.sortData(e, 'start_date', 'desc')}>Date</span> 
                                        {sortName === 'start_date' ?
                                          <React.Fragment>
                                            {sortReverse &&
                                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'start_date', 'asc')}>
                                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                              </svg>
                                            }
                                            {!sortReverse &&
                                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'start_date', 'desc')}>
                                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                              </svg>
                                            }
                                          </React.Fragment>
                                          :
                                          <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'start_date', 'desc')}>
                                            <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                          </svg>
                                        }
                                      </div>
                                    :
                                      <React.Fragment>
                                        {!this.props.permissions?.business_messages?.write ?                         
                                          <div className='flex gap-3 items-center justify-center mr-7'>
                                            <span className='cursor-pointer hidden' onClick={(e) => this.sortData(e, 'start_date', 'desc')}>Date</span> 
                                            {sortName === 'start_date' ?
                                              <React.Fragment>
                                                {sortReverse &&
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'start_date', 'asc')}>
                                                    <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                                  </svg>
                                                }
                                                {!sortReverse &&
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'start_date', 'desc')}>
                                                    <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                                  </svg>
                                                }
                                              </React.Fragment>
                                              :
                                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'start_date', 'desc')}>
                                                <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                              </svg>
                                            }
                                          </div>
                                        :
                                          <div className='flex gap-3 items-center'>
                                            <span className='cursor-pointer' onClick={(e) => this.sortData(e, 'date', 'desc')}>Sent Date</span> 
                                            {sortName === 'date' ?
                                              <React.Fragment>
                                                {sortReverse &&
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'asc')}>
                                                    <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                                  </svg>
                                                }
                                                {!sortReverse &&
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'desc')}>
                                                    <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                                  </svg>
                                                }
                                              </React.Fragment>
                                              :
                                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'desc')}>
                                                <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                              </svg>
                                            }
                                          </div>
                                        }
                                      </React.Fragment>
                                    }
                                    <div className='hidden flex gap-3 items-center'>
                                      <span className='cursor-pointer' onClick={(e) => this.sortData(e, 'date', 'desc')}>Date</span> 
                                      {sortName === 'date' &&
                                        <React.Fragment>
                                          {sortReverse &&
                                            <svg xmlns="http://www.w3.org/2000/svg" className=" h-4 w-4" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'asc')}>
                                              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                            </svg>
                                          }
                                          {!sortReverse &&
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'topic', 'desc')}>
                                              <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
                                            </svg>
                                          }
                                        </React.Fragment>
                                      }
                                    </div>
                                  </th>
                                  {user?.role_display !== "Fitness Instructor" && this.props.permissions?.business_messages?.write && 
                                    <th scope="col" className="border-b bg-white px-3 py-3.5 text-left text-xs font-medium text-gray-500">
                                      Metrics
                                    </th>
                                  }
                                </tr>
                              </thead>
                              {loadedData &&
                                <tbody className="bg-white">
                                  {messages.map((message, i) => 
                                    <tr key={i} x-description={`${message?.read ? "Odd" : "Even"} row`} className={`cursor-pointer ${message?.read ? 'bg-gray-50 msg-tr hover:bg-gray-100' : 'msg-tr'}`} onClick={(e) => this.clickOnPostStrip(e, message)}>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 border-0">
                                        <div className='flex items-center'>
                                          <div className='w-8 h-8 min-w-32 rounded-full bg-gray-200 flex items-center justify-center'>
                                            {getMessageTypeIcone(message?.message_type)}
                                          </div>
                                          <div className={`${message?.read ? 'font-light' : 'font-medium'} text-sm ml-2 max-w-6 max-w-10 max-w-14 truncate`}>
                                            {message?.title}
                                          </div>
                                          {message?.comments_count > 0 &&
                                            <div className='flex items-center gap-0.5 ml-3'>
                                              {message?.unread_comment_tag > 0 &&
                                                <div className='bg-red-500 w-4 h-4 rounded-full text-white flex items-center justify-center text-xs'>{message?.unread_comment_tag}</div>
                                              }
                                              {message?.unread_comment > 0 ?
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-800 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                                                  <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                                                </svg>
                                              :
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                                                  <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                                                </svg>
                                              }
                                            </div>
                                          }
                                          {message?.attachments &&
                                            <div className='flex items-center gap-0.5 ml-3'>
                                              <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                              </svg>
                                              <div className='text-gray-400 text-sm'>{message?.attachment_count}</div>
                                            </div>
                                          }
                                        </div>
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4">
                                        <div className="flex items-center self-center">
                                          <div className='bg-gray-200 rounded-full flex items-center justify-center'>
                                            <img className='w-6 h-6 min-w-24 rounded-full' src={message?.sender_image}/>
                                          </div>
                                          <div className={`text-gray-900 text-sm ${message?.read ? 'font-light' : 'font-medium'} ml-2`}>
                                            {`${this.countNameCharectors(message?.sender_full_name, message?.current_user_id === message?.sender_id ? 15 : 21)} ${message?.current_user_id === message?.sender_id ? '(Me)' : ''}`}
                                          </div>
                                        </div>
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4">
                                        <div className='flex items-center'>
                                          <div className='text-gray-500 text-sm'>{message?.sent_at}</div>
                                          {message?.edited && 
                                            <div className='ml-1 -mt-1'>
                                              <span className='text-xs text-gray-500 tooltip cursor-pointer italic' data-tip data-for={message?.id.toString()+'edited_text'}>(edited)</span>
                                              <ReactTooltip className='max-w-xs' id={message?.id.toString()+'edited_text'} place="top" effect="solid">
                                                {message?.edited_at}
                                              </ReactTooltip>
                                            </div>
                                          }
                                        </div>
                                        {user?.role_display !== "Fitness Instructor" && this.props.permissions?.business_messages?.write &&
                                          <React.Fragment>
                                            {message?.response_string !== '' && message?.icon_web !== '' && message?.text_color  !== '' &&
                                              <div className='flex items-center'>
                                                <img src={message?.icon_web} className='h-4 w-4'/>
                                                <div className={`text-xs ml-1`} style={{color: `${message?.text_color}`}}>
                                                  {message?.response_string}
                                                </div>
                                              </div>
                                            }
                                          </React.Fragment>
                                        }
                                      </td>
                                      {user?.role_display !== "Fitness Instructor" && this.props.permissions?.business_messages?.write ?
                                        <React.Fragment>
                                          <td className="whitespace-nowrap cursor-pointer px-3 py-4 text-sm text-gray-500">
                                            <div id="matricsTd" className={`hover:bg-gray-200 messenger-table-matrcis pl-2`} onMouseDown={(e) => this.openMetricsModal(e, message)}>
                                            <div className='flex items-center'>
                                              <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4 hover:text-gray-400" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={message?.id.toString()+'audience'}>
                                                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                                              </svg>
                                              <ReactTooltip className='max-w-xs' id={message?.id.toString()+'audience'} place="top" effect="solid">
                                                Audience
                                              </ReactTooltip>
                                              <div className='text-gray-700 text-sm font-medium ml-1.5'>{message?.receivers_count}</div>
                                            </div>
                                            <div className='flex items-center'>
                                              <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4 hover:text-gray-400" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={message?.id.toString()+'views'}>
                                                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                                <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                              </svg>
                                              <ReactTooltip className='max-w-xs' id={message?.id.toString()+'views'} place="top" effect="solid">
                                                Read
                                              </ReactTooltip>
                                              <div className='text-gray-500 text-sm font-medium ml-1.5'>{message?.viewscount}</div>
                                            </div>
                                            {message?.message_type === 'announcement' ? 
                                              <div className='flex items-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4 hover:text-gray-400" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={message?.id.toString()+'unread'}>
                                                  <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
                                                  <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                                                </svg>
                                                <ReactTooltip className='max-w-xs' id={message?.id.toString()+'unread'} place="top" effect="solid">
                                                  Unread
                                                </ReactTooltip>
                                                <div className='text-gray-500 text-sm font-medium ml-1.5'>{message?.reply_percentage}</div>
                                              </div>
                                            :
                                            (message?.message_type === 'compliance' ? 
                                              (message?.show_response ?
                                                <div className='flex items-center'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4 hover:text-gray-400 transform -rotate-180" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={message?.id.toString()+'reply'}>
                                                    <path fillRule="evenodd" d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                                  </svg>
                                                  <ReactTooltip className='max-w-xs' id={message?.id.toString()+'reply'} place="top" effect="solid">
                                                    Acknowledged
                                                  </ReactTooltip>
                                                  <div className='text-gray-500 text-sm font-medium ml-1.5'>{message?.reply_percentage}</div>
                                                </div>
                                              :
                                                <div className='flex items-center'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4 hover:text-gray-400" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={message?.id.toString()+'unread'}>
                                                    <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
                                                    <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                                                  </svg>
                                                  <ReactTooltip className='max-w-xs' id={message?.id.toString()+'unread'} place="top" effect="solid">
                                                    Unread
                                                  </ReactTooltip>
                                                  <div className='text-gray-500 text-sm font-medium ml-1.5'>{message?.reply_percentage}</div>
                                                </div>
                                              )
                                              :
                                              <div className='flex items-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4 hover:text-gray-400  transform -rotate-180" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={message?.id.toString()+'rsvp'}>
                                                  <path fillRule="evenodd" d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                                </svg>
                                                <ReactTooltip className='max-w-xs' id={message?.id.toString()+'rsvp'} place="top" effect="solid">
                                                  RSVP
                                                </ReactTooltip>
                                                <div className='text-gray-500 text-sm font-medium ml-1.5'>{message?.reply_percentage}</div>
                                              </div>
                                            )
                                            }
                                            </div>
                                          </td>
                                        </React.Fragment>
                                      :
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          {message?.response_string !== '' && message?.icon_web !== '' && message?.text_color  !== '' &&
                                            <div className='flex items-center'>
                                              <img src={message?.icon_web} className='h-4 w-4'/>
                                              <div className={`text-xs ml-1`} style={{color: `${message?.text_color}`}}>
                                                {message?.response_string}
                                              </div>
                                            </div>
                                          }
                                        </td> 
                                      }
                                    </tr>
                                  )}
                                  {messages?.length === 0 &&
                                    <tr>
                                      <td colSpan="4">
                                        <div className='bg-white px-6 min-calc-table pt-7 flex justify-center rounded-b-2xl items-center'>
                                          <div className='flex items-center'>
                                            <div className='flex flex-col'>
                                              <div className='w-28 bg-gray-300 rounded p-1 relative'>
                                                <div className='bg-white border border-black rounded px-2.5 py-3'>
                                                  <div className='flex items-center gap-2'>
                                                    <div className='w-3 h-3 bg-dark-blue rounded-full'></div>
                                                    <div className='flex flex-col gap-1.5'>
                                                      <div className='bg-gray-300 w-8 h-1 rounded-full'></div>
                                                      <div className='bg-gray-300 w-12 h-1 rounded-full'></div>
                                                    </div>
                                                  </div>
                                                  <div className='flex justify-end mt-2.5 gap-0.5'>
                                                    <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                                    <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                                    <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                                  </div>
                                                </div>
                                                <div className='absolute -bottom-2 left-2'>
                                                  <img src='/assets/bottom-angle.png' />
                                                </div>
                                              </div>
                                              <div className='w-28 bg-gray-300 rounded p-1 relative mt-3 ml-12'>
                                                <div className='bg-white border border-black rounded px-2.5 py-3'>
                                                  <div className='flex items-center gap-2'>
                                                    <div className='w-3 h-3 bg-dark-blue rounded-full'></div>
                                                    <div className='flex flex-col gap-1.5'>
                                                      <div className='bg-gray-300 w-8 h-1 rounded-full'></div>
                                                      <div className='bg-gray-300 w-12 h-1 rounded-full'></div>
                                                    </div>
                                                  </div>
                                                  <div className='flex justify-end mt-2.5 gap-0.5'>
                                                    <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                                    <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                                    <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                                  </div>
                                                </div>
                                                <div className='absolute -bottom-2 right-2 transform rotate-90'>
                                                  <img src='/assets/bottom-angle.png' />
                                                </div>
                                              </div>
                                            </div>
                                            <div className='ml-9'>
                                              <div className='text-gray-500 text-sm mb-3'>{totalForUser === 0 ? `No posts yet` : 'No posts found'}</div>
                                              {totalForUser === 0 && path !== 'archive' && (permissions?.business_messages?.write && user?.role_display !== "Fitness Instructor") &&
                                                <Link to='/messenger/create-post' className='bg-dark-blue text-white text-xs py-2 px-3.5 rounded'>Create your first post!</Link>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  }
                                  {messages?.length !== 0 && !loadMore &&
                                    <tr>
                                      <td colSpan="4"  className='bg-gray-100'>
                                        <div className="bg-gray-100 text-gray-900 py-2 text-center">
                                          That's all folks!
                                        </div>
                                      </td>
                                    </tr>
                                  }
                                  {!loadedPostOnScroll &&
                                    <tr>
                                      <td colSpan="4">
                                        <div className="p-4 w-full mx-auto">
                                          <div className="animate-pulse flex space-x-4 justify-between">
                                            <div className='w-60 flex gap-3 mr-20'>
                                              <div className="rounded-full bg-gray-200 h-8 w-8 ml-2"></div>
                                              <div className="flex-1 mt-2 py-1">
                                                <div className="h-2 bg-gray-200 rounded"></div>
                                              </div>
                                            </div>
                                            <div className='w-44 flex gap-3'>
                                              <div className="rounded-full bg-gray-200 h-8 w-8"></div>
                                              <div className="flex-1 mt-2 py-1">
                                                <div className="h-2 bg-gray-200 rounded"></div>
                                              </div>
                                            </div>
                                            <div className='w-28'>
                                              <div className="flex-1 mt-2 py-1">
                                                <div className="space-y-3">
                                                  <div className="h-2 bg-gray-200 rounded"></div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className='w-28'>
                                              <div className="space-y-3 mr-5 ml-2 mt-3">
                                                <div className="h-2 bg-gray-200 rounded"></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  }
                                </tbody>
                              }
                            </table>
                          </InfinitScroll>
                          {!loadedData &&
                            <div className="msg-loading flex flex-col items-center w-full justify-center absolute top-0 bottom-0 left-0 right-0 z-50 ">
                              <img className = "w-9 absolute top-96" src="/assets/business/loading.gif" />
                            </div>
                          }
                        </div>
                      </div>
                    </div> 
                  </div>
                </React.Fragment>
              :
                <React.Fragment>
                  <div className='mt-4 flex justify-between flex-wrap sm:mt-0'>
                    <div className='bg-white rounded-2xl'>
                      <div className='border-gray-300 rounded-2xl py-1.5 px-3.5'>
                        <div className={`flex items-center ${path === 'announcement' ? 'w-260' : 'w-60'}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                          </svg>
                          <input type="text" className="ml-1.5 focus:outline-none w-full" placeholder={`Search in ${(path === 'messenger' || path === 'inbox') ? 'all' : path == 'permanent_class_opening' ? 'permanent class opening' : path} posts`} value={searchData} onChange={(e) => this.handleSearch(e)}/>
                          {searchData !== '' &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-900 hover:text-gray-400" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleClearSearch('')}>
                              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                          }
                        </div>
                      </div>
                    </div>
                    {path !== 'archive' &&
                      <div className="relative inline-block text-left">
                        <Menu>
                          <div className="w-64">
                            <Menu.Button type="button" className="justify-between inline-flex w-full rounded-2xl border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
                              <div className='flex'>
                                <div>Filter by:</div>  
                                <div className='ml-1'>{this.filterName() === '' ? this.chekLocationNameCount('Select filter') : this.filterName()}</div>
                              </div>
                              <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                              </svg>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="origin-top-right absolute z-10 right-0 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                              <div className="pt-1 px-4" role="none">
                                <div className='justify-end flex border-b '>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a className="cursor-pointer sky-500 justify-end py-2 font-medium d-inline-block" onClick={(e) => this.handleClearFilterSorting(e)}>
                                        Clear Filter
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                                {filterTypes.map((filter, i) => 
                                  <Menu.Item  key={i}>
                                    {({ active }) => (
                                      <a className="flex cursor-pointer text-dark-blue justify-between py-3 text-sm border-b font-medium" role="menuitem" tabIndex="-1" id={`menu-item-${i}`} onClick={(e) => this.handleFilter(e, filter?.value)}>
                                        <div>{filter?.label}</div>
                                          {selectedFilter === filter?.value &&
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-dark-blue h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                              <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                            </svg>
                                          }
                                      </a>
                                    )}
                                  </Menu.Item>
                                )} 
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    }
                  </div>

                  <div className='mt-4 flex flex-col'>
                    <div id='commentsList' className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 px-5">
                        {tableLoaded &&
                          <div className='relative msg-table-height msg-table-min-height rounded-2xl' id="scrollableDiv" style={{ overflow: "auto" }}>
                            <InfinitScroll
                              className="rounded-2xl msg-table-height msg-table-min-height"
                              dataLength = {messages?.length}
                              next = {this.fetchNextUsers}
                              scrollableTarget="scrollableDiv"
                              hasMore = {loadMore}
                              // loader={
                              //   <div className="msg-loading flex flex-col items-center w-full justify-center absolute top-0 bottom-0 left-0 right-0 z-50 ">
                              //     {/* <p className="mb-1 text-center px-5">{loadingMessage}</p> */}
                              //     <img className = "w-9 absolute top-96" src="/assets/business/loading.gif" />
                              //   </div>
                              // }
                            >
                              <table className="border-separate min-w-full divide-y divide-gray-300 shadow ring-1 ring-black ring-opacity-5 rounded-2xl relative border-spacing-0">
                                <thead className="bg-white">
                                  <tr>
                                    <th scope="col" className="border-b bg-white py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 sm:pl-6 w-80">
                                      <div className='flex gap-3 items-center'>
                                        <span className='cursor-pointer' onClick={(e) => this.sortData(e, 'topic', 'desc')}>Subject</span> 
                                        {sortName === 'topic' ?
                                          <React.Fragment>
                                            {sortReverse &&
                                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'topic', 'asc')}>
                                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                              </svg>
                                            }
                                            {!sortReverse &&
                                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'topic', 'desc')}>
                                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                              </svg>
                                            }
                                          </React.Fragment>
                                          :
                                          <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'topic', 'desc')}>
                                            <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                          </svg>
                                        }
                                      </div>
                                    </th>
                                    <th id='senderList' scope="col" className="bg-white border-b px-3 py-3.5 text-left text-xs font-medium text-gray-500">
                                      {(permissions.business_messages?.write && user?.role_display !== "Fitness Instructor" && (path === 'messenger' || path === 'inbox')) ? 
                                        <div className='flex items-center'>
                                          <div className='flex gap-3 items-center'>
                                            <span className='cursor-pointer' onClick={(e) => this.sortData(e, 'sender', 'desc')}>From</span> 
                                            {sortName === 'sender' ?
                                              <React.Fragment>
                                                {sortReverse &&
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'sender', 'asc')}>
                                                    <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                                  </svg>
                                                }
                                                {!sortReverse &&
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'sender', 'desc')}>
                                                    <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                                  </svg>
                                                }
                                              </React.Fragment>
                                              :
                                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'sender', 'desc')}>
                                                <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                              </svg>
                                            }
                                          </div>
                                          <Menu as="div" className='relative'>
                                            <Menu.Button className='flex items-center border-gray-300 mr-8 -my-4 p-2 rounded-lg'>
                                              <div className='item-center ml-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="hidden text-gray-400 h-4 w-4 hover:text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                                  <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                                </svg>
                                                <ChevronDownIcon className="ml-auto text-gray-500 hover:text-gray-400 h-5 w-5" aria-hidden="true" />
                                              </div>
                                            </Menu.Button>
                                            <Transition
                                              as={Fragment}

                                              enter="transition ease-out duration-100"
                                              enterFrom="transform opacity-0 scale-95"
                                              enterTo="transform opacity-100 scale-100"
                                              leave="transition ease-in duration-75"
                                              leaveFrom="transform opacity-100 scale-100"
                                              leaveTo="transform opacity-0 scale-95">
                                              <Menu.Items className="origin-top-right top-7 z-10 absolute right-0 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none font-medium">
                                                <div className=''>
                                                  <input type='text' placeholder='Search sender' className='h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-8 py-2 bg-white text-sm focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500' name="searchSenderValue" value={searchSenderValue} onChange={(e) => this.searchSenderUsers(e)}/>
                                                  <button className="absolute top-2.5 right-2.5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                      <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                                    </svg>
                                                  </button>
                                                </div>
                                                {users?.length > 0 &&
                                                  <div className="flex justify-start items-center p-2 border-b">
                                                    <label className="inline-flex items-center text-gray-400 text-sm gap-1">
                                                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.selectAllSender()} onChange = {(e) => this.handleSelectAllSenders(e)}/>
                                                      Select all/Clear all
                                                    </label>
                                                    <a className='sky-500 cursor-pointer hidden' onClick={(e) => this.setClearSenderFilter(e)}>Clear all</a>
                                                  </div>
                                                }
                                                <div className="py-3 px-3 max-h-60 overflow-y-auto">
                                                  <div>
                                                    <div className='border-b mb-1'>
                                                      <label className="flex items-center text-gray-900 text-xs mb-1">
                                                        <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" name="selectedPeople" checked = {selectedPeople.includes(user?.id)} value={user?.id} onChange = {(e) => this.handleSelectSender(e)}/>
                                                        {user?.image !== '' ?
                                                          <div className="relative w-6 h-6">
                                                            <img src={`${user?.image}`} className='rounded-full' />
                                                          </div>
                                                          :
                                                          <div className='w-6 h-6 rounded-full bg-gray-500 flex items-center justify-center relative'>
                                                            <div className='text-white text-xs'>{user?.name_image}</div>
                                                          </div>
                                                        }
                                                          <div className='pl-1 text-xs'>{`${user?.full_name} (me)`}</div>
                                                      </label>
                                                    </div>
                                                    {users.map((user, i) => 
                                                      <div key = {i}>
                                                        <label className="flex items-center text-gray-900 text-sm mb-2">
                                                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" name="selectedPeople" checked = {selectedPeople.includes(user?.id)} value={user?.id} onChange = {(e) => this.handleSelectSender(e)}/>
                                                          {user?.image !== '' ?
                                                            <div className="relative w-6 h-6">
                                                              <img src={`${user?.image}`} className='rounded-full' />
                                                            </div>
                                                            :
                                                            <div className='w-6 h-6 min-w-24 rounded-full bg-gray-500 flex items-center justify-center relative'>
                                                              <div className='text-white text-xs'>{user?.name_image}</div>
                                                            </div>
                                                          }
                                                            <div className='pl-1 text-xs'>{user?.full_name}</div>
                                                        </label>
                                                      </div>
                                                    )}
                                                    {users?.length === 0 &&
                                                      <div className='text-gray-400 text-center'>No user found</div>
                                                    }
                                                  </div>
                                                </div>
                                              </Menu.Items>
                                            </Transition>
                                          </Menu>
                                        </div>
                                      :
                                      <div className='flex gap-3 items-center'>
                                        <span className='cursor-pointer' onClick={(e) => this.sortData(e, 'sender', 'desc')}>From</span> 
                                        {sortName === 'sender' ?
                                          <React.Fragment>
                                            {sortReverse &&
                                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'sender', 'asc')}>
                                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                              </svg>
                                            }
                                            {!sortReverse &&
                                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'sender', 'desc')}>
                                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                              </svg>
                                            }
                                          </React.Fragment>
                                          :
                                          <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'sender', 'desc')}>
                                            <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                          </svg>
                                        }
                                      </div>
                                      }
                                    </th>
                                    {user?.role_display === "Fitness Instructor" ?
                                      <th scope="col" className="border-b px-3 bg-white py-3.5 text-left text-xs font-medium text-gray-500">
                                        <div className='flex gap-3 items-center'>
                                          <span className='cursor-pointer' onClick={(e) => this.sortData(e, 'date', 'desc')}>Sent Date</span> 
                                          {sortName === 'date' ?
                                            <React.Fragment>
                                              {sortReverse &&
                                                <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'asc')}>
                                                  <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                                </svg>
                                              }
                                              {!sortReverse &&
                                                <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'desc')}>
                                                  <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                                </svg>
                                              }
                                            </React.Fragment>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'desc')}>
                                              <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                            </svg>
                                          }
                                        </div>
                                      </th>
                                    :
                                      <React.Fragment>
                                        {!this.props.permissions?.business_messages?.write &&                         
                                          <th scope="col" className="border-b bg-white px-3 py-3.5 text-left text-xs font-medium text-gray-500">
                                            <div className='flex gap-3 items-center'>
                                              <span className='cursor-pointer' onClick={(e) => this.sortData(e, 'date', 'desc')}>Sent Date</span> 
                                              {sortName === 'date' ?
                                                <React.Fragment>
                                                  {sortReverse &&
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'asc')}>
                                                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                                    </svg>
                                                  }
                                                  {!sortReverse &&
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'desc')}>
                                                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                                    </svg>
                                                  }
                                                </React.Fragment>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'desc')}>
                                                  <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                                </svg>
                                              }
                                            </div>
                                          </th> 
                                        }
                                      </React.Fragment>
                                    }
                                    {(user?.role_display === "Fitness Instructor" || !this.props.permissions?.business_messages?.write) &&
                                      <th scope="col" className="border-b bg-white px-3 py-3.5 text-left text-xs font-medium text-gray-500"></th>
                                    }
                                    <th scope="col" className="border-b bg-white px-3 py-3.5 text-left text-xs font-medium text-gray-500">
                                      {user?.role_display === "Fitness Instructor" ?
                                        <div className='flex gap-3 items-center justify-center mr-7'>
                                          <span className='cursor-pointer hidden' onClick={(e) => this.sortData(e, 'start_date', 'desc')}>Date</span> 
                                          {sortName === 'start_date' ?
                                            <React.Fragment>
                                              {sortReverse &&
                                                <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'start_date', 'asc')}>
                                                  <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                                </svg>
                                              }
                                              {!sortReverse &&
                                                <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'start_date', 'desc')}>
                                                  <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                                </svg>
                                              }
                                            </React.Fragment>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'start_date', 'desc')}>
                                              <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                            </svg>
                                          }
                                        </div>
                                      :
                                        <React.Fragment>
                                          {!this.props.permissions?.business_messages?.write ?                         
                                            <div className='flex gap-3 items-center justify-center mr-7'>
                                              <span className='cursor-pointer hidden' onClick={(e) => this.sortData(e, 'start_date', 'desc')}>Date</span> 
                                              {sortName === 'start_date' ?
                                                <React.Fragment>
                                                  {sortReverse &&
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'start_date', 'asc')}>
                                                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                                    </svg>
                                                  }
                                                  {!sortReverse &&
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'start_date', 'desc')}>
                                                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                                    </svg>
                                                  }
                                                </React.Fragment>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'start_date', 'desc')}>
                                                  <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                                </svg>
                                              }
                                            </div>
                                          :
                                            <div className='flex gap-3 items-center'>
                                              <span className='cursor-pointer' onClick={(e) => this.sortData(e, 'date', 'desc')}>Sent Date</span> 
                                              {sortName === 'date' ?
                                                <React.Fragment>
                                                  {sortReverse &&
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'asc')}>
                                                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                                    </svg>
                                                  }
                                                  {!sortReverse &&
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'desc')}>
                                                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                                    </svg>
                                                  }
                                                </React.Fragment>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'desc')}>
                                                  <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                                </svg>
                                              }
                                            </div>
                                          }
                                        </React.Fragment>
                                      }
                                      <div className='hidden flex gap-3 items-center'>
                                        <span className='cursor-pointer' onClick={(e) => this.sortData(e, 'date', 'desc')}>Date</span> 
                                        {sortName === 'date' &&
                                          <React.Fragment>
                                            {sortReverse &&
                                              <svg xmlns="http://www.w3.org/2000/svg" className=" h-4 w-4" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'date', 'asc')}>
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                              </svg>
                                            }
                                            {!sortReverse &&
                                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortData(e, 'topic', 'desc')}>
                                                <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
                                              </svg>
                                            }
                                          </React.Fragment>
                                        }
                                      </div>
                                    </th>
                                    {user?.role_display !== "Fitness Instructor" && this.props.permissions?.business_messages?.write && 
                                    <Fragment>
                                      <th scope="col" className="border-b bg-white px-3 py-3.5 text-left text-xs font-medium text-gray-500"></th>
                                      <th scope="col" className="border-b bg-white px-3 py-3.5 text-left text-xs font-medium text-gray-500">
                                        Metrics
                                      </th>
                                    </Fragment>
                                    }
                                  </tr>
                                </thead>
                                {loadedData &&
                                  <tbody className="bg-white">
                                    {messages.map((message, i) => 
                                      <tr key={i} x-description={`${message?.read ? "Odd" : "Even"} row`} className={`cursor-pointer ${message?.read ? 'bg-gray-50 msg-tr hover:bg-gray-100' : 'msg-tr'}`} onClick={(e) => this.clickOnPostStrip(e, message)}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 border-0">
                                          <div className='flex items-center'>
                                            <div className='w-8 h-8 min-w-32 rounded-full bg-gray-200 flex items-center justify-center'>
                                              {getMessageTypeIcone(message?.message_type)}
                                              {/* <svg xmlns="http://www.w3.org/2000/svg" className="text-dark-blue h-4 w-4" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z" clipRule="evenodd"></path></svg> */}
                                            </div>
                                            <div className={`${message?.read ? 'font-light' : 'font-medium'} text-sm ml-2 max-w-6 max-w-10 max-w-14   truncate`}>
                                              {message?.title}
                                            </div>
                                            {message?.comments_count > 0 &&
                                              <div className='flex items-center gap-0.5 ml-3'>
                                                {message?.unread_comment_tag > 0 &&
                                                  <div className='bg-red-500 w-4 h-4 rounded-full text-white flex items-center justify-center text-xs'>{message?.unread_comment_tag}</div>
                                                }
                                                {message?.unread_comment > 0 ?
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-800 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                                                    <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                                                  </svg>
                                                :
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                                                    <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                                                  </svg>
                                                }
                                                {/* <div className='text-gray-800 text-sm'>3</div> */}
                                              </div>
                                            }
                                            {message?.attachments &&
                                              <div className='flex items-center gap-0.5 ml-3'>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                                </svg>
                                                <div className='text-gray-400 text-sm'>{message?.attachment_count}</div>
                                              </div>
                                            }
                                          </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4">
                                          <div className="flex items-center self-center">
                                            <div className='bg-gray-200 rounded-full flex items-center justify-center'>
                                              <img className='w-6 h-6 min-w-24 rounded-full' src={message?.sender_image}/>
                                            </div>
                                            <div className={`text-gray-900 text-sm ${message?.read ? 'font-light' : 'font-medium'} ml-2`}>
                                              {`${this.countNameCharectors(message?.sender_full_name, message?.current_user_id === message?.sender_id ? 15 : 21)} ${message?.current_user_id === message?.sender_id ? '(Me)' : ''}`}
                                            </div>
                                          </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4">
                                          <div className='flex items-center'>
                                            <div className='text-gray-500 text-sm'>{message?.sent_at}</div>
                                            {message?.edited && 
                                              <div className='ml-1 -mt-1'>
                                                <span className='text-xs text-gray-500 tooltip cursor-pointer italic' data-tip data-for={message?.id.toString()+'edited_text'}>(edited)</span>
                                                <ReactTooltip className='max-w-xs' id={message?.id.toString()+'edited_text'} place="top" effect="solid">
                                                  {message?.edited_at}
                                                </ReactTooltip>
                                              </div>
                                            }
                                          </div>
                                          {user?.role_display !== "Fitness Instructor" && this.props.permissions?.business_messages?.write &&
                                            <React.Fragment>
                                              {message?.response_string !== '' && message?.icon_web !== '' && message?.text_color  !== '' &&
                                                <div className='flex items-center'>
                                                  <img src={message?.icon_web} className='h-4 w-4'/>
                                                  <div className={`text-xs ml-1`} style={{color: `${message?.text_color}`}}>
                                                    {message?.response_string}
                                                  </div>
                                                </div>
                                              }
                                            </React.Fragment>
                                          }
                                          {message?.message_type === "permanent_class_opening" && 
                                            <Fragment>
                                              <div className='flex items-center gap-1'>
                                                <div className='text-xs font-medium'>{`Time: `}</div>
                                                 <div className='flex text-xs items-center'>{message?.permanent_class_start_time} -{message?.permanent_class_end_time}</div>
                                              </div>
                                              <div className='flex items-center gap-1'>
                                                <div className='text-xs font-medium'>{`Days: `}</div>
                                                <div className='flex text-xs items-center'>{this.showDaysAbbrivation(message?.week_days)}</div>
                                              </div>
                                            </Fragment>
                                          }
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4">
                                          {message?.message_type === "permanent_class_opening" &&
                                            <Fragment>
                                              {message?.status_web === "approved" ?
                                                <Fragment>
                                                  {(parseInt(message?.sender_id) === parseInt(message?.current_user_id) || parseInt(message?.current_user_id) === parseInt(message?.approved_user_id) || (user?.role_display !== "Fitness Instructor" && this.props.permissions?.business_messages?.write)) ?
                                                    <div className='flex items-center justify-center px-4 py-1 bg-green-200 text-green-800 rounded-md text-xs'>Approved</div>
                                                  :
                                                    <div className='flex items-center justify-center px-4 py-1 red-tag text-gray-400 rounded-md text-xs'>Unavailable</div>
                                                  }
                                                </Fragment>
                                                :
                                                (message?.status_web === "cancelled" ?
                                                  <div className='flex items-center justify-center px-4 py-1 red-tag text-gray-400 rounded-md text-xs'>Unavailable</div>
                                                : 
                                                  ((message?.status_web !== "approved" && message?.status_web !== "cancelled" && message?.yes_response_count > 0 && (user?.role_display !== "Fitness Instructor" && this.props.permissions?.business_messages?.write)) &&
                                                    <div className='flex items-center justify-center px-4 py-1 bg-purple-200 text-purple-800 rounded-md text-xs'>Pending Approval</div>
                                                  )
                                                )
                                              }
                                            </Fragment>
                                          }
                                        </td>
                                        {user?.role_display !== "Fitness Instructor" && this.props.permissions?.business_messages?.write ?
                                          <React.Fragment>
                                            {/* {message?.current_user_id !== message?.sender_id ? <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                                            : */}
                                            <td className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 relative ${metricsDataLoaded ? 'cursor-pointer' : 'cursor-not-allowed'}`}>
                                              <div id="matricsTd" className={`hover:bg-gray-200 messenger-table-matrcis pl-2`} onMouseDown={(e) => {if (metricsDataLoaded){this.openMetricsModal(e, message);}}}>
                                                {(!metricsDataLoaded && Object.keys(selectedMessage)?.length > 0 && selectedMessage?.id === message?.id) &&
                                                  <div className="flex items-center right-4 top-5 justify-center absolute">
                                                    <svg className="animate-spin h-4 w-4 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                  </div>
                                                }
                                                <div className='flex items-center'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4 hover:text-gray-400" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={message?.id.toString()+'audience'}>
                                                    <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                                                  </svg>
                                                  <ReactTooltip className='max-w-xs' id={message?.id.toString()+'audience'} place="top" effect="solid">
                                                    Audience
                                                  </ReactTooltip>
                                                  <div className='text-gray-700 text-sm font-medium ml-1.5'>{message?.receivers_count}</div>
                                                </div>
                                                <div className='flex items-center'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4 hover:text-gray-400" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={message?.id.toString()+'views'}>
                                                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                                    <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                                  </svg>
                                                  <ReactTooltip className='max-w-xs' id={message?.id.toString()+'views'} place="top" effect="solid">
                                                    Read
                                                  </ReactTooltip>
                                                  <div className='text-gray-500 text-sm font-medium ml-1.5'>{message?.viewscount}</div>
                                                </div>
                                                {message?.message_type === 'announcement' ? 
                                                  <div className='flex items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4 hover:text-gray-400" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={message?.id.toString()+'unread'}>
                                                      <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
                                                      <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                                                    </svg>
                                                    <ReactTooltip className='max-w-xs' id={message?.id.toString()+'unread'} place="top" effect="solid">
                                                      Unread
                                                    </ReactTooltip>
                                                    <div className='text-gray-500 text-sm font-medium ml-1.5'>{message?.reply_percentage}</div>
                                                  </div>
                                                :
                                                (message?.message_type === 'compliance' ? 
                                                  (message?.show_response ?
                                                    <div className='flex items-center'>
                                                      <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4 hover:text-gray-400 transform -rotate-180" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={message?.id.toString()+'reply'}>
                                                        <path fillRule="evenodd" d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                                      </svg>
                                                      <ReactTooltip className='max-w-xs' id={message?.id.toString()+'reply'} place="top" effect="solid">
                                                        Acknowledged
                                                      </ReactTooltip>
                                                      <div className='text-gray-500 text-sm font-medium ml-1.5'>{message?.reply_percentage}</div>
                                                    </div>
                                                  :
                                                    <div className='flex items-center'>
                                                      <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4 hover:text-gray-400" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={message?.id.toString()+'unread'}>
                                                        <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
                                                        <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                                                      </svg>
                                                      <ReactTooltip className='max-w-xs' id={message?.id.toString()+'unread'} place="top" effect="solid">
                                                        Unread
                                                      </ReactTooltip>
                                                      <div className='text-gray-500 text-sm font-medium ml-1.5'>{message?.reply_percentage}</div>
                                                    </div>
                                                  )
                                                  :
                                                  <div className='flex items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4 hover:text-gray-400  transform -rotate-180" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={message?.id.toString()+'rsvp'}>
                                                      <path fillRule="evenodd" d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                                    </svg>
                                                    <ReactTooltip className='max-w-xs' id={message?.id.toString()+'rsvp'} place="top" effect="solid">
                                                      RSVP
                                                    </ReactTooltip>
                                                    <div className='text-gray-500 text-sm font-medium ml-1.5'>{message?.reply_percentage}</div>
                                                  </div>
                                                )
                                                }
                                              </div>
                                            </td>
                                            {/* } */}
                                          </React.Fragment>
                                        :
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {message?.response_string !== '' && message?.icon_web !== '' && message?.text_color  !== '' &&
                                              <div className='flex items-center'>
                                                <img src={message?.icon_web} className='h-4 w-4'/>
                                                <div className={`text-xs ml-1`} style={{color: `${message?.text_color}`}}>
                                                  {message?.response_string}
                                                </div>
                                              </div>
                                            }
                                          </td> 
                                        }
                                      </tr>
                                    )}
                                    {messages?.length === 0 &&
                                      <tr>
                                        <td colSpan="5">
                                          <div className='bg-white px-6 min-calc-table pt-7 flex justify-center rounded-b-2xl items-center'>
                                            <div className='flex items-center'>
                                              <div className='flex flex-col'>
                                                <div className='w-28 bg-gray-300 rounded p-1 relative'>
                                                  <div className='bg-white border border-black rounded px-2.5 py-3'>
                                                    <div className='flex items-center gap-2'>
                                                      <div className='w-3 h-3 bg-dark-blue rounded-full'></div>
                                                      <div className='flex flex-col gap-1.5'>
                                                        <div className='bg-gray-300 w-8 h-1 rounded-full'></div>
                                                        <div className='bg-gray-300 w-12 h-1 rounded-full'></div>
                                                      </div>
                                                    </div>
                                                    <div className='flex justify-end mt-2.5 gap-0.5'>
                                                      <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                                      <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                                      <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                                    </div>
                                                  </div>
                                                  <div className='absolute -bottom-2 left-2'>
                                                    <img src='/assets/bottom-angle.png' />
                                                  </div>
                                                </div>
                                                <div className='w-28 bg-gray-300 rounded p-1 relative mt-3 ml-12'>
                                                  <div className='bg-white border border-black rounded px-2.5 py-3'>
                                                    <div className='flex items-center gap-2'>
                                                      <div className='w-3 h-3 bg-dark-blue rounded-full'></div>
                                                      <div className='flex flex-col gap-1.5'>
                                                        <div className='bg-gray-300 w-8 h-1 rounded-full'></div>
                                                        <div className='bg-gray-300 w-12 h-1 rounded-full'></div>
                                                      </div>
                                                    </div>
                                                    <div className='flex justify-end mt-2.5 gap-0.5'>
                                                      <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                                      <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                                      <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                                    </div>
                                                  </div>
                                                  <div className='absolute -bottom-2 right-2 transform rotate-90'>
                                                    <img src='/assets/bottom-angle.png' />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className='ml-9'>
                                                <div className='text-gray-500 text-sm mb-3'>{totalForUser === 0 ? `No posts yet` : 'No posts found'}</div>
                                                {totalForUser === 0 && path !== 'archive' && (permissions?.business_messages?.write && user?.role_display !== "Fitness Instructor") &&
                                                  <Link to='/messenger/create-post' className='bg-dark-blue text-white text-xs py-2 px-3.5 rounded'>Create your first post!</Link>
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    }
                                    {messages?.length !== 0 && !loadMore &&
                                      <tr>
                                        <td colSpan="5"  className='bg-gray-100'>
                                          <div className="bg-gray-100 text-gray-900 py-2 text-center">
                                            That's all folks!
                                          </div>
                                        </td>
                                      </tr>
                                    }
                                    {!loadedPostOnScroll &&
                                      <tr>
                                        <td colSpan="5">
                                          <div className="p-4 w-full mx-auto">
                                            <div className="animate-pulse flex space-x-4 justify-between">
                                              <div className='w-60 flex gap-3 mr-20'>
                                                <div className="rounded-full bg-gray-200 h-8 w-8 ml-2"></div>
                                                <div className="flex-1 mt-2 py-1">
                                                  <div className="h-2 bg-gray-200 rounded"></div>
                                                </div>
                                              </div>
                                              <div className='w-44 flex gap-3'>
                                                <div className="rounded-full bg-gray-200 h-8 w-8"></div>
                                                <div className="flex-1 mt-2 py-1">
                                                  <div className="h-2 bg-gray-200 rounded"></div>
                                                </div>
                                              </div>
                                              <div className='w-28'>
                                                <div className="flex-1 mt-2 py-1">
                                                  <div className="space-y-3">
                                                    <div className="h-2 bg-gray-200 rounded"></div>
                                                    {/* <div className="h-2 bg-gray-200 rounded"></div> */}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className='w-28'>
                                                <div className="space-y-3 mr-5 ml-2 mt-3">
                                                  <div className="h-2 bg-gray-200 rounded"></div>
                                                  {/* <div className="h-2 bg-gray-200 rounded"></div>
                                                  <div className="h-2 bg-gray-200 rounded"></div> */}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    }
                                  </tbody>
                                }
                              </table>
                            </InfinitScroll>
                            {!loadedData &&
                              <div className="msg-loading flex flex-col items-center w-full justify-center absolute top-0 bottom-0 left-0 right-0 z-50 ">
                                <img className = "w-9 absolute top-96" src="/assets/business/loading.gif" />
                              </div>
                            }
                          </div>
                        }
                        {!tableLoaded &&
                          <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                            <p className="mb-1 text-center px-5">{loadingMessage}</p>
                            <img className = "w-9" src="/assets/business/loading.gif" />
                          </div>
                        }
                      </div>
                    </div> 
                  </div>
                </React.Fragment>
              }
            </div>
          </div>
        }
        <Transition.Root show={showMetricsModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeMetricsModal(e)}>
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6 h-96">
									<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
											onClick={(e) => this.closeMetricsModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
                    {["permanent_class_opening", "event"].includes(selectedMessage?.message_type) &&
                      <React.Fragment>
                        <a className={`${selectMatricsButton === 'yes' ? 'bg-gray-100 text-gray-700 hover:bg-gray-200 cursor-pointer' : 'text-gray-500 cursor-pointer hover:bg-gray-50'} text-xs px-2.5 py-2 rounded-md`} onClick={() => {this.setState({selectMatricsButton: 'yes', showUserList: message_response?.yes, showUserListAllCount: message_response?.yes_count, metricsActivePage: 1}); this.scrollMetricsListToTop()}}>Yes ({message_response?.yes_count})</a>
                        <a className={`${selectMatricsButton === 'maybe' ? 'bg-gray-100 text-gray-700 hover:bg-gray-200 cursor-pointer' : 'text-gray-500 cursor-pointer hover:bg-gray-50'} text-xs px-2.5 py-2 rounded-md`} onClick={() => {this.setState({selectMatricsButton: 'maybe', showUserList: message_response?.may_be, showUserListAllCount: message_response?.may_be_count, metricsActivePage: 1}); this.scrollMetricsListToTop()}}>Maybe ({message_response?.may_be_count})</a>
                        <a className={`${selectMatricsButton === 'no' ? 'bg-gray-100 text-gray-700 hover:bg-gray-200 cursor-pointer' : 'text-gray-500 cursor-pointer hover:bg-gray-50'} text-xs px-2.5 py-2 rounded-md`} onClick={() => {this.setState({selectMatricsButton: 'no', showUserList: message_response?.no, showUserListAllCount: message_response?.no_count, metricsActivePage: 1}); this.scrollMetricsListToTop()}}>No ({message_response?.no_count})</a>
                      </React.Fragment>
                    }
                    {(selectedMessage?.message_type === "compliance" && selectedMessage?.show_response) &&
										  <a className={`${selectMatricsButton === 'acknowledged' ? 'bg-gray-100 text-gray-700 hover:bg-gray-200 cursor-pointer' : 'text-gray-500 cursor-pointer hover:bg-gray-50'} text-xs px-2.5 py-2 rounded-md`} onClick={() => {this.setState({selectMatricsButton: 'acknowledged', showUserList: message_response?.acknowledged, showUserListAllCount: message_response?.acknowledged_count, metricsActivePage: 1}); this.scrollMetricsListToTop()}}>Acknowledged ({message_response?.acknowledged_count})</a>
                    }
                    {/* {selectedMessage?.message_response?.read?.length > 0 && */}
                      <a className={`flex items-center gap-x-1.5 text-xs px-2.5 py-2 rounded-md ${selectMatricsButton === 'read' ? 'bg-gray-100 text-gray-700 hover:bg-gray-200 cursor-pointer' : 'text-gray-500 cursor-pointer hover:bg-gray-50'}`} onClick={() => {this.setState({selectMatricsButton:'read', showUserList: message_response?.read, showUserListAllCount: message_response?.read_count, metricsActivePage: 1}); this.scrollMetricsListToTop()}}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                          <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                        </svg>
                        Read ({message_response?.read_count})
                      </a>
                    {/* } */}
                    {/* {selectedMessage?.message_response?.unread?.length > 0 && */}
                      <a className={`flex items-center gap-x-1.5 text-xs px-2.5 py-2 rounded-md ${selectMatricsButton === 'unread' ? 'bg-gray-100 text-gray-700 hover:bg-gray-200 cursor-pointer' : 'text-gray-500 cursor-pointer hover:bg-gray-50'}`} onClick={() => {this.setState({selectMatricsButton:'unread', showUserList: message_response?.unread, showUserListAllCount: message_response?.unread_count, metricsActivePage: 1}); this.scrollMetricsListToTop()}}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
                          <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                        </svg>
                        Unread ({message_response?.unread_count})
                      </a>
                    {/* } */}
                    <a className={`flex items-center gap-x-1.5 text-xs px-2.5 py-2 rounded-md ${selectMatricsButton === 'sent' ? 'bg-gray-100 text-gray-700 hover:bg-gray-200 cursor-pointer' : 'text-gray-500 cursor-pointer hover:bg-gray-50'}`} onClick={() => {this.setState({selectMatricsButton:'sent',showUserList: message_response?.receivers, showUserListAllCount: message_response?.receivers_count, metricsActivePage: 1}); this.scrollMetricsListToTop()}}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                      </svg>
                      Audience ({message_response?.receivers_count})
                    </a>
										
									</div>
                  {showUserList?.length !== 0 &&
                    <a className='text-custom-blue text-base font-medium flex justify-center pt-4 cursor-pointer' onClick={(e) => this.handlePostTagComment(e, selectMatricsButton)}>
                      
                      {selectMatricsButton === 'sent' ?
                        `Post comment to everyone in the "Audience"`
                      :
                        ['yes', 'no', 'maybe'].includes(selectMatricsButton) ?
                          ((selectedMessage?.message_type === "permanent_class_opening") ? 
                            `Post comment to all who responded "${capitalizeFirstLetter(selectMatricsButton)}"`
                          :
                            `Post comment to all who RSVPed "${capitalizeFirstLetter(selectMatricsButton)}"`
                          )
                        :
                        `Post comment to all who “${capitalizeFirstLetter(selectMatricsButton)}”`
                      }
                    </a>
                  }
                  {showUserList?.length > 0 ?
                    <div className='justify-between mt-2'>
                      <InfinitScroll
                        id="metricsDiv"
                        className="matrics-div-infinitescroll"
                        dataLength = {showUserList?.length}
                        next = {this.fetchNextMetricsUser}
                        height={270}
                        hasMore = {true}
                      >
                        <React.Fragment>
                          {showUserList.map((usr, i) =>
                            <div key={i} className='flex justify-between pb-3 pr-4'>
                              <div className='flex items-center gap-x-1.5'>
                                {usr?.create_dm_permission ?
                                  <div className='flex items-center gap-x-1.5 cursor-pointer hover:bg-gray-200 rounded-lg p-1' onMouseDown={(e) => this.handleCreateDmPopup(usr?.full_name, usr?.id)}>
                                    <img src={usr?.user_image} className='w-6 h-6 rounded-full mr-1.5'/>
                                    <div className='text-gray-900 text-sm'>{usr?.full_name}</div>
                                  </div>
                                  :
                                  <div className='flex items-center gap-x-1.5 rounded-lg p-1'>
                                    <img src={usr?.user_image} className='w-6 h-6 rounded-full mr-1.5'/>
                                    <div className='text-gray-900 text-sm'>{usr?.full_name}</div>
                                  </div>
                                }
                                {(['acknowledged', 'yes', 'no', 'may_be', 'rsvp', 'read'].includes(selectMatricsButton)) &&
                                  <div className='text-gray-400 text-xs mt-0.5'>{(selectMatricsButton === 'read' && usr?.read_at) || (['acknowledged', 'yes', 'no', 'may_be', 'rsvp'].includes(selectMatricsButton) && usr?.responsed_at)}</div>
                                }
                                {usr.active_status !== "" &&
                                  <div className='text-gray-500 text-sm mt-0.5'>
                                    ({usr.active_status})
                                  </div>
                                }
                              </div>
                              <div>
                                {(selectedMessage?.message_type === "permanent_class_opening") && 
                                  <React.Fragment>
                                    {(user?.role_display !== "Fitness Instructor" && permissions?.business_messages?.write) &&
                                      <button className={`flex items-center justify-center px-4 py-1 rounded-md text-xs ${(usr?.permanent_class_status === "approved" && selectedMessage?.approved_user_id == usr?.id) ? 'bg-green-200 text-green-800' : 'bg-purple-100 text-black'} ${selectedMessage?.status_web !== "cancelled" ? 'cursor-pointer' : 'cursor-default'}`} onClick ={() => this.handleApproveUser(usr)} disabled={selectedMessage?.status_web === "cancelled"} >{(usr?.permanent_class_status === "approved" && selectedMessage?.approved_user_id == usr?.id) ?  "Approved" : "Approve"}</button>
                                    }
                                  </React.Fragment>
                                }
                                <div className='hidden w-8 h-8 bg-gray-100 flex items-center justify-center rounded'>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clipRule="evenodd" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      </InfinitScroll>
                    </div>
                    :
                    (selectMatricsButton === 'read' ? 
                      <div className='text-center text-xs pt-5 justify-center items-center flex h-64'>
                        No one has read this yet. Check back later!
                      </div>
                    :
                      <div className='text-center text-xs pt-5 justify-center items-center flex h-64'>
                        There aren't any Response at this time. Check back later!
                      </div>
                    )
                  }
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
        {showApprovedConfirmPopup && 
          <CustomPopup show={showApprovedConfirmPopup} close={this.closeCustomPopup} title={"Confirm approve user"}>
            <div className="mt-3 border-t border-gray-200 mb-2">
              <div className='flex justify-between sm:gap-4 sm:items-center px-7 py-3 text-center text-base font-normal'>
                {`Are you sure you'd like to change approved user ${approvedUser?.full_name}?`}
              </div>
              <div className='flex sm:gap-4 sm:items-center px-7 justify-center pt-4'>
                <button className={`flex items-center px-6 py-2.5 gap-1 border border-gray-300 shadow-sm font-normal rounded-md bg-gray-800 `} onClick={(e) => this.handleProcessApproveUser(approvedUser?.id, approvedUser?.permanent_class_status)}>
                  <div className="flex items-center gap-1 relative">
                    <div className='text-white text-sm'>Yes, please change</div>
                  </div>
                </button>
                <button className={`flex items-center px-6 py-2.5 gap-1 border border-gray-300 shadow-sm font-normal rounded-md bg-gray-800 `} onClick={(e) => this.closeCustomPopup()}>
                  <div className="flex items-center gap-1 relative">
                    <div className='text-white text-sm'>Not at this time</div>
                  </div>
                </button>
              </div>
            </div>
          </CustomPopup>
        }
        {showCreateDmPopup && 
          <CustomPopup show={showCreateDmPopup} close={this.closeCustomPopup} title={"Confirm start direct message"}>
            <div className="mt-3 border-t border-gray-200 mb-2">
              <div className='flex justify-between sm:gap-4 sm:items-center px-7 py-3 text-center text-base font-normal'>
                {`Do you want to start a direct message with ${createDmUserName}?`}
              </div>
              <div className='flex sm:gap-4 sm:items-center px-7 justify-center pt-4'>
                <button className={`flex items-center px-6 py-2.5 gap-1 border border-gray-300 shadow-sm font-normal rounded-md  ${createDmBtnDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-800 cursor-pointer'}`} onClick={(e) => this.handleProcessCreateDM(createDmUserName, createDmUserId)} disabled={createDmBtnDisabled}>
                  <div className="flex items-center gap-1 relative">
                    {createDmBtnDisabled && 
                      <div className="flex items-center -left-5 justify-center absolute">
                        <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </div>
                    }
                    <div className='text-white text-sm'>Yes</div>
                  </div>
                </button>
                <button className={`flex items-center px-6 py-2.5 gap-1 border border-gray-300 shadow-sm font-normal rounded-md bg-gray-800 `} onClick={(e) => this.closeCustomPopup()}>
                  <div className="flex items-center gap-1 relative">
                    <div className='text-white text-sm'>No</div>
                  </div>
                </button>
              </div>
            </div>
          </CustomPopup>
        }
      </React.Fragment>
    )
  }
}
