import React, { Fragment } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'
import XCircleIcon from './xCircleIcon';

export default function NotificationModal (props) {
	const { showNotificationList, closeNotificationModal, notification, setNotification } = props

	const closeModal = () => {
		closeNotificationModal(false)
	}

  const handlNotification = (value) => {
    setNotification(value)
  }

	return (
		<Transition.Root show={showNotificationList} as={Fragment}>
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => closeModal(e)}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
							<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
								<button
									type="button"
									className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
									onClick={(e) => closeModal(e)}
								>
									<span className="sr-only">Close</span>
									<XCircleIcon classNames={"h-8 w-8 text-black"} />
								</button>
							</div>
							<div className="sm:items-start">
								<div className='flex flex-wrap gap-3 items-center mb-3'>
									<div className='item-center'>
										Mute Notifications
									</div>
								</div>
                <div className='flex flex-colflex flex-col justify-center items-center gap-2'>
                  <button className={`py-2 border rounded-lg inline-flex w-42 justify-center text-sm ${notification === '1_hour' ? 'bg-gray-200' : ''}`} onClick={(e) => handlNotification('1_hour')}>
                    1 Hour
                  </button>
                  <button className={`py-2 border rounded-lg inline-flex w-42 justify-center text-sm ${notification === '24_hours' ? 'bg-gray-200' : ''}`} onClick={(e) => handlNotification('24_hours')}>
                    24 Hours
                  </button>
                  <button className={`py-2 border rounded-lg inline-flex w-42 justify-center text-sm ${notification === '1_week' ? 'bg-gray-200' : ''}`} onClick={(e) => handlNotification('1_week')}>
                    1 Week
                  </button>
                  <button className={`py-2 border rounded-lg inline-flex w-42 justify-center text-sm ${notification === 'always' ? 'bg-gray-200' : ''}`} onClick={(e) => handlNotification('always')}>
                    Always
                  </button>
                  <button className={`py-2 border rounded-lg inline-flex w-42 justify-center text-sm ${notification === 'do_not_mute' ? 'bg-gray-200' : ''}`} onClick={(e) => handlNotification('do_not_mute')}>
                    Do Not Mute
                  </button>
                </div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	)
}