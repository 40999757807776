import React, { Fragment } from "react";
import { Stepper, Step } from 'react-form-stepper';

export default function StepsComponent(props) {
	const { step } = props

  return(
    <Fragment>
      <div className="flex items-center w-full md:w-3/5">
	    	<Stepper 
          activeStep={step || 0} 
          className="w-full"
          connectorStateColors={true}
		  		styleConfig={{
            activeBgColor: '#111827',
            activeTextColor: '#fff',
            completedBgColor: '#111827',
            completedTextColor: '#fff',
            size: '2.25rem'
		  		}}
		  		connectorStyleConfig={{
            activeColor: '#111827',
            completedColor: '#111827',
            size: 4
					}}
	    	>
          <Step label="" />
          <Step label="" />
          <Step label="" />
				</Stepper>
      </div>
    </Fragment>
  )
}