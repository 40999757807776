import React from "react";
import EmptyMessageIcon from './assets/emptyMessageIcon'

export default function ChannelEmptyState(){
  return(
    <div className="rounded-2xl bg-white h-full w-full flex justify-center items-center flex-col gap-7">
      <EmptyMessageIcon classNames = {"w-32 h-32 text-gray-800"}/>
      <div className='text-black text-2xl font-bold'>
        Let's start chatting!
      </div>
    </div>
  )
}