import React, { Fragment } from "react";
import ClassesComponent from './classesComponent';
import ConfirmInstructorsTableComponent from './confirmInstructorsTableComponent';
import ConfirmSubRequestButton from './confirmSubRequestButton';
import ApprovedUserDetailsComponent from './approvedUserDetailsComponent';
import UrgentClassTextMessageComponent from './urgentClassTextMessageComponent';

export default function FinalStepComponents(props) {
  const { newSubRequest, user, setStep, business } = props;

  return(
    <Fragment>
      <ClassesComponent
        newSubRequest={newSubRequest}
      />
      {newSubRequest?.approvedUser?.id == '' ?
        <ConfirmInstructorsTableComponent
          newSubRequest={newSubRequest}
        />
      :
        <ApprovedUserDetailsComponent newSubRequest={newSubRequest} />
      }
      {!newSubRequest?.previousWeek &&
        <UrgentClassTextMessageComponent 
          newSubRequest={newSubRequest}
          business={business}
          user={user}
        />
      }
      <ConfirmSubRequestButton 
        newSubRequest={newSubRequest}
        user={user}
        setStep={setStep}
        business={business}
      />
    </Fragment>
  )
}