import React, { useState, useEffect } from 'react';
import { SearchIcon } from './assets/searchIcon';
import { useChatContext } from 'stream-chat-react'
import { debounce } from "lodash";
import ChannelSearchResult from './channelSearchResult'

export default function ChannelSearch(props) {
  const { clickOnChannel, business } = props;
  const [ query, setQuery ] = useState('')
  const { client, setActiveChannel } = useChatContext()
  const [ loading, setLoading ] = useState(false);
  const [ teamChannels, setTeamChannels ] = useState([])
  const [ focused, setFocused ] = useState(undefined);
  const [ focusedId, setFocusedId ] = useState('');
  const [ allChannels, setAllChannels ] = useState([]);
  const [ directChannels, setDirectChannels ] = useState([])

  const handleKeyDown = ((event) => {
      let suggetionBox = document.getElementsByClassName('channel-search__results')
      let activeItem =  []
      var elHeight = 0
      if (event.key === 'ArrowDown') {
        setFocused((prevFocused) => {
          if (prevFocused === undefined || allChannels === undefined){
            if(suggetionBox?.length > 0 && suggetionBox[0] !== null){
              suggetionBox[0].scrollTop = elHeight 
            }
            return 1;
          }else{
            activeItem = document.getElementsByClassName('channel-search__result-container__focused')
            if(activeItem?.length > 0 && activeItem[0] !== null){
              elHeight = activeItem[0].clientHeight
              if(prevFocused === allChannels.length){
                suggetionBox[0].scrollTop = elHeight*(1 - 1);
              }else{
                suggetionBox[0].scrollTop = elHeight*((prevFocused + 1) - 1);
              }
            }
            return prevFocused === allChannels.length ? 1 : prevFocused + 1;
          }
        });
      } else if (event.key === 'ArrowUp') {
        setFocused((prevFocused) => {
          if (prevFocused === undefined || allChannels === undefined){ 
            if(suggetionBox?.length > 0 && suggetionBox[0] !== null){
              suggetionBox[0].scrollTop = elHeight
            }
            return 1;
          }else{
            activeItem = document.getElementsByClassName('channel-search__result-container__focused')
            if(activeItem?.length > 0 && activeItem[0] !== null){
              elHeight = activeItem[0].clientHeight
              if(prevFocused === 1){
                suggetionBox[0].scrollTop = elHeight*((allChannels.length) - 1);
              }else{
                suggetionBox[0].scrollTop = elHeight*((prevFocused - 1) - 1);
              }
            }
            return prevFocused === 1 ? allChannels.length : prevFocused - 1;
          }
        });
      } else if (event.key === 'Enter') {
        event.preventDefault();

        if (allChannels !== undefined && focused !== undefined) {
          const channelToCheck = allChannels[focused-1];

          // if (isChannel(channelToCheck)) {
            clickOnChannel(channelToCheck)
            setActiveChannel(channelToCheck);
          // }
        }

        setFocused(undefined);
        setFocusedId('');
        setQuery('');
      }
    }
  );

  useEffect(() => {
    if (query) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown, query]);

  useEffect(() => {
    if (!query) {
      setTeamChannels([]);
      setFocused(undefined);
      setFocusedId('');
      setDirectChannels([]);
    }
  }, [query]);

  useEffect(() => {
    if (focused && focused > 0 && allChannels?.length > 0) {
      setFocusedId(allChannels[focused-1]?.id || '');
    }
  }, [allChannels, focused]);

  const getChannels = async (text) => {
    const groupFilter = business?.multi_tenant_enabled ? { type: 'team', name: { $autocomplete: text }, members: { $in: [client.user.id]}, disabled: {$eq: false}, team: { $eq: business?.subdomain } } : { type: 'team', name: { $autocomplete: text }, members: { $in: [client.user.id]}, disabled: {$eq: false} }
    try {
      const channelResponse = client.queryChannels(
        groupFilter,
        {},
        { limit: 5 },
      );

      // const userResponse = client.queryUsers(
      //   {
      //     id: { $ne: client.userID || '' },
      //     $and: [
      //       { name: { $autocomplete: text } },
      //     ],
      //   },
      //   { id: 1 },
      //   { limit: 5 },
      // );

      // const userResponse = client.queryChannels(
      //   {
      //     type: 'messaging',
      //     members: { $in: [client.user.id]},
      //     disabled: {$eq: false}
      //   },
      //   {},
      //   { limit: 5 },
      // );

      const dmFilter = (business?.multi_tenant_enabled ?
        { type: 'messaging', members: { $in: [client.user.id] }, 'member.user.name': {$autocomplete: text}, disabled: {$eq: false}, team: { $eq: business?.subdomain } }
      :
        { type: 'messaging', members: { $in: [client.user.id] }, 'member.user.name': {$autocomplete: text}, disabled: {$eq: false} }
      )
      const userResponse = client.queryChannels(
        dmFilter,
        {},
        { limit: 100 },
      );

      const [channels, users] = await Promise.all([channelResponse, userResponse]);

      let usersChannel = []
      usersChannel = users
      // if(users && users?.length > 0){
      //   usersChannel = users.filter((chnl) => {
      //     const members = Object.values(chnl.state.members).filter(
      //       ({ user }) => user.id !== client.userID
      //     );
      //     members.sort((a, b) => a.user.name.localeCompare(b.user.name, 'es', {sensitivity: 'base'}))
      //     return members[0]?.user?.name?.toLowerCase()?.includes(text?.toLowerCase())
      //   })
      // }

      // if (channels.length) 
      setTeamChannels(channels);
      // if (users.length) 
      setDirectChannels(usersChannel);
      setAllChannels([...channels, ...usersChannel]);
    } catch (event) {
      setQuery('');
      setFocused(undefined);
      setFocusedId('');
    }

    setLoading(false);
  };

  const getChannelsDebounce = debounce(val => {
		getChannels(val)
	}, 800)

  const onSearch = (evnt) => {
    setLoading(true);
    // setFocused(undefined);
    setQuery(evnt.target.value)
    if(evnt.target.value == ''){
      setFocused(undefined);
      setFocusedId('');
    }
    if (!evnt.target.value) return;
    getChannelsDebounce(evnt.target.value);
  }

  const setChannel = (channel) => {
    setQuery('');
    clickOnChannel(channel)
    setActiveChannel(channel);
    setFocused(undefined);
    setFocusedId('');
  };

  return(
    <div className='channel-search__container'>
      <div className='channel-search__input__wrapper'>
        <div className='channel-search__input__icon'>
          <SearchIcon />
        </div>
        <input
          className='channel-search__input__text'
          onChange={onSearch}
          placeholder='Search'
          type='text'
          value={query}
        />
      </div>
      {query && <ChannelSearchResult teamChannels={allChannels} focusedId={focusedId} loading={loading} setChannel={setChannel} setQuery={setQuery} directChannels={directChannels}/>}
    </div>
  )
} 