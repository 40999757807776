import React from 'react';

export default function EmptyMessageIcon({classNames}){

  return(
    <svg className={classNames} viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M103.375 58.4376V83.4376C103.375 85.0626 103.313 86.6251 103.125 88.1251C101.688 105 91.75 113.375 73.4375 113.375H70.9376C69.3751 113.375 67.875 114.125 66.9375 115.375L59.4376 125.375C56.1251 129.813 50.75 129.813 47.4375 125.375L39.9374 115.375C39.1249 114.312 37.3125 113.375 35.9375 113.375H33.4376C13.5001 113.375 3.5 108.438 3.5 83.4376V58.4376C3.5 40.1251 11.9376 30.1876 28.7501 28.7501C30.2501 28.5626 31.8126 28.5 33.4376 28.5H73.4375C93.375 28.5 103.375 38.5001 103.375 58.4376Z" stroke="#0D0D0D" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M128.375 33.4376V58.4376C128.375 76.8126 119.937 86.6876 103.125 88.1251C103.312 86.6251 103.375 85.0626 103.375 83.4376V58.4376C103.375 38.5001 93.375 28.5 73.4375 28.5H33.4375C31.8125 28.5 30.25 28.5626 28.75 28.7501C30.1875 11.9376 40.125 3.5 58.4375 3.5H98.4375C118.375 3.5 128.375 13.5001 128.375 33.4376Z" stroke="#0D0D0D" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M75.3469 73.8125H75.4031" stroke="#0D0D0D" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M53.4719 73.8125H53.5281" stroke="#0D0D0D" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.5969 73.8125H31.6531" stroke="#0D0D0D" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}