function getHeaders(token, subdomain){  
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj
  }  
}

export function getSudomain() {
  let subdomain = ''
  let host = window.location.host;
  if (host.split('.').length < 3){
    subdomain = null
  } else {
    subdomain = host.split('.')[0];
  }
  return subdomain
}

export const getDaysIndex = async (user_token, subdomain) => {    
  return await fetch(`/api/v3/availability_days/index`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const saveAvailabilityDays = async (user_token, subdomain, days, type) => {    
  return await fetch(`/api/v3/availability_days/save`,{
    headers: getHeaders(user_token, subdomain),
    method: "POST",
    body: JSON.stringify({ availability_days: days, availability_type: type })
  });  
}

export const saveAvailabilityNote = async (user_token, subdomain, note) => {    
  return await fetch(`/api/v3/availability_days/extra_info`,{
    headers: getHeaders(user_token, subdomain),
    method: "POST",
    body: JSON.stringify({ note: note })
  });  
}

export const getUsers = async (user_token, subdomain) => {    
  return await fetch(`/api/v3/availability_insights/users?all=true`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const getLocationSettings = async (user_token, subdomain) => {    
  return await fetch(`/api/v3/get_users_settings_locations`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const getClasses = async (user_token, subdomain) => {    
  return await fetch(`/api/v3/business_classes?all=true`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const getFilterData = async (user_token, subdomain) => {    
  return await fetch(`/api/v3/availability_insights/search/filter`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const saveFilterData = async (user_token, subdomain, dataToSave) => {    
  return await fetch(`/api/v3/availability_insights/search/save`,{
    headers: getHeaders(user_token, subdomain),
    method: "POST",
    body: JSON.stringify({ data: dataToSave })
  });  
}

export const savePermanentAdditionalInfo = async (user_token, subdomain, data) => {    
  return await fetch(`/api/v3/permanent_days/extra_info`,{
    headers: getHeaders(user_token, subdomain),
    method: "POST",
    body: JSON.stringify({ note: data })
  });  
}

export const getPreferredLocations = async (user_token, subdomain) => {    
  return await fetch(`/api/v3/availability_days/preferred_locations`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const snakeCase = (string) => {
  return string?.replace(/\d+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_');
}

export const getName = (role) => {
  let name = ""
  switch(role){
    case 'business_admin':
    case 'account_owner':
      name = "admin"
      break;
    case 'regional_admin':
      name = 'regional_admin'
      break;
    case 'fitness_manager':
      name = 'fitness_manager'
      break;
    case 'instructor':
      name = 'instructor'
      break;
    case 'ops':
      name = 'ops'
      break;
  }
  return name
}

export const getSkills = async (user_token, subdomain) => {    
  return await fetch(`/api/v3/business_categories`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const getFilterUsers = async (user_token, subdomain, param) => {    
  return await fetch(`/api/v3/availability_insights/all_users_list`,{
    headers: getHeaders(user_token, subdomain),
    method: "POST",
    body: JSON.stringify(param)
  });  
}

export const getSearchResult = async (user_token, subdomain, param) => {
  return await fetch(`/api/v3/availability_insights/users`,{
    headers: getHeaders(user_token, subdomain),
    method: "POST",
    body: JSON.stringify(param)
  });  
}

export const exportSearchResult = async (user_token, subdomain, param) => {
  return await fetch(`/api/v3/availability_insights/export`,{
    headers: getHeaders(user_token, subdomain),
    method: "POST",
    body: JSON.stringify(param)
  });  
}