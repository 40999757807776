import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import ManagerTdComponent from './managerTdComponent';
import ReactTooltip from "react-tooltip";
import { managerTooltipMessage } from './../../../../../helpers';

export default function ManagerTableRecordComponent(props) {
	const { schedules, netgymSchedules, newSchedules, newSubRequest, selectedSchedules, handleSelectSchedules, handleClickReopen, netgymSchedulesEventIds, eventDetailPath, business} = props
  return(
		<Fragment>
			{schedules?.map((schedule, i) =>
				<tr key={i} className={`relative ${netgymSchedules?.includes(schedule?.class_id) ? 'cursor-not-allowed bg-gray-200' : 'bg-white'}`} data-tip data-for={`${schedule?.class_id}_${i}_row_tooltip`}>
					<ManagerTdComponent schedule={schedule} newSchedules={newSchedules} newSubRequest={newSubRequest} netgymSchedules={netgymSchedules} netgymSchedulesEventIds={netgymSchedulesEventIds} selectedSchedules={selectedSchedules} handleSelectSchedules={handleSelectSchedules} handleClickReopen={handleClickReopen}/>
					<td className={`px-3 py-3 ${netgymSchedules?.includes(schedule?.class_id) ? 'opacity-50' : ''}`}>
						<div className='text-sm text-gray-900 font-medium whitespace-nowrap'>
							{schedule?.date}
						</div>
					</td>
					<td className={`px-3 py-3 ${netgymSchedules?.includes(schedule?.class_id) ? 'opacity-50' : ''}`}>
						<div className='text-sm text-gray-900 font-medium whitespace-nowrap'>
							{schedule?.time.trim()}
						</div>
					</td>
					<td className='px-3 py-3'>
						{managerTooltipMessage(schedule, netgymSchedules) !== '' &&
							<ReactTooltip className='max-w-xs' id={`${schedule?.class_id}_${i}_row_tooltip`} place="top" effect="solid">
								{managerTooltipMessage(schedule, netgymSchedules)}
							</ReactTooltip>
						}
						<div className='text-sm font-medium text-gray-900'>
							{netgymSchedules?.includes(schedule?.class_id) ?
								<Link to={eventDetailPath(netgymSchedulesEventIds[schedule?.class_id])} className='custom-blue-200 text-sm font-medium'>{schedule?.title}</Link>
								:
								<p className={`${netgymSchedules?.includes(schedule?.class_id) ? 'opacity-50' : ''}`}>{schedule?.title}</p>
							}
						</div>
					</td>
					<td className={`px-3 py-3 ${netgymSchedules?.includes(schedule?.class_id) ? 'opacity-50' : ''}`}>
						<div className='text-sm font-medium text-gray-900'>
							{schedule?.duration}
						</div>
					</td>
					{!business?.abc_integration &&
						<td className={`px-3 py-3 ${netgymSchedules?.includes(schedule?.class_id) ? 'opacity-50' : ''}`}>
							<div className='text-sm font-medium text-gray-900'>
								{schedule?.location}
							</div>
						</td>
					}
				</tr>
			)}
		</Fragment>
	)
}