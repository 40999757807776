import React, { Fragment, useState, useEffect } from "react";
import { saveSubRequestInLocalStorage, setUrlByPageNo } from './../../../helpers';

export default function SecondStepNextButtonComponent(props) {
  const { setStep, newSubRequest, user, business } = props
  const [ nextButtonDisabled, setNextButtonDisabled ] = useState(false)

  useEffect(() => {
    if(newSubRequest?.invitedInstructors?.length == 0){
      setNextButtonDisabled(true)
    }else{
      setNextButtonDisabled(false)
    }
  }, [newSubRequest])

  const handleClickOnNext = () => {
    saveSubRequestInLocalStorage(newSubRequest, 3, business?.id, user?.id)
    setStep(3)
    setUrlByPageNo(3)
  }

  return(
    <Fragment>
      <div className="flex justify-center items-center">
        <button className={`flex justify-center items-center rounded-2xl px-4 py-3 h-11 w-48 bg-black text-sm text-white font-semibold ${nextButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleClickOnNext()} disabled={nextButtonDisabled}>
          Next
        </button>
      </div>
    </Fragment>
  )
}