import React, { useState, Component } from 'react'
import { convertToRaw  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';
import { CKEditor } from 'ckeditor4-react';

class CustomOption extends Component {
  constructor(props){
    super(props);
  }
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

  render() {
    return (
      <div className='rdw-remove-wrapper'>
        <div className='rdw-option-wrapper'>
          <input className='absolute w-full h-4 p-0 -m-px overflow-hidden whitespace-nowrap border-0 bg-none outline-0 opacity-0' type="file" onChange={(e) => this.props.handleAttachments(e)}/>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
          </svg>
        </div>
      </div>
    );
  }
}

function TextEditor(props) {
  const [ editorState, setEditorState ] = useState(props.message)
  const onEditorStateChange = editorState => {
    const { onChange } = props;
    const newValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    setEditorState(editorState)
    if (onChange) {
      onChange(
        newValue
      );
    }
  };

  const config = {
    image: { uploadCallback: props.uploadCallback },
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'history'],
    inline: {
      inDropdown: false,
      options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
    },
    fontFamily: {
      options: ['Arial', 'Georgia', 'Tahoma', 'Times New Roman', 'Verdana']
    },
    link: {
      inDropdown: false,
      showOpenOptionOnHover: false,
      defaultTargetOption: '_blank',
      options: ['link', 'unlink'],
    }
  };

  const onEditorChange = (e) => {
    const editorState = e.editor.getData()
    const { onChange } = props;
    
    setEditorState(editorState)
    if (onChange) {
      onChange(
        editorState, e.editor.document.getBody().getText()?.trim()?.length > 0 ? true : false
      );
    }
  }

  const onDragAndDrop = (e) => {
    e.stop()
    const { showDragDropError } = props;
    if(showDragDropError){
      showDragDropError()
    }
  }

  const onClickedDouble = (e) => {
    if(e?.data?.dialog === "link"){
      window.setTimeout(()=> {
        if($('.cke_dialog_body').length > 0){
          let dialogBody = document.getElementsByClassName('cke_dialog_body')
          for(let i =0; i < dialogBody?.length; i++){
            let titleDiv = $(dialogBody[i]).children()[0]
            if($(titleDiv).text() == 'Link'){
              let titleDiv = $(dialogBody[i]).children()[0]
              $(titleDiv).text('Edit Link')
            }
          }
          
          let lables = document.getElementsByClassName('cke_dialog_ui_labeled_label')
          for(let i = 0; i < lables?.length; i++){
            let lable = lables[i]
            if($(lable).text() == 'Display Text'){
              $(lable).addClass('link-text-to-display-class')
              $(lable).text('Text to display')
            }else if($(lable).text() == 'URL*'){
              $(lable).addClass('link-link-to-class')
              $(lable).text('Link to')
              $(lable).css("margin-left","-135px")
            }else if($(lable).text() == 'Link Type'){
              $(lable).addClass('link-url-should-go-class')
              $(lable).text('To what URL should this link go?')
            }
          }

          let lable = document.getElementsByClassName('link-link-to-class')
          for(let i = 0; i < lable?.length; i++){
            let lbl = lable[i]
            if($(lbl).text() == 'URL'){
              $(lbl).text('Link to')
              $(lbl).css("margin-left","-135px")
            }
          }
        }else if($('.cke_dialog_body').length == 0){
          window.setTimeout(() => {
            let dialogBody = document.getElementsByClassName('cke_dialog_body')
            for(let i =0; i < dialogBody?.length; i++){
              let titleDiv = $(dialogBody[i]).children()[0]
              if($(titleDiv).text() == 'Link'){
                let titleDiv = $(dialogBody[i]).children()[0]
                $(titleDiv).text('Edit Link')
              }
            }
            
            
            let lables = document.getElementsByClassName('cke_dialog_ui_labeled_label')
            for(let i = 0; i < lables?.length; i++){
              let lable = lables[i]
              if($(lable).text() == 'Display Text'){
                $(lable).addClass('link-text-to-display-class')
                $(lable).text('Text to display')
              }else if($(lable).text() == 'URL*'){
                $(lable).addClass('link-link-to-class')
                $(lable).text('Link to')
                $(lable).css("margin-left","-135px")
              }else if($(lable).text() == 'Link Type'){
                $(lable).addClass('link-url-should-go-class')
                $(lable).text('To what URL should this link go?')
              }
            }

            let lable = document.getElementsByClassName('link-link-to-class')
            for(let i = 0; i < lable?.length; i++){
              let lbl = lable[i]
              if($(lbl).text() == 'URL'){
                $(lbl).text('Link to')
                $(lbl).css("margin-left","-135px")
              }
            }
          }, 300)
        }
      },300)
    }else{
      window.setTimeout(()=> {
        let div = document.getElementsByClassName('cke_dialog_ui_text')
        if(div?.length > 0){
          for(let i = 0; i < div?.length; i++){
            if(["Border", "HSpace", "VSpace"].includes($($($(div[i]).children()[0])[0]).text())){
              $(div[i]).css("display","none")
            }else if(["Link to"].includes($($($(div[i]).children()[0])[0]).text())){
            }else if(["Width", "Height"].includes($($($(div[i]).children()[0])[0]).text())){
              $($($(div[i]).children()[0])[0]).css("width", "45px")
            }
          }
        }else if(div?.length == 0){
          window.setTimeout(() => {
            let div = document.getElementsByClassName('cke_dialog_ui_text')
            if(div?.length > 0){
              for(let i = 0; i < div?.length; i++){
                if(["Border", "HSpace", "VSpace"].includes($($($(div[i]).children()[0])[0]).text())){
                  $(div[i]).css("display","none")
                }else if(["Link to"].includes($($($(div[i]).children()[0])[0]).text())){
                }else if(["Width", "Height"].includes($($($(div[i]).children()[0])[0]).text())){
                  $($($(div[i]).children()[0])[0]).css("width", "45px")
                }
              }
            }
          }, 300)
        }
      }, 300)
    }
  }

  const onPaste = (evt) => {
    let data = evt.data.dataValue;
    data = data.replace(/ style="[^"]*"/g, ''); // remove inline styles
    data = data.replace(/<\/?span[^>]*>/g, ''); // remove span tags
    data = data.replace(/<div>\s*<\/div>/g, ''); // Remove extra spaces around div tags
    data = data.replace(/&nbsp;/g, ''); // Basic &nbsp;
    data = data.replace(/\u00A0/g, '');// Remove &nbsp from div tags
    data = data.replace(/<div>\s*<\/div>/g, ''); // Remove extra spaces around div tags
    data = data.replace(/<div[^>]*\bclass="[^"]*"[^>]*>/g, '<div>'); // remove class from div tags
    evt.data.dataValue = data;
  };

  return (
    <div>
      <CKEditor
        initData={editorState}
        onChange={onEditorChange}
        onDrop={onDragAndDrop}
        onPaste={onPaste}
        config={{
          disableNativeSpellChecker: false,
          extraPlugins: "justify,font,colorbutton,emoji,attach,simage,imageresizerowandcolumn,SimpleLink,autolink",
          removePlugins: "elementspath,contextmenu,tabletools,tableselection,magicline",
          removeButtons: 'Templates,Save,Source,Cut,Copy,Paste,PasteText,PasteFromWord,NewPage,Preview,Print,Find,Replace,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,CopyFormatting,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Flash,Table,HorizontalRule,Iframe,ShowBlocks,Maximize,Scayt,About,Image,Link,SpecialChar',
          uploadUrl: `/api/v3/messenger/upload?upload=${false}`,
          autoCloseUpload: true,
          imageResize:{maxWidth:600, maxHeight:600},
          imageUploadURL: `/api/v3/messenger/upload?upload=${true}`,
          image_previewText: ' ',
          dataParser: function (data){
            if (data){
              return data.url
            }
          },
          fontSize_defaultLabel: '12px',
          font_defaultLabel: 'Arial',
          font_names:
                'Arial/Arial, Helvetica, sans-serif;' +
                'Georgia/Georgia, serif;' +
                'Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;' +
                'Tahoma/Tahoma, Geneva, sans-serif;' +
                'Times New Roman/Times New Roman, Times, serif;' +
                'Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;' +
                'Verdana/Verdana, Geneva, sans-serif',
          toolbarGroups: [
            { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
            { name: 'paragraph', groups: [ 'list', 'blocks', 'align', 'bidi', 'paragraph' ] },
            { name: 'styles', groups: [ 'styles' ] },
            '/',
            { name: 'colors', groups: [ 'colors' ] },
            { name: 'insert', groups: [ 'insert' ] },
            { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
            { name: 'document', groups: [ 'doctools', 'document', 'mode' ] },
            { name: 'forms', groups: [ 'forms' ] },
            { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
            { name: 'others', groups: [ 'others' ] },
            { name: 'links', groups: [ 'links' ] },
            '/',
            '/',
            { name: 'tools', groups: [ 'tools' ] },
            { name: 'about', groups: [ 'about' ] }
          ],
          allowedContent: true,
          forcePasteAsPlainText: false,
          height: 500,
        }}
        onBeforeLoad={(CKEDITOR) => {
          CKEDITOR.addCss( '.cke_editable, .cke_editable p { margin: 0 1em; }' )
          CKEDITOR.addCss( '.cke_editable, .cke_editable{ margin-top: 1em; }' )
          CKEDITOR.plugins.addExternal('attach', '/assets/ckeditor/plugins/attach/plugin.js');
          CKEDITOR.plugins.addExternal('emoji', '/assets/ckeditor/plugins/emoji/plugin.js');
          CKEDITOR.plugins.addExternal('simage', '/assets/ckeditor/plugins/simage_1.3/reactPlugin.js');
          CKEDITOR.plugins.addExternal('imageresizerowandcolumn', '/assets/ckeditor/plugins/imageresizerowandcolumn/plugin.js');
          CKEDITOR.plugins.addExternal('SimpleLink', '/assets/ckeditor/plugins/simpleLink/plugin.js');
          CKEDITOR.plugins.addExternal('autolink', '/assets/ckeditor/plugins/autolink/plugin.js');
        }}
        onDoubleclick={onClickedDouble}
        
      />
      <div className='hidden'>
        <input type="file" id="file_upload_id" onChange={(e) => props.handleAttachments(e)} disabled={props.disableAttachBtn} multiple/>
      </div>
    </div>
  )
}

export default TextEditor
