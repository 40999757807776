import React, { Fragment, useState } from "react";
import { axiosRequest } from './../../../../../subRequests/helpers/helpers';
import CustomPopupComponent from './../../../../../subRequests/pages/components/customPopupComponent/customPopupComponent';

export default function ApproveWithoutSyncComponent(props) {
  const { eventData, handleSetAlert, closeApproveWithoutSyncPopup, handleUpdateEvent, showApproveWithoutSyncPopup, confirmationMessage, approvedUserId, actionUrl } = props
  const [ disableContinueBtn, setDisableContinueBtn ] = useState(false)

  const handleContinueConfirm = () => {
    setDisableContinueBtn(true)
    let url = actionUrl
    let params = {
      key: 'approve_without_sync',
      user_id: approvedUserId,
      preview: false
    }
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if(result?.status === 200){
        setDisableContinueBtn(false);
        handleSetAlert(true, result?.success, 'success');
        closeApproveWithoutSyncPopup(false);
        handleUpdateEvent(result?.event)
      }else{
        setDisableContinueBtn(false);
        handleSetAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleSetAlert(false, '', ''); 
      }, 3000)
    })
  }

  return(
    <Fragment>
      <CustomPopupComponent show={showApproveWithoutSyncPopup} close={closeApproveWithoutSyncPopup} title={'Approve Sub Request'}>
        <div className="px-4 pt-3 font-open-sans">
          <div className='text-sm px-2 font-semibold text-gray-700 mt-3 text-center'>
            {confirmationMessage}
          </div>
          <div className="mt-5 flex gap-3 flex-row-reverse">
            <button 
              className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-40 py-2 text-sm font-semibold ${disableContinueBtn ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => handleContinueConfirm()}
              disabled={disableContinueBtn}
            >
              Confirmed!
            </button>
            <button 
              className={`bg-white border rounded-xl text-gray-800 flex items-center justify-center w-40 py-2 text-sm hover:bg-gray-100 font-semibold shadow`}
              onClick={() => closeApproveWithoutSyncPopup(false)}
            >
              Opps, never mind!
            </button>
          </div>
        </div>
      </CustomPopupComponent>
    </Fragment>
  )
}