import React, { Component } from "react";
import NotificationSettingsShow from './notificationSettings/notificationSettingsShow'
import NotificationSettingsEdit from './notificationSettings/notificationSettingsEdit'
import SubRequestLocationsShow from './subRequestLocations/subRequestLocationsShow'
import SubRequestLocationsEdit from './subRequestLocations/subRequestLocationsEdit'
import { axiosGetRequest, axiosRequest, checkReloadUserAvailable, removeReloaduser } from '../../helpers'
import AlertPopup from './../../../messenger/pages/chatComponents/assets/alertPopup'
import ContactInfo from './contactInformation'
import ContactInfoEdit from './contactInformation/contactInfoEdit'

export default class Notifications extends Component {
  constructor(props){
    super(props);
    this.state = {
      editNotificationSettings: false,
      editSubRequestLocations: false,
      subRequestRegionAndLocations: {},
      loaded: false,
      alert_message: '',
      alert_type: '',
      show: false
    }
  }

  componentDidMount(){
    this.getRegionsLocations()
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  setEditNotificationSettings = (data) => {
    this.setState({
      editNotificationSettings: data
    })
    let reload = checkReloadUserAvailable()
    if (reload != '' && (reload === 'true' || reload === true)){
      this.props.getCurrentUser()
      removeReloaduser()
    }
  }

  setEditSubRequestLocations = (data) => {
    this.setState({
      editSubRequestLocations: data
    })
  }

  getRegionsLocations = () => {
    axiosGetRequest("/api/v3/my_profile/get_locations_and_regions").then(result => {
      if(result.status === 200){
        this.setState({
          subRequestRegionAndLocations: result,
          loaded: true
        })
      }
    })
  }

  setSubRequestLocations = (data) => {
    axiosRequest('/api/v3/my_profile/save_locations', 'POST', data, 'data').then(result => {
			if(result.status === 200){
        this.setState({
          alert_message: result?.message,
          alert_type: 'success',
          show: true,
          editSubRequestLocations: false,
        }, () => {
          this.props.getUserData()
          window.setTimeout(()=> {
            this.setState({show: false})
          }, 3000)
          this.getRegionsLocations()
        })
			}else{
			}
		})
  }

  setNotificationSettings = (data) => {
    axiosRequest('/api/v3/my_profile/update_notifications_settings', 'POST', data, 'data').then(result => {
			if(result.status === 200){
        this.setState({
          alert_message: result?.message,
          alert_type: 'success',
          show: true,
          editNotificationSettings: false,
        }, () => {
          this.processUpdateUserData(result?.user)
          window.setTimeout(()=> {
            this.setState({show: false})
          }, 3000)
        })
			}else{
			}
		})
  }

  processUpdateUserData = (data) => {
    let reload = checkReloadUserAvailable()
    this.props.handleUpdateUser(data)
    if(reload != '' && (reload === 'true' || reload === true)){
      removeReloaduser()
    }
  }

  showPopup = (show, alert_type, alert_message) => {
    this.setState({
      alert_message: alert_message,
      alert_type: alert_type,
      show: show
    })
  }

  render(){
    const { editNotificationSettings, editSubRequestLocations, subRequestLocations, subRequestRegionAndLocations, alert_message, alert_type, show, showPhoneInDirectory } = this.state
    const { user, business } = this.props
    
    return(
      <React.Fragment>
        {show &&
          <AlertPopup alert_type={alert_type} alert_message={alert_message} closeAlert={this.closeAlert}/>
        }
        {editNotificationSettings ?
          <NotificationSettingsEdit user={user} setEditNotificationSettings={this.setEditNotificationSettings} setNotificationSettings={this.setNotificationSettings} showPopup={this.showPopup}/>
          :
          <NotificationSettingsShow user={user} setEditNotificationSettings={this.setEditNotificationSettings} />
        }
        {((user.role !== 'Ops' && user.role !== 'Fitness Instructor') || (user.role === 'Fitness Instructor' && user?.can_instructor_modify_locations)) &&
          <React.Fragment>
            {editSubRequestLocations ?
              <SubRequestLocationsEdit subRequestRegionAndLocations={subRequestRegionAndLocations} setEditSubRequestLocations={this.setEditSubRequestLocations} setSubRequestLocations={this.setSubRequestLocations}/>
            :
              <SubRequestLocationsShow user={user} setEditSubRequestLocations={this.setEditSubRequestLocations} subRequestLocations={this.props.userSettingsLocation || {}}/>
            }
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}