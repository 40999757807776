import React, { Fragment, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { getPopupHeadingText, eventsOfTheDay, countEventsWithError, formatDateToFindDiv, appendApprovalErrorSpan } from './../../../../helpers/helpers'
import MonthViewEventComponent from './../../monthViewEventComponent/monthViewEventComponent'

export default function MonthViewCalendarComponent(props) {
  const { events, calendarRef, currentDate, handleEventClick, showAllEvents } = props
  const [ dayMaxEventRowsCount, setDayMaxEventRowsCount ] = useState(showAllEvents ? 10000 : 4)

  useEffect(() => {
    if(showAllEvents){
      setDayMaxEventRowsCount(10000)
    }else{
      setDayMaxEventRowsCount(4)
    }
  }, [showAllEvents])

  const handleMoreLinkText = (arg) => {
    let dateInToDateStringFormat = $(arg?.el).parent().parent().parent().parent()[0].dataset?.date;
    let dateInToDateStringFormatDate =  new Date(dateInToDateStringFormat)
    const eventsForDay = eventsOfTheDay(arg, dateInToDateStringFormatDate)
    if (eventsForDay.length > 3){
      const numEventsWithError = countEventsWithError(eventsForDay)
      if (numEventsWithError > 0) {
        let formattedDate = formatDateToFindDiv(dateInToDateStringFormatDate)
        appendApprovalErrorSpan(formattedDate, numEventsWithError)
      }
    }
  }

  return(
    <Fragment>
      <FullCalendar
        ref={calendarRef}
        height = {'auto'}
        plugins={[dayGridPlugin]}
        initialView='dayGridMonth'
        initialDate={currentDate}
        weekends={true}
        events={events}
        eventContent={MonthViewEventComponent}
        eventTimeFormat= { {
          hour12: true,
          hour: 'numeric',
          minute: 'numeric',
          meridiem: true
        } }
        dayMaxEventRows={dayMaxEventRowsCount}
        dayPopoverFormat={{ weekday: 'short', day: 'numeric', month: 'short' }}
        moreLinkClassNames={'link-more-text-color font-medium capitalize'}
        moreLinkClick={() => {getPopupHeadingText()}}
        eventClick={(info) => {handleEventClick(info)}}
        fixedWeekCount={false}
        moreLinkDidMount={(arg) => handleMoreLinkText(arg)}
      />
    </Fragment>
  )
}