import React, { Fragment } from "react";
import DateTimeDropDownComponent from './dateTimeDropDownComponent';

export default function DateTimeSelectorComponent(props) {
  const { i, newClass, handleSaveDateTime, businessDateFormat } = props

  return(
    <Fragment>
      <div className="flex flex-col gap-2 w-full">
        <div className={`flex ${i > 0 ? 'lg:hidden' : ''} text-base md:text-lg text-black font-semibold`}>
            Date
          </div>
        <div className="flex items-center gap-2">
          <DateTimeDropDownComponent
            i={i}
            eventDateTime={newClass?.selectedDateTime?.eventDate} 
            eventStartDateTime={newClass?.selectedDateTime?.startTime} 
            eventEndDateTime={newClass?.selectedDateTime?.endTime}
            handleSaveDateTime={(eventDate, startTime, endTime) => handleSaveDateTime(eventDate, startTime, endTime, i)}
            newClass={newClass}
            businessDateFormat={businessDateFormat}
          />
        </div>
      </div>
    </Fragment>
  )
}