import React, { Fragment } from "react";

export default function TrashIcon({classNames, colour}){
  return(
    <Fragment>
      <svg className={classNames} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.289 3.05067C11.3498 2.95734 10.4107 2.88734 9.46568 2.83484V2.829L9.33735 2.07067C9.24985 1.534 9.12151 0.729004 7.75651 0.729004H6.22818C4.86901 0.729004 4.74068 1.499 4.64735 2.06484L4.52485 2.8115C3.98235 2.8465 3.43985 2.8815 2.89735 2.934L1.70735 3.05067C1.46235 3.074 1.28735 3.28984 1.31068 3.529C1.33401 3.76817 1.54401 3.94317 1.78901 3.91984L2.97901 3.80317C6.03568 3.49984 9.11568 3.6165 12.2073 3.92567C12.2248 3.92567 12.2365 3.92567 12.254 3.92567C12.4757 3.92567 12.6682 3.7565 12.6915 3.529C12.709 3.28984 12.534 3.074 12.289 3.05067Z" fill={`${colour ? colour : "#292D32"}`}/>
        <path d="M11.2165 4.7485C11.0765 4.60266 10.884 4.521 10.6857 4.521H3.31237C3.11404 4.521 2.91571 4.60266 2.78154 4.7485C2.64737 4.89433 2.57154 5.09266 2.58321 5.29683L2.94487 11.2818C3.00904 12.1685 3.09071 13.2768 5.12654 13.2768H8.87154C10.9074 13.2768 10.989 12.1743 11.0532 11.2818L11.4149 5.30266C11.4265 5.09266 11.3507 4.89433 11.2165 4.7485ZM7.96737 10.3543H6.02487C5.78571 10.3543 5.58737 10.156 5.58737 9.91683C5.58737 9.67766 5.78571 9.47933 6.02487 9.47933H7.96737C8.20654 9.47933 8.40487 9.67766 8.40487 9.91683C8.40487 10.156 8.20654 10.3543 7.96737 10.3543ZM8.45737 8.021H5.54071C5.30154 8.021 5.10321 7.82266 5.10321 7.5835C5.10321 7.34433 5.30154 7.146 5.54071 7.146H8.45737C8.69654 7.146 8.89487 7.34433 8.89487 7.5835C8.89487 7.82266 8.69654 8.021 8.45737 8.021Z" fill={`${colour ? colour : "#292D32"}`}/>
      </svg>
    </Fragment>
  )
}