import React, { Fragment, useState } from "react";
import { axiosRequest } from "../../../../subRequests/helpers/helpers";
import UploaderIcon from './uploaderIcon';

export default function PicUploaderComponent(props){
  const { user, setAlert, setBtnDisabled } = props
  const [ currentUser, setCurrentUser ] = useState(user)
  const [ profileImage, setProfileImage ] = useState('')
  const [ imageUploading, setImageUploading ] = useState(false)

  const handleUploadProfileImage = (e) => {
    if(e.target.files && e.target.files?.length > 0){     
      setProfileImage(e.target.files[0])
      uploadProfilePic(e.target.files[0])
    }
  }

  const uploadProfilePic = (data) => {
    const formData = new FormData()
    formData.append('file', data);
    formData.append('filename', data?.name);
    let url = '/api/v3/my_profile/update_user'
    setImageUploading(true)
    setBtnDisabled(true)
    axiosRequest(url, 'POST', formData, 'content').then(result => {
      setImageUploading(false)
      setBtnDisabled(false)
			if(result.status === 200){
        setAlert(true, 'success', result?.message)
        setCurrentUser(result?.user)
        setProfileImage('')
        setTimeout(()=>{
          setAlert(false, '', '')
        }, 3000)
			}else{
        setAlert(true, 'danger', result?.error)
        setTimeout(()=>{
          setAlert(false, '', '')
        }, 3000)
			}
		})
  }

  const onMouseEnter = (e) => {
    $(`.image-container`).addClass('opacity-75')
  }

  const onMouseLeave = (e) => {
    $(`.image-container`).removeClass('opacity-75')
  }

  return(
    <Fragment>
      <div className="relative group custom-w-h-200 rounded-full border-4 border-gray-300">
        {profileImage === "" ? 
          <img src={currentUser?.image} className="w-full h-full image-container rounded-full"/>
        :
          <React.Fragment>
            <img className={`max-w-full rounded-full image-container ${imageUploading ? 'opacity-50' : ''}`} style={{width: '100%', height: '100%', flexBasis: `${50}px`, objectFit: 'cover',}} src={URL.createObjectURL(profileImage)}/>
            {imageUploading &&
              <div className="flex w-full h-full items-center left-0 top-0 justify-center absolute">
                <svg className="animate-spin h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            }
          </React.Fragment>
        }
        {!imageUploading &&
          <label htmlFor="dropzone-file" className="flex items-center left-0 right-0 top-0 bottom-0 justify-center absolute m-0 rounded-full cursor-pointer opacity-0 group-hover:opacity-100" onMouseEnter={(e) => onMouseEnter(e)} onMouseLeave={(e) => onMouseLeave(e)}>
            <input id="dropzone-file" type="file" className={`hidden absolute w-full h-full rounded-full top-0 left-0 bottom-0 opacity-0 cursor-pointer sr-onl`} accept=".jpg, .jpeg, .png" onChange={(e) => handleUploadProfileImage(e)} disabled={imageUploading}></input>
            <div className="bg-white h-8 w-8 rounded-md flex items-center justify-center border border-gray-400">
              <UploaderIcon classNames={'h-5 w-5'}/>
            </div>
          </label>
        }
      </div>
    </Fragment>
  )
}