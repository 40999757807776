import React, { useState, useEffect, useRef, Fragment } from "react";
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import CustomPopupComponent from '../../../subRequests/pages/components/customPopupComponent/customPopupComponent';

export function AttendanceUpdatePopupComponent(props) {
  const { show, close, cls, handleUpdateAttendance, disableConfirmBtn } = props;
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const generateNumberArray = (max) => {
    return Array.from({ length: max + 1 }, (_, index) => index);
  };
  const [maxAttendees, setMaxAttendees] = useState(generateNumberArray(cls?.max_attendees))
  const [ selectAttendees, setSelectAttendees ] = useState('')
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (val) => {
    setSelectAttendees(val);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && buttonRef.current && !menuRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  
  useEffect(() => {
    $(".attendees-count").on("click", function() {
      const updatePopup = $(this).closest('.updateAttancePopup');
      if (updatePopup.hasClass('overflow-hidden')) {
        updatePopup.removeClass('overflow-hidden');
      }
    });

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return(
    <CustomPopupComponent show={show} close={close} title={''}>
      <div className="px-4 pt-3 font-open-sans">
        <div className="flex flex-col justify-center mt-4">
          <span className="text-center font-semibold text-base font-open-sans">{`How many people attended ${cls?.title} @ ${cls?.business_location?.name} on ${cls?.day_display} ${cls?.date_display} at ${cls?.start_time} ${cls?.subrequest_timezone}?`}</span>
        </div>
        <div className="w-20 mx-auto my-8 attendees-count">
          <div className="relative filters_box"> 
            <Menu as="div" className="relative block text-left" ref={menuRef}>
              <div>
                <Menu.Button
                  ref={buttonRef}
                  onClick={() => setIsOpen(!isOpen)}
                  className="flex justify-center w-full rounded-xl pl-4 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 class_items_button border-2 border-transparent filter-apply-border need-border font-medium"
                >
                  {selectAttendees}
                  <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                show={isOpen}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right z-30 py-2 right-0 w-full rounded-2xl bg-white shadow-md ring-2 ring-gray-500 focus:outline-none mt-2 absolute">
                  <div className="py-2 max-h-60 overflow-y-auto mr-0.5">
                    <div>
                      {maxAttendees.map((val, i) => (
                        <Menu.Item key={i}>
                          {({ active }) => (
                            <a
                              className={`flex items-center justify-between px-3 text-gray-900 text-sm mx-2 py-2 rounded-md cursor-pointer`}
                              onClick={() => handleSelect(val)}
                            >
                              {val}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <div className="mt-4 flex gap-3 flex-row-reverse mx-10">
          <button 
            className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-full py-2 text-sm font-semibold h-11 ${(disableConfirmBtn) ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={(e) => handleUpdateAttendance(e, cls, selectAttendees)}
            disabled={disableConfirmBtn || (selectAttendees === '')}
          >
            Confirm
          </button>
          <button 
            className={`bg-white border rounded-xl text-gray-800 flex items-center justify-center w-full py-2 text-sm hover:bg-gray-100 font-semibold shadow h-11 ${(disableConfirmBtn) ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={(e) => handleUpdateAttendance(e, cls, '')}
            disabled={disableConfirmBtn}
          >
            Didn't Teach
          </button>
        </div>
      </div>
    </CustomPopupComponent>
  )
}