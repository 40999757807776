import React, { Fragment, Component } from 'react'
import Pagination from "react-js-pagination";

export default class Behavior extends Component{

  constructor(props){
    super(props)
    this.state = {
      activePage: 1,
      pageSize: 20,
      sortType: 'total',
      total: 0,
      sortReverse: true,
      behaviourData: [],
      approvalRequestCount: 0,
      noShowCount: 0,
      totalSubrequestCount: 0,
      loadTableData: true,
      baseCount: 0,
      behaviorCol1Part1: 0,
      behaviorCol1Part2: 1,
      behaviorCol2Part1: 1,
      behaviorCol2Part2: 3,
      behaviorCol3Part1: 3,
      behaviorCol3Part2: 7,
      behaviorCol4: 7
    }
  }

  componentDidMount(){
    
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    this.setState({
      activePage: parseInt(nextProps.behaviourAllData?.page),
      pageSize: parseInt(nextProps.behaviourAllData?.page_size),
      // sortType: 'total',
      // sortReverse: true,
      baseCount: (parseInt(nextProps.behaviourAllData?.page)-1)*parseInt(nextProps.behaviourAllData?.page_size),
      behaviourData: nextProps.behaviourAllData?.results || [],
      total: nextProps.behaviourAllData?.total || 0,
      approvalRequestCount: nextProps.behaviourAllData?.approval_pertg || 0,
      noShowCount: nextProps.behaviourAllData?.no_shows || 0,
      totalSubrequestCount: nextProps.behaviourAllData?.events_total || 0,
      behaviorCol1Part1: nextProps?.behaviorTheCol1Part1 || 0,
      behaviorCol1Part2: nextProps?.behaviorTheCol1Part2 || 1,
      behaviorCol2Part1: nextProps?.behaviorTheCol2Part1 || 1,
      behaviorCol2Part2: nextProps?.behaviorTheCol2Part2 || 3,
      behaviorCol3Part1: nextProps?.behaviorTheCol3Part1 || 3,
      behaviorCol3Part2: nextProps?.behaviorTheCol3Part2 || 7,
      behaviorCol4: nextProps?.behaviorTheCol4 || 7,
      loadTableData: nextProps.loadTableData
    })
  }

  componentWillUnmount(){
    this.setState = (state,callback)=>{
      return;
    };
  }

  handleClickOnName = (id, name) => {
    this.props.handleBehaviourUserClick(id, name )
  }

  handleSort = (e, param, type) => {
    e.preventDefault()
    let sort_desc = false
    if(type === 'asc'){
      sort_desc = false
    }else{
      let sortRev =  this.state.sortReverse
      if(sortRev === true){
        sort_desc = false
      }
      else{
        sort_desc = true
      }
    }
    this.setState({
      sortType: param,
      sortReverse: sort_desc
    })
    this.props.handleFetchSortBehaviourData(this.state.activePage, this.state.pageSize, param, sort_desc)
  }

  handlePageChange = (pageNumber) => {
    this.setState({activePage: pageNumber})
    this.props.handleFetchSortBehaviourData(pageNumber, this.state.pageSize, this.state.sortType, this.state.sortReverse)
  }

  render(){
    const { activePage, pageSize, sortType, sortReverse, behaviourData, total, approvalRequestCount, noShowCount, totalSubrequestCount, loadTableData, baseCount, behaviorCol1Part1, behaviorCol1Part2, behaviorCol2Part1, behaviorCol2Part2, behaviorCol3Part1, behaviorCol3Part2, behaviorCol4} = this.state
    return(
      <Fragment>
        {/* <header>
          <h1 className='text-2xl font-semibold leading-tight text-gray-900 pb-5'>Behavior</h1>
        </header> */}
        <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
          <div className="flex items-center h-24">
            <div className='flex w-24 h-full flex-shrink-0 items-center justify-center bg-blue-500 rounded-l-md text-sm font-medium text-white'>
            <svg className="w-10 h-10" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.75 1V3.25M15.25 1V3.25M1 16.75V5.5C1 4.90326 1.23705 4.33097 1.65901 3.90901C2.08097 3.48705 2.65326 3.25 3.25 3.25H16.75C17.3467 3.25 17.919 3.48705 18.341 3.90901C18.7629 4.33097 19 4.90326 19 5.5V16.75M1 16.75C1 17.3467 1.23705 17.919 1.65901 18.341C2.08097 18.7629 2.65326 19 3.25 19H16.75C17.3467 19 17.919 18.7629 18.341 18.341C18.7629 17.919 19 17.3467 19 16.75M1 16.75V9.25C1 8.65326 1.23705 8.08097 1.65901 7.65901C2.08097 7.23705 2.65326 7 3.25 7H16.75C17.3467 7 17.919 7.23705 18.341 7.65901C18.7629 8.08097 19 8.65326 19 9.25V16.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M7.5 15.5L12.5 10.5M7.5 10.5L12.5 15.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <div className='flex h-full flex-1 items-center justify-between truncate rounded-r-md border-gray-200 bg-blue-400'>
              <div className=' px-4 py-2 text-sm'>
                <div className='text-white text-2xl'>{noShowCount}</div>
                <div className='text-white'>NO SHOWS</div>
              </div>
            </div>
          </div>
          <div className="flex items-center h-24">
            <div className='flex w-24 h-full flex-shrink-0 items-center justify-center bg-purple-500 rounded-l-md text-sm font-medium text-white'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12">
              <path fillRule="evenodd" d="M14.47 2.47a.75.75 0 011.06 0l6 6a.75.75 0 010 1.06l-6 6a.75.75 0 11-1.06-1.06l4.72-4.72H9a5.25 5.25 0 100 10.5h3a.75.75 0 010 1.5H9a6.75 6.75 0 010-13.5h10.19l-4.72-4.72a.75.75 0 010-1.06z" clipRule="evenodd" />
              </svg>

            </div>
            <div className='flex h-full flex-1 items-center justify-between truncate rounded-r-md border-gray-200 bg-purple-400'>
              <div className=' px-4 py-2 text-sm'>
                <div className='text-white text-2xl'>{totalSubrequestCount}</div>
                <div className='text-white'>SUB REQUESTS</div>
              </div>
            </div>
          </div>
          <div className="flex items-center h-24">
            <div className='flex w-24 h-full flex-shrink-0 items-center justify-center bg-green-500 rounded-l-md text-sm font-medium text-white'>
              <svg className="w-12 h-12" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.75 3V5.25M17.25 3V5.25M3 18.75V7.5C3 6.90326 3.23705 6.33097 3.65901 5.90901C4.08097 5.48705 4.65326 5.25 5.25 5.25H18.75C19.3467 5.25 19.919 5.48705 20.341 5.90901C20.7629 6.33097 21 6.90326 21 7.5V18.75M3 18.75C3 19.3467 3.23705 19.919 3.65901 20.341C4.08097 20.7629 4.65326 21 5.25 21H18.75C19.3467 21 19.919 20.7629 20.341 20.341C20.7629 19.919 21 19.3467 21 18.75M3 18.75V11.25C3 10.6533 3.23705 10.081 3.65901 9.65901C4.08097 9.23705 4.65326 9 5.25 9H18.75C19.3467 9 19.919 9.23705 20.341 9.65901C20.7629 10.081 21 10.6533 21 11.25V18.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9 14.9777L11.4 17.2L15 12.2" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <div className='flex h-full flex-1 items-center justify-between truncate rounded-r-md border-gray-200 bg-green-400'>
              <div className=' px-4 py-2 text-sm'>
                <div className='text-white text-2xl'>{approvalRequestCount}%</div>
                <div className='text-white'>APPROVED REQUESTS</div>
              </div>
            </div>
          </div>
          
        </div>
        {!loadTableData &&
          <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
            <img className = "w-9" src="/assets/business/loading.gif" />
          </div>
        }
        {loadTableData &&
          <div className='overflow-x-auto overflow-y-hidden sm:-mx-6 lg:-mx-8 my-4'>
            <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 min-h300'>
              <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                        <div className='flex items-center'>
                          <span className='cursor-pointer whitespace-nowrap text-sm'>Name</span> &nbsp; &nbsp;
                          {sortType !== 'full_name' &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'full_name', 'desc')}>
                              <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                            </svg>
                          }
                          {(sortType === 'full_name' && sortReverse) &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'full_name', 'asc')}>
                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                            </svg>
                          }
                          {(sortType === 'full_name' && !sortReverse) &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'full_name', 'desc')}>
                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                            </svg>
                          }
                        </div>
                      </th>
                      <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                        <div className='flex items-center'>
                          <span className='cursor-pointer text-sm whitespace-nowrap'>Total Sub Requests</span>&nbsp; &nbsp;
                          {sortType !== 'total' &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'total', 'desc')}>
                              <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                            </svg>
                          }
                          {(sortType === 'total' && sortReverse) &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'total', 'asc')}>
                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                            </svg>
                          }
                          {(sortType === 'total' && !sortReverse) &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'total', 'desc')}>
                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                            </svg>
                          }
                        </div>
                      </th>
                      <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                        <div className='flex items-center'>
                          <span className='cursor-pointer text-sm whitespace-nowrap'>Total Re-opened Sub Requests</span>&nbsp; &nbsp;
                          {sortType !== 'reopened_event' &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'reopened_event', 'desc')}>
                              <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                            </svg>
                          }
                          {(sortType === 'reopened_event' && sortReverse) &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'reopened_event', 'asc')}>
                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                            </svg>
                          }
                          {(sortType === 'reopened_event' && !sortReverse) &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'reopened_event', 'desc')}>
                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                            </svg>
                          }
                        </div>
                      </th>
                      <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                        <div className='flex items-center'>
                          <div className='cursor-pointer text-sm'>{`${behaviorCol1Part1} to ${behaviorCol1Part2} Days Notice`}
                          <div className='text-xs whitespace-nowrap'>(prior to class start time)</div></div>&nbsp; &nbsp;
                          {sortType !== 'day1' &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'day1', 'desc')}>
                              <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                            </svg>
                          }
                          {(sortType === 'day1' && sortReverse) &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'day1', 'asc')}>
                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                            </svg>
                          }
                          {(sortType === 'day1' && !sortReverse) &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'day1', 'desc')}>
                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                            </svg>
                          }
                        </div>
                      </th>
                      <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                        <div className='flex items-center'>
                          <div className='cursor-pointer text-sm'>{`${behaviorCol2Part1} to ${behaviorCol2Part2} Days Notice`}
                          <div className='text-xs whitespace-nowrap'>(prior to class start time)</div>
                          </div>&nbsp; &nbsp;
                          {sortType !== 'day13' &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'day13', 'desc')}>
                              <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                            </svg>
                          }
                          {(sortType === 'day13' && sortReverse) &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'day13', 'asc')}>
                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                            </svg>
                          }
                          {(sortType === 'day13' && !sortReverse) &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'day13', 'desc')}>
                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                            </svg>
                          }
                        </div>
                      </th>
                      <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                        <div className='flex items-center'>
                          <div className='cursor-pointer text-sm'>{`${behaviorCol3Part1} to ${behaviorCol3Part2} Days Notice`}
                          <div className='text-xs whitespace-nowrap'>(prior to class start time)</div>
                          </div>&nbsp; &nbsp;
                          {sortType !== 'day37' &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'day37', 'desc')}>
                              <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                            </svg>
                          }
                          {(sortType === 'day37' && sortReverse) &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'day37', 'asc')}>
                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                            </svg>
                          }
                          {(sortType === 'day37' && !sortReverse) &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'day37', 'desc')}>
                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                            </svg>
                          }
                        </div>
                      </th>
                      <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                        <div className='flex items-center'>
                          <div className='cursor-pointer text-sm'><span className='text-xs'>ᐳ</span>{`${behaviorCol4} Days Notice`}
                          <div className='text-xs whitespace-nowrap'>(prior to class start time)</div>
                          </div>&nbsp; &nbsp;
                          {/* <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor"><path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z"></path></svg> */}
                          {sortType !== 'day7' &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'day7', 'desc')}>
                              <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                            </svg>
                          }
                          {(sortType === 'day7' && sortReverse) &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'day7', 'asc')}>
                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                            </svg>
                          }
                          {(sortType === 'day7' && !sortReverse) &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'day7', 'desc')}>
                              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                            </svg>
                          }
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200'>
                    {behaviourData.map((user, i) => 
                      <tr key={i} className='bg-white'>
                        <td className='px-3 py-4'>
                          <div className='text-sm text-center'>
                            <a className='text-blue-600 cursor-pointer' onClick={() => this.handleClickOnName(user?.id, user?.full_name)}>{user?.full_name}</a>  
                          </div>
                        </td>
                        <td className='px-3 py-4'>
                          <div className='text-sm text-gray-500 text-center'>
                            {user?.total}
                          </div>
                        </td>
                        <td className='px-3 py-4'>
                          <div className='text-sm text-gray-500 text-center'>
                            {user?.reopened_event}
                          </div>
                        </td>
                        <td className='px-3 py-4'>
                          <div className='text-sm text-gray-500 text-center'>
                            {user?.day1}
                          </div>
                        </td>
                        <td className='px-3 py-4'>
                          <div className='text-sm text-gray-500 text-center'>
                            {user?.day13}
                          </div>
                        </td>
                        <td className='px-3 py-4'>
                          <div className='text-sm text-gray-500 text-center'>
                            {user?.day37}
                          </div>
                        </td>
                        <td className='px-3 py-4'>
                          <div className='text-sm text-gray-500 text-center'>
                            {user?.day7}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="py-3 mt-2 flex items-center justify-between">
                <div className='flex justify-between w-full flex-wrap'>
                  <div>
                    <p className='text-sm text-gray-700'>
                      {`Showing ${total == 0 ? total : baseCount+1} to ${activePage*pageSize > total ? total : activePage*pageSize} of ${total} results`}
                    </p>
                  </div>
                </div>
                <div className="custom-pagination order-first sm:order-last mb-3 sm:mb-0">
                  {total > pageSize && 
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={pageSize}
                      totalItemsCount={total}
                      pageRangeDisplayed={9}
                      onChange={this.handlePageChange.bind(this)}
                      itemClassPrev='previous'
                      itemClassNext='next'
                      itemClassFirst='first'
                      itemClassLast='last'
                      hideDisabled={true}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </Fragment>
    )
  }
}