import React from 'react';
import { ActionsIcon as DefaultActionsIcon, ReactionIcon as DefaultReactionIcon, ThreadIcon as DefaultThreadIcon, MESSAGE_ACTIONS, showMessageActionsBox, MessageContextValue, useMessageContext, DefaultStreamChatGenerics, IconProps, useChatContext } from 'stream-chat-react';

import MessageActions from './../messageActionsWrapper/messageActions'
import { snakeCase } from '../../../../../helpers'
const UnMemoizedMessageOptions = (props) => {
  const { ActionsIcon = DefaultActionsIcon, displayReplies = true, handleOpenThread: propHandleOpenThread, messageWrapperRef, ReactionIcon = DefaultReactionIcon, theme = 'simple', ThreadIcon = DefaultThreadIcon, } = props;

  const { client, channel } = useChatContext()

  const { customMessageActions, getMessageActions, handleOpenThread: contextHandleOpenThread, initialMessage, message, onReactionListClick, threadList, } = useMessageContext('MessageOptions');

  const handleOpenThread = propHandleOpenThread || contextHandleOpenThread;

  const messageActions = getMessageActions();
  const showActionsBox =
    showMessageActionsBox(messageActions, threadList) || !!customMessageActions;

  const shouldShowReactions = messageActions.indexOf(MESSAGE_ACTIONS.react) > -1;
  const shouldShowReplies =
    messageActions.indexOf(MESSAGE_ACTIONS.reply) > -1 && displayReplies && !threadList;

  if (
    !message.type ||
    message.type === 'error' ||
    message.type === 'system' ||
    message.type === 'ephemeral' ||
    message.status === 'failed' ||
    message.status === 'sending' ||
    initialMessage
  ) {
    return null;
  }

  const rootClassName = `str-chat__message-${theme}__actions str-chat__message-options`;

  const checkMessageActionsPermission = () => {
    const clientRole  = client.user?.user_role
    const messageUserRole = message.user?.user_role
    if(channel?.type === 'team'){
      let userRole = channel.data?.acknowledged_roles?.map(role => {return snakeCase(role)}) || [client.user?.user_role]
      
      if(!userRole.includes(clientRole)){
        return false
      }
    }

    if(message.user?.id == client.user?.id){
      return true
    }else{
      // if(clientRole == 'business_admin' && ['business_admin', 'regional_admin', 'fitness_manager', 'instructor'].includes(messageUserRole)){
      //   return true
      // }else if(clientRole == 'regional_admin' && ['regional_admin', 'fitness_manager', 'instructor'].includes(messageUserRole)){
      //   return true
      // }else if(clientRole == 'fitness_manager' && ['fitness_manager', 'instructor'].includes(messageUserRole)){
      //   return true
      // }else{
      //   return false
      // }
      return true
    }
  }

  return (
    <div className={rootClassName} data-testid='message-options'>
      {(showActionsBox && checkMessageActionsPermission()) && (
        <MessageActions ActionsIcon={ActionsIcon} messageWrapperRef={messageWrapperRef} />
      )}
      {shouldShowReplies && (
        <button
          aria-label='Open Thread'
          className={`str-chat__message-${theme}__actions__action str-chat__message-${theme}__actions__action--thread str-chat__message-reply-in-thread-button`}
          data-testid='thread-action'
          onClick={handleOpenThread}
        >
          <ThreadIcon className='str-chat__message-action-icon' />
        </button>
      )}
      {shouldShowReactions && (
        <button
          aria-label='Open Reaction Selector'
          className={`str-chat__message-${theme}__actions__action str-chat__message-${theme}__actions__action--reactions str-chat__message-reactions-button`}
          data-testid='message-reaction-action'
          onClick={onReactionListClick}
        >
          <ReactionIcon className='str-chat__message-action-icon' />
        </button>
      )}
    </div>
  );
} 

export const MessageOptions = React.memo(
  UnMemoizedMessageOptions,
)