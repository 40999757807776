import React, { Fragment } from 'react';
import { ClockIcon, LocationPinIcon } from '../../../subRequests/assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
library.add( faPlus )

export default function AttendanceCardComponent(props) {
  const { cls, handleAttendanceProcess } = props;
  const date = new Date(cls?.date_check?.substring(0, 19));
  const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
  const day = date.getDate();
  const weekDay = cls?.day?.toUpperCase();


  return(
    <Fragment>
      <div
        className={`max-w-xl bg-white shadow-sm items-center justify-between rounded-r-lg px-3 pl-4 py-3 mb-2 relative`}
      >
        <div className="flex w-full">
          <div className="w-6 h-full flex flex-col items-center mt-1">
            <div className="text-xs font-medium">{month}</div>
            <div className="text-base font-semibold">{day}</div>
            <div className="text-xs font-medium">{weekDay}</div>
          </div>
          <div className="w-full ml-3">
            <div className="relative">
              <div className="flex justify-between mb-2">
                <div className="font-base font-semibold text-gray-900">
                  {cls?.title} 
                </div>
              </div>
              <div className="flex items-center mb-1">
                <ClockIcon classNames={"w-5 h-5 text-neutral-400"} />
                <div className="text-xs font-semibold ml-2">{`${cls?.start_time} ${cls?.subrequest_timezone} (${cls?.duration_display})`}</div>
              </div>
              <div className="flex items-center mb-1">
                <LocationPinIcon classNames={"w-5 h-5 text-neutral-400"} />
                <a href={cls?.business_location?.location_map_url} target="_blank" className="custom-blue-200 text-xs ml-2 cursor-pointer font-semibold">
                  {cls?.business_location?.name}
                </a>
              </div>
            </div>
            <div>
              <button className='bg-black flex items-center top-6 text-sm py-1.5 px-2 font-semibold cursor-pointer sm:absolute static sm:mt-3.5 mt-5 right-3 text-white rounded-md gap-1' onClick={(e) => handleAttendanceProcess(e, cls)}>
                <FontAwesomeIcon className='w-3.5 h-3.5 shrink-0' icon="fa-solid fa-plus" />
                <div>Attendance</div>
              </button>  
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
