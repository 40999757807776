import React, { Fragment } from "react";

export default function LocationRegionSelectorComponent(props) {
  const { regionLocations, selectedLocations, setSelectedLocations, selectedRegions, setSelectedRegions, showRegion } = props

  const selectLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = selectedLocations?.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newLocs = [...selectedLocations, val]
        setSelectedLocations(newLocs)
        checkRegions(region, newLocs)
      }
    }else{
      if(__FOUND){
        let newLocs = selectedLocations.filter((tag, index) => tag !== val) 
        setSelectedLocations(newLocs)
        checkRegions(region, newLocs)
      }
    }
  }

  const checkRegions = (region, locations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});    
    const found = location_ids.some(r=> locations.includes(r))
    if(!found){
      setSelectedRegions(selectedRegions.filter((tag, index) => tag !== region.region.name))
    }else{
      if(!selectedRegions.includes(region.region.name)){
        setSelectedRegions([...selectedRegions, region.region.name])
      }
    }
  }

  const selectRegions = (e, region) => {
    let val = e.target.value
    var __FOUND = selectedRegions.find(function(region, index) {
      if(region == val)
        return true;
    });
    var result = region.locations.map(function(a) {return a.id;});
    if(e.target.checked){
      if(!__FOUND){
        setSelectedRegions([...selectedRegions, val])
        setSelectedLocations([...new Set([...selectedLocations, ...result])])
      }
    }else{
      if(__FOUND){
        setSelectedRegions(selectedRegions.filter((tag, index) => tag !== val))
        setSelectedLocations(selectedLocations.filter(item => !result.includes(item)))
      }
    }
  }

  return(
    <Fragment>
      <div className="flex flex-col items-start gap-2">
        {regionLocations.map((region, i) =>
          <div className="" key = {i}>
            {showRegion &&
              <div>
                <label className="flex items-center text-gray-900 text-sm mb-2">
                  <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => selectRegions(e, region)} value={region?.region?.name} checked={selectedRegions.includes(region?.region?.name)}/>
                  {region.region.name}
                </label>
              </div>
            }
            {(!showRegion || (showRegion && selectedRegions.includes(region.region.name))) &&
              <div className={`${showRegion ? 'ml-5 ' : ''}flex gap-1 flex-col flex-wrap`}>
                {region.locations.map((location, j) =>
                  <div key = {j} className="flex">
                    <label className="flex items-center text-gray-900 text-sm mb-2">
                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_location_id_${location.id}`} checked={selectedLocations?.includes(location?.id)} value={location.id} onChange={(e) => selectLocation(e, region)}/>
                      {location.name}
                    </label>
                  </div>
                )}
              </div>
            }
          </div>
        )}
      </div>
    </Fragment>
  )
}