import React, { Fragment, useEffect, useState, useContext } from "react";
import { axiosRequest, handleSetEventsData, axiosGetRequest, checkFiltersState } from './../../../../../helpers/helpers';
import CustomPopupComponent from './../../../customPopupComponent/customPopupComponent';
import EventsContentComponent from './eventsContentComponent';
import NoEventsComponent from './noEventsComponent';
import DateComponent from './dateComponent';
import UserContext from '../../../../../../userComponents/userContext';

export default function DayEventsPopup(props) {
  const context = useContext(UserContext);
  const { user } = context
  const { clickDate, showEventsPopup, closeEventsPopup, hanldeClickOnErrorEvent, selectedInstructors, selectedClasses, selectedLocations, statusFilter, path, myRequestsTab, sentStatuses, availableStatuses } = props
  const [ events, setEvents ] = useState([])
  const [ loaded, setLoaded ] = useState(false)
  const startDate = (new Date(clickDate)).toLocaleDateString('en-GB')
  
  useEffect(() => {
    if(clickDate && clickDate !== ''){
      if(['all-requests'].includes(path)){
        handleFetchEventsData()
      }else{
        handleFetchMyRequestsData()
      }
    }
  }, [])

  const handleFetchEventsData = () => {
    let selectedInstructorsIds = selectedInstructors?.map(function(a) {return a?.id});
    let selectedClassesIds = selectedClasses?.map(function(a) {return a?.id});
    let url = `${window._sub_board_url}/api/v3/sub_overview/get_location_events`
    let instructor_status = ['all_selected', 'no_selected'].includes(checkFiltersState(user?.id, 'instructor'))
    let class_status = ['all_selected', 'no_selected'].includes(checkFiltersState(user?.id, 'class'))
    let params = {
      start_date: startDate,
      end_date: startDate,
      event_type: undefined,
      view_type: 'calendar',
      initialize: false,
      version: 'v2',
      year_view: true,
      day_year_view_data: true,
      location_ids: selectedLocations,
      instructor_ids: selectedInstructorsIds,
      class_ids: selectedClassesIds,
      statuses: statusFilter,
      all_instructors: instructor_status,
      all_classes: class_status
    }
    axiosRequest(url, 'POST', params, 'data').then((response) => {
      if(response.status == 200){
        let results = handleSetEventsData(Object.values(response?.results).flat(), path)
        setEvents(results || [])
        setLoaded(true)
      }else{
        setEvents([])
        setLoaded(true)
      }
    })
  }

  const handleDayEventClick = (e, id, redirect_url) => {
    hanldeClickOnErrorEvent(e, id, redirect_url)
    closeEventsPopup(false)
  }

  const handleFetchMyRequestsData = () => {
    let url = `${window._sub_board_url}/api/v3/business_events?start_date=${startDate}&end_date=${startDate}&view_type=calendar&year_view=false&sent_statuses=${sentStatuses}&available_statuses=${availableStatuses}`
    axiosGetRequest(url).then((result) => {
      if(result.status == 200){
        let eventData = []
        if(myRequestsTab == 'sent'){
          eventData = handleSetEventsData(result?.sent, path)
        }else if(myRequestsTab == 'available'){
          eventData = handleSetEventsData(result?.available, path)
        }else if(myRequestsTab == 'approved'){
          eventData = handleSetEventsData(result?.approved, path)
        }
        setEvents(eventData || [])
        setLoaded(true)
      }else{
        setEvents([])
        setLoaded(true)
      }
    })
  }

  
  return (
    <Fragment>
      <CustomPopupComponent show={showEventsPopup} close={closeEventsPopup} title={''}>
        <DateComponent clickDate={clickDate}/>
        {!loaded &&
          <Fragment>
            <div className='flex flex-col items-center w-full justify-center h-56'>
              <img className = "w-9" src="/assets/business/loading.gif" />
            </div>
          </Fragment>
        }
        {loaded &&
          <Fragment>
            {events.length > 0 ?
              <EventsContentComponent events={events} handleEventClick={handleDayEventClick} />
              :
              <Fragment>
                <NoEventsComponent />
              </Fragment>
            }
          </Fragment>
        }
      </CustomPopupComponent>
    </Fragment>
  )
}