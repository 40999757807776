import Cookies from 'js-cookie';
import { addAccountCookies, getSudomain } from '../../helpers';

export const checkEmailAPI = "/api/v2/check_email"
export const loginAPI = "/api/v2/login"

export const currentUserEmail = () => {
  return (Cookies.get(`currentEmail`) || '')
}

export const switchAccountOwner = () => {
  return (isAddAccount() ? (Cookies.get(`switch_account_owner`) || '') : '')
}

export const switchAccountBusiness = () => {
  return (isAddAccount() ? (Cookies.get(`switch_account_business`) || '') : '')
}

export const setCurrentUserToken = (userApiToken) => {
  let subdomain = getSudomain();
  let new_domain = window.location.hostname.replace(subdomain + '.', '' );
  let expires_at = new Date('3020', '05', '01');
  addAccountCookies(`currentUser.${subdomain}`, JSON.stringify(userApiToken), expires_at, new_domain);
}

export const setUserEmailInCookies = (subdomain, email) => {
  let new_domain = window.location.hostname.replace(subdomain + '.', '' );
  let expires_at = new Date('3020', '05', '01');
  addAccountCookies(`currentEmail`, email, expires_at, new_domain);
}

export const setBusinessSubdomainInUrl = (subdomain) => {
  let splited_host = location.host.split('.')
  logoutUser(subdomain)
  let new_host = splited_host[splited_host.length -2] + '.' + splited_host[splited_host.length -1]
  location.href = `${location.protocol}//${subdomain}.${new_host}/signin`;
}

export const checkLoggedInUser = (subdomain) => {
  return Cookies.get(`currentUser.${subdomain}`)
}

const isAddAccount = () => {
  let switch_account = Cookies.get(`switch_account`)
  return (switch_account && switch_account !== null && switch_account !== '' && switch_account.toString() == 'true')
}

export const logoutUser = (subdomain) => {
  if(isAddAccount()){
    let userToken = checkLoggedInUser(subdomain)
    if(userToken && userToken !== null && userToken !== ''){
      let domain = "."+window.location.hostname.replace(subdomain + '.', '' )
      Cookies.remove(`currentUser.${subdomain}`, { path: '/', domain: domain })
    }
  }
}

export const removeAddAccountCookies = (subdomain) => {
  let domain = window.location.hostname.replace(subdomain + '.', '' )
  Cookies.remove(`switch_account`, { path: '/', domain: domain })
  Cookies.remove(`switch_account_owner`, { path: '/', domain: domain })
  Cookies.remove(`switch_account_business`, { path: '/', domain: domain })
  Cookies.remove(`switch_account_email`, { path: '/', domain: domain })
}