import React from 'react';

export const RightArrowIcon = ({classNames}) => {

  return(
    <svg className={`${classNames}`} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.525 4.94167L17.5834 10L12.525 15.0583" stroke="#0D0D0D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.41669 10H17.4417" stroke="#0D0D0D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}