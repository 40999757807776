import React, { Fragment } from 'react';
import CustomPopup from './customPopup'

export default function ConfirmCreateDmPopup (props) {
  const { show, title, close, createDmUser, processCreateDm, createDmBtnDisabled } = props

  const handleProcessCreateDM = () => {
    processCreateDm()
  }

  return(
    <Fragment>
      <CustomPopup show={show} close={close} title={title}>
        <div className="mt-3 border-t border-gray-200 mb-2">
          <div className='flex justify-between sm:gap-4 sm:items-center px-7 py-3 text-center text-base font-normal'>
            {`Do you want to start a direct message with ${createDmUser?.full_name}?`}
          </div>
          <div className='flex sm:gap-4 sm:items-center px-7 justify-center pt-4'>
            <button className={`flex items-center px-6 py-2.5 gap-1 border border-gray-300 shadow-sm font-normal rounded-md  ${createDmBtnDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-800 cursor-pointer'}`} onClick={(e) => handleProcessCreateDM(createDmUser?.full_name, createDmUser?.id)} disabled={createDmBtnDisabled}>
              <div className="flex items-center gap-1 relative">
                {createDmBtnDisabled && 
                  <div className="flex items-center -left-5 justify-center absolute">
                    <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                }
                <div className='text-white text-sm'>Yes</div>
              </div>
            </button>
            <button className={`flex items-center px-6 py-2.5 gap-1 border border-gray-300 shadow-sm font-normal rounded-md bg-gray-800 `} onClick={(e) => close()}>
              <div className="flex items-center gap-1 relative">
                <div className='text-white text-sm'>No</div>
              </div>
            </button>
          </div>
        </div>
      </CustomPopup>
    </Fragment>
  )
}