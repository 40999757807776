import React, { Fragment, useState } from "react";
import CustomPopupContainerComponent from '../../../../../subRequestDetails/components/customPopupContainerComponent';

export default function SkipConfirmationPopupComponent(props) {
  const { showConfirmationPopup, handleClosePopup, processSkip, disableConfirmBtn } = props

  const processClose = (data) => {
    if (!disableConfirmBtn) handleClosePopup(data)
  }

  return(
    <Fragment>
      <CustomPopupContainerComponent show={showConfirmationPopup} close={processClose} title={"Skip Phone Verification Confirmation"} customWidth={""}>
        <div className="flex flex-col gap-3 items-center px-4 pt-3 font-open-sans border-t mt-2">
          <div className="flex items-center text-center text-sm text-gray-600">
            If you do not add your mobile number, then you will not be able to receive SMS notifications. You can update it in your profile settings later on if you change your mind.
          </div>
          <div className="flex items-center text-center text-sm px-2 text-gray-900">
            Are you sure you want to skip this step?
          </div>
          <div className="flex w-full lg:w-1/2 justify-center mt-4 flex gap-3 flex-row-reverse">
            <button 
              className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-full py-2 text-sm font-semibold h-9 ${(disableConfirmBtn) ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={processSkip}
              disabled={disableConfirmBtn}
            >
              Yes
            </button>
            <button 
              className={`bg-white border rounded-xl text-gray-800 flex items-center justify-center w-full py-2 text-sm hover:bg-gray-100 font-semibold shadow h-9 ${(disableConfirmBtn) ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => handleClosePopup(false)}
              disabled={disableConfirmBtn}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  )
}