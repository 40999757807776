import React, { Fragment } from "react";

export default function ApprovedUserDetailsComponent(props) {
  const { newSubRequest } = props;

  return(
    <Fragment>
      <div className="flex flex-col gap-2 w-full mb-4">
        <div className="flex text-base md:text-lg text-black font-semibold">
          Approved Instructor
        </div>
        <div className="max-h-72 shadow border overflow-auto border-b border-gray-200 rounded-2xl">
          <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
              <tr>
                <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                  <div className='flex items-center'>
                    <span className=''>Instructors</span> &nbsp; &nbsp;
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200'>
              <tr className='bg-white relative'>
                <td className='px-3 py-3'>
                  <div className="flex items-center gap-2">
                    <div className="w-6 h-6 rounded-full bg-gray-300 shrink-0">
                      <img className="w-full h-full rounded-full" src={newSubRequest?.approvedUser?.image ||"/assets/default-profile.jpg"}/>
                    </div>
                    <div className='text-sm font-medium text-gray-900'>
                      {newSubRequest?.approvedUser?.full_name}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  )
}