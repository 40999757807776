import React, { Fragment } from "react";

export default function CardComponent(props) {
  const { children, path } = props;
  return (
    <Fragment>
      <div className="w-full md:w-1/2 rounded-xl bg-white px-5 md:px-8 py-10 flex items-center justify-center shadow-md">
        {children}
      </div>
    </Fragment>
  )
}