import React, { Fragment, useState } from 'react';
import { AngleIcon } from './../../../../assets'
import { TrashIcon } from './../../../../../subRequestDetails/assets';
import CommentDeleteConfirmationComponent from './../../../../../subRequestDetails/components/commentSectionComponent/commentDeleteConfirmationComponent';

export default function ComentsSectionComponent(props){
  const { comments, commentsToShow, showAllComments, handleShowComments, eventData, handleAlert, handleUpdateEvent } = props
  const [ deleteComment, setDeleteComment ] = useState({})
  const [ showDeleteCommentPopup, setShowDeleteCommentPopup ] = useState(false)

  const handleDeleteComment = (comment) => {
    setDeleteComment(comment)
    setShowDeleteCommentPopup(true)
  }

  const closeDeleteCommentPopup = (data) => {
    setDeleteComment({})
    setShowDeleteCommentPopup(data)
  }
  
  return(
    <Fragment>
      {showDeleteCommentPopup &&
        <CommentDeleteConfirmationComponent eventData={eventData} comment={deleteComment} handleSetAlert={handleAlert} handleUpdateEvents={handleUpdateEvent} showDeleteCommentPopup={showDeleteCommentPopup} closeDeleteCommentPopup={closeDeleteCommentPopup}/>
      }
      <div className='border-t border-gray-300 max-h-52 overflow-auto py-2 mt-2'>
      {commentsToShow.map((comment, i) =>
        (<div key={i} className='flex items-center gap-2 pt-2'>
          <img src={comment?.user?.image} className='w-6 h-6 rounded-full' />
          <div className='w-full'>
            <div className='flex items-center justify-between'>
              <div className='text-gray-900 text-xs font-semibold flex gap-1'>
                {comment?.user?.name}
                <div className='text-gray-500'>
                  {comment?.created?.trim()}
                </div>
              </div>
              {comment?.can_delete &&
                <button className={`w-5 h-5 rounded-full border-neutral-500-custom flex items-center justify-center opacity-75 hover:opacity-100`} onClick={() => handleDeleteComment(comment)}>
                  <TrashIcon classNames={"w-3 h-3"} colour={''}/>
                </button>
              }
            </div>
            <div className='text-black text-xs font-semibold'>{comment?.comment}</div>
          </div>
        </div>)
      )}
      </div>
      {comments?.length > 1 &&
        <div className='mt-2'>
          <a className='custom-blue-200 flex items-center gap-2 text-xs font-semibold cursor-pointer' onClick={() => handleShowComments(!showAllComments)}>
            {showAllComments ? `Hide comments` : `Show all comments (${comments?.length})`}
            <AngleIcon classNames={showAllComments ? 'w-3 h-4 transform rotate-180' : 'w-3 h-4'}/>
          </a>
        </div>
      }
    </Fragment>
  )
}