import React, { Fragment, useState, useEffect } from 'react';
import AssignPopupComponent from '../../../../../subRequests/pages/components/assignPopupComponent';
import CancelConfirmationPopupComponent from './cancelConfirmationPopupComponent';

export default function CancelOptionsPopupComponent(props){
  const { eventData, showCancelOptionsPopup, closeShowCancelOptionsPopup, handleSetAlert, handleUpdateEvent, optionListKeys, cancelOptionKeys, backPath, type, sentBucket, calendarEvent, handleUpdateCalendarEvent } = props
  const [ selectedOption, setSelectedOption ] = useState('')
  const [ showConfirmationPopup, setShowConfirmationPopup ] = useState(false)
  const [ cancelActionOptions, setCancelActionOptions ] = useState(eventData?.more_action_detail?.filter((item) => cancelOptionKeys.includes(item?.key)) || [])
  const [ disableConfirmBtn, setDisableConfirmBtn ] = useState(false)

  useEffect(() => {
    if(eventData){
      setCancelActionOptions(eventData?.more_action_detail?.filter((item) => cancelOptionKeys.includes(item?.key)) || [])
    }
  }, [eventData])

  const handleOptionSelection = (e) => {
    if(selectedOption === e.target.value) {
      setSelectedOption('');
    }else{
      setSelectedOption(e.target.value);
    }
  }

  const handleApply = () => {
    setShowConfirmationPopup(true)
  }

  const handleClosePopup = (data) => {
    if(!disableConfirmBtn){
      closeShowCancelOptionsPopup(data)
    }
  }

  return(
    <Fragment>
      <AssignPopupComponent show={showCancelOptionsPopup} close={handleClosePopup} title={""}>
        {!showConfirmationPopup ?
          <div className="px-5 pt-3 font-open-sans">
            <div className="text-center font-bold text-xl mb-4 font-open-sans">
              Select Reason for Canceling
            </div>
            {cancelActionOptions?.map((action, i) => (
              <div
                key={i}
                className="flex items-center gap-2 mb-3"
              >
                <div className="assign-checkbox flex items-center">
                  <input
                    id={action?.key+'_'+i}
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer"
                    value={action?.key}
                    checked={selectedOption == action?.key}
                    onChange={(e) => handleOptionSelection(e)}
                  />
                </div>
                <label className="flex items-center gap-2 cursor-pointer" htmlFor={action?.key+'_'+i}>
                  <div className="text-sm">{action?.value}</div>
                </label>
              </div>
            ))}
            <div className="mt-4">
              <button 
                className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-full py-2 text-sm h-11 font-semibold ${(selectedOption == '') ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={() => handleApply()}
                disabled={selectedOption == ''}
              >
                Apply
              </button>
            </div>
          </div>
          :
          <CancelConfirmationPopupComponent eventData={eventData} handleSetAlert={handleSetAlert} closeShowCancelOptionsPopup={closeShowCancelOptionsPopup} handleUpdateEvent={handleUpdateEvent} selectedOption={selectedOption} backPath={backPath} type={type} sentBucket={sentBucket} disableConfirmBtn={disableConfirmBtn} setDisableConfirmBtn={setDisableConfirmBtn} calendarEvent={calendarEvent} handleUpdateCalendarEvent={handleUpdateCalendarEvent}/>
        }
      </AssignPopupComponent>
    </Fragment>
  )
}