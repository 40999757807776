import React from "react";

export const BellIcon = ({classNames}) => {
	return (
		// <svg className={`${classNames}`} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		// 	<path d="M10.5167 2.425C7.75835 2.425 5.51668 4.66667 5.51668 7.425V9.83333C5.51668 10.3417 5.30001 11.1167 5.04168 11.55L4.08335 13.1417C3.49168 14.125 3.90001 15.2167 4.98335 15.5833C8.57501 16.7833 12.45 16.7833 16.0417 15.5833C17.05 15.25 17.4917 14.0583 16.9417 13.1417L15.9833 11.55C15.7333 11.1167 15.5167 10.3417 15.5167 9.83333V7.425C15.5167 4.675 13.2667 2.425 10.5167 2.425Z" stroke="#0D0D0D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
		// 	<path d="M12.0583 2.66667C11.8 2.59167 11.5333 2.53333 11.2583 2.5C10.4583 2.4 9.69164 2.45833 8.97498 2.66667C9.21664 2.05 9.81664 1.61667 10.5166 1.61667C11.2166 1.61667 11.8166 2.05 12.0583 2.66667Z" stroke="#0D0D0D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
		// 	<path d="M13.0167 15.8833C13.0167 17.2583 11.8917 18.3833 10.5167 18.3833C9.83333 18.3833 9.2 18.1 8.75 17.65C8.3 17.2 8.01666 16.5667 8.01666 15.8833" stroke="#0D0D0D" strokeWidth="1.5" strokeMiterlimit="10"/>
		// </svg>
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`${classNames}`}>
			<path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
		</svg>
	)
}

export const CrossBellIcon = ({classNames}) => {
	return(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`${classNames}`}>
			<path strokeLinecap="round" strokeLinejoin="round" d="M9.143 17.082a24.248 24.248 0 003.844.148m-3.844-.148a23.856 23.856 0 01-5.455-1.31 8.964 8.964 0 002.3-5.542m3.155 6.852a3 3 0 005.667 1.97m1.965-2.277L21 21m-4.225-4.225a23.81 23.81 0 003.536-1.003A8.967 8.967 0 0118 9.75V9A6 6 0 006.53 6.53m10.245 10.245L6.53 6.53M3 3l3.53 3.53" />
		</svg>
	)
}