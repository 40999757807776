import React, { Fragment, useState } from "react";
import { axiosRequest } from './../../../../../../subRequests/helpers/helpers';
import { Redirect } from 'react-router-dom';

export default function CancelConfirmationPopupComponent(props){
  const { eventData, handleSetAlert, closeShowCancelOptionsPopup, handleUpdateEvent, selectedOption, backPath, type, sentBucket, disableConfirmBtn, setDisableConfirmBtn, calendarEvent, handleUpdateCalendarEvent } = props
  const [ redirect, setRedirect ] = useState(false)

  const handleConfirm = () => {
    setDisableConfirmBtn(true)
    let url = `/api/v3/business_events/${eventData?.id}/perform_more_actions`
    let params = {
      key: selectedOption,
      preview: type !== 'detail',
      bucket_type: (type !== 'detail' ? sentBucket : ''),
      calendar_event: calendarEvent
    }
    if(selectedOption == 'delete_without_sync'){
      params = {...params, reason: "Studio schedule updated"}
    }else if(selectedOption == 'delete_class'){
      params = {...params, reason: "No longer need a sub"}
    }else if(selectedOption == 'delete_class_original_instructor'){
      params = {...params, reason: "No longer need a sub original instructor is teaching"}
    }else if(selectedOption == 'delete_class_duplicate'){
      params = {...params, reason: 'Duplicate Sub Request'}
    }else if(selectedOption == 'delete_class_no_longer'){
      params = {...params, reason: 'No longer need a sub'}
    }else if(selectedOption == 'delete_class_other'){
      params = {...params, reason: 'Other'}
    }
    if(selectedOption == ''){
      setDisableConfirmBtn(false);
      return
    }
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if(result?.status === 200){
        handleSetAlert(true, result?.success, 'success');
        if(['cancel_sub_request', 'rescind_class'].includes(selectedOption)){
          setDisableConfirmBtn(false);
          closeShowCancelOptionsPopup(false);
        }
        setTimeout(() => {
          handleSetAlert(false, '', '');
          if(['cancel_sub_request', 'rescind_class'].includes(selectedOption)){
            handleUpdateEvent(result?.event);
            if(calendarEvent){
              handleUpdateCalendarEvent(result?.calendar_event, 'updated');
            }
          }else{
            if(type == 'detail'){
              if(backPath !== '' && !backPath.includes('/business_user')){
                setRedirect(true)
              }else{
                window.location.href = backPath
              }
            }else{
              handleUpdateEvent('deleted');
              if(calendarEvent) handleUpdateCalendarEvent('', `deleted_${eventData?.id}`);
              closeShowCancelOptionsPopup(false);
            }
          }
        }, 3000)
      }else{
        setDisableConfirmBtn(false);
        handleSetAlert(true, (selectedOption == 'crunch_event_delete' ? result?.success : result?.error), 'danger');
        setTimeout(() => {
          handleSetAlert(false, '', ''); 
        }, 3000)
      }
    })
  }

  return(
    <Fragment>
      {redirect &&
        <Redirect to={`${backPath}`} />
      }
      <div className="px-4 pt-3 font-open-sans">
        <div className="text-center font-bold text-2xl font-open-sans">
          Are you sure you want to cancel this sub request?
        </div>
        {['delete_class_original_instructor', 'delete_class'].includes(selectedOption) &&
          <div className="text-center text-sm font-open-sans mt-2 px-2">
            Only select this option if you have confirmed with the original instructor and the manager that they are teaching and no longer need a sub. The sub request will be deleted and cannot be reverted.
          </div>
        }
        <div className="mt-4 flex gap-3 flex-row-reverse">
          <button 
            className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-full py-2 text-sm font-semibold h-11 ${(selectedOption == '' || disableConfirmBtn) ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => handleConfirm()}
            disabled={selectedOption == '' || disableConfirmBtn}
          >
            Yes
          </button>
          <button 
            className={`bg-white border rounded-xl text-gray-800 flex items-center justify-center w-full py-2 text-sm hover:bg-gray-100 font-semibold shadow h-11 ${(disableConfirmBtn) ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => closeShowCancelOptionsPopup(false)}
            disabled={disableConfirmBtn}
          >
            No
          </button>
        </div>
      </div>
    </Fragment>
  )
}