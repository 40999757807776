import React, { Fragment, useState } from "react";

export default function NeedHelpComponent(){
  const [ interComComponent, setInterComComponent ] = useState([])

  return(
    <Fragment>
      <button className="hidden lg:flex justify-center items-center rounded-2xl px-4 py-4 h-11 bg-white text-sm text-gray-900 border-2 border-gray-300 font-semibold gap-2 need-help-button">
        Need Help?
      </button>
    </Fragment>
  )
}