import React, { Fragment, useState } from "react";
import ClassDescriptionPopupComponent from './../../classDescriptionPopupComponent';

export default function ClassDescriptionComponent(props){
  const { eventData } = props
  const [showClassDescription, setShowClassDescription] = useState(false);

  return(
    <Fragment>
      {showClassDescription && (
        <ClassDescriptionPopupComponent
          classData={eventData?.selected_class}
          setShowClassDescription={setShowClassDescription}
          showClassDescription={showClassDescription}
        />
      )}
      <button
        className="flex text-sm font-semibold custom-blue-200 mt-2"
        onClick={() => setShowClassDescription(true)}
      >
        View Class Description
      </button>
    </Fragment>
  )
}