import React, { Fragment } from 'react';
import { getSudomain, setTitle } from './../helpers.js'
import { getInstructors, getLocationsSkills } from './helpers'
import Cookies from 'js-cookie';
import { Link, Redirect } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { debounce } from "lodash";
import Pagination from "react-js-pagination";
import { createGroup } from './../messenger/pages/helpers.js'

export default class Directory extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {     
      loaded: false,
      alert_message: '',
      alert_type: '',
      show: false,
      page: 1,
      pageSize: 20,
      users: {},
      totalUsers: 0,
      regions_and_locations: [],
      skills: [],
      searchData: '',
      show_others: false,
      selectedLocations: [],
      selectedRegions: [],
      selectedSkills: [],
      filterDataLoaded: false,
      baseCount: 0,
      redirectToChat: false,
      redireactUrl: '',
      createDmBtnDisabled: false
    }
  }

  componentDidMount = () => {
    setTitle('directory')
    this.props.setCurrent('Directory')
    if(this.props.pageLoaded){
      this.getFilterLocationsSkills()
      this.getAll('', [], [], '1')
    }
  }

  getAll(text, locations, skills, page){
    this.setState({filterDataLoaded: false})
    getInstructors(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), text, skills, locations, page, this.state.pageSize).then(response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          users: result.results,
          page: parseInt(result.page),
          pageSize: parseInt(result.page_size),
          totalUsers: result.total,
          baseCount: (parseInt(result.page)-1)*20,
          loaded: true,
          filterDataLoaded: true
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },5000)
      });
    })
  }

  getFilterLocationsSkills = () => {
    getLocationsSkills(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          regions_and_locations: result?.regions_and_locations,
          skills: result?.skills,
          show_others: result?.show_others,
          locations: result?.locations
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },5000)
      });
    })
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  shortData = (val) => {
    let data = val.split(', ')
    let hold = []
    let string = ''
    for(let i = 0; i < data?.length; i++){
      hold.push(data[i])
      if(hold?.join(', ')?.length > 47){
        string = this.countNameCharectors(hold?.join(', '))+(((data?.length-1) - i) > 0 ? ' +'+((data?.length-1) - i) : '')
        break
      }else{
        string = this.countNameCharectors(hold?.join(', '))
      }
    }
    return string
  }

  countNameCharectors = (name) => {
		let shortName = []
		for(let i = 0; i < name?.length; i++){
			if(i < 48){
				shortName.push(name[i])
			}else{
				shortName.push('...')
				break
			}
		}
	
		return shortName.join('')
	}

  handleSearch = (e) => {
    this.setState({
      searchData: e.target.value
    })
    this.performSearch(e.target.value)
  }

  performSearch = debounce(val => {
		this.getAll(val, this.state.selectedLocations, this.state.selectedSkills, '1')
	}, 800);

  chekLocationNameCount = (name) => {
		let shortName = []
		for(let i = 0; i < name?.length; i++){
			if(i < 20){
				shortName.push(name[i])
			}else{
				shortName.push('...')
				break
			}
		}
	
		return shortName.join('')
	}

	locationName = () => {
    let locations = this.state.locations
    let selected_locations = this.state.selectedLocations
    let locationName = []
    let ctr = 0
    for(let i = 0; i < locations?.length; i++){
      if(selected_locations.includes(locations[i].id)){
        if(ctr < 2){
          locationName.push(locations[i].name)
          ctr++
        }
        else{
          break;
        }
      }
    }
    return this.chekLocationNameCount(locationName.join(", "))
  }

  selectedAllRegLoc = () => {
    let check = this.state.selectedRegions?.length === this.state.regions_and_locations?.length
    return check
  }

  selectedAllRegionsLocations = (e) => {
    if(e.target.checked){
      let all = this.state.regions_and_locations
      let selected_regions = []
      let selected_locations = []
      for(var i=0; i<all.length;i++){     
        selected_regions.push(all[i]['region']['name'])
        for(var j=0; j<all[i]['locations'].length; j++){
          selected_locations.push(all[i]['locations'][j]['id'])
        }
      }
      this.setState({
        selectedLocations: selected_locations,
        selectedRegions: selected_regions
      })
			this.getAll(this.state.searchData, selected_locations, this.state.selectedSkills, '1')
    }else{
      this.setState({
        selectedRegions: [],
        selectedLocations: []
      })
			this.getAll(this.state.searchData, [], this.state.selectedSkills, '1')
    }
  }

  selectRegions = (e, region) => {
    let val = e.target.value
    let regions = this.state.selectedRegions
   
    var __FOUND = regions.indexOf(val) > -1;
    var result = region.locations.map(function(a) {return a.id;});
    let selected_locations = this.state.selectedLocations;
    
    if(e.target.checked){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }));
        let final_result = []
        for(let i = 0; i < result?.length; i++){
          if(!selected_locations.includes(result[i])){
            final_result.push(result[i])
          }
        }
        selected_locations = Array.prototype.push.apply(selected_locations, final_result)
      }
			this.getAll(this.state.searchData, this.state.selectedLocations, this.state.selectedSkills, '1')
    }else{
      if(__FOUND){
        
        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)          
        });
        selected_locations = selected_locations.filter(item => !result.includes(item));
        this.setState({
          selectedLocations: selected_locations
        })
				this.getAll(this.state.searchData, selected_locations, this.state.selectedSkills, '1')
      }
    }
  }

  selectLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedLocations.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newlocs = [...this.state.selectedLocations, val]
        this.setState(state => ({ selectedLocations: newlocs }));
        this.checkRegionVisibility(region, newlocs)
      }
    }else{
      if(__FOUND){
        const { selectedLocations } = this.state;
        let newLocs = selectedLocations.filter((tag, index) => tag !== val)  
        this.setState({
          selectedLocations: newLocs        
        });
        this.checkRegionVisibility(region, newLocs)  
      }      
    }
  }

  checkRegionVisibility = (region, locations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(locations, location_ids)
    let val = region.region?.name

    let regions = this.state.selectedRegions
    var __FOUND = regions.indexOf(val) > -1;
    if(equal){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }))
      }
    }else{
      if(__FOUND){
        
        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)          
        });
      }
    }
		this.getAll(this.state.searchData, locations, this.state.selectedSkills, '1')
  }

  selectAllSkills = () => {
    let check = this.state.skills?.length === this.state.selectedSkills?.length
    return check
  }

	handleSelectAllSkills = (e) => {
		if(e.target.checked){
      let allSkills = this.state.skills
      let selected_skills = []
      for(var i=0; i<allSkills.length;i++){ 
				selected_skills.push(allSkills[i]['id'])    
      }
      this.setState({
        selectedSkills: selected_skills,
      })
			this.getAll(this.state.searchData, this.state.selectedLocations, selected_skills, '1')
    }else{
      this.setState({
        selectedSkills: []
      })
			this.getAll(this.state.searchData, this.state.selectedLocations, [], '1')
    }
	}

  handleSelectSkills = (e) => {
		let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedSkills.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newSkills = [...this.state.selectedSkills, val]
        this.setState(state => ({ selectedSkills: newSkills }));
				this.getAll(this.state.searchData, this.state.selectedLocations, newSkills, '1')
      }
    }else{
      if(__FOUND){
        const { selectedSkills } = this.state;
        let newSkills = selectedSkills.filter((tag, index) => tag !== val)  
        this.setState({
          selectedSkills: newSkills        
        }); 
				this.getAll(this.state.searchData, this.state.selectedLocations, newSkills, '1')
      }      
    }
	}

  checkSelectedSkillsName = () => {
		let skills = this.state.skills
		let selectedSkills = this.state.selectedSkills
		let skillName = []
		let ctr = 0
		for(let i = 0; i < skills?.length; i++){
			if(selectedSkills.includes(skills[i].id)){
				if(ctr < 2){
					skillName.push(skills[i].name)
					ctr++
				}else{
					break;
				}
			}
		}
	
		return skillName.join(', ')
	}

  handlePageChange(pageNumber) {
    this.setState({
      page: pageNumber, 
      // loaded: false
    });
    this.getAll(this.state.searchData, this.state.selectedLocations, this.state.selectedSkills, pageNumber)
  }

  handleCreateDirectMessage = (user_id) => {
    this.setState({createDmBtnDisabled: true})
    if(!user_id || user_id == '' || user_id == 0) return
    let receivers = []
    receivers.push(user_id)
    const formData = new FormData()

    formData.append('group_data[message]', '');
    formData.append('group_data[type]', 'direct_message');
    formData.append('group_data[receivers]', receivers);
    formData.append('group_data[is_private]', true);
    
    createGroup(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), formData).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
          redireactUrl: `/messenger/chat-room/${result?.id}`,
          alert_message: result?.message,
          alert_type: 'success',
          show: false,
          createDmBtnDisabled: false, 
          redirectToChat: true
				})
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,
          createDmBtnDisabled: false
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

  render(){
    const { users, totalUsers, page, loaded, regions_and_locations, skills, searchData, show_others, selectedLocations, selectedRegions, selectedSkills, filterDataLoaded, baseCount, locations, alert_message, alert_type, show, pageSize, createDmBtnDisabled, redirectToChat, redireactUrl } = this.state
    const { loadingMessage } = this.props
    
    return (  
      <React.Fragment>
        {redirectToChat && <Redirect push to={`${redireactUrl}`} />}
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        } 
        {!loaded &&
          <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
            <p className="mb-1 text-center px-5">{loadingMessage}</p>
            <img className = "w-9" src="/assets/business/loading.gif" />
          </div>
        }
        {loaded &&
          <div className="w-full min-h-screen bg-gray-50 mt-10 md:mt-0">
            <div className="py-8">
              <header>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <h1 className="text-2xl font-semibold leading-tight text-gray-900">Directory</h1>
                  <div className="grid items-center t-filter py-5">
                    <div className="items-center grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                      <div className="relative bg-gray-300 rounded-md">
                        <div className="bg-white border border-gray-300 shadow-sm rounded-md h-9 flex items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4 ml-3.5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                          </svg>
                          <input 
                          type="text" 
                          className="text-sm w-full border-0 h-9 ml-1.5 focus:outline-none bg-transparent" 
                          placeholder="Search by name"
                          value={searchData}
                          onChange={(e) => this.handleSearch(e)}
                          />
                        </div>
                      </div>
                      <div className="relative filters_box">
                        <Menu as="div" className="relative block text-left">

                          <div>
                            <Menu.Button className="inline-flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 location_items_button">
                              {selectedLocations?.length === locations?.length && <p className = "text-gray-900">All Locations Selected</p>
                              }
                              {selectedLocations?.length < locations?.length && 
                                <React.Fragment>
                                  {(selectedLocations?.length === 1 && selectedLocations?.length !== 0) && 
                                    <p className = "text-gray-900">{this.locationName()}</p>
                                  }
                                  {(selectedLocations?.length > 1 && selectedLocations?.length < locations?.length) && 
                                    <div className = "flex text-gray-900">{this.locationName()} {(selectedLocations?.length - this.locationName()?.split(',')?.length) > 0 && <p>+{selectedLocations?.length - this.locationName()?.split(',')?.length}</p>}</div>
                                  }
                                </React.Fragment>
                              }
                              {(selectedLocations?.length === 0  && selectedLocations?.length !== locations?.length) && <p>Select Location</p>} 
                              <ChevronDownIcon className="ml-auto h-5 w-5 text-gray-900" aria-hidden="true" />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            show={true}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95">
                            <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden location_items filter_items">
                              <div className="flex items-center p-2 border-b">
                                <div className=" mr-auto">
                                  <label className="inline-flex items-center text-gray-400 text-sm mb-2 gap-1">
                                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300" checked = {this.selectedAllRegLoc()} onChange = {(e) => this.selectedAllRegionsLocations(e)}/>
                                    Select all/Clear all
                                  </label>
                                </div>
                              </div>
                              <div className="p-2 max-h-60 overflow-y-auto">
                                {regions_and_locations.map((region, i) =>
                                  <div className="py-1" key = {i}>
                                    {show_others &&
                                      <Menu.Item>
                                        {({ active }) => (
                                          <label className="flex items-center text-gray-900 text-sm mb-2">
                                            <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => this.selectRegions(e, region)} value={region?.region?.name} checked={selectedRegions.includes(region.region.name)}/>
                                            {region.region.name}
                                          </label>
                                            
                                        )}
                                      </Menu.Item>
                                    }
                                    <div className="ml-5">
                                      {region.locations.map((location, j) =>
                                      <Menu.Item key = {j}>
                                        {({ active }) => (
                                          <label className="flex items-center text-gray-900 text-sm mb-2">
                                            <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={selectedLocations.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)}/>
                                            {location.name} <p className='text-gray-400 text-xs ml-2'>{`(${location.count})`}</p>
                                          </label>
                                            
                                        )}
                                      </Menu.Item>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>

                      <div className="relative filters_box">
                        <Menu as="div" className="relative block text-left">
                          <div>
                            <Menu.Button className="flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 instructor_items_button">
                              {selectedSkills?.length === skills?.length && <p className = "text-gray-900">All Skills Selected</p>
                              }
                              {selectedSkills?.length < skills?.length && 
                                <React.Fragment>
                                  {(selectedSkills?.length <= 2 && selectedSkills?.length !== 0) && 
                                    <p className = "text-gray-900">{this.checkSelectedSkillsName()}</p>
                                  }
                                  {(selectedSkills?.length > 2 && selectedSkills?.length < skills?.length) && 
                                    <p className = "text-gray-900">{this.checkSelectedSkillsName()} +{selectedSkills?.length-2}</p>
                                  }
                                </React.Fragment>
                              }
                              {(selectedSkills?.length === 0  && selectedSkills?.length !== skills?.length) && <p>Select Skill</p>}

                              <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            show={true}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95">
                            <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none instructor_items hidden filter_items">
                              <div className="flex items-center p-2 border-b">
                                <div className="mr-auto">
                                  <label className="inline-flex items-center text-gray-400 text-sm mb-2 gap-1">
                                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300" checked = {this.selectAllSkills()} onChange = {(e) => this.handleSelectAllSkills(e)}/>
                                    Select all/Clear all
                                  </label>
                                </div>
                              </div>
                              <div className="p-2 max-h-60 overflow-y-auto">
                                <div className="py-1">
                                  <div>
                                    {skills.map((skill, i) => 
                                      <Menu.Item key = {i}>
                                        {({ active }) => (
                                          <label className="flex items-center text-gray-900 text-sm mb-2">
                                            <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked = {selectedSkills.includes(skill?.id)} value={skill.id} onChange = {(e) => this.handleSelectSkills(e)}/>
                                              {skill.name}
                                              <p className='text-gray-400 text-xs ml-2'>{`(${skill.count})`}</p>
                                          </label>
                                            
                                        )}
                                      </Menu.Item>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              {!filterDataLoaded && 
                <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                  <p className="mb-1 text-center px-5">{loadingMessage}</p>
                  <img className = "w-9" src="/assets/business/loading.gif" />
                </div>
              }
              {filterDataLoaded &&
                <main>
                  {totalUsers > 0 ?
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                      <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        {users.map((usr, i) => 
                          <li className={`col-span-1 flex flex-col text-center bg-white rounded-lg shadow ${(usr?.show_phone || usr?.show_email || usr?.create_dm_permission) ? 'divide-y divide-gray-200' : ''}`} key={i}>
                            <div className="flex-1 flex flex-col p-6">
                              <img className="w-24 h-24 flex-shrink-0 mx-auto rounded-full" src={`${usr?.image}`} alt="" />
                              <div className='relative h-9'>
                                <h3 className="mt-2 text-gray-900 text-md font-medium absolute whitespace-nowrap overflow-hidden left-0 right-0 overflow-ellipsis px-5" data-tip data-for={usr.id.toString()+'full_name'}>{usr?.full_name}</h3>
                                {(usr?.full_name && usr?.full_name?.length > 24) &&
                                  <ReactTooltip className='max-w-xs' id={usr.id.toString()+'full_name'} place="top" effect="solid">
                                    {usr?.full_name}
                                  </ReactTooltip>
                                }                                
                              </div>
                              <div className='mb-2'>
                                {!usr?.active &&
                                  <div className="text-gray-500 text-xs -mt-1">(Inactive)</div>
                                }
                              </div>
                              <dl className="mt-1 flex-grow flex flex-col">
                                <dt className="sr-only">Title</dt>
                                {usr?.locations?.length > 0 &&
                                  <dd className="text-gray-500 text-xs mb-3" data-tip data-for={usr.id.toString()+'locations'}>
                                    <span className="font-semibold">Locations: </span> 
                                    {this.shortData(usr?.locations)}
                                    {usr?.locations?.length > 47 &&
                                      <ReactTooltip className='max-w-xs' id={usr.id.toString()+'locations'} place="top" effect="solid">
                                        {usr?.locations}
                                      </ReactTooltip>
                                    }
                                  </dd>
                                }
                                <dt className="sr-only">Role</dt>
                                {usr?.skills?.length > 0 &&
                                  <dd className="text-gray-500 text-xs" data-tip data-for={usr.id.toString()+'skills'}>
                                    <span className="font-semibold">Skills: </span> 
                                    {this.shortData(usr?.skills)}
                                    {usr?.skills?.length > 47 &&
                                      <ReactTooltip className='max-w-xs' id={usr.id.toString()+'skills'} place="top" effect="solid">
                                        {usr?.skills}
                                      </ReactTooltip>
                                    }
                                  </dd>
                                }
                              </dl>
                            </div>
                            <div>
                              <div className="-mt-px flex py-3 divide-x divide-gray-200">
                                {usr?.show_phone &&
                                  <React.Fragment>
                                    <div className="w-0 flex-1 flex visible lg:hidden">
                                      <a href={`sms:${usr?.phone}`} className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500" data-tip data-for={usr.id.toString()+'text'}>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd" />
                                      </svg>
                                        <span className="ml-3">Text</span>
                                      </a>
                                      <ReactTooltip className='max-w-xs' id={usr.id.toString()+'text'} place="top" effect="solid">
                                        {usr?.phone}
                                      </ReactTooltip>
                                    </div>
                                    <div className="-ml-px w-0 flex-1 flex">
                                      <a href={`tel:${usr?.phone}`} className="relative w-0 flex-1 inline-flex items-center justify-center text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500" data-tip data-for={usr.id.toString()+'phone'}>
                                        
                                        <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                          <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                        </svg>
                                        <span className="ml-3">Call</span>
                                      </a>
                                      <ReactTooltip className='max-w-xs' id={usr.id.toString()+'phone'} place="top" effect="solid">
                                        {usr?.phone}
                                      </ReactTooltip>
                                    </div>
                                  </React.Fragment>
                                }
                                {usr?.show_email &&
                                  <div className="w-0 flex-1 flex">
                                    <a href={`mailto:${usr?.email === null ? '' : usr?.email}`} className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500" data-tip data-for={usr.id.toString()+'email'}>
                                      
                                      <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                      </svg>
                                      <span className="ml-3 text-sm">Email</span>
                                    </a>
                                    {usr?.email !== null &&
                                      <ReactTooltip className='max-w-xs' id={usr.id.toString()+'email'} place="top" effect="solid">
                                        {usr?.email}
                                      </ReactTooltip>
                                    }
                                  </div>
                                }
                                {usr?.create_dm_permission &&
                                  <div className="w-0 flex-1 flex">
                                    <button className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500" data-tip data-for={usr.id.toString()+'direct_message'} onClick={() => this.handleCreateDirectMessage(usr?.id)} disabled={createDmBtnDisabled}>
                                      <img src='/assets/chat-icon.svg' className="w-5 h-5" />
                                      <span className="ml-3 text-sm">Chat</span>
                                    </button>
                                  </div>
                                }
                              </div>
                            </div>
                          </li>
                        )}
                      </ul>
                      
                      <div className="py-3 mt-4 flex items-center justify-between">
                        
                        <div className="flex justify-between w-full flex-wrap">
                          <div>
                            <p className="text-sm text-gray-700">
                              {`Showing ${baseCount+1} to ${page*pageSize > totalUsers ? totalUsers : page*pageSize} of ${totalUsers} results`} 
                            </p>
                          </div>
                          <div className="custom-pagination order-first sm:order-last mb-3 sm:mb-0">
                            {totalUsers > pageSize && 
                              <Pagination
                                activePage={page}
                                itemsCountPerPage={pageSize}
                                totalItemsCount={totalUsers}
                                pageRangeDisplayed={9}
                                onChange={this.handlePageChange.bind(this)}
                                itemClassPrev='previous'
                                itemClassNext='next'
                                itemClassFirst='first'
                                itemClassLast='last'
                                hideDisabled={true}
                              />
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div className='no-data flex justify-center items-center h-96'>
                      No User Found
                    </div>
                  }
                </main>
              }
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}