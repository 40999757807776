import React, { Fragment } from "react";
import CardComponent from './../cardComponent'

export default function OpenEventsComponent(props){
  const { openEvents, user, business, handleAlert, processGetEventData, path, getEventDataWithoutLoad, handleUpdateEvents, handleDeleteEvent } = props

  return(
    <Fragment>
      <div className="min-w-72">
        <div className="bg-white shadow-md flex items-center justify-between rounded-lg px-3 py-3">
          <div className="flex text-black text-sm font-medium">Open {openEvents?.length > 0 ? `(${openEvents?.length})` : ''}</div>
          <div className="flex gap-1">
            <div className="flex justify-center items-center"></div>
            <div className="flex justify-center items-center"></div>
            <div className="flex justify-center items-center"></div>
            <div className="flex justify-center items-center"></div>
          </div>
        </div>
        <div className="mt-3">
          {openEvents?.map((event, i) => (
            <div key={i}>
              <CardComponent event={event} listType={'Open'} user={user} business={business} handleAlert={handleAlert} processGetEventData={getEventDataWithoutLoad} path={path} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent}/>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  )
}