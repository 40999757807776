import React, { Fragment } from "react";

export default function StatusFilterComponent(props) {
  const { managerCount, pendingCount, acceptedCount, approvedCount, reopenCount, selectedStatus, setSelectedStatus, statusList, bucketType } = props

  const checkTypeAndShowCount = (status) => {
    const statusCounts = {
      pending_mgr: managerCount,
      pending: pendingCount,
      accepted: acceptedCount,
      confirm: approvedCount,
      reopen: reopenCount,
      accept: acceptedCount,
      decline: approvedCount
    };
    const count = statusCounts[status];
    return count > 0 ? `(${count})` : '';
  }

  const handleChangeStatus = (e, status) => {
    var __FOUND = selectedStatus.includes(status)
    if(e.target.checked){
      if(!__FOUND){
        let newStatus = [...selectedStatus, status]
        setSelectedStatus(newStatus)
      }
    }else{
      if(__FOUND){
        let newStatus = selectedStatus.filter((tag) => tag !== status)  
        setSelectedStatus(newStatus)
      }      
    }
  }

  return(
    <Fragment>
      <div className="flex flex-col items-start gap-3 mb-2">
        <div className="text-lg font-semibold text-gray-900">
          {bucketType == 'available' ? 'By Response Type' : 'By Status'}
        </div>
        <div className="flex flex-col items-start">
          {statusList.map((type, i) => 
            <label key = {i} className="flex items-center text-gray-900 text-sm font-semibold mb-2">
              <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 cursor-pointer mr-2" onChange={(e) => handleChangeStatus(e, type?.value)} value={type?.value} checked={selectedStatus.includes(type?.value)}/>
              <div className={`flex items-center gap-2 text-sm font-semibold cursor-pointer`}>
                {`${type?.label} ${checkTypeAndShowCount(type?.value)}`}
              </div>
            </label>
          )}
        </div>
      </div>
    </Fragment>
  )
}