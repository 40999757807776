import React, { Fragment, useState } from "react";
import  MessageIcon  from './../../../../assets/messageIcon';
import ReactTooltip from "react-tooltip";
import CreateCommentComponent from './../../createCommentComponent';

export default function CommentButtonComponent(props) {
  const { event, user, handleSetAlert, processFetchEventData, sentBucket } = props
  const [ showCommentAddPopup, setShowCommentAddPopup ] = useState(false)

  const handleAddComment = () => {
    setShowCommentAddPopup(true)
  }

  const handleCloseAndReloadData = (data) => {
    processFetchEventData(data)
  }
  
  return(
    <Fragment>
      {showCommentAddPopup &&
        <CreateCommentComponent eventId={event?.id} show={showCommentAddPopup} close={() => setShowCommentAddPopup(false)} user={user} handleSetAlert={handleSetAlert} processFetchEventData={handleCloseAndReloadData} sentBucket={sentBucket}/>
      }
      <button className='flex relative z-20 justify-center items-center border border-gray-300 rounded-full min-h-8 min-w-8' onClick={() => handleAddComment()} data-tip data-for={`comment_button_`+event?.id.toString()}>
        <MessageIcon classNames={'text-black wc-14 hc-14'}/>
        <ReactTooltip className='max-w-xs whitespace-nowrap' id={`comment_button_`+event?.id.toString()} place="top" effect="solid">
          Comment
        </ReactTooltip>
      </button>
    </Fragment>
  )
}