import React from 'react';

export default function EmptyEmailIcon(){

  return(
    <svg width="138" height="106" viewBox="0 0 138 106" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16.5" cy="16" r="16" fill="#0D0D0D"/>
      <path d="M40.5 5.5675C52.8685 5.5675 97.9239 5.5675 114.548 5.5675C128.015 5.5675 134.497 8.51516 134.497 29.1488C134.497 49.1967 134.331 75.165 134.497 85.6455C134.614 93.0147 130.906 101.17 118.538 102.349C106.17 103.528 56.5295 102.84 33.2555 102.349C24.6109 102.349 9.31609 97.4989 9.31653 77.7851L9.31653 40.9999" stroke="#0D0D0D" strokeWidth="5.5" strokeLinecap="round"/>
      <path d="M41.6674 40.3649L72.6116 61.2432" stroke="#0D0D0D" strokeWidth="5.5" strokeLinecap="round"/>
      <path d="M72.6116 61.2433L128.874 9.74329" stroke="#0D0D0D" strokeWidth="5.5" strokeLinecap="round"/>
      <path d="M30.4149 79.3378L53.0987 48.7162M86.8723 48.7162L117.621 79.3378" stroke="#0D0D0D" strokeWidth="5.5" strokeLinecap="round"/>
    </svg>
  )
}