import React, { Fragment, useState } from "react";
import ReactTooltip from "react-tooltip";
import UserIcon from './../../../../assets/userIcon';
import AssignPopupContentComponent from './../../assignPopupContentComponent';

export default function AssignButtonComponent(props) {
  const { event, handleAlert, handleCloseAndReloadData } = props
  const [ assignPopupType, setAssignPopupType ] = useState('')
  const [ approvedUserId, setApprovedUserId ] = useState('')
  const [ showAssignPopup, setShowAssignPopup ] = useState(false)

  const handleShowAssignPopup = (type) => {
    setAssignPopupType(type)
    if(type == 'replace'){
      setApprovedUserId(event?.approved_user?.id)
    }
    setShowAssignPopup(true)
  }

  const handleCloseAssignPopup = (data) => {
    setShowAssignPopup(data)
    setShowAssignPopup('')
    setApprovedUserId('')
  }

  return(
    <Fragment>
      {showAssignPopup &&
        <AssignPopupContentComponent show={showAssignPopup} eventId={event?.id} close={handleCloseAssignPopup} assignPopupType={assignPopupType} handleSetAlert={handleAlert} approvedUserId={approvedUserId} processFetchEventData={handleCloseAndReloadData} type={'preview'} calendarEvent={false} handleUpdateCalendarEvent={() => {}}/>
      }
      <button className='flex relative z-20 justify-center items-center border border-gray-300 rounded-full min-h-8 min-w-8' onClick={() => {['Approved', 'No Show'].includes(event?.status) ? handleShowAssignPopup('replace') : handleShowAssignPopup('assign')}} data-tip data-for={`assign_button_`+event?.id.toString()}>
        <UserIcon classNames={'text-black wc-14 hc-14'}/>
        <ReactTooltip className='max-w-xs whitespace-nowrap' id={`assign_button_`+event?.id.toString()} place="top" effect="solid">
          Assign
        </ReactTooltip>
      </button>
    </Fragment>
  )
}