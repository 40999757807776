import React from 'react'
import { createRoot } from 'react-dom/client';
import TailwindApp from '../components/TailwindApp'
import { BrowserRouter as Router, Route } from 'react-router-dom'

document.addEventListener('DOMContentLoaded', () => {
  createRoot(document.body.appendChild(document.createElement('div'))).render(    
    <Router>	    
	    <Route path="/" component={TailwindApp} />	    
    </Router>
  )
})