import React, { Fragment, useState } from "react";
import ImageComponent from './imageComponent';
import NotificationSelectionComponent from './notificationSelectionComponent';
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import { checkTheSignUpPagePath } from '../../helpers';
import ConfirmSmsNotificationsComponent from './confirmSmsNotificationsComponent';

export default function NotificationComponent(props) {
  const { user, setAlert, handleUpdateUser } = props
  const [ notifications, setNotifications ] = useState(user?.away ? 'away' : (user?.notifications || 'email'))
  const [ btnDisabled, setBtnDisabled ] = useState(false)
  const [ showGetNotifications, setShowGetNotifications ] = useState(false)

  const handleCheckBox = (e) => {
    let value = e.target.value
    setNotifications(value)
  }

  const handleSaveNotifications = (e) => {
    e.preventDefault()
    setBtnDisabled(true)
    let params = {
      notification_value: notifications
    }
    let url = '/api/v3/update_notifications'
    axiosRequest(url, 'PATCH', params, 'data').then(result => {
      if(result.status == 200){
        setAlert(true, 'success', result?.success)
        setTimeout(() => {
          setAlert(false, '', '')
          setBtnDisabled(false);
        }, 3000);
        if(['sms', 'all'].includes(notifications)){
          setShowGetNotifications(true)
        } else {
          handleUpdateUser(result?.user, checkTheSignUpPagePath(result?.user))
        }
      }else{
        setAlert(true, 'danger', result?.error)
        setTimeout(() => {
          setAlert(false, '', '')
        }, 3000);
        setBtnDisabled(false);
      }
    })
  }

  return(
    <Fragment>
      {showGetNotifications &&
        <ConfirmSmsNotificationsComponent 
          user={user}
          setAlert={setAlert}
          handleUpdateUser={handleUpdateUser}
          notifications={notifications}
          setShowGetNotifications={setShowGetNotifications}
        />
      }
      {!showGetNotifications &&
        <div className="w-full flex flex-col items-center justify-center mt-4 px-2">
          <div className="flex items-center justify-center text-center text-lg text-gray-900 font-normal">
            Optional: How would you like to receive sub requests & notifications?
          </div>
          <div className="font-light text-center text-gray-600 text-sm">
            (You can update these settings anytime in your profile)
          </div>
          <div className="flex w-full md:w-3/5 lg:w-3/6 xl:w-2/5 px-0 sm:px-5 md:px-10 lg:px-5 xl:px-16 justify-between mt-7">
            <NotificationSelectionComponent 
              user={user}
              notifications={notifications}
              handleCheckBox={handleCheckBox}
            />
            <ImageComponent notifications={notifications} />
          </div>
          <div className="w-full md:w-3/5 lg:w-3/6 xl:w-2/5 flex flex-col items-center justify-center text-center mt-7 gap-1">
            <p className="text-xs text-gray-600 text-center">
              {user?.sms_notifications_restriction ?
                `If you selected Email & SMS, you'll be able to receive, accept, decline, or approve sub requests via text if the sub request is sent within ${user.sms_notifications_restriction_days} days of the class. No more mass email or texts and their reply-alls!`
              :
                "You'll be able to receive, accept, decline, or approve sub requests via text. No more mass email or texts and their reply-alls!"
              }
            </p>
            <p className="text-xs text-gray-600 text-center">*Message and data rates may apply from your mobile service provider. Message frequency varies.</p>
          </div>
          <div className='flex flex-col items-center justify-center w-full mt-7 gap-2'>
            <button
              className={`bg-gray-900 rounded-md text-white flex items-center justify-center px-8 py-2 text-sm w-1/2 md:w-1/5 ${btnDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={(e) => handleSaveNotifications(e)}
              disabled={btnDisabled}
            >
              Next
            </button>
            <p className="font-light text-gray-600 text-sm">Text HELP for help or STOP to cancel</p>
          </div>
        </div>
      }
    </Fragment>
  )
}