import React, { Fragment } from "react";

export default function SubbingUserComponent(props) {
  const { eventData, handleShowInstructorDetails, path, type, bucketType } = props
  return(
    <Fragment>
      <div className='flex items-center mb-1'>
        <img src={eventData?.approved_user?.image} className='w-5 h-5 rounded-full cursor-pointer' onClick={() => handleShowInstructorDetails(eventData?.approved_user)}/>
        <div className={`text-xs font-semibold ml-2`}>Sub:</div>
        <button className={`custom-blue-200 text-xs font-semibold ml-1`} onClick={() => handleShowInstructorDetails(eventData?.approved_user)}>
          {(['my-requests']?.includes(path) && bucketType == "Approved")
            ? "Me"
            : eventData?.approved_user?.full_name
          }
        </button>
      </div>
    </Fragment>
  )
}