import React, { Fragment, useEffect, useState, useRef } from "react";
import { axiosGetRequest, handleStartDate, handleEndDate, handleSetEventsData, handleCheckCurrentDate, handleStartDateOfweek, handleEndDateOfWeek, veryFirstSetStartDate, veryFirstSetEndDate, filterEventsAsPerFilters, seprateOutLocations, checkFilterSaved, seprateOutApprovalErrorEvents, axiosRequest, currentYearFirstDateByCalendar, currentYearLastDateByCalendar, checkSavedAndComingLocations, checkAllInstructors, checkAllClasses, checkInstructors, checkClasses, handleCheckShowAll, handleRemoveCurrentDate, checkShowRegions, updateCalendarEvent, removeCalendarEvent, checkFiltersState, checkLocations, checkMyRequestFiltersState, checkMyRequestLocations } from '../../helpers/helpers'
import LoaderComponent from './../components/loaderComponent/loaderComponent'
import ShowEventDeatilsComponent from './../components/showEventDeatilsComponent/showEventDeatilsComponent';
import FiltersComponent from './../components/filtersComponent/filtersComponent';
import MonthViewCalendarComponent from './../components/calendarRangeTypeSelectorComponents/monthViewCalendarComponent';
import WeekViewCalendarComponent from './../components/calendarRangeTypeSelectorComponents/weekViewCalendarComponent';
import CalendarApprovalErrorEventsPopupComponent from './../components/calendarApprovalErrorEventsPopupComponent';
import DayViewCalendarComponent from './../components/calendarRangeTypeSelectorComponents/dayViewCalendarComponent';
import YearViewCalendarComponent from './../components/calendarRangeTypeSelectorComponents/yearViewCalendarComponent';
import { Redirect } from 'react-router-dom'
import { getSentBucketDefaultSelection, getAvaialbleBucketDefaultSelection, getAffectedVaraibles, getVariableNameByStatus } from '../../helpers/supportHelpers';

export default function CalendarView(props) {
  const { loadingMessage, business, user, activeTab, path, selectedLocationsFilter, statusesFilter, selectedRegionsFilter, selectedInstructorsFilter, selectedClassesFilter, calendarViewTypeFilter, totalApiCallCount, allRegionsLocations, allInstructors, allClassesData, handleSaveData, dataApiCallCount, handleSaveFilter, hanldeClickOnWarningPopup, showApprovalErrorEventsPopup, updateApprovalErrorEventsCount, sentStatusesFilter, availableStatusFilter, availableLocationFilter, availableRegionFilter } = props
  const [ events, setEvents ] = useState([])
  const calendarRef = useRef(null);
  const [ currentDate, setCurrentDate ] = useState(handleCheckCurrentDate());
  useEffect(() => {
    handleRemoveCurrentDate();
  }, []);
  const [ startDate, setStartDate ] = useState(veryFirstSetStartDate(currentDate, (calendarViewTypeFilter || 'month'), activeTab))
  const [ endDate, setEndDate ] = useState(veryFirstSetEndDate(currentDate, (calendarViewTypeFilter || 'month'), activeTab))
  const [ loaded, setLoaded ] = useState(false)
  const [ showEventPopup, setShowEventPopup ] = useState(false)
  const [ eventIdToShow, setEventIdToShow ] = useState('')
  const [ approvedEvents, setApprovedEvents ] = useState([])
  const [ cancelledEvents, setCancelledEvents ] = useState([])
  const [ managerEvents, setManagerEvents ] = useState([])
  const [ noShowEvents, setNoShowEvents ] = useState([])
  const [ openEvents, setOpenEvents ] = useState([])
  const [ pendingEvents, setPendingEvents ] = useState([])
  const allStatuses = (business?.manager_request_gate ? ['approved', 'cancelled', 'mgr', 'no_show', 'open', 'pending'] : ['approved', 'cancelled', 'no_show', 'open', 'pending'])
  const [ statusFilter, setStatusFilter ] = useState(statusesFilter || allStatuses)
  const [ regionLocationsList, setRegionLocationsList ] = useState(path === 'all-requests' ? (allRegionsLocations || []) : [])
  const [ showRegions, setShowRegions ] = useState(path !== 'all-requests')
  const [ selectedLocations, setSelectedLocations ] = useState(path === 'all-requests' ? (selectedLocationsFilter || []) : availableLocationFilter)
  const [ selectedRegions, setSelectedRegions ] = useState(path === 'all-requests' ? (selectedRegionsFilter || []) : availableRegionFilter)
  const [ sentEvents, setSentEvents ] = useState([])
  const [ availableEvents, setAvailableEvents ] = useState([])
  const [ myRequestsTab, setMyRequestsTab ] = useState('sent')
  const [ selectedMonth, setSelectedMonth ] = useState(currentDate)
  const [ calendarViewType, setCalendarViewType ] = useState(calendarViewTypeFilter || 'month')
  const [ selectedWeek, setSelectedWeek ] = useState(currentDate)
  const [ apiCallCount, setApiCallCount ] = useState(dataApiCallCount || 0)
  const [ selectedInstructors, setSelectedInstructors ] = useState(selectedInstructorsFilter || [])
  const [ selectedClasses, setSelectedClasses ] = useState(selectedClassesFilter || [])
  const [ selectedYear, setSelectedYear ] = useState(currentDate)
  const [ loadedCalendar, setLoadedCalendar ] = useState(true)
  const [ approvalErrorEvents, setApprovalErrorEvents ] = useState([])
  const [ approvedCount, setApprovedCount ] = useState(0)
  const [ cancelledCount, setCancelledCount ] = useState(0)
  const [ managerCount, setManagerCount ] = useState(0)
  const [ noShowCount, setNoShowCount ] = useState(0)
  const [ openCount, setOpenCount ] = useState(0)
  const [ pendingCount, setPendingCount ] = useState(0)
  const [ sentCount, setSentCount ] = useState(0)
  const [ availableCount, setAvailableCount ] = useState(0)
  const [ eventDetailUrl, setEventDetailUrl ] = useState('')
  const [ redirectToDetailPage, setRedirectToDetailPage ] = useState(false)
  const [ showAllEvents, setShowAllEvents ] = useState(handleCheckShowAll() || false)
  const [ dateToSave, setDateToSave ] = useState('')
  const allSentStatuses = getSentBucketDefaultSelection(business?.manager_request_gate)
  const [ sentStatusFilter, setSentStatusFilter ] = useState((sentStatusesFilter.length > 0 ? sentStatusesFilter : allSentStatuses) || allSentStatuses)
  const [ sentFilterEventsCount, setSentFiltersEventsCount ] = useState({
    managerCount: 0,
    pendingCount: 0,
    acceptedCount: 0,
    approvedCount: 0,
    reopenCount: 0
  })
  const [ availableSelectedStatus, setAvailableSelectedStatus ] = useState(getAvaialbleBucketDefaultSelection(availableStatusFilter))
  const [ availableFilterEventsCount, setAvailableFilterEventsCount ] = useState({
    pendingCount: 0,
    acceptCount: 0,
    declineCount: 0
  })

  useEffect(() => {
    if(startDate !== '' && endDate !== ''){
      if(['all-requests'].includes(path)){
        if(dataApiCallCount !== 0){
          setLoadedCalendar(false)
        }
        processGetEventData(path === 'all-requests')
      }else{
        setLoadedCalendar(false)
        fetchMyRequests(sentStatusFilter, true, availableSelectedStatus, selectedLocations)
      }
    }
  }, [startDate, endDate])

  const handleSelectStatusFilter = (statuses) => {
    setStatusFilter(statuses)
    mergeEventsAsPerFilters(statuses, selectedLocations, selectedInstructors, selectedClasses)
  }

  const mergeEventsAsPerFilters = (selectedStatusData, selectedLocationsData, selectedInstructorsData, selectedClassesData) => {
    let selectedInstructorsIds = selectedInstructorsData?.map(function(a) {return a?.id});
    let selectedClassesIds = selectedClassesData?.map(function(a) {return a?.id});
    if(calendarViewType == 'year'){
      setLoadedCalendar(false)
      if(['all-requests'].includes(path)){
        window.setTimeout(() => {
          processGetEventDataForYearView(false, selectedLocationsData, selectedInstructorsIds, selectedClassesIds, selectedStatusData)
        }, 200)
      }else{
        fetchMyRequests(sentStatusFilter, false, availableSelectedStatus, selectedLocations)
      }
      return
    }
    let locations = seprateOutLocations(regionLocationsList).map(function(a) {return a.id;});
    
    window.setTimeout(() => {
      let events = filterEventsAsPerFilters(business, selectedStatusData, selectedLocationsData, selectedInstructorsIds, selectedClassesIds, approvedEvents, cancelledEvents, managerEvents, noShowEvents, openEvents, pendingEvents, path, locations, [], [], allStatuses, user?.id) || []
      setEvents(events)
    }, 200)
  }

  const processGetEventDataForYearView = (initialize, locationIds, instructorIds, classIds, statuses) => {
    updateApprovalErrorEventsCount(0)
    let url = `${window._sub_board_url}/api/v3/sub_overview/get_location_events`
    let instructor_status = ['all_selected', 'no_selected'].includes(checkFiltersState(user?.id, 'instructor'))
    let class_status = ['all_selected', 'no_selected'].includes(checkFiltersState(user?.id, 'class'))
    let initialsValue = (initialize ? initialize : dataApiCallCount == 0)
    let params = {
      start_date: startDate,
      end_date: endDate,
      event_type: undefined,
      view_type: activeTab,
      initialize: initialsValue,
      version: 'v2',
      year_view: true,
      location_ids: locationIds,
      instructor_ids: instructorIds,
      class_ids: classIds,
      statuses: statuses,
      all_instructors: instructor_status,
      all_classes: class_status
    }
    axiosRequest(url, 'POST', params, 'data').then((response) => {
      if(response.status == 200){
        setApiCallCount(apiCallCount + 1)
        let results = Object.values(response?.results).flat()
        let data = results
        setEvents(data || [])
        if(initialize || dataApiCallCount == 0){
          setRegionLocationsList(response?.locations || [])
          setShowRegions(checkShowRegions(response?.locations))
          handleSaveData(response?.locations || [], response?.instructors || [], response?.classes || [], (dataApiCallCount + 1))
          setApprovedCount(response?.approved_count)
          setCancelledCount(response?.cancelled_count)
          setManagerCount(response?.manager_count)
          setNoShowCount(response?.no_show_count)
          setOpenCount(response?.open_count)
          setPendingCount(response?.pending_count)
          if(initialize || totalApiCallCount == 0){
            let regions = response?.locations.map(function(a) {return a.region?.name;});
            let locations = seprateOutLocations(response?.locations).map(function(a) {return a.id;});
            let instructorIds = response?.instructors;
            let classIds = response?.classes;

            let locationState = checkFiltersState(user?.id, 'location')
            let [locationsToBeSelected, regionsToBeSelected] = locationState == 'all_selected' ? [locations, regions] : checkLocations(locations, regions, selectedLocations, selectedRegions, response?.locations, user?.id)
            setSelectedLocations(locationsToBeSelected)
            setSelectedRegions(regionsToBeSelected)
            let instructorsToBeSelected = checkFiltersState(user?.id, 'instructor') == 'all_selected' ? instructorIds : selectedInstructors
            setSelectedInstructors(instructorsToBeSelected)
            let classesToBeSelected = checkFiltersState(user?.id, 'class') == 'all_selected' ? classIds : selectedClasses
            setSelectedClasses(classesToBeSelected)
            let statusToBeSelected = checkFiltersState(user?.id, 'status') == 'all_selected' ? allStatuses : statusFilter
            setStatusFilter(statusToBeSelected)
          }
        }
        setLoaded(true)
        setLoadedCalendar(true)
      }else{
        setEvents([])
        setApprovedEvents([])
        setCancelledEvents([])
        setManagerEvents([])
        setNoShowEvents([])
        setOpenEvents([])
        setPendingEvents([])
        setRegionLocationsList([])
        setShowRegions(false)
        setSelectedRegions([])
        setSelectedLocations([])
        setLoaded(true)
        setLoadedCalendar(true)
      }
    })
  }

  const processGetEventData = (initialize) => {
    if(calendarViewType == 'year'){
      let selectedInstructorsIds = selectedInstructors?.map(function(a) {return a?.id});
      let selectedClassesIds = selectedClasses?.map(function(a) {return a?.id});
      processGetEventDataForYearView(initialize, selectedLocations, selectedInstructorsIds, selectedClassesIds, statusFilter)
      return
    }
    let url = `${window._sub_board_url}/api/v3/sub_overview/get_location_events?start_date=${startDate}&end_date=${endDate}&event_type=undefined&view_type=${activeTab}&initialize=${initialize ? initialize : dataApiCallCount == 0}&version=${'v2'}&year_view=${calendarViewType == 'year'}`
    axiosGetRequest(url).then((response) => {
      if(response.status == 200){
        setApiCallCount(apiCallCount + 1)
        let approve_results = handleSetEventsData(response?.results?.approved, path) || []
        setApprovedEvents(approve_results)
        let cancelled_results = handleSetEventsData(response?.results?.cancelled, path) || []
        setCancelledEvents(cancelled_results)
        let manager_results = handleSetEventsData(response?.results?.manager, path) || []
        setManagerEvents(manager_results)
        let no_show_results = handleSetEventsData(response?.results?.no_show, path) || []
        setNoShowEvents(no_show_results)
        let open_results = handleSetEventsData(response?.results?.open, path) || []
        setOpenEvents(open_results)
        let pending_results = handleSetEventsData(response?.results?.pending, path) || []
        setPendingEvents(pending_results)
        let errorApprovalEvents = seprateOutApprovalErrorEvents(approve_results, cancelled_results, manager_results, no_show_results, open_results, pending_results)
        setApprovalErrorEvents(errorApprovalEvents)
        updateApprovalErrorEventsCount(errorApprovalEvents.length)
        if(initialize || dataApiCallCount == 0){
          setApprovedCount(response?.approved_count || 0)
          setCancelledCount(response?.cancelled_count || 0)
          setManagerCount(response?.manager_count || 0)
          setNoShowCount(response?.no_show_count || 0)
          setOpenCount(response?.open_count || 0)
          setPendingCount(response?.pending_count || 0)
          setRegionLocationsList(response?.locations || [])
          setShowRegions(checkShowRegions(response?.locations))
          handleSaveData(response?.locations || [], response?.instructors || [], response?.classes || [], (dataApiCallCount + 1))
          let locations = seprateOutLocations(response?.locations).map(function(a) {return a.id;});
          let instructorIds = response?.instructors;
          let classIds = response?.classes;
          let regions = response?.locations.map(function(a) {return a.region?.name;});

          let locationState = checkFiltersState(user?.id, 'location')
          let [locationsToBeSelected, regionsToBeSelected] = locationState == 'all_selected' ? [locations, regions] : checkLocations(locations, regions, selectedLocations, selectedRegions, response?.locations, user?.id)
          setSelectedLocations(locationsToBeSelected)
          setSelectedRegions(regionsToBeSelected)
          let instructorsToBeSelected = checkFiltersState(user?.id, 'instructor') == 'all_selected' ? instructorIds : selectedInstructors
          setSelectedInstructors(instructorsToBeSelected)
          let classesToBeSelected = checkFiltersState(user?.id, 'class') == 'all_selected' ? classIds : selectedClasses
          setSelectedClasses(classesToBeSelected)
          let statusToBeSelected = checkFiltersState(user?.id, 'status') == 'all_selected' ? allStatuses : statusFilter
          setStatusFilter(statusToBeSelected)
          let selectedInstructorsIds = instructorsToBeSelected?.map(function(a) {return a?.id});
          let selectedClassesIds = classesToBeSelected?.map(function(a) {return a?.id});
          setEvents(filterEventsAsPerFilters(business, statusToBeSelected, locationsToBeSelected, selectedInstructorsIds, selectedClassesIds, approve_results, cancelled_results, manager_results, no_show_results, open_results, pending_results, path, locations, instructorIds, classIds, allStatuses, user?.id) || [])
        }
        setLoaded(true)
        setLoadedCalendar(true)
      }else{
        setEvents([])
        setApprovedEvents([])
        setCancelledEvents([])
        setManagerEvents([])
        setNoShowEvents([])
        setOpenEvents([])
        setPendingEvents([])
        setRegionLocationsList([])
        setShowRegions(false)
        setSelectedRegions([])
        setSelectedLocations([])
        setLoaded(true)
        setLoadedCalendar(true)
      }
    })
  }

  const closeEventPopup = () => {
    setShowEventPopup(false)
    setEventIdToShow('')
  }

  const handleEventClick = (info) => {
    info.jsEvent.preventDefault();
    let calendarDate = calendarViewType == 'month' ? selectedMonth : currentDate
    if(['all-requests', 'my-requests'].includes(path)){
      let publicId = parseInt(info?.event?._def.publicId) || info?.event?._def.publicId
      setEventIdToShow(publicId)
      setDateToSave(calendarDate)
      setShowEventPopup(true)
    }else{
      localStorage.setItem("calendarDate", calendarDate);
      let openInNewTab = info?.jsEvent?.ctrlKey;
      let redirectUrl = info?.event?._def.extendedProps?.redirect_url;
      if (openInNewTab) {
        window.open(redirectUrl, '_blank');
      } else {
        setEventDetailUrl(redirectUrl);
        setRedirectToDetailPage(true);
      }
    }
  }

  const hanldleSelectLocations = (regions, locations) => {
    setSelectedLocations(locations)
    setSelectedRegions(regions)
    switch(path){
      case 'all-requests':
        mergeEventsAsPerFilters(statusFilter, locations, selectedInstructors, selectedClasses)
        break;
      case 'my-requests':
        setLoadedCalendar(false)
        fetchMyRequests(sentStatusFilter, false, availableSelectedStatus, locations)
        break;
    }
  }

  const fetchMyRequests = (sentStatuses, initialize, availableStatuses, availableLocations) => {
    let url = `${window._sub_board_url}/api/v3/business_events?start_date=${startDate}&end_date=${endDate}&view_type=${activeTab}&year_view=${calendarViewType == 'year'}&sent_statuses=${sentStatuses}&initialize=${initialize}&available_statuses=${availableStatuses}&available_locations=${availableLocations}`
    axiosGetRequest(url).then((result) => {
      if(result.status == 200){
        setApprovedEvents(handleSetEventsData(result?.approved, path))
        setSentEvents(handleSetEventsData(result?.sent, path))
        setAvailableEvents(handleSetEventsData(result?.available, path))
        let eventData = []
        if(myRequestsTab == 'sent'){
          eventData = calendarViewType == 'year' ? result?.sent : handleSetEventsData(result?.sent, path)
        }else if(myRequestsTab == 'available'){
          eventData = calendarViewType == 'year' ? result?.available : handleSetEventsData(result?.available, path)
        }else if(myRequestsTab == 'approved'){
          eventData = calendarViewType == 'year' ? result?.approved : handleSetEventsData(result?.approved, path)
        }
        if(calendarViewType == 'year'){
          setSentCount(result?.sent_count || 0)
          setAvailableCount(result?.available_count || 0)
          setApprovedCount(result?.approved_count || 0)
        } else{
          setApprovedCount(result?.approved?.length || 0)
        }
        setSentFiltersEventsCount({
          managerCount: result?.sent_bucket_filters_count?.sent_pending_mgr_count,
          pendingCount: result?.sent_bucket_filters_count?.sent_open_count,
          acceptedCount: result?.sent_bucket_filters_count?.sent_pending_count,
          approvedCount: result?.sent_bucket_filters_count?.sent_approved_count,
          reopenCount: result?.sent_bucket_filters_count?.sent_re_open_count
        })
        setAvailableFilterEventsCount({
          pendingCount: result?.available_bucket_filters_count?.available_pending_count,
          acceptCount: result?.available_bucket_filters_count?.available_accept_count,
          declineCount: result?.available_bucket_filters_count?.available_decline_count
        })
        if(initialize){
          setRegionLocationsList(result?.locations || [])
          setShowRegions(checkShowRegions(result?.locations))
          let regions = result?.locations.map(function(a) {return a.region?.name;});
          let locations = seprateOutLocations(result?.locations).map(function(a) {return a.id;});
          let locationState = checkMyRequestFiltersState(user?.id, 'location', 'available')
          let [locationsToBeSelected, regionsToBeSelected] = checkMyRequestLocations(locations, regions, selectedLocations, selectedRegions, result?.locations, user?.id, 'available')
          setSelectedLocations(locationsToBeSelected)
          setSelectedRegions(regionsToBeSelected)
        }
        setEvents(eventData || [])
        setLoaded(true)
        setLoadedCalendar(true)
      }else{
        setEvents([])
        setApprovedEvents([])
        setSentEvents([])
        setAvailableEvents([])
        setRegionLocationsList([])
        setShowRegions(false)
        setSelectedRegions([])
        setSelectedLocations([])
        setLoaded(true)
        setLoadedCalendar(true)
      }
    })
  }

  const handleMyRequestsTab = (data) => {
    setMyRequestsTab(data)
    if(data == 'sent'){
      setEvents(sentEvents || [])
    }else if(data == 'available'){
      setEvents(availableEvents || [])
    }else if(data == 'approved'){
      setEvents(approvedEvents || [])
    }
  }

  const handleMonthChange = (date) => {
    setSelectedMonth(date)
    if(date.getMonth() !== new Date().getMonth()){
      setCurrentDate(handleStartDate(date))
    }else{
      setCurrentDate(date)
    }
    changeMonth(date.getFullYear(), date.getMonth())
  }

  const changeMonth = (full_year, month) => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(new Date(full_year, month, 1));
    let start = calendarApi.view.activeStart
    let end = calendarApi.view.activeEnd
    end.setDate(end.getDate() - 1);
    setStartDate((new Date(start)).toLocaleDateString('en-GB'))
    setEndDate((new Date(end)).toLocaleDateString('en-GB'))
  };

  const handleCalendarViewType = (data) => {
    // let current_date = new Date()
    // setCurrentDate(current_date)
    if(calendarViewType == 'year' && data !== 'year'){
      setEvents([])
    }
    let current_date = currentDate
    if(data == 'month'){
      if(currentDate.getMonth() !== new Date().getMonth()){
        setCurrentDate(handleStartDate(current_date))
        setSelectedMonth(handleStartDate(current_date))
      }else{
        setSelectedMonth(current_date)
      }
      setStartDate(handleStartDate(current_date).toLocaleDateString('en-GB'))
      setEndDate(handleEndDate(current_date).toLocaleDateString('en-GB'))
    }else if(data == 'week'){
      setSelectedWeek(current_date)
      setStartDate(handleStartDateOfweek(current_date).toLocaleDateString('en-GB'))
      setEndDate(handleEndDateOfWeek(current_date).toLocaleDateString('en-GB'))
    }else if(data == 'year'){
      setSelectedYear(current_date)
      setStartDate(currentYearFirstDateByCalendar(current_date).toLocaleDateString('en-GB'))
      setEndDate(currentYearLastDateByCalendar(current_date).toLocaleDateString('en-GB'))
    }else if(data == 'day'){
      setCurrentDate(current_date)
      setStartDate(current_date.toLocaleDateString('en-GB'))
      setEndDate(current_date.toLocaleDateString('en-GB'))
    }
    setCalendarViewType(data)
    handleSaveFilter(data, apiCallCount)
  }

  const handleWeekChange = (data) => {
    if(calendarViewType == 'week'){
      setSelectedWeek(data)
      // setCurrentDate(data)
      setCurrentDate(handleStartDateOfweek(data))
      changeWeek(data)
    }else if(calendarViewType == 'day'){
      setCurrentDate(data)
      changeDay(data)
    }else if(calendarViewType == 'year'){
      setSelectedYear(data)
      setCurrentDate(data)
      changeYear(data)
    }
  }

  const changeYear = (date) => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(date);
    let start = calendarApi.view.activeStart
    let end = calendarApi.view.activeEnd
    setStartDate((new Date(start)).toLocaleDateString('en-GB'))
    setEndDate((new Date(end)).toLocaleDateString('en-GB'))
  }

  const changeWeek = (date) => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(date);
    let start = calendarApi.view.activeStart
    let end = calendarApi.view.activeEnd
    end.setDate(end.getDate() - 1);
    setStartDate((new Date(start)).toLocaleDateString('en-GB'))
    setEndDate((new Date(end)).toLocaleDateString('en-GB'))
  };

  const changeDay = (date) => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(date);
    let starEndDate = (new Date(date)).toLocaleDateString('en-GB')
    setStartDate(starEndDate)
    setEndDate(starEndDate)
  }
  
  const handleSelectInstructors = (instructors) => {
    setSelectedInstructors(instructors)
    mergeEventsAsPerFilters(statusFilter, selectedLocations, instructors, selectedClasses)
  }

  const handleSelectClasses = (classes) => {
    setSelectedClasses(classes)
    mergeEventsAsPerFilters(statusFilter, selectedLocations, selectedInstructors, classes)
  }

  const hanldeClickOnErrorEvent = (e, id, redirectUrl) => {
    e.preventDefault()
    if(['all-requests', 'my-requests'].includes(path)){
      let publicId = parseInt(id) || id
      setEventIdToShow(publicId)
      setShowEventPopup(true)
    }else{
      if (calendarViewType == 'month'){
        localStorage.setItem("calendarDate", selectedMonth);
      } else {
        localStorage.setItem("calendarDate", currentDate);
      }
      let openInNewTab = e.ctrlKey || e.metaKey;
      if (openInNewTab) {
        window.open(redirectUrl, '_blank');
      } else {
        setEventDetailUrl(redirectUrl);
        setRedirectToDetailPage(true)
      }
    }
  }

  const handleSetShowAllEvents = (data) => {
    setShowAllEvents(data)
    if(data){
      localStorage.setItem("showAllEvents", data);
    }else{
      localStorage.removeItem("showAllEvents");
    }
  }

  const handleUpdateCalendarEvent = (data, type) => {
    if(type && type !== '' && type.includes('deleted_')){
      let eventId = parseInt(type.split('_')[1])
      if(eventId) {
        setEvents(removeCalendarEvent(events, eventId))
        if(['all-requests'].includes(path)){
          setApprovedEvents(removeCalendarEvent(approvedEvents, eventId))
          setCancelledEvents(removeCalendarEvent(cancelledEvents, eventId))
          setManagerEvents(removeCalendarEvent(managerEvents, eventId))
          setNoShowEvents(removeCalendarEvent(noShowEvents, eventId))
          setOpenEvents(removeCalendarEvent(openEvents, eventId))
          setPendingEvents(removeCalendarEvent(pendingEvents, eventId))
        }else{
          setApprovedEvents(removeCalendarEvent(approvedEvents, eventId))
          setSentEvents(removeCalendarEvent(sentEvents, eventId))
          setAvailableEvents(removeCalendarEvent(availableEvents, eventId))
        }
      }
    }else if(type && type !== '' && type == 'updated' && data && data !== '' && calendarViewType !== 'year'){
      let newEvents = []
      newEvents.push(data)
      let newEvent = (handleSetEventsData(newEvents, path) || [])[0]
      setEvents(updateCalendarEvent(events, newEvent))
      if(['all-requests'].includes(path)){
        setApprovedEvents(updateCalendarEvent(approvedEvents, newEvent))
        setCancelledEvents(updateCalendarEvent(cancelledEvents, newEvent))
        setManagerEvents(updateCalendarEvent(managerEvents, newEvent))
        setNoShowEvents(updateCalendarEvent(noShowEvents, newEvent))
        setOpenEvents(updateCalendarEvent(openEvents, newEvent))
        setPendingEvents(updateCalendarEvent(pendingEvents, newEvent))
      }else{
        setApprovedEvents(updateCalendarEvent(approvedEvents, newEvent))
        setSentEvents(updateCalendarEvent(sentEvents, newEvent))
        setAvailableEvents(updateCalendarEvent(availableEvents, newEvent))
      }
    }
  }

  const setMyRequestStatusFilter = (data, type) => {
    setLoadedCalendar(false)
    switch(type){
      case 'sent':
        setSentStatusFilter(data)
        fetchMyRequests(data, false, availableSelectedStatus, selectedLocations)
        break;
      case 'available':
        setAvailableSelectedStatus(data)
        fetchMyRequests(sentStatusFilter, false, data, selectedLocations)
        break;
    }
  }

  const updateAvailableEventsCount = (oldEvent, newEvent) => {
    if (!newEvent || !oldEvent) return;
    let oldEvents = [oldEvent]
    let [ reduceVar, increaseVar ] = getAffectedVaraibles(oldEvents, newEvent);
    if(newEvent.status == "Approved") increaseVar = ''
    if (!reduceVar || (reduceVar == '') || (reduceVar == increaseVar)) return;
    setAvailableFilterEventsCount(prevState => ({
      ...prevState,
      [reduceVar]: Math.max(prevState[reduceVar] - 1, 0),
      ...(increaseVar && {[increaseVar]: prevState[increaseVar] + 1})
    }))
  }

  const updateSentEventsCount = (oldEvent, newEvent) => {
    if (!oldEvent) return;
    const reduceVar = getVariableNameByStatus(oldEvent) || ''
    const increaseVar = (newEvent ? getVariableNameByStatus(newEvent) || '' : '')
    if (!reduceVar || (reduceVar == '') || (reduceVar == increaseVar)) return;
    setSentFiltersEventsCount(prevState => ({
      ...prevState,
      [reduceVar]: Math.max(prevState[reduceVar] - 1, 0),
      ...(increaseVar && { [increaseVar]: prevState[increaseVar] + 1 })
    }));
  }

  return (
    <Fragment>
      {(redirectToDetailPage && eventDetailUrl !== '') &&
        <Redirect push to={`${eventDetailUrl}`} />
      }
      {showEventPopup &&
        <ShowEventDeatilsComponent eventId={eventIdToShow} show={showEventPopup} close={closeEventPopup} title={''} business={business} user={user} processGetEventData={processGetEventData} path={path} myRequestsTab={myRequestsTab} dateToSave={dateToSave} handleUpdateCalendarEvent={handleUpdateCalendarEvent} updateAvailableEventsCount={updateAvailableEventsCount} updateSentEventsCount={updateSentEventsCount}/>
      }
      {showApprovalErrorEventsPopup &&
        <CalendarApprovalErrorEventsPopupComponent approvalErrorEvents={approvalErrorEvents} showApprovalErrorEventsPopup={showApprovalErrorEventsPopup} closeshowApprovalErrorEventsPopup={(data) => {hanldeClickOnWarningPopup(data)}} path={path} hanldeClickOnErrorEvent={hanldeClickOnErrorEvent}/>
      }
      <div className={`mt-5 w-full h-500 pt-100`}>
        {!loaded &&
          <LoaderComponent loadingMessage={loadingMessage}/>
        }
        {loaded &&
          <Fragment>
            <FiltersComponent 
              selectedStatus={statusFilter} 
              setSelectedStatus={handleSelectStatusFilter} 
              business={business} 
              approvedCount={approvedCount} 
              cancelledCount={cancelledCount} 
              managerCount={managerCount} 
              noShowCount={noShowCount} 
              openCount={openCount} 
              pendingCount={pendingCount} 
              regionLocationsList={regionLocationsList} 
              showRegions={showRegions}
              selectedLocations={selectedLocations} 
              hanldleSelectLocations={hanldleSelectLocations} 
              selectedRegions={selectedRegions} 
              myRequestsTab={myRequestsTab} 
              handleMyRequestsTab={handleMyRequestsTab} 
              sentCount={calendarViewType == 'year' ? sentCount : sentEvents.length} 
              availableCount={calendarViewType == 'year' ? availableCount :  availableEvents.length} 
              path={path} 
              selectedMonth={selectedMonth} 
              setSelectedMonth={handleMonthChange} 
              calendarViewType={calendarViewType}
              handleCalendarViewType={handleCalendarViewType}
              currentDate={currentDate}
              selectedWeek={selectedWeek}
              handleWeekChange={handleWeekChange}
              selectedInstructors={selectedInstructors} 
              handleSelectInstructors={handleSelectInstructors} 
              handleSelectClasses={handleSelectClasses} 
              selectedClasses={selectedClasses} 
              activeTab={activeTab}
              selectedYear={selectedYear}
              showAllEvents={showAllEvents}
              handleSetShowAllEvents={handleSetShowAllEvents}
              user={user}
              setMyRequestStatusFilter={setMyRequestStatusFilter}
              sentManagerCount={sentFilterEventsCount?.managerCount}
              sentPendingCount={sentFilterEventsCount?.pendingCount}
              sentApprovedCount={sentFilterEventsCount?.approvedCount}
              sentAcceptedCount={sentFilterEventsCount?.acceptedCount}
              sentReopenCount={sentFilterEventsCount?.reopenCount}
              sentStatusFilter={sentStatusFilter}
              availableSelectedStatus={availableSelectedStatus}
              availablePendingCount={availableFilterEventsCount?.pendingCount}
              availableAcceptCount={availableFilterEventsCount?.acceptCount}
              availableDeclineCount={availableFilterEventsCount?.declineCount}
            />
            <div className="w-full h-500 pt-100 calendar_view pb-10 px-8 bg-white relative">
              {!loadedCalendar &&
                <div className="absolute z-10 bg-gray-400 bg-opacity-10 flex flex-col items-center w-full justify-center h-screen calendar-loader-screen">
                  <LoaderComponent loadingMessage={''}/>
                </div>
              }
              {calendarViewType == 'month' &&
                <MonthViewCalendarComponent events={events} calendarRef={calendarRef} currentDate={currentDate} handleEventClick={handleEventClick} showAllEvents={showAllEvents}/>
              }
              {calendarViewType == 'week' &&
                <WeekViewCalendarComponent 
                  events={events} 
                  calendarRef={calendarRef} 
                  currentDate={currentDate} 
                  handleEventClick={handleEventClick}
                  hanldeClickOnErrorEvent={hanldeClickOnErrorEvent}
                  showAllEvents={showAllEvents}
                />
              }
              {calendarViewType == 'day' &&
                <DayViewCalendarComponent 
                  events={events} 
                  calendarRef={calendarRef} 
                  currentDate={currentDate} 
                  handleEventClick={handleEventClick}
                  hanldeClickOnErrorEvent={hanldeClickOnErrorEvent}
                  showAllEvents={showAllEvents}
                />
              }
              {calendarViewType == 'year' &&
                <YearViewCalendarComponent 
                  events={events} 
                  calendarRef={calendarRef} 
                  currentDate={currentDate} 
                  handleEventClick={handleEventClick} 
                  hanldeClickOnErrorEvent={hanldeClickOnErrorEvent}
                  selectedInstructors={selectedInstructors}
                  selectedClasses={selectedClasses}
                  selectedLocations={selectedLocations}
                  statusFilter={statusFilter}
                  path={path}
                  myRequestsTab={myRequestsTab}
                  sentStatuses={sentStatusFilter}
                  availableStatuses={availableSelectedStatus}
                />
              }
            </div>
          </Fragment>
        }
      </div>
    </Fragment>
  )
}
