import React, { Fragment, useState } from 'react';
import ChannelPreviewTimeStamp from './../channelPreviewTimeStamp/channelPreviewTimeStamp'

export default function CustomSystemMessage (props){
  const { message, dataFormat } = props
  const [ lastMessageTime, setLastMessageTime ] = useState(message?.created_at ? ChannelPreviewTimeStamp(message?.created_at, dataFormat) : '')
  return(
    <Fragment>
      <div className="flex items-center justify-center">
        <div className="text-sm text-gray-500 py-7 text-center">
          <div>{message?.text}</div>
          <div className="text-xs">{lastMessageTime}</div>
        </div>
      </div>
    </Fragment>
  )
}