import React, { Fragment } from "react";

export default function InfoIcon({classNames, customColour}) {
  return(
    <Fragment>
      <svg className={classNames} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 0.5C6.41775 0.5 4.87103 0.969192 3.55544 1.84824C2.23985 2.72729 1.21447 3.97672 0.608967 5.43853C0.00346632 6.90034 -0.15496 8.50887 0.153721 10.0607C0.462403 11.6126 1.22433 13.038 2.34315 14.1569C3.46197 15.2757 4.88743 16.0376 6.43928 16.3463C7.99113 16.655 9.59966 16.4965 11.0615 15.891C12.5233 15.2855 13.7727 14.2602 14.6518 12.9446C15.5308 11.629 16 10.0822 16 8.5C15.9977 6.37897 15.1541 4.34547 13.6543 2.84568C12.1545 1.34589 10.121 0.502294 8 0.5ZM8.16667 3.83333C8.36445 3.83333 8.55779 3.89198 8.72224 4.00186C8.88669 4.11174 9.01486 4.26792 9.09055 4.45065C9.16624 4.63338 9.18604 4.83444 9.14745 5.02842C9.10887 5.2224 9.01363 5.40059 8.87378 5.54044C8.73392 5.68029 8.55574 5.77553 8.36176 5.81412C8.16778 5.8527 7.96671 5.8329 7.78399 5.75721C7.60126 5.68152 7.44508 5.55335 7.3352 5.3889C7.22532 5.22445 7.16667 5.03111 7.16667 4.83333C7.16667 4.56812 7.27203 4.31376 7.45956 4.12623C7.6471 3.93869 7.90145 3.83333 8.16667 3.83333ZM9.66667 12.8333H7C6.82319 12.8333 6.65362 12.7631 6.5286 12.6381C6.40357 12.513 6.33334 12.3435 6.33334 12.1667C6.33334 11.9899 6.40357 11.8203 6.5286 11.6953C6.65362 11.5702 6.82319 11.5 7 11.5H7.5C7.54421 11.5 7.5866 11.4824 7.61785 11.4512C7.64911 11.4199 7.66667 11.3775 7.66667 11.3333V8.33333C7.66667 8.28913 7.64911 8.24674 7.61785 8.21548C7.5866 8.18423 7.54421 8.16667 7.5 8.16667H7C6.82319 8.16667 6.65362 8.09643 6.5286 7.9714C6.40357 7.84638 6.33334 7.67681 6.33334 7.5C6.33334 7.32319 6.40357 7.15362 6.5286 7.02859C6.65362 6.90357 6.82319 6.83333 7 6.83333H7.66667C8.02029 6.83333 8.35943 6.97381 8.60948 7.22386C8.85953 7.47391 9 7.81304 9 8.16667V11.3333C9 11.3775 9.01756 11.4199 9.04882 11.4512C9.08007 11.4824 9.12247 11.5 9.16667 11.5H9.66667C9.84348 11.5 10.013 11.5702 10.1381 11.6953C10.2631 11.8203 10.3333 11.9899 10.3333 12.1667C10.3333 12.3435 10.2631 12.513 10.1381 12.6381C10.013 12.7631 9.84348 12.8333 9.66667 12.8333Z" fill={customColour ? customColour : "#A3A3A3"}/>
      </svg>
    </Fragment>
  )
}