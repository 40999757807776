import React, { Fragment } from 'react';
import { integratedBusiness, showCancelButton } from './../../../../../helpers/helpers';
import { UserIcon, MessageIcon, TashIcon } from './../../../../../assets';

export default function PendingEventsButtonsComponent(props) {
  const { event, business, handleShowAssignPopup, handleAddComment, user, handleShowCancelPopup } = props

  return(
    <Fragment>
      <div className='flex gap-1.5 mt-3'>
        {event?.show_assign_button &&
          <button className='w-full tc-11 font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5' onClick={() => handleShowAssignPopup('assign')}>
            <UserIcon classNames={'wc-14 hc-14'}/>
            Assign
          </button>
        }
        {business?.comments_feature &&
          <button className='w-full tc-11 font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5 px-1.5' onClick={() => handleAddComment()}>
            <MessageIcon classNames={'wc-14 hc-14'}/>
            Comment
          </button>
        }
        {event?.show_cancel_button &&
          <button className='w-full tc-11 font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5' onClick={() => handleShowCancelPopup()}>
            <TashIcon classNames={'text-black wc-14 hc-14'}/>
            Cancel
          </button>
        }
      </div>
    </Fragment>
  )
}