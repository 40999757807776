import React from 'react';

export const RenderLastMessageText = (props) => {
  const { channel } = props

  if(channel.state.messages?.length === 0){
    return "Nothing yet.."
  }
  const lastMessageText = channel.state.messages[channel.state.messages.length - 1].text;

  const text = lastMessageText || 'message text';

  return text.length < 60 ? lastMessageText : `${text.slice(0, 70)}...`;
}; 

export const RenderLastMessageUserName = (props) => {
  const { channel, client } = props

  if(channel.state.messages?.length === 0){
    return null
  }
  const lastMessageUserName = 
  channel.state.messages[channel.state.messages.length - 1].user?.id == client.user.id ?  
    'You'
    :
    (channel.state.messages[channel.state.messages.length - 1].user?.name || channel.state.messages[channel.state.messages.length - 1].user?.first_name+' '+channel.state.messages[channel.state.messages.length - 1].user?.last_name);

  const name = lastMessageUserName || '';
  if(name === ''){
    return null
  }
  return (
    <div className="str-chat__channel-preview-message--user-name">
      <span className="cut-text font-semibold">{lastMessageUserName}</span>
    </div>
  )
}