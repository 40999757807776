import React, { Fragment } from "react";

export default function EmptyCardsMessageComponent({listType}) {
  return (
    <Fragment>
      {['Sent', 'Available', 'Approved'].includes(listType) &&
        <div className="bg-white shadow-sm items-center justify-between rounded-lg px-3 py-5 mb-2 text-center text-sm text-gray-500">
          {listType == 'Sent' ?
            `You haven't sent out any sub requests at this time. If you need coverage, send a sub request below.`
          :
          listType == 'Available' ?
            `There aren't any classes that need subs at this time. Check back later!`
          :
            `You aren't scheduled to sub any upcoming classes at this time.`
          }
        </div>
      }
    </Fragment>
  )
}