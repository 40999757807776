import React, { Fragment } from "react";

export default function EventMonthWeekDayComponent(props) {
  const { eventData } = props
  return(
    <Fragment>
      <div className='flex flex-col items-center mr-4 ml-3'>
        <div className='text-xs font-medium'>{eventData?.month_name}</div>
        <div className='text-base font-semibold'>{eventData?.day}</div>
        <div className='text-xs font-medium'>{eventData?.week_day}</div>
      </div>
    </Fragment>
  )
}