import React, { Fragment, useState } from "react";
import { ApproveButtonIcon, DeclineButtonIcon } from "./../../../../subRequests/assets";
import { axiosRequest } from './../../../../subRequests/helpers/helpers';
import AutoApproveConfirmationComponent from './../../../../subRequests/pages/components/cardViewComponents/cardComponent/availableEventsButtonsComponent/autoApproveConfirmationComponent';

export default function AcceptDeclineButtonsComponent(props){
  const { eventData, business, handleAlert, handleUpdateEvent } = props
  const [ showAutoApproveModal, setShowAutoApproveModal ] = useState(false)
  const [ disableActionButtons, setDisableActionButtons ] = useState(false)

  const handlePerformAccept = (action) => {
    if (action == 'maybe') return
    if(action == 'accept' && eventData?.auto_approve_first_accepted){
      setShowAutoApproveModal(true)
    }else{
      handleProcessUpdate(action)
    }
  }

  const handleProcessUpdate = (action) => {
    let url = `/api/v2/sub_requests/update_status`
    let params = {
      status: action,
      event_id: eventData?.id,
      inst_id: '',
      gql_event_details: true,
      preview: false
    }
    setDisableActionButtons(true)
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if(Object.keys(result).includes('status') && result?.status == 200){
        handleAlert(true, 'Status Updated', 'success');
      }else{
        handleAlert(true, result?.error, 'danger');
      }
      setDisableActionButtons(false)
      setTimeout(() => {
        handleAlert(false, '', ''); 
        handleUpdateEvent(result?.event);
      }, 3000)
    })
  }

  return(
    <Fragment>
      {showAutoApproveModal &&
        <AutoApproveConfirmationComponent showAutoApproveModal={showAutoApproveModal} setShowAutoApproveModal={setShowAutoApproveModal} handleProcessUpdate={handleProcessUpdate} />
      }
      <button className={`flex items-center justify-center border border-green-600 rounded-2xl font-semibold w-full text-sm text-sm h-11 gap-2.5 ${eventData?.user_status == 'Accepted' ? 'text-white bg-green-600' : 'text-green-600'} ${(disableActionButtons || eventData?.user_status == 'Accepted') ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handlePerformAccept('accept')} disabled={disableActionButtons || eventData?.user_status == 'Accepted'}>
        <ApproveButtonIcon classNames={`w-4 h-4`}/>
        {eventData?.user_status == 'Accepted' ? 'Accepted' : 'Accept'}
      </button>
      <button className={`flex items-center justify-center border border-red-600 rounded-2xl font-semibold w-full text-sm text-sm h-11 gap-2.5 ${eventData?.user_status == 'Declined' ? 'text-white bg-red-600' : 'text-red-600'} ${(disableActionButtons || eventData?.user_status == 'Declined') ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handlePerformAccept('decline')} disabled={disableActionButtons || eventData?.user_status == 'Declined'}>
        <DeclineButtonIcon classNames={`${eventData?.user_status == 'Declined' ? 'text-white' : 'text-red-600'} w-4 h-4`}/>
        {eventData?.user_status == 'Declined' ? 'Declined' : 'Decline'}
      </button>
    </Fragment>
  )
}