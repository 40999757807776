import React, { Fragment } from "react";

export default function AlertPopup(props){
  const { alert_type, alert_message, closeAlert } = props

  const handleClose = () => {
    closeAlert(false)
  }

  return(
    <React.Fragment>
      {alert_type === 'success' &&
        <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
          </svg>
          <div className="text-sm text-green-800 font-medium">
            {alert_message}
          </div>
          <a className="ml-auto" onClick={() => handleClose()}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </a>
        </div>
      }
      {alert_type === 'danger' &&
        <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
          </svg>
          <div className="text-sm text-red-700 font-medium">
            {alert_message}
          </div>
          <a className="ml-auto" onClick={() => handleClose()}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </a>
        </div>
      }  
    </React.Fragment>
  )
}