import React, { Fragment, useState } from "react";
import { BreadCrumbArrowIcon } from '../../../assets';

export default function BreadCrumbsComponent(props) {
  const { step } = props
  const totalSteps = [
      { id: 1, name: 'Request' },
      { id: 2, name: 'Invite Instructor(s) to Sub' },
      { id: 3, name: 'Confirm details' }
    ];

  return(
    <Fragment>
      <div className="flex justify-start md:justify-center w-full items-center h-auto lg:h-7 gap-2 mb-6 flex-wrap">
        {totalSteps?.map((crumb, i) => 
          <div key={i} className="flex items-center gap-2">
            <div className={`flex justify-center w-7 h-7 items-center text-xs font-semibold rounded-full bg-gray-300 shrink-0 ${parseInt(step) >= crumb?.id ? 'text-white bg-gray-900' : 'text-black bg-gray-200'}`}>
              {crumb?.id}
            </div>
            <div className="font-medium text-sm md:text-base text-black">
              {crumb?.name}
            </div>
            {i < totalSteps.length - 1 &&
              <BreadCrumbArrowIcon classNames={'w-4 h-4.5'}/>
            }
          </div>
        )}
      </div>
    </Fragment>
  )
}