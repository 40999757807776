import React, { Fragment, useState } from 'react';
import CustomPopup from '../../../assets/customPopup/customPopup';
import ActivationEmailComponent from '../../activationEmailComponent/activationEmailComponent';
import { getSudomain } from '../../../../../../helpers'
import { sendConfirmationAll } from './../../../helpers'
import Cookies from 'js-cookie';

export default function SendActivationEmailComponent(props){
  const { user, selectedPeople, showAlert, reloadDataAfterSendActivationEmail, setTheState } = props
  const [ showConfirmationPopup, setShowConfirmationPopup ] = useState(false)
  const [ disabledSendAllBtn, setDisabledSendAllBtn ] = useState(false)

  const handleSendActivationEmail = (e) => {
    setShowConfirmationPopup(true)
  }

  const closeConfirmationPopup = (data) => {
    setShowConfirmationPopup(data)
  }

  const processSendActivationEmail = (e) => {
    e.preventDefault()
    setDisabledSendAllBtn(true)

    sendConfirmationAll(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), selectedPeople?.length, selectedPeople).then(result => {
      if(result.status === 200){
        showAlert(true, 'success', result.success)
        setShowConfirmationPopup(false)
        setTheState('selectedPeople', [])
        reloadDataAfterSendActivationEmail()
      }else{
        showAlert(true, 'danger', result.error)
        setDisabledSendAllBtn(false)
      }
      window.setTimeout(()=>{
        showAlert(false, '', '')
      },3000)
    })
  }

  return(
    <Fragment>
      {showConfirmationPopup &&
        <CustomPopup show={showConfirmationPopup} close={closeConfirmationPopup} title={'Users Activation'}>
          <ActivationEmailComponent selectedPeople={selectedPeople} disabledSendAllBtn={disabledSendAllBtn} closeConfirmationPopup={closeConfirmationPopup} processSendActivationEmail={processSendActivationEmail}/>
        </CustomPopup>
      }
      <div>
        <a className='bg-sky-500 text-white text-sm rounded-md py-2 px-4 font-medium h-10 block cursor-pointer flex items-center' onClick={(e) => handleSendActivationEmail(e)}>{`Send Activation Email${(selectedPeople?.length > 0) ? ' to '+selectedPeople?.length+(selectedPeople?.length == 1 ? ' User' : ' Users') : ''}`}</a>
      </div>
    </Fragment>
  )
}