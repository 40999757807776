import React, { forwardRef, MouseEventHandler } from 'react';
import clsx from 'clsx';

const EmojiIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.08998 13.28H9.17998V20.48C9.17998 22.16 10.09 22.5 11.2 21.24L18.77 12.64C19.7 11.59 19.31 10.72 17.9 10.72H14.81V3.52002C14.81 1.84002 13.9 1.50002 12.79 2.76002L5.21998 11.36C4.29998 12.42 4.68998 13.28 6.08998 13.28Z" stroke="#8C8A94" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default function MessageInputControlButton ({ active, onClick, type }) {

  return(
    <button className={clsx('message-input__controls-button', type, { 'active': active })} onClick={onClick}>
      <EmojiIcon />
    </button>
  )
}