import React, { Fragment } from "react";

export default function NotepadIcon({classNames}) {
  return(
    <Fragment>
      <svg className={classNames} viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.2401 13.8029C24.9069 13.136 24.9069 12.1019 24.2401 11.435L23.0392 10.2355C22.3724 9.6025 21.3382 9.6025 20.6714 10.2355L19.5044 11.4025L13.4037 17.5045V17.5383C13.3698 17.5709 13.3698 17.6034 13.3698 17.6034C13.336 17.6373 13.336 17.6699 13.3034 17.7037V17.7376L12.0036 21.8714C11.9372 22.0381 12.0036 22.2387 12.1364 22.4054C12.2367 22.5057 12.3696 22.5721 12.5037 22.5721C12.5702 22.5721 12.604 22.5721 12.6705 22.5382L16.8043 21.2384H16.8381C16.872 21.2384 16.9045 21.2046 16.9384 21.2046C16.9723 21.1707 17.0048 21.1707 17.0387 21.1381L23.1407 15.0361L24.2401 13.8029ZM13.2697 21.2386L14.0369 18.8382L15.6701 20.4714L13.2697 21.2386ZM16.604 20.0052L14.5031 17.9043L19.8719 12.5355L22.0053 14.6689L16.604 20.0052ZM21.6394 12.8365L20.5727 11.7699L21.3724 10.9702C21.6394 10.7032 22.0392 10.7032 22.2723 10.9702L23.4732 12.171C23.7063 12.438 23.7063 12.8379 23.4732 13.071L22.7061 13.9033L21.6394 12.8365Z" fill="black"/>
        <path d="M20.3715 19.3383C20.072 19.3383 19.8714 19.5715 19.8714 19.8385V24.0403C19.8714 24.8739 19.1707 25.5746 18.3371 25.5746L2.56714 25.5733C1.73357 25.5733 1.03285 24.8726 1.03285 24.039V2.53312C1.03285 1.69956 1.73357 0.998837 2.56714 0.998837L13.3694 1.00014V7.00178C13.3694 7.30134 13.6026 7.50191 13.8696 7.50191H19.8372V8.60248C19.8372 8.90204 20.0703 9.10262 20.3373 9.10262C20.6369 9.10262 20.8375 8.86948 20.8375 8.60248V6.96794V6.90151V6.83509C20.8036 6.7348 20.771 6.66837 20.7046 6.60195L14.2369 0.132849C14.1704 0.0664246 14.104 0.0325622 14.0037 0H13.9373H13.8709H2.5671C1.16699 0 0 1.13311 0 2.5671V24.107C0 25.5071 1.16699 26.6741 2.5671 26.6741H18.3384C19.7385 26.6741 20.9055 25.541 20.9055 24.107V19.8715C20.9055 19.5719 20.6711 19.3388 20.3715 19.3388L20.3715 19.3383ZM14.4039 1.73321L19.1382 6.50151H14.4039V1.73321Z" fill="black"/>
        <path d="M3.40297 21.5098C3.10341 21.5098 2.90283 21.7429 2.90283 22.0099C2.90283 22.3095 3.13597 22.51 3.40297 22.51H10.4389C10.7385 22.51 10.939 22.2769 10.939 22.0099C10.939 21.7103 10.7059 21.5098 10.4389 21.5098H3.40297Z" fill="black"/>
        <path d="M11.5982 17.8683C11.5982 17.5687 11.3651 17.3682 11.0981 17.3682H3.42934C3.12977 17.3682 2.9292 17.6013 2.9292 17.8683C2.9292 18.1353 3.16234 18.3684 3.42934 18.3684H11.0981C11.3651 18.3684 11.5982 18.1692 11.5982 17.8683Z" fill="black"/>
        <path d="M15.0728 13.7033C15.0728 13.4037 14.8397 13.2031 14.5727 13.2031H3.40297C3.10341 13.2031 2.90283 13.4363 2.90283 13.7033C2.90283 13.9703 3.13597 14.2034 3.40297 14.2034H14.5387C14.8382 14.2373 15.0728 14.0041 15.0728 13.7033Z" fill="black"/>
        <path d="M17.8729 9.5724C17.8729 9.27284 17.6398 9.07227 17.3728 9.07227H3.40297C3.10341 9.07227 2.90283 9.3054 2.90283 9.5724C2.90283 9.8394 3.13597 10.0725 3.40297 10.0725H17.3728C17.6398 10.0725 17.8729 9.8394 17.8729 9.5724Z" fill="black"/>
      </svg>
    </Fragment>
  )
}