import React, { Fragment } from "react";
import TimeDropDownComponent from './timeDropDownComponent';
import { TrashIcon } from './../../../../../subRequestDetails/assets';

export default function TimeSelectorComponent(props) {
  const { handleRemoveSchedule, i, newClass, handleSaveDateTime } = props
  
  return(
    <Fragment>
      <div className="flex flex-col gap-2 w-full">
        <div className={`flex ${i > 0 ? 'lg:hidden' : ''} text-base md:text-lg text-black font-semibold`}>
            Time
          </div>
        <div className="flex items-center gap-2">
          <TimeDropDownComponent
            i={i}
            eventDateTime={newClass?.selectedDateTime?.eventDate} 
            eventStartDateTime={newClass?.selectedDateTime?.startTime} 
            eventEndDateTime={newClass?.selectedDateTime?.endTime}
            handleSaveDateTime={(eventDate, startTime, endTime) => handleSaveDateTime(eventDate, startTime, endTime, i)}
            newClass={newClass}
          />
          {i > 0 &&
            <div className="flex items-center justify-end">
              <button className={`w-7 h-7 rounded-full border-neutral-500-custom flex items-center justify-center opacity-75 hover:opacity-100`} onClick={() => handleRemoveSchedule(i)}>
                <TrashIcon classNames={"w-4 h-4"} colour={''}/>
              </button>
            </div>
          }
        </div>
      </div>
    </Fragment>
  )
}