import React, { Fragment, useState } from "react"; 
import AssignPopupComponent from './../../../assignPopupComponent';
import { BellIcon, PhoneIcon, EnvelopeIcon, ChatIcon, TextIcon } from './../../../../../assets';
import ReactTooltip from "react-tooltip";
import { axiosRequest } from './../../../../../helpers/helpers'
import { Redirect } from "react-router-dom";

export default function InstructorDetailComponent(props) {
  const { showInstructorDetail, detailInstructor, close, handleAlert } = props
  const [ createDmBtnDisabled, setCreateDmBtnDisabled ] = useState(false)
  const [ redirectAfterCreateGroup, setRedirectAfterCreateGroup ] = useState(false)
  const [ redirectAtGroupPage, setRedirectAtGroupPage ] = useState('')

  const handleCallButton = (val, type) => {
    if(type == 'call'){
      window.location.href = `tel:${val}`
    }else if(type == 'mail'){
      window.location.href = `mailto:${val}`
    }else if(type == 'text'){
      window.location.href = `sms:${val}`
    }else if(type == 'chat'){
      handleProcessCreateDM(val)
    }
  }

  const handleProcessCreateDM = (id) => {
    setCreateDmBtnDisabled(true)
    if(!id || id == '' || id == 0) return
    let receivers = []
    receivers.push(id)
    const formData = new FormData()
    formData.append('group_data[message]', '');
    formData.append('group_data[type]', 'direct_message');
    formData.append('group_data[receivers]', receivers);
    formData.append('group_data[is_private]', true);
    let url = `/api/v3/messenger/create_group`
    axiosRequest(url, 'POST', formData, 'content').then(result => {
			if(result.status === 200){
        setRedirectAtGroupPage(`/messenger/chat-room/${result?.id}`)
        handleAlert(true, result?.message, 'success')
        window.setTimeout(()=>{
          setCreateDmBtnDisabled(false)
          setRedirectAfterCreateGroup(true)
        },100)
			}else{
        handleAlert(true, result.error, 'danger')
        setCreateDmBtnDisabled(false)
        window.setTimeout(()=>{
          handleAlert(false, '', '')
        },3000)
			}
		})
  }

  return(
    <Fragment>
      {redirectAfterCreateGroup &&
        <Redirect push to={`${redirectAtGroupPage}`} />
      }
      <AssignPopupComponent show={showInstructorDetail} close={close} title={''}>
        <div className="px-7 py-2">
          <div className="mb-5">
            <div className="flex justify-center">
              <div className="flex w-16 h-16 rounded-full">
                <img className="w-full h-full rounded-full" src={detailInstructor?.image} />
              </div>
            </div>
            <div className="flex justify-center mt-3">
              <div className="text-xl font-semibold text-black text-center">
                {detailInstructor?.full_name}
              </div>
            </div>
            <div className="flex justify-center items-center mt-3 border border-gray-200 rounded-lg bg-custom-gray px-2 py-2 gap-2 profile-notification-container relative">
              <BellIcon classNames={'h-4 w-4 shrink-0'}/>
              <div className="text-sm font-semibold text-black"><span className="text-sm font-semibold text-black truncate">Notification preferences</span>:</div>
              <div className="text-sm font-semibold text-neutral-500 truncate">
                {detailInstructor?.daily_notifications}
              </div>
              <div className="profile-notification-tooltip text-black bg-white shadow-md border rounded-md p-2 absolute bottom-10 text-xs font-semibold w-40">
                {detailInstructor?.daily_notifications == 'Daily Summary' ?
                  'Receiving sub request invites once per day as a daily summary at 6pm.'
                :
                  'Receiving every sub request invite immediately at the time it was sent.'
                }
              </div>
            </div>
          </div>
          <div className="flex w-full justify-center gap-2">
            {detailInstructor?.show_phone &&
              <Fragment>
                <button className="border boarder-gray-200 rounded-2xl px-3 py-3 h-16 w-20 text-xs font-semibold hover:bg-gray-100" onClick={() => handleCallButton(detailInstructor?.phone, 'text')}>
                  <div className="flex justify-center mb-1">
                    <TextIcon classNames={'h-5 w-5'} />
                  </div>
                  Text
                </button>
                <button className="border boarder-gray-200 rounded-2xl px-3 py-3 h-16 w-20 text-xs font-semibold hover:bg-gray-100" onClick={() => handleCallButton(detailInstructor?.phone, 'call')}>
                  <div className="flex justify-center mb-1">
                    <PhoneIcon classNames={'h-5 w-5'} />
                  </div>
                  Call
                </button>
              </Fragment>
            }
            {detailInstructor?.show_email &&
              <button className="border boarder-gray-200 rounded-2xl px-3 py-3 h-16 w-20 text-xs font-semibold hover:bg-gray-100" onClick={() => handleCallButton(detailInstructor?.email, 'mail')}>
                <div className="flex justify-center mb-1">
                  <EnvelopeIcon classNames={'h-5 w-5'} />
                </div>
                Mail
              </button>
            }
            {detailInstructor?.create_dm_permission &&
              <button className="border boarder-gray-200 rounded-2xl px-3 py-3 h-16 w-20 text-xs font-semibold hover:bg-gray-100" onClick={() => handleCallButton(detailInstructor?.id, 'chat')}>
                <div className="flex justify-center mb-1">
                  <ChatIcon classNames={'h-5 w-5'} />
                </div>
                Chat
              </button>
            }
          </div>
        </div>
      </AssignPopupComponent>
    </Fragment>
  )
}