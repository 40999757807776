import React, { Fragment, useEffect, useState } from "react";
import { MessageIcon, TashIcon, UserIcon } from '../../../../assets'
import NoShowConfirmationComponent from './../../../../../subRequestDetails/components/topButtonsComponent/moreActionComponent/noShowConfirmationComponent';
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSlash } from '@fortawesome/free-solid-svg-icons'
library.add( faUserSlash )

export default function NoShowEventButtonsComponent(props){
  const { eventData, business, cancelOptions, optionListKeys, handleSetAlert, handleUpdateEvent, calendarEvent, handleUpdateCalendarEvent } = props
  const showCancelButton = optionListKeys.some(v => cancelOptions.includes(v));
  const [ showNoShowPopup, setShowNoShowPopup ] = useState(false)
  const [ undoCancelOption, setUndoCancelOption ] = useState([])

  useEffect(() => {
    if(eventData){
      setUndoCancelOption(eventData?.more_action_detail?.filter(item => item?.key == 'undo_no_show_sub_request'))
    }
  }, [eventData])
  
  return(
    <Fragment>
      {showNoShowPopup &&
        <NoShowConfirmationComponent eventData={eventData} handleSetAlert={handleSetAlert} closeNoShowPopup={setShowNoShowPopup} handleUpdateEvent={handleUpdateEvent} showNoShowPopup={showNoShowPopup} noShowPopupType={'undo_no_show_sub_request'} type={'preview'} calendarEvent={calendarEvent} handleUpdateCalendarEvent={handleUpdateCalendarEvent}/> 
      }
      {optionListKeys.includes('undo_no_show_sub_request') &&
        <Fragment>
          {/* {!eventData?.show_assign_button || !business?.comments_feature || !showCancelButton ? */}
            <button className='w-full text-xs font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5' onClick={() => setShowNoShowPopup(true)}>
              <FontAwesomeIcon className='w-4 h-4 shrink-0' icon="fa-solid fa-user-slash"/>
              {undoCancelOption[0]?.value}
            </button>
          {/* :
            <button className='flex justify-center items-center border border-gray-400 rounded-full min-h-8 min-w-8' onClick={() => setShowNoShowPopup(true)} data-tip data-for={`${undoCancelOption[0]?.value}_button_`+eventData?.id.toString()}>
              <FontAwesomeIcon className='w-4 h-4 shrink-0' icon="fa-solid fa-user-slash"/>
              <ReactTooltip className='max-w-xs whitespace-nowrap' id={`${undoCancelOption[0]?.value}_button_`+eventData?.id.toString()} place="left" effect="solid">
                {undoCancelOption[0]?.value}
              </ReactTooltip>
            </button>
          } */}
        </Fragment>
      }
    </Fragment>
  )
}