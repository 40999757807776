import React from 'react';

export default function TypingIndicator(props) {
  const { whoIsTyping } = props

  return(
    <div className="str-chat__channel-preview-message--typing-indecator-container">
      <div className="str-chat__channel-preview-message--typing-indecator--user-name font-semibold">
        {whoIsTyping}
      </div> 
      <div className="str-chat__channel-preview-message--typing-indecator--text-dot">
        is typing ...
      </div>
    </div>
  )
}