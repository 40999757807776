import React, { Fragment, useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import AngleIcon from './../../../assets/angleIcon'

export default function MonthSelectComponent(props) {
  const { selectedMonth, handleChangeMonth } = props
  const [ isOpen, setIsOpen ] = useState(false);

  const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const handleViewDate = (value) => {
    let dateParts = value.split("/");
    let month = parseInt(dateParts[0], 10);
    let year = parseInt(dateParts[1], 10);
    let formattedDate = new Date(year, month - 1).toLocaleString("en-US", {
      month: "short",
      year: "numeric"
    });
    return formattedDate;
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
    const handleOnClick = () => {
      if(!isOpen) {
        onClick()
      }
    }
    return (
      <button className="" onClick={handleOnClick} ref={ref}>
        <div className="text-2xl font-semibold flex items-center justify-between font-open-sans wc-130">{handleViewDate(value) || value}
          <div>
            <AngleIcon classNames={'ml-auto text-gray-900 h-5 w-5'}/>
          </div>
        </div>
      </button>
    );
  });

  const handleCalendarOpen = () => {
    setIsOpen(true)
  }

  const handleCalendarClose = () => {
    setIsOpen(false)
  }

  const handlePrevMonth = () => {
    let currentMonth = new Date(selectedMonth);
    let newMonth = (new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1))
    handleChangeMonth(newMonth);
  }

  const handleNextMonth = () => {
    let currentMonth = new Date(selectedMonth);
    let newMonth = (new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1))
    handleChangeMonth(newMonth);
  }
  
  return (
    <Fragment>
      <div className="month-selector-filter flex items-center">
        <div className="flex">
          <button className="month-change-button month-change-left-button" onClick={() => handlePrevMonth()}>
            <AngleIcon classNames={'ml-auto text-gray-900 h-5 w-5'}/>
          </button>
          <button className="month-change-button month-change-right-button" onClick={() => handleNextMonth()}>
            <AngleIcon classNames={'ml-auto text-gray-900 h-5 w-5'}/>
          </button>
        </div>
        <DatePicker
          className="w-36"
          selected={selectedMonth}
          renderMonthContent={renderMonthContent}
          showMonthYearPicker
          dateFormat="MM/yyyy"
          onChange={(e) => handleChangeMonth(e)}
          customInput={<ExampleCustomInput />}
          onCalendarOpen={handleCalendarOpen}
          onCalendarClose={handleCalendarClose}
        />
      </div>
    </Fragment>
  )
}