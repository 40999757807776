import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { hideItems } from './../../../helpers'

export default function RoleComponent(props){
  const { selectedRole, handleSelectedRole, userRoles } = props

  return(
    <Fragment>
      <div className="relative filters_box w-48">
        <Menu as="div" className="relative block text-left ">
          <div>
            <Menu.Button className="inline-flex items-center h-10 justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 end_time_button">
              {selectedRole == '' ? 'Role'
                :
                <p className = "text-gray-900"> {selectedRole == 'account_owner' ? "Account Owner" : selectedRole == "business_admin" ? "Business Admin" : selectedRole == "regional_admin" ? "Regional Admin" : selectedRole == "fitness_manager" ? "Fitness Manager" : selectedRole == "instructor" ? "Fitness Instructor" : selectedRole == "ops" ? "Ops" : '' } </p>
              }
              <ChevronDownIcon className="ml-auto w-4 mr-0.5 text-gray-400" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden end_time_items filter_items">
              <div className="max-h-60 overflow-y-auto">
                <div className="py-1">
                  {userRoles.map((role, i) => 
                    <label key={i} className={`flex items-center text-gray-900 text-sm p-2 hover:bg-gray-100 ${selectedRole == role?.value ? 'bg-gray-200' : ''}`} onClick={() => {handleSelectedRole(selectedRole == role?.value ? '' : role?.value); hideItems('end_time');}}>
                      {role?.label}
                    </label>  
                  )} 
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}