import React, { Fragment } from "react";

export default function PushNotificationIcon({ classNames, customColour }){
  
  return(
    <Fragment>
      <svg className={classNames} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.67095 7.245L9.17095 6.415C9.06595 6.23 8.97095 5.88 8.97095 5.675V4.41C8.97095 3.235 8.28095 2.22 7.28595 1.745C7.02595 1.285 6.54595 1 5.99595 1C5.45095 1 4.96095 1.295 4.70095 1.76C3.72595 2.245 3.05095 3.25 3.05095 4.41V5.675C3.05095 5.88 2.95595 6.23 2.85095 6.41L2.34595 7.245C2.14595 7.58 2.10095 7.95 2.22595 8.29C2.34595 8.625 2.63095 8.885 3.00095 9.01C3.97095 9.34 4.99095 9.5 6.01095 9.5C7.03095 9.5 8.05095 9.34 9.02095 9.015C9.37095 8.9 9.64095 8.635 9.77095 8.29C9.90095 7.945 9.86595 7.565 9.67095 7.245Z" fill={customColour ? customColour : "#525252"}/>
        <path d="M7.41484 10.005C7.20484 10.585 6.64984 11 5.99984 11C5.60484 11 5.21484 10.84 4.93984 10.555C4.77984 10.405 4.65984 10.205 4.58984 10C4.65484 10.01 4.71984 10.015 4.78984 10.025C4.90484 10.04 5.02484 10.055 5.14484 10.065C5.42984 10.09 5.71984 10.105 6.00984 10.105C6.29484 10.105 6.57984 10.09 6.85984 10.065C6.96484 10.055 7.06984 10.05 7.16984 10.035C7.24984 10.025 7.32984 10.015 7.41484 10.005Z" fill={customColour ? customColour : "#525252"}/>
      </svg>

    </Fragment>
  )
}