import React, { Fragment } from "react";

export default function FooterComponent(props) {
  const { path } = props;
  return (
    <Fragment>
      <div className={`w-full flex-shrink-0 bottom-0 flex ${path == 'signin' ? 'h-1/2' : 'h-1/3'} bg-white`}>
        <div className="flex justify-center items-center px-4 h-full w-full">
          <div className="flex flex-col items-center">
            <img src="/assets/business/logo-black.png" alt="netgym" className="max-wc-150"/>
            <p className="footer-copyright-text">
              © {new Date().getFullYear()} NetGym, All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}