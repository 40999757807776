import React from 'react';
import { MessageInput, MessageList, Thread, Window, SendButtonProps, useMessageInputContext } from "stream-chat-react";
import { SendButtonIcon } from '../assets/sendButtonIcon';

export default function CustomSendButton({ sendMessage, disabled }){
  const { numberOfUploads } = useMessageInputContext("CustomSendButton");
  // const disabled = disabledSendButton && numberOfUploads === 0;

  return(
    <button aria-label="Send" className="str-chat__send-button" onClick={sendMessage} disabled={disabled}>
      <SendButtonIcon />
    </button>
  )
}