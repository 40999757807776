import React, { Component, Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { Accordion, Card } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import {getMessageTypeIconeWithSize, deletePost} from './helpers'
import Cookies from 'js-cookie';
import { getSudomain } from '../../helpers'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { StreamChat } from 'stream-chat'
import { Chat, Channel, ChannelList, useChatContext } from 'stream-chat-react'
import CustomChannelList from './chatComponents/customChannelList';

export default class MessengerSidebar extends Component {
  constructor(props){
    super(props);
    this.state = {
      sendAllModal: false,
      alert_message: '',
      show: false,
      alert_type: '',
      deleteDraftId: '',
      drafts: this.props.sideBarCounts?.draft_posts,
      redirectToEditDrafts: false,
      redirectToEditDraftsUrl: '',
      unreadMessages: 0
    }
  }

  componentDidMount(){
    if(this.props.business?.group_chat_feature && this.props.user?.stream_token !== "" && Object.keys(this.props.chatClient)?.length !== 0){
      let unreadCount = this.props.chatClient?.user?.total_unread_count
      this.setState({unreadMessages: unreadCount})
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    this.setState({
      drafts: nextProps.sideBarCounts?.draft_posts,
    })
  }

  closeModal = () => {
    this.setState({sendAllModal: false})
  }

  handleDeleteDraft = (e, id) => {
    this.setState({
      sendAllModal: true,
      deleteDraftId: id
    })
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  performDeleteDraft = (e) => {
    e.preventDefault();
    let postId = this.state.deleteDraftId
    let drafts = this.state.drafts
    let remainDrafts = drafts.filter((item) =>  item?.id !== postId)
    this.setState({
      drafts: remainDrafts,
      sendAllModal: false,
    })
    deletePost(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), postId).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
          alert_message: result?.message,
          alert_type: 'success',
          show: true,
          sendAllModal: false,
				})
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

  redirectToEditDraft = (e, postId) => {
    let target = $(e.target)
    let matricsTdTarget = document.getElementById("deleteDraftDiv")
    if(matricsTdTarget !== null){
      if(target.parents('div#deleteDraftDiv').length || matricsTdTarget == e.target){
        e.preventDefault();
      }else{
        this.setState({
          redirectToEditDraftsUrl: `messenger/edit-post/${postId}`,
          redirectToEditDrafts: true
        })
      }
    }else{
      this.setState({
        redirectToEditDraftsUrl: `messenger/edit-post/${postId}`,
        redirectToEditDrafts: true
      })
    }
  }

  activateChannel = (channel) => {
    this.props.setChannel(channel)
  }

  customChannelTeamFilter = (channels) => {
    return channels.filter((channel) => channel.type === 'team');
  }

  customChannelMessagingFilter = (channels) => {
    return channels.filter((channel) => channel.type === 'messaging');
  }

  setUnreadMessges = (value) => {
    this.setState({unreadMessages: value})
  }

  handleCreatePost = () => {
    this.props.setShowCreatePopup(true)
  }

  handleClickOnChatAccordion = () => {
    if(this.props.allChannelsList?.length === 0 ){
      this.props.setShowEmptyChannelState(true)
    }
  }

  handleChangePagePath = (data) => {
    this.props.setPagePath(data)
  }

  render() {
    const { user, permissions, business, sideBardAccordion, sideBarCounts, chatClient, groupFilter, dmFilter, groupChannelsList, dmChannelsList, allChannelsList } = this.props
    const { sendAllModal, show, alert_type, alert_message, drafts, redirectToEditDrafts, redirectToEditDraftsUrl, unreadMessages } = this.state
    const dataFormat = user?.date_format_web
    
    return (
      <React.Fragment>
        {redirectToEditDrafts && 
          <Redirect push to={redirectToEditDraftsUrl} />
        }
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto" onClick={(e) => this.setState({show: false})}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto" onClick={(e) => this.setState({show: false})}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
        <div className='bg-white pt-4 border-r'>
          <div className='w-72 pl-4 relative'>
            <div className='custom-scroll pb-3 pr-3'>
              {((permissions?.business_messages?.write || permissions?.business_messages?.groups || permissions?.business_messages?.direct_messages || user?.role_display === "Netgym Employee") && user?.role_display !== "Fitness Instructor") &&
                // <Link to="/messenger/create-post" className='bg-dark-blue flex items-center justify-center rounded-md py-3 gap-1.5'>
                //   <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
                //     <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                //     <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                //   </svg>
                //   <div className='text-white text-sm font-medium'>Create</div>
                // </Link>
                <button className='bg-dark-blue flex items-center justify-center rounded-2xl py-3 gap-1.5 w-full' onClick={(e) => this.handleCreatePost(e)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                  </svg>
                  <div className='text-white text-sm font-medium'>Create</div>
                </button>
              }
              {(business?.group_chat_feature && (permissions?.business_messages?.direct_messages_to_managers || permissions?.business_messages?.direct_messages_to_instructors) && user?.role_display === "Fitness Instructor") &&
                <Link to="/messenger/create-post?type=direct_message" className='bg-dark-blue flex items-center justify-center rounded-md py-2.5 gap-1.5'>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                  </svg>
                  <div className='text-white text-sm font-medium'>Create</div>
                </Link>
              }
            </div>
            <div className='sidebar-overflow pr-1'>
              {(permissions.business_messages?.write && user?.role_display !== "Fitness Instructor" && drafts?.length > 0) &&  
                <Accordion className="bg-white pr-1" defaultActiveKey={'0'}>
                  <Card className="border-0">
                    <Accordion.Toggle eventKey="1" className="py-2.5 flex items-center views-accordion-dd text-left border-0 w-full font-semibold rounded-2xl px-3">
                      <div className='text-sm text-gray-600'>{`Draft${drafts?.length > 1 ? 's' : ''} (${drafts?.length})`}</div> 
                      <div className="ml-2 text-sm text-gray-900"></div>
                        <div className="ml-auto">
                          {/* <svg xmlns="http://www.w3.org/2000/svg" className="text-dark-blue angle-down h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                          </svg> */}
                          <svg xmlns="http://www.w3.org/2000/svg" className={`text-dark-blue angle-down h-6 w-6`} viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                          </svg>
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body className="p-0 max-h-48 overflow-y-auto overflow-x-hidden">
                          {drafts.map((post, i) =>
                            <div className='relative hover:bg-gray-100 rounded-2xl my-2' key={i}>
                              <div className='py-3 cursor-pointer'  onClick={(e) => this.redirectToEditDraft(e, post?.recipient_id)}>
                                <div className="flex space-between px-3">
                                  <div className="flex items-center">
                                    <div className='w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center'>
                                      {/* <svg xmlns="http://www.w3.org/2000/svg" className="text-dark-blue h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z" clipRule="evenodd" />
                                      </svg> */}
                                      {getMessageTypeIconeWithSize(post?.message_type, 5)}
                                    </div>
                                    <div className="pl-2 w-40">
                                      <div className='text-dark-blue text-sm w-36 truncate'>{post?.title === '' ? '(No Subject)' : post?.title}</div>
                                      <div className="text-xs text-gray-400 w-40 truncate">{post?.created_at}</div>
                                    </div>
                                  </div>
                                  <div className="mt-2.5">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 hover:text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                    </svg>
                                  </div>
                                  <div id="deleteDraftDiv" className=" right-3 top-5 mt-2.5" onClick={(e) => this.handleDeleteDraft(e, post?.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 hover:text-gray-500 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                                      <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              }
              <div className='relative post-accordion'>
                {/* {(permissions?.business_messages?.write && user?.role_display !== "Fitness Instructor") &&
                  <Link to="/messenger/create-post" className='edit absolute z-10 bg-dark-blue rounded-md flex items-center justify-center gap-1 py-1 px-2 left-20 top-2.5'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                      <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                    </svg>
                    <div className='text-white text-sm'>New</div>
                  </Link>
                } */}
                <Accordion className="bg-white pr-1" defaultActiveKey={['messenger'].includes(sideBardAccordion) ? '2' : ['inbox', 'compliance', 'announcement', 'event', 'permanent_class_opening', 'chat-room'].includes(sideBardAccordion) ? '2' : '0'}>
                  <Card className="border-0">
                    <Accordion.Toggle eventKey="2" className={`py-2.5 flex items-center views-accordion-dd relative text-left border-0 w-full rounded-2xl px-3 ${['messenger', 'inbox', 'compliance', 'announcement', 'event', 'permanent_class_opening'].includes(sideBardAccordion) ? 'active-accordion-dd' : ''}`}>
                      <div className='flex items-center'>
                        <div className='text-sm text-gray-600 mr-2.5 font-semibold'>Posts</div>
                        {sideBarCounts?.unread_count > 0 && 
                          <div className="str-chat__unread-count__chat-accordion">
                            {sideBarCounts?.unread_count}
                          </div>
                        }
                      </div> 
                      <div className="ml-2 text-sm text-gray-900"></div>
                        <div className="ml-auto">
                          {/* <svg xmlns="http://www.w3.org/2000/svg" className={`text-dark-blue angle-down h-6 w-6 ${['messenger', 'compliance', 'announcement', 'event', 'permanent_class_opening'].includes(sideBardAccordion) ? 'rotated' : ''}`} viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                          </svg> */}
                          <svg xmlns="http://www.w3.org/2000/svg" className={`text-dark-blue angle-down h-6 w-6 ${['messenger', 'inbox', 'compliance', 'announcement', 'event', 'permanent_class_opening', 'chat-room'].includes(sideBardAccordion) ? 'rotated-90' : ''}`} viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                          </svg>
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body className="p-0 mt-2">
                          <div>
                            {/* <Link to={`/messenger`}> */}
                            <button className='w-full' onClick={() => this.handleChangePagePath('messenger')}>
                              <div className={`p-3 pl-3 rounded-2xl hover:bg-gray-custom ${['messenger', 'inbox'].includes(sideBardAccordion) ? 'custom-gray' : ''}`}>
                                <div className="flex items-center space-between">
                                  <div className="flex items-center">
                                    <div className='w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center relative'>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="text-dark-blue h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                      </svg>
                                      {sideBarCounts?.unread_count > 0 &&
                                        <div className='bg-red-500 min-w-24 h-6 border-white border-2 rounded-full text-xs text-white flex items-center justify-center absolute -top-2.5 right-0 px-1'>{sideBarCounts?.unread_count}</div>
                                      }
                                    </div>
                                    <div className="pl-2">
                                      <div className='text-dark-blue text-sm'>All</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </button>
                            {/* </Link> */}
                            {/* <Link to={`/messenger/compliance`} className={`flex items-center p-3 pl-5 rounded-2xl hover:bg-gray-custom ${sideBardAccordion === 'compliance' ? 'custom-gray' : ''}`}> */}
                              {/* <svg xmlns="http://www.w3.org/2000/svg" className="transform -rotate-180 h-6 w-6 text-gray-500 mr-3.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
                              </svg> */}
                            <button onClick={() => this.handleChangePagePath('compliance')} className={`flex items-center p-3 pl-3 w-full rounded-2xl hover:bg-gray-custom ${sideBardAccordion === 'compliance' ? 'custom-gray' : ''}`}>
                              <div className="flex items-center">
                                <div className='w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center relative'>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-dark-blue h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z" clipRule="evenodd" />
                                  </svg>
                                  {sideBarCounts?.unread_compliance_count > 0 &&
                                    <div className='bg-red-500 min-w-24 h-6 border-white border-2 rounded-full text-xs text-white flex items-center justify-center absolute -top-2.5 right-0 px-1'>{sideBarCounts?.unread_compliance_count}</div> 
                                  }
                                </div>
                                <div className="pl-2">
                                  <div className='text-dark-blue text-sm'>Compliance</div>
                                  {/* <div className="text-xs text-gray-400">{sideBarCounts?.last_compliance_time}</div> */}
                                </div>
                              </div>
                            </button>
                            {/* <Link to={`/messenger/announcement`} className={`flex items-center p-3 pl-5 rounded-2xl hover:bg-gray-custom ${sideBardAccordion === 'announcement' ? 'custom-gray' : ''}`}> */}
                            <button onClick={() => this.handleChangePagePath('announcement')} className={`flex items-center p-3 pl-3 w-full rounded-2xl hover:bg-gray-custom ${sideBardAccordion === 'announcement' ? 'custom-gray' : ''}`}>
                              <div className="flex items-center">
                                <div className='w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center relative'>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-dark-blue h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z" clipRule="evenodd" />
                                  </svg>
                                  {sideBarCounts?.unread_announcement_count > 0 &&
                                    <div className='bg-red-500 min-w-24 h-6 border-white border-2 rounded-full text-xs text-white flex items-center justify-center absolute -top-2.5 right-0 px-1'>{sideBarCounts?.unread_announcement_count}</div>
                                  }
                                </div>
                                <div className="pl-2">
                                  <div className='text-dark-blue text-sm'>Announcements</div>
                                  {/* <div className="text-xs text-gray-400">{sideBarCounts?.last_announcement_time}</div> */}
                                </div>
                              </div>
                            </button>
                            {/* <Link to={`/messenger/event`} className={`flex items-center p-3 pl-5  rounded-2xl hover:bg-gray-custom ${sideBardAccordion === 'event' ? 'custom-gray' : ''}`}> */}
                            <button onClick={() => this.handleChangePagePath('event')} className={`flex items-center p-3 pl-3 w-full rounded-2xl hover:bg-gray-custom ${sideBardAccordion === 'event' ? 'custom-gray' : ''}`}>
                              <div className="flex items-center">
                                <div className='w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center relative'>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-dark-blue h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                                  </svg>
                                  {sideBarCounts?.unread_event_count > 0 &&
                                    <div className='bg-red-500 min-w-24 h-6 border-white border-2 rounded-full text-xs text-white flex items-center justify-center absolute -top-2.5 right-0 px-1'>{sideBarCounts?.unread_event_count}</div>
                                  }
                                </div>
                                <div className="pl-2">
                                  <div className='text-dark-blue text-sm'>Events</div>
                                  {/* <div className="text-xs text-gray-400">{sideBarCounts?.last_event_time}</div> */}
                                </div>
                              </div>
                            </button>
                            {business?.permanent_class_opening_feature &&
                              <button onClick={() => this.handleChangePagePath('permanent_class_opening')} className={`flex items-center p-3 pl-3 pr-0 w-full rounded-2xl hover:bg-gray-custom ${sideBardAccordion === 'permanent_class_opening' ? 'custom-gray' : ''}`}>
                                <div className="flex items-center">
                                  <div className='w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center relative'>
                                    <img className="h-5 w-5" src={"/assets/permanent_classs_opening_icon.svg"} />
                                    {sideBarCounts?.unread_permanent_class_opening_count > 0 &&
                                      <div className='bg-red-500 min-w-24 h-6 border-white border-2 rounded-full text-xs text-white flex items-center justify-center absolute -top-2.5 right-0 px-1'>{sideBarCounts?.unread_permanent_class_opening_count}</div>
                                    }
                                  </div>
                                  <div className="pl-2">
                                    <div className='text-dark-blue text-sm'>Permanent Class Openings</div>
                                    {/* <div className="text-xs text-gray-400">{sideBarCounts?.last_event_time}</div> */}
                                  </div>
                                </div>
                              </button>
                            }
                          </div>
                          {/* <Link to={`/messenger/archive`} className={`flex items-center p-3 pl-5 rounded-2xl hover:bg-gray-custom ${sideBardAccordion === 'archive' ? 'bg-gray-100' : ''}`}> */}
                          <button onClick={() => this.handleChangePagePath('archive')} className={`flex items-center p-3 pl-3 w-full rounded-2xl hover:bg-gray-custom ${sideBardAccordion === 'archive' ? 'bg-gray-100' : ''}`}>
                            <div className="flex items-center">
                              <div className='w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center relative'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-dark-blue h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                  <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                                  <path fillRule="evenodd" d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" clipRule="evenodd" />
                                </svg>
                                {sideBarCounts?.archive_unread_count > 0 &&
                                  <div className='bg-red-500 min-w-24 h-6 border-white border-2 rounded-full text-xs text-white flex items-center justify-center absolute -top-2.5 right-0 px-1'>{sideBarCounts?.archive_unread_count}</div>
                                }
                              </div>
                              <div className="pl-2">
                                <div className='text-dark-blue text-sm'>Archives</div>
                                {/* <div className="text-xs text-gray-400">{sideBarCounts?.archive_message_time}</div> */}
                              </div>
                            </div>
                          </button>
                        </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
              {(business?.group_chat_feature && user?.stream_token !== "") &&
                <React.Fragment>
                  <Accordion className="bg-white pr-1" defaultActiveKey={['messenger'].includes(sideBardAccordion) ? '4' : ['inbox', 'compliance', 'announcement', 'event', 'permanent_class_opening', 'chat-room'].includes(sideBardAccordion) ? '4' : '0'}>
                    <Card className="border-0">
                      <Accordion.Toggle eventKey="4" className={`py-2.5 flex items-center views-accordion-dd relative text-left border-0 w-full rounded-2xl px-3 ${['chat-room' ].includes(sideBardAccordion) ? 'active-accordion-dd' : ''}`}>
                        <div className='flex items-center'>
                          <div className='text-sm text-gray-600 mr-2.5 font-semibold'>Chat</div>
                          <div className='hidden edit bg-dark-blue rounded-md flex items-center justify-center gap-1 py-1 px-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-white h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                              <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                            </svg>
                            <div className='text-white text-sm'>New</div>
                          </div>
                          {unreadMessages > 0 && 
                            <div className="str-chat__unread-count__chat-accordion">
                              {unreadMessages}
                            </div>
                          }
                        </div> 
                        <div className="ml-2 text-sm text-gray-900"></div>
                          <div className="ml-auto">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" className="text-dark-blue angle-down h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg> */}
                            <svg xmlns="http://www.w3.org/2000/svg" className={`text-dark-blue angle-down h-6 w-6 ${['chat-room', 'messenger', 'inbox', 'compliance', 'announcement', 'event', 'permanent_class_opening'].includes(sideBardAccordion) ? 'rotated-90' : ''}`} viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                            </svg>
                          </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="4">
                          <Card.Body className="p-0">
                            <CustomChannelList 
                              // filters={groupFilter} 
                              activateChannel={this.activateChannel}
                              setUnreadMessges={this.setUnreadMessges} 
                              onClickOnEmptyState={this.handleClickOnChatAccordion}
                              dataFormat={dataFormat}
                              business={business}
                              // channelType={"team"} 
                              // customChannelFilter={this.customChannelTeamFilter}
                            />
                          </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  {/* {(dmChannelsList && dmChannelsList?.length > 0) &&
                    <Accordion className="bg-white border-b" defaultActiveKey={['messenger'].includes(sideBardAccordion) ? '0' : ['compliance', 'announcement', 'event', 'permanent_class_opening'].includes(sideBardAccordion) ? '0' : '4'}>
                      <Card className="border-0">
                        <Accordion.Toggle eventKey="4" className="bg-gray-50 py-2.5 pl-3 pr-5 border-t border-b flex items-center views-accordion-dd text-left border-0 w-full">
                          <div className='flex items-center'>
                            <div className='text-sm text-gray-600 mr-2.5'>Direct message</div>
                          </div> 
                          <div className="ml-2 text-sm text-gray-900"></div>
                            <div className="ml-auto">
                              <svg xmlns="http://www.w3.org/2000/svg" className={`text-dark-blue angle-down h-6 w-6 ${['chat-room'].includes(sideBardAccordion) ? 'rotated-90' : ''}`} viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                              </svg>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body className="p-0">
                              <CustomChannelList filters={dmFilter} activateChannel={this.activateChannel} channelType={"messaging"} customChannelFilter={this.customChannelMessagingFilter}/>
                            </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  } */}
                </React.Fragment>
              }
            </div>
          </div>
        </div>
        <Transition.Root show={sendAllModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeModal(e)}>
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
									<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none "
											onClick={(e) => this.closeModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									
                  <div className='flex items-center'>
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 ml-2">
                        Discard draft
                    </Dialog.Title>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left w-full">
                    <div className="mt-4">
                      <p className="text-sm text-gray-500">Are you sure you want to discard draft?</p> 
                    </div>
                  </div>
									
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={(e) => this.performDeleteDraft(e)}
                    >
                      Discard
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={(e) => this.closeModal(e)}
                    >
                      Cancel
                    </button>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
      </React.Fragment>
    )
  }
}
