import { useEffect, useRef, useState } from 'react'
import { useChatContext, useChannelStateContext } from 'stream-chat-react'
import { fetchFilteredMessages } from './heplers'

const channelAttachmentMessages = () => {
  const { channel, client } = useChatContext()
  const { messages } = useChannelStateContext()
  const queryInProgress = useRef(false)
  const [attachmentsLoading, setAttachmentsLoading] = useState(true)
  const [attachmentMessages, setAttachmentMessages] = useState([])

  useEffect(() => {
    if(channel) fetchAttachments()
  }, [messages])

  const fetchAttachments = async () => {
    if (queryInProgress.current) return;
    setAttachmentsLoading(true)
    try {
      queryInProgress.current = true
      const attachMessages = await fetchFilteredMessages(client, channel.cid);
      setAttachmentMessages(attachMessages)
    } catch (error) {
      console.error('Error fetching attachments:', error)
    }
    queryInProgress.current = false
    setAttachmentsLoading(false)
  }

  return {
    attachmentsLoading,
    attachmentMessages
  }
}

export default channelAttachmentMessages