import React, { Fragment } from "react";

export default function MailNotificationIcon({ classNames, customColour }){

  return(
    <Fragment>
      <svg className={classNames} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 1.75H3.5C2 1.75 1 2.5 1 4.25V7.75C1 9.5 2 10.25 3.5 10.25H8.5C10 10.25 11 9.5 11 7.75V4.25C11 2.5 10 1.75 8.5 1.75ZM8.735 4.795L7.17 6.045C6.84 6.31 6.42 6.44 6 6.44C5.58 6.44 5.155 6.31 4.83 6.045L3.265 4.795C3.105 4.665 3.08 4.425 3.205 4.265C3.335 4.105 3.57 4.075 3.73 4.205L5.295 5.455C5.675 5.76 6.32 5.76 6.7 5.455L8.265 4.205C8.425 4.075 8.665 4.1 8.79 4.265C8.92 4.425 8.895 4.665 8.735 4.795Z" fill={customColour ? customColour : "#525252"}/>
      </svg>
    </Fragment>
  )
}