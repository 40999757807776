import React, { Component } from 'react'
import { Switch } from '@headlessui/react'
import { getSudomain, setTitle, getHeaders } from './../../helpers'
import Cookies from 'js-cookie';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default class MarianaDetails extends Component {
  constructor(props){
    super(props);
    this.state = {
      business: props.business,
      show: false,
      alert_type: '',
      alert_message: '',
      siteName: props.business?.mariana_site,
      isFranchise: props.business?.marianatek_is_franchise
    }
  }

  componentDidMount = () => {
    setTitle('marina')
  }

  save = (e) => {
    e.preventDefault()
    const requestOptions = {
      method: 'POST',
      headers: getHeaders(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()),
      body: JSON.stringify({ marianatek_details: {site_name: this.state.siteName, is_franchise: this.state.isFranchise} })
    };
    fetch('/api/v3/marianatek/save', requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: result.success,
          alert_type: 'success',
          show: true
        })
        setTimeout(function(){ window.location.reload() }, 1000);
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  render() {
    const { business, show, alert_type, alert_message } = this.state

    return (
      <React.Fragment>
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto" onClick={(e) => this.setState({show: false})}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto" onClick={(e) => this.setState({show: false})}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
        <div className='w-full min-h-screen bg-gray-50'>
          <div className='h-full'>
            <div className='max-w-8xl mx-auto h-full'>
              <div className='flex gap-4 justify-between'>
                <div className='left-0'>
                  <h3 className='flex gap-3 text-lg'>
                    Add Mariana Tek Details
                  </h3>
                </div>
                <div className='right-0'>
                </div>
              </div>
              
              <div className='mt-3 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4'>
                <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                  <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                    <div className="bg-white px-3 py-2">
                      <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-5'>
                        <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Site Name
                        </label>
                        <div className='mt-1 sm:mt-0 sm:col-span-2'>
                          <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm" placeholder='Enter Site Name' onChange={(e) => this.setState({siteName: e.target.value})} defaultValue={business?.mariana_site}/>
                        </div>
                      </div>
                      <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-5'>
                        <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Is Franchise?</label>
                        <Switch
                          id="toggle_is_franchise"
                          checked={this.state.isFranchise}
                          name="is_franchise"
                          onChange={(e) => this.setState({isFranchise: !this.state.isFranchise})}
                          className={classNames(
                            this.state.isFranchise ? 'bg-gray-900' : 'bg-gray-200',
                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                          )}
                        >
                          <span className="sr-only">Use setting</span>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              this.state.isFranchise ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            )}
                          />
                        </Switch>
                      </div>
                      <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-5'>
                        <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2"></label>
                        <div className='mt-1 sm:mt-0 sm:col-span-2'>
                          <div>
                            <a className="bg-dark-blue text-white inline-block px-4 py-2 ml-auto border shadow rounded-md cursor-pointer" onClick={(e) => this.save(e)}>
                              Save Mariana Tek Details
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
