import React, { Fragment, useState } from "react";
import { MentionsInput, Mention } from 'react-mentions'
import { StylesViaJss } from "substyle-jss";
import { SendIcon, AtTagIcon } from './../../../../subRequests/assets';

export default function CommentSectionComponent(props) {
  const { user, newSubRequest, setNewSubRequest, common } = props
  const [ commentString, setCommentString ] = useState(newSubRequest?.comment || '')

  const handleCommentChange = (e) => {
    setCommentString(e.target.value)
    setNewSubRequest(prevNewSubRequest => ({
      ...prevNewSubRequest,
      comment: e.target.value
    }))
  }

  return(
    <Fragment>
      <div className={`flex flex-col gap-2 w-full ${common ? 'mb-6' : ''}`}>
        <div className="flex text-base md:text-lg text-black font-semibold items-center">
          Comments
        </div>
        <div className='flex bg-custom-gray2 px-3 rounded-2xl items-center gap-2'>
          <div className='w-7'>
            <img className='w-6 h-6 rounded-full' src={user?.image || "/assets/default-profile.jpg"}/>
          </div>
          <div className='w-full'>
            <div id='' className='relative rounded-md bg-transparent'>
              <StylesViaJss>
                <div className="w-full flex-1">
                  <div
                    className="comment-input-container row col-12"
                  >
                    <div className="col-11 px-0">
                      <MentionsInput
                        value={commentString}
                        onChange={(e) => handleCommentChange(e)}
                        className="mentions"
                        // classNames={Style}
                        allowSpaceInQuery={true}
                        placeholder="Write a comment"
                        allowSuggestionsAboveCursor={true}
                        customSuggestionsContainer={(data) => (
                          <React.Fragment>
                            {data?.props?.children?.map((item, i) =>
                              <li id={item.props.id} role="option" aria-selected="true" className={`example-module__mentions__suggestions__item___3qw61 css-7-0-1-8 flex gap-2 cursor-pointer ${item.props.focused ? 'example-module__mentions__suggestions__item--focused___3JpKM' : ''}`} key={i} onClick={item.props.onClick}>
                                <div className='w-5 h-5 bg-gray-100 rounded flex items-center justify-center'>
                                  {item.props.suggestion?.image === '' ? <p className='text-xs'>{`AB`}</p> : <img className="rounded w-5 h-5" src={item.props.suggestion?.image} />
                                  }
                                </div>
                                <div className='flex items-center ml-2'>
                                  <div className='text-gray-900 font-bold flex items-center css-2-0-1-3'>
                                    <AtTagIcon classNames={'font-bold h-4 w-4'}/>
                                    <div className="text-xs truncate">{item.props.suggestion?.display}</div>
                                  </div>
                                </div>
                              </li>
                            )}
                          </React.Fragment>
                        )}
                      >
                        <Mention 
                          data={[]} 
                          className='mentions__mention'
                          appendSpaceOnAdd={true}
                          displayTransform={(id, display) => `@${display}:`}
                          markup='@[__display__](email:__id__):'
                        />
                      </MentionsInput>
                    </div>
                  </div>
                </div>
              </StylesViaJss>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}