import React, { Fragment } from "react";
import ReactTooltip from "react-tooltip";
import { ExclamationIcon } from './../../../../../assets';
import ReactHtmlParser from 'react-html-parser';
import { dayWeekViewEventTimeFormat } from '../../../../../helpers/helpers';

export default function DayViewEventComponent(eventInfo) {
  const event_duration = eventInfo?.event?._def?.extendedProps?.duration_display || ''
  let time = 0
  let pix = '0'
  if(eventInfo?.event?._def?.extendedProps?.more_button){
    time = parseInt(eventInfo?.timeText ? eventInfo?.timeText?.split(' - ')[0]?.split(':')[1] || '0' : '0')
    pix = time == 0 ? '0' : ((48 /60 )*time) * 2
    window.setTimeout(() => {
      let elem = $(`#${eventInfo?.event?._def?.defId}-more-button`)
      if(elem?.length > 0){
        let parentDiv = elem.parent().parent().parent()
        let insetValue = parentDiv.css('inset')
        let insetValueArray = insetValue.split(' ')
        let newInsetValue = `${insetValueArray.slice(0, 3).join(' ')} 97%`
        parentDiv.css('inset', newInsetValue)
        parentDiv.css('z-index', '5')
        let SecondParentDiv = elem.parent().parent()
        SecondParentDiv.css("cssText", "background: transparent !important; box-shadow: none;")
      }
    }, 50)
  }

  return (
    <Fragment>
      {!eventInfo?.event?._def?.extendedProps?.more_button &&
        <div className='lg:flex items-start rounded-md p-1 px-2 w-full h-full week-event-border truncate event-border overflow-hidden' style={{background:`${eventInfo?.event?._def?.extendedProps?.background}`, borderColor: `${eventInfo?.event?._def?.extendedProps?.border}`}}>
          <div className={`${(event_duration && event_duration !== '' && ['1 min', '2 min'].includes(event_duration)) ? 'flex truncate' : ''}`}>
            <span className="inline-block leading-3 text-black">
              {eventInfo?.event?._def?.extendedProps?.extra_html !== '' &&
                <span className='mr-1'>
                  <Fragment>
                    {ReactHtmlParser(eventInfo?.event?._def?.extendedProps?.extra_html)}
                  </Fragment>
                </span>
              }
              <span className='event-time mr-1'>{dayWeekViewEventTimeFormat(eventInfo.timeText)}</span>
              {eventInfo?.event?._def?.extendedProps?.key &&
                <span className='event-time mr-1'>{eventInfo?.event?._def?.extendedProps?.tz}</span>
              }
              <span className='event-tile'>{eventInfo.event.title}</span>
              {(eventInfo?.event?._def?.extendedProps?.unavailable_error_message !== '' || eventInfo?.event?._def?.extendedProps?.api_sync_error_message !== '') &&
                <span className=''>
                  <ExclamationIcon classNames={"text-red-600 h-3.5 w-3.5 inline-block ml-1"} componentId={eventInfo?.event?._def?.publicId}/>
                </span>
              }
            </span>
            {(eventInfo?.event?._def?.extendedProps?.unavailable_error_message !== '' || eventInfo?.event?._def?.extendedProps?.api_sync_error_message !== '') &&
              <Fragment>
                <ReactTooltip className='max-w-xs text-center react-tooltip-z-index-max' id={eventInfo?.event?._def?.publicId.toString()} place="top" effect="solid">
                  {eventInfo?.event?._def?.extendedProps?.unavailable_error_message || eventInfo?.event?._def?.extendedProps?.api_sync_error_message}
                </ReactTooltip>
              </Fragment>
            }
          </div>
        </div>
      }
      {eventInfo?.event?._def?.extendedProps?.more_button &&
        <div id={eventInfo?.event?._def?.defId+'-more-button'} className="flex flex-col justify-center absolute bg-white right-0 border border-gray-100 rounded-md px-1 overflow-x-hidden" style={{top: `-${pix}px`, height: '96px'}}>
          <div className="flex items-center truncate custom-blue-200">
            {eventInfo?.event?._def?.extendedProps?.more_count_text}
          </div>
          {eventInfo?.event?._def?.extendedProps?.approvalErrorEventsCount > 0 &&
            <span className="flex items-center truncate whitespace-nowrap max-wc-13" >
              <span className='approval-error-span text-xs text-red-500 lowercase'>
                <ExclamationIcon classNames={"text-red-600 h-3.5 w-3.5 inline-block"} componentId={eventInfo?.event?._def?.publicId}/>
              </span>
              <span className='approval-error-span text-xs text-red-500 lowercase'>
                {eventInfo?.event?._def?.extendedProps?.approvalErrorEventsCount} approval errors
              </span>
            </span>
          }
        </div>
      }
    </Fragment>
  )
}