import React from 'react';
import { getSudomain, setTitle } from './../helpers.js'
import { getHeaders, snakeCase, getName } from './helpers.js'
import Cookies from 'js-cookie';
import { Link, Redirect } from "react-router-dom";
import FileUploader from 'devextreme-react/file-uploader';
import ProgressBar from 'devextreme-react/progress-bar';

export default class Dropzone extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
      isDropZoneActive: false,
      imageSource: '',
      textVisible: true,
      progressVisible: false,
      progressValue: 0,
			folderPathNames: [],
			folderName: null,
			path: '',
			alert_message: '',
			alert_type: '', 
			show: false,
			uploaded: false,
			uploadedId: ''
    };
    this.allowedFileExtensions = ['.jpg', '.jpeg', '.gif', '.png'];

    this.onDropZoneEnter = this.onDropZoneEnter.bind(this);
    this.onDropZoneLeave = this.onDropZoneLeave.bind(this);
    this.onUploaded = this.onUploaded.bind(this);
    this.onProgress = this.onProgress.bind(this);
    this.onUploadStarted = this.onUploadStarted.bind(this);
  }

	componentDidMount = () => {
		this.checkAvailability()
		this.props.setCurrent('Resource Hub')
		this.getfolderName()
    setTitle('resource_hub')
  }

	checkAvailability = () => { 
    const { business, user } = this.props   
    if (Object.keys(business).length != 0) {
      if(business?.resource_hub === false){
        let role = snakeCase(user?.role)        
        if(role){
          let url = `/business_user#/${getName(role)}_home`
          window.location.href = url
        }        
      }
    }
  }

	getfolderName = () => {
    let folderPath = location.pathname.split('/')
    let data = []
    for(let i = 0; i < folderPath?.length-1; i++){
      data.push(folderPath[i])
    }
    
    let folderPathName = []
    for(let i = 0; i < folderPath?.length-1; i++){
      if(i > 2){
        folderPathName.push(folderPath[i])
      }
    }
		
    let folderName = folderPath[folderPath?.length-1]
    
		let pathName = []
		for(let i = 0; i < folderPath?.length-1; i++){
			pathName.push(folderPath[i])
		}
    this.setState({
      folderPathNames: folderPathName,
      folderName: folderName,
      path: pathName.join('/'),
    })
  }

	checkPrevUrl = (index) => {
    let folderPathName = this.state.folderPathNames
    let path = []
    for(let i = 0; i < folderPathName?.length; i++){
      if(i <= index){
        path.push(folderPathName[i])
      }
    }
    return path.join('/')
  }

	componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

	onDropZoneEnter(e) {
    if (e.dropZoneElement.id === 'dropzone-external') {
      this.setState({ isDropZoneActive: true });
    }
  }

  onDropZoneLeave(e) {
    if (e.dropZoneElement.id === 'dropzone-external') {
      this.setState({ isDropZoneActive: false });
    }
  }

  onUploaded(e) {
    const { file } = e;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.setState({
        isDropZoneActive: false,
        imageSource: fileReader.result,
      });
    };
    fileReader.readAsDataURL(file);
    this.setState({
      textVisible: false,
      progressVisible: false,
      progressValue: 0,
    });
		let responce = JSON.parse(e.request.response)
		if(responce.status === 200){
			this.setState({
				alert_message: responce.message,
				alert_type: 'success', 
				show: true,
				uploadedId: responce.Id
			})
			this.setState({show:true},()=>{
				window.setTimeout(()=>{
					this.setState({show:false, uploaded: true})
				},3000)
			});
		}else{
			this.setState({
				alert_message: responce.message,
				alert_type: 'danger', 
				show: true,           
			})
			this.setState({show:true},()=>{
				window.setTimeout(()=>{
					this.setState({show:false, uploaded: false})
				},3000)
			});
		}
  }

  onProgress(e) {
    this.setState({ progressValue: (e.bytesLoaded / e.bytesTotal) * 100 });
  }

  onUploadStarted() {
    this.setState({
      imageSource: '',
      progressVisible: true,
    });
  }

	onBeforeSend = (e) => {
		const { file } = e
		let fileType = file?.type?.split('/')[0]
		let fileSize =  file?.size/1000000
		if(fileType === 'video'){
			if(fileSize > 1000){
				this.setState({
						alert_message: 'This file is too large to upload. The max supported file size is 20MB for documents and images, 100MB for audio, 1000MB for video files and 256MB for other resource file types',
						alert_type: 'danger', 
						show: true,
				})
				this.setState({show:true},()=>{
					window.setTimeout(()=>{
						this.setState({show:false})
					},5000)
				});
				throw new Error('This is not an error. This is just to abort javascript');
			}
		} else if(fileType === 'audio'){
			if(fileSize > 100){
				this.setState({
						alert_message: 'This file is too large to upload. The max supported file size is 20MB for documents and images, 100MB for audio, 1000MB for video files and 256MB for other resource file types',
						alert_type: 'danger', 
						show: true,
				})
				this.setState({show:true});
				throw new Error('This is not an error. This is just to abort javascript');
			}
		} else if(fileType === 'image' || fileType === 'document'){
			if(fileSize > 20){
				this.setState({
						alert_message: 'This file is too large to upload. The max supported file size is 20MB for documents and images, 100MB for audio, 1000MB for video files and 256MB for other resource file types',
						alert_type: 'danger', 
						show: true,
				})
				this.setState({show:true});
				throw new Error('This is not an error. This is just to abort javascript');
			}
		} else {
			if(fileSize > 256){
				this.setState({
						alert_message: 'This file is too large to upload. The max supported file size is 20MB for documents and images, 100MB for audio, 1000MB for video files and 256MB for other resource file types',
						alert_type: 'danger', 
						show: true,
				})
				this.setState({show:true});
				throw new Error('This is not an error. This is just to abort javascript');
			}
		}  
  }
	
	countBreadCrumbsNameCharectors = (name) => {
		let shortName = []
		for(let i = 0; i < name?.length; i++){
			if(i < 36){
				shortName.push(name[i])
			}else{
				shortName.push('...')
				break
			}
		}
		return shortName.join('').replace(/%20|%2|%/gi, ' ')
	}

	headers = getHeaders(Cookies.get(`currentUser.${getSudomain()}`), getSudomain())

	render(){
		const {
      isDropZoneActive, imageSource, textVisible, progressVisible, progressValue, folderPathNames, folderName, path, show, alert_message, alert_type, uploaded, uploadedId } = this.state;
		return(
			<React.Fragment>
				{uploaded &&
					<Redirect push to={`publish/${uploadedId}`} />
				}
				{show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto" onClick={() => {this.setState({show:false})}}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto" onClick={() => {this.setState({show:false})}}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
				<div className="w-full min-h-screen bg-gray-50">
					<div className="pt-20 md:pt-8 h-full">
						<main className="h-full">
							<div className="max-w-8xl mx-auto px-6 lg:px-8 h-full pb-8">
								<div className="t-breadcrumbs">
									<nav className="flex">
										<ol className="flex items-center flex-wrap">
											<li>
												<div className="flex items-center">
													<Link to="/resource_hub" className="md:text-xl text-sm font-medium text-gray-500 hover:text-gray-700">Resource hub</Link>
												</div>
											</li>
											{folderPathNames && folderPathNames.map((name, i) =>
												<li key = {i}>
													<div className="flex items-center">
														<svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
														<Link to = {`/resource_hub/folder/${this.checkPrevUrl(i)}`} className="md:text-xl text-sm font-medium text-gray-500 hover:text-gray-700">{this.countBreadCrumbsNameCharectors(name)}</Link>
													</div>
												</li>
											)}
											<li>
												<div className="flex items-center">
													<svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
													<a href="#" className="md:text-xl text-sm font-medium text-gray-700 hover:text-gray-700">Upload file(s)</a>
												</div>
											</li>
										</ol>
									</nav>
								</div>
								<Link to={path} className="bg-white text-xs py-2 px-2.5 inline-flex my-4 rounded text-gray-700 border-solid border-2 border-gray-200">Back</Link>
								
								<div id="dropzone-external" className={`border-dashed relative border-2 md:p-6 p-3 rounded-md cursor-pointer ${isDropZoneActive ? 'border-gray-500': 'border-gray-300'}`}>
									<div className="bg-transparent textIindent absolute cursor-pointer w-full">
										<FileUploader
											id="file-uploader"
											accept='*'
											dialogTrigger="#dropzone-external"
											dropZone="#dropzone-external"
											multiple={false}
											chunkSize={6000001}
											uploadMode="instantly"
											uploadUrl={`/api/v3/resource_hub/chunk_upload?subdomain=${this.headers.subdomain}&authorization=${this.headers.Authorization}&folder=${folderPathNames.join('/')}`}
											visible={false}
											onBeforeSend={this.onBeforeSend}
											onDropZoneEnter={this.onDropZoneEnter}
											onDropZoneLeave={this.onDropZoneLeave}
											onUploaded={this.onUploaded}
											onProgress={this.onProgress}
											onUploadStarted={this.onUploadStarted} />
									</div>
									<div className="bg-gray-100 py-40 rounded-md">
										<div className="flex items-center flex-col gap-y-4">
											{progressVisible &&
												<div className='md:w-72 w-56 text-center'>
													<div className="overflow-hidden h-1 text-lg flex rounded bg-gray-300">
														<div
															//style={{width: '50%'}}
															style={{width: `${Math.ceil(progressValue)}%`}}
															className="
																shadow-none
																flex flex-col
																text-center
																whitespace-nowrap
																justify-center
																bg-black
															"
														></div>
													</div>
													<p className="text-gray-900 text-base mt-3">{Math.ceil(progressValue)}%</p>
												</div>
											}
											<p className="text-gray-900 text-lg font-bold text-center">Drag and drop any media(s) here</p>
											<span className="text-gray-900 text-base">Or</span>
											<div className="bg-dark-blue text-white inline-flex items-center rounded-md font-sm px-3 h-9">
												<button type="submit" className="flex items-center relative">
													{/* <input type="file" className="bg-transparent textIindent absolute cursor-pointer w-full" /> */}
													<div>
														<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
															<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
														</svg>
													</div>
													<div className="text-white ml-2.5">
														Select File(s)
													</div>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</main>
					</div>
				</div>
			</React.Fragment>
		)
	}
}