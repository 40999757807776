import React, { Fragment } from "react";
import NoEventsComponent from './../../calendarRangeTypeSelectorComponents/yearViewCalendarComponent/dayEventsPopup/noEventsComponent';

export default function EmptyTableComponent() {
  return(
    <Fragment>
      <div className='bg-white shadow-md min-h-screen border border-gray-300 px-6 pt-7 flex justify-center rounded-b-2xl items-center'>
        <NoEventsComponent />
      </div>
    </Fragment>
  )
}