import React, { Component, Fragment } from 'react'
import { updateBusiness, uploadLogo, uploadDefaultThumbnail, deleteDefaultThumbnail} from './helpers'
import { getSudomain, setTitle, checkSettingsPermissios } from './../../helpers'
import { ResourceThumbnailMinHeight, ResourceThumbnailMinWidth } from "../../resource_hub/helpers"
import Cookies from 'js-cookie';
import AccountSubbingComponent from './accountSubbingComponent';
import AboutSectionComponent from './aboutSectionComponent';
import SubPolicy from '../../configure/subRequestPolicy';
import DeleteThumbnailPopupComponent from './deleteThumbnailPopupComponent';
import AlertPopup from '../../messenger/pages/chatComponents/assets/alertPopup'

export default class AccountSettings extends Component {
  constructor(props){
    super(props);
    this.state = {
      business: props.business,
      show: false,
      alert_type: '',
      alert_message: '',
      thumbnailUploading: false,
      deleteThumbnailPopupOpen: false,
      defaultPreviewImg: null,      
			thumbnailMetadata: {},
			attachment: null,
      businessLogoFileName: '',
      businessLogoFileShortName: '',
      dateFormat: 'dd/mm/yyyy',
      loaded: false,
      firtTime: true,
      disabledPage: !['Netgym Employee', 'Account Owner'].includes(props.user.role_display)
    }
    this.thumbnailRef = React.createRef();
  }
  
  componentDidMount = () => {
    setTitle('account_info')
    if(Object.keys(this.props.user).length !== 0){
      checkSettingsPermissios('account_info', this.props)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.pageLoaded && this.state.firtTime){
      this.setState({
        loaded: true,
        firtTime: false
      })
    }
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  getPreview = (e) => {
    let val = e.target.value
    if(val === ""){
      return;
    }
    this.handleChange(e)
  }

  handleChange = (e) => {
    const { name } = e.target
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({
      business: {
        ...this.state.business,
        [name]: value
      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { name } = this.state.business;
    this.setState({
      submitted: true
    });
    if (name !== "") {
      this.setState({thumbnailUploading: true, show: false}, () => {
        this.saveAll()
      })
    } else {
      console.log("Error creating location");
    }
  }

  saveAll = async () => {
    const response = await this.submitForm();
    let message = "Business details Updated";
    if(response.status === 200) { 
      if(Boolean(this.state.attachment)){
        const fileUploadResponse = await this.uploadAttachmentAsync();
        if(fileUploadResponse.status === 200){
          message = "Business details Updated and Thumbnail uploaded Successfully"
          this.setState({
            alert_message: message,
            alert_type: 'success', 
            show: true,           
            loaded: true,
            thumbnailUploading: false,
            showNotifyAudiencePopup: false
          })			
          this.setState({show:true},()=>{
            window.setTimeout(()=>{
              this.setState({show:false, uploaded: true})
              window.location.reload(true);
            },3000)
          });	
        }else {
          this.setState({
            alert_message: "Uploading thumbnail failed.Try again",
            alert_type: 'danger', 
            show: true,           
            loaded: true,
            thumbnailUploading: false,
            showNotifyAudiencePopup: false
          })			
          this.setState({show:true},()=>{
            window.setTimeout(()=>{
              this.setState({show:false})
            },3000)
          });					
        }
      }else {
        this.setState({
          alert_message: message,
          alert_type: 'success', 
          show: true,           
          loaded: true,
          thumbnailUploading: false,
          showNotifyAudiencePopup: false
        })			
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false, uploaded: true})
            window.location.reload(true);
          },3000)
        });	        
      }  
    }else {
        this.setState({
          alert_message: response.error,
          alert_type: 'danger',
          show: true,
          loaded: true,
          thumbnailUploading: false,
        })
    }

  }

  submitForm = (e) => {    
    return updateBusiness(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.business).then(      
      response => response.json()
    ).then(result => {
			return result;
		}).catch (error => {
			return error;
		})    
  }

  upload = (e) => {
    e.preventDefault()
    let fullName = e.target.files[0].name
    let shortName = ''
    if(fullName.length > 22){
      let startText = fullName.slice(0, 11);
      let endText = fullName.slice(-11);
      shortName = `${startText}...${endText}`
    }else{
      shortName = fullName
    }
    this.setState({
      businessLogoFileName: fullName,
      businessLogoFileShortName: shortName
    })
    
    uploadLogo(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), e.target.files[0]).then(      
      response => response.json()
      .then(result => { 
        if(result.status === 200){
          this.setState({
            show: true,
            alert_message: 'Logo Updated Successfully',
            alert_type: 'success',            
            loaded: true
          })
          setTimeout(function(){ window.location.reload() }, 1500);
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger',            
            loaded: true
          })
        }        
      })
    )
  }

  getDimensions = async (f) => {
		let meta = {};
		const fileCallbackToPromise = (fileObj) => {
			return Promise.race([
				new Promise((resolve) => {
					if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve;
					else fileObj.onloadedmetadata = resolve;
				}),
				new Promise((_, reject) => {
					setTimeout(reject, 2000);
				})
			]);
		};

		const objectUrl = URL.createObjectURL(f);
		const img = document.createElement('img');
		img.src = objectUrl;
		try {
			await fileCallbackToPromise(img);
			this.setState({ isMetadataPresent: true });
		} catch (error) {
			this.setState({ isMetadataPresent: false });
		}
		return {
			width: img.width,
			height: img.height,
		};
	};


  setThumbnailData = async (e) => {
		this.setState({ ...this.state, [e.target.name]: e.target.files[0] });
		//reset if there is alert
		this.setState({
			show: false,
			alert_message: "",
		})	    
		
    e.preventDefault()
    const file = e.target.files[0];
		const {files} = e.target;
    const meta = await this.getDimensions(file);
    if(meta.width < ResourceThumbnailMinWidth || meta.height < ResourceThumbnailMinHeight) {
        this.setState({
          show: true,
          // alert_message: `Upload Failed: Your image needs to be between ${ResourceThumbnailMinWidth}x${ResourceThumbnailMinHeight}px and ${ResourceThumbnailMaxWidth}x${ResourceThumbnailMaxHeight}px - please try again.`,
          alert_message: 'Thumbnail must have a minimum width/height '+ResourceThumbnailMinWidth+"x"+ResourceThumbnailMinHeight+"px",
          alert_type: 'danger',            
          loaded: true,
          attachment: null,
          defaultPreviewImg: null
        }, ()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },5000)
        })  
        this.thumbnailRef.current.value = null;
        return true;    
    }
    // if(meta.width > ResourceThumbnailMaxWidth || meta.height > ResourceThumbnailMaxHeight) {
    //   this.setState({
    //     show: true,
    //     alert_message: `Upload Failed: Your image needs to be between ${ResourceThumbnailMinWidth}x${ResourceThumbnailMinHeight}px and ${ResourceThumbnailMaxWidth}x${ResourceThumbnailMaxHeight}px - please try again.`,
    //     // alert_message: 'Width/Height of Thumbnail must not exceed '+ResourceThumbnailMaxWidth+"x"+ResourceThumbnailMaxHeight+"px",
    //     alert_type: 'danger',            
    //     loaded: true,
    //     attachment: null
    //   }, ()=>{
		// 		window.setTimeout(()=>{
		// 			this.setState({show:false})
		// 		},5000)
		// 	})   
    //   return true;   
    // }
    this.setPreviewHandler(file);
    const details = {
      name: file.name,
      size: file.size,
      width: meta.width,
      height: meta.height,
    }    
		this.setState({thumbnailMetadata: details})
	}  


	uploadAttachmentAsync = () => {
		const {thumbnailMetadata, attachment} = this.state;
		if(Boolean(this.state.attachment)){
			const formData = new FormData();
			formData.append( "thumbnail", attachment)
			formData.append( "content_type", attachment.type)
			formData.append( "name", thumbnailMetadata.name)
			formData.append( "size", thumbnailMetadata.size)
			formData.append( "width", thumbnailMetadata.width)
			formData.append( "height", thumbnailMetadata.height)
      formData.append( "type", "Business")
      formData.append( "id", this.state.business.id)	    
    
			return uploadDefaultThumbnail(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), formData )
			.then(result => {
				return result;
			}).catch (error => {
				return error;
			})
		}
  }    

	deleteThumbnail = (business_id) => {
		if(business_id){
			this.setState({deleteThumbnailPopupOpen: false}, () => {
				deleteDefaultThumbnail(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), business_id, "Business").then(      
					response => response.json()
				)
				.then(result => {
					if(result.status === 200){
						this.setState({
							alert_message: result.message,
							alert_type: 'success', 
							show: true,
						})
						this.setState({show:true},()=>{
							window.setTimeout(()=>{
								this.setState({show:false})
								window.location.reload(true)
							},3000)
						});
					}else{
						this.setState({
							alert_message: result.error,
							alert_type: 'danger', 
							show: true,     
						})
						this.setState({show:true},()=>{
							window.setTimeout(()=>{
								this.setState({show:false})
								window.location.reload(true)
							},3000)
						});
					}
				})	
			})			
		}
	} 

	deleteThumbnailHandler = () => {
		const { deleteThumbnailPopupOpen } = this.state
		this.setState({deleteThumbnailPopupOpen: !deleteThumbnailPopupOpen})
	}

	setPreviewHandler = (file) => {
		this.setState({defaultPreviewImg: URL.createObjectURL(file)})
	}

	removeAttachmentHandler = () => {
		this.setState({defaultPreviewImg: null, attachment: null})
	}	

  closeAlert = (data) => {
    this.setState({show: data})
  }

  setAlert = (show, alertType, alertMessage) => {
    this.setState({show: show, alert_type: alertType, alert_message: alertMessage})
  }
  
  render() {
    const { business, show, alert_type, alert_message, thumbnailUploading, deleteThumbnailPopupOpen, defaultPreviewImg, businessLogoFileName, businessLogoFileShortName, dateFormat, loaded, disabledPage } = this.state
    const { loadingMessage, user } = this.props

    return (
      <React.Fragment>
        {deleteThumbnailPopupOpen &&
          <DeleteThumbnailPopupComponent business={business} deleteThumbnailPopupOpen={deleteThumbnailPopupOpen} deleteThumbnailHandler={this.deleteThumbnailHandler} deleteThumbnail={this.deleteThumbnail}/>
        }
        {show &&
          <AlertPopup alert_type={alert_type} alert_message={alert_message} closeAlert={this.closeAlert} />
        }
        {!loaded && 
          <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
            <p className="mb-1 text-center px-5">{loadingMessage}</p>
            <img className = "w-9" src="/assets/business/loading.gif" />
          </div>
        }
        {loaded &&
          <Fragment>
            <AboutSectionComponent business={business} handleChange={this.handleChange} upload={this.upload} businessLogoFileName={businessLogoFileName} businessLogoFileShortName={businessLogoFileShortName} defaultPreviewImg={defaultPreviewImg} removeAttachmentHandler={this.removeAttachmentHandler} thumbnailUploading={thumbnailUploading} setThumbnailData={this.setThumbnailData} dateFormat={dateFormat} getPreview={this.getPreview} handleSubmit={this.handleSubmit} thumbnailRef={this.thumbnailRef} deleteThumbnailHandler={this.deleteThumbnailHandler}/>
            <AccountSubbingComponent business={this.props.business} setAlert={this.setAlert} user={user} disabledPage={disabledPage}/>
            <SubPolicy user={user} business={business} loadingMessage={''} disabledPage={disabledPage}/>
          </Fragment>
        }
      </React.Fragment>
    )
  }
}
