import React, { useState } from 'react';
import CustomPopup from './../../../assets/customPopup'
import { axiosRequest } from './../../../helpers'
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";

export default function ContactInfoEdit(props){
  const { user, editContactInfo, setEditContactInfo, showPopup, handlePhoneVerified } = props
  const countryList = ['US', 'AU', 'PR', 'NL', 'GB', 'TH', 'FI', 'CA', 'NZ', 'IE']
  const [ phoneNumber, setPhoneNumber ] = useState(user?.directory_phone_number == '' ? undefined : user?.directory_phone_number.startsWith('+')? user?.directory_phone_number : '+'+user?.directory_phone_number)
  const [ verificationCode, setVerificationCode ] = useState('')
  const [ submitted, setSubmitted ] = useState(false)
  const [ validPhoneNumber, setValidPhoneNumber ] = useState(user?.directory_phone_number == '' ? false : (parsePhoneNumber(user?.directory_phone_number) && countryList.includes(parsePhoneNumber(user?.directory_phone_number).country) && isValidPhoneNumber(user?.directory_phone_number.startsWith('+') ? user?.directory_phone_number : '+'+user?.directory_phone_number) === true))
  const [ verifyCodeSend, setVerifyCodeSend ] = useState(false)

  const closeModal = (data) => {
    setEditContactInfo(data)
  }

  const sendVerificationCode = (e) => {
    let params = { phone_number: phoneNumber }
    axiosRequest('/api/v3/my_profile/send_verification_code', 'POST', params, 'data').then(result => {
      if(result.status === 200){
        showPopup(true, 'success', result?.success)
        setVerifyCodeSend(true)
			}else{
        showPopup(true, 'danger', result?.error)
        setVerifyCodeSend(false)
			}
      window.setTimeout(() => {
        showPopup(false, '', '')
      }, 3000)
		})
  }

  const VerifyPhoneNumver = (e) => {
    e.preventDefault();
    setSubmitted(true)

    if(verificationCode !== '' && verificationCode?.split("")?.length == 6){
      let params = { code: verificationCode }
      axiosRequest('/api/v3/my_profile/confirm_verification_code', 'POST', params, 'data').then(result => {
        if(result.status === 200){
          showPopup(true, 'success', result?.success)
          setEditContactInfo(false)
          handlePhoneVerified()
        }else{
          showPopup(true, 'danger', result?.error)
        }
        window.setTimeout(() => {
          showPopup(false, '', '')
        }, 3000)
      })
    }
  }

  const handleSetPhoneNumber = (value) => {
    if (value){
      let phoneNumber = parsePhoneNumber(value)
      if(phoneNumber && countryList.includes(phoneNumber.country) && isValidPhoneNumber(value) === true){
        setValidPhoneNumber(true)
      }else{
        setValidPhoneNumber(false)
      }
      setPhoneNumber(value)
    }else{
      setValidPhoneNumber(false)
      // setPhoneNumber('')
    }
  }

  return(
    <React.Fragment>
      <CustomPopup show={editContactInfo} close={closeModal} title={"Verify Phone Number"}>
        <React.Fragment>
          <div className="mt-3 border-t border-gray-200 sm:h-64">
            <div className='flex flex-wrap sm:gap-4 sm:items-center pt-2 px-7'>
              <label htmlFor="first-name" className="block text-sm text-gray-500 sm:mt-px w-32 sm:pt-0 text-left flex items-center">
                Phone Number
              </label>
              <div className='flex flex-wrap gap-3'>
                <div className="sm:col-span-1 flex items-center">
                  <div className="lg:w-56 relative messenger-box py-1.5">
                    {/* <PhoneInput
                      className="border rounded-md pl-2 pr-2 py-1.5 w-full shadow-sm"
                      value={phoneNumber}
                      onChange={handleSetPhoneNumber}
                      defaultCountry="US"
                      id="phone-input"
                      placeholder='+1 201-555-5555'
                      countries={countryList}
                    /> */}
                    {phoneNumber}
                  </div>
                </div>
                <div className='flex items-center'>
                  <button className={`flex items-center px-3.5 py-2 gap-1 border border-gray-300 shadow-sm font-medium rounded-md whitespace-nowrap ${!validPhoneNumber ? 'button-disabled bg-gray-400' : 'bg-gray-800'}`} disabled={!validPhoneNumber} onClick={(e) => sendVerificationCode(e)}>
                    <div className="flex items-center">
                      <div className='text-white text-sm font-normal'>{"Send Code"}</div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className='flex sm:gap-4 sm:items-center px-7 pt-2 justify-start sm:justify-center'>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="lg:w-56 relative messenger-box py-1.5">
                  <input type='text' placeholder='Enter Verification Code'  className="border rounded-md pl-4 pr-12 py-2 w-full shadow-sm focus:outline-none text-sm" name="verificationCode" value = {verificationCode} ui-mask="999999" ui-mask-placeholder="true" ui-mask-placeholder-char="_" onChange={(e) => setVerificationCode(e.target.value)} minLength={"6"} maxLength={"6"}/>
                </div>
                {(verificationCode !== "" && verificationCode?.split("")?.length !== 6) && 
                  <span className="error text-xs">*Length of code must be 6 digits.</span>
                }
                {submitted && verificationCode == '' && (
                  <span className="error text-xs">*Please enter text code.</span>
                )}
              </div>
            </div>
            <div className='flex sm:gap-4 sm:items-center px-7 pt-3.5 justify-start sm:justify-center'>
              <button className={`flex items-center px-3.5 py-2 gap-1 border border-gray-300 shadow-sm font-medium rounded-md ${!verifyCodeSend ? 'button-disabled bg-gray-400' : 'bg-gray-800'}`} onClick={(e) => VerifyPhoneNumver(e)} disabled={!verifyCodeSend}>
                <div className="flex items-center">
                  <div className='text-white text-base'>Verify</div>
                </div>
              </button>
            </div>
          </div>
        </React.Fragment>
      </CustomPopup>
    </React.Fragment>
  )
} 