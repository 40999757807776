import React, { Fragment, useState } from 'react';
import { axiosGetExportRequest } from './../../../helpers'

export default function ExportComponent(props){
  const { pageTab } = props
  const [ disableExpBtn, setDisableExpBtn ] = useState(false)

  const handleExport = (e) => {
    e.preventDefault() 
    setDisableExpBtn(true)       
    axiosGetExportRequest(`/api/v3/export_users?pageTab=${pageTab}`).then(result => { 
      const url = window.URL.createObjectURL(result)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', "users.csv")
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)        
      setDisableExpBtn(false)
    })
  }

  return(
    <Fragment>
      <div className="relative filters_box">
        <button className={`border rounded-md border-gray-300 shadow-sm text-gray-700 text-sm py-2 px-4 h-10 block cursor-pointer flex items-center ${disableExpBtn ? 'button-disabled bg-gray-200' : 'bg-white hover:bg-gray-100'}`} onClick={(e) => handleExport(e)} disabled={disableExpBtn}>
          <div className={`${disableExpBtn ? 'text-gray-500' : 'text-gray-700'} flex items-center gap-2.5 relative px-1`}>
            {disableExpBtn &&
              <div className="flex items-center -left-3.5 justify-center absolute">
                <svg className="animate-spin h-3.5 w-3.5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            }
            Export Users
          </div>
        </button>
      </div>
    </Fragment>
  )
}