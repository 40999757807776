import React, { Fragment } from "react";

export default function BreadCrumbArrowIcon ({classNames}) {
  return (
    <Fragment>
      <svg className={classNames} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
          <path d="M8 5L13 10L8 15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
      </svg>

    </Fragment>
  );
}