import React, { Fragment } from "react";

export default function RequestingInstructorComponent(props){
  const { eventData, handleShowInstructorDetails, type, user, bucketType, path } = props

  return(
    <Fragment>
      {(eventData?.very_original_instructor?.id !== eventData?.requesting_instructor?.id && eventData?.reopened) ?
        <Fragment>
          <div className='flex items-center mb-1'>
            <img src={eventData?.very_original_instructor?.image} className='w-5 h-5 rounded-full cursor-pointer' onClick={() => handleShowInstructorDetails(eventData?.very_original_instructor)}/>
            <div className={`text-xs font-semibold ml-2`}>Original Instructor:</div>
            <button className={`custom-blue-200 text-xs font-semibold ml-1`} onClick={() => handleShowInstructorDetails(eventData?.very_original_instructor)}>
              {['my-requests']?.includes(path) && bucketType == 'sent' && eventData?.very_original_instructor?.id == user?.id ? 
                "Me" 
              :
                eventData?.very_original_instructor?.full_name
              }
            </button>
          </div>
          <div className='flex items-center mb-1'>
            <img src={eventData?.requesting_instructor?.image} className='w-5 h-5 rounded-full cursor-pointer' onClick={() => handleShowInstructorDetails(eventData?.requesting_instructor)}/>
            <div className={`text-xs font-semibold ml-2`}>Reopened Sub:</div>
            <button className={`custom-blue-200 text-xs font-semibold ml-1`} onClick={() => handleShowInstructorDetails(eventData?.requesting_instructor)}>
              {['my-requests']?.includes(path) && bucketType == 'sent' && eventData?.requesting_instructor?.id == user?.id ? 
                "Me" 
              :
                eventData?.requesting_instructor?.full_name
              }
            </button>
          </div>
        </Fragment>
        :
        <Fragment>
          <div className='flex items-center mb-1'>
            <img src={eventData?.requesting_instructor?.image} className='w-5 h-5 rounded-full cursor-pointer' onClick={() => handleShowInstructorDetails(eventData?.requesting_instructor)}/>
            {eventData?.status_content.toLowerCase().includes('approved') &&
              <div className={`text-xs font-semibold ml-2`}>Original Instructor:</div>
            }
            <button className={`custom-blue-200 text-xs font-semibold ${eventData?.status_content.toLowerCase().includes('approved') ? 'ml-1' : 'ml-2'}`} onClick={() => handleShowInstructorDetails(eventData?.requesting_instructor)}>
              {['my-requests']?.includes(path) && bucketType == 'sent' && eventData?.requesting_instructor?.id == user?.id ? 
                "Me" 
              :
                eventData?.requesting_instructor?.full_name
              }
            </button>
          </div>
        </Fragment>
      }
    </Fragment>
  )
}