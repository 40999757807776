import React, { Fragment, useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSlash } from '@fortawesome/free-solid-svg-icons'
library.add( faUserSlash )
import NoShowConfirmationComponent from '../../../../../subRequestDetails/components/topButtonsComponent/moreActionComponent/noShowConfirmationComponent';

export default function UndoNoShowButtonComponent(props) {
  const { event, handleSetAlert, handleUpdateEvent } = props
  const [ undoNoShowOption, setUndoNoShowOption ] = useState(event?.more_action_detail?.filter(item => item?.key == 'undo_no_show_sub_request'))
  const [ showUndoNoShowPopup, setShowUndoNoShowPopup ] = useState(false)

  useEffect(() => {
    if(event){
      setUndoNoShowOption(event?.more_action_detail?.filter(item => item?.key == 'undo_no_show_sub_request'))
    }
  }, [event])

  return(
    <Fragment>
      {showUndoNoShowPopup &&
        <NoShowConfirmationComponent eventData={event} handleSetAlert={handleSetAlert} closeNoShowPopup={setShowUndoNoShowPopup} handleUpdateEvent={handleUpdateEvent} showNoShowPopup={showUndoNoShowPopup} noShowPopupType={'undo_no_show_sub_request'} type={'preview'} calendarEvent={false} handleUpdateCalendarEvent={() => {}}/> 
      }
      {undoNoShowOption.length > 0 ?
        <button className='flex relative z-20 justify-center items-center border border-gray-300 rounded-full min-h-8 min-w-8' onClick={() => setShowUndoNoShowPopup(true)} data-tip data-for={`undo_no_show_button_`+event?.id.toString()}>
          <FontAwesomeIcon className='w-3.5 h-3.5 shrink-0' icon="fa-solid fa-user-slash" />
          <ReactTooltip className='max-w-xs whitespace-nowrap' id={`undo_no_show_button_`+event?.id.toString()} place="top" effect="solid">
            {undoNoShowOption[0]?.value}
          </ReactTooltip>
        </button>
      :
        null
      }
    </Fragment>
  )
}