import React, { Fragment } from "react";

export default function HeaderComponent(props) {
  const { subdomainBusinessImage } = props

  return(
    <Fragment>
      <div className="fixed w-full z-40 flex-shrink-0 flex bg-white px-4 py-2 max-h-24">
        <div className="max-w-20">
          <img src={subdomainBusinessImage} />
        </div>
      </div>
    </Fragment>
  )
}