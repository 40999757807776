import React, { Fragment } from "react";

export default function NotificationSelectionComponent(props) {
  const {user, notifications, handleCheckBox } = props

  return(
    <Fragment>
      <div className="flex flex-col items-start gap-2">
        {!user?.sms_notifications_restriction && user?.sms_notifications && 
          <label className={`flex items-center gap-1 text-sm assign-checkbox`}> <input type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer" name='notifications' checked={notifications == "sms"} value="sms" onChange={(e) => handleCheckBox(e)}/> SMS only</label>
        }
        <label className={`flex items-center gap-1 text-sm assign-checkbox`}> <input type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer" name='notifications' checked={notifications == "email"} value="email" onChange={(e) => handleCheckBox(e)}/> Email only</label>
        {user?.sms_notifications &&   
          <label className={`flex items-center gap-1 text-sm assign-checkbox`}> <input type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer" checked={notifications == "all"} name='notifications' value="all" onChange={(e) => handleCheckBox(e)}/> SMS and Email</label>
        }
        <label className={`flex items-center gap-1 text-sm assign-checkbox`}> <input type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer" name='notifications' checked={notifications == "away"} value="away" onChange={(e) => handleCheckBox(e)}/> Turn Off</label>
      </div>
    </Fragment>
  )
}