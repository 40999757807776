import React from "react";

export const AudienceIcon = ({classNames}) => {
  return (
    <svg className={`${classNames}`} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.13332 9.05833C8.04999 9.05 7.94999 9.05 7.85832 9.05833C5.87499 8.99167 4.29999 7.36667 4.29999 5.36667C4.29999 3.325 5.94999 1.66667 7.99999 1.66667C10.0417 1.66667 11.7 3.325 11.7 5.36667C11.6917 7.36667 10.1167 8.99167 8.13332 9.05833Z" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.175 3.33333C15.7916 3.33333 17.0917 4.64167 17.0917 6.25C17.0917 7.825 15.8417 9.10833 14.2833 9.16667C14.2167 9.15833 14.1417 9.15833 14.0667 9.16667" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.96666 12.1333C1.95 13.4833 1.95 15.6833 3.96666 17.025C6.25833 18.5583 10.0167 18.5583 12.3083 17.025C14.325 15.675 14.325 13.475 12.3083 12.1333C10.025 10.6083 6.26666 10.6083 3.96666 12.1333Z" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.7833 16.6667C16.3833 16.5417 16.95 16.3 17.4167 15.9417C18.7167 14.9667 18.7167 13.3583 17.4167 12.3833C16.9583 12.0333 16.4 11.8 15.8083 11.6667" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}