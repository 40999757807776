import React, { Fragment, useState } from "react";
import { axiosGetRequest } from '../../../subRequests/helpers/helpers';
import { Link } from "react-router-dom";

export default function ForgotPasswordComponent(props) {
  const { loginUser, emailRegex, setAlert } = props;
  const [ email, setEmail ] = useState(loginUser?.email || '');
  const [ submited, setSubmited ] = useState(false);
  const [ disableSubmit, setDisableSubmit ] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [ passwordLinkSent, setPasswordLinkSent ] = useState(false);

  const validateEmail = (email) => {
    return emailRegex.test(email);
  };

  const handleBlur = () => {
    setIsTouched(true);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let url = `/api/v3/forget_password?email=${encodeURIComponent(email)}`
    if (email !== ''){
      setDisableSubmit(true);
      axiosGetRequest(url).then((result) => {
        if(result?.status === 200){
          setSubmited(true);
          setPasswordLinkSent(true);
        }else{
          setAlert(true, 'danger', result?.error)
        }
        setTimeout(() => {
          setDisableSubmit(false);
          setAlert(false, '', '')
        }, 3000)
      })
    }
  }

  return (
    <Fragment>
      {!passwordLinkSent &&
        <div className="flex flex-col gap-4 w-full">
          <h1 className="text-2xl text-center">Forgot Password</h1>
          <div className="flex items-center justify-end grid grid-cols-1 md:grid-cols-4 items-start">
            <label
              htmlFor="email"
              className="block text-sm font-semibold text-black col-span-1 pb-2 md:pb-0"
            >
              Email
            </label>
            <div className="sm:mt-0 col-span-1 md:col-span-3">
              <input
                type="email"
                className="block w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 px-4"
                id="email"
                name="email"
                placeholder="Enter Email"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {(submited || isTouched) && email == '' &&
                <p className="text-xs text-red-500 mt-1">Please enter an email.</p>
              }
              {(submited || isTouched) && email !== '' && !validateEmail(email) &&
                <p className="text-xs text-red-500 mt-1">Invalid Email.</p>
              }
            </div>
          </div>
          <div className="flex items-center justify-end grid grid-cols-1 md:grid-cols-4 items-start mt-2">
            <div className="hidden md:flex"></div>
            <div className="sm:mt-0 col-span-1 md:col-span-3 gap-2 flex items-center flex-wrap">
              <button
                className={`bg-gray-900 rounded-xl text-white flex items-center justify-center px-8 py-2 text-sm w-full md:w-1/2 ${(disableSubmit || (email =='' || (email !=='' && !validateEmail(email)))) ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={(e) => handleSubmit(e)}
                disabled={(disableSubmit || (email =='' || (email !=='' && !validateEmail(email))))}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      }
      {passwordLinkSent &&
        <div className="flex flex-col items-center gap-4 w-full">
          <h1 className="text-base">{`An email has been sent to ${email} for you to reset your password.`}</h1>
          <Link
            to='/signin'
            className={`bg-gray-900 rounded-xl text-white flex items-center justify-center px-8 py-2 text-sm w-full md:w-1/2`}
          >
            Back
          </Link>
        </div>
      }
    </Fragment>
  )
}