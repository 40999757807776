import React, { Fragment, useState } from "react";
import PhoneInputFieldComponent from './phoneInputFieldComponent';
import ConfirmVerificationCodeComponent from './confirmVerificationCodeComponent';
import SkipVerificationComponent from './skipVerificationComponent';

export default function PhoneVerifyComponent(props) {
  const { user, setAlert, handleUpdateUser, updateUserDataAndRedirect } = props
  const [ verificationCodeSent, setVerificationCodeSent ] = useState(false)
  const [ phoneNumberToHold, setPhoneNumberToHold ] = useState(user?.directory_phone_number || '')

  return (
    <Fragment>
      <div className="w-full flex flex-col items-center justify-center mt-4">
        {!verificationCodeSent &&
          <Fragment>
            <div className="flex items-center justify-center text-center text-lg text-gray-900 font-normal">
              {user?.allow_phone_verification ? "Optional: Add your phone number as an option to receive SMS/text updates." : "Optional: Add your phone number so your team has your contact information in the directory."}
            </div>
            {user?.allow_phone_verification &&
              <div className="flex flex-col items-center justify-center gap-3 mt-7">
                <div className="flex text-gray-600 text-xs text-center justify-center items-center">
                  Please enter your phone number and we'll text you a verification code to finish setting up SMS notifications.
                </div>
                <div className="flex text-gray-600 tc-10 text-center justify-center items-center">
                  * Message and data rates may apply from your mobile service provider
                </div>
              </div>
            }
            <div className="flex w-full justify-center mt-7">
              <PhoneInputFieldComponent 
                user={user} 
                setAlert={setAlert}
                setVerificationCodeSent={setVerificationCodeSent}
                updateUserDataAndRedirect={updateUserDataAndRedirect}
                handleUpdateUser={handleUpdateUser}
                phoneNumberToHold={phoneNumberToHold}
                setPhoneNumberToHold={setPhoneNumberToHold}
              />
            </div>
          </Fragment>
        }
        {verificationCodeSent &&
          <Fragment>
            <div className="flex items-center justify-center text-center text-lg text-gray-900 font-normal">
              Please enter the verification code we sent to your phone. If you haven’t received it, click resend code.
            </div>
            <div className="flex flex-col w-full items-center mt-7">
              <ConfirmVerificationCodeComponent
                user={user}
                setAlert={setAlert}
                setVerificationCodeSent={setVerificationCodeSent}
                handleUpdateUser={handleUpdateUser}
              />
            </div>
          </Fragment>
        }
        <SkipVerificationComponent 
          user={user}
          setAlert={setAlert}
          handleUpdateUser={handleUpdateUser}
          verificationCodeSent={verificationCodeSent}
          updateUserDataAndRedirect={updateUserDataAndRedirect}
        />
      </div>
    </Fragment>
  )
}