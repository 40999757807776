import React, { Fragment, useState } from "react";
import EyeIcons from '../../assets/eyeIcons';
import { switchAccountOwner, loginAPI, setCurrentUserToken, removeAddAccountCookies, switchAccountBusiness } from '../../helpers';
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import { Redirect, Link } from "react-router-dom";

export default function LoginComponent(props) {
  const { emailRegex, loginUser, setLoginUser, subdomain, setAlert, updateUserData } = props;
  const [ email, setEmail ] = useState(loginUser?.email || '');
  const [ password, setPassword ] = useState('');
  const [ showPassword, setShowPassword ] = useState(false);
  const [ submited, setSubmited ] = useState(false);
  const [ disableSubmit, setDisableSubmit ] = useState(false);
  const [ isEmailTouched, setIsEmailTouched ] = useState(false);
  const [ isPasswordTouched, setIsPasswordTouched ] = useState(false);
  const [ logedIn, setLogedIn ] = useState(false);

  const validateEmail = (email) => {
    return emailRegex.test(email);
  };

  const handleBlur = (e) => {
    let name = e.target.name
    if(name == 'email'){
      setIsEmailTouched(true);
    }else if(name == 'password'){
      setIsPasswordTouched(true);
    }
  };

  const handleChange = (e) => {
    let name = e.target.name
    if(name == 'email'){
      setEmail(e.target.value);
    }else if(name == 'password'){
      setPassword(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmited(true);
    if (email !== '' && password !== '') {
      let data = {
        business_users: {
          password: password,
          email:email
        },
        "parent_user_id": switchAccountOwner(),
        "parent_user_business": switchAccountBusiness(),
        "subdomain": subdomain,
        "web_request": true
      }
      setDisableSubmit(true);
      axiosRequest(loginAPI, 'POST', data, 'data')
      .then(result => {
        if(result.status == 200){
          setCurrentUserToken(result?.user?.api_token)
          removeAddAccountCookies(subdomain)
          if(subdomain == 'console'){
            location.href = '/business_user#/console_home'
          }else{
            updateUserData(result?.user, '/my-requests')
          }
        }else{
          setAlert(true, 'danger', result.error)
          window.setTimeout(()=>{
            setAlert(false, '', '')
            if(result.error == 'No such domain found!'){
              let splited_host = location.host.split('.')
              let new_host = splited_host[splited_host.length -2] + '.' + splited_host[splited_host.length -1]
              location.href = `${location.protocol}//${new_host}/signin`;
            }
          }, 5000)
        }
        setDisableSubmit(false)
      })
    }
  };

  return (
    <Fragment>
      {logedIn &&
        <Redirect to={'/my-requests'} />
      }
      <div className="flex flex-col gap-4 w-full">
        <h1 className="text-2xl text-center">Sign In</h1>
        <div className="flex items-center justify-end grid grid-cols-1 md:grid-cols-4 items-start">
          <label className="block text-sm font-semibold text-black col-span-1 pb-2 md:pb-0">Email</label>
          <div className='sm:mt-0 col-span-1 md:col-span-3'>
            <input
              type="email"
              className="block w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 px-4"
              id="email"
              name="email"
              placeholder="Enter Email"
              value={email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {(submited || isEmailTouched) && email =='' &&
              <p className="text-xs text-red-500 mt-1">Please enter an email.</p>
            }
            {(submited || isEmailTouched) && email !=='' && !validateEmail(email) &&
              <p className="text-xs text-red-500 mt-1">Invalid Email.</p>
            }
          </div>
        </div>
        <div className="flex items-center justify-end grid grid-cols-1 md:grid-cols-4 items-start">
          <label className="block text-sm font-semibold text-black col-span-1 pb-2 md:pb-0">Password</label>
          <div className='sm:mt-0 col-span-1 md:col-span-3 relative'>
            <input
              type={showPassword ? 'text' : 'password'}
              className="block w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 px-4 pr-8"
              id="password"
              name="password"
              placeholder="Enter Password"
              value={password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <EyeIcons open={showPassword} classNames={'w-5 h-5 absolute right-3 top-2'} setOpen={setShowPassword}/>
            {(submited || isPasswordTouched) && password == '' &&
              <span className="text-xs text-red-500 mt-1">Please enter a Password.</span>
            }
          </div>
        </div>
        <div className="flex items-center justify-end grid grid-cols-1 md:grid-cols-4 items-start mt-2">
          <div className="hidden md:flex"></div>
          <div className='sm:mt-0 col-span-1 md:col-span-3 gap-2 flex items-center flex-wrap'>
            <button
              className={`bg-gray-900 rounded-xl text-white flex items-center justify-center px-8 py-2 text-sm w-full md:w-1/2 ${(disableSubmit || (email =='' || (email !=='' && !validateEmail(email))) || password == '') ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={(e) => handleSubmit(e)}
              disabled={(disableSubmit || (email =='' || (email !=='' && !validateEmail(email))) || password == '')}
            >
              Sign In
            </button>
            <Link to={`/forget-password`} className='forget-password-link-a'>Forgot Password?</Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
}