import React, { useMemo } from 'react';

import { DefaultStreamChatGenerics, useMessageContext, isDate, useTranslationContext, StreamMessage } from 'stream-chat-react';
import { getDateString } from 'stream-chat-react/dist/i18n/utils';

const defaultTimestampFormat = 'h:mmA';

const UnMemoizedMessageTimestamp = (props) => {
  const {
    calendar = false,
    customClass = '',
    format = defaultTimestampFormat,
    message: propMessage,
  } = props;

  const { formatDate, message: contextMessage } = useMessageContext('MessageTimestamp');
  const { tDateTimeParser } = useTranslationContext('MessageTimestamp');

  const message = propMessage || contextMessage;

  const messageCreatedAt =
    message.created_at && isDate(message.created_at)
      ? message.created_at.toISOString()
      : message.created_at;

  const when = useMemo(
    () => getDateString({ calendar, format, formatDate, messageCreatedAt, tDateTimeParser }),
    [formatDate, calendar, tDateTimeParser, format, messageCreatedAt],
  );

  if (!when) return null;

  return (
    <time className={customClass} dateTime={messageCreatedAt} title={messageCreatedAt}>
      {when.includes(' at') ? when.replace(' at', '') : when}
    </time>
  );
};

export const MessageTimestamp = React.memo(
  UnMemoizedMessageTimestamp,
)