import React, { Fragment } from "react";
import { Link } from 'react-router-dom';

export default function TitleStatusComponent(props){
  const { eventData, selectedEventURL, handleLinkClick } = props

  return(
    <Fragment>
      <div className='sm:flex justify-between gap-x-5'>
        <div className={`${eventData?.status_content === 'Open' ? 'text-yellow-500' : (['Pending Approval', 'Pending Your Approval', 'Pending Manager Approval'].includes(eventData?.status_content) || eventData?.status_content.toLowerCase().includes('pending')) ? (eventData?.status == 'Open' ? 'text-yellow-500' : eventData?.status == 'Approved' ? 'approved-text-color' : 'pending-text-color') : eventData?.status_content.toLowerCase().includes('approved') ? 'approved-text-color' : ['Cancelled', 'Declined'].includes(eventData?.status_content) ? 'cancelled-text-color' : (['Response Needed (Re-opened)', 'Response Needed'].includes(eventData?.status_content) && ['Open', 'Approved'].includes(eventData?.status)) ? 'mgr-text-color' : 'text-yellow-500'} text-xs font-semibold sm:order-last whitespace-nowrap`}>{eventData?.status_content}</div>
        <Link to={selectedEventURL} className='text-base font-semibold mb-1 cursor-pointer' onClick={() => handleLinkClick()}>{`${eventData?.title}`}</Link>
      </div>
    </Fragment>
  )
}