import React, { Fragment, useEffect, useState } from 'react';
import { ClockIcon, LocationPinIcon, MessageIcon, ReOpenIcon, TashIcon, UserIcon, SendIcon, AngleIcon } from './../../../assets'
import AssignPopupContentComponent from './../assignPopupContentComponent'
import CreateCommentComponent from './../createCommentComponent'
import AlertPopup from './../../../../messenger/pages/chatComponents/assets/alertPopup'
import MgrEventButtonsComponent from './../mgrEventButtonsComponent'
import { axiosRequest, axiosGetRequest, eventDetailUrl, savePageViewInLocalStorage, handleClickOnLink } from './../../../helpers/helpers'
import ReOpenEventComponent from './../reOpenEventComponent'
import ComentsSectionComponent from './comentsSectionComponent';
import UsersListAccordionComponent from './usersListAccordionComponent';
import InstructorDetailComponent from './../cardViewComponents/cardComponent/instructorDetailComponent';
import InviteMoreComponent from './../../../../subRequestDetails/components/topButtonsComponent/moreActionComponent/inviteMoreComponent';
import CancelOptionsPopupComponent from '../../../../subRequestDetails/components/topButtonsComponent/moreActionComponent/cancelOptionsPopupComponent';
import CancelEventButtonsComponent from './cancelEventButtonsComponent';
import NoShowEventButtonsComponent from './noShowEventButtonsComponent';
import AcceptDeclineButtonsComponent from '../listViewComponents/acceptDeclineButtonsComponent';
import ReactTooltip from "react-tooltip";
import LocationComponent from './locationComponent';
import EventDateComponent from './eventDateComponent';
import SubbingUserComponent from './subbingUserComponent';
import RequestingInstructorComponent from './requestingInstructorComponent';
import TitleStatusComponent from './titleStatusComponent';
import EventMonthWeekDayComponent from './eventMonthWeekDayComponent';
import AddToCalendarButtonComponent from './../listViewComponents/addToCalendarButtonComponent';
import { Link } from 'react-router-dom';

export default function EventDetailsComponent(props){
  const { eventData, eventId, business, user, handleAlert, processFetchEventData, closeAndReloadData, handleUpdateEvent, path, myRequestsTab, dateToSave, handleUpdateCalendarEvent } = props
  const [ comments, setComments ] = useState(eventData?.comments)
  const [ commentsToShow, setCommentsToShow ] = useState(Array(eventData?.comments[0]))
  const [ showAllComments, setShowAllComents ] = useState(false)
  const [ showAssignPopup, setShowAssignPopup ] = useState(false)
  const [ showCommentAddPopup, setShowCommentAddPopup ] = useState(false)
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ alertType, setAlertType ] = useState('')
  const [ assignPopupType, setAssignPopupType ] = useState('')
  const [ approvedUserId, setApprovedUserId ] = useState('')
  const [ showReopenPopup, setShowReopenPopup ] = useState(false)
  const [ disableReopenBtn, setDisableReopenBtn ] = useState(false)
  const [ showCancelPopup, setShowCancelPopup ] = useState(false)
  const [ disableResendButton, setDisableResendButton ] = useState(false)
  const [ showInstructorDetail, setShowInstructorDetail ] = useState(false)
  const [ userToShowDetails, setUserToShowDetails ] = useState({})
  const [ confirmationMessage, setConfirmationMessage ] = useState('')
  const [ showInviteMorePopup, setShowInviteMorePopup ] = useState(false)
  const cancelOptions = ['rescind_class', 'delete_class', 'delete_without_sync', 'cancel_sub_request', 'crunch_event_delete', 'delete_class_duplicate', 'delete_class_no_longer', 'delete_class_other', 'delete_class_original_instructor']
  const [ optionListKeys, setOptionListKeys ] = useState(eventData?.more_action_detail?.map(item => item?.key) || [])
  const [ cancelOptionsOnly, setCancelOptionsOnly ] = useState(eventData?.more_action_detail?.filter(item => cancelOptions.includes(item?.key)) || [])
  const sentBucket = (['my-requests'].includes(path) && myRequestsTab == 'sent') ? myRequestsTab : ''
  const [ reopenEventMRG, setReopenEventMRG ] = useState(false)

  useEffect(() => {
    if(eventData){
      setOptionListKeys(eventData?.more_action_detail?.map(item => item?.key) || [])
      setCancelOptionsOnly(eventData?.more_action_detail?.filter(item => cancelOptions.includes(item?.key)) || [])
    }
  }, [eventData])

  const handleShowInstructorDetails = (user) => {
    setUserToShowDetails(user)
    setShowInstructorDetail(true)
  }

  const handleCloseInstructorDetails = () => {
    setShowInstructorDetail(false)
    setUserToShowDetails({})
  }

  useEffect(() => {
    if (showAllComments){
      setCommentsToShow(comments)
    }else{
      setCommentsToShow(comments.slice(0, 1))
    }
  }, [showAllComments])

  useEffect(() => {
    if(eventData){
      setComments(eventData?.comments)
      if (showAllComments){
        setCommentsToShow(eventData?.comments)
      }else{
        setCommentsToShow(Array(eventData?.comments[0]))
      }
    }
  }, [eventData])

  const handleShowComments = (data) => {
    setShowAllComents(data)
  }

  const handleShowAssignPopup = (type) => {
    setAssignPopupType(type)
    if(type == 'replace'){
      setApprovedUserId(eventData?.approved_user?.id)
    }
    setShowAssignPopup(true)
  }

  const handleAddComment = () => {
    setShowCommentAddPopup(true)
  }

  const closeAlert = (data) => {
    setShowAlert(data)
    setAlertMessage('')
    setAlertType('')
  }

  const handleSetAlert = (show, message, type) => {
    handleAlert(show, message, type)
  }

  const handleCloseAssignPopup = (data) => {
    setShowAssignPopup(data)
    setShowAssignPopup('')
    setApprovedUserId('')
  }

  const handleCloseAndReloadData = () => {
    closeAndReloadData()
  }

  const handleReopenEventClick = (e) => {
    setApprovedUserId(eventData?.approved_user?.id)
    setShowReopenPopup(true)
  }

  const handleCloseReopenPopup = (data) => {
    setShowReopenPopup(data)
    setApprovedUserId('')
  }

  const handleDirectReopen = (approvedUserId) => {
    setDisableReopenBtn(true)
    let params = {
      event_id: eventId,
      inst_id: approvedUserId,
      reason: '1',
      gql_event_details: true,
      preview: true,
      bucket_type: sentBucket,
      calendar_event: true
    }
    let url = '/api/v2/reopen_sub_request'
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if(!Object.keys(result).includes('error')){
        setDisableReopenBtn(false);
        handleAlert(true, result?.success, 'success');
        if(result?.invite_more_display){
          setConfirmationMessage(result?.invite_more_display_msg)
          if(result?.MRG){
            setReopenEventMRG(result?.MRG)
          }
          setShowInviteMorePopup(true)
        }
        handleUpdateEvent(result?.event);
        handleUpdateCalendarEvent(result?.calendar_event, 'updated');
      }else{
        setDisableReopenBtn(false);
        handleAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleAlert(false, '', ''); 
      }, 3000)
    })
  }

  const handleShowCancelPopup = () => {
    setShowCancelPopup(true)
  }

  const closeCancelPopup = (data) => {
    setShowCancelPopup(data)
  }

  const handleUpdateEventData = (data) => {
    if(data == 'deleted'){
      closeAndReloadData()
    }else{
      handleUpdateEvent(data)
    }
  }

  const handleResendInvite = () => {
    setDisableResendButton(true)
    let url = `/api/v2/resend_invites?id=${eventId}&inst_id=&gql_event_details=true&bucket_type=${sentBucket}`
    axiosGetRequest(url).then((result) => {
      if(Object.keys(result).includes('success')){
        handleAlert(true, result?.success, 'success');
      }else{
        setDisableResendButton(false);
        handleAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleAlert(false, '', ''); 
        handleUpdateEvent(result?.event);
      }, 3000)
    })
  }

  const closeInviteMorePopup = (data) => {
    setConfirmationMessage('')
    setShowInviteMorePopup(data)
  }

  const handleLinkClick = () => {
    savePageViewInLocalStorage('calendar', path)
    handleClickOnLink(dateToSave)
  }

  const fetchInviteMorePopupData = (result) => {
    handleUpdateEvent(result?.event);
    setConfirmationMessage(result?.invite_more_display_msg)
    if(result?.MRG){
      setReopenEventMRG(result?.MRG)
    }
    setShowInviteMorePopup(true)
  }
  
  return(
    <Fragment>
      {showCancelPopup &&
        <CancelOptionsPopupComponent eventData={eventData} showCancelOptionsPopup={showCancelPopup} handleSetAlert={handleSetAlert} closeShowCancelOptionsPopup={closeCancelPopup} handleUpdateEvent={handleUpdateEventData} optionListKeys={optionListKeys} cancelOptionKeys={cancelOptions} backPath={''} type={'preview'} sentBucket={sentBucket} calendarEvent={true} handleUpdateCalendarEvent={handleUpdateCalendarEvent}/>
      }
      {showInviteMorePopup &&
        <InviteMoreComponent user={user} eventId={eventData?.id} confirmationMessage={confirmationMessage} closeInviteMorePopup={closeInviteMorePopup} showInviteMorePopup={showInviteMorePopup} handleUpdateEvent={handleUpdateEvent} handleSetAlert={handleSetAlert} type={'preview'} sentBucket={sentBucket} reopenEventMRG={reopenEventMRG}/>
      }
      {showInstructorDetail &&
        <InstructorDetailComponent showInstructorDetail={showInstructorDetail} detailInstructor={userToShowDetails} close={handleCloseInstructorDetails} handleAlert={handleAlert}/>
      }
      {showAssignPopup &&
        <AssignPopupContentComponent show={showAssignPopup} eventId={eventId} close={handleCloseAssignPopup} assignPopupType={assignPopupType} handleSetAlert={handleSetAlert} approvedUserId={approvedUserId} processFetchEventData={handleUpdateEvent} type={'preview'} calendarEvent={true} handleUpdateCalendarEvent={handleUpdateCalendarEvent}/>
      }
      {showReopenPopup &&
        <ReOpenEventComponent showReopenPopup={showReopenPopup} closeReopenPopup={handleCloseReopenPopup} user={user} eventId={eventId} approvedUserId={approvedUserId} handleSetAlert={handleSetAlert} processFetchEventData={handleUpdateEvent} type={'preview'} sentBucket={sentBucket} calendarEvent={true} handleUpdateCalendarEvent={handleUpdateCalendarEvent} fetchInviteMorePopupData={fetchInviteMorePopupData}/>
      }
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert} />
      }
      {showCommentAddPopup &&
        <CreateCommentComponent eventId={eventId} show={showCommentAddPopup} close={() => setShowCommentAddPopup(false)} user={user} handleSetAlert={handleSetAlert} processFetchEventData={handleUpdateEvent} sentBucket={sentBucket}/>
      }
      <div className='sm:flex rounded-md sm:pt-8 w-full p-4'>
        <EventMonthWeekDayComponent eventData={eventData}/>
        <div className='w-full'>
          <div className='relative'>
            <div>
              <Link to={eventDetailUrl(eventData, path)} className=' text-base font-semibold cursor-pointer absolute right-0 inset-y-1/2' onClick={() => handleLinkClick()}>
                <AngleIcon classNames={'-rotate-90 w-7 transform'}/>
              </Link>  
            </div>
            <TitleStatusComponent eventData={eventData} selectedEventURL={eventDetailUrl(eventData, path)} handleLinkClick={handleLinkClick}/>
            <RequestingInstructorComponent eventData={eventData} handleShowInstructorDetails={handleShowInstructorDetails} type={'preview'} user={user} bucketType={''} path={path}/>
            {(eventData?.status_content.toLowerCase().includes('approved') && Object.keys(eventData?.approved_user).length > 0) &&
              <SubbingUserComponent eventData={eventData} handleShowInstructorDetails={handleShowInstructorDetails} type={'preview'} path={path} bucketType={''}/>
            }
            <EventDateComponent eventData={eventData} user={user}/>
            <LocationComponent eventData={eventData}/>
          </div>
          <div className='flex gap-3 mt-3'>
            {(['my-requests'].includes(path) && myRequestsTab == 'approved') &&
              <AddToCalendarButtonComponent event={eventData} type={'preview'}/>
            }
            {(['my-requests'].includes(path) && eventData?.show_accept_decline_button && myRequestsTab == 'available') &&
              <AcceptDeclineButtonsComponent 
                event={eventData}
                business={business}
                handleAlert={handleAlert} 
                handleUpdateEvent={handleUpdateEvent}
                type={'preview'}
                calendarEvent={true} 
                handleUpdateCalendarEvent={handleUpdateCalendarEvent}
              />
            }
            {eventData?.show_manger_request_buttons &&
              <Fragment>
                <MgrEventButtonsComponent user={user} eventId={eventId} handleSetAlert={handleSetAlert} handleCloseAndReloadData={handleUpdateEventData} type={'preview'} calendarEvent={true} handleUpdateCalendarEvent={handleUpdateCalendarEvent}/>
              </Fragment>
            }
            {!(['my-requests'].includes(path) && myRequestsTab == 'available') &&
            <Fragment>
              {!(['my-requests'].includes(path) && myRequestsTab == 'approved') &&
                <Fragment>
                  {(eventData?.show_assign_button) &&
                    <button className='w-full text-xs font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5' onClick={() => handleShowAssignPopup(eventData?.status_content.toLowerCase().includes('approved') ? 'replace' : 'assign')}>
                      <UserIcon classNames={'w-4 h-4'}/>
                      Assign
                    </button>
                  }
                </Fragment>
              }
              {eventData?.show_reopen_button &&
                <button className={`w-full text-xs font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5 ${disableReopenBtn ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={(e) => handleReopenEventClick(e)} disabled={disableReopenBtn}>
                  <ReOpenIcon classNames={'w-4 h-4'}/>
                  Reopen
                </button>
              }
              {!['Open', 'Re-Opened'].includes(eventData?.status_content) && eventData?.show_resend_invite_button &&
                <Fragment>
                  <button className={`w-full text-xs font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5 ${((eventData?.pending_subs_count == 0 || (eventData?.pending_subs_count > 0 && eventData?.resent_invite)) || disableResendButton) ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={(eventData?.pending_subs_count == 0 || (eventData?.pending_subs_count > 0 && eventData?.resent_invite)) || disableResendButton} onClick={() => handleResendInvite()}>
                    <SendIcon classNames={'w-4 h-4 transform -rotate-45 mb-0.5 ml-0.5'}/>
                    Resend
                  </button>
                </Fragment>
              }
            </Fragment>
            }
            {business?.comments_feature &&
              <Fragment>
                <button className='w-full text-xs font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5' onClick={() => handleAddComment()}>
                  <MessageIcon classNames={'w-4 h-4'}/>
                  Comment
                </button>
              </Fragment>
            }
            {(eventData?.status_content == 'Cancelled' && eventData?.cancelled) &&
              <CancelEventButtonsComponent eventData={eventData} business={business} cancelOptions={cancelOptions} optionListKeys={optionListKeys} handleSetAlert={handleSetAlert} handleUpdateEvent={handleUpdateEvent} handleAddComment={handleAddComment} handleShowCancelPopup={handleShowCancelPopup} calendarEvent={true} handleUpdateCalendarEvent={handleUpdateCalendarEvent}/>
            }
            {(eventData?.status == 'No Show' && eventData?.no_show) &&
              <NoShowEventButtonsComponent eventData={eventData} business={business} cancelOptions={cancelOptions} optionListKeys={optionListKeys} handleSetAlert={handleSetAlert} handleUpdateEvent={handleUpdateEvent} handleAddComment={handleAddComment} handleShowCancelPopup={handleShowCancelPopup} handleShowAssignPopup={handleShowAssignPopup} calendarEvent={true} handleUpdateCalendarEvent={handleUpdateCalendarEvent}/>
            }
            {!(['my-requests'].includes(path) && myRequestsTab == 'available') &&
              <Fragment>
                {(cancelOptionsOnly?.length > 0 && (((['my-requests'].includes(path) && myRequestsTab == 'sent') && eventData?.show_cancel_button) || (!(['my-requests'].includes(path) && myRequestsTab == 'sent') && (eventData?.show_cancel_button || eventData?.cancelled || eventData?.no_show)))) &&
                  <Fragment>
                    {((eventData?.show_assign_button && (!['Open', 'Re-Opened'].includes(eventData?.status_content) && eventData?.show_resend_invite_button) && business?.comments_feature) ||
                      (eventData?.show_assign_button && eventData?.show_reopen_button && business?.comments_feature) || 
                      ((eventData?.show_assign_button && !(['my-requests'].includes(path) && myRequestsTab == 'approved')) && (eventData?.status == 'No Show' && eventData?.no_show && optionListKeys.includes('undo_no_show_sub_request'))) || 
                      ((['my-requests'].includes(path) && myRequestsTab == 'approved') && !eventData?.past) ||
                      (eventData?.show_manger_request_buttons)
                      ) ?
                      <Fragment>
                        <button className={`flex justify-center items-center border border-gray-400 rounded-full min-h-8 min-w-8`} onClick={() => handleShowCancelPopup()} data-tip data-for={`cancel_button_`+eventData?.id.toString()}>
                          <TashIcon classNames={'text-black w-4 h-4'}/>
                          <ReactTooltip className='max-w-xs whitespace-nowrap' id={`cancel_button_`+eventData?.id.toString()} place="top" effect="solid">
                            Cancel
                          </ReactTooltip>
                        </button>
                      </Fragment>
                      :
                      <Fragment>
                        <button className='w-full text-xs font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5' onClick={() => handleShowCancelPopup()}>
                          <TashIcon classNames={'text-black w-4 h-4'}/>
                          Cancel
                        </button>
                      </Fragment>
                    }
                  </Fragment>
                }
              </Fragment>
            }
          </div>
          {(((['Pending Approval', 'Pending Your Approval', 'Pending Manager Approval', 'Open', 'Re-Opened'].includes(eventData?.status_content) || eventData?.status_content.toLowerCase().includes('pending')) && eventData?.show_invites_section) || eventData?.show_manger_request_buttons) &&
            <UsersListAccordionComponent eventData={eventData} eventId={eventId} handleAlert={handleAlert} handleCloseAndReloadData={handleCloseAndReloadData} handleUpdateEvent={handleUpdateEvent} user={user} calendarEvent={true} handleUpdateCalendarEvent={handleUpdateCalendarEvent}/>
          }
          {comments?.length > 0 && 
            <ComentsSectionComponent 
              eventData={eventData}
              comments={comments} 
              commentsToShow={commentsToShow} 
              showAllComments={showAllComments} 
              handleShowComments={handleShowComments}
              handleAlert={handleAlert}
              handleUpdateEvent={handleUpdateEvent}
            />
          }
        </div>
      </div>
    </Fragment>
  )
}