import React from 'react';
import { BellIcon, CrossBellIcon } from './bellIcon';
import { AudienceIcon } from './audienceIcon';
import { RightArrowIcon } from './rightArrowIcon';

export const CustomDropDownButton = ({name, value, open, onClickOnButton, classNames, extraValue}) => {
  return(
    <div className={`relative inline-block text-left min-w-240 ${classNames}`}>
      <button type="button" className="inline-flex justify-between w-full rounded-2xl border-2 border-gray-200 px-3 py-3 bg-white text-sm font-medium text-black hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 items-center" id="menu-button" aria-expanded="true" aria-haspopup="true" onClick={() => onClickOnButton(!open)}>
        <div className="flex gap-1 items-center font-semibold">
          {name == 'Notifications' &&
            (value === "ON" ?
              <BellIcon classNames={'h-5 w-5'}/>
            :
              <CrossBellIcon classNames={'h-5 w-5'}/>
            )
          }
          {name == 'Audience' &&
            <AudienceIcon classNames={'h-5 w-5'}/>
          }
          {name}
        </div>
        <div className="flex gap-1 items-center font-semibold ml-5">
          {value}
          {name == 'Notifications' && extraValue !== '' &&
            <div className="items-center text-sm font-semibold w-44 text-gray-400">
             {extraValue}
            </div>
          }
          <RightArrowIcon classNames={'h-5 w-5'}/>
        </div>
      </button>
    </div>	
  )
} 