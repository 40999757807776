import React, { Fragment, useState, useEffect } from "react";
import ReopenConfirmationComponent from './reOpenConfirmationComponents';
import AlertPopup from './../../../../../messenger/pages/chatComponents/assets/alertPopup';
import InviteMoreComponent from './../../../../../subRequestDetails/components/topButtonsComponent/moreActionComponent/inviteMoreComponent';
import { axiosRequest } from './../../../../../subRequests/helpers/helpers';
import { checkInstructorDisableRows, checkInstructorEligibalClasses } from './../../../../helpers';
import ManagerTableRecordComponent from './managerTableRecordComponent';
import InstructorTableRecordComponent from './instructorTableRecordComponent';
import { ChevronUpIcon } from '@heroicons/react/outline'
import { LoadingCircleIcon } from './../../../../assets';
import ReactTooltip from "react-tooltip";

export default function TableComponent(props) {
  const { user, schedules, selectedSchedules, setSelectedSchedules, netgymSchedules, newSchedules, newSubRequest, netgymSchedulesEventIds, fetchSchedules, instructorDaysInAdvanceCheckDate, setNewSubRequest, business, fetchingPastSchedules, getPastSchedules } = props
  const [ eligibleScheduleIds, setEligibleScheduleIds ] = useState([])
  const [ showReopenConfirmationPopup, setShowReopenConfirmationPopup ] =useState(false)
  const [ disableReopenButton, setDisableReopenButton ] = useState(false)
  const [ reopenEventId, setReopenEventId ] = useState('')
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertType, setAlertType ] = useState('')
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ confirmationMessage, setConfirmationMessage ] = useState('')
  const [ showInviteMorePopup, setShowInviteMorePopup ] = useState(false)
  const [ reopenEventMRG, setReopenEventMRG ] = useState(false)

  useEffect(() => {
    let subPolicyEligibleSchedules = checkInstructorEligibalClasses(schedules, instructorDaysInAdvanceCheckDate, netgymSchedules, user)
    setEligibleScheduleIds(schedules?.filter((schedule) => (!netgymSchedules?.includes(schedule?.class_id) && (subPolicyEligibleSchedules.includes(schedule?.class_id)) ))?.map((schedule) => schedule?.class_id))
  }, [schedules])

  const handleSelectSchedules = (e, schedule) => {
    if(e.target.checked) {
      setSelectedSchedules([...selectedSchedules, schedule?.class_id])
      setNewSubRequest(prevNewSubRequest => ({...prevNewSubRequest, schedules: [...prevNewSubRequest.schedules, schedule]}))
    }else{
      setSelectedSchedules(selectedSchedules.filter((s) => s !== schedule.class_id))
      setNewSubRequest(prevNewSubRequest => ({...prevNewSubRequest, schedules: prevNewSubRequest.schedules.filter((s) => s.class_id !== schedule.class_id)}))
    }
  }

  const handleSelectAllSchedules = (e) => {
    if(e.target.checked) {
      let allSheduleIds = Array.from(new Set([...selectedSchedules, ...eligibleScheduleIds?.map((schedule) => schedule)]))
      setSelectedSchedules(allSheduleIds)
      let allSchedules = schedules.filter((schedule) => allSheduleIds.includes(schedule?.class_id) )
      setNewSubRequest(prevNewSubRequest => ({...prevNewSubRequest, schedules: allSchedules}))
    }else{
      setSelectedSchedules([])
      setNewSubRequest(prevNewSubRequest => ({...prevNewSubRequest, schedules: []}))
    }
  }

  const checkAllSelected = () => {
    return(eligibleScheduleIds.every(v => selectedSchedules.includes(v)) && eligibleScheduleIds.length > 0)
  }

  const eventDetailPath = (eventId) => {
    return(`/sub-request-details/${eventId}`)
  }

  const handleClickReopen = (e, eventId, is_invite_more_case) => {
    setReopenEventId(eventId)
    if(is_invite_more_case){
      handleConfirmReopen(eventId)
    }else{
      setShowReopenConfirmationPopup(true)
    }
  }

  const handleCloseReopenConfirmationPopup = (data) => {
    setShowReopenConfirmationPopup(data)
  }

  const closeAlert = (data) => {
    setShowAlert(data)
    setAlertType('')
    setAlertMessage('')
  }

  const handleSetAlert = (show, message, type) => {
    setAlertType(type)
    setAlertMessage(message)
    setShowAlert(show)
  }

  const handleConfirmReopen = (eventId) => {
		if(newSubRequest?.requestingInstructor?.id == ''){
			setShowReopenConfirmationPopup(false)
		}
		let url = "/api/v2/reopen_sub_request"
		let data = {
			event_id: eventId,
			inst_id: newSubRequest?.requestingInstructor?.id
		}
		axiosRequest(url, "PATCH", data, 'data').then((result) => {
			if(result?.status == 200){
				handleSetAlert(true, result?.success, 'success')
        setShowReopenConfirmationPopup(false)
        if(result?.invite_more_display){
          setConfirmationMessage(result?.invite_more_display_msg)
          if(result?.MRG){
            setReopenEventMRG(result?.MRG)
          }
          setShowInviteMorePopup(true)
        }else{
          fetchSchedules()
        }
			}else{
				handleSetAlert(true, result?.error, 'danger')
        setShowReopenConfirmationPopup(false)
			}
			window.setTimeout(() => {
				handleSetAlert(false, '', '')
			}, 5000)
		})
	}

  const closeInviteMorePopup = (data) => {
    setShowInviteMorePopup(data)
    fetchSchedules()
  }

  const handleUpdateEvents = () => {
    fetchSchedules()
  }

  return(
    <Fragment>
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert} />
      }
      {showReopenConfirmationPopup &&
        <ReopenConfirmationComponent user={user} showReopenConfirmationPopup={showReopenConfirmationPopup} closeReopenConfirmationPopup={handleCloseReopenConfirmationPopup} handleConfirmReopen={handleConfirmReopen} reopenEventId={reopenEventId}/>
      }
      {showInviteMorePopup &&
        <InviteMoreComponent user={user} eventId={reopenEventId} confirmationMessage={confirmationMessage} closeInviteMorePopup={closeInviteMorePopup} showInviteMorePopup={showInviteMorePopup} handleUpdateEvent={() => handleUpdateEvents} handleSetAlert={handleSetAlert} type={'preview'} sentBucket={''} reopenEventMRG={reopenEventMRG}/>
      }
      <div className="max-hc-365 overflow-auto border border-gray-300 rounded-2xl">
        <table className='min-w-full divide-y divide-gray-300'>
          <thead className='bg-gray-50'>
            <tr>
              <th className="px-3 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider">
                <div>
                  <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={checkAllSelected() == true ? true : false} value={'all'} onChange={(e) => handleSelectAllSchedules(e)}/>
                </div>
              </th>
              <th className={`px-3 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider`}>
                <div className='flex items-center'>
                  <span className=''>Date</span> &nbsp; &nbsp;
                </div>
              </th>
              <th className={`px-3 py-3 text-left text-xs font-medium text-neutral-500 tracking-wider`}>
                <div className='flex items-center'>
                  <span className='uppercase'>Time</span> &nbsp; &nbsp;
                  <div className="h-5 w-5 flex items-center justify-center">
                    {((!business?.crunch_integration && user?.role_display !== 'Fitness Instructor') && !newSubRequest?.previousWeek) &&
                      <Fragment>
                        {fetchingPastSchedules ?
                          <LoadingCircleIcon classNames={"w-4 h-4 text-black"}/>
                        :
                          <Fragment>
                            <button className="w-5 h-5 rounded-full border border-gray-400 flex items-center justify-center hover:bg-gray-200" onClick={() => getPastSchedules()} data-tip data-for={`previous_7_day_schedules_tooltip`}><ChevronUpIcon className="text-gray-900 h-3.5 w-3.5" aria-hidden="true" /></button>
                            <ReactTooltip className='max-w-xs' id={`previous_7_day_schedules_tooltip`} place="top" effect="solid">
                              Load previous 7 days of classes
                            </ReactTooltip>
                          </Fragment>
                        }
                      </Fragment>
                    }
                  </div>
                </div>
              </th>
              <th className={`px-3 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider`}>
                <div className='flex items-center'>
                  <span className=''>Class</span> &nbsp; &nbsp;
                </div>
              </th>
              <th className={`px-3 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider`}>
                <div className='flex items-center'>
                  <span className=''>Duration</span> &nbsp; &nbsp;
                </div>
              </th>
              {!business?.abc_integration &&
                <th className={`px-3 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider`}>
                  <div className='flex items-center'>
                    <span className=''>Location</span> &nbsp; &nbsp;
                  </div>
                </th>
              }
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-300'>
            {(user?.role_display !== 'Fitness Instructor') ?
              <ManagerTableRecordComponent schedules={schedules} netgymSchedules={netgymSchedules} newSchedules={newSchedules} newSubRequest={newSubRequest} selectedSchedules={selectedSchedules} handleSelectSchedules={handleSelectSchedules} handleClickReopen={handleClickReopen} netgymSchedulesEventIds={netgymSchedulesEventIds} eventDetailPath={eventDetailPath} business={business}/>
            :
              <InstructorTableRecordComponent schedules={schedules} instructorDaysInAdvanceCheckDate={instructorDaysInAdvanceCheckDate} netgymSchedules={netgymSchedules} user={user} newSchedules={newSchedules} newSubRequest={newSubRequest} selectedSchedules={selectedSchedules} handleSelectSchedules={handleSelectSchedules} handleClickReopen={handleClickReopen} netgymSchedulesEventIds={netgymSchedulesEventIds} eventDetailPath={eventDetailPath} business={business}/>
            }
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}