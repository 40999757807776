import React, { Fragment, useState } from "react";
import ReactTooltip from "react-tooltip";
import ReOpenIcon from './../../../../assets/reOpenIcon';
import { axiosRequest } from './../../../../helpers/helpers';
import ReOpenEventComponent from './../../reOpenEventComponent';
import InviteMoreComponent from './../../../../../subRequestDetails/components/topButtonsComponent/moreActionComponent/inviteMoreComponent';

export default function ReOpenButtonComponent(props) {
  const { event, handleAlert, handleCloseAndReloadData, user, sentBucket } = props
  const [ approvedUserId, setApprovedUserId ] = useState('')
  const [ showReopenPopup, setShowReopenPopup ] = useState(false)
  const [ disableReopenBtn, setDisableReopenBtn ] = useState(false)
  const [ confirmationMessage, setConfirmationMessage ] = useState('')
  const [ showInviteMorePopup, setShowInviteMorePopup ] = useState(false)
  const [ eventUpdateData, setEventUpdateData ] = useState(event)
  const [ reopenEventMRG, setReopenEventMRG ] = useState(false)

  const handleReopenEventClick = (e) => {
    if(!event?.is_invite_more_case){
      setApprovedUserId(event?.approved_user?.id)
      setShowReopenPopup(true)
    }else{
      handleDirectReopen(event?.approved_user?.id)
    }
  }

  const handleCloseReopenPopup = (data) => {
    setShowReopenPopup(data)
    setApprovedUserId('')
  }

  const handleDirectReopen = (approvedUserId) => {
    setDisableReopenBtn(true)
    let params = {
      event_id: event?.id,
      inst_id: approvedUserId,
      reason: '1',
      gql_event_details: true,
      preview: true,
      bucket_type: sentBucket
    }
    let url = '/api/v2/reopen_sub_request'
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if(!Object.keys(result).includes('error')){
        setDisableReopenBtn(false);
        handleAlert(true, result?.success, 'success');
        setEventUpdateData(result?.event)
        if(result?.invite_more_display){
          setConfirmationMessage(result?.invite_more_display_msg)
          if(result?.MRG){
            setReopenEventMRG(result?.MRG)
          }
          setShowInviteMorePopup(true)
        }else{
          handleCloseAndReloadData(result?.event);
        }
      }else{
        setDisableReopenBtn(false);
        handleAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleAlert(false, '', ''); 
        
      }, 3000)
    })
  }

  const closeInviteMorePopup = (data) => {
    setConfirmationMessage('')
    setShowInviteMorePopup(data)
    handleCloseAndReloadData(eventUpdateData);
  }

  const handleUpdateEventData = (data) => {
    setEventUpdateData(data)
    handleCloseAndReloadData(data);
  }

  const fetchInviteMorePopupData = (result) => {
    setEventUpdateData(result?.event)
    setConfirmationMessage(result?.invite_more_display_msg)
    if(result?.MRG){
      setReopenEventMRG(result?.MRG)
    }
    setShowInviteMorePopup(true)
  }

  return(
    <Fragment>
      {showInviteMorePopup &&
        <InviteMoreComponent user={user} eventId={event?.id} confirmationMessage={confirmationMessage} closeInviteMorePopup={closeInviteMorePopup} showInviteMorePopup={showInviteMorePopup} handleUpdateEvent={handleUpdateEventData} handleSetAlert={handleAlert} type={'preview'} sentBucket={sentBucket} reopenEventMRG={reopenEventMRG}/>
      }
      {showReopenPopup &&
        <ReOpenEventComponent showReopenPopup={showReopenPopup} closeReopenPopup={handleCloseReopenPopup} user={user} eventId={event?.id} approvedUserId={approvedUserId} handleSetAlert={handleAlert} processFetchEventData={handleCloseAndReloadData} type={'preview'} sentBucket={sentBucket} calendarEvent={false} handleUpdateCalendarEvent={() => {}} fetchInviteMorePopupData={fetchInviteMorePopupData}/>
      }
      <button className={`flex relative z-20 justify-center items-center border border-gray-300 rounded-full min-h-8 min-w-8 ${disableReopenBtn ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={(e) => handleReopenEventClick(e)} data-tip data-for={`reopen_button_`+event?.id.toString()} disabled={disableReopenBtn}>
        <ReOpenIcon classNames={'text-black wc-14 hc-14'}/>
        <ReactTooltip className='max-w-xs whitespace-nowrap' id={`reopen_button_`+event?.id.toString()} place="top" effect="solid">
          Reopen
        </ReactTooltip>
      </button>
    </Fragment>
  )
}