import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { hideItems, axiosRequest, getName, integratedBusiness } from '../../../../subRequests/helpers/helpers';
import { UserIcon, ReOpenIcon } from '../../../../subRequests/assets';
import { TrashIcon } from "../../../assets";
import CancelConfirmationPopupComponent from '../../../../subRequests/pages/components/cancelConfirmationPopupComponent';
import AssignPopupContentComponent from './../../../../subRequests/pages/components/assignPopupContentComponent';
import ReOpenEventComponent from '../../../../subRequests/pages/components/reOpenEventComponent';
import InviteMoreComponent from './inviteMoreComponent';
import NoShowConfirmationComponent from './noShowConfirmationComponent';
import ApproveWithoutSyncComponent from './approveWithoutSyncComponent';
import ReactTooltip from "react-tooltip";
import CancelOptionsPopupComponent from './cancelOptionsPopupComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserAlt, faRefresh, faCalendar, faUndo, faUserSlash, faFlag, faPencil } from '@fortawesome/free-solid-svg-icons'
library.add( faUserAlt, faRefresh, faCalendar, faUndo, faUserSlash, faFlag, faPencil )

export default function MoreActionComponent(props){
  const { user, eventData, handleSetAlert, handleUpdateEvent, business, backPath } = props
  const cancelOptions = ['rescind_class', 'delete_class', 'delete_without_sync', 'cancel_sub_request', 'crunch_event_delete', 'delete_class_duplicate', 'delete_class_no_longer', 'delete_class_other', 'delete_class_original_instructor']
  const [ optionListKeys, setOptionListKeys ] = useState(eventData?.more_action_detail?.map(item => item?.key) || [])
  const [ moreActionOptions , setMoreActionOptions ] = useState(eventData?.more_action_detail?.filter((item) => !cancelOptions.includes(item?.key)) || [])
  const [ showCancelPopup, setShowCancelPopup ] = useState(false)
  const [ showAssignPopup, setShowAssignPopup ] = useState(false)
  const [ assignPopupType, setAssignPopupType ] = useState('')
  const [ approvedUserId, setApprovedUserId ] = useState('')
  const [ confirmationMessage, setConfirmationMessage ] = useState('')
  const [ showReopenPopup, setShowReopenPopup ] = useState(false)
  const [ showInviteMorePopup, setShowInviteMorePopup ] = useState(false)
  const [ showNoShowPopup, setShowNoShowPopup ] = useState(false)
  const [ showApproveWithoutSyncPopup, setShowApproveWithoutSyncPopup ] = useState(false)
  const [ actionButtonDisabled, setActionButtonDisabled ] = useState(false)
  const [ showCancelOptionsPopup, setShowCancelOptionsPopup ] = useState(false)
  const actionUrl = `/api/v3/business_events/${eventData?.id}/perform_more_actions`
  const [ reopenEventMRG, setReopenEventMRG ] = useState(false)
  const [ eventUpdateData, setEventUpdateData ] = useState(eventData)

  useEffect(() => {
    if(eventData){
      setOptionListKeys(eventData?.more_action_detail?.map(item => item?.key) || [])
      setMoreActionOptions(eventData?.more_action_detail?.filter((item) => !cancelOptions.includes(item?.key)) || [])
    }
  }, [eventData])

  const handleMoreAction = (action) => {
    hideItems('more_action')
    if(action?.is_disabled) return

    if(['undo_cancel', 'cancel_sub_request'].includes(action?.key)){
      setShowCancelPopup(true)
    }else if(action?.key === 'assign_sub'){
      setAssignPopupType('assign')
      setShowAssignPopup(true)
    }else if(action?.key === 'replace_class'){
      setAssignPopupType('replace')
      setApprovedUserId(eventData?.approved_user?.id)
      setShowAssignPopup(true)
    }else if(action?.key === 'reopen_class'){
      handleReopenEventClick()
    }else if(action?.key === 'add_to_calendar'){
      window.open(eventData?.google_cal_link, '_blank');
    }else if(['undo_no_show_sub_request', 'no_show_sub_request'].includes(action?.key)){
      setAssignPopupType(action?.key)
      setShowNoShowPopup(true)
    }else if(action?.key === 'approve_without_sync'){
      setApprovedUserId(action?.user_id)
      setConfirmationMessage(action?.message)
      setShowApproveWithoutSyncPopup(true)
    }else if(action?.key === 'send_to_manager'){
      handleSendToManager()
    }
  }

  const handleShowCancelOptionsPopup = () => {
    hideItems('more_action')
    setShowCancelOptionsPopup(true)
  }

  const closeCancelPopup = (data) => {
    setShowCancelPopup(data)
    setShowAssignPopup(data)
    setAssignPopupType('')
    setApprovedUserId('')
    setShowReopenPopup(data)
    setShowNoShowPopup(data)
    setShowApproveWithoutSyncPopup(data)
  }

  const handleReopenEventClick = () => {
    setApprovedUserId(eventData?.approved_user?.id)
    setShowReopenPopup(true)
  }

  const handleDirectReopen = (approvedUserId) => {
    let params = {
      key: 'reopen_class',
      inst_id: approvedUserId,
      reason: '1',
      preview: false
    }
    let url = actionUrl
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if(result?.status === 200){
        handleSetAlert(true, result?.success, 'success');
        if(result?.invite_more_display){
          setConfirmationMessage(result?.invite_more_display_msg)
          if(result?.MRG){
            setReopenEventMRG(result?.MRG)
          }
          setShowInviteMorePopup(true)
        }
      }else{
        handleSetAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleSetAlert(false, '', ''); 
        if(result?.event && Object.keys(result?.event).length > 0) handleUpdateEvent(result?.event);
      }, 3000)
    })
  }

  const handleSendToManager = () => {
    if(actionButtonDisabled) return
    let url = actionUrl
    let params = {
      key: 'send_to_manager',
      preview: false
    }
    setActionButtonDisabled(true)
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if(Object.keys(result).includes('status') && result?.status === 200){
        handleSetAlert(true, result?.success, 'success');
        setActionButtonDisabled(false)
        handleUpdateEvent(result?.event)
      }else{
        setActionButtonDisabled(false)
        handleSetAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleSetAlert(false, '', ''); 
      }, 3000)
    })
  }

  const closeShowCancelOptionsPopup = (data) => {
    setShowCancelOptionsPopup(data)
  }

  const showCancelOption = (all_options, keys) => {
    return keys.some(r=> all_options.includes(r))
  }
  
  const handleEditOption = () => {
    let userRole = getName(user?.role)
    window.location.href = `/business_user#/${userRole}/edit_class?id=${eventData?.id}`
  }

  const fetchInviteMorePopupData = (result) => {
    setEventUpdateData(result?.event)
    setConfirmationMessage(result?.invite_more_display_msg)
    if(result?.MRG){
      setReopenEventMRG(result?.MRG)
    }
    setShowInviteMorePopup(true)
  }

  const closeInviteMorePopup = (data) => {
    setConfirmationMessage('')
    setShowInviteMorePopup(data)
    handleUpdateEvent(eventUpdateData)
  }

  return(
    <Fragment>
      {showCancelOptionsPopup && 
        <CancelOptionsPopupComponent eventData={eventData} showCancelOptionsPopup={showCancelOptionsPopup} handleSetAlert={handleSetAlert} closeShowCancelOptionsPopup={closeShowCancelOptionsPopup} handleUpdateEvent={handleUpdateEvent} optionListKeys={optionListKeys} cancelOptionKeys={cancelOptions} backPath={backPath} type={'detail'} sentBucket={''} calendarEvent={false} handleUpdateCalendarEvent={() => {}}/>
      }
      {showApproveWithoutSyncPopup &&
        <ApproveWithoutSyncComponent eventData={eventData} handleSetAlert={handleSetAlert} closeApproveWithoutSyncPopup={closeCancelPopup} handleUpdateEvent={handleUpdateEvent} showApproveWithoutSyncPopup={showApproveWithoutSyncPopup} confirmationMessage={confirmationMessage} approvedUserId={approvedUserId} actionUrl={actionUrl}/>
      }
      {showNoShowPopup &&
        <NoShowConfirmationComponent eventData={eventData} handleSetAlert={handleSetAlert} closeNoShowPopup={closeCancelPopup} handleUpdateEvent={handleUpdateEvent} showNoShowPopup={showNoShowPopup} noShowPopupType={assignPopupType} type={'detail'} calendarEvent={false} handleUpdateCalendarEvent={() => {}}/> 
      }
      {showInviteMorePopup &&
        <InviteMoreComponent user={user} eventId={eventData?.id} confirmationMessage={confirmationMessage} closeInviteMorePopup={closeInviteMorePopup} showInviteMorePopup={showInviteMorePopup} handleUpdateEvent={handleUpdateEvent} handleSetAlert={handleSetAlert} type={'detail'} sentBucket={''} reopenEventMRG={reopenEventMRG}/>
      }
      {showReopenPopup &&
        <ReOpenEventComponent showReopenPopup={showReopenPopup} closeReopenPopup={closeCancelPopup} user={user} eventId={eventData?.id} approvedUserId={approvedUserId} handleSetAlert={handleSetAlert} processFetchEventData={handleUpdateEvent} type={'detail'} sentBucket={''} calendarEvent={false} handleUpdateCalendarEvent={() => {}} fetchInviteMorePopupData={fetchInviteMorePopupData}/>
      }
      {showAssignPopup &&
        <AssignPopupContentComponent show={showAssignPopup} eventId={eventData?.id} close={closeCancelPopup} assignPopupType={assignPopupType} handleSetAlert={handleSetAlert} approvedUserId={approvedUserId} processFetchEventData={handleUpdateEvent} type={'detail'} calendarEvent={false} handleUpdateCalendarEvent={() => {}}/>
      }
      {showCancelPopup &&
        <CancelConfirmationPopupComponent eventId={eventData?.id} showCancelPopup={showCancelPopup} closeCancelPopup={closeCancelPopup} handleSetAlert={handleSetAlert} handleCloseAndReloadData={handleUpdateEvent} type={'detail'} eventData={eventData} calendarEvent={false} handleUpdateCalendarEvent={() => {}}/> 
      }
      <div className="relative filters_box"> 
        <Menu as="div" className="relative block text-left">
          <div>
            <Menu.Button className="flex justify-center items-center rounded-2xl px-4 py-4 h-11 bg-black text-sm text-white font-semibold gap-2 more_action_items_button">
              More actions
              <ChevronDownIcon className="ml-auto text-white h-5 w-4" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="origin-top-right z-30 left-0 rounded-2xl bg-white shadow-md focus:outline-none mt-2 hidden more_action_items filter_items absolute w-max">
              <div className="py-2 max-h-60 overflow-y-auto">
                {moreActionOptions?.map((action, i) => 
                  <Menu.Item key = {i}>
                    {({ active }) => (
                      <Fragment>
                        <a className={`flex items-center max-w-64 gap-2 p-2 cursor-pointer ${i > 0 && 'border-t border-gray-300'} ${(action?.is_disabled || (action?.key == 'send_to_manager' && actionButtonDisabled)) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleMoreAction(action)} data-tip data-for={`${eventData?.id}_more_action_tooltip_${i}`}>
                          {['replace_class', 'assign_sub', 'approve_without_sync'].includes(action?.key) ?
                            <FontAwesomeIcon className='w-3.5 h-3.5 shrink-0' icon="fa-solid fa-user-alt" />
                            :
                            action?.key == 'reopen_class' ?
                            <FontAwesomeIcon className='w-3.5 h-3.5 shrink-0' icon="fa-solid fa-refresh" />
                            :
                            action?.key == 'add_to_calendar' ?
                            <FontAwesomeIcon className='w-3.5 h-3.5 shrink-0' icon="fa-solid fa-calendar" />
                            :
                            action?.key == 'undo_cancel' ?
                            <FontAwesomeIcon className='w-3.5 h-3.5 shrink-0' icon="fa-solid fa-undo" />
                            :
                            action?.key == 'no_show_sub_request' ?
                            <FontAwesomeIcon className='w-4 h-4 shrink-0' icon="fa-solid fa-user-slash" />
                            :
                            action?.key == 'undo_no_show_sub_request' ?
                            <FontAwesomeIcon className='w-4 h-4 shrink-0' icon="fa-solid fa-user-slash" />
                            :
                            action?.key == 'send_to_manager' ?
                            <FontAwesomeIcon className='w-3.5 h-3.5 shrink-0' icon="fa-solid fa-flag" />
                            :
                            null
                          }
                          <div className="flex items-center text-xs font-semibold">
                            {action?.value}
                          </div>
                        </a>
                        {(action.key === 'send_to_manager' && action?.is_disabled && action?.message !== '') &&
                          <ReactTooltip 
                            className='max-w-xs text-center' 
                            id={`${eventData?.id}_more_action_tooltip_${i}`} 
                            place="top" 
                            effect="solid"
                          >
                            {action?.message}
                          </ReactTooltip>
                        }
                      </Fragment>
                    )}
                  </Menu.Item>
                )}
                {showCancelOption(optionListKeys, cancelOptions) &&
                  <Menu.Item>
                    {({ active }) => (
                      <Fragment>
                        <a className={`flex items-center max-w-64 gap-2 p-2 cursor-pointer ${moreActionOptions?.length > 0 && 'border-t border-gray-300'}`} onClick={() => handleShowCancelOptionsPopup()}>
                          <TrashIcon classNames={'w-4 h-4 shrink-0'}/>
                          <div className="flex items-center text-xs font-semibold">
                            Cancel sub request
                          </div>
                        </a>
                      </Fragment>
                    )}
                  </Menu.Item>
                }
                {(!integratedBusiness(business) && eventData?.canEdit) &&
                  <Menu.Item>
                    {({ active }) => (
                      <Fragment>
                        <a className={`flex items-center max-w-64 gap-2 p-2 cursor-pointer border-t border-gray-300`} onClick={() => handleEditOption()}>
                          <FontAwesomeIcon className='w-3.5 h-3.5 shrink-0' icon="fa-solid fa-pencil" />
                          <div className="flex items-center text-xs font-semibold">
                            Edit
                          </div>
                        </a>
                      </Fragment>
                    )}
                  </Menu.Item>
                }
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}