import React, { Fragment, useState, useEffect, useRef } from "react";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { ClassesSelectorIcon, SearchIcon } from '../../../../../assets';
import { debounce } from "lodash";
import Select from 'react-select';
import { customSearchDropDownStyles } from '../../../../../helpers';

export default function ClassDropDownComponent(props) {
  const { instructorClasses, selectedClsId, selectedClsName, handleSaveClass, selectedSkillId, i, classOptions } = props
  const menuButtonRef = useRef(null);
  const [ searchValue, setSearchValue ] = useState('')
  const [ classesList, setClassesList] = useState(instructorClasses)
  const [ selectedOption, setSelectedOption ] = useState(selectedClsId !== '' ? {value: selectedClsId, label: selectedClsName} : null)
  const [inputValue, setInputValue] = useState(selectedClsId !== '' ? selectedClsName : '');

  useEffect(() => {
    setClassesList(instructorClasses)
  }, [instructorClasses])

  useEffect(() => {
    if (selectedClsId !== '') setSelectedOption({value: selectedClsId, label: selectedClsName})
  }, [selectedClsId])

  const handleClassSearch = (e) => {
    let val = e.target.value
    if (e.key === ' ') {
      e.preventDefault();
      val += ' ';
    }
    searchClass(val)
    setSearchValue(val)
  }

  const searchClass = debounce(val => {
    performSearchClass(val.trim())
  }, 800);

  const performSearchClass = (text) => {
    let newClassList = instructorClasses.filter((c) =>{
      return c?.name?.toLowerCase().includes(text?.toLowerCase())
    })
    setClassesList(newClassList);
  }

  const handleChangeClass = (e) => {
    let classData = getClassName(e.target.value)
    handleSaveClass(parseInt(e.target.value), classData?.name, classData?.business_skill?.id || '')
    menuButtonRef.current.click()
  }

  const getClassName = (id) => {
    return instructorClasses.find((c) => c?.id == id )
  }

  const handleClick = () => {
    let div = $('.special-container-class')
    if(div.hasClass('pb-8')){
      div.removeClass('pb-8')
    }else{
      div.addClass('pb-8')
    }
  }

  const processFillBackFilter = () => {
    setSearchValue('')
    setClassesList(instructorClasses)
  }

  const handleRemoveExtraPadding = () => {
    let div = $('.special-container-class')
    if(div.hasClass('pb-8')){
      div.removeClass('pb-8')
    }
  }

  const setRef = node => {
    if (node) {
      $(`.class-name-need-border-${i}`).addClass('filter-dynamic-border')
      handleClick()
    }else if (node === null) {
      $(`.class-name-need-border-${i}`).removeClass('filter-dynamic-border')
      handleRemoveExtraPadding()
      processFillBackFilter()
    }
  };

  const handleSelectedOption = (e) => {
    let classData = getClassName(e.value)
    handleSaveClass(parseInt(e.value), classData?.name, classData?.business_skill?.id || '')
    setSelectedOption(e)
    setInputValue(e ? e.label : '');
  }

  const handleMenuOpen =  (e) => {
    $(`.class-name-need-border-${i}`).addClass('filter-dynamic-border')
    handleClick()
  }

  const handleMenuClose =  (e) => {
    $(`.class-name-need-border-${i}`).removeClass('filter-dynamic-border')
    handleRemoveExtraPadding()
    processBackFill()
  }

  const processBackFill = () => {
    if(inputValue == '' || selectedOption == null){
      setSelectedOption(selectedClsId !== '' ? {value: selectedClsId, label: selectedClsName} : null)
      setInputValue(selectedClsId !== '' ? selectedClsName : '');
    }else if(inputValue !== selectedOption.label){
      setInputValue(selectedClsId !== '' ? selectedClsName : '');
    }
  }

  const handleInputChange = (newValue, { action }) => {
    if (action === 'input-change') {
      if(newValue !==  ''){
        setInputValue(newValue);
      }else{
        setInputValue('');
        setSelectedOption(null);
      }
    }
    return newValue;
  };

  return(
    <Fragment>
      <div className="relative filters_box">
        <ClassesSelectorIcon classNames={'w-5 h-5 absolute top-3 left-3 z-10'}/>
        <Select
          className={`flex justify-center w-full rounded-2xl pl-8 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 h-11 gap-1 items-center border-2 border-transparent class-name-need-border-${i} class-container`}
          placeholder="Select Class"
          classNamePrefix="r-select"
          value={selectedOption}
          onChange={handleSelectedOption}
          options={classOptions}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          styles={customSearchDropDownStyles}
          inputValue={inputValue}
          defaultInputValue={inputValue}
          onInputChange={handleInputChange}
        />
      </div>
    </Fragment>
  )
}