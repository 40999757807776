import React, { useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import { ChannelHeader, MessageInput, MessageList, useChatContext, Thread, Window } from 'stream-chat-react'
import CustomChannelHeader from './customChannelHeader';
import CustomChannelInfo from './customChannelInfo';
import Cookies from 'js-cookie';
import { getGroupDetails, convertToMilliseconds, getExpiredAtString, fetchMuteDuration } from '../helpers'
import { getSudomain } from '../../../helpers'
import CustomMessageInput from './customMessageInput/customMessageInput'
import NotAllowInputField from './assets/notAllowInputField'
import NotificationModal from './assets/notificationModal'
import AlertPopup from './assets/alertPopup'
import DeleteChannelModal from './assets/deleteChannelModal'
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import { Redirect } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  display: flex;
  background-color: white;
  border-radius:12px;

  .str-chat-header-livestream {
    width: 100%;
    height: 60px;
  }

  .str-chat__list {
    height: calc(100vh - 270px);
  }

  .str-chat__input-flat-wrapper {
    position: absolute;
    bottom: -55px;
    width: 100%;
  }
`;

const InfoContainer = styled.div`
  width: 100%;
  border-radius:12px;
  background-color: white;
  overflow: hidden;
`;

export default function ChannelBody(props) {
  const { user, permissions, switchedChannelAfterDisable } = props
  const { channel, setActiveChannel } = useChatContext()
  const [ showEditButton, setShowEditButton ] = useState(false)
  const [ showInputField, setShowInputField ] = useState(true)
  const [ showInfoComponent, setShowInfoComponent ] = useState(false)
  const [ locationTags, setLocationTags ] = useState([])
  const [ skillTags, setSkillTags ] = useState([])
  const [ notification, setNotification ] = useState('do_not_mute')
  const [ audienceList, setAudienceList ] = useState([])
  const [ notificationDuration, setNotificationDuration ] = useState('')
  const [ homeClubTags, setHomeClubTags ] = useState([])
  const [ showDeleteButton, setShowDeleteButton ] = useState(false)
  const [ loadingInProgress, setLoadingInProgress ] = useState(false)
  const [ channelDataLoaded, setChannelDataLoaded ] = useState(false)
  const [ showNotificationList, setShowNotificationList ] = useState(false)
	const [ showAlertPopup, setShowAlertPopup ] = useState(false)
	const [ alertType, setAlertType ] = useState('success')
	const [ alertMessage, setAlertMessage ] = useState('')
  const [ showDeleteComfirmationPopup, setShowDeleteComfirmationPopup ] = useState(false)
  const [ disableDeleteBtn, setDisableDeleteBtn ] = useState(false);
  const [ redirect, setRedirect ] = useState(false)
  const [ redirectTo, setRedirectTo ] = useState('')

  useEffect(() => {
    if(channel){
      let userRole = channel.data.acknowledged_roles || [user?.role]
      if(!userRole?.includes(user?.role)){
        setShowInputField(false)
      }
      // if(permissions?.business_messages?.groups && user?.role !== 'Fitness Instructor'){
      //   setShowEditButton(true)
      // }
    }
  }, [])

  const handleSetShowInfo = () => {
    const channelMute = channel.muteStatus();
    setLoadingInProgress(true)
    getGroupDetails(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), channel.id).then(      
      response => response.json()
    )
    .then(result => {
      setLoadingInProgress(false)
      if(result.status === 200){
        let usr = {full_name: user?.full_name, id: user?.id, image: user?.profile_pic, name_image: `${user?.last_name === '' ? user?.first_name[0] : `${user?.first_name[0]}${user?.last_name[0]}`}`}
        setLocationTags(result?.chatroom?.locations_mobile)
        setSkillTags(result?.chatroom?.categories_mobile)
        setHomeClubTags(result?.chatroom?.home_clubs_mobile)
        setNotification(fetchMuteDuration(channelMute, user.time_zone_offset))
        let audeince_list = result?.chatroom?.audience_mobile || []
        audeince_list.push(usr)
        audeince_list.sort((a, b) => a.full_name.localeCompare(b.full_name, 'es', {sensitivity: 'base'}))
        setAudienceList(audeince_list)
        setNotificationDuration(getExpiredAtString(channelMute, user.time_zone_offset, user.date_format))
        setShowEditButton(result?.chatroom?.show_edit)
        setShowDeleteButton(result?.chatroom?.show_delete)
        setChannelDataLoaded(true)
      }else{
        setLocationTags([])
        setSkillTags([])
        setHomeClubTags([])
        setNotification('do_not_mute')
        setAudienceList([])
        setNotificationDuration('')
        setShowEditButton(false)
        setShowDeleteButton(false)
        setChannelDataLoaded(false)
      }
    })
  }

  const setUserNotification = (data) => {
    setNotification(data)
  }

  if(showInfoComponent){
    const setInfoComponentOff = (data) => {
      setShowInfoComponent(data)
    }
    return(
      <InfoContainer>
        <CustomChannelInfo setInfoComponentOff = {setInfoComponentOff} showEditButton = {showEditButton} locationTags = {locationTags} skillTags = {skillTags} userNotification = {notification} setUserNotification = {setUserNotification} allAudienceList = {audienceList} notificationDuration = {notificationDuration} homeClubTags={homeClubTags} showDeleteButton={showDeleteButton}/>
      </InfoContainer>
    )
  }

  const getMessageActions = () => {
      return ["edit", "delete", "react", "reply"];
  };

  const customInput = () => {
    return <NotAllowInputField />
  }

  const customActions = {
    'Copy text': (message) => {
      navigator.clipboard.writeText(message.text || '')
    },
  };

  const handleCloseAlertPopup = (data) =>{
		setShowAlertPopup(data)
	}

  const clickOnNotificationOption = (data) => {
    handleMuteNotifications(data)
		setUserNotification(data)
		setNotification(data)
	}

  const handleMuteNotifications = async (data) => {
    try {
      if (data === 'do_not_mute') {
        await channel.unmute();
      } else if (data === 'always') {
        await channel.mute();
      } else {
        await channel.mute({
          expiration: convertToMilliseconds(data)
        });
      }

      setSuccessAlert()
    } catch (error) {
      setErrorAlert()
    }
  }

  const setSuccessAlert = () => {
    setTimeout(() => {
      const channelMute = channel.muteStatus();
      setNotificationDuration(getExpiredAtString(channelMute, user.time_zone_offset, user.date_format))
    }, 100)
  
    setAlertMessage('Notification settings updated')
    setAlertType('success')
    setShowAlertPopup(true)
    setShowNotificationList(false)
    setTimeout(()=>{
      setShowAlertPopup(false)
    },5000)
  }

  const setErrorAlert = () => {
    setAlertMessage('There was an error updating your preferences. Please try again.')
    setAlertType('danger')
    setShowAlertPopup(true)
    setTimeout(()=>{
      setShowAlertPopup(false)
    },5000)
  }

  const handleShowDeleteComfirmation = (data) => {
		setShowDeleteComfirmationPopup(data)
	}

  const performDeleteChannel = () => {
		let channelId = channel.id
    if (!channelId || channelId === '' || disableDeleteBtn) return
    setDisableDeleteBtn(true)
    let url = `/api/v3/messenger/delete_chatroom`
    let params = {
      stream_id: channelId
    }
    axiosRequest(url, 'DELETE', params, 'data').then(result => {
      if(result.status === 200){
				setAlertType('success')
				setAlertMessage(result?.message)
				setShowAlertPopup(true)
				setShowDeleteComfirmationPopup(false)
        setTimeout(()=>{
					setShowAlertPopup(false)
          setDisableDeleteBtn(false)
				},5000)
      }else{
				setDisableDeleteBtn(false)
				setAlertType('danger')
				setAlertMessage(result?.error)
				setShowAlertPopup(true)
        setTimeout(()=>{
					setShowAlertPopup(false)
				},5000)
      }
    })
  }

  const processHideChannel = async() => {
    let channelId = channel.id
    try {
      await channel.hide().then(() => {
        switchedChannelAfterDisable(channelId, 'hide')
      })
    } catch (error) {
      setRedirectTo(`/messenger`)
      setRedirect(true)
    }
  }
  
  return (
    <Fragment>
      {redirect && 
        <Redirect to={redirectTo} />
      }
      {showNotificationList &&
        <NotificationModal showNotificationList={showNotificationList} closeNotificationModal={setShowNotificationList} notification={notification} setNotification={clickOnNotificationOption}
        />
      }
      {showAlertPopup &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert = {handleCloseAlertPopup}
        />
      }
      {showDeleteComfirmationPopup &&
				<DeleteChannelModal showDeleteComfirmationPopup={showDeleteComfirmationPopup} handleShowDeleteComfirmation={handleShowDeleteComfirmation} disableDeleteBtn={disableDeleteBtn} performDeleteChannel={performDeleteChannel}/>
			}
      <Container>
        <Window>
          <CustomChannelHeader 
            handleSetShowInfo={handleSetShowInfo} 
            loadingInProgress={loadingInProgress}
            channelDataLoaded={channelDataLoaded}
            showEditButton={showEditButton}
            showDeleteButton={showDeleteButton}
            setShowInfoComponent={setShowInfoComponent}
            setShowNotificationList={setShowNotificationList}
            handleShowDeleteComfirmation={handleShowDeleteComfirmation}
            processHideChannel={processHideChannel}
            notification={notification}
            notificationDuration={notificationDuration}
          />
          <MessageList messageActions={getMessageActions()} customMessageActions={customActions} />
          {showInputField ?
            <MessageInput />
            :
            <NotAllowInputField />
          }
        </Window>
        <Thread additionalMessageInputProps={{ grow: true, Input: showInputField ? CustomMessageInput : customInput }} messageActions={getMessageActions()}/>
      </Container>
    </Fragment>
  )
}
