import React, { Fragment, useState } from "react";
import UsersListCardComponent from "../usersListCardComponent";
import { axiosRequest } from "../../../../subRequests/helpers/helpers";
import { Accordion, Card } from "react-bootstrap";
import { AngleIcon, SendIcon, TashIcon } from './../../../../subRequests/assets';
import { TrashIcon } from './../../../assets';

export default function MgrEventUsersListComponent(props) {
  const { eventData, redirectToUrl, handleAlert, handleUpdateEvent } = props;
  const [disableRemoveButton, setDisableRemoveButton] = useState(false);
  const [ openAccordionKey, setOpenAccordionKey ] = useState('0')

  const processRemoveUserFromEvent = (userId) => {
    setDisableRemoveButton(true);
    let params = {
      inst_id: userId,
      preview: false
    }
    let url = `/api/v3/business_events/${eventData?.id}/delete_invite`;
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if (result?.status == 200) {
        handleAlert(true, result?.success, "success");
        handleUpdateEvent(result?.event);
      } else {
        handleAlert(true, result?.error, "danger");
      }
      setDisableRemoveButton(false);
      setTimeout(() => {
        handleAlert(false, "", "");
      }, 3000);
    });
  };

  const handleAccordionClick= (accordKey) => {
    if(openAccordionKey === accordKey){
      setOpenAccordionKey('')
    }else{
      setOpenAccordionKey(accordKey)
    }
  }

  return (
    <Fragment>
      <div className="text-xl font-semibold mb-2 hidden lg:flex">{`No Responses (${eventData?.pending_subs?.length})`}</div>
      <div className="hidden lg:flex ">
        <div className="mt-3 w-full">
          {eventData?.pending_subs?.map((sub, i) => (
            <div
              key={i}
              className="px-4 py-3 border-2 border-gray-50 rounded-2xl shadow mb-3 flex justify-between"
            >
              <UsersListCardComponent
                sub={sub}
                redirectToUrl={redirectToUrl}
                handleAlert={handleAlert}
                subTimestamp={""}
              />
              {eventData?.show_manger_request_buttons && (
                <button
                  className={`flex items-center px-3 py-2 rounded-2xl text-sm font-semibold h-9 gap-2 border-1-c border-yellow-500 text-yellow-500 ${
                    disableRemoveButton ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={() => processRemoveUserFromEvent(sub?.id)}
                  disabled={disableRemoveButton}
                >
                  Remove
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="flex lg:hidden">
        <Accordion className="bg-white w-full" defaultActiveKey={'0'}>
          <Card className="border-0">
            <Accordion.Toggle eventKey="0" className="bg-white flex items-center w-full justify-between py-2 h-16" onClick={() => handleAccordionClick('0')}>
              <div className="flex items-center gap-2">
                <div className='bg-yellow-500 w-2 h-4 rounded-sm'></div>
                <div className='text-base font-medium'>No Responses</div>
                <div className="tc-10 rounded-full bg-gray-900 text-white flex items-center justify-center w-4 h-4 font-medium">{eventData?.pending_subs?.length}</div>
              </div>
              <div className="flex items-center">
                <AngleIcon classNames={`w-4 h-4 transform ${openAccordionKey == '0' ? 'rotate-180' : ''}`}/>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="p-0">
                <div className="mt-2">
                  {eventData?.pending_subs?.map((usr, i) => 
                    (<div key={i} className='flex items-center gap-2 justify-between py-2'>
                      <button className="flex items-center gap-2" onClick={() => handleShowInstructorDetails(usr)}>
                        <img src={usr?.image} className='w-6 h-6 rounded-full' />
                        <div className="flex flex-wrap gap-1">
                          <div className='custom-blue-200 text-xs font-semibold flex gap-1'>{usr?.full_name}</div>
                          {usr?.home_club_name !== undefined && usr?.home_club_name !== '' &&
                            <div className="flex items-center text-black font-normal text-xs lg:ml-2">
                              {`(${usr?.home_club_name})`}
                            </div>
                          }
                        </div>
                      </button>
                      {eventData?.show_manger_request_buttons &&
                        <Fragment>
                          <button className={`flex justify-center items-center shadow-md rounded-full border-1-c border-yellow-500 text-yellow-500 h-6 w-6 cursor-pointer bg-white ${disableRemoveButton ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => processRemoveUserFromEvent(usr?.id)} disabled={disableRemoveButton}>
                            <TrashIcon classNames={'w-4 h-4'} colour={'#EAB308'}/>
                          </button>
                        </Fragment>
                      }
                    </div>)
                  )}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </Fragment>
  );
}
