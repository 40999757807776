import React, { Fragment, Component } from 'react';
import HeaderComponent from '../../signIn/components/headerComponent';
import FooterComponent from '../../signIn/components/footerComponent';
import AlertPopup from '../../messenger/pages/chatComponents/assets/alertPopup';
import UserContext from '../../userComponents/userContext';
import ConfirmResetPasswordComponent from '../components/confirmResetPasswordComponent';

export default class ResetPasswordLayout extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      alertType: '',
      alertMessage: '',
      pagePath: this.props.path,
      subdomain: this.props.subdomain
    }
  }

  setAlert = (showAlert, alertType, alertMessage) => {
    this.setState({
      showAlert: showAlert,
      alertType: alertType,
      alertMessage: alertMessage
    })
  }

  closeAlert = (data) => {
    this.setAlert(data, '', '')
  }

  render() {
    const { showAlert, alertType, alertMessage, pagePath } = this.state
    const { subdomainBusinessImage, handleReIntializeAllApiCalls, pageLoaded, updateUserData, loadingMessage } = this.props

    return (
      <Fragment>
        {showAlert &&
          <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={this.closeAlert}/>
        }
        {pageLoaded &&
          <div className='h-screen font-open-sans pt-16'>
            <HeaderComponent path={pagePath} subdomainBusinessImage={subdomainBusinessImage}/>
              <div className={`flex items-center justify-center w-full h-2/3 bg-gray-custom-50 py-8 px-5 md:px-8`}>
                {(pagePath == 'reset-password') && 
                  <ConfirmResetPasswordComponent
                    handleReIntializeAllApiCalls={handleReIntializeAllApiCalls}
                    setAlert={this.setAlert}
                    updateUserData={updateUserData}
                    loadingMessage={loadingMessage}
                  />
                }
              </div>
            <FooterComponent path={pagePath} />
          </div>
        }
      </Fragment>
    );
  }
}