import React, { Fragment } from "react";

export default function DateComponent(props) {
  const { clickDate } = props

  return(
    <Fragment>
      <div className="w-full flex justify-center">
        <div className="">
          <div className="font-open-sans text-center text-xs uppercase text-gray-900">{clickDate.toLocaleString('en-US', {weekday: 'short'})}</div>
          <div className="font-open-sans text-center text-base font-semibold text-gray-900">{clickDate.getDate()}</div>
          <div className="font-open-sans text-center text-xs uppercase text-gray-900">{clickDate.toLocaleString('en-US', {month: 'short'})}</div>
        </div>
      </div>
    </Fragment>
  )
}