import React, { Fragment, useState, useEffect } from 'react';
import LoaderComponent from './../components/loaderComponent/loaderComponent'
import FiltersComponent from './../components/filtersComponent/filtersComponent';
import { axiosRequest, seprateOutLocations, handleCheckCurrentDate, currentMonthFirstDate, currentMonthLastDate, currentYearFirstDate, currentYearLastDate, handleStartDateOfweek, handleEndDateOfWeek, veryFirstSetStartDate, veryFirstSetEndDate, updateEventsList, axiosGetRequest, removeDeletedEvent, handleRemoveCurrentDate, handleClickOnLink, checkShowRegions, checkHidePastEvents, checkFiltersState, handleSaveFilters, checkLocations, checkInstructors, checkClasses, checkMyRequestFiltersState, checkMyRequestLocations } from '../../helpers/helpers'
import ListViewTableComponent from './../components/listViewComponents/listViewTableComponent';
import { getSentBucketDefaultSelection, getAvaialbleBucketDefaultSelection, getAffectedVaraibles, getVariableNameByStatus, getSentAffectedVaraibles } from '../../helpers/supportHelpers';

export const ListView = (props) => {
  const { loadingMessage, business, user, activeTab, path, selectedLocationsFilter, statusesFilter, selectedRegionsFilter, selectedInstructorsFilter, selectedClassesFilter, calendarViewTypeFilter, totalApiCallCount, handleSaveFilter, allRegionsLocations, allInstructors, allClassesData, handleSaveData, dataApiCallCount, sentStatusesFilter, availableStatusFilter, availableLocationFilter, availableRegionFilter } = props
  const [ currentDate, setCurrentDate ] = useState(handleCheckCurrentDate());
  useEffect(() => {
    handleRemoveCurrentDate();
  }, []);
  const [ startDate, setStartDate ] = useState(veryFirstSetStartDate(currentDate, (calendarViewTypeFilter || 'month'), activeTab))
  const [ endDate, setEndDate ] = useState(veryFirstSetEndDate(currentDate, (calendarViewTypeFilter || 'month'), activeTab))
  const [ loaded, setLoaded ] = useState(false)
  const [ events, setEvents ] = useState([{id: 1, title: 'test'}])
  const [ approvedEvents, setApprovedEvents ] = useState([])
  const [ cancelledEvents, setCancelledEvents ] = useState([])
  const [ managerEvents, setManagerEvents ] = useState([])
  const [ noShowEvents, setNoShowEvents ] = useState([])
  const [ openEvents, setOpenEvents ] = useState([])
  const [ pendingEvents, setPendingEvents ] = useState([])
  const allStatuses = (business?.manager_request_gate ? ['approved', 'cancelled', 'mgr', 'no_show', 'open', 'pending'] : ['approved', 'cancelled', 'no_show', 'open', 'pending'])
  const [ statusFilter, setStatusFilter ] = useState((checkFiltersState(user?.id, 'status') == 'all_selected' ? allStatuses : statusesFilter) || allStatuses)
  const [ regionLocationsList, setRegionLocationsList ] = useState(allRegionsLocations || [])
  const [ showRegions, setShowRegions ] = useState(false)
  const [ selectedLocations, setSelectedLocations ] = useState(path === 'all-requests' ? (selectedLocationsFilter || []) : availableLocationFilter)
  const [ selectedRegions, setSelectedRegions ] = useState(path === 'all-requests' ? (selectedRegionsFilter || []) : availableRegionFilter)
  const [ selectedMonth, setSelectedMonth ] = useState(currentDate)
  const [ selectedEventURL, setSelectedEventURL ] = useState('')
  const [ apiCallCount, setApiCallCount ] = useState(dataApiCallCount || 0)
  const [ selectedInstructors, setSelectedInstructors ] = useState(selectedInstructorsFilter || [])
  const [ selectedClasses, setSelectedClasses ] = useState(selectedClassesFilter || [])
  const [ myRequestsTab, setMyRequestsTab ] = useState('sent')
  const [ calendarViewType, setCalendarViewType ] = useState(calendarViewTypeFilter || 'month')
  const [ selectedWeek, setSelectedWeek ] = useState(currentDate)
  const [ selectedYear, setSelectedYear ] = useState(currentDate)
  const [ tableDateLoaded, setTableDateLoaded ] = useState(false)
  const [ activePage, setActivePage ] = useState(1)
  const [ totalEventsCount, setTotalEventsCount ] = useState(0)
  const [ pageSize, setPageSize ] = useState(50)
  const [ approvedCount, setApprovedCount ] = useState(0)
  const [ cancelledCount, setCancelledCount ] = useState(0)
  const [ managerCount, setManagerCount ] = useState(0)
  const [ noShowCount, setNoShowCount ] = useState(0)
  const [ openCount, setOpenCount ] = useState(0)
  const [ pendingCount, setPendingCount ] = useState(0)
  const [ sentEvents, setSentEvents ] = useState([])
  const [ availableEvents, setAvailableEvents ] = useState([])
  const [ hidePastEvents, setHidePastEvents ] = useState(checkHidePastEvents() || false)
  const [ sentPage, setSentPage ] = useState(1)
  const [ availablePage, setAvailablePage ] = useState(1)
  const [ approvedPage, setApprovedPage ] = useState(1)
  const [ sentEventsTotalCount, setSentEventsTotalCount ] = useState(0)
  const [ availableEventsTotalCount, setAvailableEventsTotalCount ] = useState(0)
  const [ approvedEventsTotalCount, setApprovedEventsTotalCount ] = useState(0)
  const allSentStatuses = getSentBucketDefaultSelection(business?.manager_request_gate)
  const [ sentStatusFilter, setSentStatusFilter ] = useState((sentStatusesFilter.length > 0 ? sentStatusesFilter : allSentStatuses) || allSentStatuses)
  const [ sentFilterEventsCount, setSentFiltersEventsCount ] = useState({
    managerCount: 0,
    pendingCount: 0,
    acceptedCount: 0,
    approvedCount: 0,
    reopenCount: 0
  })
  const [ availableSelectedStatus, setAvailableSelectedStatus ] = useState(getAvaialbleBucketDefaultSelection(availableStatusFilter))
  const [ availableFilterEventsCount, setAvailableFilterEventsCount ] = useState({
    pendingCount: 0,
    acceptCount: 0,
    declineCount: 0
  })

  useEffect(() => {
    if(startDate !== '' && endDate !== ''){
      if(['all-requests'].includes(path)){
        setActivePage(1)
        setTableDateLoaded(false)
        processGetEventsData(1, selectedLocations, selectedInstructors, selectedClasses, statusFilter, true, hidePastEvents)
      }else {
        setTableDateLoaded(false)
        setSentPage(1)
        setAvailablePage(1)
        setApprovedPage(1)
        fetchMyRequests(hidePastEvents, 1, sentStatusFilter, true, availableSelectedStatus, selectedLocations)
      }
    }
  }, [startDate, endDate])

  useEffect(() => {
    if(tableDateLoaded){
      document.body.style.overflow = 'auto';
    }else{
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [tableDateLoaded])

  const processGetEventsData = (page, locationIds, instructorIds, classIds, statuses, initialize, hidePastEvents) => {
    let selectedInstructorsIds = instructorIds?.map(function(a) {return a?.id});
    let selectedClassesIds = classIds?.map(function(a) {return a?.id});
    let instructor_status = ['all_selected', 'no_selected'].includes(checkFiltersState(user?.id, 'instructor'))
    let class_status = ['all_selected', 'no_selected'].includes(checkFiltersState(user?.id, 'class'))
    let initialsValue = (initialize ? initialize : dataApiCallCount == 0)
    let params = {
      start_date: startDate,
      end_date: endDate,
      view_type: activeTab,
      initialize: initialsValue,
      page: parseInt(page) == 0 ? 1 : parseInt(page),
      location_ids: locationIds,
      instructor_ids: selectedInstructorsIds,
      class_ids: selectedClassesIds,
      statuses: statuses,
      version: 'v2',
      hide_past_events: hidePastEvents,
      all_instructors: instructor_status,
      all_classes: class_status
    }
    let url = `${window._sub_board_url}/api/v3/sub_overview/get_location_events`
    axiosRequest(url, 'POST', params, 'data').then((response) => {
      if(response.status == 200){
        setTotalEventsCount(response?.total_count)
        setActivePage(response?.page)
        setPageSize(response?.page_size)
        setApiCallCount(apiCallCount + 1)
        let approve_results = response?.results?.approved || []
        setApprovedEvents(approve_results)
        let cancelled_results = response?.results?.cancelled || []
        setCancelledEvents(cancelled_results)
        let manager_results = response?.results?.manager || []
        setManagerEvents(manager_results)
        let no_show_results = response?.results?.no_show || []
        setNoShowEvents(no_show_results)
        let open_results = response?.results?.open || []
        setOpenEvents(open_results)
        let pending_results = response?.results?.pending || []
        setPendingEvents(pending_results)
        let results = Object.values(response?.results).flat()
        let data = results
        setEvents(data || [])
        if(initialize || dataApiCallCount == 0){
          setRegionLocationsList(response?.locations || [])
          setShowRegions(checkShowRegions(response?.locations))
          handleSaveData(response?.locations || [], response?.instructors || [], response?.classes || [], (dataApiCallCount + 1))
          setApprovedCount(response?.approved_count)
          setCancelledCount(response?.cancelled_count)
          setManagerCount(response?.manager_count)
          setNoShowCount(response?.no_show_count)
          setOpenCount(response?.open_count)
          setPendingCount(response?.pending_count)
          if(initialize || totalApiCallCount == 0){
            let regions = response?.locations.map(function(a) {return a.region?.name;});
            let locations = seprateOutLocations(response?.locations).map(function(a) {return a.id;});
            let instructorIds = response?.instructors || [];
            let classIds = response?.classes || [];

            let locationState = checkFiltersState(user?.id, 'location')
            let [locationsToBeSelected, regionsToBeSelected] = locationState == 'all_selected' ? [locations, regions] : checkLocations(locations, regions, selectedLocations, selectedRegions, response?.locations, user?.id)
            setSelectedLocations(locationsToBeSelected)
            setSelectedRegions(regionsToBeSelected)
            
            let instructorsToBeSelected = checkFiltersState(user?.id, 'instructor') == 'all_selected' ? instructorIds : selectedInstructors
            setSelectedInstructors(instructorsToBeSelected)

            let classesToBeSelected = checkFiltersState(user?.id, 'class') == 'all_selected' ? classIds : selectedClasses
            setSelectedClasses(classesToBeSelected)
            
            handleSaveFilter(calendarViewType, apiCallCount)
          }
        }
        setTableDateLoaded(true)
        setLoaded(true)
      }else{
        setEvents([])
        setApprovedEvents([])
        setCancelledEvents([])
        setManagerEvents([])
        setNoShowEvents([])
        setOpenEvents([])
        setPendingEvents([])
        setRegionLocationsList([])
        setShowRegions(false)
        setSelectedRegions([])
        setSelectedLocations([])
        setTableDateLoaded(true)
        setLoaded(true)
      }
    })
  }

  const fetchMyRequests = (hidePastEvents, page, sentStatuses, initialize, availableStatuses, availableLocations) => {
    let url = `${window._sub_board_url}/api/v3/business_events?start_date=${startDate}&end_date=${endDate}&view_type=${activeTab}&year_view=${calendarViewType == 'year'}&hide_past_events=${hidePastEvents}&page=${page}&page_type=${myRequestsTab}&sent_statuses=${sentStatuses}&initialize=${initialize}&available_statuses=${availableStatuses}&available_locations=${availableLocations}`
    axiosGetRequest(url).then((result) => {
      if(result.status == 200){
        setSentEventsTotalCount(result?.sent_count)
        setAvailableEventsTotalCount(result?.available_count)
        setApprovedEventsTotalCount(result?.approved_count)
        setSentFiltersEventsCount({
          managerCount: result?.sent_bucket_filters_count?.sent_pending_mgr_count,
          pendingCount: result?.sent_bucket_filters_count?.sent_open_count,
          acceptedCount: result?.sent_bucket_filters_count?.sent_pending_count,
          approvedCount: result?.sent_bucket_filters_count?.sent_approved_count,
          reopenCount: result?.sent_bucket_filters_count?.sent_re_open_count
        })
        setAvailableFilterEventsCount({
          pendingCount: result?.available_bucket_filters_count?.available_pending_count,
          acceptCount: result?.available_bucket_filters_count?.available_accept_count,
          declineCount: result?.available_bucket_filters_count?.available_decline_count
        })
        if(initialize){
          setRegionLocationsList(result?.locations || [])
          setShowRegions(checkShowRegions(result?.locations))
          let regions = result?.locations.map(function(a) {return a.region?.name;});
          let locations = seprateOutLocations(result?.locations).map(function(a) {return a.id;});
          let locationState = checkMyRequestFiltersState(user?.id, 'location', 'available')
          let [locationsToBeSelected, regionsToBeSelected] = checkMyRequestLocations(locations, regions, selectedLocations, selectedRegions, result?.locations, user?.id, 'available')
          setSelectedLocations(locationsToBeSelected)
          setSelectedRegions(regionsToBeSelected)
        }
        if(page == 1){
          setApprovedEvents(result?.approved)
          setSentEvents(result?.sent)
          setAvailableEvents(result?.available)
        }else{
          if(result?.page_type == 'sent'){
            setSentEvents(result?.sent)
          }else if(result?.page_type == 'available'){
            setAvailableEvents(result?.available)
          }else if(result?.page_type == 'approved'){
            setApprovedEvents(result?.approved)
          }
        }
        setApprovedCount(result?.approved_count)
        let eventData = []
        if(myRequestsTab == 'sent'){
          eventData = result?.sent
        }else if(myRequestsTab == 'available'){
          eventData = result?.available
        }else if(myRequestsTab == 'approved'){
          eventData = result?.approved
        }
        setEvents(eventData || [])
        setTableDateLoaded(true)
        setLoaded(true)
      }else{
        setEvents([])
        setApprovedEvents([])
        setSentEvents([])
        setAvailableEvents([])
        setTableDateLoaded(true)
        setLoaded(true)
      }
    })
  }

  const handleMyRequestsPageChange = (page) => {
    if(myRequestsTab == 'sent'){
      setSentPage(page)
    }else if(myRequestsTab == 'available'){
      setAvailablePage(page)
    }else if(myRequestsTab == 'approved'){
      setApprovedPage(page)
    }
    setTableDateLoaded(false)
    fetchMyRequests(hidePastEvents, page, sentStatusFilter, false, availableSelectedStatus, selectedLocations)
  }

  const hanldleSelectLocations = (regions, locations) => {
    setSelectedLocations(locations)
    setSelectedRegions(regions)
    setTableDateLoaded(false)
    switch(path){
      case 'all-requests':
        processGetEventsData(1, locations, selectedInstructors, selectedClasses, statusFilter, false, hidePastEvents)
        break;
      case 'my-requests':
        fetchMyRequests(hidePastEvents, 1, sentStatusFilter, false, availableSelectedStatus, locations)
        break;
    }
  }

  const handleMonthChange = (date) => {
    setSelectedMonth(date)
    setCurrentDate(date)
    setStartDate(currentMonthFirstDate(date).toLocaleDateString('en-GB'))
    setEndDate(currentMonthLastDate(date).toLocaleDateString('en-GB'))
  }

  const handleSelectInstructors = (instructors) => {
    setSelectedInstructors(instructors)
    setTableDateLoaded(false)
    window.setTimeout(() => {
      processGetEventsData(1, selectedLocations, instructors, selectedClasses, statusFilter, false, hidePastEvents)
    }, 200)
  }

  const handleSelectClasses = (classes) => {
    setSelectedClasses(classes)
    setTableDateLoaded(false)
    window.setTimeout(() => {
      processGetEventsData(1, selectedLocations, selectedInstructors, classes, statusFilter, false, hidePastEvents)
    }, 200)
  }

  const handleCalendarViewType = (data) => {
    let current_date = new Date()
    if(data == 'month'){
      setSelectedMonth(current_date)
      setCurrentDate(current_date)
      let start_date = currentMonthFirstDate(current_date).toLocaleDateString('en-GB')
      setStartDate(start_date)
      setEndDate(currentMonthLastDate(current_date).toLocaleDateString('en-GB'))
    }else if(data == 'week'){
      setSelectedWeek(current_date)
      setCurrentDate(current_date)
      let start_date = handleStartDateOfweek(current_date).toLocaleDateString('en-GB')
      setStartDate(start_date)
      setEndDate(handleEndDateOfWeek(current_date).toLocaleDateString('en-GB'))
    }else if(data == 'day'){
      let start_date = current_date.toLocaleDateString('en-GB')
      setStartDate(start_date)
      setEndDate(current_date.toLocaleDateString('en-GB'))
    }else if(data == 'year'){
      setSelectedYear(current_date)
      setCurrentDate(current_date)
      let start_date = currentYearFirstDate(current_date).toLocaleDateString('en-GB')
      setStartDate(start_date)
      setEndDate(currentYearLastDate(current_date).toLocaleDateString('en-GB'))
    }
    setCalendarViewType(data)
    handleSaveFilter(data, apiCallCount)
  }

  const handleWeekChange = (data) => {
    if(calendarViewType == 'week'){
      setSelectedWeek(data)
      setCurrentDate(data)
      let start_date = handleStartDateOfweek(data).toLocaleDateString('en-GB')
      setStartDate(start_date)
      setEndDate(handleEndDateOfWeek(data).toLocaleDateString('en-GB'))
    }else if(calendarViewType == 'day'){
      setCurrentDate(data)
      let start_date = data.toLocaleDateString('en-GB')
      setStartDate(start_date)
      setEndDate(data.toLocaleDateString('en-GB'))
    }else if(calendarViewType == 'year'){
      setSelectedYear(data)
      setCurrentDate(data)
      let start_date = currentYearFirstDate(data).toLocaleDateString('en-GB')
      setStartDate(start_date)
      setEndDate(currentYearLastDate(data).toLocaleDateString('en-GB'))
    }
  }

  const handleReloadData = (reload) => {
    if(reload) setLoaded(false)
    if(['all-requests'].includes(path)){
      processGetEventsData(activePage, selectedLocations, selectedInstructors, selectedClasses, statusFilter, false, hidePastEvents)
    }else{
      fetchMyRequests(hidePastEvents, (myRequestsTab == 'sent' ? sentPage : myRequestsTab == 'available' ? availablePage : approvedPage), sentStatusFilter, false, availableSelectedStatus, selectedLocations)
    }
  }

  const handleUpdateEvents = (data) => {
    if(!data) return;
    setEvents(updateEventsList(events, data));
    if(['all-requests'].includes(path)){
      setApprovedEvents(updateEventsList(approvedEvents, data));
      setCancelledEvents(updateEventsList(cancelledEvents, data));
      setManagerEvents(updateEventsList(managerEvents, data));
      setNoShowEvents(updateEventsList(noShowEvents, data));
      setOpenEvents(updateEventsList(openEvents, data));
      setPendingEvents(updateEventsList(pendingEvents, data));
    }else{
      updateSentEventsCount(sentEvents, data);
      setSentEvents(updateEventsList(sentEvents, data));
      updateAvailableEventsCount(availableEvents, data);
      setAvailableEvents(updateEventsList(availableEvents, data));
    }
  }

  const updateAvailableEventsCount = (allEvents, newEvent) => {
    if (!newEvent || !allEvents || !allEvents.length > 0) return;
    let [ reduceVar, increaseVar ] = getAffectedVaraibles(allEvents, newEvent);
    if(newEvent.status == "Approved") increaseVar = ''
    if (!reduceVar || (reduceVar == '') || (reduceVar == increaseVar)) return;
    setAvailableFilterEventsCount(prevState => ({
      ...prevState,
      [reduceVar]: Math.max(prevState[reduceVar] - 1, 0),
      ...(increaseVar && {[increaseVar]: prevState[increaseVar] + 1})
    }))
  }

  const handleDeleteEvent = (data) => {
    setEvents(removeDeletedEvent(events, data));
    if(['all-requests'].includes(path)){
      setApprovedEvents(removeDeletedEvent(approvedEvents, data));
      setCancelledEvents(removeDeletedEvent(cancelledEvents, data));
      setManagerEvents(removeDeletedEvent(managerEvents, data));
      setNoShowEvents(removeDeletedEvent(noShowEvents, data));
      setOpenEvents(removeDeletedEvent(openEvents, data));
      setPendingEvents(removeDeletedEvent(pendingEvents, data));
    }else{
      sentDeleteEventCountChange(sentEvents, data);
      setSentEvents(removeDeletedEvent(sentEvents, data));
      setAvailableEvents(removeDeletedEvent(availableEvents, data));
    }
  }

  const handleChangePage = (data) => {
    setActivePage(data)
    if(['all-requests'].includes(path)){
      setTableDateLoaded(false)
      processGetEventsData(data, selectedLocations, selectedInstructors, selectedClasses, statusFilter, false, hidePastEvents)
    }
  }

  const handleSelectStatusFilter = (statuses) => {
    setStatusFilter(statuses)
    setTableDateLoaded(false)
    processGetEventsData(1, selectedLocations, selectedInstructors, selectedClasses, statuses, false, hidePastEvents)
  }

  const handleMyRequestsTab = (data) => {
    setMyRequestsTab(data)
    if(data == 'sent'){
      setEvents(sentEvents || [])
    }else if(data == 'available'){
      setEvents(availableEvents || [])
    }else if(data == 'approved'){
      setEvents(approvedEvents || [])
    }
  }

  const handleClickOnEvent = () => {
    handleClickOnLink(currentDate)
  }

  const handleSetHidePastEvents = (data) => {
    setHidePastEvents(data)
    if(['all-requests'].includes(path)){
      setActivePage(1)
      setTableDateLoaded(false)
      processGetEventsData(1, selectedLocations, selectedInstructors, selectedClasses, statusFilter, true, data)
    }else {
      setTableDateLoaded(false)
      setSentPage(1)
      setAvailablePage(1)
      setApprovedPage(1)
      fetchMyRequests(data, 1, sentStatusFilter, false, availableSelectedStatus, selectedLocations)
    }
    if(data){
      localStorage.setItem("hidePastEvents", data);
    }else{
      localStorage.removeItem("hidePastEvents");
    }
  }

  const setMyRequestStatusFilter = (data, type) => {
    setTableDateLoaded(false)
    switch(type){
      case 'sent':
        setSentStatusFilter(data)
        fetchMyRequests(hidePastEvents, 1, data, false, availableSelectedStatus, selectedLocations)
        break;
      case 'available':
        setAvailableSelectedStatus(data)
        fetchMyRequests(hidePastEvents, 1, sentStatusFilter, false, data, selectedLocations)
        break;
    }
  }

  const sentDeleteEventCountChange = (allEvents, event) => {
    if(!event || !allEvents || !allEvents.length > 0) return;
    const existingEvent = allEvents?.find(event => event.id === event.id);
    if (!existingEvent) return;
    let counterKey = getVariableNameByStatus(event) || '';
    if (!counterKey || counterKey == '') return;
    setSentFiltersEventsCount(prevState => ({
      ...prevState,
      [counterKey]: Math.max(prevState[counterKey] - 1, 0)
    }))
  }

  const updateSentEventsCount = (allEvents, newEvent) => {
    if (!newEvent || !allEvents || !allEvents.length > 0) return;
    let [ reduceVar, increaseVar ] = getSentAffectedVaraibles(allEvents, newEvent);
    if (!reduceVar || (reduceVar == '') || (reduceVar == increaseVar)) return;
    setSentFiltersEventsCount(prevState => ({
      ...prevState,
      [reduceVar]: Math.max(prevState[reduceVar] - 1, 0),
      ...(increaseVar && { [increaseVar]: prevState[increaseVar] + 1 })
    }));
  }
  
  return (
    <Fragment>
      <div className={`mt-5 w-full h-500 pt-100`}>
        {!loaded &&
          <LoaderComponent loadingMessage={loadingMessage}/>
        }
        {loaded &&
          <Fragment>
            <FiltersComponent 
              selectedStatus={statusFilter} 
              setSelectedStatus={handleSelectStatusFilter} 
              business={business} 
              approvedCount={approvedCount} 
              cancelledCount={cancelledCount} 
              managerCount={managerCount} 
              noShowCount={noShowCount} 
              openCount={openCount} 
              pendingCount={pendingCount} 
              regionLocationsList={regionLocationsList} 
              showRegions={showRegions}
              selectedLocations={selectedLocations} 
              hanldleSelectLocations={hanldleSelectLocations} 
              selectedRegions={selectedRegions} 
              myRequestsTab={myRequestsTab} 
              handleMyRequestsTab={handleMyRequestsTab} 
              sentCount={sentEventsTotalCount} 
              availableCount={availableEventsTotalCount} 
              path={path} 
              selectedMonth={selectedMonth} 
              setSelectedMonth={handleMonthChange} 
              selectedInstructors={selectedInstructors} 
              handleSelectInstructors={handleSelectInstructors}
              handleSelectClasses={handleSelectClasses} 
              selectedClasses={selectedClasses}
              activeTab={activeTab} 
              calendarViewType={calendarViewType}
              handleCalendarViewType={handleCalendarViewType}
              currentDate={currentDate}
              selectedWeek={selectedWeek}
              handleWeekChange={handleWeekChange}
              selectedYear={selectedYear} 
              hidePastEvents={hidePastEvents}
              handleSetHidePastEvents={handleSetHidePastEvents}
              user={user}
              setMyRequestStatusFilter={setMyRequestStatusFilter}
              sentManagerCount={sentFilterEventsCount?.managerCount}
              sentPendingCount={sentFilterEventsCount?.pendingCount}
              sentApprovedCount={sentFilterEventsCount?.approvedCount}
              sentAcceptedCount={sentFilterEventsCount?.acceptedCount}
              sentReopenCount={sentFilterEventsCount?.reopenCount}
              sentStatusFilter={sentStatusFilter}
              availableSelectedStatus={availableSelectedStatus}
              availablePendingCount={availableFilterEventsCount?.pendingCount}
              availableAcceptCount={availableFilterEventsCount?.acceptCount}
              availableDeclineCount={availableFilterEventsCount?.declineCount}
            />
            <ListViewTableComponent 
              events={events.sort((a, b) => new Date(a.start_time) - new Date(b.start_time))} 
              handleReloadData={handleReloadData} 
              user={user} 
              business={business} 
              tableDateLoaded={tableDateLoaded} 
              handleUpdateEvents={handleUpdateEvents} 
              handleChangePage={handleChangePage} 
              activePage={activePage} 
              totalEventsCount={totalEventsCount} 
              pageSize={pageSize} 
              path={path}
              myRequestsTab={myRequestsTab}
              handleDeleteEvent={handleDeleteEvent}
              handleClickOnEvent={handleClickOnEvent}
              myRequestsPage={myRequestsTab == 'sent' ? sentPage : myRequestsTab == 'available' ? availablePage : approvedPage}
              myRequestsPageTotalCount={myRequestsTab == 'sent' ? sentEventsTotalCount : myRequestsTab == 'available' ? availableEventsTotalCount : approvedEventsTotalCount}
              handleMyRequestsPageChange={handleMyRequestsPageChange}
            />
          </Fragment>
        }
      </div>
    </Fragment>
  )
}