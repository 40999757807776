import React, { Fragment } from 'react';

export default function ShowUploadComponent(props){
  const { uploadNewUsers, uploadOldUsers, business, saveUpload, disabledSaveBtn } = props

  const handleSubmit = (e) => {
    saveUpload(e)
  }

  return(
    <Fragment>
      <div className="mt-5 big-modal-scroll">
        { uploadNewUsers?.length > 0 &&
          <div>
            <p className="text-center">New Users</p>
            <div className="flex flex-col mt-3">
              <div className="flex-grow overflow-auto">
                <table className="relative w-full border">
                  <thead>
                    <tr>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        First Name
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        Last Name
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        Employee Id
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        Email
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        Role
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        Home Location
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        Skills
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        Phone
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        Home Club
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        City
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        State
                      </th>
                      {/* {business.mbo_integration &&
                        <React.Fragment>
                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Mindbody ID</th>
                        </React.Fragment>
                      }
                      {business.marianatek_integration &&
                        <React.Fragment>
                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Marianatek ID</th>
                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Marianatek Profile ID</th>
                        </React.Fragment>
                      }
                      {business.abc_integration &&
                        <React.Fragment>
                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">ABC ID</th>
                        </React.Fragment>
                      } */}
                      {business.clubready_integration &&
                        <React.Fragment>
                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Clubready ID</th>
                        </React.Fragment>
                      }
                      {business.fisikal_integration &&
                        <React.Fragment>
                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Fisikal ID</th>
                        </React.Fragment>
                      }
                    </tr>
                  </thead>
                  <tbody className="divide-y">
                    {uploadNewUsers.map((cls, i) =>
                      <tr key={i}>
                        <td className="px-2 py-4 text-sm">
                          {cls.first_name}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.last_name}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.employee_id}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.email}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.role}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.location}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.skills}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.phone}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.home_club}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.City}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.State}
                        </td>
                        {/* {business.mbo_integration &&
                          <React.Fragment>
                            <td className="px-2 py-4 text-center text-sm">{cls.mindbody_id}</td>
                          </React.Fragment>
                        }
                        {business.marianatek_integration &&
                          <React.Fragment>
                            <td className="px-2 py-4 text-center text-sm">{cls.external_id}</td>
                            <td className="px-2 py-4 text-center text-sm">{cls.marianatek_profile_id}</td>
                          </React.Fragment>
                        }
                        {business.abc_integration &&
                          <React.Fragment>
                            <td className="px-2 py-4 text-center text-sm">{cls.external_id}</td>
                          </React.Fragment>
                        } */}
                        {business.clubready_integration &&
                          <React.Fragment>
                            <td className="px-2 py-4 text-center text-sm">{cls.external_id}</td>
                          </React.Fragment>
                        }
                        {business.fisikal_integration &&
                          <React.Fragment>
                            <td className="px-2 py-4 text-center text-sm">{cls.external_id}</td>
                          </React.Fragment>
                        }
                      </tr>
                    )}
                  </tbody>
                  
                </table>
              </div>
            </div>
          </div>
        }
        <hr className="mt-5 mb-3" />
        
        {uploadOldUsers?.length > 0 &&
          <div>
            <p className="text-center">These Users are already existing</p>
            <div className="flex flex-col mt-3">
              <div className="flex-grow overflow-auto">
                <table className="relative w-full border">
                  <thead>
                    <tr>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        First Name
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        Last Name
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        Employee Id
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        Email
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        Role
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        Home Location
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        Skills
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        Phone
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        Home Club
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        City
                      </th>
                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                        State
                      </th>
                      {/* {business.mbo_integration &&
                        <React.Fragment>
                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Mindbody ID</th>
                        </React.Fragment>
                      }
                      {business.marianatek_integration &&
                        <React.Fragment>
                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Marianatek ID</th>
                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Marianatek Profile ID</th>
                        </React.Fragment>
                      }
                      {business.abc_integration &&
                        <React.Fragment>
                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">ABC ID</th>
                        </React.Fragment>
                      } */}
                      {business.clubready_integration &&
                        <React.Fragment>
                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Clubready ID</th>
                        </React.Fragment>
                      }
                      {business.fisikal_integration &&
                        <React.Fragment>
                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Fisikal ID</th>
                        </React.Fragment>
                      }
                    </tr>
                  </thead>
                  <tbody className="divide-y">
                    {uploadOldUsers.map((cls, i) =>
                      <tr key={i}>
                        <td className="px-2 py-4 text-sm">
                          {cls.first_name}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.last_name}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.employee_id}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.email}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.role}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.location}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.skills}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.phone}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.home_club}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.City}
                        </td>
                        <td className="px-2 py-4 text-sm">
                          {cls.State}
                        </td>
                        {/* {business.mbo_integration &&
                          <React.Fragment>
                            <td className="px-2 py-4 text-center text-sm">{cls.mindbody_id}</td>
                          </React.Fragment>
                        }
                        {business.marianatek_integration &&
                          <React.Fragment>
                            <td className="px-2 py-4 text-center text-sm">{cls.external_id}</td>
                            <td className="px-2 py-4 text-center text-sm">{cls.marianatek_profile_id}</td>
                          </React.Fragment>
                        }
                        {business.abc_integration &&
                          <React.Fragment>
                            <td className="px-2 py-4 text-center text-sm">{cls.external_id}</td>
                          </React.Fragment>
                        } */}
                        {business.clubready_integration &&
                          <React.Fragment>
                            <td className="px-2 py-4 text-center text-sm">{cls.external_id}</td>
                          </React.Fragment>
                        }
                        {business.fisikal_integration &&
                          <React.Fragment>
                            <td className="px-2 py-4 text-center text-sm">{cls.external_id}</td>
                          </React.Fragment>
                        }
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        }
        <div className='flex my-4 gap-3 justify-center'>
          <button
            type="button"
            className={`cursor-pointer w-full h-10 items-center inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm ${disabledSaveBtn ? 'button-disabled bg-gray-400' : 'bg-dark-blue'}`}
            onClick={(e) => handleSubmit(e)}
            disabled={disabledSaveBtn}
          >
            <div className='text-white text-base flex items-center gap-2.5 relative px-1'>
              {disabledSaveBtn &&
                <div className="flex items-center -left-3.5 justify-center absolute">
                  <svg className="animate-spin h-3.5 w-3.5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              }
              Save
            </div>
          </button>
        </div>
      </div>
    </Fragment>
  )
}