import React from "react";
import { Avatar, useChatContext } from 'stream-chat-react';
import { Channel, UserResponse } from 'stream-chat';

const SearchResult = (props) => {
  const { focusedId, result, setChannel } = props
  const { client, setActiveChannel } = useChatContext()
  const channel = result

  return (
    <div onClick={() => setChannel(channel)} className={focusedId === channel.id ? 'channel-search__result-container__focused' : 'channel-search__result-container'}>
      <div className='channel-search__result-user'>
        <Avatar image={channel.data.image} name={channel.data.name || channel.data.id} size={24} />
        <p className='channel-search__result-text'>{channel?.data?.name || channel.data.id}</p>
      </div>
    </div>
  )
}

const renderDmName = (members) => {
  let names = members.map((usr) => {return usr.user.name})
  let name = names.join(', ')
  let len = 21
  let shortName = []
  for(let i = 0; i < name?.length; i++){
    if(i < len){
      shortName.push(name[i])
    }else{
      shortName.push('...')
      break
    }
  }
  let groupName = shortName.join('') 
  
  return(<span>{groupName}</span>)
}


const DirectPreview = (props) => {
  const { focusedId, result, setChannel } = props
  const { client, setActiveChannel } = useChatContext()
  const channel = result

  const members = Object.values(channel.state.members).filter(
    ({ user }) => user.id !== client.userID
  );
  members.sort((a, b) => a.user.name.localeCompare(b.user.name, 'es', {sensitivity: 'base'}))
  const defaultName = "Johnny Blaze";

  if (!members.length || members.length === 1) {
    return (
      <div onClick={() => setChannel(channel)} className={focusedId === channel.id ? 'channel-search__result-container__focused' : 'channel-search__result-container'}>
        <div className="channel-search__result-user">
          <Avatar
            image={members[0]?.user.image || undefined}
            name={members[0]?.user.name || members[0]?.user.id}
            size={24}
          />
        </div>
        <div className={`str-chat__channel-preview-messenger--right str-chat__channel-preview-end`}>
          <div className='str-chat__channel-preview-messenger--right'>
            <div className='str-chat__channel-preview-end-first-row'>
              <div className='str-chat__channel-preview-messenger--name'>
                <span>{members[0]?.user.name || members[0]?.user.id || defaultName}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div onClick={() => setChannel(channel)} className={focusedId === channel.id ? 'channel-search__result-container__focused' : 'channel-search__result-container'}>
      <div className="channel-search__result-user multi">
        <Avatar
          image={members[0].user?.image}
          name={members[0].user?.name || members[0].user?.id}
          size={24}
        />
        <span>
          <span className="bg-gray-300 p-1 py-0 text-xs rounded-md">{members.length}</span>
        </span>
      </div>
      <div className={`str-chat__channel-preview-messenger--right str-chat__channel-preview-end`}>
        <div className='str-chat__channel-preview-messenger--right'>
          <div className='str-chat__channel-preview-end-first-row'>
            <div className='str-chat__channel-preview-messenger--name'>
              {renderDmName(members)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default function ChannelSearchResult (props) {
  const { teamChannels, focusedId, loading, setChannel, setQuery } = props;
  document.addEventListener('click', () => setQuery(''));

  return(
    <div className='channel-search__results'>
      {loading && !teamChannels?.length && (
        <p className='channel-search__results-header'>
          <i>Loading...</i>
        </p>
      )}
      {!loading && !teamChannels?.length ? (
        <p className='channel-search__results-header'>
          <i>No channels found</i>
        </p>
      ) : (
        teamChannels?.map((channel, i) => (
          (channel?.type === 'team' ? <SearchResult result={channel} focusedId={focusedId} key={i} setChannel={setChannel} /> : <DirectPreview result={channel} focusedId={focusedId} key={i} setChannel={setChannel}/>)
        ))
      )}
    </div>
  )
}