import React, { Fragment, useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { CalendarIcon } from '../../../../assets';

export default function AddToCalendarButtonComponent(props){
  const { event, type } = props
  const [ addToCalendarOption, setAddToCalendarOption ] = useState(event?.more_action_detail?.filter(item => item?.key == 'add_to_calendar'))

  useEffect(() => {
    if(event){
      setAddToCalendarOption(event?.more_action_detail?.filter(item => item?.key == 'add_to_calendar'))
    }
  }, [event])

  return(
    <Fragment>
      {addToCalendarOption?.length > 0 ?
        <Fragment>
          {(type == 'preview') ?
            <button className='relative z-20 w-full text-xs font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5' onClick={() => {window.open(event?.google_cal_link, '_blank');}} data-tip data-for={`add_to_calendar_button_`+event?.id.toString()}>
              <CalendarIcon classNames={'w-4 h-4 shrink-0 -mt-0.5'}/>
              Add to Cal
            </button>
          :
            <button className='flex relative z-20 justify-center items-center border border-gray-300 rounded-full min-h-8 min-w-8' onClick={() => {window.open(event?.google_cal_link, '_blank');}} data-tip data-for={`add_to_calendar_button_`+event?.id.toString()}>
              <CalendarIcon classNames={'w-4 h-4 shrink-0 -mt-0.5'}/>
              <ReactTooltip className='max-w-xs whitespace-nowrap' id={`add_to_calendar_button_`+event?.id.toString()} place={type == 'list' ? 'top' : 'left'} effect="solid">
                {addToCalendarOption[0]?.value}
              </ReactTooltip>
            </button>
          }
        </Fragment>
      :
        null
      }
    </Fragment>
  )
}