import React, { Fragment, useState } from "react";
import { SendIcon } from "./../../../../subRequests/assets";
import { axiosGetRequest } from '../../../../subRequests/helpers/helpers';
import ReactTooltip from "react-tooltip";

export default function ResendInviteButtonComponent(props){
  const { sub, eventData, updateEventData, handleAlert, eventPreview } = props
  const [ disableResendButton, setDisableResendButton ] = useState(false)

  const handleResendInvite = (userId) => {
    setDisableResendButton(true)
    let url = `/api/v2/resend_invites?id=${eventData?.id}&inst_id=${userId}&gql_event_details=true&preview=${eventPreview}`
    axiosGetRequest(url).then((result) => {
      if(Object.keys(result).includes('success')){
        handleAlert(true, result?.success, 'success');
        setTimeout(() => { 
          updateEventData(result?.event);
        }, 3000)
      }else{
        setDisableResendButton(false);
        handleAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleAlert(false, '', ''); 
      }, 3000)
    })
  }

  return(
    <Fragment>
      {!eventPreview &&
        <button className={`hidden lg:flex items-center px-3 py-2 rounded-2xl text-sm font-semibold h-9 gap-2 border-1-c border-yellow-500 text-yellow-500 ${((sub?.show_resend_invite_button_disabled) || disableResendButton) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleResendInvite(sub?.id)} disabled={(sub?.show_resend_invite_button_disabled) || disableResendButton} data-tip data-for={`${eventData?.id}_${sub?.id}_resend_invite_tooltip`}>
          <SendIcon classNames={'w-4 h-4 transform text-yellow-500 -rotate-45 mb-0.5 ml-0.5 shrink-0'}/>
          Resend invite
        </button>
      }
      <button className={`flex ${eventPreview ? '' : 'lg:hidden'} items-center justify-center rounded-full h-6 w-6 gap-2 border-1-c border-yellow-500 text-yellow-500 ${((sub?.show_resend_invite_button_disabled) || disableResendButton) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleResendInvite(sub?.id)} disabled={(sub?.show_resend_invite_button_disabled) || disableResendButton} data-tip data-for={`${eventData?.id}_${sub?.id}_resend_invite_tooltip`}>
        <SendIcon classNames={'w-3.5 h-3.5 transform text-yellow-500 -rotate-45 mb-0.5 ml-0.5 shrink-0'}/>
      </button>
      {(sub?.show_resend_invite_button_disabled && sub?.resent_invite_left && sub?.resent_invite_left !== '') &&
        <ReactTooltip 
          className='max-w-xs text-center' 
          id={`${eventData?.id}_${sub?.id}_resend_invite_tooltip`} 
          place={eventPreview ? "left" : "top"} 
          effect="solid"
        >
          You may send another invite in {sub?.resent_invite_left}
        </ReactTooltip>
      }
    </Fragment>
  )
}