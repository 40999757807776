function getHeaders(token, subdomain){  
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj
  }  
}

export const getLocations = async (user_token, subdomain, page) => {    
  return await fetch(`/api/v3/business_locations?page=${page}&pageSize=30`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const filterLocations = async (user_token, subdomain, page, text, sortType, sortReverse, siteIds) => {    
  return await fetch(`/api/v3/get_filtered_locations?text=${text}&page=${page}&pageSize=30&sortType=${sortType}&sortReverse=${sortReverse}&siteIds=${siteIds}`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const deleteLocation = async (user_token, subdomain, loc) => {  
  return await fetch(`/api/v3/business_locations/${loc.id}`,{
    method: "DELETE",
    headers: getHeaders(user_token, subdomain)
  });
}

export const exportLocations = async (user_token, subdomain) => {
  let url = "/api/v3/export_locations";  
  return await fetch(`${url}`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const uploadCSV = async (user_token, subdomain, file) => {
  const formData = new FormData();
  formData.append(
    "uploadFile",
    file,
    file.name
  );
    
  return await fetch(`/api/v3/upload_locations`,{
    method: "POST",    
    body: formData,
    headers: {
      'Accept': 'application/vnd.netgym.v3',
      'Authorization': eval(`${user_token}`),
      'subdomain': localStorage.getItem("businessID")
    }
  });
}

export const syncLocations = async (user_token, subdomain, type, siteId) => {    
  let url = "";
  switch(type){
    case 'mbo':
      url = `/api/v3/mindbody/fetch_locations?site_id=${siteId}&time_frame=today`
      break;
    case 'marinatek': 
      url = '/api/v3/marianatek/fetch_locations'
      break;
    case 'crunch': 
      url = '/api/v3/crunch/fetch_locations'
      break;
  }
  return await fetch(url,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const processSyncLocations = async (user_token, subdomain, type, siteId, classes) => {    
  let url = "";
  switch(type){
    case 'mbo':
      url = '/api/v3/upload_selected_mbo_locations'
      break;
    case 'marinatek':
      url = '/api/v3/upload_selected_marianatek_locations'
      break;    
  }

  return await fetch(`${url}`,{
    headers: getHeaders(user_token, subdomain),
    method: "POST",
    body: JSON.stringify({ locations: classes,site_id: siteId})
  });
}

export const getStates = async (user_token, subdomain) => {    
  return await fetch(`/api/v3/get_country_states`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const getSuggestions = async (user_token, subdomain) => {    
  return await fetch(`/api/v3/get_fitness_managers`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const getRegions = async (user_token, subdomain) => {
  return await fetch(`/api/v3/business_regions`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const createLocation = async (user_token, subdomain, loc) => {  
  return await fetch(`/api/v3/business_locations`,{
    method: "POST",
    headers: getHeaders(user_token, subdomain),
    body: JSON.stringify({ business_location: loc, fitness_managers: loc.fitness_managers, business_region: {id: loc.business_region_id}})
  });
}

export const updateLocation = async (user_token, subdomain, loc) => {  
  return await fetch(`/api/v3/business_locations/${loc.id}`,{
    method: "PATCH",
    headers: getHeaders(user_token, subdomain),
    body: JSON.stringify({ business_location: loc, fitness_managers: loc.fitness_managers, business_region: {id: loc.business_region_id}})
  });
}

export const downloadData = async (user_token, subdomain, type) => {
  let url = "";
  switch(type){
    case "abc":
      url = "/api/v3/export_abc_locations"
      break;
    case "marina":
      url = "/api/v3/export_marianatek_locations"
      break;
  }
  return await fetch(`${url}`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export function hideItems(cls){
  $('.filter_items').each(function(i, obj) {
    if($(obj).hasClass(`${cls}_items`)){
      $(`.${cls}_items`).toggleClass("hidden")
    }else{
      $(obj).addClass("hidden")
    }
  });
}