import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { MESSAGE_ACTIONS, useChannelActionContext, CustomMessageActions, MessageContextValue, useMessageContext, useTranslationContext, StreamMessage, useChatContext } from 'stream-chat-react';
// import { useChannelActionContext } from '../../context/ChannelActionContext';
// import {
//   CustomMessageActions,
//   MessageContextValue,
//   useMessageContext,
// } from '../../context/MessageContext';

// import { useTranslationContext } from '../../context/TranslationContext';

// import { StreamMessage } from '../../context/ChannelStateContext';

// import { DefaultStreamChatGenerics } from '../../types/types';

const CustomMessageActionsList = (props) => {
  const { customMessageActions, message } = props;
  const customActionsArray = Object.keys(customMessageActions);

  return (
    <>
      {customActionsArray.map((customAction) => {
        const customHandler = customMessageActions[customAction];

        return (
          <button
            aria-selected='false'
            className='str-chat__message-actions-list-item str-chat__message-actions-list-item-button'
            key={customAction}
            onClick={(event) => customHandler(message, event)}
            role='option'
          >
            {customAction}
          </button>
        );
      })}
    </>
  );
};

const UnMemoizedMessageActionsBox = (props) => {
  const { getMessageActions, handleDelete, handleEdit, handleFlag, handleMute, handlePin, isUserMuted, mine, open = false, } = props;

  const { client } = useChatContext()

  const { setQuotedMessage } = useChannelActionContext('MessageActionsBox')
  const { customMessageActions, message, messageListRect } = useMessageContext('MessageActionsBox',);
  const { t } = useTranslationContext('MessageActionsBox');
  const [reverse, setReverse] = useState(false);
  const messageActions = getMessageActions();

  const checkIfReverse = useCallback(
    (containerElement) => {
      if (!containerElement) {
        setReverse(false);
        return;
      }

      if (open) {
        const containerRect = containerElement.getBoundingClientRect();

        if (mine) {
          setReverse(!!messageListRect && containerRect.left < messageListRect.left);
        } else {
          setReverse(!!messageListRect && containerRect.right + 5 > messageListRect.right);
        }
      }
    },
    [messageListRect, mine, open],
  );

  const handleQuote = () => {
    setQuotedMessage(message);

    const elements = message.parent_id
      ? document.querySelectorAll('.str-chat__thread .str-chat__textarea__textarea')
      : document.getElementsByClassName('str-chat__textarea__textarea');
    const textarea = elements.item(0);

    if (textarea instanceof HTMLTextAreaElement) {
      textarea.focus();
    }
  };

  const rootClassName = clsx('str-chat__message-actions-box', {
    'str-chat__message-actions-box--mine': mine,
    'str-chat__message-actions-box--open': open,
    'str-chat__message-actions-box--reverse': reverse,
  });

  const buttonClassName =
    'str-chat__message-actions-list-item str-chat__message-actions-list-item-button';

  const checkCurrentUserPermission = (type) => {
    const clientRole  = client.user?.user_role
    const messageUserRole = message.user?.user_role
    if(message.user?.id == client.user?.id){
      return true
    }else{
      if(type == 'edit'){
        return false
      }else{
        if(clientRole == 'account_owner' && ['account_owner', 'business_admin', 'regional_admin', 'fitness_manager', 'instructor'].includes(messageUserRole)){
          return true
        }else if(clientRole == 'business_admin' && ['business_admin', 'regional_admin', 'fitness_manager', 'instructor'].includes(messageUserRole)){
          return true
        }else if(clientRole == 'regional_admin' && ['regional_admin', 'fitness_manager', 'instructor'].includes(messageUserRole)){
          return true
        }else if(clientRole == 'fitness_manager' && ['fitness_manager', 'instructor'].includes(messageUserRole)){
          return true
        }else{
          return false
        }
      }
    }
  }

  return (
    <div className={rootClassName} data-testid='message-actions-box' ref={checkIfReverse}>
      <div aria-label='Message Options' className='str-chat__message-actions-list' role='listbox'>
        {customMessageActions && (
          <CustomMessageActionsList customMessageActions={customMessageActions} message={message} />
        )}
        {messageActions.indexOf(MESSAGE_ACTIONS.quote) > -1 && (
          <button
            aria-selected='false'
            className={buttonClassName}
            onClick={handleQuote}
            role='option'
          >
            {t('Reply')}
          </button>
        )}
        {messageActions.indexOf(MESSAGE_ACTIONS.pin) > -1 && !message.parent_id && (
          <button
            aria-selected='false'
            className={buttonClassName}
            onClick={handlePin}
            role='option'
          >
            {!message.pinned ? t('Pin') : t('Unpin')}
          </button>
        )}
        {messageActions.indexOf(MESSAGE_ACTIONS.flag) > -1 && (
          <button
            aria-selected='false'
            className={buttonClassName}
            onClick={handleFlag}
            role='option'
          >
            {t('Flag')}
          </button>
        )}
        {messageActions.indexOf(MESSAGE_ACTIONS.mute) > -1 && (
          <button
            aria-selected='false'
            className={buttonClassName}
            onClick={handleMute}
            role='option'
          >
            {isUserMuted() ? t('Unmute') : t('Mute')}
          </button>
        )}
        {(messageActions.indexOf(MESSAGE_ACTIONS.edit) > -1 && checkCurrentUserPermission('edit')) && (
          <button
            aria-selected='false'
            className={buttonClassName}
            onClick={handleEdit}
            role='option'
          >
            {t('Edit Message')}
          </button>
        )}
        {(messageActions.indexOf(MESSAGE_ACTIONS.delete) > -1 && checkCurrentUserPermission('delete')) && (
          <button
            aria-selected='false'
            className={buttonClassName}
            onClick={handleDelete}
            role='option'
          >
            {t('Delete')}
          </button>
        )}
      </div>
    </div>
  );

};

export const MessageActionsBox = React.memo(
  UnMemoizedMessageActionsBox
)