import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { defaultApprovalStyles } from '../../helpers';
import { axiosRequest } from '../../subRequests/helpers/helpers';
import { hideItems } from './helpers';
import LoadingIcon from '../../subRequests/assets/loadingIcon';
import TooltipComponent from '../account/tooltipComponent';

export default function ApprovalSettingComponent(props){
  const { business, setAlert, getAllLocationsData } = props
  const [ selectedApprovalSetting, setSelectedApprovalSetting ] = useState(business?.default_approval_style || 'manager')
  const [ updating, setUpdating ] = useState(false)

  const handleSelectedApprovalSetting = (newValue) => {
    hideItems('end_time');
    if(selectedApprovalSetting == newValue || newValue == '') return
    setSelectedApprovalSetting(newValue)
    setUpdating(true)
    let url = '/api/v3/update_locations_approval_setting'
    let data = {
      default_approval_style: newValue
    }
    axiosRequest(url, 'POST', data, 'data').then((result) => {
      setUpdating(false)
      if (result?.status == 200){
        setAlert(true, 'success', result?.success)
        if(newValue !== result?.approval_style) setSelectedApprovalSetting(result?.approval_style)
        setTimeout(() => {
          setAlert(false, '', '')
          getAllLocationsData()
        }, 3000);
      }else{
        setAlert(true, 'danger', result?.error)
        setTimeout(() => {
          setAlert(false, '', '')
        }, 3000);
      }
    })
  }

  return(
    <Fragment>
      <div className="flex items-center gap-2">
        <div className="relative filters_box w-full sm:w-72">
          <Menu as="div" className="relative block text-left ">
            <div className="relative">
              <Menu.Button className="inline-flex items-center h-9 justify-center w-full rounded-md border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 end_time_button">
                Approval Setting for All Locations
                <ChevronDownIcon className="ml-auto w-4 mr-0.5 text-gray-400" aria-hidden="true" />
              </Menu.Button>
              {updating &&
                <div className="absolute top-0 bottom-0 right-8 flex items-center justify-center opacity-50">
                  <LoadingIcon classNames={'animate-spin h-4 w-4 text-black'}/>
                </div>
              }
            </div>
            <Transition
              as={Fragment}
              show={true}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden end_time_items filter_items">
                <div className="max-h-60 overflow-y-auto">
                  <div className="py-1">
                    {defaultApprovalStyles().map((style, i) => 
                      <button key={i} className={`flex items-center text-gray-900 text-sm py-2 px-4 w-full ${selectedApprovalSetting == style?.value ? 'bg-gray-200 cursor-default' : 'cursor-pointer hover:bg-gray-100'}`} onClick={() => handleSelectedApprovalSetting(style?.value)}>
                        {style?.label}
                      </button>  
                    )} 
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <TooltipComponent componentId={`approval_setting_for_all_location`} classNames={'w-4 h-4'} tooltipMessage={'This is the default approval setting for all locations. Once set, the default approval setting will be applied to all existing and new locations. You can then update each location individually with a different approval setting if needed. Only the account owner can edit the default setting.'}/>
      </div>
    </Fragment>
  )
}