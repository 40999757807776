import React, { Component } from 'react'
import { getSudomain, setTitle, getHeaders } from './../../helpers'
import Cookies from 'js-cookie';

export default class CrunchDetails extends Component {
  constructor(props){
    super(props);
    this.state = {
      business: props.business,
      show: false,
      alert_type: '',
      alert_message: '',
      group: props.business?.crunch_ownership_group,
      clubs: props.business?.crunch_club_ids?.join(', ') || '',
      club_type: props.business?.crunch_club_type || '',
      showError: false
    }
  }

  componentDidMount = () => {
    setTitle('crunch')
  }

  save = (e) => {
    let club_type = this.state.club_type
    if(!club_type || club_type === ''){
      this.setState({showError: true})
      return
    }
    e.preventDefault()
    const requestOptions = {
      method: 'POST',
      headers: getHeaders(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()),
      body: JSON.stringify({ club_number: this.state.group, club_ids: this.state.clubs.toString(), club_type: this.state.club_type })
    };
    fetch('/api/v3/crunch/save_ownership_groups', requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: result.success,
          alert_type: 'success',
          show: true
        })
        setTimeout(function(){ window.location.reload() }, 1000);
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  syncUsers = (e) => { 
    e.preventDefault() 
    fetch('/api/v3/crunch/sync_users',{
      method: "GET",
      headers: getHeaders(Cookies.get(`currentUser.${getSudomain()}`), getSudomain())   
    })
    .then(response => response.json())
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: "Your users will be synced shortly",
          alert_type: 'success',
          show: true
        })
        // setTimeout(function(){ window.location.reload() }, 1000);
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  syncLocations = (e) => { 
    e.preventDefault() 
    fetch('/api/v3/crunch/sync_locations',{
      method: "GET",
      headers: getHeaders(Cookies.get(`currentUser.${getSudomain()}`), getSudomain())   
    })
    .then(response => response.json())
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: "Your locations will be synced shortly",
          alert_type: 'success',
          show: true
        })
        // setTimeout(function(){ window.location.reload() }, 1000);
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  syncClasses = (e) => { 
    e.preventDefault() 
    fetch('/api/v3/crunch/sync_classes',{
      method: "GET",
      headers: getHeaders(Cookies.get(`currentUser.${getSudomain()}`), getSudomain())   
    })
    .then(response => response.json())
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: "Your classes will be synced shortly",
          alert_type: 'success',
          show: true
        })
        // setTimeout(function(){ window.location.reload() }, 1000);
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  render() {
    const { business, show, alert_type, alert_message, group, clubs, club_type, showError } = this.state

    return (
      <React.Fragment>
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto" onClick={(e) => this.setState({show: false})}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto" onClick={(e) => this.setState({show: false})}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
        <div className='w-full min-h-screen bg-gray-50'>
          <div className='h-full'>
            <div className='max-w-8xl mx-auto h-full'>
              <div className='flex gap-4 justify-between'>
                <div className='left-0'>
                  <h3 className='flex gap-3 text-lg'>
                    Add Crunch Details
                  </h3>
                </div>
                <div className='right-0'>
                </div>
              </div>

              <div className='mt-3 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4'>
                <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                  <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                    <div className="bg-white px-3 py-2">
                      <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-5'>
                        <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">OwnerShip Group ID
                        </label>
                        <div className='mt-1 sm:mt-0 sm:col-span-2'>
                          <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm numeric" placeholder='Enter an OwnerShip Group ID' onChange={(e) => this.setState({group: e.target.value})} defaultValue={group}/>
                        </div>
                      </div>
                      <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-5 border-t border-gray-200'>
                        <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Club ID(s)
                        </label>
                        <div className='mt-1 sm:mt-0 sm:col-span-2'>
                          <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm" placeholder='Enter Club ID(s)' onChange={(e) => this.setState({clubs: e.target.value})} defaultValue={clubs}/>
                        </div>
                      </div>
                      <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-5 border-t border-gray-200'>
                        <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Club Type*
                        </label>
                        <div className='mt-1 sm:mt-0 sm:col-span-1'>
                          <select defaultValue={club_type} name="club_type" onChange={(e) => this.setState({club_type: e.target.value})} className='form-select lg:w-80 bg-white block w-full pl-3 pr-10 py-2 h-9 text-base border border-gray-300  sm:text-sm rounded-md shadow-sm'>
                            <option value="">Select a Club Type</option>
                            <option value="base">Base</option>
                            <option value="select">Select</option>
                            <option value="signature">Signature</option>
                            <option value="base or select">Base or Select</option>
                            <option value="base or signature">Base or Signature</option>
                            <option value="select or signature">Select or Signature</option>
                            <option value="base or select or signature">Base or Select or Signature</option>
                          </select>  
                          {(showError && club_type === '') &&
                            <span className="error text-xs">*Club Type is required</span>
                          }
                        </div>
                      </div>
                      <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-5'>
                        <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2"></label>
                        <div className='mt-1 sm:mt-0 sm:col-span-2'>
                          <div>
                            <a className="bg-dark-blue text-white inline-block px-4 py-2 ml-auto border shadow rounded-md cursor-pointer" onClick={(e) => this.save(e)}>
                              Save Crunch Details
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex gap-3 mt-4'>
                    <a className="bg-dark-blue text-white inline-block px-4 py-2 border shadow rounded-md cursor-pointer" onClick={(e) => this.syncUsers(e)}>
                      Sync Users
                    </a>
                    <a className="bg-dark-blue text-white inline-block px-4 py-2 border shadow rounded-md cursor-pointer" onClick={(e) => this.syncClasses(e)}>
                      Sync Classes
                    </a>
                    <a className="bg-dark-blue text-white inline-block px-4 py-2 border shadow rounded-md cursor-pointer" onClick={(e) => this.syncLocations(e)}>
                      Sync Locations
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
