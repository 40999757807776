import React, { Fragment, useState } from "react";
import { axiosRequest } from "../../../subRequests/helpers/helpers";
import PicUploaderComponent from './picUploaderComponent';

export default function ProfilePicComponent(props){
  const { user, setAlert, handleUpdateUser, updateUserDataAndRedirect } = props
  const [ btnDisabled, setBtnDisabled ] = useState(false)

  const completeTheSignUp = (e) => {
    e.preventDefault()
    setBtnDisabled(true)
    let url = '/api/v3/complete_activation'
    axiosRequest(url, 'POST', {}, 'data').then(result => {
      if(result.status == 200){
        setAlert(true, 'success', result?.success)
        setTimeout(() => {
          setAlert(false, '', '')
          setBtnDisabled(false);
        }, 3000);
        updateUserDataAndRedirect(result?.user, '/my-requests')
      }else{
        setAlert(true, 'danger', result?.error)
        setTimeout(() => {
          setAlert(false, '', '')
        }, 3000);
        setBtnDisabled(false);
      }
    })
  }

  return(
    <Fragment>
      <div className="w-full flex flex-col items-center justify-center mt-4 sm:mt-32 px-2">
        <div className="flex items-center justify-center text-center font-light text-lg text-gray-600">
          Upload a Profile Picture
        </div>
        <div className="flex items-center justify-center text-center mt-4">
          <PicUploaderComponent
            user={user}
            setAlert={setAlert}
            setBtnDisabled={setBtnDisabled}
          />
        </div>
        <div className='flex flex-col items-center justify-center w-full mt-7 gap-2'>
          <button
            className={`bg-gray-900 rounded-md text-white flex items-center justify-center px-8 py-2 text-sm w-1/2 md:w-1/5 ${btnDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={(e) => completeTheSignUp(e)}
            disabled={btnDisabled}
          >
            Done
          </button>
        </div>
      </div>
    </Fragment>
  )
}