import React, { Fragment } from "react";
import CustomPopupContainerComponent from './../customPopupContainerComponent';

export default function ClassDescriptionPopupComponent(props) {
  const { classData, setShowClassDescription, showClassDescription } = props;

  return (
    <Fragment>
      <CustomPopupContainerComponent
        show={showClassDescription}
        close={setShowClassDescription}
        title={""}
        customWidth={"sm:max-w-sm"}
      >
        <div className="text-lg text-center leading-6 font-semibold text-gray-900 px-7">{classData?.name || ""}</div>
        <div className="px-4 font-open-sans">
          <div className="text-sm text-center px-2 text-gray-700 mt-3">
            {classData?.description}
          </div>
          <div className="mt-5 flex gap-3 flex justify-center">
            <button
              className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-24 py-2 text-sm`}
              onClick={() => setShowClassDescription(false)}
            >
              OK
            </button>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  );
}