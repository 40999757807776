import React, { Fragment, useState } from 'react';
import XCircleIcon from './../../../../../messenger/pages/chatComponents/assets/xCircleIcon'
import { Dialog, Menu, Transition } from '@headlessui/react'
import CustomPopup from './../../../../assets/customPopup'

export default function EditPassword(props){
  const { editPassword, setEditPassword, processUpdatePassword } = props
  const [ currentPassword, setCurrentPassword ] = useState('')
  const [ newPassword, setNewPassword ] = useState('')
  const [ confirmPassword, setConfirmPassword ] = useState('')
  const [ showCurrentPassword, setShowCurrentPassword ] = useState(false)
  const [ showNewPassword, setShowNewPassword ] = useState(false)
  const [ showConfirmPassword, setShowConfirmPassword ] = useState(false)
  const [ submitted, setSubmitted ] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    if(currentPassword == ""){
      console.log("Error editing My-profile");
      return
    }

    if (newPassword !== "" && confirmPassword !== "") {
      if(newPassword?.split("")?.length >= 6 && confirmPassword?.split("")?.length >= 6){
        if(!checkConfirmPassword()){
          let data = {
            current_password: currentPassword,
            new_password: newPassword,
            confirm_password: confirmPassword
          }
          processUpdatePassword(data)
        }
      } else {
        console.log("Error editing My-profile");
      }
    } else {
      console.log("Error editing My-profile");
    }
  }

  const checkConfirmPassword = () => {
    if(newPassword?.split("")?.length >= 6 && confirmPassword?.split("")?.length >= 6 && newPassword !== confirmPassword){
      return true
    } else {
      return false
    }
  }

  const closeEditModal = (data) => {
    setEditPassword(data)
  }


  return(
    <Fragment>
      <CustomPopup show={editPassword} close={closeEditModal} title={"Update Password"}>
        <Fragment>
          <div className="mt-3 border-t border-gray-200 h-64">
            <div className='flex sm:gap-4 sm:items-center pt-2 px-7'>
              <label htmlFor="first-name" className="block text-sm text-gray-500 sm:mt-px pb-2 w-36">
                Current Password
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="lg:w-80 relative messenger-box py-1.5">
                  <input type={showCurrentPassword ? 'text' : 'password'} id ="currentPassword" placeholder='Enter Current Password'  className="border rounded-md pl-2 pr-9 py-1.5 w-full shadow-sm" name="currentPassword" value = {currentPassword} onChange={(e) => setCurrentPassword(e.target.value)}/>
                  {!showCurrentPassword &&
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 absolute right-3 top-4" onClick={() => setShowCurrentPassword(true)}>
                      <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                      <path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clipRule="evenodd" />
                    </svg>
                  }
                  {showCurrentPassword &&
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 absolute right-3 top-4" onClick={() => setShowCurrentPassword(false)}>
                      <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
                      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
                      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
                    </svg>
                  }
                </div>
                {submitted && currentPassword == '' &&
                  <span className="error text-xs">Please enter Current Password.</span>
                }
              </div>
            </div>
            <div className='flex sm:gap-4 sm:items-center px-7'>
              <label htmlFor="first-name" className="block text-sm text-gray-500 sm:mt-px pb-2 w-36">
                New Password
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="lg:w-80 relative messenger-box py-1.5">
                  <input type={showNewPassword ? 'text' : 'password'} id ="newPassword" placeholder='Enter New Password'  className="border rounded-md pl-2 pr-9 py-1.5 w-full shadow-sm" name="newPassword" value = {newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                  {!showNewPassword &&
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 absolute right-3 top-4" onClick={() => setShowNewPassword(true)}>
                      <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                      <path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clipRule="evenodd" />
                    </svg>
                  }
                  {showNewPassword &&
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 absolute right-3 top-4" onClick={() => setShowNewPassword(false)}>
                      <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
                      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
                      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
                    </svg>
                  }
                </div>
                {(newPassword !== "" && newPassword?.split("")?.length < 6) && 
                  <span className="error text-xs">*Password should be minimum 6 characters long.</span>
                }
                {submitted && !newPassword && (
                  <span className="error text-xs">*New Password is required</span>
                )}
              </div>
            </div>
            <div className='flex sm:gap-4 sm:items-center px-7'>
              <label htmlFor="first-name" className="block text-sm text-gray-500 sm:mt-px pb-2 w-36">
              Confirm Password
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="lg:w-80 relative messenger-box py-1.5">
                  <input type={showConfirmPassword ? 'text' : 'password'} id ="confirmPassword" placeholder='Enter New Password'  className="border rounded-md pl-2 pr-9 py-1.5 w-full shadow-sm" name="confirmPassword" value = {confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                  {!showConfirmPassword &&
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 absolute right-3 top-4" onClick={() => setShowConfirmPassword(true)}>
                      <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                      <path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clipRule="evenodd" />
                    </svg>
                  }
                  {showConfirmPassword &&
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 absolute right-3 top-4" onClick={() => setShowConfirmPassword(false)}>
                      <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
                      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
                      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
                    </svg>
                  }
                </div>
                {(confirmPassword !== "" && confirmPassword?.split("")?.length < 6) &&                   
                  <span className="error text-xs">*Password should be minimum 6 characters long.</span>
                }
                {submitted && !confirmPassword && (
                  <span className="error text-xs">*Password Confirmation is required</span>
                )}
                {submitted && checkConfirmPassword() && (
                  <span className="error text-xs">*Confirm Password do not match</span>
                )}
              </div>
            </div>
          </div>
          <div className='flex sm:gap-4 sm:items-center px-7 justify-end mb-4'>
            <button className={`flex items-center px-6 py-2.5 gap-1 border border-gray-300 shadow-sm font-medium rounded-md bg-gray-800 `} onClick={(e) => handleSubmit(e)}>
              <div className="flex items-center gap-1 relative">
                <div className='text-white text-base'>Save</div>
              </div>
            </button>
          </div>
        </Fragment>
      </CustomPopup>
    </Fragment>
  )
}