import React, { Fragment, useState } from 'react';
import EyeIcons from '../../../signIn/assets/eyeIcons'
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import { checkTheSignUpPagePath } from '../../helpers';

export default function UserInfoComponent(props) {
  const { user, updateUserDataAndRedirect, setAlert, handleUpdateUser } = props
  const [ newPassword, setNewPassword ] = useState('')
  const [ confirmPassword, setConfirmPassword ] = useState('')
  const [ showPassword, setShowPassword ] = useState(false)
  const [ isPasswordTouched, setIsPasswordTouched ] = useState(false);
  const [ showConfirmPassword, setShowConfirmPassword ] = useState(false)
  const [ isConfirmPasswordTouched, setIsConfirmPasswordTouched ] = useState(false);
  const [ submited, setSubmited ] = useState(false);
  const [ termsAndConditions, setTermsAndConditions ] = useState(false);
  const [ disableSubmit, setDisableSubmit ] = useState(false);

  const handleBlur = (e) => {
    let name = e.target.name
    if(name == 'confirmPassword'){
      setIsConfirmPasswordTouched(true);
    }else if(name == 'newPassword'){
      setIsPasswordTouched(true);
    }
  };

  const handleChange = (e) => {
    let name = e.target.name
    if(name == 'confirmPassword'){
      setConfirmPassword(e.target.value);
    }else if(name == 'newPassword'){
      setNewPassword(e.target.value);
    }
  }

  const checkConfirmPassword = () => {
    if(newPassword?.split("")?.length >=6 && newPassword !== confirmPassword){
      return true
    } else {
      return false
    }
  }

  const checkDisabledConditions = () => {
    return (!termsAndConditions || disableSubmit || checkConfirmPassword() || (newPassword == '' || (newPassword !== "" && newPassword?.split("")?.length < 6)) || confirmPassword == '')
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmited(true);
    setDisableSubmit(true);
    let params = {
      password: newPassword
    }
    let url = '/api/v3/create_password'
    axiosRequest(url, 'POST', params, 'data').then(result => {
      if(result.status == 200){
        setAlert(true, 'success', result?.success)
        setTimeout(() => {
          setAlert(false, '', '')
          setDisableSubmit(false);
        }, 3000);
        if(result.user?.active){
          updateUserDataAndRedirect(result?.user, '/my-requests')
        } else {
          handleUpdateUser(result?.user, checkTheSignUpPagePath(result?.user))
        }
      }else{
        setAlert(true, 'danger', result?.error)
        setTimeout(() => {
          setAlert(false, '', '')
        }, 3000);
        setDisableSubmit(false);
      }
    })
  }
  
  return(
    <Fragment>
      <div className="w-full flex flex-col items-center justify-center mt-4">
        <div className="w-full flex items-center justify-center mb-4">
          <h2 className="text-center font-light text-3xl">Create Account</h2>
        </div>
        <div className="w-full px-0 lg:px-10 lg:w-2/5 flex flex-col items-center justify-center gap-3">
          <div className='flex items-center w-full'>
            <input type="text" className="border rounded-md px-4 py-1.5 w-full text-sm shadow-sm h-10 cursor-not-allowed text-gray-500" placeholder="Enter Your First Name" value={user?.first_name} name="firstName" disabled/>
          </div>
          <div className='flex items-center w-full'>
            <input type="text" className="border rounded-md px-4 py-1.5 w-full text-sm shadow-sm h-10 cursor-not-allowed text-gray-500" placeholder="Enter Your Last Name" value={user?.last_name} name="lastName" disabled/>
          </div>
          <div className='flex items-center w-full'>
            <input type="text" className="border rounded-md px-4 py-1.5 w-full text-sm shadow-sm h-10 cursor-not-allowed text-gray-500" placeholder="Enter Your Role" value={user?.role_display} name="role" disabled/>
          </div>
          <div className='flex flex-col items-start w-full relative'>
            <input
              type={showPassword ? 'text' : 'password'}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm h-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none text-sm px-4 pr-8"
              id="newPassword"
              name="newPassword"
              placeholder="Enter Password"
              value={newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <EyeIcons open={showPassword} classNames={'w-5 h-5 absolute right-3 top-2.5'} setOpen={setShowPassword}/>
            {(submited || isPasswordTouched) && newPassword == '' &&
              <span className="text-xs text-red-500 mt-1">Please enter Password</span>
            }
            {(newPassword !== "" && newPassword?.split("")?.length < 6) && 
              <span className="text-xs text-red-500 mt-1">Password should be minimum 6 characters long.</span>
            }
          </div>
          <div className='flex flex-col items-start w-full relative'>
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm h-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none text-sm px-4 pr-8"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <EyeIcons open={showConfirmPassword} classNames={'w-5 h-5 absolute right-3 top-2.5'} setOpen={setShowConfirmPassword}/>
            {(submited || isConfirmPasswordTouched) && confirmPassword == '' &&
              <span className="text-xs text-red-500 mt-1">Please enter confirm Password</span>
            }
            {(submited || isConfirmPasswordTouched) && checkConfirmPassword() && (
              <span className="text-xs text-red-500 mt-1">Passwords do not match. Please try again.</span>
            )}
          </div>
          <div className='flex items-center justify-start w-full'>
            <label htmlFor="terms_and_conditions_checkbox" className="flex text-gray-900 text-sm mb-2 cursor-pointer flex-wrap gap-2">
              <input id={`terms_and_conditions_checkbox`} type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mt-0.5 cursor-pointer" onChange={(e) => setTermsAndConditions(!termsAndConditions)} checked={termsAndConditions}/>
                I have read and agree to the <a href="/#/terms" target='_blank' className='text-blue-500'>Terms of Use</a> and <a href="/#/privacy_policy" target='_blank' className='text-blue-500'>Privacy Policy</a>
            </label> 
          </div>
        </div>
        <div className='flex items-center justify-center w-full mt-4'>
          <button
            className={`bg-gray-900 rounded-md text-white flex items-center justify-center px-8 py-2 text-sm w-1/2 md:w-1/5 ${(checkDisabledConditions()) ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={(e) => handleSubmit(e)}
            disabled={(checkDisabledConditions())}
          >
            Next
          </button>
        </div>
      </div>
    </Fragment>
  )
}