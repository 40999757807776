import React, { Fragment, useState } from 'react';
import { Avatar } from 'stream-chat-react';
import { debounce } from "lodash";
import ConfirmCreateDmPopup from './confirmCreateDmPopup'
import { createGroup } from './../../helpers'
import Cookies from 'js-cookie';
import { getSudomain } from '../../../../helpers'

export default function AudienceModal (props) {
	const { audienceList, handleShowAlertPopup, changeChannel } = props
	const [ allAudienceList, setAllAudienceList ] = useState(audienceList)
	const [ searchAudience, setSearchAudience ] = useState('')
	const [ showCreateDmPopup, setShowCreateDmPopup ] = useState(false)
	const [ createDmUser, setCreateDmUser ] = useState({})
	const [ createDmBtnDisabled, setCreateDmBtnDisabled ] = useState(false)

	const handleSearchAudience = (e) => {
		let val = e.target.value
		setSearchAudience(val)
		serachUserData(val)
	}

	const serachUserData = debounce(val => {
		performSearchUsers(val)
	}, 800);

	const performSearchUsers = (val) => {
		const searchTerm = val.trim()
    const usersList = audienceList
    if(searchTerm === ''){
      setAllAudienceList(usersList)
    }else{
      var foundUsers = usersList.filter(function (entry) {
        return (entry.full_name.toLowerCase().includes(searchTerm.toLowerCase()));
      });
        setAllAudienceList(foundUsers)
    }
	}

	const handleShowCreateDmPopup = (user) => {
		setShowCreateDmPopup(true)
		setCreateDmUser(user)
	}

	const closeCreateDmPopup = () => {
		setShowCreateDmPopup(false)
		setCreateDmUser({})
	}

	const handleCreateDmPopup = () => {
		setCreateDmBtnDisabled(true)
    
    if(!createDmUser?.id || createDmUser?.id == '' || createDmUser?.id == 0) return
    let receivers = []
    receivers.push(createDmUser?.id)
    const formData = new FormData()

    formData.append('group_data[message]', '');
    formData.append('group_data[type]', 'direct_message');
    formData.append('group_data[receivers]', receivers);
    formData.append('group_data[is_private]', true);
    
    createGroup(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), formData).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				setShowCreateDmPopup(false)
        changeChannel(result?.id)
			}else{
				handleShowAlertPopup(true, 'danger', result.error)
				setCreateDmBtnDisabled(false)
				
				window.setTimeout(()=>{
					handleShowAlertPopup(false, '', '')
				},3000)
			}
		})
	}

	return (
		<Fragment>
			{showCreateDmPopup && (
				<ConfirmCreateDmPopup show={showCreateDmPopup} close={closeCreateDmPopup} createDmUser={createDmUser} title={"Create Direct Message"} createDmBtnDisabled={createDmBtnDisabled} processCreateDm={handleCreateDmPopup}/>
			)}
			<div className='px-6'>
				<div className='flex flex-wrap gap-3 items-center mb-3'>
					<div className='item-center'>
						<a className={`flex items-center gap-x-1.5 text-sm px-1 rounded-md text-gray-700`}>
							<svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
							</svg>
							View Audience ({audienceList?.length})
						</a>
					</div>
					<div className='relative'>
						<div className='absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none h-10'>
							<svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
							</svg>
						</div>
						<input type='text' placeholder='Search audience' className='h-10 flex justify-center w-52 rounded border border-gray-300 shadow-sm pl-7 pr-2 py-2 bg-white text-sm focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500' name="searchAudience" value={searchAudience} onChange={(e) => handleSearchAudience(e)}/>
					</div>
				</div>
				{allAudienceList?.length > 0 ?
					<div className='justify-between pt-3 max-h-72 overflow-y-auto pr-4 min-h-388'>
						{allAudienceList.map((user, i) =>
							<div key={i} className='flex justify-between pb-3'>
								{user?.create_dm_permission ?
									<div className='flex items-center gap-x-1.5 cursor-pointer' onMouseDown={(e) => handleShowCreateDmPopup(user)}>
										{user?.image !== '' ?
											<Avatar
												image={user?.image || undefined}
												name={user?.full_name || user?.id}
												size={24}
											/>
											:
											<div className='w-6 h-6 rounded-full bg-gray-500 flex items-center justify-center relative mr-2'>
												<div className='text-white text-xs'>{user?.name_image}</div>
											</div>
										}
										<div className='bg-purple-100 text-purple-800 py-0.5 px-2.5 flex items-center rounded-xl'>
											<div className='text-sm pr-1 underline'>{user?.full_name}</div>
										</div>
									</div>
									:
									<div className='flex items-center gap-x-1.5'>
										{user?.image !== '' ?
											<Avatar
												image={user?.image || undefined}
												name={user?.full_name || user?.id}
												size={24}
											/>
											:
											<div className='w-6 h-6 rounded-full bg-gray-500 flex items-center justify-center relative mr-2'>
												<div className='text-white text-xs'>{user?.name_image}</div>
											</div>
										}
										<div className='bg-purple-100 text-purple-800 py-0.5 px-2.5 flex items-center rounded-xl'>
											<div className='text-sm pr-1'>{user?.full_name}</div>
										</div>
									</div>
								}
							</div>
						)}
					</div>
					:
					<div className='text-center text-sm pt-3 justify-center items-center flex h-64 min-h-388 text-gray-500'>
						No audience found.
					</div>
				}
			</div>
		</Fragment>
	)
}