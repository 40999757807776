import React, { useState, useEffect, Fragment } from 'react'
import { Channel, useChatContext, useChannelActionContext } from 'stream-chat-react'
import ChannelBody from './channelBody'
import styled from 'styled-components'
import ChannelEmptyState from './channelEmptyState'
import CustomMessageInput from './customMessageInput/customMessageInput' 
import CustomMessage from './customMessage/customMessage'
import CustomThreadHeader from './customThread/customThreadHeader'
import { DateSeparator } from './customMessage/customDateSeparator/customDateSeparator'
import CustomSystemMessage from './customSystemMessage/customSystemMessage'
import { Redirect } from 'react-router-dom';

const Container = styled.div`
    height: 100%;
  `;

export default function CustomChannel({selChannel, user, permissions}) {
  const { client, channel :  activeChannel, setActiveChannel } = useChatContext()
  const [ channel, setChannel ] = useState(selChannel)
  const [ redirect, setRedirect ] = useState(false)
  const [ redirectTo, setRedirectTo ] = useState('')
  const dataFormat = user?.date_format_web

  const sendMessageDataForNotification = (param) => {
    if(activeChannel){
      if(param.channel.id == activeChannel.id && param.channel.disabled == true){
        switchedChannelAfterDisable(param.channel.id, 'disable')
      }
    }else{
      if(param.channel.id == selChannel.id && param.channel.disabled == true){
        switchedChannelAfterDisable(param.channel.id, 'disable')
      }
    }
  }

  const switchedChannelAfterDisable = async (deletedChannelId, type) => {
    const filter = {
      $or: [
        {
          type: 'team',
          members: { $in: [client.user.id] },
          disabled: {$eq: false}
        },
        {
          type: 'messaging',
          members: { $in: [client.user.id] },
          disabled: {$eq: false},
          last_message_at: { $exists: true }
        },
      ],
    };
    const channels = await client.queryChannels(filter);
    if(channels.length > 0){
      let redirectChannel = channels[0]
      if (channels[0]?.id === deletedChannelId){
        redirectChannel = channels[1]
      }
      let chnl = client.channel(redirectChannel?.type, redirectChannel?.id, {})
      setActiveChannel(chnl)
      letWatchChannel(chnl)
      if(type == 'hide'){
        window.history.replaceState(`path:messenger/chat-room/${chnl?.id}`, '', `/messenger/chat-room/${chnl?.id}`)
      }else{
        setRedirectTo(`/messenger`)
        setRedirect(true)
      }
    }else{
      setRedirectTo(`/messenger`)
      setRedirect(true)
    }
  }

  const letWatchChannel = async (chnl) => {
    await chnl.watch()
  }
  
  useEffect(() => {
    if(activeChannel){
      setChannel(activeChannel)
      letWatchChannel(activeChannel)
    }else{
      setActiveChannel(selChannel)
      letWatchChannel(selChannel)
    }
  })

  useEffect(() => {
    channel.on('channel.updated', sendMessageDataForNotification);

    return () => {
      channel.off('channel.updated', sendMessageDataForNotification);
    };
  }, [channel]);

  if(!channel) <div>Loading ...</div>

  return(
    <Container>
      {redirect && 
        <Redirect to={redirectTo} />
      }
      <Channel channel={channel} Input={CustomMessageInput} Message={(previewProps) => (<CustomMessage {...previewProps} dataFormat = {dataFormat} />)} ThreadHeader={CustomThreadHeader} EmptyStateIndicator={ChannelEmptyState} DateSeparator={(previewProps) => (<DateSeparator {...previewProps} dataFormat = {dataFormat}/>)} MessageSystem={(previewProps) => (<CustomSystemMessage {...previewProps} dataFormat = {dataFormat}/>)}>
        <ChannelBody user={user} permissions={permissions} switchedChannelAfterDisable={switchedChannelAfterDisable}/>
      </Channel>
    </Container>
  )
}