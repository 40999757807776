import React, { useState } from "react";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { getCountriesAbbr, getPhoneNumberValue } from '../../../helpers'; 

export default function PersionalInfoEdit(props){
  const { user, business, handleEditPersionalInfo, timeZones, states, setInformationData, loaded, imageUploading } = props
  const [ firstName, setFirstName ] = useState(user?.first_name)
  const [ lastName, setLastName ] = useState(user?.last_name)
  const [ email, setEmail ] = useState(user?.email)
  const [ timeZone, setTimeZone ] = useState(user?.time_zone_string)
  const [ employeeId, setEmployeeId ] = useState(user?.employee_id == null ? '' : user?.employee_id)
  const [ city, setCity ] = useState(user?.city)
  const [ state, setState ] = useState(user?.state)
  const [ zipcode, setZipcode ] = useState(user?.zipcode)
  const [ disabledSaveAvailableBtn, setDisabledSaveAvailableBtn ] = useState(false)
  const [ profileImage, setProfileImage ] = useState('')
  const [ submitted, setSubmitted ] = useState(false)
  const countryList = getCountriesAbbr()
  const [ phoneNumber, setPhoneNumber ] = useState(getPhoneNumberValue(user?.directory_phone_number))
  const [ validPhoneNumber, setValidPhoneNumber ] = useState(user?.directory_phone_number == '' ? true : (parsePhoneNumber(user?.directory_phone_number) && countryList.includes(parsePhoneNumber(user?.directory_phone_number).country) && isValidPhoneNumber(user?.directory_phone_number.startsWith('+') ? user?.directory_phone_number : '+'+user?.directory_phone_number) === true))
  const [ defaultCountry, seDefaultCountry ] = useState('US')

  const handleSaveInfo = (e) => {
    e.preventDefault();
    setSubmitted(true);

    if((email !== "" && email.match("[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?") && ((city !== "" && city.match(/^[A-Za-z\s]*$/)) || city == "") && ((zipcode !== "" && zipcode?.split("")?.length > 2 && zipcode?.split("")?.length <= 6) || zipcode == "" ) && validPhoneNumber)){
      const formData = new FormData()
      formData.append('city', city);
      formData.append('email', email);
      formData.append('employee_id', employeeId);
      formData.append('first_name', firstName);
      formData.append('last_name', lastName);
      formData.append('state', state);
      formData.append('time_zone', timeZone);
      formData.append('zipcode', zipcode);
      formData.append('directory_phone_number', phoneNumber);
      setInformationData(formData, 'persional_info')
    } 
    else {
      console.log("Error editing User");
    }
  }

  const handleUploadProfileImage = (e) => {
    if(e.target.files && e.target.files?.length > 0){     
      setProfileImage(e.target.files[0])
      uploadProfilePic(e.target.files[0])
    }
  }

  const uploadProfilePic = (data) => {
    const formData = new FormData()
    formData.append('file', data);
    formData.append('filename', data?.name);
    setInformationData(formData, 'pic')
  }

  const handleSetPhoneNumber = (value) => {
    if (value){
      let phoneNumber = parsePhoneNumber(value)
      if(phoneNumber && countryList.includes(phoneNumber.country) && isValidPhoneNumber(value) === true){
        setValidPhoneNumber(true)
      }else{
        setValidPhoneNumber(false)
      }
      setPhoneNumber(value)
    }else{
      setValidPhoneNumber(true)
      setPhoneNumber('')
    }
  }

  const handleCountryChange = (value) => {
    seDefaultCountry(value)
  }

  return(
    <React.Fragment>
      <div className='bg-white flex flex-col rounded-md shadow px-6 py-7 mb-5'>
        <div className="flex items-center mb-4 pb-1">
          <div className="text-lg font-medium flex-auto">Personal Information</div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Profile Pic
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-5 items-center'>
            <div className="relative">
              {profileImage === "" ? 
                  <img src={user?.image} className="w-16 h-16 rounded-full"/>
                :
                  <React.Fragment>
                    <img className={`max-w-full rounded-full ${imageUploading && !loaded ? 'opacity-50' : ''}`} style={{width: '64px', height: '64px', flexBasis: `${50}px`, objectFit: 'cover',}} src={URL.createObjectURL(profileImage)}/>
                    {imageUploading && !loaded &&
                      <div className="flex items-center left-6 top-6 justify-center absolute">
                        <svg className="animate-spin h-4 w-4 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </div>
                    }
                  </React.Fragment>
              }
            </div>
            <div className={`border rounded-md border-gray-300 shadow-sm text-gray-700 text-sm py-2 px-4 relative h-9 leading-4 items-center justify-center w-34 flex gap-1 hover:bg-gray-100 ${!loaded ? 'button-disabled bg-gray-100' : ''}`}>
              Change
              <input type="file" className={`absolute w-full top-0 left-0 bottom-0 opacity-0`} accept=".jpg, .jpeg, .png" onChange={(e) => handleUploadProfileImage(e)} disabled={!loaded}></input>
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            First Name
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <div className='text-sm font-normal flex-col flex'>
              <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full text-sm shadow-sm h-10 cursor-not-allowed" placeholder="Enter Your First Name" value={firstName} name="name" onChange={(e) => setFirstName(e.target.value)} disabled/>
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Last Name
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <div className='text-sm font-normal flex-col flex'>
              <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full text-sm shadow-sm h-10 cursor-not-allowed" placeholder="Enter Your Last Name" value={lastName} name="lastName" onChange={(e) => setLastName(e.target.value)} disabled/>
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Email Address
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <div className='text-sm font-normal flex-col flex'>
              <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full text-sm shadow-sm h-10" placeholder="Enter Your Email" value={email} name="email" onChange={(e) => setEmail(e.target.value)} />
              {(email !== "" && submitted && !email.match("[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?") ) && 
                <span className="error text-xs">*Enter Valid Email format.</span>
              }
              {submitted && email == "" && (
                <span className="error text-xs">*Please enter email.</span>
              )}
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Phone Number
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <div className="lg:w-80 rounded-md w-full text-sm shadow-sm">
              <PhoneInput
                className="border rounded-md pl-2 pr-2 py-1.5 w-full h-10"
                value={phoneNumber}
                onChange={handleSetPhoneNumber}
                defaultCountry={defaultCountry}
                international
                withCountryCallingCode
                countryCallingCodeEditable={false}
                id="phone-input"
                placeholder='+1 201-555-5555'
                countries={countryList}
                onCountryChange={handleCountryChange}
              />
              {submitted && !validPhoneNumber && (
                <span className="error text-xs">*Please enter valid phone number.</span>
              )}
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Time Zone
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <div className='text-sm font-normal flex-col flex'>
              <select className='form-select lg:w-80 bg-white mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-sm rounded-md h-10' defaultValue={timeZone} name="timeZone" onChange={(e)=>setTimeZone(e.target.value)}>
                <option value="">Select Your Time Zone</option>
                {timeZones.map((zone,i) =>
                  <option key={i} value={zone.key}>{zone.value}</option>
                )}
              </select>
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Employee Id
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <div className='text-sm font-normal flex-col flex'>
              <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full text-sm shadow-sm h-10 cursor-not-allowed" placeholder="Enter Your Employee Id" value={employeeId} name="employeeId" onChange={(e) => setEmployeeId(e.target.value)} disabled/>
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            City
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <div className='text-sm font-normal flex-col flex'>
              <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full text-sm shadow-sm h-10" placeholder="Enter Your City" value={city} pattern='/^[A-Za-z\s]*$/' name="city" onChange={(e) => setCity(e.target.value)}/>
              {submitted && city !== "" && !city.match(/^[A-Za-z\s]*$/) && (
                <span className="error text-xs">*City should only contain alphabets and spaces.</span>
              )}
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            State
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <div className='text-sm font-normal flex-col flex'>
              <select className='form-select lg:w-80 bg-white mt-1 block h-10 w-full pl-2 pr-10 py-2 border border-gray-300 shadow-sm text-sm rounded-md focus:outline-none focus:ring focus:ring-gray-300' value={state} name="state" onChange={(e)=> setState(e.target.value)} >
                <option value="">Select Your State</option>
                {states.map((state,i) =>
                  <option key={i} value={state.value}>{state.label}</option>
                )}
							</select>
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Zip Code
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <div className='text-sm font-normal flex-col flex'>
              <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full text-sm shadow-sm h-10" placeholder="Enter Your Zip Code" value={zipcode} name="zipcode" onChange={(e) => setZipcode(e.target.value)} minLength={"2"} maxLength={"6"}/>
              {zipcode !== "" && zipcode?.split("")?.length < 2 && (
                <span className="error text-xs">*Zipcode should contain minimum 2 digits.</span>
              )}
              {zipcode !== "" && zipcode?.split("")?.length > 6 && (
                <span className="error text-xs">*Zipcode should contain maximum 6 digits.</span>
              )}
            </div>
          </div>
        </div>
        <div className="flex mt-4 gap-3 md:justify-end">
          <button className="text-base rounded-md text-gray-700 font-medium border border-gray-200 px-6 py-3" onClick={() => handleEditPersionalInfo(false)}>
            Cancel
          </button>
          <button className={`flex text-base font-medium rounded-md text-white px-4 py-3 gap-2 items-center ${!loaded ? 'button-disabled bg-gray-500' : 'bg-dark-blue'}`} onClick={(e) => handleSaveInfo(e)} disabled={!loaded}>
            <svg viewBox="0 0 14 10" className="h-4 w-4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M13.4226 0.244078C13.7481 0.569515 13.7481 1.09715 13.4226 1.42259L5.0893 9.75592C4.76386 10.0814 4.23622 10.0814 3.91078 9.75592L0.577452 6.42259C0.252015 6.09715 0.252015 5.56951 0.577452 5.24408C0.902889 4.91864 1.43053 4.91864 1.75596 5.24408L4.50004 7.98816L12.2441 0.244078C12.5696 -0.0813592 13.0972 -0.0813592 13.4226 0.244078Z" fill="#ffffff"/>
            </svg>
            Save
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}