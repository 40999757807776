import React, { Fragment } from 'react'
import ReactTooltip from "react-tooltip";
import ExclamationIcon from './../../../assets/exclamationIcon'
import ReactHtmlParser from 'react-html-parser';

export default function MonthViewEventComponent(eventInfo) {
  return(
    <Fragment>
      <div className='lg:flex items-center rounded-md p-1 w-full event-border' style={{background:`${eventInfo?.event?._def?.extendedProps?.background}`, borderColor: `${eventInfo?.event?._def?.extendedProps?.border}`}}>
        <div className=''>
          <span className='inline-block leading-3'>
            {eventInfo?.event?._def?.extendedProps?.extra_html !== '' &&
              <span className='mr-1'>
                <Fragment>
                  {ReactHtmlParser(eventInfo?.event?._def?.extendedProps?.extra_html)}
                </Fragment>
              </span>
            }
            <span className='event-time mr-1'>{eventInfo.timeText.toLowerCase().replace(' ', '')}</span>
            {eventInfo?.event?._def?.extendedProps?.key &&
              <span className='event-time mr-1'>{eventInfo?.event?._def?.extendedProps?.tz}</span>
            }
            <span className='event-tile'>{eventInfo.event.title}</span>
            {(eventInfo?.event?._def?.extendedProps?.unavailable_error_message !== '' || eventInfo?.event?._def?.extendedProps?.api_sync_error_message !== '') &&
              <span>
                <ExclamationIcon classNames={"text-red-600 h-3.5 w-3.5 inline-block ml-1"} componentId={eventInfo?.event?._def?.publicId}/>
              </span>
            }
          </span>
          {(eventInfo?.event?._def?.extendedProps?.unavailable_error_message !== '' || eventInfo?.event?._def?.extendedProps?.api_sync_error_message !== '') &&
            <Fragment>
              <ReactTooltip className='max-w-xs text-center react-tooltip-z-index-max' id={eventInfo?.event?._def?.publicId.toString()} place="top" effect="solid">
              {eventInfo?.event?._def?.extendedProps?.unavailable_error_message || eventInfo?.event?._def?.extendedProps?.api_sync_error_message}
              </ReactTooltip>
            </Fragment>
          }
        </div>
      </div>
    </Fragment>
  )
}