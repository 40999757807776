import React, { Fragment, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { getEventsByDate, modifyWeekData, dayWeekViewTimeGridTime } from './../../../../helpers/helpers'
import WeekViewEventComponent from './weekViewEventComponent'
import DayViewPlusMorePopupComponent from './../dayViewCalendarComponent/dayViewPlusMorePopupComponent';

export default function WeekViewCalendarComponent(props) {
  const { events, calendarRef, currentDate, handleEventClick, hanldeClickOnErrorEvent, showAllEvents } = props
  const [ plusMoreEvents, setPlusMoreEvents ] = useState([])
  const [ showEventsPopup, setShowEventsPopup ] = useState(false)
  const [ moreEventsDate, setMoreEventsDate ] = useState(null)

  const timeGridWeekViewOptions = {
    dayHeaderContent: ({ date }) => {
      const day = date.toLocaleString('default', { weekday: 'short' });
      const dayNumber = date.getDate();
      return (
        <>
          <div className="header-day font-open-sans">{day}</div>
          <div className="header-date font-open-sans">{dayNumber}</div>
        </>
      );
    },
    slotLabelContent: ({ date }) => dayWeekViewTimeGridTime(date)
  };

  const views = {
    timeGridWeek: timeGridWeekViewOptions,
  };

  const eventsByDate = getEventsByDate(events)
  const modifyEvents = showAllEvents ? events : modifyWeekData(eventsByDate)

  const handleClickOnEvents = (info) => {
    if(info?.event?._def?.extendedProps?.more_button){
      let allHourEvents = eventsByDate[info?.event?._def?.extendedProps?.varDateKey][info?.event?._def?.extendedProps?.varKey]
      setPlusMoreEvents(allHourEvents)
      setMoreEventsDate(new Date(info?.event?._def?.extendedProps?.varDateKey))
      setShowEventsPopup(true)
    }else{
      handleEventClick(info)
    }
  }

  const closeEventsPopup = (data) => {
    setPlusMoreEvents([])
    setMoreEventsDate(null)
    setShowEventsPopup(data)
  }

  return(
    <Fragment>
      {showEventsPopup &&
        <DayViewPlusMorePopupComponent events={plusMoreEvents} showEventsPopup={showEventsPopup} closeEventsPopup={closeEventsPopup} hanldeClickOnErrorEvent={hanldeClickOnErrorEvent} type={"week"} moreEventsDate={moreEventsDate}/>
      }
      <FullCalendar
        ref={calendarRef}
        height = {'auto'}
        plugins={[dayGridPlugin, timeGridPlugin]}
        initialView="timeGridWeek"
        views={views}
        initialDate={currentDate}
        weekends={true}
        events={modifyEvents}
        eventContent={WeekViewEventComponent}
        eventTimeFormat= { {
          hour12: true,
          hour: 'numeric',
          minute: 'numeric',
          meridiem: true
        } }
        // eventMaxStack={3}
        // dayPopoverFormat={{ weekday: 'short', day: 'numeric', month: 'short' }}
        eventClick={(info) => {handleClickOnEvents(info)}}
        fixedWeekCount={false}
        slotDuration="00:10:00"
        slotLabelInterval="00:30:00"
      />
    </Fragment>
  )
}