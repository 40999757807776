import React, { Fragment, useState, useEffect } from 'react';
import MrgEventsComponent from '../components/cardViewComponents/mrgEventsComponent'
import OpenEventsComponent from '../components/cardViewComponents/openEventsComponent'
import PendingEventsComponent from '../components/cardViewComponents/pendingEventsComponent';
import ApprovedEventsComponent from '../components/cardViewComponents/approvedEventsComponent'
import NoShowEventsComponent from '../components/cardViewComponents/noShowEventsComponent'; 
import CancelledEventsComponent from '../components/cardViewComponents/cancelledEventsComponent'
import { axiosGetRequest, updateEventsList, removeDeletedEvent, seprateOutLocations, checkMyRequestFiltersState, checkMyRequestLocations, checkShowRegions } from './../../helpers/helpers'
import SentEventsComponent from './../components/cardViewComponents/sentEventsComponent'
import AvailableEventsComponent from './../components/cardViewComponents/availableEventsComponent'
import MyRequestsApprovedEventsComponent from './../components/cardViewComponents/myRequestsApprovedEventsComponent'
import AlertPopup from './../../../messenger/pages/chatComponents/assets/alertPopup'
import DisabledSubboardComponent from '../components/disabledSubboardComponent';
import { getSentBucketDefaultSelection, getAvaialbleBucketDefaultSelection, getAffectedVaraibles, getSentAffectedVaraibles, getVariableNameByStatus } from '../../helpers/supportHelpers';

export default function CardView(props) {
  const { loadingMessage, business, activeTab, path, user, sentStatusesFilter, availableStatusFilter, availableLocationFilter, availableRegionFilter } = props
  const [ loaded, setLoaded ] = useState(false)
  const currentDate = new Date();
  const [ startDate, setStartDate ] = useState((new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)).toLocaleDateString('en-GB'))
  const [ endDate, setEndDate ] = useState((new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)).toLocaleDateString('en-GB'))
  const [ approvedEvents, setApprovedEvents ] = useState([])
  const [ cancelledEvents, setCancelledEvents ] = useState([])
  const [ managerEvents, setManagerEvents ] = useState([])
  const [ noShowEvents, setNoShowEvents ] = useState([])
  const [ openEvents, setOpenEvents ] = useState([])
  const [ pendingEvents, setPendingEvents ] = useState([])
  const [ sentEvents, setSentEvents ] = useState([])
  const [ availableEvents, setAvailableEvents ] = useState([])
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ alertType, setAlertType ] = useState('')
  const [ userHideDeclinedRequests, setUserHideDeclinedRequests ] = useState(user?.hide_declined_requests)
  const disabledSubBoardPage = (business?.banner_enabled)
  const [ availablePage, setAvailablePage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(25)
  const [ sentEventsTotalCount, setSentEventsTotalCount ] = useState(0)
  const [ availableEventsTotalCount, setAvailableEventsTotalCount ] = useState(0)
  const [ approvedEventsTotalCount, setApprovedEventsTotalCount ] = useState(0)
  const [ paginationLoader, setPaginationLoader ] = useState(false)
  const allSentStatuses = getSentBucketDefaultSelection(business?.manager_request_gate)
  const [ statusFilter, setStatusFilter ] = useState((sentStatusesFilter.length > 0 ? sentStatusesFilter : allSentStatuses) || allSentStatuses)
  const [ sentFilterEventsCount, setSentFiltersEventsCount ] = useState({
    managerCount: 0,
    pendingCount: 0,
    acceptedCount: 0,
    approvedCount: 0,
    reopenCount: 0
  })
  const [ availableSelectedStatus, setAvailableSelectedStatus ] = useState(getAvaialbleBucketDefaultSelection(availableStatusFilter))
  const [ availableFilterEventsCount, setAvailableFilterEventsCount ] = useState({
    pendingCount: 0,
    acceptCount: 0,
    declineCount: 0
  })
  const [ regionLocationsList, setRegionLocationsList ] = useState([])
  const [ showRegions, setShowRegions ] = useState(true)
  const [ availableSelectedLocations, setAvailableSelectedLocations ] = useState(availableLocationFilter || [])
  const [ availableSelectedRegions, setAvailableSelectedRegions ] = useState(availableRegionFilter || [])
  const [ sentBucketLoader, setSentBucketLoader ] = useState(false)
  const [ availableBucketLoader, setAvailableBucketLoader ] = useState(false)

  useEffect(() => {
    if(startDate !== '' && endDate !== ''){
      if(['all-requests'].includes(path)){
        processGetEventData()
      }else{
        setAvailablePage(1)
        processGetMyRequestsData(1, statusFilter, availableSelectedStatus, availableSelectedLocations, true)
      }
    }
  }, [startDate, endDate])

  const processGetEventData = () => {
    let url = `${window._sub_board_url}/api/v3/sub_overview/get_location_events?start_date=${startDate}&end_date=${endDate}&event_type=undefined&view_type=${activeTab}&initialize=false`
    axiosGetRequest(url).then((response) => {
      if(response.status == 200){
        setApprovedEvents(response?.results?.approved)
        setCancelledEvents(response?.results?.cancelled)
        setManagerEvents(response?.results?.manager)
        setNoShowEvents(response?.results?.no_show)
        setOpenEvents(response?.results?.open)
        setPendingEvents(response?.results?.pending)
        setLoaded(true)
      }else{
        setApprovedEvents([])
        setCancelledEvents([])
        setManagerEvents([])
        setNoShowEvents([])
        setOpenEvents([])
        setPendingEvents([])
        setLoaded(true)
      }
    })
  }

  const processGetMyRequestsData = (page, sentStatuses, availableStatuses, availableLocations, initialize) => {
    let url = `${window._sub_board_url}/api/v3/business_events?start_date=${startDate}&end_date=${endDate}&view_type=${activeTab}&page=${page}&page_size=${pageSize}&sent_statuses=${sentStatuses}&available_statuses=${availableStatuses}&initialize=${initialize}&available_locations=${availableLocations}`
    axiosGetRequest(url).then((result) => {
      if(result.status == 200){
        setSentEventsTotalCount(result?.sent_count)
        setAvailableEventsTotalCount(result?.available_count)
        setApprovedEventsTotalCount(result?.approved_count)
        let sentFilterEventsCounts = {
          managerCount: result?.sent_bucket_filters_count?.sent_pending_mgr_count,
          pendingCount: result?.sent_bucket_filters_count?.sent_open_count,
          acceptedCount: result?.sent_bucket_filters_count?.sent_pending_count,
          approvedCount: result?.sent_bucket_filters_count?.sent_approved_count,
          reopenCount: result?.sent_bucket_filters_count?.sent_re_open_count
        }
        setSentFiltersEventsCount(sentFilterEventsCounts)
        let availableFilterEventsCounts = {
          pendingCount: result?.available_bucket_filters_count?.available_pending_count,
          acceptCount: result?.available_bucket_filters_count?.available_accept_count,
          declineCount: result?.available_bucket_filters_count?.available_decline_count
        }
        setAvailableFilterEventsCount(availableFilterEventsCounts)
        if(initialize){
          setRegionLocationsList(result?.locations)
          setShowRegions(checkShowRegions(result?.locations))
          let regions = result?.locations.map(function(a) {return a.region?.name;});
          let locations = seprateOutLocations(result?.locations).map(function(a) {return a.id;});
          let locationState = checkMyRequestFiltersState(user?.id, 'location', 'available')
          let [locationsToBeSelected, regionsToBeSelected] = checkMyRequestLocations(locations, regions, availableSelectedLocations, availableSelectedRegions, result?.locations, user?.id, 'available')
          setAvailableSelectedLocations(locationsToBeSelected)
          setAvailableSelectedRegions(regionsToBeSelected)
        }
        if(page == 1){
          setApprovedEvents(result?.approved)
          setSentEvents(result?.sent)
          setAvailableEvents(result?.available)
          setUserHideDeclinedRequests(result?.user_hide_declined_requests)
        }else{
          setAvailableEvents(result?.available)
          setUserHideDeclinedRequests(result?.user_hide_declined_requests)
        }
        setLoaded(true)
        setPaginationLoader(false)
        setSentBucketLoader(false)
        setAvailableBucketLoader(false)
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0);
      }else{
        setApprovedEvents([])
        setSentEvents([])
        setAvailableEvents([])
        setSentFiltersEventsCount({
          managerCount: 0,
          pendingCount: 0,
          acceptedCount: 0,
          approvedCount: 0,
          reopenCount: 0
        })
        setAvailableFilterEventsCount({
          pendingCount: 0,
          acceptCount: 0,
          declineCount: 0
        })
        setSentBucketLoader(false)
        setAvailableBucketLoader(false)
        setLoaded(true)
      }
    })
  }

  const handleChangePage = (page) => {
    setAvailablePage(page)
    setPaginationLoader(true)
    processGetMyRequestsData(page, statusFilter, availableSelectedStatus, availableSelectedLocations, false)
  }

  const handleAlert = (show, message, type) => {
    setShowAlert(show)
    setAlertMessage(message)
    setAlertType(type)
  }

  const handleGetEventData = () => {
    setLoaded(false)
    if(['all-requests'].includes(path)){
      processGetEventData()
    }else{
      processGetMyRequestsData(availablePage, statusFilter, availableSelectedStatus, availableSelectedLocations, true)
    }
  }

  const closeAlert = (data) => {
    setShowAlert(data)
    setAlertMessage('')
    setAlertType('')
  }

  const getEventDataWithoutLoad = () => {
    if(['all-requests'].includes(path)){
      processGetEventData()
    }else{
      setAvailablePage(1)
      processGetMyRequestsData(1, statusFilter, availableSelectedStatus, availableSelectedLocations, true)
    }
  }

  const handleUpdateEvents = (data) => {
    if(!data) return;
    setApprovedEvents(updateEventsList(approvedEvents, data));
    if(['all-requests'].includes(path)){
      setCancelledEvents(updateEventsList(cancelledEvents, data));
      setManagerEvents(updateEventsList(managerEvents, data));
      setNoShowEvents(updateEventsList(noShowEvents, data));
      setOpenEvents(updateEventsList(openEvents, data));
      setPendingEvents(updateEventsList(pendingEvents, data));
    }else{
      updateSentEventsCount(sentEvents, data);
      setSentEvents(updateEventsList(sentEvents, data));
      updateAvailableEventsCount(availableEvents, data);
      setAvailableEvents(updateEventsList(availableEvents, data));
    }
  }

  const handleDeleteEvent = (data) => {
    setApprovedEvents(removeDeletedEvent(approvedEvents, data));
    if(['all-requests'].includes(path)){
      setCancelledEvents(removeDeletedEvent(cancelledEvents, data));
      setManagerEvents(removeDeletedEvent(managerEvents, data));
      setNoShowEvents(removeDeletedEvent(noShowEvents, data));
      setOpenEvents(removeDeletedEvent(openEvents, data));
      setPendingEvents(removeDeletedEvent(pendingEvents, data));
    }else{
      sentDeleteEventCountChange(sentEvents, data);
      setSentEvents(removeDeletedEvent(sentEvents, data));
      setAvailableEvents(removeDeletedEvent(availableEvents, data));
    }
  }

  const sentDeleteEventCountChange = (allEvents, event) => {
    if(!event || !allEvents || !allEvents.length > 0) return;
    const existingEvent = allEvents?.find(event => event.id === event.id);
    if (!existingEvent) return;
    let counterKey = getVariableNameByStatus(event) || '';
    if (!counterKey || counterKey == '') return;
    setSentFiltersEventsCount(prevState => ({
      ...prevState,
      [counterKey]: Math.max(prevState[counterKey] - 1, 0)
    }))
  }

  const saveAndApplyFilters = (data, type) => {
    if(type == 'sent'){
      setStatusFilter(data.selectedStatus)
      setAvailablePage(1)
      setSentBucketLoader(true)
      processGetMyRequestsData(1, data.selectedStatus, availableSelectedStatus, availableSelectedLocations, false)
    }else{
      setAvailableSelectedStatus(data.selectedStatus)
      setAvailableSelectedLocations(data.locations)
      setAvailableSelectedRegions(data.regions)
      setAvailablePage(1)
      setAvailableBucketLoader(true)
      processGetMyRequestsData(1, statusFilter, data.selectedStatus, data.locations, false)
    }
  }

  const updateAvailableEventsCount = (allEvents, newEvent) => {
    if (!newEvent || !allEvents || !allEvents.length > 0) return;
    let [ reduceVar, increaseVar ] = getAffectedVaraibles(allEvents, newEvent);
    if (!reduceVar || !increaseVar || (reduceVar == '' || increaseVar == '') || (reduceVar == increaseVar)) return;
    setAvailableFilterEventsCount(prevState => ({
      ...prevState,
      [reduceVar]: Math.max(prevState[reduceVar] - 1, 0),
      [increaseVar]: prevState[increaseVar] + 1
    }))
  }

  const updateSentEventsCount = (allEvents, newEvent) => {
    if (!newEvent || !allEvents || !allEvents.length > 0) return;
    let [ reduceVar, increaseVar ] = getSentAffectedVaraibles(allEvents, newEvent);
    if (!reduceVar || (reduceVar == '') || (reduceVar == increaseVar)) return;
    setSentFiltersEventsCount(prevState => ({
      ...prevState,
      [reduceVar]: Math.max(prevState[reduceVar] - 1, 0),
      ...(increaseVar && { [increaseVar]: prevState[increaseVar] + 1 })
    }));
  }

  return(
    <Fragment>
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert} />
      }
      {!loaded &&
        <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
          <p className="mb-1 text-center px-5">{loadingMessage}</p>
          <img className = "w-9" src="/assets/business/loading.gif" />
        </div>
      }
      {loaded &&
        <Fragment>
          <div className={`mt-5 mx-auto flex-row lg:flex items-start gap-x-4 gap-y-8 lg:mx-0 lg:max-w-none overflow-x-auto ${(['all-requests'].includes(path)) ? 'card-view-width' : ''} px-8`}>
            {(['all-requests'].includes(path)) ?
              <Fragment>
                {business?.manager_request_gate &&
                  <MrgEventsComponent managerEvents={managerEvents} user={user} business={business} handleAlert={handleAlert} processGetEventData={handleGetEventData} path={path} getEventDataWithoutLoad={getEventDataWithoutLoad} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent}/>
                }
                <OpenEventsComponent openEvents={openEvents} user={user} business={business} handleAlert={handleAlert} processGetEventData={handleGetEventData} path={path} getEventDataWithoutLoad={getEventDataWithoutLoad} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent}/>
                <PendingEventsComponent pendingEvents={pendingEvents} user={user} business={business} handleAlert={handleAlert} processGetEventData={handleGetEventData} path={path} getEventDataWithoutLoad={getEventDataWithoutLoad} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent}/>
                <ApprovedEventsComponent approvedEvents={approvedEvents} user={user} business={business} handleAlert={handleAlert} processGetEventData={handleGetEventData} path={path} getEventDataWithoutLoad={getEventDataWithoutLoad} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent}/>
                <NoShowEventsComponent noShowEvents={noShowEvents} user={user} business={business} handleAlert={handleAlert} processGetEventData={handleGetEventData} path={path} getEventDataWithoutLoad={getEventDataWithoutLoad} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent}/>
                <CancelledEventsComponent cancelledEvents={cancelledEvents} user={user} business={business} handleAlert={handleAlert} processGetEventData={handleGetEventData} path={path} getEventDataWithoutLoad={getEventDataWithoutLoad} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent}/>
              </Fragment>
            :
              <Fragment>
                <SentEventsComponent
                  sentEvents={sentEvents}
                  user={user}
                  business={business}
                  handleAlert={handleAlert}
                  processGetEventData={handleGetEventData}
                  path={path}
                  getEventDataWithoutLoad={getEventDataWithoutLoad}
                  handleUpdateEvents={handleUpdateEvents}
                  handleDeleteEvent={handleDeleteEvent}
                  disabledSubBoardPage={disabledSubBoardPage}
                  sentEventsTotalCount={sentEventsTotalCount}
                  selectedStatus={statusFilter}
                  managerCount={sentFilterEventsCount?.managerCount}
                  pendingCount={sentFilterEventsCount?.pendingCount}
                  acceptedCount={sentFilterEventsCount?.acceptedCount}
                  approvedCount={sentFilterEventsCount?.approvedCount}
                  reopenCount={sentFilterEventsCount?.reopenCount}
                  saveAndApplyFilters={saveAndApplyFilters}
                  sentBucketLoader={sentBucketLoader}
                />
                <AvailableEventsComponent
                  availableEvents={availableEvents}
                  user={user}
                  business={business}
                  handleAlert={handleAlert}
                  path={path}
                  getEventDataWithoutLoad={getEventDataWithoutLoad}
                  userHideDeclinedRequests={userHideDeclinedRequests}
                  handleUpdateEvents={handleUpdateEvents}
                  handleDeleteEvent={handleDeleteEvent}
                  disabledSubBoardPage={disabledSubBoardPage}
                  page={availablePage}
                  pageSize={pageSize}
                  availableEventsTotalCount={availableEventsTotalCount}
                  handleChangePage={handleChangePage}
                  paginationLoader={paginationLoader}
                  availableSelectedStatus={availableSelectedStatus}
                  pendingCount={availableFilterEventsCount?.pendingCount}
                  acceptCount={availableFilterEventsCount?.acceptCount}
                  declineCount={availableFilterEventsCount?.declineCount}
                  saveAndApplyFilters={saveAndApplyFilters}
                  regionLocationsList={regionLocationsList}
                  showRegions={showRegions}
                  selectedLocations={availableSelectedLocations}
                  selectedRegions={availableSelectedRegions}
                  availableBucketLoader={availableBucketLoader}
                />
                <MyRequestsApprovedEventsComponent approvedEvents={approvedEvents} user={user} business={business} handleAlert={handleAlert} processGetEventData={handleGetEventData} path={path} getEventDataWithoutLoad={getEventDataWithoutLoad} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent} disabledSubBoardPage={disabledSubBoardPage} approvedEventsTotalCount={approvedEventsTotalCount}/>
              </Fragment>
            }
          </div>
          {(['my-requests'].includes(path)) &&
            <div>
              <DisabledSubboardComponent business={business}/>
            </div>
          }
        </Fragment>
      }
    </Fragment>
  )
}