import React, { Fragment, useState } from "react";
import { Menu, Transition } from '@headlessui/react'
import { AngleIcon } from './../../../../assets';
import { handleAddExtraPadding, handleRemoveExtraPadding } from '../../../../helpers/helpers';

export default function CommentsComponent(props) {
  const { comments, eventsCount, index } = props
  const [ isOpen, setIsOpen ] = useState(false);

  const setRef = node => {
    if (node) {
      setIsOpen(true)
      handleAddExtraPadding(index, eventsCount, comments)
    }else if (node === null) {
      setIsOpen(false)
      handleRemoveExtraPadding()
    }
  };

  return (
    <Fragment>
      <Menu as="div" className="relative block text-left">
        <Menu.Button className="flex relative z-20 justify-center items-center w-full text-xs font-medium gap-1 custom-blue-200 whitespace-nowrap">
          All Comments ({comments?.length})
          <AngleIcon classNames={`ml-1 text-gray-800 hc-14 wc-14 transform ${isOpen ? 'rotate-180' : ''}`}/>
        </Menu.Button>
        <Transition
          as={Fragment}
          // show={true}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items ref={setRef} className="origin-top-right z-30 right-0 rounded-xl bg-white focus:outline-none mt-1 w-64 shadow-md border-t border-gray-100 absolute">
            <div className="p-2 max-h-40 overflow-y-auto">
              {comments?.map((comment, i) => (
                <div key={i} className="flex gap-2 pt-2">
                  <img src={comment?.user?.image} className='w-6 h-6 rounded-full mt-1' />
                  <div>
                    <div className='text-gray-900 tc-10 font-semibold flex gap-1'>{comment?.user?.name}<div className='text-gray-500'>{comment?.created?.trim()}</div></div>
                    <div className='text-black text-xs font-semibold'>{comment?.comment}</div>
                  </div>
                </div>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </Fragment>
  )
}