import React, { Fragment, useEffect, useState } from "react";
import { MessageIcon, TashIcon } from '../../../../assets'
import CancelConfirmationPopupComponent from './../../cancelConfirmationPopupComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
library.add( faUndo )

export default function CancelEventButtonsComponent(props){
  const { eventData, handleSetAlert, handleUpdateEvent, calendarEvent, handleUpdateCalendarEvent } = props
  const [ undoCancelOption, setUndoCancelOption ] = useState([])
  const [ showCancelPopup, setShowCancelPopup ] = useState(false)

  useEffect(() => {
    if(eventData){
      setUndoCancelOption(eventData?.more_action_detail?.filter(item => item?.key == 'undo_cancel'))
    }
  }, [eventData])

  return(
    <Fragment>
      {showCancelPopup &&
        <CancelConfirmationPopupComponent eventId={eventData?.id} showCancelPopup={showCancelPopup} closeCancelPopup={setShowCancelPopup} handleSetAlert={handleSetAlert} handleCloseAndReloadData={handleUpdateEvent} type={'preview'} eventData={eventData} calendarEvent={calendarEvent} handleUpdateCalendarEvent={handleUpdateCalendarEvent}/> 
      }
      {undoCancelOption.length > 0 &&
        <button className='w-full text-xs font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5' onClick={() => setShowCancelPopup(true)}>
          <FontAwesomeIcon className='w-3.5 h-3.5 shrink-0' icon="fa-solid fa-undo" />
          {undoCancelOption[0]?.value}
        </button>
      }
    </Fragment>
  )
}