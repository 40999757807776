import React, { Component, Fragment, useEffect } from 'react'
import { setTitle } from './../../../helpers'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'

export default function InsightLayout (props){
  const { handleTabChange, activeTab, user, business } = props

  useEffect(() => {
    if (['behavior', 'responsiveness', 'instructor_history'].includes(activeTab)){
      setTitle('analytics')
    }else if ( activeTab == 'availability'){
      setTitle('availability')
    }
  }, [activeTab])

  const handleTabClick = (data) => {
    handleTabChange(data)
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return(
    <Fragment>
      <div className="relative filters_box  w-full md:hidden">
        <Menu as="div" className="relative block text-left">
          <div className=''>
            <Menu.Button className="inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-none focus:ring-offset-2 hover:bg-gray-50 focus:ring-offset-gray-100 focus:ring-indigo-500">
              <p className='text-gray-900'>{['behavior', 'responsiveness', 'availability'].includes(activeTab) ? capitalizeFirstLetter(activeTab) : activeTab === 'instructor_history' ? 'Instructor History' : activeTab === 'home_club_report' && 'Home Club Report' }</p>
              <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="origin-top-right z-25 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-2 max-h-60 overflow-y-auto">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button className={`text-sm flex items-center font-medium px-8 py-1 w-full ${activeTab === 'instructor_history' ? 'bg-gray-200' : ''}`} onClick={(e) => handleTabClick('instructor_history')}>Instructor History</button>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button className={`text-sm flex items-center font-medium px-8 py-1 w-full ${activeTab === 'behavior' ? 'bg-gray-200' : ''}`} onClick={(e) => handleTabClick('behavior')}>Behavior</button>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button className={`text-sm flex items-center font-medium px-8 py-1 w-full ${activeTab === 'responsiveness' ? 'bg-gray-200' : ''}`} onClick={(e) => handleTabClick('responsiveness')}>Responsiveness</button>
                    )}
                  </Menu.Item>
                </div>
                {(business?.reports) &&
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button className={`text-sm flex items-center font-medium px-8 py-1 w-full ${activeTab === 'home_club_report' ? 'bg-gray-200' : ''}`} onClick={(e) => handleTabClick('home_club_report')}>Home Club Report</button>
                      )}
                    </Menu.Item>
                  </div>
                }
                {(business?.availability_feature) &&
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button className={`text-sm flex items-center font-medium px-8 py-1 w-full ${activeTab === 'availability' ? 'bg-gray-200' : ''}`} onClick={(e) => handleTabClick('availability')}>Availability</button>
                      )}
                    </Menu.Item>
                  </div>
                }
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <div className='hidden md:inline-flex bg-white h-11 shadow rounded-lg mb-5'>
        <button className={`sm:text-sm text-xs flex items-center border-b-2 font-medium px-4 ${activeTab === 'instructor_history' ? 'text-gray-900 border-gray-900 rounded-bl' : 'text-gray-300 border-transparent'}`} onClick={(e) => handleTabClick('instructor_history')}>Instructor History</button>

        <button className={`sm:text-sm text-xs flex items-center border-b-2 font-medium px-4 ${activeTab === 'behavior' ? 'text-gray-900 border-gray-900' : 'text-gray-300 border-transparent'}`} onClick={(e) => handleTabClick('behavior')}>Behavior</button>

        <button className={`sm:text-sm text-xs flex items-center border-b-2 font-medium px-4 ${activeTab === 'responsiveness' ? 'text-gray-900 border-gray-900' : 'text-gray-300 border-transparent'}`} onClick={(e) => handleTabClick('responsiveness')}>Responsiveness</button>
        
        {business?.reports &&
          <button className={`sm:text-sm text-xs flex items-center border-b-2 font-medium px-4 ${activeTab === 'home_club_report' ? 'text-gray-900 border-gray-900' : 'text-gray-300 border-transparent'}`} onClick={(e) => handleTabClick('home_club_report')}>Home Club Report</button>
        }

        {business?.availability_feature &&
          <button className={`sm:text-sm text-xs flex items-center border-b-2 font-medium px-4 ${activeTab === 'availability' ? 'text-gray-900 border-gray-900 rounded-br' : 'text-gray-300 border-transparent'}`} onClick={(e) => handleTabClick('availability')}>Availability</button>
        }
      </div>
    </Fragment>
  )
}