import React, { Fragment } from "react";

export default function ManagersNameComponent(props) {
  const { eventData, handleShowInstructorDetails } = props
  return (
    <Fragment>
      <div className="flex border-b border-t border-gray-300 py-2 gap-2">
        <div className="text-base font-semibold">Manager(s):</div>
        <span className="inline-block custom-blue-200 text-base font-semibold custom-blue-200 text-left">
          {eventData?.fitness_managers_details?.map(
            (manager, index) => (
              <span className="inline" key={index}>
                <a
                  className="text-base font-semibold custom-blue-200 cursor-pointer"
                  onClick={() => handleShowInstructorDetails(manager)}
                >
                  {manager?.full_name}
                </a>
                {index <
                  eventData?.fitness_managers_details?.length - 1 && (
                  <span className="text-base font-semibold custom-blue-200 mr-1">
                    ,
                  </span>
                )}
              </span>
            )
          )}
        </span>
      </div>
    </Fragment>
  )
}