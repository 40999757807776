import React, { Fragment } from "react";

export default function NetgymLogoComponent() {

  return(
    <Fragment>
      <div className="flex items-center justify-center">
        <img src="/assets/logo-black.png" />
      </div>
    </Fragment>
  )
}