import React, { Fragment, useState } from "react";
import { axiosRequest } from '../../../../subRequests/helpers/helpers'
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { getCountriesAbbr, getPhoneNumberValue } from '../../../../myProfile/helpers'; 
import { checkTheSignUpPagePath } from '../../../helpers';

export default function PhoneInputFieldComponent(props) {
  const { user, setVerificationCodeSent, setAlert, updateUserDataAndRedirect, handleUpdateUser, phoneNumberToHold, setPhoneNumberToHold } = props
  const countryList = getCountriesAbbr()
  let lastSavedPhoneNumber = phoneNumberToHold || user?.directory_phone_number || ''
  const [ phoneNumber, setPhoneNumber ] = useState(getPhoneNumberValue(lastSavedPhoneNumber))
  const [ validPhoneNumber, setValidPhoneNumber ] = useState(lastSavedPhoneNumber ? true : (parsePhoneNumber(lastSavedPhoneNumber) && countryList.includes(parsePhoneNumber(lastSavedPhoneNumber).country) && isValidPhoneNumber(lastSavedPhoneNumber ? (lastSavedPhoneNumber.startsWith('+') ? lastSavedPhoneNumber : '+'+lastSavedPhoneNumber) : '') === true))
  const [ defaultCountry, seDefaultCountry ] = useState(user?.country || 'US')
  const [ submitted, setSubmitted ] = useState(false)
  const [ disableConfirmBtn, setDisableConfirmBtn ] = useState(false)


  const processSendVerificationCode = () => {
    setDisableConfirmBtn(true)
    let params = { 
      user_data: {
        phone_number: phoneNumber 
      }
    }
    axiosRequest('/api/v3/my_profile/send_verification_code', 'POST', params, 'data').then(result => {
      if(result.status === 200){
        setAlert(true, 'success', result?.success)
        setVerificationCodeSent(true)
        setPhoneNumberToHold(phoneNumber)
			}else{
        setAlert(true, 'danger', result?.error)
			}
      setDisableConfirmBtn(false)
      window.setTimeout(() => {
        setAlert(false, '', '')
      }, 3000)
		})
  }

  const handleSetPhoneNumber = (value) => {
    if (value){
      let phoneNumber = parsePhoneNumber(value)
      if(phoneNumber && countryList.includes(phoneNumber.country) && isValidPhoneNumber(value) === true){
        setValidPhoneNumber(true)
      }else{
        setValidPhoneNumber(false)
      }
      setPhoneNumber(value)
    }else{
      setValidPhoneNumber(true)
      setPhoneNumber('')
    }
  }

  const handleCountryChange = (value) => {
    seDefaultCountry(value)
  }

  const savePhoneNumberWithoutValidate = (e) => {
    setDisableConfirmBtn(true)
    let params = { 
      phone_number: phoneNumber 
    }
    let url = '/api/v3/skip_phone_verification'
    axiosRequest(url, 'POST', params, 'data').then(result => {
      if(result.status == 200){
        setAlert(true, 'success', result?.success)
        setTimeout(() => {
          setAlert(false, '', '')
        }, 3000);
        if(result.user?.active){
          updateUserDataAndRedirect(result?.user, '/my-requests')
        } else {
          handleUpdateUser(result?.user, checkTheSignUpPagePath(result?.user))
        }
      }else{
        setAlert(true, 'danger', result?.error)
        setTimeout(() => {
          setAlert(false, '', '')
        }, 3000);
      }
      setDisableConfirmBtn(false)
    })
  }

  return(
    <Fragment>
      <div className="flex flex-wrap items-center justify-between gap-5">
        <div className="md:w-64 rounded-md w-full text-sm shadow-sm">
          <PhoneInput
            className="border rounded-md px-3 py-1.5 w-full h-10"
            value={phoneNumber}
            onChange={handleSetPhoneNumber}
            defaultCountry={defaultCountry}
            international
            withCountryCallingCode
            countryCallingCodeEditable={false}
            id="phone-input"
            placeholder='+1 201-555-5555'
            countries={countryList}
            onCountryChange={handleCountryChange}
          />
          {submitted && !validPhoneNumber && (
            <span className="error text-xs">Phone Number should only contain digits.</span>
          )}
          {submitted && phoneNumber == "" && (
            <span className="error text-xs">Please enter a Phone Number.</span>
          )}
        </div>
        <button className={`bg-gray-900 rounded-md text-white flex items-center justify-center px-10 py-2 text-sm w-full md:w-36 h-10 ${(!validPhoneNumber || disableConfirmBtn) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={(e) => user?.allow_phone_verification ? processSendVerificationCode(e) : savePhoneNumberWithoutValidate(e)} disabled={!validPhoneNumber || disableConfirmBtn}>
          {user?.allow_phone_verification ? 'Text Me' : 'Save'}
        </button>
      </div>
    </Fragment>
  )
}