import React, { Fragment, useState } from "react";
import { uploadCSV, uploadUsers } from './../../../../helpers'
import Cookies from 'js-cookie';
import { getSudomain } from './../../../../../../helpers'
import ShowUploadComponent from "../../showUploadComponent/showUploadComponent";
import CustomLargePopup from '../../../assets/customLargePopup/customLargePopup'

export default function UploadCsvComponent(props){
  const { showAlert, setTheState, business, reloadDataAfterSendActivationEmail } = props

  const [ uploadNewUsers, setUploadNewUsers ] = useState([])
  const [ uploadOldUsers, setuploadOldUsers ] = useState([])
  const [ showUpload, setShowUpload ] = useState(false)
  const [ disabledSaveBtn, setDisabledSaveBtn ] = useState(false)
  const [ disabledUploadBtn, setDisabledUploadBtn ] = useState(false)

  const processUploadCsv = (e) => {
    // setTheState('dataLoaded', false)
    setDisabledUploadBtn(true)
    uploadCSV(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), e.target.files[0], business).then(      
      response => response.json()
      .then(result => {
        if(result.status === 200){
          setUploadNewUsers(result.results)
          setuploadOldUsers(result.old_results)
          showAlert(true, 'success', 'File uploaded successfully')
          setShowUpload(true)
          // setTheState('dataLoaded', true)
          setDisabledUploadBtn(false)
        }else{
          showAlert(true, 'danger', result.error)
          // setTheState('dataLoaded', false)
          setDisabledUploadBtn(false)
        }
        window.setTimeout(()=>{
          showAlert(false, '', '')
        },3000)
      })
    )
  }

  const closeModal = (data) => {
    setShowUpload(data)
  }

  const saveUpload = () => {
    setDisabledSaveBtn(true)
    uploadUsers(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), uploadNewUsers).then(      
      response => response.json()
      .then(result => {
        if(result.status === 200){
          setUploadNewUsers([])
          setuploadOldUsers([])
          showAlert(true, 'success', 'File uploaded successfully')
          setShowUpload(false)
          setDisabledSaveBtn(false)
          // this.getAll()
          setTheState('dataLoaded', false)
          reloadDataAfterSendActivationEmail()
        }else{
          showAlert(true, 'success', result.error)
          setDisabledSaveBtn(false)
        }
        window.setTimeout(()=>{
          showAlert(false, '', '')
        },3000)
      })
    )
  }

  return(
    <Fragment>
      {showUpload &&
        <CustomLargePopup show={showUpload} close={closeModal} title={"Users Preview"}>
          <ShowUploadComponent uploadNewUsers={uploadNewUsers} uploadOldUsers={uploadOldUsers} business={business} saveUpload={saveUpload} disabledSaveBtn={disabledSaveBtn}/>
        </CustomLargePopup>
      }
      <div>
        <div className={`border rounded-md border-gray-300 shadow-sm text-gray-700 text-sm py-2 px-4 relative h-10 flex items-center block cursor-pointer ${disabledUploadBtn ? 'button-disabled bg-gray-200' : 'bg-white hover:bg-gray-100'}`}>
          <div className={`${disabledUploadBtn ? 'text-gray-500' : 'text-gray-700'} flex items-center gap-2.5 relative px-1`}>
            {disabledUploadBtn &&
              <div className="flex items-center -left-3.5 justify-center absolute">
                <svg className="animate-spin h-3.5 w-3.5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            }
            Upload CSV
          </div>
          <input type="file" className={`absolute w-full top-0 left-0 bottom-0 opacity-0 cursor-pointer ${disabledUploadBtn ? 'button-disabled' : ''}`} required="required" accept=".csv" onChange={(e) => processUploadCsv(e)} disabled={disabledUploadBtn}/>
        </div>
      </div>
    </Fragment>
  )
}