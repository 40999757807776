import React from 'react';
import EmptyEmailIcon from './assets/emptyEmailIcon'

export const ChannelListEmptyState = (props) => {
  const { onBannerClick } = props

  return(
    <div className='rounded-2xl bg-gray-200 flex items-center justify-center text-sm text-gray-400 h-20 mx-2 my-1 cursor-pointer' onClick={() => onBannerClick()}>
      You don't have chats yet
    </div>
  )
}

export const ChannelListEmptyScreen = () => {

  return(
    <div className='bg-gray-100 flex items-center justify-center text-sm text-gray-400 w-full px-8 py-5 mb-8'>
      <div className='rounded-2xl bg-white h-full w-full flex justify-center items-center flex-col gap-7'>
        <EmptyEmailIcon />
        <div className='text-black text-2xl font-bold'>
          Let's start chatting!
        </div>
      </div>
    </div>
  )
}