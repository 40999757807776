import React, { Fragment } from "react";
import { ClockIcon } from './../../../../assets'

export default function EventDateComponent(props) {
  const { eventData, user } = props
  return(
    <Fragment>
      <div className='flex items-center mb-1'>
        <div>
          <ClockIcon classNames={'w-5 h-5 text-neutral-400'}/>
        </div>
        <div className='text-xs font-semibold ml-2'>{eventData?.event_time} {user?.show_tz ? `${eventData?.tz_display}` : ''} {eventData?.duration_display !== '' ? `(${eventData?.duration_display})` : ''}</div>
      </div>
    </Fragment>
  )
}