import React, { Component, Fragment } from 'react';
import HeadingComponent from '../components/headingComponent';
import SubRequestDetailsPageComponent from '../components/subRequestDetailsPageComponent';
import { withRouter } from 'react-router-dom';
import LoaderComponent from './../../subRequests/pages/components/loaderComponent/loaderComponent';
import { axiosGetRequest } from './../../subRequests/helpers/helpers';
import AlertPopup from './../../messenger/pages/chatComponents/assets/alertPopup';
import { setTitle } from './../../helpers';

class SubRequestDetailsLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: props?.match?.params?.id,
      loaded: false,
      eventData: {},
      showAlert: false,
      alertType: '',
      alertMessage: '',
      firstTime: true
    };
  }

  componentDidMount = () => {
    let params = new URLSearchParams(window.location.search)
    if(params.has('backpath') && params.get('backpath') == 'schedule_path'){
      this.props.setActive('Sub Schedule')
    }else{
      this.props.setActive('Sub Board')
    }
    setTitle('sub_request_details')
    if(this.state.eventId === 0){
      window.location.href = '/'
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.state.eventId !== 0 && nextProps.pageLoaded){
      if(this.state.firstTime){
        this.handleFetchEvent()
        this.setState({firstTime: false})
      }
    }
  }

  handleFetchEvent = () => {
    let eventId = this.state.eventId
    let url = `/api/v3/business_events/${parseInt(eventId)}?preview=false`
    axiosGetRequest(url).then((response) => {
      if(response?.status === 200){
        this.setState({
          eventData: response?.event,
          loaded: true
        })
      }else{
        this.setState({
          showAlert: true,
          alertType: 'danger',
          alertMessage: response?.error
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
            window.location.href = '/'
          },3000)
        });
      }
    })
  }

  closeAlert = (data) => {
    this.setState({showAlert: data})
  }

  handleSetAlert = (showAlert, alertMessage, alertType) => {
    this.setState({showAlert: showAlert, alertType: alertType, alertMessage: alertMessage})
  }

  handleUpdateEventComments = (data) => { 
    this.setState({eventData: {...this.state.eventData, comments: data?.comments}})
  }

  handleReloadData = () => {
    this.handleFetchEvent()
  }

  handleUpdateEvent = (data) => {
    this.setState({eventData: data})
  }

  render() {
    const { user, business, permissions, loadingMessage, path} = this.props
    const { loaded, eventId, eventData, showAlert, alertType, alertMessage } = this.state
    return (
      <Fragment>
        {showAlert &&
          <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={this.closeAlert} />
        }
        <div className={`w-full mt-20 md:mt-8 min-h-screen font-open-sans bg-gray-custom-50 lg:px-8 pb-8`}>
          <HeadingComponent />
          {!loaded &&
            <LoaderComponent loadingMessage={loadingMessage}/>
          }
          {loaded &&
            <Fragment>
              <SubRequestDetailsPageComponent user={user} business={business} permissions={permissions} loadingMessage={loadingMessage} path = {path} eventData={eventData} handleSetAlert={this.handleSetAlert} handleUpdateEventComments={this.handleUpdateEventComments} handleReloadData={this.handleReloadData} handleUpdateEvent={this.handleUpdateEvent}/>
            </Fragment>
          }
        </div>
      </Fragment>
    );
  }
}

export default withRouter(SubRequestDetailsLayout);