import React, { Fragment, useState, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { AngleIcon } from './../../../../assets';
import { handleStartDateOfweek, handleEndDateOfWeek } from './../../../../helpers/helpers'

export default function WeekTypeRangeSelectorComponent(props) {
  const { selectedWeek, handleChangeWeek, calendarViewType, currentDate } = props
  const [ isOpen, setIsOpen ] = useState(false);

  const handleViewDate = (value) => {
    let date = new Date(value);
    if(calendarViewType == 'week'){
      let start_date = handleStartDateOfweek(date);
      let end_date = handleEndDateOfWeek(date);
      let firstDate = start_date.getDate();
      let firstDateMonth = start_date.getMonth() + 1;
      let firstDateMonthName = new Date(start_date.getFullYear(), start_date.getMonth()).toLocaleString("en-US", {
        month: "short"
      });
      let lastDate = end_date.getDate();
      let lastDateMonth = end_date.getMonth() + 1;
      let formattedDate = new Date(end_date.getFullYear(), end_date.getMonth(), end_date.getDate()).toLocaleString("en-US", {
        month: "short",
        day: 'numeric',
        year: "numeric"
      });
      let formattedWithoutDate = new Date(end_date.getFullYear(), end_date.getMonth()).toLocaleString("en-US", {
        month: "short",
        year: "numeric"
      });
      if(firstDateMonth == lastDateMonth){
        return `${firstDate}-${lastDate} ${formattedWithoutDate.replace(/(\w{3})\s(\d{2})/, "$1, $2")}`
      }else{
        return `${firstDateMonthName} ${firstDate} - ${formattedDate.replace(/(\w{3})\s(\d{2})/, "$1, $2")}`
      }
    }else if(calendarViewType == 'day'){
      let formattedDate = date.toLocaleString("en-US", {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
      return `${formattedDate}`
    }
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
    const handleOnClick = () => {
      if(!isOpen) {
        onClick()
      }
    }

    return (
      <button className="" onClick={handleOnClick} ref={ref}>
        <div className={`text-2xl font-semibold flex items-center justify-between font-open-sans`}>{handleViewDate(value) || value}
          <div>
            <AngleIcon classNames={'ml-2 text-gray-900 h-5 w-5'}/>
          </div>
        </div>
      </button>
    )
  });

  const handleCalendarOpen = () => {
    setIsOpen(true)
  }

  const handleCalendarClose = () => {
    setIsOpen(false)
  }

  const handlePrevWeek = () => {
    handleChangeWeek(getDateOfWeek(selectedWeek, 'prev'));
  }

  const handleNextWeek = () => {
    handleChangeWeek(getDateOfWeek(selectedWeek, 'next'));
  }

  const getDateOfWeek = (date, type) => {
    let currentWeek = new Date(date);
    const dayOfWeek = currentWeek.getDay();
    const theDate = new Date(currentWeek);
    if(type == 'prev'){
      const daysSinceLastMonday = (dayOfWeek + 6) % 7;
      theDate.setDate(currentWeek.getDate() - (daysSinceLastMonday + 7))
    }else{
      const daysUntilNextMonday = (8 - dayOfWeek) % 7 || 7;
      theDate.setDate(currentWeek.getDate() + daysUntilNextMonday);
    }
    return new Date(theDate.setHours(0, 0, 0, 0));
  }

  return(
    <Fragment>
      <div className={`week-selector-datepicker flex items-center`}>
        {calendarViewType == 'week' &&
          <div className="flex">
            <button className="month-change-button month-change-left-button" onClick={() => handlePrevWeek()}>
              <AngleIcon classNames={'ml-auto text-gray-900 h-5 w-5'}/>
            </button>
            <button className="month-change-button month-change-right-button" onClick={() => handleNextWeek()}>
              <AngleIcon classNames={'ml-auto text-gray-900 h-5 w-5'}/>
            </button>
          </div>
        }
        <DatePicker
          className={``}
          selected={calendarViewType == 'week' ? selectedWeek : currentDate}
          onChange={(e) => handleChangeWeek(e)}
          customInput={<ExampleCustomInput />}
          onCalendarOpen={handleCalendarOpen}
          onCalendarClose={handleCalendarClose}
        />
      </div>
    </Fragment>
  )
}