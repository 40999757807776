import React, { Fragment } from "react";
import { ExclamationIcon, AngleIcon } from '../../../assets';

export default function ApprovalErrorWarningComponent(props) {
  const { approvalErrorEventsCount, setShowApprovalErrorEventsPopup } = props

  return (
    <Fragment>
      <button className="border-2 border-gray-200 bg-white rounded-2xl flex justify-center py-1 px-4 text-md font-semibold  items-center gap-1" onClick={() => {setShowApprovalErrorEventsPopup(true)}}>
        <ExclamationIcon classNames={"text-red-600 h-4 w-4 inline-block ml-1"} componentId={'approval-error-warning-no-tooltip'} />
        {`Warning! ${approvalErrorEventsCount} Approval Error${approvalErrorEventsCount > 1 ?'s' : ''}`}
        <AngleIcon classNames={'-rotate-90 w-4 transform'}/>
      </button>
    </Fragment>
  )
}