import React, { Fragment, useEffect, useState } from "react";
import { extractAttachments, photosFilterMethod, EmptyListComponent, linksFilterMethod } from './heplers'
import LoadingIcon from '../../../../subRequests/assets/loadingIcon';
import AttachmentCard from './attachmentCard';

export default function AttachmentsComponent(props) {
  const { attachmentsLoading, attachmentMessages, name } = props
  const [ attachments, setAttachments ] = useState(extractAttachments(attachmentMessages, (name == 'Links' ? linksFilterMethod : photosFilterMethod)) || [])

  useEffect(() => {
    setAttachments(extractAttachments(attachmentMessages, (name == 'Links' ? linksFilterMethod : photosFilterMethod)) || [])
  }, [attachmentMessages])

  return (
    <Fragment>
      <div className="flex border-b py-4">
        <div className="text-md font-medium text-gray-400 w-72 flex-shrink-0">
          {name}
        </div>
        <div className="flex items-center gap-2 flex-wrap relative">
          {attachmentsLoading && <div className="absolute -left-7 top-1"><LoadingIcon classNames={'animate-spin h-4 w-4 text-black'}/></div>}
          {attachments.length === 0 && <EmptyListComponent type={name.toLowerCase()}/>}
          {attachments.map((item, i) => 
            <div key={i}>
              <AttachmentCard item={item} i={i} type={name.toLowerCase()}/>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}