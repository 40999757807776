import React, { Fragment, useEffect, useState } from 'react';
import ContactInfoEdit from './../contactInformation/contactInfoEdit'

export default function NotificationSettingsEdit(props){
  const { user, setEditNotificationSettings, setNotificationSettings, showPopup } = props
  const [ notifications, setNotifications ] = useState(user?.notifications || '')
  const [ reminders, setReminders ] = useState(user?.reminders || '')
  const [ dailyNotifications, setDailyNotifications ] = useState(user?.daily_notifications || '')
  const [ enableMessengerNotifications, setEnableMessengerNotifications ] = useState(user?.enable_messenger_notifications || false)
  const [ messengerNotificationsType, setMessengerNotificationsType ] = useState(user?.messenger_notifications_type || '')
  const [ resourceNotificationType, setResourceNotificationType ] = useState(user?.resource_notification_type || '')
  const [ away, setAway ] = useState(user?.away || false)
  const [ editContactInfo, setEditContactInfo ] = useState(false)
  const [ pendingNotificationsUpdate, setPendingNotificationsUpdate ] = useState('')
  const [ currentUser, setCurrentUser ] = useState(user)
  const [ allowVerification, setAllowVerification ] = useState(user?.allow_phone_verification || false)

  useEffect(() => {
    setAllowVerification(user?.allow_phone_verification || false)
  },[user])

  const handleCheckBox = (e) => {
    let name = e.target.name
    let value = e.target.value
    if (name == 'notifications'){
      if(['sms', 'all'].includes(value) && notifications !== value){
        if (!currentUser?.phone_verified){
          setEditContactInfo(true)
          setPendingNotificationsUpdate(value)
        }else{
          setNotifications(value)
        }
      }else{
        setNotifications(value)
      }
    }else if(name == 'reminders'){
      setReminders(value)
    }
    else if(name == 'dailyNotifications'){
      setDailyNotifications(value)
    }else if(name == 'enableMessengerNotifications'){
      setEnableMessengerNotifications(value == 'true')
    }else if(name == 'messengerNotificationsType'){
      setMessengerNotificationsType(value)
    }else if(name == 'resourceNotificationType'){
      setResourceNotificationType(value)
    }else if(name == 'away'){
      setAway(value == 'true')
    }
  }

  const handleSaveNotificationSettings = () => {
    let params = {
      notifications: notifications, 
      daily_notifications: dailyNotifications, 
      reminders: reminders, 
      away: away, 
      enable_messenger_notifications: enableMessengerNotifications, 
      messenger_notifications_type: messengerNotificationsType, 
      resource_notification_type: resourceNotificationType,
      push_notifications: user?.push_notifications || false
    }
    setNotificationSettings(params)
  }

  const handleShowPopup = (show, alert_type, alert_message) => {
    showPopup(show, alert_type, alert_message)
  }

  const handlePhoneVerified = () => {
    setCurrentUser({...user, phone_verified: true})
    if(pendingNotificationsUpdate !== ""){
      setNotifications(pendingNotificationsUpdate)
    }
    let params = new URLSearchParams(window.location.search)
    params.set('reload_user', 'true')
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, null, newUrl);
  }

  return(
    <React.Fragment>
      {editContactInfo && 
        <ContactInfoEdit user={user} editContactInfo={editContactInfo} setEditContactInfo={setEditContactInfo} showPopup={handleShowPopup} handlePhoneVerified={handlePhoneVerified}/>
      }
      <div className='bg-white flex flex-col rounded-md shadow px-6 py-7 mb-5'>
        <div className="flex items-center mb-3 pb-5 border-b border-gray-200">
          <div className="text-lg font-medium flex-auto">Update Notification Settings</div>
        </div>
        {user?.sms_notifications &&
          <div className={`sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3 border-b border-gray-200`}>
            <label className="block text-sm font-normal text-gray-500">
              {!away ?
                'How would you like to receive subbing notifications?'
                :
                'Notifications are OFF'
              }
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
              <div className='text-sm font-normal text-gray-900 flex gap-3'>
                {allowVerification &&
                  <Fragment>
                    {(!user?.sms_notifications_restriction && user?.sms_notifications && (user?.directory_phone_number == "")) &&
                      <label className="radio-inline notifications-radio-btns disabled text-gray-400 cursor-not-allowed flex items-center gap-1"> <input type="radio" className='text-gray-400 cursor-not-allowed' name='notifications' checked={notifications == "sms"} value="sms" onChange={(e) => handleCheckBox(e)} disabled={true}/> SMS only</label>
                    }
                    {(!user?.sms_notifications_restriction && user?.sms_notifications && user?.directory_phone_number !== "") &&
                      <label className={`radio-inline notifications-radio-btns flex items-center gap-1 ${away ? 'disabled text-gray-400 cursor-not-allowed' : ''}`}> <input type="radio" className={`${away ? 'text-gray-400 cursor-not-allowed' : ''}`} name='notifications' checked={notifications == "sms"} value="sms" onChange={(e) => handleCheckBox(e)} disabled = {away == true}/> SMS only</label>
                    }
                  </Fragment>
                }
                <label className={`radio-inline notifications-radio-btns flex items-center gap-1 ${away ? 'disabled text-gray-400 cursor-not-allowed' : ''}`}> <input type="radio" className={`${away ? 'text-gray-400 cursor-not-allowed' : ''}`} name='notifications' checked={notifications == "email"} value="email" onChange={(e) => handleCheckBox(e)} disabled = {away}/> Email only</label>
                {allowVerification &&
                  <Fragment>
                    {(user?.directory_phone_number == '') &&
                      <label className="radio-inline notifications-radio-btns flex items-center gap-1 disabled text-gray-400 cursor-not-allowed"> <input type="radio" checked={notifications == "all"} className='text-gray-400 cursor-not-allowed' name='notifications' value="all" onChange={(e) => handleCheckBox(e)} disabled={true}/> SMS and Email</label>
                    }
                    {user?.sms_notifications && user?.directory_phone_number != '' &&
                      <label className={`radio-inline notifications-radio-btns flex items-center gap-1 ${away ? 'disabled text-gray-400 cursor-not-allowed' : ''}`}> <input type="radio" checked={notifications == "all"} className={`${away ? 'text-gray-400 cursor-not-allowed' : ''}`} name='notifications' value="all" onChange={(e) => handleCheckBox(e)} disabled = {away}/> SMS and Email</label>
                    }
                  </Fragment>
                }
              </div>
            </div>
          </div>
        }
        <div className={`sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3 border-b border-gray-200`}>
          <label className="block text-sm font-normal text-gray-500">
            How long before a class would you like to receive a reminder notification that you are subbing?
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
            <div className='text-sm font-normal text-gray-900 flex gap-3'>
              <label className={`radio-inline notifications-radio-btns flex items-center gap-1`}> <input type="radio" name='reminders' checked={reminders == "24hrs"} value="24hrs" onChange={(e) => handleCheckBox(e)}/> 24 hours before</label>
              <label className={`radio-inline notifications-radio-btns flex items-center gap-1`}> <input type="radio" name='reminders' checked={reminders == "2hrs"} value="2hrs" onChange={(e) => handleCheckBox(e)}/> 2 hours before</label>
            </div>
          </div>
        </div>
        <div className={`sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3 border-b border-gray-200`}>
          <label className="block text-sm font-normal text-gray-500">
            How often do you want to receive sub request invites from your team?
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
            <div className='text-sm font-normal text-gray-900'>
              <div className='flex gap-3 mb-2'>
                <label className={`radio-inline notifications-radio-btns flex items-center gap-1`}> <input type="radio" name='dailyNotifications' checked={dailyNotifications == "daily"} value="daily" onChange={(e) => handleCheckBox(e)}/> Daily Summary (once per day)</label>
                <label className={`radio-inline notifications-radio-btns flex items-center gap-1`}> <input type="radio" name='dailyNotifications' checked={dailyNotifications == "immediately"} value="immediately" onChange={(e) => handleCheckBox(e)}/> Send me every invite immediately</label>
              </div>
              <span className="text-xs text-gray-500 font-normal pt-2">
                *You’ll receive an individual notification for any sub request sent to you within 24 hours of the start time of the class to ensure the class gets covered.
              </span>
            </div>
          </div>
        </div>
        {((user?.solidcore_gym) && !user?.new_messenger && user?.mailbox_feature) &&
          <div className={`sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3 border-b border-gray-200`}>
            <label className="block text-sm font-normal text-gray-500">
              Do you want to receive Messenger notifications?
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
              <div className='text-sm font-normal text-gray-900 gap-3'>
                <label className={`radio-inline notifications-radio-btns flex items-center gap-1`}> <input type="radio" name='enableMessengerNotifications' checked={enableMessengerNotifications} value={true} onChange={(e) => handleCheckBox(e)}/> Yes</label>
                <label className={`radio-inline notifications-radio-btns flex items-center gap-1`}> <input type="radio" name='enableMessengerNotifications' checked={!enableMessengerNotifications} value={false} onChange={(e) => handleCheckBox(e)}/> No</label>
              </div>
            </div>
          </div>
        }
        {(user?.new_messenger && user?.mailbox_feature) &&
          <div className={`sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3 border-b border-gray-200`}>
            <label className="block text-sm font-normal text-gray-500">
              How would you like to receive Post notifications?
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
              <div className='text-sm font-normal text-gray-900 gap-3 flex'>
                <label className={`radio-inline notifications-radio-btns flex items-center gap-1 ${away ? 'disabled text-gray-400 cursor-not-allowed' : ''}`}> <input type="radio" className={`${away ? 'text-gray-400 cursor-not-allowed' : ''}`} name='messengerNotificationsType' checked={messengerNotificationsType == "email_and_push"} value="email_and_push" onChange={(e) => handleCheckBox(e)} disabled = {away}/> Email and Mobile Push</label>

                <label className={`radio-inline notifications-radio-btns flex items-center gap-1 ${away ? 'disabled text-gray-400 cursor-not-allowed' : ''}`}> <input type="radio" className={`${away ? 'text-gray-400 cursor-not-allowed' : ''}`} name='messengerNotificationsType' checked={messengerNotificationsType == "email"} value="email" onChange={(e) => handleCheckBox(e)} disabled = {away}/> Email only</label>

                <label className={`radio-inline notifications-radio-btns flex items-center gap-1 ${away ? 'disabled text-gray-400 cursor-not-allowed' : ''}`}> <input type="radio" className={`${away ? 'text-gray-400 cursor-not-allowed' : ''}`} name='messengerNotificationsType' checked={messengerNotificationsType == "push"} value="push" onChange={(e) => handleCheckBox(e)} disabled = {away}/> Mobile Push Only</label>

                <label className={`radio-inline notifications-radio-btns flex items-center gap-1 ${away ? 'disabled text-gray-400 cursor-not-allowed' : ''}`}> <input type="radio" className={`${away ? 'text-gray-400 cursor-not-allowed' : ''}`} name='messengerNotificationsType' checked={messengerNotificationsType == "off"} value="off" onChange={(e) => handleCheckBox(e)} disabled = {away}/> Off</label>
              </div>
            </div>
          </div>
        }
        {(user?.resource_hub) &&
          <div className={`sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3 border-b border-gray-200`}>
            <label className="block text-sm font-normal text-gray-500">
              How would you like to receive Resource notifications?
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
              <div className='text-sm font-normal text-gray-900 gap-3 flex'>
                <label className={`radio-inline notifications-radio-btns flex items-center gap-1 ${away ? 'disabled text-gray-400 cursor-not-allowed' : ''}`}> <input type="radio" className={`${away ? 'text-gray-400 cursor-not-allowed' : ''}`} name='resourceNotificationType' checked={resourceNotificationType == "email_and_push"} value="email_and_push" onChange={(e) => handleCheckBox(e)} disabled = {away}/> Email and Mobile Push</label>

                <label className={`radio-inline notifications-radio-btns flex items-center gap-1 ${away ? 'disabled text-gray-400 cursor-not-allowed' : ''}`}> <input type="radio" className={`${away ? 'text-gray-400 cursor-not-allowed' : ''}`} name='resourceNotificationType' checked={resourceNotificationType == "email"} value="email" onChange={(e) => handleCheckBox(e)} disabled = {away}/> Email only</label>

                <label className={`radio-inline notifications-radio-btns flex items-center gap-1 ${away ? 'disabled text-gray-400 cursor-not-allowed' : ''}`}> <input type="radio" className={`${away ? 'text-gray-400 cursor-not-allowed' : ''}`} name='resourceNotificationType' checked={resourceNotificationType == "push"} value="push" onChange={(e) => handleCheckBox(e)} disabled = {away}/> Mobile Push Only</label>

                <label className={`radio-inline notifications-radio-btns flex items-center gap-1 ${away ? 'disabled text-gray-400 cursor-not-allowed' : ''}`}> <input type="radio" className={`${away ? 'text-gray-400 cursor-not-allowed' : ''}`} name='resourceNotificationType' checked={resourceNotificationType == "off"} value="off" onChange={(e) => handleCheckBox(e)} disabled = {away}/> Off</label>
              </div>
            </div>
          </div>
        }
        <div className={`sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3`}>
          <label className="block text-sm font-normal text-gray-500">
            I'm away (on vacation, taking time off, etc)
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
            <div className='text-sm font-normal text-gray-900 flex gap-3'>
              <label className={`radio-inline notifications-radio-btns flex items-center gap-1`}> <input type="radio" name='away' checked={!away} value={false} onChange={(e) => handleCheckBox(e)}/> Turn on sub notifications</label>
              <label className={`radio-inline notifications-radio-btns flex items-center gap-1`}> <input type="radio" name='away' checked={away} value={true} onChange={(e) => handleCheckBox(e)}/> Turn off sub notifications</label>
            </div>
          </div>
        </div>
        <div className="flex mt-4 gap-3 md:justify-end">
          <button className="text-base rounded-md text-gray-700 font-medium border border-gray-200 px-6 py-3" onClick={() => setEditNotificationSettings(false)}>
            Cancel
          </button>
          <button className={`flex text-base font-medium rounded-md text-white px-4 py-3 gap-2 items-center ${ 'bg-dark-blue'}`} onClick={(e) => handleSaveNotificationSettings(e)}>
            <svg viewBox="0 0 14 10" className="h-4 w-4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M13.4226 0.244078C13.7481 0.569515 13.7481 1.09715 13.4226 1.42259L5.0893 9.75592C4.76386 10.0814 4.23622 10.0814 3.91078 9.75592L0.577452 6.42259C0.252015 6.09715 0.252015 5.56951 0.577452 5.24408C0.902889 4.91864 1.43053 4.91864 1.75596 5.24408L4.50004 7.98816L12.2441 0.244078C12.5696 -0.0813592 13.0972 -0.0813592 13.4226 0.244078Z" fill="#ffffff"/>
            </svg>
            Save
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}