import React, { Component, Fragment } from 'react'
import { getSudomain, setTitle, timeZones, getBusiness } from './../../helpers'
import { removeSite, addSite, activateSite, validateSite, syncStaff, updateMboSettings } from './helpers'
import Cookies from 'js-cookie';
import ReactTooltip from "react-tooltip";
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Switch } from '@headlessui/react'

export default class MboDetails extends Component {
  constructor(props){
    super(props);
    this.state = {
      business: props.business,
      show: false,
      showModal: false,
      alert_type: '',
      alert_message: '',
      showError: false,
      delete_site: '',
      time_zones: timeZones(),
      sites: {},//props.business?.mindbody_details,
      activationModal: false,
      activationCode: '',
      activationLink: '',
      businessData: {},
      loaded: false,
      syncingStaff: false,
      sendSubstituteTeacherEmailDisabled: false
    }
  }

  componentDidMount = () => {
    setTitle('mbo')
    if(this.props.pageLoaded){
      this.businessData()
    }
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  businessData = () => {
    getBusiness(Cookies.get(`currentUser.${getSudomain()}`),  getSudomain()).then(response => response.json())
    .then(result => {
      this.setState({
        business: result.business,
        businessData: result.business,
        sites: result.business?.mindbody_details,
        loaded: true,
        dataLoaded: true
      })
      // localStorage.setItem("businessID", result.business.id);
    })
  }

  handleDelete(e, i, site){
    e.preventDefault()
    this.setState({
      showModal: true,
      delete_site: i,
      deletable: site
    })
  }

  closeModal(){
    this.setState({showModal: false, activationModal: false})
    setTimeout(function(){ window.location.reload() }, 1500);
  }

  handleChange = (e, i) => {
    e.preventDefault()
    let sites = [...this.state.sites];
    sites[i][e.target.name] = e.target.value;
    this.setState({ sites });
  }

  handleAdd = (e) => {
    e.preventDefault()
    this.setState(prevState => ({
      sites: [...prevState.sites, { id: '', site_id: '', site_name: '', time_zone: '' }]
    }));
  }

  handleRemove = (i, e, site) => {
    e.preventDefault()
    if(site?.site_id === ''){
      let sites = [...this.state.sites];
      sites.splice(i, 1);
      this.setState({
        sites: sites,
        showModal: false
      });
    }else{
      removeSite(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), site.site_id).then(
        response => response.json()
      )
      .then(result => {
        if(result.status === 200){
          this.setState({
            show: true,
            showModal: false,
            alert_message: result.success,
            alert_type: 'success',
          })
          setTimeout(function(){ window.location.reload() }, 1500);
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger',
            show: true,
            loaded: true
          })
        }
      })
    }
  }

  save = (e, site) => {
    e.preventDefault()
    addSite(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), site).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          show: true,
          showModal: false,
          alert_message: result.success,
          alert_type: 'success',
        })
        setTimeout(function(){ window.location.reload() }, 1500);
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          loaded: true
        })
      }
    })
  }

  validate = (e, site) => {
    e.preventDefault()
    validateSite(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), site.site_id).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          show: true,
          alert_message: result.success,
          alert_type: 'success',
        })
         setTimeout(function(){ window.location.reload() }, 1500);
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          loaded: true
        })
      }
    })
  }

  activate = (e, site) => {
    e.preventDefault()
    activateSite(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), site.site_id).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          show: true,
          activationModal: true,
          alert_message: result.success,
          alert_type: 'success',
          activationCode: result.mindbody_detail?.activation_code,
          activationLink: result.mindbody_detail?.activation_link
        })
        //setTimeout(function(){ window.location.reload() }, 1500);
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          loaded: true
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  removeButtons = (allSites, site, index) => {
    let all_sites = allSites
    if(site?.id === ''){
      return true
    }
    else if(site?.id !== '') {
      if(all_sites[index]?.site_name === site?.site_name && all_sites[index]?.site_id.toString() === site?.site_id.toString() && all_sites[index]?.time_zone === site?.time_zone){
        return false
      }
      else {
        return true
      }
    }

    return true
  }

  handleSyncStaff = (evt, site) => {
    evt.preventDefault()
    if (this.state.syncingStaff) {
      return;
    }

    this.setState({ syncingStaff: true });

    syncStaff(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(
      response => response.json()
    ).then(result => {
      this.setState({
        syncingStaff: result.status === 200,
        show: true,
        alert_message: result.status === 200 ? result.success : result.error,
        alert_type: result.status === 200 ? 'success' : 'danger'
      }, () => {
        window.setTimeout(() => {
          this.setState({ show: false })
        }, 5000);
        window.setTimeout(() => {
          this.setState({ syncingStaff: false });
        }, result.lockExp * 1000);
      });
    });
  };

  handleSendSubstituteTeacherEmail = (e, name) => {
    this.setState({
      business: {
        ...this.state.business,
        [name]: e
      },
      sendSubstituteTeacherEmailDisabled: true
    })

    let params = {
      send_client_email: this.state.business?.send_client_email,
      multi_site_multi_staff_feature: this.state.business?.multi_site_multi_staff_feature,
    }
    params[name] = e
    updateMboSettings(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), params).then(
      response => response.json()
    ).then(result => {
      if(result.status === 200) {
        this.setState({
          sendSubstituteTeacherEmailDisabled: false,
          show: true,
          alert_message: result?.message,
          alert_type: 'success',
        })
      }else{
        this.setState({
          sendSubstituteTeacherEmailDisabled: false,
          alert_message: result?.error,
          alert_type: 'danger',
          show: true,
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
    
  };

  render() {
    const { sites, show, alert_type, alert_message, deletable, showModal, delete_site, time_zones, business, activationModal, activationCode, activationLink, businessData, loaded, dataLoaded } = this.state;
    const { loadingMessage } = this.props
    const { mindbody_details } = this.props.business

    return (
      <React.Fragment>
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto" onClick={() => this.setState({ show: false })}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto" onClick={() => this.setState({ show: false })}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
          </React.Fragment>
        }
        <div className='w-full min-h-screen bg-gray-50'>
          <div className='h-full'>
            {!loaded &&
              <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                <p className="mb-1 text-center px-5">{loadingMessage}</p>
                <img className = "w-9" src="/assets/business/loading.gif" />
              </div>
            }
            {loaded &&
              <div className='max-w-8xl mx-auto h-full'>
                <div className='flex flex-wrap gap-4 justify-between'>
                  <div className='left-0'>
                    <h3 className='flex flex-wrap gap-3 text-lg items-center'>
                      Mindbody Details
                      <a className="powered-by-mindbody-logo flex items-center ml-2 mb-0.5" href="https://company.mindbodyonline.com/" target="_blank">
                        <img className="mindbody-logo w-28" src="/assets/powered-by-mindbody-new.png" />
                      </a>
                    </h3>
                  </div>
                  <div className='right-0'>
                    <div className='flex gap-2'>
                      {this.props.user?.role_display === "Netgym Employee" && !business.mbo_staff_auto_sync_disabled && (
                        <a className={`bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 flex cursor-pointer`} onClick={(e) => this.handleSyncStaff(e)} >
                          <FontAwesomeIcon size="lg" icon={this.state.syncingStaff ? faSpinner : faUsers} spin={this.state.syncingStaff} />
                          &nbsp;Synchronize Staff
                        </a>
                      )}
                      <a className={`bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 flex cursor-pointer`} onClick = {(e)=> this.handleAdd(e)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                        </svg>
                        Add new Site ID
                      </a>
                    </div>
                  </div>
                </div>

                <div className='mt-5 mb-2'>
                  <div className='bg-white break-words p-3 border border-gray-200 rounded-lg'>
                    <p className="text-gray-500 text-sm font-normal">
                      Please activate your MBO account by clicking on the link, or adding the activation code to API Integrations at the bottom right, under MINDBODY Add-Ons. Once you add Netgym, please validate the credentials here to complete the setup.
                    </p>
                  </div>
                </div>

                <div className='overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4'>
                  <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                    {!dataLoaded &&
                      <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                        <p className="mb-1 text-center px-5">{loadingMessage}</p>
                        <img className = "w-9" src="/assets/business/loading.gif" />
                      </div>
                    }
                    {dataLoaded &&
                      <Fragment>
                        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start pt-2 pb-4'>
                          <label className="block text-sm font-medium text-gray-500 sm:mt-px">
                            Send Substitute Teacher email
                          </label>
                          <div className='mt-1 item-center sm:mt-0 sm:col-span-2 flex gap-2'>
                            <Switch
                              id={`send_client_email-1`}
                              checked={business?.send_client_email}
                              name="send_client_email"
                              onChange={(e) => this.handleSendSubstituteTeacherEmail (e, "send_client_email")}
                              disabled={this.state?.sendSubstituteTeacherEmailDisabled}
                              className={`${business?.send_client_email ? 'bg-gray-900' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ${this.state?.sendSubstituteTeacherEmailDisabled ? 'cursor-not-allowed opacity-50' : ''}`}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={`${business?.send_client_email ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                              />
                            </Switch>
                            <label className='ml-2' htmlFor="send_client_email-1">
                              {business?.send_client_email ? 'Yes' : 'No'}
                            </label>
                          </div>
                        </div>
                        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 pt-4 pb-6'>
                          <label className="block text-sm font-medium text-gray-500 sm:mt-px">
                            Activate Multi-site Multi-staff
                          </label>
                          <div className='mt-1 item-center sm:mt-0 sm:col-span-2 flex gap-2'>
                            <Switch
                              id={`multi_site_multi_staff_feature-1`}
                              checked={business?.multi_site_multi_staff_feature}
                              name="multi_site_multi_staff_feature"
                              onChange={(e) => this.handleSendSubstituteTeacherEmail (e, "multi_site_multi_staff_feature")}
                              disabled={this.state?.sendSubstituteTeacherEmailDisabled}
                              className={`${business?.multi_site_multi_staff_feature ? 'bg-gray-900' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ${this.state?.sendSubstituteTeacherEmailDisabled ? 'cursor-not-allowed opacity-50' : ''}`}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={`${business?.multi_site_multi_staff_feature ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                              />
                            </Switch>
                            <label className='ml-2' htmlFor="multi_site_multi_staff_feature-1">
                              {business?.multi_site_multi_staff_feature ? 'Yes' : 'No'}
                            </label>
                          </div>
                        </div>

                        <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                          {sites?.map((site, i) =>
                            <div className="save-mindbody px-3 py-2 bg-white" key ={i}>
                              <div className='flex items-center'>
                                <div className='grid sm:grid-cols-8 gap-5'>
                                  <div className='sm:col-span-2'>
                                    <div className="block text-sm font-medium text-gray-500">Site ID</div>
                                    <div className='mt-1 sm:mt-0 sm:col-span-2'>
                                      <input type="text" required className='border rounded-md px-2 py-1.5 w-full shadow-sm' defaultValue={site?.site_id} name = "site_id" onChange = {(e)=> this.handleChange(e, i)}/>
                                    </div>
                                  </div>
                                  <div className='sm:col-span-2'>
                                    <div className="block text-sm font-medium text-gray-500">Site Name</div>
                                    <div className='mt-1 sm:mt-0 sm:col-span-2'>
                                      <input type="text" className='border rounded-md px-2 py-1.5 w-full shadow-sm' defaultValue={site?.site_name} name = "site_name" onChange = {(e)=> this.handleChange(e, i)}/>
                                    </div>
                                  </div>
                                  <div className='sm:col-span-2'>
                                    <div className="block text-sm font-medium text-gray-500" >Time Zone</div>
                                    <div className='mt-1 sm:mt-0 sm:col-span-2'>
                                      <select className='form-select max-w-lg h-9 block border shadow-sm w-full sm:max-w-xs sm:text-sm bg-white rounded-md' name="time_zone" onChange = {(e)=> this.handleChange(e, i)} defaultValue={site?.time_zone}>
                                        <option value=''>Time Zone</option>
                                        {time_zones.map((zone,i) =>
                                          <option key={i} value={zone.key}>{zone.value}</option>
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="flex items-center w-40 gap-2 sm:mt-5">
                                  <a data-tip data-for="deleteSite" onClick = {(e)=> this.handleDelete(e, i, site)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-900 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                      <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                    </svg>
                                  </a>
                                  <ReactTooltip id="deleteSite" place="top" effect="solid">
                                    Delete Site ID
                                  </ReactTooltip>
                                  {site?.id !== '' &&
                                    <React.Fragment>
                                      <a className="" data-tip data-for={`getActivation_${i}`} onClick={(e) => this.activate(e, site)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-900 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                          <path fillRule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clipRule="evenodd" />
                                        </svg>
                                        {/* <img className='w-4' src="/assets/get-activation-code.svg" /> */}
                                      </a>
                                      <ReactTooltip id={`getActivation_${i}`} place="top" effect="solid">
                                        {site?.activation_code ? 'Re-Activate' : 'Get Activation Code'}
                                      </ReactTooltip>
                                      {site?.activation_code &&
                                        <React.Fragment>
                                          {!site?.validated &&
                                            <React.Fragment>
                                              <input type='checkbox' onClick={(e) => this.validate(e, site)} data-tip data-for={`validate_${i}`} className='cursor-pointer' />
                                              <ReactTooltip id={`validate_${i}`} place="top" effect="solid">
                                                Validate
                                              </ReactTooltip>
                                            </React.Fragment>
                                          }
                                        </React.Fragment>
                                      }
                                    </React.Fragment>
                                  }
                                  {this.removeButtons(mindbody_details, site, i) &&
                                    <a className="bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 flex cursor-pointer gap-2" onClick={(e) => this.save(e, site)}>Save</a>
                                  }
                                </div>
                                </div>

                              </div>
                            </div>
                          )}
                        </div>
                      </Fragment>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <Transition.Root show={showModal || activationModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeModal(e)}>
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>

							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${activationModal ? 'sm:max-w-2xl' : 'sm:max-w-lg'} sm:w-full sm:p-6`}>
									<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
											onClick={(e) => this.closeModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										{/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
										</div> */}
										<div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left">
											<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        {showModal &&
                          `Delete Site ID`
                        }
                        {activationModal &&
                          `Mindbody Activation Details`
                        }
											</Dialog.Title>
											<div className="mt-5">
                        {showModal &&
                          <p className="text-sm text-gray-500">Are you sure you want to delete MBO Site {deletable?.site_name}</p>
                        }
                        {activationModal &&
                          <React.Fragment>
                            <div className="flex mb-3 gap-4">
                              <p className='text-gray-700 w-36'>Activation Code</p>
                              <p className="text-sm text-gray-500">{activationCode}</p>
                            </div>
                            <div className="flex gap-4">
                              <p className='text-gray-700 w-36'>Activation Link</p>
                              <p className="text-sm text-gray-500 break-all w-96">{activationLink}</p>
                            </div>
                          </React.Fragment>
                        }
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    {showModal &&
                      <button
                        type="button"
                        className="cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-blue text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={(e) => this.handleRemove(delete_site, e, deletable)}
                      >
                        Delete Site
                      </button>
                    }
                    {activationModal &&
                      <button
                        type="button"
                        className="cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-blue text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={(e) => this.closeModal()}
                      >
                        OK
                      </button>
                    }
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
      </React.Fragment>
    )
  }
}
