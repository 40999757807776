export const ResourceThumbnailMinHeight = 300;
export const ResourceThumbnailMinWidth = 300;
export const ResourceThumbnailMaxHeight = 800;
export const ResourceThumbnailMaxWidth = 800;

export function getSudomain() {
    let subdomain = ''
    let host = window.location.host;
    if (host.split('.').length < 3){
      subdomain = null
    } else {
      subdomain = host.split('.')[0];
    }
    return subdomain
  }
  
  export function getHeaders(token, subdomain){
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID") || subdomain
    }
    return obj
  }

  export const getFilterSkills = async (user_token, subdomain) => {  
    return await fetch(`/api/v3/resource_hub/filters`,{
      headers: getHeaders(user_token, subdomain)
    });
  }

  export const getFilterSkillsOptions = async (user_token, subdomain) => {  
    return await fetch(`/api/v3/resource_hub/filters`,{
      headers: getHeaders(user_token, subdomain)
    });
  }

  export const uploadFile = async (user_token, subdomain, file) => { 
    const formData = new FormData();
		formData.append(
			"uploadFile",
			file,
			file.name
		); 
    return await fetch(`api/v3/resource_hub/upload`,{
        method: "POST",    
        body: formData,
        headers: {
          'Accept': 'application/vnd.netgym.v3',
          'Authorization': eval(`${user_token}`),
          'subdomain': localStorage.getItem("businessID")
        }
    });
  }

  export const dragAndMoveFile = async (e, user_token,file_id_to_move,destination_folder_name, folder) => { 
    const subdomain = localStorage.getItem("businessID");
    const authorization = eval(`${user_token}`);
    let params = new URLSearchParams();
    params.append('resource_id', file_id_to_move);
    params.append('destination_folder_name', destination_folder_name); 
    params.append('folder_path', folder)   
    return await fetch(`/api/v3/resource_hub/move_to_folder?subdomain=${subdomain}&authorization=${authorization}}`,{
        method: "PUT",    
        body: params,
        headers: {
          'Accept': 'application/vnd.netgym.v3',
          'Content-Type': 'multipart/form-data',
          'Authorization': eval(`${user_token}`),
          'subdomain': localStorage.getItem("businessID")
        }
    });
  }

	export const showFile = async (user_token, subdomain) => {  
    return await fetch(`/api/v3/resource_hub/show`,{
      headers: getHeaders(user_token, subdomain)
    });
  }

  export const getRegionsLocations = async (user_token, subdomain) => {    
    return await fetch(`/api/v3/get_users_settings_locations`,{
      headers: getHeaders(user_token, subdomain)
    });
  }

  export const getFilterResource = async (user_token, subdomain, search, locations, skills, folder,sortType, sortReverse, draftSortType, draftSortReverse) => {    
    return await fetch(`/api/v3/get_filtered_resources?search=${search}&locations=${locations}&skills=${skills}&folder=${folder}&sort_type=${sortType}&sort_reverse=${sortReverse}&draft_sort_type=${draftSortType}&draft_sort_reverse=${draftSortReverse}`,{
      headers: getHeaders(user_token, subdomain)
    });  
  }
  
  export const getResourceData = async (user_token, subdomain, id, folder) => {    
    return await fetch(`/api/v3/resource_detail?id=${id}&folder=${folder}`,{
      headers: getHeaders(user_token, subdomain)
    });  
  }

  export const getFolerData = async (user_token, subdomain, folder) => {    
    return await fetch(`/api/v3/resource_folder_detail?folder=${folder}`,{
      headers: getHeaders(user_token, subdomain)
    });  
  }

  export const updateFolderName = async (user_token, subdomain, folderName, folder) => {    
    return await fetch(`/api/v3/resource_hub/folder_edit?folderName=${folderName}&folder=${folder}`,{
      headers: getHeaders(user_token, subdomain)
    });  
  }

  export const createFolder = async (user_token, subdomain, folder) => {    
    return await fetch(`/api/v3/resource_hub/folder_create?folder=${folder}`,{
      headers: getHeaders(user_token, subdomain)
    });  
  }

  export const destroyFolder = async (user_token, subdomain, folderName, folder) => {  
    return await fetch(`/api/v3/resource_hub/folder_delete?folderName=${folderName}&folder=${folder}`,{
      method: "DELETE",
      headers: getHeaders(user_token, subdomain)
    });
  }

  export const destroyFile = async (user_token, subdomain, fileId, folder) => {  
    return await fetch(`/api/v3/resource_hub/delete_resource?id=${fileId}&folder=${folder}`,{
      method: "DELETE",
      headers: getHeaders(user_token, subdomain)
    });
  }

  export const saveComment = async (user_token, subdomain, fileId, folder, comment) => {  
    return await fetch(`/api/v3/resource_hub/comment_save?id=${fileId}&folder=${folder}&comment=${comment}`,{
      method: "POST",
      headers: getHeaders(user_token, subdomain)
    });
  }

  export const saveFile = async (user_token, subdomain, fileId, folder, fileData) => { 
    return await fetch(`/api/v3/resource_hub/save_resource`,{
      method: "POST",
      headers: getHeaders(user_token, subdomain),
      body: JSON.stringify({ id: fileId, folder: folder, fileData: fileData }),
    });
  }

  export const saveReactions = async (user_token, subdomain, fileId, folder, reactions) => { 
    return await fetch(`/api/v3/resource_hub/reactions`,{
      method: "POST",
      headers: getHeaders(user_token, subdomain),
      body: JSON.stringify({ business_resource_id: fileId, folder: folder, emoji: reactions })
    });
  }

  export const removeExtensionFromName = (str) => {
    let arr = str.split('.')
    let fileName = ''
    if (arr.length > 2) {
      let s=[]
      for(let i=0;i< arr.length-1;i++){
          // s+=arr[i]+".";
          s.push(arr[i])
  
      }
     fileName = s.join('.')
  
    } else {
        fileName = arr[0]
        // ext=arr[1];
    }
    return fileName
  }

  export const snakeCase = (string) => {
    return string?.replace(/\d+/g, ' ')
      .split(/ |\B(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join('_');
  }
  
  export const getName = (role) => {
    let name = ""
    switch(role){
      case 'business_admin':
      case 'account_owner':
        name = "admin"
        break;
      case 'regional_admin':
        name = 'regional_admin'
        break;
      case 'fitness_manager':
        name = 'fitness_manager'
        break;
      case 'instructor':
        name = 'instructor'
        break;
      case 'ops':
        name = 'ops'
        break;
    }
    return name
  }