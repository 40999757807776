import React from 'react'

export default class Footer extends React.Component {  
  render() {
    const date = new Date()
    const year = date.getFullYear()
    return (
      <React.Fragment>
        <div className="bg-black md:h-9 md:py-0 py-3 md:flex items-center">
          <div className="powered-by text-white text-base flex items-center justify-center md:w-2/5">
            Powered by <img className="ml-2" src="/assets/business/crunch-netgym.png" />
          </div>
          <div className="text-white text-xs flex items-center justify-center md:w-2/5">
            © {year} NetGym, All Rights Reserved.
          </div>
          <div className="footer-nav text-white text-xs flex items-center justify-center md:w-1/6">
            <a href="/terms" target="_blank">Terms</a> <span className="mx-1">|</span> <a href="/privacy" target="_blank">Privacy Policy</a>
          </div>
        </div>
        
      </React.Fragment>
    )
  }
}