import React, { Fragment } from 'react';

export default function TabComponent(props){
  const { pageTab, setPageTab} = props

  const handleChangeTab = (data) => {
    setPageTab(data)
  }

  return(
    <Fragment>
      <div className='flex'>
        <button className={`flex text-center items-center justify-center rounded-l h-10 w-32 font-semibold ${pageTab == 'active' ? 'bg-lime-600 text-gray-50' : 'bg-gray-200 text-gray-800'}`} onClick={() => handleChangeTab('active')}>
          Active
        </button>
        <button className={`flex text-center items-center justify-center rounded-r h-10 w-32 font-semibold ${pageTab == 'inactive' ? 'bg-lime-600 text-gray-50' : 'bg-gray-200 text-gray-800'}`} onClick={() => handleChangeTab('inactive')}>
          Inactive
        </button>
      </div>
    </Fragment>
  )
}