function getHeaders(token, subdomain){
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj
  }
}

export const getLocationSettings = async (user_token, subdomain) => {
  return await fetch(`/api/v3/get_users_settings_locations`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const getUsers = async (user_token, subdomain, page, locations, sortType, sortReverse, selectedHomeClubs, search) => {
  return await fetch(`/api/v3/users?page=${page}&pageSize=30&ids=${locations}&sortType=${sortType}&sortReverse=${sortReverse}&selectedHomeClubs=${selectedHomeClubs}&text=${search}`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const getClasses = async (user_token, subdomain, page) => {
  return await fetch(`/api/v3/business_classes?page=${page}&pageSize=30`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const getRoles = async (user_token, subdomain) => {
  return await fetch(`/api/v3/get_roles`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const setLocationSettings = async (user_token, subdomain, locations, regions) => {
  return await fetch(`/api/v3/update_user_settings_location_filter`,{
    method: "PATCH",
    body: JSON.stringify({ location_ids: locations, region_ids: regions }),
    headers: getHeaders(user_token, subdomain)
  });
}

export const filterUsers = async (user_token, subdomain, page, text, sortType, sortReverse, userIds, selectedHomeClubs) => {
  return await fetch(`/api/v3/get_filtered_users`,{
    method: "POST",
    body: JSON.stringify({ text, page, pageSize: 30, sortType, sortReverse, ids: userIds, selectedHomeClubs }),
    headers: getHeaders(user_token, subdomain)
  });
}

export const exportUsers = async (user_token, subdomain) => {
  let url = "/api/v3/export_users";
  return await fetch(`${url}`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const uploadCSV = async (user_token, subdomain, file, business) => {
  const formData = new FormData();
  formData.append(
    "uploadFile",
    file,
    file.name
  );

  let url = `/api/v3/show_uploaded_biz_users`

  return await fetch(url,{
    method: "POST",
    body: formData,
    headers: {
      'Accept': 'application/vnd.netgym.v3',
      'Authorization': eval(`${user_token}`),
      'subdomain': localStorage.getItem("businessID")
    }
  });
}

export const sendConfirmationAll = async (user_token, subdomain, count) => {
  return await fetch(`/api/v3/send_confirmation_mail_to_all?users_count=${parseInt(count)}`,{
    method: "POST",
    headers: getHeaders(user_token, subdomain)
  });
}

export const sendConfirmation = async (user_token, subdomain, user) => {
  return await fetch(`/api/v3/resend_confirmation_mail?id=${user.id}`,{
    method: "POST",
    headers: getHeaders(user_token, subdomain)
  });
}

export const updateUserActivation = async (user_token, subdomain, val, user) => {
  return await fetch(`/api/v3/update_user_activation`,{
    method: "POST",
    body: JSON.stringify({ id: user.id, val: val }),
    headers: getHeaders(user_token, subdomain)
  });
}

export const deleteUser = async (user_token, subdomain, user) => {
  return await fetch(`/api/v3/users/${user.id}`,{
    method: "DELETE",
    headers: getHeaders(user_token, subdomain)
  });
}

export const updatePassword = async (user_token, subdomain, password, confirmPass, user) => {
  return await fetch(`/api/v3/update_temp_password`,{
    method: "POST",
    body: JSON.stringify({
      user: {
        email: user.email,
        new_password: password,
        confirm_password: confirmPass
      }
    }),
    headers: getHeaders(user_token, subdomain)
  });
}

export const getAbcRegions = async (user_token, subdomain) => {
  return await fetch(`/api/v3/get_all_regions`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const getRegionLocations = async (user_token, subdomain, id) => {
  return await fetch(`/api/v3/get_location_business_regions?business_region_id=${id}`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const syncUsers = async (user_token, subdomain, type, siteId) => {
  let url = "";
  switch(type){
    case 'mbo':
      url = `/api/v3/mindbody/fetch_users?site_id=${siteId}&time_frame=today`
      break;
    case 'marinatek':
      url = '/api/v3/marianatek/fetch_users'
      break;
  }
  return await fetch(url,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const syncPaginatedUsers = async (user_token, subdomain, page, siteId) => {
  let url = `/api/v3/mindbody/fetch_users?site_id=${siteId}&time_frame=today&page=${page}`
  return await fetch(url,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const processSyncUsers = async (user_token, subdomain, type, siteId, classes, remote_classes) => {
  let url = "";
  switch(type){
    case 'mbo':
      url = '/api/v3/mindbody/upload_mbo_users'
      break;
    case 'marinatek':
      url = '/api/v3/marianatek/upload_marianatek_users'
      break;
    case 'abc':
      url = '/api/v3/abc/upload_abc_users'
      break;
  }

  return await fetch(`${url}`,{
    headers: getHeaders(user_token, subdomain),
    method: "POST",
    body: JSON.stringify({ users: classes, site_id: siteId, employees: (type === 'marinatek' ? remote_classes : [])})
  });
}

export const syncAbcUsers = async (user_token, subdomain, locations, departments, active) => {
  let url = "/api/v3/abc/fetch_users";
  // {"filter_active"=>"true", "filter_department"=>"GRXINS,GRXINS Placeholder", "locations"=>"62,56,59"}
  return await fetch(`${url}`,{
    headers: getHeaders(user_token, subdomain),
    method: 'POST',
    body: JSON.stringify({ locations: locations, filter_active: active, filter_department: departments.join(",")})
  });
}

export const processsyncAbcUsers = async (user_token, subdomain, locations, departments, users, active) => {
  let url = "/api/v3/abc/upload_abc_users";
  // {"filter_active"=>"true", "filter_department"=>"GRXINS,GRXINS Placeholder", "locations"=>"62,56,59"}
  return await fetch(`${url}`,{
    headers: getHeaders(user_token, subdomain),
    method: 'POST',
    body: JSON.stringify({ locations: locations.join(","), filter_department: departments.join(","), users: users, filter_active: active })
  });
}

export const downloadData = async (user_token, subdomain, type, siteId) => {
  let url = "";
  switch(type){
    case 'mbo':
      // url = '/api/v3/export_mbo_users'
      url = "/api/v3/mindbody/download_users"
      break;
    case 'marina':
      url = '/api/v3/export_marianatek_users'
      break;
  }
  if(type === 'mbo'){
    return await fetch(`${url}?site_id=${siteId}&time_frame=today`,{
    headers: getHeaders(user_token, subdomain)
  });
  }
  else{
    return await fetch(`${url}`,{
      headers: getHeaders(user_token, subdomain)
    });
  }
}

export const downloadAbcData = async (user_token, subdomain, locations, departments) => {
  let url = "/api/v3/export_abc_users";
  return await fetch(`${url}`,{
    headers: getHeaders(user_token, subdomain),
    method: 'POST',
    body: JSON.stringify({ locations: locations, filter_department: departments, filter_active: 'true' })
  });
}

export const getTeachingLocations = async (user_token, subdomain) => {
  return await fetch(`/api/v3/get_teaching_locations`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const getSkills = async (user_token, subdomain, userId) => {
  return await fetch(`/api/v3/business_categories?user_id=${userId}`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const getRegions = async (user_token, subdomain, userId) => {
  return await fetch(`/api/v3/get_locations_and_regions?id=${userId}`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const createUser = async (user_token, subdomain, request) => {
  return await fetch(`/api/v3/users`,{
    method: "POST",
    body: JSON.stringify({
      role: request.role,
      business_skill_ids: request.business_skill_ids,
      business_location_ids: request.business_location_ids,
      manager_location_ids: request.manager_location_ids,
      add_user_in_classes: request.add_user_in_classes,
      manager_region_ids: request.manage_region_ids,
      business_region_ids: request.business_region_ids,
      admin_region_ids: request.admin_region_ids,
      business_user: {
        first_name: request.first_name,
        last_name: request.last_name,
        email: request.email,
        home_club_id: request.home_club_id,
        time_zone: request.time_zone_string,
        external_id: request.external_id,
        marianatek_profile_id: request.marianatek_profile_id,
        hire_date: request.hire_date,
        mindbody_id: request.mindbody_id,
        mbo_site_id: request.mbo_site_id,
        employee_id: request.employee_id,
        directory_phone_number: request.directory_phone_number
      },
      send_activation_email: request.send_activation_email
    }),
    headers: getHeaders(user_token, subdomain)
  });
}

export const updateUser = async (user_token, subdomain, request) => {
  return await fetch(`/api/v3/users/${request.id}`,{
    method: "PATCH",
    body: JSON.stringify({
      role: request.role,
      business_skill_ids: request.business_skill_ids,
      business_location_ids: request.business_location_ids,
      manager_location_ids: request.manager_location_ids,
      add_user_in_classes: request.add_user_in_classes,
      manager_region_ids: request.manage_region_ids,
      business_region_ids: request.business_region_ids,
      admin_region_ids: request.admin_region_ids,
      business_user: {
        id: request.id,
        first_name: request.first_name,
        last_name: request.last_name,
        email: request.email,
        home_club_id: request.home_club_id,
        time_zone: request.time_zone_string,
        external_id: request.external_id,
        marianatek_profile_id: request.marianatek_profile_id,
        hire_date: request.hire_date,
        mindbody_id: request.mindbody_id,
        mbo_site_id: request.mbo_site_id,
        employee_id: request.employee_id,
        directory_phone_number: request.directory_phone_number
      },
      send_activation_email: request.send_activation_email
    }),
    headers: getHeaders(user_token, subdomain)
  });
}

export const uploadUsers = async (user_token, subdomain, newUsers) => {
  let url = "/api/v3/upload_biz_users";
  return await fetch(`${url}`,{
    headers: getHeaders(user_token, subdomain),
    method: 'POST',
    body: JSON.stringify({ new_users: newUsers })
  });
}

export const downloadMboSyncData = async (user_token, subdomain, siteId, users_id) => {
  let url = "/api/v3/export_mbo_users";
  return await fetch(`${url}?site_id=${siteId}&users=${JSON.stringify(users_id)}`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const assignTempPassword = async (user_token, subdomain, user_id, create) => {
  let url = `/api/v3/assign_temporary_password?user_id=${user_id}&create=${create}`;
  return await fetch(`${url}`,{
    headers: getHeaders(user_token, subdomain),
  });
}

export const sendEmailVerification = async (user_token, subdomain, userId) => {
  return await fetch(`/api/v3/resend_email_verification_mail?id=${userId}`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const syncMarianatekEmployees = async(user_token, subdomain) => {
  let url = `/api/v3/marianatek/sync_employees`;
  return await fetch(`${url}`, {
    headers: getHeaders(user_token, subdomain),
    method: 'POST'
  });
}

export const integration_enabled = (business) => {
  return (business?.marianatek_integration || business?.abc_integration || business.mbo_integration || business?.crunch_integration || business?.clubready_integration || business?.fisikal_integration)
}

export const processSyncCrFisikalUsers = async(user_token, subdomain) => {
  return await fetch(`/api/v3/sync_cr_fisikal_users`, {
    headers: getHeaders(user_token, subdomain),
  })
}

export const processSyncUser = async (user_token, subdomain, userId) => {
  return await fetch(`/api/v3/sync_user?user_id=${userId}`, {
    headers: getHeaders(user_token, subdomain),
  })
}

const allRoles = [
  {value: "business_admin", label: "Business Admin"},
  {value: "regional_admin", label: "Regional Admin"},
  {value: "fitness_manager", label: "Fitness Manager"},
  {value: "instructor", label: "Fitness Instructor"},
  {value: "ops", label: "Ops"}
]

export const findMatchingRoleAndSlice = (data) => {
  const index = allRoles.findIndex(item => item.value === data);
  if (index === -1) {
    return [];
  } else {
    return allRoles.slice(index);
  }
};

export const checkUnsyncedSkills = (allSkills, synckedSkills, selectedSkills) => {
  return(allSkills?.filter(skill => selectedSkills?.includes(skill.id) || !synckedSkills?.includes(skill.id)))
}

export const checkAllUnsyncedSkillsSelected = (selectedSkills, synckedSkills) => {
  return(synckedSkills.every(v => selectedSkills.includes(v?.id)))
}