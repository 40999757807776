import React from 'react';
import { capitalizeFirstLetter } from './../../../helpers'

export default function NotificationSettingsShow(props){
  const { user, setEditNotificationSettings } = props

  const handleEditNotificationSettings = (data) => {
    setEditNotificationSettings(data)
  }

  return(
    <React.Fragment>
      <div className='bg-white flex flex-col rounded-md shadow px-6 py-7 mb-5'>
        <div className="flex items-center mb-4 pb-1">
          <div className="text-lg font-medium flex-auto">Notification Settings</div>
          <button className="flex bg-dark-blue text-sm text-white py-2 px-4 rounded-md h-10 items-center" onClick={() => handleEditNotificationSettings(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
            </svg>
            Edit
          </button>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            How would you like to receive subbing notifications?
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
            <div className='text-sm font-normal text-gray-900 info-detail'>
              {!user?.away ? 
                (user?.notifications == 'all' ?
                  'SMS and Email'
                  :
                  capitalizeFirstLetter(user?.notifications)+' only'
                ) 
              : 
                'Notifications are OFF'
              }
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            How long before a class would you like to receive a reminder notification that you are subbing?
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
            <div className='text-sm font-normal text-gray-900'>
              {user?.reminders_display+' before'}
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            How often do you want to receive sub request invites from your team?
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
            <div className='text-sm font-normal text-gray-900 flex-col flex'>
              {user?.daily_notifications == "immediately" ? 'Send me every invite immediately' : "Daily Summary (once per day)"}
              <span className="text-xs text-gray-500 font-normal pt-2">
                *You’ll receive an individual notification for any sub request sent to you within 24 hours of the start time of the class to ensure the class gets covered.
              </span>
            </div>
          </div>
        </div>
        {(user?.solidcore_gym) && !user?.new_messenger && user?.mailbox_feature &&
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-normal text-gray-500">
              Do you want to receive Messenger notifications?
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
              <div className='text-sm font-normal text-gray-900'>
                {user?.enable_messenger_notifications ? 'Yes' : 'No'}
              </div>
            </div>
          </div>
        }
        {user?.new_messenger && user?.mailbox_feature &&
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-normal text-gray-500">
              How would you like to receive Post notifications?
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
              <div className='text-sm font-normal text-gray-900'>
                {!user?.away ? 
                  (user?.messenger_notifications_type == 'email_and_push' && 'Email and Mobile Push')
                  ||
                  (user?.messenger_notifications_type == 'email' && 'Email Only' )
                  ||
                  (user?.messenger_notifications_type == 'push' && 'Mobile Push Only' )
                  ||
                  (user?.messenger_notifications_type == 'off' && 'Off' )
                : 
                  'Notifications are OFF'
                }
              </div>
            </div>
          </div>
        }
        {user?.resource_hub &&
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-normal text-gray-500">
              How would you like to receive Resource notifications?
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
              <div className='text-sm font-normal text-gray-900'>
                {!user?.away ? 
                  (user?.resource_notification_type == 'email_and_push' && 'Email and Mobile Push')
                  ||
                  (user?.resource_notification_type == 'email' && 'Email Only' )
                  ||
                  (user?.resource_notification_type == 'push' && 'Mobile Push Only' )
                  ||
                  (user?.resource_notification_type == 'off' && 'Off' )
                : 
                  'Notifications are OFF'
                }
              </div>
            </div>
          </div>
        }
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            I'm away (on vacation, taking time off, etc)?
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
            <div className='text-sm font-normal text-gray-900'>
              {!user?.away ? 
                'Turn on sub notifications'
              : 
                'Turn off sub notifications'
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}