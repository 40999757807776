function getHeaders(token, subdomain){
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj
  }  
}

export const getRegions = async (user_token, subdomain) => {
  return await fetch(`/api/v3/business_regions`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const createRegion = async (user_token, subdomain, name, tags) => {
  let ids = tags.map(a => a.id).join(", ")
  return await fetch(`/api/v3/business_regions`,{
    headers: getHeaders(user_token, subdomain),
    method: "POST",
    body: JSON.stringify({ business_region: {name: name},regional_admins: ids})
  });
}

export const updateRegion = async (user_token, subdomain, region, tags) => {
  let ids = tags.map(a => a.id).join(", ")
  return await fetch(`/api/v3/business_regions/${region.id}`,{
    headers: getHeaders(user_token, subdomain),
    method: "PATCH",
    body: JSON.stringify({ business_region: {name: region.name},regional_admins: ids})
  });
}

export const getSuggestions = async (user_token, subdomain) => {
  return await fetch(`/api/v3/get_regional_admins`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const destroyRegion = async (user_token, subdomain, region) => {
  return await fetch(`/api/v3/business_regions/${region.id}`,{
    method: "DELETE",
    headers: getHeaders(user_token, subdomain)
  });
}

