import React, { Fragment } from "react";
import CustomPopupContainerComponent from '../../../subRequestDetails/components/customPopupContainerComponent';
import { ExclamationIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'

export default function DeleteThumbnailPopupComponent(props) {
  const { business, deleteThumbnailPopupOpen, deleteThumbnailHandler, deleteThumbnail } = props

  return(
    <Fragment>
      <CustomPopupContainerComponent show={deleteThumbnailPopupOpen} close={deleteThumbnailHandler} title={''} customWidth={'sm:max-w-lg'}>
        <div className="flex flex-col gap-3 items-start px-7 pt-3 font-open-sans">
          <div className="flex items-center justify-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                Delete Thumbnail?
              </Dialog.Title>
            </div>
          </div>
          <div className="flex w-full justify-center mt-2 flex gap-3 flex-row">
            <button 
              className={`inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto sm:text-sm h-9`}
              onClick={() => deleteThumbnail(business.id)}
            >
              Delete
            </button>
            <button 
              className={`mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm h-9`}
              onClick={() => deleteThumbnailHandler()}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  )
}