function getHeaders(token, subdomain){  
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj
  }  
}

export const snakeCase = (string) => {
  return string.replace(/\d+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_');
};

export const getAllPermissions = async (user_token, subdomain, name) => {    
  return await fetch(`/api/v3/get_business_permissions?name=${snakeCase(name)}`,{
    headers: getHeaders(user_token, subdomain)
  });  
}



export const setAllPermissions = async (user_token, subdomain, name, entity, action_type, value) => {    
  return await fetch(`/api/v3/update_business_permissions`,{
    method: 'POST',
    body: JSON.stringify({ entity: entity, action_type: action_type, name: name, value: value}),
    headers: getHeaders(user_token, subdomain)
  });  
}