import React, { Fragment, useState } from "react";
import { BackButtonIcon } from './../../../assets';
import { Redirect } from 'react-router-dom';

export default function BackButtonComponent(props) {
  const { user, backPath } = props
  const [ redirect, setRedirect ] = useState(false)
  
  const goBack = () => {
    if(backPath !== '' && !backPath.includes('/business_user')){
      setRedirect(true)
    }else{
      window.location.href = backPath
    }
  }

  return (
    <Fragment>
      {redirect &&
        <Redirect to={`${backPath}`} />
      }
      <button className="flex justify-center items-center rounded-2xl px-4 py-4 h-11 bg-white text-sm text-gray-900 border-2 border-gray-300 font-semibold gap-2" onClick={() => goBack()}>
        <BackButtonIcon classNames={'w-4 h-4'}/>
        Back
      </button>
    </Fragment>
  );
}