function getHeaders(token, subdomain){  
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj
  }  
}

export const getSkills = async (user_token, subdomain) => {    
  return await fetch(`/api/v3/business_categories`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const createSkill = async (user_token, subdomain, business_category) => {  
  return await fetch(`/api/v3/business_categories`,{
    method: "POST",
    headers: getHeaders(user_token, subdomain),
    body: JSON.stringify({ business_category: business_category })
  });
}

export const updateSkill = async (user_token, subdomain, id, business_category) => {  
  return await fetch(`/api/v3/business_categories/${id}`,{
    method: "PATCH",
    headers: getHeaders(user_token, subdomain),
    body: JSON.stringify({ business_category: business_category })
  });
}

export const destroySkill = async (user_token, subdomain, id) => {  
  return await fetch(`/api/v3/business_categories/${id}`,{
    method: "DELETE",
    headers: getHeaders(user_token, subdomain)
  });
}

export const downloadABCdata = async (user_token, subdomain, id) => {  
  return await fetch(`/api/v3/export_abc_skills`,{    
    headers: getHeaders(user_token, subdomain)
  });
}

export const uploadSkills = async (user_token, subdomain, file) => {
  const formData = new FormData();
  formData.append(
    "uploadFile",
    file,
    file.name
  );
    
  return await fetch(`/api/v3/upload_skills`,{
    method: "POST",    
    body: formData,
    headers: {
      'Accept': 'application/vnd.netgym.v3',
      'Authorization': eval(`${user_token}`),
      'subdomain': localStorage.getItem("businessID")
    }
  });
}
