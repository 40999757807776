function getHeaders(token, subdomain){  
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj
  }  
}

export const removeSite = async (user_token, subdomain, site_id) => {  
  return await fetch(`/api/v3/mindbody/destroy`,{
    method: "DELETE",
    headers: getHeaders(user_token, subdomain),
    body: JSON.stringify({ 
      site_id: site_id
    })
  });
}

export const addSite = async (user_token, subdomain, site) => {  
  return await fetch(`/api/v3/mindbody/save`,{
    method: "POST",
    headers: getHeaders(user_token, subdomain),
    body: JSON.stringify({ 
      id: site.id,
      site_id: site.site_id,
      site_name: site.site_name,
      time_zone: site.time_zone,
    })
  });
}

export const activateSite = async (user_token, subdomain, site_id) => {  
  return await fetch(`/api/v3/mindbody/activation_code?site_id=${site_id}`,{
    method: "GET",
    headers: getHeaders(user_token, subdomain)    
  });
}

export const validateSite = async (user_token, subdomain, site_id) => {  
  return await fetch(`/api/v3/mindbody/validate?site_id=${site_id}`,{
    method: "GET",
    headers: getHeaders(user_token, subdomain)    
  });
}

export const syncStaff = async(user_token, subdomain) => {
  return await fetch("/api/v3/mindbody/sync_staff", {
    method: "POST",
    headers: getHeaders(user_token, subdomain) 
  });
}

export const updateMboSettings = async(user_token, subdomain, data) => {
  return await fetch("/api/v3/mindbody/update_mbo_settings", {
    method: "PATCH",
    headers: getHeaders(user_token, subdomain),
    body: JSON.stringify(data)
  });
}
