import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ExclamationIcon,
  XIcon,
  DotsVerticalIcon,
  ExclamationCircleIcon,
  FolderAddIcon,
} from "@heroicons/react/outline";

export default function Index({ openState, stateHandler, nameHandler }) {
  const [open, setOpen] = useState(true);
  const [newFolderName, setNewFolderName] = useState({});
  const [ validFolderName, setValidFolderName ] = useState(true);

  // add focus
  useEffect(() => {
    return function () {
      addAutofocus()
    }
  }, [])

  // onchange, close and return false
  useEffect(() => {
    return function () {
        setOpen(false);
        stateHandler(false);
    }
  }, [open])

  const cancelButtonRef = useRef(null);

  const setValues = (event) => {
    const { name, value } = event.target;
    if(!value.match(/^[0-9A-Za-z\s]*$/)){
      setValidFolderName(false)
    }else{
      setValidFolderName(true)
    }
    if (value.length <= 20){
      setNewFolderName({ [name]: value });
    }
  };

  const addAutofocus = () => {
		setTimeout(() => {
			addFocusExplicitly();
		}, 1000);
	}

  const addFocusExplicitly = () => {
		//take care if only one element of this class exists
		document.querySelector('input#folder_name').focus();
	}

  // close on submit
	const createNewFolderHandler = (e) => {
    setOpen(false);
    stateHandler(false);
    //send it more values if needed
    nameHandler(newFolderName.folder_name)
	}

  // console.log("===test====", Boolean(newFolderName?.folder_name))
  return (
    <Transition.Root show={openState} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>                
                <div className="w-full block relative flex">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <FolderAddIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Create New Folder
                    </Dialog.Title>
                    <div className="mt-2 create-new-folder-container">
                      <div className="space-y-6 bg-white py-5">
                        <div className="grid grid-cols-1 gap-6">
                          <div className="col-span-3 sm:col-span-2">
                            {/* <label
                              htmlFor="company-website"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Folder Name
                            </label> */}
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                name="folder_name"
                                id="folder_name"
                                className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 text-sm w-full border-0 h-9 ml-1.5 focus:outline-none bg-gray-100 hover:bg-gray-50 focus:bg-gray-50 focus:border-gray-800 border border-2 pl-2"
                                placeholder="Enter Folder name"
                                minLength={2}
                                onChange={(e) => setValues(e)}
                                maxLength={20}
                                autoFocus={true}
                              />
                            </div>
                            {!validFolderName &&
                              <span className="error text-xs">*Special characters are not allowed in folder name.</span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    disabled={!Boolean(newFolderName?.folder_name) || !validFolderName}
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50"
                    onClick={(e) => createNewFolderHandler(e)}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}