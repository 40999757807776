import React, { Fragment, useState } from "react";
import CustomPopupContainerComponent from "../../../../subRequestDetails/components/customPopupContainerComponent";

export default function DeleteChannelModal(props) {
  const { showDeleteComfirmationPopup, handleShowDeleteComfirmation, disableDeleteBtn, performDeleteChannel } = props;
  const [ permanentlyDelete, setPermanentlyDelete ] = useState(false);

  const handleDeleteChannel = () => {
    performDeleteChannel()
  }

  const handleCloseModal = (data) => {
    if(disableDeleteBtn) return
    handleShowDeleteComfirmation(data)
  }
  return (
    <Fragment>
      <CustomPopupContainerComponent show={showDeleteComfirmationPopup} close={handleCloseModal} title={''}>
        <div className="px-5 font-open-sans">
          <div className="text-xl font-open-sans font-bold text-black">Delete this group chat?</div>
          <div className="my-5">
            <span className="inline-block text-sm font-medium text-gray-900">
              When you delete a group chat, all messages from this channel will be removed from NetGym immediately. <span className="font-bold text-black">This can't be undone.</span>
            </span>
          </div>
          <div className="flex flex-wrap justify-center sm:justify-between gap-2 items-center pt-2">
            <label htmlFor="permanently_delete_checkbox" className="flex text-sm font-medium text-gray-900 cursor-pointer flex-wrap gap-2">
              <input id={`permanently_delete_checkbox`} type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mt-0.5 cursor-pointer" onChange={(e) => setPermanentlyDelete(!permanentlyDelete)} checked={permanentlyDelete}/>
              Yes, permanently delete this group
            </label>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className={`flex items-center justify-center rounded-xl border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none ${disableDeleteBtn ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100'}`}
                onClick={(e) => handleCloseModal(false)}
                disabled={disableDeleteBtn}
              >
                Cancel
              </button>
              <button
                type="button"
                className={`bg-dark-blue flex items-center justify-center rounded-xl border border-transparent shadow-sm px-4 py-2 text-sm font-medium text-white focus:outline-none ${disableDeleteBtn || !permanentlyDelete ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-700'}`}
                onClick={(e) => handleDeleteChannel(e)}
                disabled={disableDeleteBtn || !permanentlyDelete}
              >
                Delete Group
              </button>
            </div>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  )
}