import React, { Fragment } from "react";

export default function ReopenMessageComponent(props){
  const { eventData } = props

  return(
    <Fragment>
      <div className="flex flex-wrap mt-5 h-auto w-full -mb-1">
        <div className={`h-full w-full`}>
          <div className="lg:mr-2">
            <div className={`flex lg:rounded-2xl lg:border-2 lg:border-gray-300 h-auto lg:h-1/2 w-full bg-white p-4 relative justify-center`}>
              <div className="text-base font-semibold text-gray-900 text-center">
                {eventData?.Reopened_message}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}