import React, { Component, Fragment } from 'react'
import { Link, Redirect } from "react-router-dom";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { Switch } from '@headlessui/react'
import TextEditor from './editor';
import { getLocationsSkills, getMessageAudience, sendMessage, uploadAttachments, uploadImage, getMessage, editPost, deletePost, searchAllUser, saveDraft, createGroup, getGroupDetails, updateGroup, searchDmAllUser, checkGroupPresent, editDirectMesage, possibleRoles, checkLastSavedHigherRoles, disabledRolesList, arrayDifference } from './helpers'
import Cookies from 'js-cookie';
import { getSudomain, setTitle } from '../../helpers'
import ReactTooltip from "react-tooltip";
import { debounce } from "lodash";
import CustomPopup from './chatComponents/assets/customPopup'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const groupType = [{label: "Group Chat", value: 'group_chat'}]
const dmType = [{label: "Direct message", value: 'direct_message'}]

export default class CreatePostForm extends Component {
  constructor(props) {
    super(props);
    const postMessageType = props.business?.permanent_class_opening_feature ? [{label: "Compliance", value: 'compliance'}, {label:"Announcement", value: 'announcement'}, {label: "Event", value: 'event'}, {label: "Permanent Class Opening", value: 'permanent_class_opening'}] : [{label: "Compliance", value: 'compliance'}, {label:"Announcement", value: 'announcement'}, {label: "Event", value: 'event'}]
    this.state = {
      show: false,
      alert_type: '',
      alert_message: '',
      loaded: false,
      selectedPostType: '',
      postType: 
        (Object.keys(props?.permissions)?.length !== 0 && Object.keys(props?.business)?.length !== 0 && Object.keys(props?.user)?.length !== 0 ) && 
          (props?.user?.role_display !== "Fitness Instructor" ? 
            (props?.business?.group_chat_feature ?
              (props?.permissions?.business_messages?.write && props.permissions?.business_messages?.groups && props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee" ? 
                  [...postMessageType, ...groupType, ...dmType] 
                : 
              props?.permissions?.business_messages?.write && props.permissions?.business_messages?.groups && !props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee" ? 
                  [...postMessageType, ...groupType] 
                : 
              props?.permissions?.business_messages?.write && !props.permissions?.business_messages?.groups && props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee" ? 
                  [...postMessageType, ...dmType] 
                :
              props?.permissions?.business_messages?.write && !props.permissions?.business_messages?.groups && !props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee" ?
                  [...postMessageType]
                :
              !props?.permissions?.business_messages?.write && props.permissions?.business_messages?.groups && props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee" ?
                  [...groupType, ...dmType]
                :
              !props?.permissions?.business_messages?.write && !props.permissions?.business_messages?.groups && props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee" ?
                  [...dmType]
                :
              !props?.permissions?.business_messages?.write && props.permissions?.business_messages?.groups && !props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee" ?
                  [...groupType]
                :
              [...postMessageType]
              )
              :
              [...postMessageType]
            )
            :
            (props?.business?.group_chat_feature ?
              (this.props?.permissions?.business_messages?.direct_messages_to_instructors || this.props?.permissions?.business_messages?.direct_messages_to_managers) && [...dmType]
              :
              [...dmType]
            )
          ),
      locations: [],
      allowComment: true,
      setReminder: false,
      every: {
        count: 3,
        type: 'days',
        notifyType: 'Email'
      },
      reminderForm: ['Email', 'SMS', 'Email & SMS'],
      subject: '',
      message: '',
      due_date: '',// new Date(),
      showDiscardModal: false,
      regions_and_locations: [],
      roles_array: [],
      skills: [],
      show_others: false,
      selectedRegions: [],
      selectedLocations: [],
      selectedSkills: [],
      users: [],
      all_users: [],
      selectedPeople: [],
      searchValue: '',
      acknowledgeRole: [],
      discard: false,
      ccPeople: {},
      selectedCCPeople: [],
      ccPeopleIds: [],
      attachments: [],
      totalUsers: [],
      formType: '',
      postId: '',
      messageId: '',
      updated: false,
      showDeleteModal: false,
      deleted: false,
      messageExpired: false,
      eventSelectedLocation: '',
      eventStartDate: '',
      eventEndDate: '',
      help_range: [],
      start_hour_range: [],
      end_hour_range: [],
      to_hour_range: [],
      from_hour_range: [],
      eventStartTime: '',
      eventEndTime: '',
      locationType: '',
      showEndTime: false,
      locationFilterTooltipMessage: `These are subbing, managed, or home club locations listed on the user's profile. If a user does not have the location(s) checked on their profile for teaching or assigned as management to the location or region, they will not be included in the audience unless you add them individually or add the location to their profile.`,
      showSelectedPeopleModal: false,
      showPeoplelist: false,
      searchAudience: '',
      all_selectedPeople: [],
      draftedPost: false,
      draftButtonDisabled: false,
      sendButtonDisabled: false,
      editButtonDisabled: false,
      editPostConfModal: false,
      redirectAt: '',
      redirectAfterSend: false,
      totalResult: {},
      dueDateMinValue: new Date(),
      eventStartDateMinValue: new Date(),
      showDragDropError: false,
      disableAttachBtn: false,
      discriptionBoxEmpty: false,
      archivePostUpdated: false,
      groupName: '',
			groupImage: {},
			groupDescription: '',
			createGroupBtnDisabled: false,
      rolesCanPost: [],
      groupChatRooms: [],
      groupNameAlreadyPresent: false,
      redirectAfterCreateGroup: false,
      redirectAtGroupPage: '',
      groupImageFile: '',
      uploadedGroupImage: '',
      groupId: '',
      dmMessage: '',
      createDmBtnDisabled: false,
      searchDmValue: '',
      dmUsers: [],
      focused: undefined,
      selectedManagedLocations: [],
      selectedNonManagedLocations: [],
      filterUsersLoaded: true,
      permanentClassDaysList: [{label: "M", value: "Monday"},{label: "T", value: "Tuesday"},{label: "W", value: "Wednesday"},{label: "Th", value: "Thursday"},{label: "F", value: "Friday"},{label: "S", value: "Saturday"},{label: "Su", value: "Sunday"}],
      selectedPermanentClassDays: [],
      showCreatorWantInGroupPopup: false,
      addCurrentUserInGroup: false,
      currentUserSkills: [],
      showConfirmGroupPresentPopup: false,
      allowEmojis: true,
      homeClubRegionsAndLocations: [],
      homeClubShowOthers: false,
      homeClubSelectedLocations: [],
      homeClubSelectedRegions: [],
      homeClubLocations: [],
      currentUserHomeClubs: [],
      homeClubTooltipMessage: `This is the home club field (think of this as where the instructor has permanent classes, not all locations they sub at as well) on the user's profile within settings > users. Only 1 home club is applied to the user's profile at this time. When creating a group chat based on home club, you can select multiple home clubs to be in the audience of your group chat. This is especially useful if you manage multiple locations.`,
      disabledRoles: [],
      enabledRoles: [],
      higerSavedRoles: [],
      dmOldAudience: [],
    }
    this.callRef = React.createRef();
  }

  componentDidMount(){
    this.props.setCurrent('Messages')
    setTitle('messenger')
    this.checkUserPermissions()
    document.body.addEventListener('click', this.handleClosepeopleList)
    if(this.props.pageLoaded){
      this.checkAndLoad()
    }
  }

  checkUserPermissions = () => {
    if(Object.keys(this.props?.user).length !== 0 && Object.keys(this.props?.permissions).length !== 0 && Object.keys(this.props?.business).length !== 0){
      if(this.props?.user?.role_display !== "Fitness Instructor"){
        if((!this.props?.permissions?.business_messages?.write && !this.props.permissions?.business_messages?.groups && !this.props.permissions?.business_messages?.direct_messages && this.props.user?.role_display !== 'Netgym Employee')){
          this.setState({discard: true})
        }
      }else if(this.props?.user?.role_display === "Fitness Instructor"){
        if(this.props?.business?.group_chat_feature){
          if(!this.props?.permissions?.business_messages?.direct_messages_to_instructors && !this.props?.permissions?.business_messages?.direct_messages_to_managers){
            this.setState({discard: true})
          }else{
            this.setState({selectedPostType: 'direct_message'})
          }
        }else{
          this.setState({discard: true})
        }
      }
    }
  }

  checkPageType = () => {
    const props = this.props
    let params = new URLSearchParams(window.location.search)
    let type = (params.has('type') && params.get('type') !== '') ? params.get('type') : ''
    let allPossiblePage = [ 'compliance', 'announcement', 'event', 'permanent_class_opening', 'group_chat', 'direct_message' ]
    if (!props.business?.permanent_class_opening_feature){
      allPossiblePage = [ 'compliance', 'announcement', 'event', 'group_chat', 'direct_message' ]
    }else{
      allPossiblePage = [ 'compliance', 'announcement', 'event', 'permanent_class_opening', 'group_chat', 'direct_message' ]
    }
    if(type === '' || !allPossiblePage.includes(type)){
      this.setState({discard: true})
    }else{
      if(type == 'group_chat'){
        let roleToHold = disabledRolesList(props.user?.role_display, possibleRoles())
        this.setState({
          rolesCanPost: roleToHold,
          higerSavedRoles: roleToHold
        })
      }
      if(props?.user?.role_display !== "Fitness Instructor"){
        if(props?.business?.group_chat_feature){
          if(props?.permissions?.business_messages?.write && props.permissions?.business_messages?.groups && props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee"){ 
            this.setState({selectedPostType: type})
            this.checkPageTypeSelection(type)
          }else if(props?.permissions?.business_messages?.write && props.permissions?.business_messages?.groups && !props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee"){
              // [...postMessageType, ...groupType]
            if([ 'compliance', 'announcement', 'event', 'permanent_class_opening', 'group_chat' ].includes(type)){
              this.setState({selectedPostType: type})
              this.checkPageTypeSelection(type)
            }else{
              this.setState({discard: true})
            }
          }else if(props?.permissions?.business_messages?.write && !props.permissions?.business_messages?.groups && props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee"){
            // [...postMessageType, ...dmType] 
            if([ 'compliance', 'announcement', 'event', 'permanent_class_opening', 'direct_message' ].includes(type)){
              this.setState({selectedPostType: type})
              this.checkPageTypeSelection(type)
            }else{
              this.setState({discard: true})
            }
          }else if(props?.permissions?.business_messages?.write && !props.permissions?.business_messages?.groups && !props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee"){
            // [...postMessageType]
            if([ 'compliance', 'announcement', 'event', 'permanent_class_opening' ].includes(type)){
              this.setState({selectedPostType: type})
              this.checkPageTypeSelection(type)
            }else{
              this.setState({discard: true})
            }
          }else if(!props?.permissions?.business_messages?.write && props.permissions?.business_messages?.groups && props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee"){
            // [...groupType, ...dmType]
            if([ 'group_chat', 'direct_message' ].includes(type)){
              this.setState({selectedPostType: type})
              this.checkPageTypeSelection(type)
            }else{
              this.setState({discard: true})
            }
          }else if(!props?.permissions?.business_messages?.write && !props.permissions?.business_messages?.groups && props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee"){
            // [...dmType]
            if([ 'direct_message' ].includes(type)){
              this.setState({selectedPostType: type})
              this.checkPageTypeSelection(type)
            }else{
              this.setState({discard: true})
            }
          }else if(!props?.permissions?.business_messages?.write && props.permissions?.business_messages?.groups && !props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee"){
              // [...groupType]
              if([ 'group_chat' ].includes(type)){
                this.setState({selectedPostType: type})
                this.checkPageTypeSelection(type)
              }else{
                this.setState({discard: true})
              }
          }else{
            // [...postMessageType]
            if([ 'compliance', 'announcement', 'event', 'permanent_class_opening' ].includes(type)){
              this.setState({selectedPostType: type})
              this.checkPageTypeSelection(type)
            }else{
              this.setState({discard: true})
            }
          }
        }else{
          // [...postMessageType]
          if([ 'compliance', 'announcement', 'event', 'permanent_class_opening' ].includes(type)){
            this.setState({selectedPostType: type})
            this.checkPageTypeSelection(type)
          }else{
            this.setState({discard: true})
          }
        }
      }else{
        if(props?.business?.group_chat_feature){
          if(this.props?.permissions?.business_messages?.direct_messages_to_instructors || this.props?.permissions?.business_messages?.direct_messages_to_managers){ 
            // [...dmType]
            if([ 'direct_message' ].includes(type)){
              this.setState({selectedPostType: type})
              this.checkPageTypeSelection(type)
            }else{
              this.setState({discard: true})
            }
          }else{
            this.setState({discard: true})
          }
        }else{
          // [...dmType]
          this.setState({discard: true})
        }
      } 
            
      // this.setState({selectedPostType: type})
    }
  }

  checkPageTypeSelection = (type) =>{
    let val = type
    if(val === 'compliance'){
      this.setState({
        due_date: new Date()
      })
    }else if(val === 'event'){
      this.setState({
        eventStartDate: new Date(),
        eventEndDate: '',
        eventStartTime: '',
        eventEndTime: '',
        eventSelectedLocation: '',
        showEndTime: false
      })
      this.getBusinessRange()
    }else if(val === 'group_chat'){
      this.setState({
        selectedRegions: [],
        selectedLocations: [],
        selectedSkills: [],
        selectedPeople: []
      })
    }else if(val === 'direct_message'){
      this.setState({
        selectedRegions: [],
        selectedLocations: [],
        selectedSkills: [],
        selectedPeople: [],
        searchDmValue: ''
      })
    }else if(val === 'permanent_class_opening'){
      this.setState({
        eventStartDate: new Date(),
        eventStartTime: '',
        eventSelectedLocation: '',
      })
      this.getBusinessRange()
    }
    else{
      this.setState({
        due_date: ''
      })
    }
  }

  checkAndLoad = () => {
    let pathname = location.pathname?.split('/')
    let postId = pathname.pop()
    let form = pathname.pop()
    if(form === 'messenger'){
      this.checkPageType()
    }
    if(this.props?.permissions?.business_messages?.write && this.props?.user?.role_display !== "Fitness Instructor"){
      this.checkPageTypeBeforeGetAudience()
    }else{
      this.performGetAudience("", undefined, undefined, false, undefined)
    }
    this.getAll()
  }

  handleClosepeopleList = (e) => {
    const target = $(e.target) // $(this).parents().children()
    const showPeoplelist = this.state.showPeoplelist

    if(!target.parents('div#targetShowpeopleList1').length && !target.parents('div#showPeopleListInput').length && showPeoplelist){
      this.setState({
        showPeoplelist: false,
        searchDmValue: ''
      })
    }
  }

  getAll = () => {
    let pathname = location.pathname?.split('/')
    let postId = pathname.pop()
    let form = pathname.pop()
    this.setState({
      formType: form === 'messenger' ? 'add' : 'edit',
      postId: postId
    })
    if(form !== 'messenger' && postId !== 'create-post'){
      if(form === 'edit-post'){
        getMessage(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), postId).then(      
          response => response.json()
        )
        .then(result => {
          if(result.status === 200){
            let every = this.state.every
            every.notifyType = result?.reminder_type,
            every.count = result?.reminder_days
            let selectedLocations = []
            if(this.state.locations?.length > 0){
              let locations = this.state.locations.map(item => item.id)
              selectedLocations = result?.location_ids.filter(item => locations.includes(item))
            }else{
              selectedLocations = result?.location_ids
            }
            this.setState({
              totalResult: result,
              messageId: result?.message?.id,
              draftedPost: result?.draft,
              selectedPostType: result?.message?.message_type,
              subject: result?.message?.title,
              due_date: result?.message?.due_date === '' ? '' : new Date(Date.parse(result?.message?.due_date_timestamp)),//new Date(Date.parse(result?.message?.due_date)),
              setReminder: result?.reminder,
              selectedPeople: result?.audience_ids,
              // selectedCCPeople: result?.cc_ids,
              allowComment: result?.message?.allow_comments,
              message: result?.message?.description,
              discriptionBoxEmpty: true,
              attachments: result?.message?.attachments_list,
              acknowledgeRole: result?.acknowledged_roles,
              every: every,
              messageExpired: result?.message?.post_expired_web,
              dueDateMinValue: new Date(),//result?.message?.post_expired_web ? new Date(Date.parse(result?.message?.due_date_timestamp)) : new Date(),
              eventStartDateMinValue: new Date(),//result?.message?.post_expired_web ? result?.message?.start_date_timestamp === '' ? new Date() : new Date(Date.parse(result?.message?.start_date_timestamp)) : new Date(),
              eventStartDate: result?.message?.message_type === "permanent_class_opening" ? (result?.message?.date_timestamp === '' ? '' : new Date(Date.parse(result?.message?.date_timestamp))) : (result?.message?.start_date_timestamp === '' ? '' : new Date(Date.parse(result?.message?.start_date_timestamp))),
              eventEndDate: result?.message?.end_date_timestamp === '' ? '' : new Date(Date.parse(result?.message?.end_date_timestamp)),
              showEndTime: result?.message?.end_date_timestamp === '' ? false : true,
              eventStartTime: result?.message?.start_time,
              eventEndTime: result?.message?.end_time,
              locationType: result?.message?.event_location_type,
              eventSelectedLocation: result?.message?.message_type === "permanent_class_opening" ? (result?.message?.business_location_id ? result?.message?.business_location_id : '') : (result?.message?.event_location_type === 'studio_list' ? (result?.message?.business_location_id ? result?.message?.business_location_id : '') : result?.message?.location_name),
              selectedLocations: selectedLocations,
              selectedRegions: result?.region_ids,
              selectedSkills: result?.category_ids,
              selectedPermanentClassDays: result?.message?.week_days,
              allowEmojis: result?.message?.allow_emojis
            })
            if(['event', "permanent_class_opening"].includes(result?.message?.message_type)){
              this.getBusinessRange()
            }
          }else{
            this.setState({
              alert_message: result.error,
              alert_type: 'danger', 
              show: true
            })
            this.setState({show:true},()=>{
              window.setTimeout(()=>{
                this.setState({show:false})
              },3000)
            });
          }
        })
      }else if(form === 'edit-chat-room'){
        getGroupDetails(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), postId).then(      
          response => response.json()
        )
        .then(result => {
          if(result.status === 200){
            this.setState({
              totalResult: result?.chatroom,
              groupId: postId,
              selectedPostType: result?.chatroom?.chatroom_type,
              groupName: result?.chatroom?.name,
              
              // selectedLocations: result?.chatroom?.location_ids,
              selectedLocations: result?.chatroom?.managing_locations.map(loc => {return loc?.id}),
              selectedRegions: result?.chatroom?.region_ids,
              selectedSkills: result?.chatroom?.category_ids,
              selectedPeople: result?.chatroom?.audience_ids,
              dmOldAudience: result?.chatroom?.chatroom_type == 'direct_message' ? result?.chatroom?.audience_ids : [],

              rolesCanPost: result?.chatroom?.acknowledged_roles,
              higerSavedRoles: checkLastSavedHigherRoles(possibleRoles(), result?.chatroom?.acknowledged_roles, this.props.user?.role_display),
              uploadedGroupImage: result?.chatroom?.image,
              groupDescription: result?.chatroom?.description,

              selectedManagedLocations: result?.chatroom?.managing_locations.map(loc => {return loc?.id}),
              selectedNonManagedLocations: result?.chatroom?.non_managing_locations.map(loc => {return loc?.id}),

              homeClubSelectedLocations: result?.chatroom?.home_club_ids,
              homeClubSelectedRegions: result?.chatroom?.home_club_region_ids
            })
  
          }else{
            this.setState({
              redirectAtGroupPage: this.props.business?.group_chat_feature ? `/messenger/chat-room/${postId}` : `/messenger`,
              alert_message: result.error,
              alert_type: 'danger', 
              show: true
            })
            this.setState({show:true, redirectAfterCreateGroup: false},()=>{
              window.setTimeout(()=>{
                this.setState({show:false, redirectAfterCreateGroup: true})
              },3000)
            });
          }
        })
      }
    }
    getLocationsSkills(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
        let regions_and_locations = result.regions_and_locations?.sort((a, b) => a.region.name.localeCompare(b.region.name, 'es', {sensitivity: 'base'}))
        let disabledRoles = disabledRolesList(this.props.user?.role_display, result?.roles_array)
        let enabledRoles = arrayDifference(result?.roles_array, disabledRoles);
				this.setState({
          homeClubRegionsAndLocations: regions_and_locations,
					regions_and_locations: regions_and_locations,
          roles_array: result?.roles_array,
          disabledRoles: disabledRoles,
          enabledRoles: enabledRoles,
          skills: result?.skills,
          show_others: result?.show_others,
          homeClubShowOthers: result?.show_others,
          locations: result?.locations,
          homeClubLocations: result?.locations,
          // selectedLocations: result?.locations?.length !== 0 ? result?.locations.map((item) => {return item.id}) : [],
          // selectedSkills: result?.skills?.length !== 0 ? result?.skills.map((item) => {return item.id}) : [],
          // selectedRegions: result?.regions_and_locations?.length !== 0 ? result?.regions_and_locations.map((item) => {return item?.region?.name}) : [],
          currentUserSkills: result?.current_user_skills,
          currentUserHomeClubs: result?.current_user_home_club_locations,
          loaded: true
				})
        if(form === 'messenger' && postId === 'create-post' && [ 'compliance', 'announcement', 'event' ].includes(this.state.selectedPostType)){
          this.setState({
            selectedLocations: result?.locations?.length !== 0 ? result?.locations.map((item) => {return item.id}) : [],
            // selectedSkills: result?.skills?.length !== 0 ? result?.skills.map((item) => {return item.id}) : [],
            selectedRegions: result?.regions_and_locations?.length !== 0 ? result?.regions_and_locations?.map((item) => {return item?.region?.name}) : [],
          })
        }else if(form === 'edit-post'){
          if(this.state.selectedLocations?.length > 0){
            if(result?.locations?.length > 0){
              let locations = result?.locations?.map(item => item.id)
              let selectedLocations = this.state.selectedLocations.filter(item => locations.includes(item))
              this.setState({
                selectedLocations: selectedLocations
              })
            }
          }
        }
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
					loaded: true,
          discard: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

  checkPageTypeBeforeGetAudience = () => {
    let pathname = location.pathname?.split('/')
    let postId = pathname.pop()
    let form = pathname.pop()
    let params = new URLSearchParams(window.location.search)
    let type = (params.has('type') && params.get('type') !== '') ? params.get('type') : ''
    this.setState({
      formType: form === 'messenger' ? 'add' : 'edit',
      postId: postId
    })
    if ([ 'compliance', 'announcement', 'event' ].includes(type)){
      this.setState({filterUsersLoaded: false})
    }
    this.performFetchAudienceOnLoad("", undefined, undefined, form === 'messenger' ? true : false, undefined)
  }

  performFetchAudienceOnLoad = (search, locations, skills, storeAudience, homeClubs) => {
    getMessageAudience(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), search, locations, skills, this.state.selectedPostType === 'group_chat' ? true : false, homeClubs).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
          all_users: result?.results,
          totalUsers: result?.total_results,
          // users: result?.results,
          // selectedPeople: result?.results?.length !== 0 && this.state.formType === 'add' && result?.results.map((item) => {return item.id}),
          ccPeople: result?.admins,
          ccPeopleIds: result?.cc_ids,
          filterUsersLoaded: true
				})
        if(storeAudience && [ 'compliance', 'announcement', 'event' ].includes(this.state.selectedPostType)){
          this.setState({
            selectedPeople: result?.results.map((item) => {return item.id})
          })
        }
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
          filterUsersLoaded: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

  performGetAudience = (search, locations, skills, storeAudience, homeClubs) => {
    this.setState({filterUsersLoaded: false})
    getMessageAudience(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), search, locations, skills, this.state.selectedPostType === 'group_chat' ? true : false, homeClubs).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
          all_users: result?.results,
          totalUsers: result?.total_results,
          // users: result?.results,
          // selectedPeople: result?.results?.length !== 0 && this.state.formType === 'add' && result?.results.map((item) => {return item.id}),
          ccPeople: result?.admins,
          ccPeopleIds: result?.cc_ids,
          filterUsersLoaded: true
				})
        if(storeAudience){
          this.setState({
            selectedPeople: result?.results.map((item) => {return item.id})
          })
        }
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
          filterUsersLoaded: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
    document.body.removeEventListener('click', this.handleClosepeopleList)
  }

  handleSwitch = (e, name) => { 
		let allowComment = this.state.allowComment
    let setReminder = this.state.setReminder
    let allowEmojis = this.state.allowEmojis
    if(name === 'allowComment'){
		  this.setState({allowComment: !allowComment})
    }
    else if(name === 'setReminder'){
      this.setState({
        setReminder: !setReminder,
      })
      if(!e){
        this.setState(prevState => ({
          every: {
            ...prevState.every,           
            count: 3
          }
        }))
      }
    }
    else if(name === 'allowEmojis'){
      this.setState({
        allowEmojis: !allowEmojis,
      })
    }
	}

  getTextMessage = (val, empty) => {
    if(val === '<p></p>\n' || val === '' || val === '<p>&nbsp;&nbsp;</p>'){
      this.setState({message: '', discriptionBoxEmpty: false})
    }else if(val !== '' && val.includes('<img') && !empty){
      this.setState({message: val, discriptionBoxEmpty: true})
    }else if(val !== '' && val.includes('<loader-elem>') && empty){
      this.setState({message: val, discriptionBoxEmpty: false})
    }
    else{
      this.setState({message: val, discriptionBoxEmpty: empty})
    }
  }

  handleChange = (e) => {
    let name = e.target.name
    if(name.split('.')[0] === 'every'){
      let every = this.state.every
      if(name.split('.')[1] === 'count'){
        every.count = e.target.value
      }else if(name.split('.')[1] === 'type'){
        every.type = e.target.value
      }else if(name.split('.')[1] === 'notifyType'){
        every.notifyType = e.target.value
      }
      this.setState({every})
    }else if(name === "groupDescription"){
      this.setState({[name]: e.target.value})
      if (this.callRef) {
        this.callRef.current.style.height = "118px";
        const scrollHeight = this.callRef.current.scrollHeight;
        this.callRef.current.style.height = scrollHeight + "px";
      }
    }else {
      this.setState({[name]: e.target.value})
    }
  }

  checkTypeName = (clss) => {
    let classes =  this.state.postType;
    for (let i = 0; i < classes?.length; i++){
      if(classes[i]?.value.toString() === clss){
        return classes[i]?.label;
      }
    }
  }

  handleDateChange = (e) => {
    var startDate = new Date(e);
    // var day = 60 * 60 * 24 * 1000;
    // let data = new Date(startDate.getTime() + day);
    let month = ("0" + (startDate.getMonth() + 1)).slice(-2)
    let day = ("0" + startDate.getDate()).slice(-2)
    let year = startDate.getFullYear().toString()
    let data = month+"/"+day+"/"+year
    this.setState({
      due_date: e === null ? '' : e
    })
  }

  handleDiscard = (e) => {
    let { subject, message, selectedPeople } = this.state
    if(subject !== '' || message !== '' || selectedPeople?.length > 0){
      this.setState({showDiscardModal: true})
    }else{
      if(this.state.draftedPost){
        this.setState({showDiscardModal: true})
      }else{
        this.setState({discard: true})
      }
    }
    // this.setState({showDiscardModal: true})
  }

  closeDiscardModal = (e) => {
		this.setState({
			showDiscardModal: false,
      editPostConfModal: false
		})
	}

  selectedAllRegLoc = () => {
    let locationsIds = this.state.locations.map(item => item.id)
    let check = locationsIds.every(v => this.state.selectedLocations.includes(v))
    return check
  }

  selectedAllRegionsLocations = (e) => {
    if(e.target.checked){
      let all = this.state.regions_and_locations
      let selected_regions = []
      let selected_locations = []
      for(var i=0; i<all.length;i++){     
        selected_regions.push(all[i]['region']['name'])
        for(var j=0; j<all[i]['locations'].length; j++){
          selected_locations.push(all[i]['locations'][j]['id'])
        }
      }
      this.setState({
        selectedLocations: selected_locations,
        selectedRegions: selected_regions
      })
			this.performGetAudience('', [...selected_locations, ...this.state.selectedNonManagedLocations] , this.state.selectedSkills, true, [])
    }else{
      this.setState({
        selectedRegions: [],
        selectedLocations: []
      })
			this.performGetAudience('', [...[], ...this.state.selectedNonManagedLocations], this.state.selectedSkills, true, [])
    }
  }

  selectRegions = (e, region) => {
    let val = e.target.value
    let regions = this.state.selectedRegions
   
    var __FOUND = regions.indexOf(val) > -1;
    var result = region.locations.map(function(a) {return a.id;});
    let selected_locations = this.state.selectedLocations;
    
    if(e.target.checked){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }));
        let final_result = []
        for(let i = 0; i < result?.length; i++){
          if(!selected_locations.includes(result[i])){
            final_result.push(result[i])
          }
        }
        selected_locations = Array.prototype.push.apply(selected_locations, final_result)
      }
			this.performGetAudience('', [...this.state.selectedLocations, ...this.state.selectedNonManagedLocations], this.state.selectedSkills, true, [])
    }else{
      if(__FOUND){
        
        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)          
        });
        selected_locations = selected_locations.filter(item => !result.includes(item));
        this.setState({
          selectedLocations: selected_locations
        })
				this.performGetAudience('', [...selected_locations, ...this.state.selectedNonManagedLocations], this.state.selectedSkills, true, [])
      }
    }
  }

  selectLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedLocations.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newlocs = [...this.state.selectedLocations, val]
        this.setState(state => ({ selectedLocations: newlocs }));
        this.checkRegionVisibility(region, newlocs)
      }
    }else{
      if(__FOUND){
        const { selectedLocations } = this.state;
        let newLocs = selectedLocations.filter((tag, index) => tag !== val)  
        this.setState({
          selectedLocations: newLocs        
        });
        this.checkRegionVisibility(region, newLocs)  
      }      
    }
  }

  checkRegionVisibility = (region, locations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(locations, location_ids)
    let val = region.region?.name

    let regions = this.state.selectedRegions
    var __FOUND = regions.indexOf(val) > -1;
    if(equal){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }))
      }
    }else{
      if(__FOUND){
        
        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)          
        });
      }
    }
		this.performGetAudience('', [...locations, ...this.state.selectedNonManagedLocations], this.state.selectedSkills, true, [])
  }

  chekLocationNameCount = (name) => {
		let shortName = []
		for(let i = 0; i < name?.length; i++){
			if(i < 20){
				shortName.push(name[i])
			}else{
				shortName.push('...')
				break
			}
		}
	
		return shortName.join('')
	}

	locationName = () => {
    let locations = this.state.locations
    let selected_locations = this.state.selectedLocations
    let locationName = []
    let ctr = 0
    for(let i = 0; i < locations?.length; i++){
      if(selected_locations.includes(locations[i].id)){
        if(ctr < 2){
          locationName.push(locations[i].name)
          ctr++
        }
        else{
          break;
        }
      }
    }
    return this.chekLocationNameCount(locationName.join(", "))
  }

  selectAllSkills = () => {
    let check = this.state.skills?.length === this.state.selectedSkills?.length
    return check
  }

  handleSelectAllSkills = (e) => {
		if(e.target.checked){
      let allSkills = this.state.skills
      let selected_skills = []
      for(var i=0; i<allSkills.length;i++){ 
				selected_skills.push(allSkills[i]['id'])    
      }
      this.setState({
        selectedSkills: selected_skills,
      })
			this.performGetAudience('', [...this.state.selectedLocations, ...this.state.selectedNonManagedLocations], selected_skills, true, this.state.homeClubSelectedLocations)
    }else{
      this.setState({
        selectedSkills: []
      })
			this.performGetAudience('', [...this.state.selectedLocations, ...this.state.selectedNonManagedLocations], [], true, this.state.homeClubSelectedLocations)
    }
	}

  handleSelectSkills = (e) => {
		let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedSkills.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newSkills = [...this.state.selectedSkills, val]
        this.setState(state => ({ selectedSkills: newSkills }));
				this.performGetAudience('', [...this.state.selectedLocations, ...this.state.selectedNonManagedLocations], newSkills, true, this.state.homeClubSelectedLocations)
      }
    }else{
      if(__FOUND){
        const { selectedSkills } = this.state;
        let newSkills = selectedSkills.filter((tag, index) => tag !== val)  
        this.setState({
          selectedSkills: newSkills        
        }); 
				this.performGetAudience('', [...this.state.selectedLocations, ...this.state.selectedNonManagedLocations], newSkills, true, this.state.homeClubSelectedLocations)
      }      
    }
	}

  checkSelectedSkillsName = () => {
		let skills = this.state.skills
		let selectedSkills = this.state.selectedSkills
		let skillName = []
		let ctr = 0
		for(let i = 0; i < skills?.length; i++){
			if(selectedSkills.includes(skills[i].id)){
				if(ctr < 2){
					skillName.push(skills[i].name)
					ctr++
				}else{
					break;
				}
			}
		}
	
		return this.chekLocationNameCount(skillName.join(', '))
	}

  searchUsers = (e) => {
    let searchTerm = e.target.value.trim()
    // let classes = this.state.all_users
    if(searchTerm === ''){
      // this.setState({ users: []})
      this.serachData(e.target.value)
    }else{
      this.serachData(e.target.value)
      // var foundClasses = classes.filter(function (entry) {
      //   return (entry.full_name.toLowerCase().includes(searchTerm.toLowerCase()));
      // });
      // this.setState({
      //   users: foundClasses
      // })
    }
    this.setState({searchValue: e.target.value})
    
  }

  serachData = debounce(val => {
		this.performSearch(val)
	}, 800);

  performSearch = (search) => {
    searchAllUser(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), search).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
          users: result?.users,
				})
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

  selectAudience = (e) => {
    let val = parseInt(e.target.value)
    this.setState({focused: undefined})
    var __FOUND = this.state.selectedPeople.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newPeoples = [...this.state.selectedPeople, val]
        this.setState(state => ({ selectedPeople: this.shortUersList(newPeoples)}));
        // this.selectCCAudience(this.state.ccPeopleIds)
      }
    }else{
      if(__FOUND){
        if(this.checkShowCrossButton() === false){
          return
        }
        const { selectedPeople } = this.state;
        let newPeoples = selectedPeople.filter((tag, index) => tag !== val)  
        // if(newPeoples?.length === 0){
        //   this.setState({selectedCCPeople: this.shortUersList(newPeoples)})
        // }
        this.setState({
          selectedPeople: this.shortUersList(newPeoples)// newPeoples        
        }); 
      }      
    }
  }

  selectCCAudience = (ccPeopleIds) => {
    let selectedCCPeople = this.state.selectedCCPeople
    for(let i = 0; i < ccPeopleIds?.length; i++){
      var val = parseInt(ccPeopleIds[i])
      var __FOUND = selectedCCPeople.includes(val)
      
      if(!__FOUND){
        selectedCCPeople.push(val)
      }
      
    }
    this.setState({ selectedCCPeople: this.shortUersList(selectedCCPeople)});
    
  }

  peopleName = (id) => {
    let users = this.state.totalUsers
    for(let i = 0; i < users?.length; i++){
      if(users[i]?.id === id){
        return users[i].full_name
      }
    }
  }

  removeSelectedPeople = (id) => {
		let selectedPeople = this.state.selectedPeople
    let newPeoples = selectedPeople.filter(function(ele){ 
        return ele !== id;
    })
    if(newPeoples?.length === 0){
      this.setState({selectedCCPeople: this.shortUersList(newPeoples)})
    }
		this.setState({
			selectedPeople: newPeoples,
      all_selectedPeople: newPeoples
		})
	}

  selectAllUsers = () => {
    let users = this.state.users
    let selectedPeople = this.state.selectedPeople
    let users_ids = users.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(selectedPeople, users_ids)
    return equal
  }

  handleSelectAllUsers = (e) => {
    let users = this.state.users
    let selected_people = this.state.selectedPeople
    var result = users.map(function(a) {return a.id;});
    if(e.target.checked){
      for(var i=0; i<users?.length;i++){ 
				if(!selected_people.includes(users[i]['id'])){
          selected_people.push(users[i]['id'])  
        }  
      }
      this.setState({
        selectedPeople: this.shortUersList(selected_people)
      })
      // this.selectCCAudience(this.state.ccPeopleIds)
    }else{
			let new_peoples = selected_people.filter(item => !result.includes(item))    
      this.setState({
        selectedPeople: this.shortUersList(new_peoples)
      })
      // if(new_peoples?.length === 0){
      //   this.setState({selectedCCPeople: this.shortUersList(new_peoples)})
      // }
    }
  }

  handleSend = (e) => {
    e.preventDefault()
    this.setState({sendButtonDisabled: true})
    let finalMsg = this.removeSpacesFromMsg(this.state.message)
    let params = {
      'receivers': this.state.selectedPeople,
      // 'cc_receivers': this.state.selectedCCPeople,
      'message': {
        'id': this.state.messageId,
        'description': finalMsg,
        'title': this.state.subject.trim(),
        'expiration_date': this.state.due_date.toString(),
        'date': this.state.selectedPostType === "permanent_class_opening" ? this.state.eventStartDate.toString() : new Date(),
        'permanent_class_open': this.state.selectedPostType === "permanent_class_opening" ? true : false,
        'acknowledged': false,
        'who_acknowledge': this.state.acknowledgeRole,
        'business_location_id': this.state.eventSelectedLocation,
        'event_start_date': this.state.eventStartDate.toString(),
        'event_start_time': this.state.eventStartTime,
        'event_end_date': this.state.eventEndDate.toString(),
        'event_end_time': this.state.eventEndTime,
        'event_location_type': this.state.locationType
      },
      'location_ids': this.state.selectedLocations,
      'category_ids': this.state.selectedSkills,
      'allow_comments': this.state.allowComment,
      'type': this.state.selectedPostType,
      'reminder': this.state.setReminder,
      'reminder_days': this.state.every.count,
      'acknowledged_roles': this.state.acknowledgeRole,
      'reminder_type': this.state.every.notifyType,
      'attachment': this.state.attachments,
      'week_days': this.state.selectedPermanentClassDays,
      'allow_emojis': this.state.allowEmojis
    }

    sendMessage(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), params).then(      
			response => response.json()
		)
		.then(result => {
      // this.setState({sendButtonDisabled: false})
			if(result.status === 200){
				this.setState({
          redirectAt: result?.post_id,
          alert_message: result?.success,
          alert_type: 'success',
          show: true,
          // discard: true
				})
        this.setState({redirectAfterSend: false},()=>{
          window.setTimeout(()=>{
            this.setState({redirectAfterSend: true, sendButtonDisabled: false})
          },1500)
        });
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,
          sendButtonDisabled: false
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})

    
  }

  removeSpacesFromMsg = (text) => {
    let splitString = text.split('\n\n')
    let msg = []
    if(splitString[0] === "<p>&nbsp;</p>" || splitString[0] === ""){
      msg = this.removeSpaceFromStartMessage(splitString)
    }else{
      msg = splitString
    }

    let fnlMsg = []

    if(msg[msg?.length - 1] === "<p>&nbsp;</p>" || msg[msg?.length - 1] === "<p>&nbsp;</p>\n"){
      fnlMsg = this.removeSpaceFromEndMessage(msg)
      fnlMsg[fnlMsg?.length - 1] = fnlMsg[fnlMsg?.length - 1].toString()+'\n'
    }else{
      fnlMsg = msg
    }

    return fnlMsg.join('\n\n')

  }

  removeSpaceFromStartMessage = (msg) => {
    let splitString = msg
    splitString.shift()
    if(splitString[0] !== "<p>&nbsp;</p>"){
      return splitString
    }else{
      return  this.removeSpaceFromStartMessage(splitString)
    }
  }

  removeSpaceFromEndMessage = (msg) => {
    let splitString = msg
    splitString.pop()
    if(splitString[splitString?.length - 1] === "<p>&nbsp;</p>"  || splitString[splitString?.length - 1] === "<p>&nbsp;</p>\n"){
      return  this.removeSpaceFromEndMessage(splitString)
    }else{
      return splitString
    }
  }

  removeSelectedCCPeople = (id) => {
		let selectedCCPeople = this.state.selectedCCPeople
		this.setState({
			selectedCCPeople: selectedCCPeople.filter(function(ele){ 
				return ele !== id;
			})
		})
	}

  ccPeopleName = (id) => {
    let users = this.state.totalUsers
    for(let i = 0; i < users?.length; i++){
      if(users[i]?.id === id){
        return users[i].full_name
      }
    }
  }

  handleSaveDraft = (e) => {
    e.preventDefault()
    this.setState({draftButtonDisabled: true})
    let params = {
      'receivers': this.state.selectedPeople,
      // 'cc_receivers': this.state.selectedCCPeople,
      'message': {
        'id': this.state.messageId,
        'description': this.state.message,
        'title': this.state.subject.trim(),
        'expiration_date': this.state.due_date.toString(),
        'date': this.state.selectedPostType === "permanent_class_opening" ? this.state.eventStartDate.toString() : new Date(),
        'permanent_class_open': this.state.selectedPostType === "permanent_class_opening" ? true : false,
        'acknowledged': false,
        'who_acknowledge': this.state.acknowledgeRole,
        'business_location_id': this.state.eventSelectedLocation,
        'event_start_date': this.state.eventStartDate.toString(),
        'event_start_time': this.state.eventStartTime,
        'event_end_date': this.state.eventEndDate.toString(),
        'event_end_time': this.state.eventEndTime,
        'event_location_type': this.state.locationType
      },
      'location_ids': this.state.selectedLocations,
      'category_ids': this.state.selectedSkills,
      'allow_comments': this.state.allowComment,
      'type': this.state.selectedPostType,
      'reminder': this.state.setReminder,
      'reminder_days': this.state.every.count,
      'acknowledged_roles': this.state.acknowledgeRole,
      'reminder_type': this.state.every.notifyType,
      'attachment': this.state.attachments,
      'week_days': this.state.selectedPermanentClassDays,
      'allow_emojis': this.state.allowEmojis
    }

    saveDraft(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), params).then(      
			response => response.json()
		)
		.then(result => {
      this.setState({draftButtonDisabled: false})
			if(result.status === 200){
				this.setState({
          alert_message: result?.success,
          alert_type: 'success',
          show: true,
          discard: true
				})
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
        // this.setState({discard: false},()=>{
        //   window.setTimeout(()=>{
        //     this.setState({discard: true})
        //   },1500)
        // });
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

  handleAttachments = (e) => {
    e.preventDefault()
    if(e.target.files?.length == 0){
      return
    }
    let files = e.target.files
    let uploadingIds = []
    let id = this.state.attachments?.length == 0 ? 1 : Math.max(...this.state.attachments.map(evt => evt.id)) + 1
    let attachmentsData = []
    for(let i = 0; i < files?.length; i++){
      attachmentsData.push({'id': id+i, 'fileName': files[i]?.name, url: '', uploading: true})
    }
    uploadingIds = attachmentsData.map(item => item.id)
    
    this.setState({attachments: [...this.state.attachments, ...attachmentsData]})
    
    this.setState({disableAttachBtn: true})
    // const elm_attach_btn = document.getElementsByClassName('cke_button__attachments')
    // elm_attach_btn[0].classList.add("cke_button_disabled");
    
    for (const key of Object.keys(files)) {
      uploadAttachments(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), files[key], uploadingIds[key]).then(      
        response => response.json()
        .then(result => {
          this.setState({disableAttachBtn: false})
          // elm_attach_btn[0].classList.remove("cke_button_disabled");
          if(result.status === 200){
            let attachmentsData =  this.state.attachments
            for(let i = 0; i < attachmentsData?.length; i++){
              if(parseInt(result?.file_id) === parseInt(attachmentsData[i]?.id)){
                attachmentsData[i] = {...attachmentsData[i], id: result?.id, url: result?.url, uploading: false}
              }
            }
            // attachmentsData.push({'id': result?.id, 'fileName': result?.fileName, url: result?.url})
            this.setState({
              attachments: attachmentsData,
              alert_message: 'File uploaded successfully',
              alert_type: 'success',            
              loaded: true,
            })
          }else{
            this.setState({
              alert_message: result.error,
              alert_type: 'danger',            
              loaded: true
            })
          }
          this.setState({show:true},()=>{
            window.setTimeout(()=>{
              this.setState({show:false})
            },3000)
          });
        })
      )
    }
  }

  handleCrossAttachments = (id) => {
    let attachments = this.state.attachments
    let newAttachments = attachments.filter((tag, index) => tag?.id !== id)  
    this.setState({
      attachments: newAttachments        
    });
  }

  uploadCallback = (file, callback) => {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      reader.onloadend = async () => {
        const form_data = new FormData();
        form_data.append("file", file);
        // const res = await uploadImage(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), form_data);
        // const data = await res.json()
        
        // resolve({ data: { link: data.url } });
        uploadImage(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), form_data)
        .then(res => res.json())
            .then(result => {
                resolve({ data: { link: result.url } });

            })
            .catch(error => {
                resolve(error)
            })
      };
      reader.readAsDataURL(file);
      
    });
  };

  shortUersList = (selected_people) => {
    let all_users = this.state.totalUsers
    all_users.sort((a, b) => a.full_name.localeCompare(b.full_name, 'es', {sensitivity: 'base'}))
    let selected = all_users.filter((tag, index) => selected_people.includes(tag?.id))
    let selected_result = selected.map((user) => {return user?.id})
    return selected_result
  }

  handleBackUrl = () => {
    if(this.state.selectedPostType === 'group_chat'){
      let pathName = location.pathname
      if(pathName.includes(`/messenger/edit-chat-room`)){
        return pathName.replace('edit-','')
      }else{
        return `/messenger`
      }
    }else{
      if(location.pathname.includes(`/messenger/edit-chat-room`)){
        return location.pathname.replace('edit-','')
      }
      let pathname = location.pathname?.replace('edit', 'show')
    // pathname.pop()
    return pathname
    }
  }

  performEditPost = (e, send_notification) => {
    e.preventDefault()
    this.setState({editButtonDisabled: true, editPostConfModal: false})
    let finalMsg = this.removeSpacesFromMsg(this.state.message)
    let params = {
      'receivers': this.state.selectedPeople,
      // 'cc_receivers': this.state.selectedCCPeople,
      'message': {
        'id': this.state.messageId,
        'description': finalMsg,
        'title': this.state.subject.trim(),
        'expiration_date': this.state.due_date.toString(),
        'date': this.state.selectedPostType === "permanent_class_opening" ? this.state.eventStartDate.toString() : new Date(),
        'permanent_class_open': this.state.selectedPostType === "permanent_class_opening" ? true : false,
        'acknowledged': false,
        'who_acknowledge': this.state.acknowledgeRole,
        'business_location_id': this.state.eventSelectedLocation,
        'event_start_date': this.state.eventStartDate.toString(),
        'event_start_time': this.state.eventStartTime,
        'event_end_date': this.state.eventEndDate.toString(),
        'event_end_time': this.state.eventEndTime,
        'event_location_type': this.state.locationType 
      },
      'location_ids': this.state.selectedLocations,
      'category_ids': this.state.selectedSkills,
      'allow_comments': this.state.allowComment,
      'type': this.state.selectedPostType,
      'reminder': this.state.setReminder,
      'reminder_days': this.state.every.count,
      'acknowledged_roles': this.state.acknowledgeRole,
      'reminder_type': this.state.every.notifyType,
      'attachment': this.state.attachments,
      'send_notification': send_notification,
      'week_days': this.state.selectedPermanentClassDays,
      'allow_emojis': this.state.allowEmojis
    }

    editPost(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), params).then(      
			response => response.json()
		)
		.then(result => {
      // this.setState({editButtonDisabled: false})
			if(result.status === 200){
				this.setState({
          alert_message: result?.success,
          alert_type: 'success',
          show: true,
          editPostConfModal: false
				})
        if(result?.post_unarchive){
          this.setState({archivePostUpdated: false},()=>{
            window.setTimeout(()=>{
              this.setState({archivePostUpdated: true, editButtonDisabled: false})
            },1500)
          });
        }else{
          this.setState({updated: false},()=>{
            window.setTimeout(()=>{
              this.setState({updated: true, editButtonDisabled: false})
            },1500)
          });
        } 
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,
          editButtonDisabled: false
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

  checkWhereNeedToRedirect = () => {
    let pathname = location.pathname.split('/') //?.replace('edit', 'show')
    let newPath = []
    if(pathname[2] === 'archive'){
      newPath = pathname.filter(item => item !== 'archive')
    }else{
      newPath = pathname
    }
    return newPath.join('/')?.replace('edit', 'show')
  }

  handleEditPost = (e) => {
    // this.setState({editPostConfModal: true})
    const { totalResult, selectedPostType, messageId, message, subject, due_date, acknowledgeRole, eventSelectedLocation, eventStartDate, eventStartTime, eventEndDate, eventEndTime, locationType, selectedPeople, selectedLocations, selectedSkills, allowComment, setReminder, attachments, selectedPermanentClassDays } = this.state
    const { count, notifyType } = this.state.every
    
    let newDueDate = due_date == '' ? '' : due_date.getFullYear().toString()+'-'+due_date.getMonth().toString()+'-'+due_date.getDate().toString()
    let oldDate = totalResult?.message?.due_date === '' ? '' : new Date(Date.parse(totalResult?.message?.due_date_timestamp))
    let oldDueDate = oldDate === '' ? '' : oldDate.getFullYear().toString()+'-'+oldDate.getMonth().toString()+'-'+oldDate.getDate().toString()
    if(selectedPostType === "compliance"){
      // (totalResult?.audience_ids.every(v => selectedPeople.includes(v)) && selectedPeople.every(v => totalResult?.audience_ids.includes(v))) && allowComment == totalResult?.message?.allow_comments && setReminder == totalResult?.reminder && (totalResult?.acknowledged_roles.every(v => acknowledgeRole.includes(v)) && acknowledgeRole.every(v => totalResult?.acknowledged_roles.includes(v)))
      if(message == totalResult?.message?.description && subject == totalResult?.message?.title && newDueDate == oldDueDate){
        // if(totalResult?.reminder == true){
        //   if(count == totalResult?.reminder_days){
        //     this.performEditPost(e, false)
        //   }else{
        //     this.setState({editPostConfModal: true})
        //   }
        // }else {
          this.performEditPost(e, false)
        // }
      }else{
        this.setState({editPostConfModal: true})
      }
    }else if(selectedPostType === "announcement"){
      if(message == totalResult?.message?.description && subject == totalResult?.message?.title && newDueDate == oldDueDate ){
        this.performEditPost(e, false)
      }else{
        this.setState({editPostConfModal: true})
      }
    }else if(selectedPostType === "event"){

      let newStartDate = eventStartDate == '' ? '' : eventStartDate.getFullYear().toString()+'-'+eventStartDate.getMonth().toString()+'-'+eventStartDate.getDate().toString()
      let old_start_date = totalResult?.message?.start_date_timestamp === '' ? '' : new Date(Date.parse(totalResult?.message?.start_date_timestamp))
      let oldStartDate = old_start_date === '' ? '' : old_start_date.getFullYear().toString()+'-'+old_start_date.getMonth().toString()+'-'+old_start_date.getDate().toString()

      let newEndDate = eventEndDate == '' ? '' : eventEndDate.getFullYear().toString()+'-'+eventEndDate.getMonth().toString()+'-'+eventEndDate.getDate().toString()
      let old_end_date = totalResult?.message?.end_date_timestamp === '' ? '' : new Date(Date.parse(totalResult?.message?.end_date_timestamp))
      let oldEndDate = old_end_date === '' ? '' : old_end_date.getFullYear().toString()+'-'+old_end_date.getMonth().toString()+'-'+old_end_date.getDate().toString()

      if(message == totalResult?.message?.description && subject == totalResult?.message?.title && locationType == totalResult?.message?.event_location_type && newStartDate == oldStartDate && eventStartTime == totalResult?.message?.start_time && newEndDate == oldEndDate && eventEndTime == totalResult?.message?.end_time){
        if(locationType == 'studio_list'){
          if(eventSelectedLocation  == totalResult?.message?.business_location_id){
            this.performEditPost(e, false)
          }else{
            this.setState({editPostConfModal: true})
          }
        }else{
          if(eventSelectedLocation == totalResult?.message?.location_name){
            this.performEditPost(e, false)
          }else{
            this.setState({editPostConfModal: true})
          }
        }
      }else{
        this.setState({editPostConfModal: true})
      }
    }else if(selectedPostType === "permanent_class_opening"){
      let newStartDate = eventStartDate == '' ? '' : eventStartDate.getFullYear().toString()+'-'+eventStartDate.getMonth().toString()+'-'+eventStartDate.getDate().toString()
      let old_start_date = totalResult?.message?.date === '' ? '' : new Date(Date.parse(totalResult?.message?.date))
      let oldStartDate = old_start_date === '' ? '' : old_start_date.getFullYear().toString()+'-'+old_start_date.getMonth().toString()+'-'+old_start_date.getDate().toString()

      if(message == totalResult?.message?.description && subject == totalResult?.message?.title && newStartDate == oldStartDate && eventStartTime == totalResult?.message?.start_time && eventEndTime == totalResult?.message?.end_time && eventSelectedLocation  == totalResult?.message?.business_location_id && totalResult?.message?.week_days.every(v => selectedPermanentClassDays.includes(v)) &&  selectedPermanentClassDays.every(v => totalResult?.message?.week_days.includes(v))){
        this.performEditPost(e, false)
      }else{
        this.setState({editPostConfModal: true})
      }
    }else{
      this.setState({editPostConfModal: true})
    }
  }

  handleDeletePost = () => {
    this.setState({
      showDeleteModal: true
    })
  }

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
    })
  }

  processDeletePost = (e) => {
    e.preventDefault();
    this.setState({
      showDiscardModal: false,
      showDeleteModal: false
    })
    let postId = this.state.messageId
    deletePost(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), postId).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
          alert_message: result?.message,
          alert_type: 'success',
          show: true,
          showDiscardModal: false,
          showDeleteModal: false
				})
        this.setState({deleted: false},()=>{
          window.setTimeout(()=>{
            this.setState({deleted: true})
          },1500)
        });
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

  throwAfterDeletePost = () => {
    let pathname = location.pathname.split('/')
    pathname.pop()
    pathname.pop()
    return pathname.join('/')
  }

  handleWhoAcknowledge = (e) => {
    let val = e.target.value
    var __FOUND = this.state.acknowledgeRole.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newRole = [...this.state.acknowledgeRole, val]
        this.setState({ acknowledgeRole: newRole});
      }
    }else{
      if(__FOUND){
        const { acknowledgeRole } = this.state;
        let newRole = acknowledgeRole.filter((tag, index) => tag !== val)
        this.setState({
          acknowledgeRole: newRole 
        }); 
        if(newRole?.length == 0){
          this.setState({
            setReminder: false
          })
          this.setState(prevState => ({
            every: {
              ...prevState.every,           
              count: 3
            }
          }))
        }
      }      
    }
  }

  handleEventLocation = (e) => {
    if(e.target.checked){
      this.setState({
        eventSelectedLocation: parseInt(e.target.value)
      })
    }else{
      this.setState({
        eventSelectedLocation: ''
      })
    }
  }

  showEventLocationName = (location) => {
    let locations = this.state.locations
    let locationName = locations.filter(item => item?.id === location)
    return locationName[0]?.name
  }

  handleEventDateChange = (e, name) => {
    let val = e || ''
    if(name === 'eventStartDate'){
      this.setState({
        [name]: val
      })
      if(val === ''){
        return
      }
      if(this.state.showEndTime){
        if(this.state.eventEndDate === ''){
          let endTime = this.state.eventEndTime === '' ? '' : (this.state.to_hour_range.includes(this.state.eventEndTime) ? this.state.eventEndTime : this.state.to_hour_range[0])
          this.setState({
            eventEndDate: val,
            eventEndTime: endTime,
            end_hour_range: this.state.to_hour_range,
            start_hour_range: this.state.from_hour_range
          })
        }
        else if(this.state.eventEndDate?.getTime() < val.getTime()){
          let endTime = this.state.eventEndTime === '' ? '' : (this.state.to_hour_range.includes(this.state.eventEndTime) ? this.state.eventEndTime : this.state.to_hour_range[0])
          this.setState({
            eventEndDate: val,
            eventEndTime: endTime,
            end_hour_range: this.state.to_hour_range,
            start_hour_range: this.state.from_hour_range
          })
        }else if(this.state.eventEndDate?.getDate() === val.getDate() && this.state.eventEndDate?.getMonth() === val.getMonth() && this.state.eventEndDate?.getFullYear() === val.getFullYear()){
          let endTime = this.state.eventEndTime === '' ? '' : (this.state.to_hour_range.includes(this.state.eventEndTime) ? this.state.eventEndTime : this.state.to_hour_range[0])
          this.setState({
            end_hour_range: this.state.to_hour_range,
            eventEndTime: endTime,
            start_hour_range: this.state.from_hour_range
          })
        }else if(this.state.eventEndDate?.getTime() > val.getTime()){
          this.setState({
            end_hour_range: this.state.help_range,
            start_hour_range: this.state.help_range,
          })
        }
      }
    }else{
      this.setState({
        eventEndDate: val
      })
      if(val === ''){
        return
      }
      if(this.state.eventStartDate?.getDate() === val.getDate() && this.state.eventStartDate?.getMonth() === val.getMonth() && this.state.eventStartDate?.getFullYear() === val.getFullYear()){
        this.setState({
          end_hour_range: this.state.to_hour_range,
          eventEndTime: this.state.eventEndTime === '' ? '' : this.state.to_hour_range[0],
          start_hour_range: this.state.from_hour_range
        })
      }else{
        this.setState({
          end_hour_range: this.state.help_range,
          start_hour_range: this.state.help_range,
        })
      }
    }
  }

  getBusinessRange = () => {
    let businessHour = {time_from: '12:00 AM', time_to: '11:55 PM'}
    let start_time = businessHour?.time_from
    let end_time = businessHour?.time_to
    let start_time_ampm = start_time.split(" ")
    let end_time_ampm = end_time.split(" ")
    let start_time_hours = start_time_ampm[0]
    let end_time_hours = end_time_ampm[0]
    let large_start_time_hours = ""
    let large_end_time_hours = ""
    if(start_time_ampm[1] === "PM"){
      if(start_time_hours.split(":")[0] !== "12"){
        large_start_time_hours = (Number(start_time_hours.split(":")[0]) + 12).toString()+":"+start_time_hours.split(":")[1]
      }
      else{
        large_start_time_hours = start_time_hours
      }
    }
    else{
      if(start_time_hours.split(":")[0] === "12"){
        large_start_time_hours = "0:"+start_time_hours.split(":")[1]
      }
      else{
        large_start_time_hours = start_time_hours
      }
    }

    if(end_time_ampm[1] === "PM"){
      if(end_time_hours.split(":")[0] !== "12"){
        large_end_time_hours = (Number(end_time_hours.split(":")[0]) + 12).toString()+":"+end_time_hours.split(":")[1]
      }
      else{
          large_end_time_hours = end_time_hours
      }
    }
    else{
        if(end_time_hours.split(":")[0] === "12"){
          large_end_time_hours = "0:"+end_time_hours.split(":")[1]
        }
        else{
          large_end_time_hours = end_time_hours
        }
      }

    let start_hour_min = large_start_time_hours.split(":")
    let end_hour_min = large_end_time_hours.split(":")
    let start_hour = Number(large_start_time_hours.split(":")[0])
    let end_hour = Number(large_end_time_hours.split(":")[0])
    let row_range = []
    for (; start_hour <= end_hour; start_hour++) {
      let period = start_hour < 12 ? "AM" : "PM";
      let hour = start_hour === 0 ? 12 : start_hour > 12 ? start_hour - 12 : start_hour;
      for (let minute = 0; minute < 60; minute += 5) {
        let time = `${hour.toString()}:${minute.toString().padStart(2, "0")} ${period}`;
        row_range.push(time);
      }
    }
    for(let i = 0; i < 12; i++){
      if(start_time !== row_range[0]){
        row_range.shift();
      }else{
        break;
      }
    }
    for(let i = 0; i < 12; i++){
      if(end_time !== row_range[row_range?.length-1]){
        row_range.pop();
      }else{
        break;
      }
    }
    let range = []
    let starting_range = row_range?.length -1
    // let params = new URLSearchParams(window.location.search)
    // let type = (params.has('type') && params.get('type') !== '') ? params.get('type') : ''
    // if (type == 'permanent_class_opening'){
    //   starting_range = row_range?.length -3
    // }
    for (let i = 0;i < starting_range; i++){
      range.push(row_range[i])
    }

    let from_time_index = row_range.indexOf(businessHour?.time_from)
    let check = []
    for (let i = from_time_index +1;i < row_range?.length; i++){
      check.push(row_range[i])
    } 

    this.setState({
      help_range: row_range,
      start_hour_range: range,
      from_hour_range: range,
      end_hour_range: check,
      to_hour_range: check,
    });
    if(this.state.formType === 'edit' && this.state.showEndTime && this.state.selectedPostType === 'event'){
      let eventStartDate = this.state.eventStartDate
      let eventEndDate = this.state.eventEndDate
      if(eventStartDate !== '' && eventEndDate !== ''){
        if(eventStartDate.getTime() !== eventEndDate.getTime()){
          this.setState({
            start_hour_range: row_range,
            end_hour_range: row_range
          })
        }else{
          let from_time_index = row_range.indexOf(this.state.eventStartTime)
          let timeFrames = row_range.filter((item, index) => index > from_time_index)
          this.setState({
            end_hour_range: timeFrames
          })
        }
      }
      if(this.state.eventStartTime !== '' && this.state.eventEndTime !== ''){
        let from_time_index = row_range.indexOf(this.state.eventStartTime)
        let check = []
        for (let i = from_time_index +1;i < row_range?.length; i++){
          check.push(row_range[i])
        } 
        this.setState({
          to_hour_range: check,
        });
        
      }
    }
  }

  handleEventTime = (e) => {
    let name = e.target.name
    if(name === 'eventStartTime'){
      let from_time_index = this.state.help_range.indexOf(e.target.value)
      let check = []
      for (let i = from_time_index +1;i < this.state.help_range?.length; i++){
        check.push(this.state.help_range[i])
      } 
      
      if(!check.includes(this.state.eventEndTime) && this.state.showEndTime && this.state.eventStartDate.getTime() === this.state.eventEndDate.getTime()){
        this.setState({
          eventEndTime: check[0],
          end_hour_range: check
        })
      }

      this.setState({
        // end_hour_range: check,
        to_hour_range: check,
        eventStartTime: e.target.value
      });
    } else {
      this.setState({
        eventEndTime: e.target.value,
      })
    }
  }

  handlePermanentClassTime = (e) => {
    let name = e.target.name
    if(name === 'eventStartTime'){
      let from_time_index = this.state.help_range.indexOf(e.target.value)
      let check = []
      for (let i = from_time_index +1;i < this.state.help_range?.length; i++){
        check.push(this.state.help_range[i])
      } 
      
      if(!check.includes(this.state.eventEndTime)){
        this.setState({
          eventEndTime: check[2]
        })
      }

      this.setState({
        end_hour_range: check,
        to_hour_range: check,
        eventStartTime: e.target.value
      });
    } else {
      this.setState({
        eventEndTime: e.target.value,
      })
    }
  }

  checkDisableSendButto = () => {
    if(this.state.selectedPostType === 'event'){
      if(this.state.selectedPeople?.length > 0 && this.state.message !== '' && this.state.discriptionBoxEmpty && this.state.subject.trim() !== '' && this.state.eventSelectedLocation.toString().trim() !== '' && this.state.eventStartTime !== '' && this.state.eventStartDate !== '' && !this.state.showEndTime  && !this.checkUploadingAtachment()){
        return true
      } else if(this.state.selectedPeople?.length > 0 && this.state.message !== '' && this.state.discriptionBoxEmpty && this.state.subject.trim() !== '' && this.state.eventSelectedLocation.toString().trim() !== '' && this.state.eventStartTime !== '' && this.state.eventStartDate !== '' && this.state.showEndTime && this.state.eventEndDate !== '' && this.state.eventEndTime !== '' && !this.checkUploadingAtachment()){
        return true
      } else {
        return false
      }
    }else if(this.state.selectedPostType === 'group_chat'){
			if(this.state.selectedPeople?.length > 0 && this.state.rolesCanPost?.length > 0 && this.state.groupName !== '' && !this.state.groupNameAlreadyPresent){
				return true
			} else {
				return false
			}
		}else if(this.state.selectedPostType === 'compliance'){
      if(this.state.selectedPeople?.length > 0 && this.state.message !== '' && this.state.discriptionBoxEmpty && this.state.subject.trim() !== '' && this.state.acknowledgeRole?.length !== 0 && this.state.due_date !== '' && !this.checkUploadingAtachment()){
        return true
      } else {
        return false
      }
    }else if(this.state.selectedPostType === 'direct_message'){
      if(this.state.selectedPeople?.length > 0 && ((this.state.dmMessage !== '' && this.state.formType === 'add') || (this.state.formType !== 'add'))){
        return true
      } else {
        return false
      }
    }else if(this.state.selectedPostType === 'permanent_class_opening'){
      if(this.state.selectedPeople?.length > 0 && this.state.message !== '' && this.state.discriptionBoxEmpty && this.state.subject.trim() !== '' && this.state.eventStartTime !== '' && this.state.eventStartDate !== '' && this.state.eventEndTime !== '' && this.state.eventSelectedLocation.toString().trim() !== '' && this.state.selectedPermanentClassDays?.length > 0 && !this.checkUploadingAtachment()){
        return true
      } else {
        return false
      }
    }else{
      if(this.state.selectedPeople?.length > 0 && this.state.message !== '' && this.state.discriptionBoxEmpty && this.state.subject.trim() !== '' && !this.checkUploadingAtachment()){
        return true
      } else {
        return false
      }
    }
  }

  checkUploadingAtachment = () => {
    let attachements = this.state.attachments
    const found = attachements.some(r=> r.uploading)
    return found
  }

  handleLocationType = (e, type) => {
    let locationType = this.state.locationType
    if(type === locationType){
      this.setState({
        locationType: ''
      })
    }else{
      this.setState({
        locationType: type,
        eventSelectedLocation: ''
      })
    }
  }

  handleShowEndTime = (type) => {
    if(type){
      this.setState({
        showEndTime: type,
        eventEndDate: this.state.eventStartDate,
        eventEndTime: '',
        end_hour_range: this.state.to_hour_range
      })
    }else{
      this.setState({
        showEndTime: type,
        eventEndDate: '',
        eventEndTime: ''
      })
    }
    
  }

  rolesName = (roles) => {
    let showName = []
    for(let i = 0; i< roles?.length; i++){
      if(i < 2){
        showName.push(roles[i])
      }else{
        showName.push(`+${roles?.length - (i)}`)
        break;
      }
    }
    return showName.join(', ')
  }

  handleChangePostType = (e, type) => {
    let name = e.target.name
    let val = type
    if(val === 'compliance'){
      this.setState({
        [name]: val,
        due_date: new Date()
      })
      this.selectAllPeopleLocSkill()
    }else if(val === 'event'){
      this.setState({
        [name]: val,
        eventStartDate: new Date(),
        eventEndDate: '',
        eventStartTime: '',
        eventEndTime: '',
        eventSelectedLocation: '',
        showEndTime: false
      })
      this.selectAllPeopleLocSkill()
      this.getBusinessRange()
    }else if(val === 'group_chat'){
      // this.getGroupNames()
      this.setState({
        [name]: val,
        selectedRegions: [],
        selectedLocations: [],
        selectedSkills: [],
        selectedPeople: []
      })
    }else if(val === 'direct_message'){
      // this.getGroupNames()
      this.setState({
        [name]: val,
        selectedRegions: [],
        selectedLocations: [],
        selectedSkills: [],
        selectedPeople: [],
        searchDmValue: ''
      })
    }
    else{
      this.setState({
        [name]: val,
        due_date: ''
      })
      this.selectAllPeopleLocSkill()
    }
  }

  selectAllPeopleLocSkill = () => {
    const { all_users, locations, regions_and_locations } = this.state
    let allPeopleIds = all_users.map((item) => item.id)
    this.setState({
      selectedPeople: allPeopleIds,
      selectedLocations: locations?.length !== 0 ? locations.map((item) => {return item.id}) : [],
      // selectedSkills: result?.skills?.length !== 0 ? result?.skills.map((item) => {return item.id}) : [],
      selectedRegions: regions_and_locations?.length !== 0 ? regions_and_locations.map((item) => {return item?.region?.name}) : [],
    })
  }

  handleReminderDayKeyDown = (e) => {
    // if (e.keyCode == '38' || e.keyCode == '40') {
    // }else{
    //   e.preventDefault()
    // } 
    var invalidChars = [
      "-",
      "+",
      "e",
      "."
    ]
    if(invalidChars.includes(e.key)){
      e.preventDefault()
    }
  }

  handleReminderDayChange = (e) => {
    let name = e.target.name
    if(name.split('.')[0] === 'every'){
      let every = this.state.every
      if(name.split('.')[1] === 'count'){
        if(e.target.value !== ''){
          if(e.target.value > 20){
            return
          }else{
            let val = e.target.value.replace(/^0+/, '');
            every.count = val
          }
        }else{
          every.count = e.target.value
        }
      }
      this.setState({every})
    }
  }

  handleReminderDayOnBlur = (e) => {
    let every = this.state.every
    if(e.target.value === '' || parseInt(e.target.value) === 0){
      every.count = 3
    }
    this.setState({every})
  }

  toggleShowAllAudience = () => {
    this.setState({
      showSelectedPeopleModal: true,
      all_selectedPeople: this.state.selectedPeople
    })
  }

  closeAudienceModal = () => {
    this.setState({
      showSelectedPeopleModal: false
    })
  }

  handleOnBlur = (e) => {
    this.setState({showPeoplelist: true})
  }

  handleOnCLick = (e) => {
    this.setState({showPeoplelist: true})
    this.performSearch(e.target.value)
  }

  handleSearchAudience = (e) => {
    this.setState({searchAudience: e.target.value})
    const searchTerm = e.target.value.trim()
    // const users = this.state.all_users
    const users = this.state.totalUsers
    const selected_people = this.state.selectedPeople
    if(searchTerm === ''){
      this.setState({all_selectedPeople: selected_people})
    }else{
      var foundUsers = users.filter(function (entry) {
        return (entry.full_name.toLowerCase().includes(searchTerm.toLowerCase()) && selected_people.includes(entry.id));
      });
      this.setState({
        all_selectedPeople: foundUsers.map((item) => item.id)
      })
    }
  }

  checkSelectedRolesForAcnowledge = () => {
    const { roles_array, acknowledgeRole } = this.state
    if(roles_array?.length === acknowledgeRole?.length){
      return true
    }else{
      return false
    }
  }

  handleSelectAllWhoAcknowledge = (e) => {
    if(e.target.checked){
      this.setState({acknowledgeRole: this.state.roles_array})
    }else{
      this.setState({
        acknowledgeRole: [],
        setReminder: false
      })
      this.setState(prevState => ({
        every: {
          ...prevState.every,           
          count: 3
        }
      }))
    }
  }

  showDragDropError = () => {
    this.setState({ 
      showDragDropError: true,
    })
    this.setState({showDragDropError: true},()=>{
      window.setTimeout(()=>{
        this.setState({showDragDropError: false})
      },5000)
    });
  }

  trimAttachmentNames = (attachment) => {
    let attach = ''
    let attachName = attachment.split('.')
    let name = attachName[0]
    let extension = attachName[attachName?.length -1]
    if(name?.length > 35){
      let splitName = name.substring(0, 35)+'...';
      attach = splitName+'.'+extension
    }else{
      attach = attachment
    }
    return attach
  }

  handleChangeGroupName = (e) => {
    if(e.target.value?.length > 30){
      e.preventDefault()
    }else{
      this.setState({
        groupName: e.target.value
      })
      // this.searchGroupNames(e.target.value.trim())
    }
  }

  checkRolesCanPost = () => {
    const { enabledRoles, rolesCanPost } = this.state
    if(enabledRoles.every(v => rolesCanPost.includes(v))){
      return true
    }else{
      return false
    }
  }

  handleSelectAllRolesCanPost = (e) => {
    if(e.target.checked){
      if(this.state.higerSavedRoles.length > 0){
        let all_roles = []
        if(this.state.disabledRoles.length > 0){
          all_roles = [...this.state.higerSavedRoles, ...this.state.enabledRoles]
        }else{
          all_roles = [...this.state.higerSavedRoles, ...this.state.roles_array]
        }
        let uniqArray = _.uniq(all_roles)
        this.setState({rolesCanPost: uniqArray})
      }else if(this.state.disabledRoles.length > 0){
        this.setState({rolesCanPost: this.state.enabledRoles})
      }else{
        this.setState({rolesCanPost: this.state.roles_array})
      }
    }else{
      if(this.state.higerSavedRoles.length > 0){
        this.setState({
        rolesCanPost: this.state.rolesCanPost.filter((tag) => this.state.higerSavedRoles.includes(tag))
        })
      }else{
        this.setState({
          rolesCanPost: [],
        })
      }
    }
  }

  handleRolesCanPost = (e) => {
    let val = e.target.value
    var __FOUND = this.state.rolesCanPost.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newRole = [...this.state.rolesCanPost, val]
        this.setState({ rolesCanPost: newRole});
      }
    }else{
      if(__FOUND){
        const { rolesCanPost } = this.state;
        let newRole = rolesCanPost.filter((tag, index) => tag !== val)
        this.setState({
          rolesCanPost: newRole 
        }); 
      }      
    }
  }

  handleUploadGroupImage = (e) => {        
    if(e.target.files && e.target.files?.length > 0){     
      this.setState({
        groupImageFile: e.target.files[0],
      })
    }
	}

	handleCreateGroup = (e) => {
    const { currentUserSkills, selectedSkills, currentUserHomeClubs, homeClubSelectedLocations } = this.state

    if(homeClubSelectedLocations?.length !== 0 && selectedSkills?.length !== 0){
      if (currentUserHomeClubs?.length != 0 && currentUserSkills?.length != 0){
        let checker = (arr, target) => target.some(v => arr.includes(v));
        let equal1 = checker(homeClubSelectedLocations, currentUserHomeClubs)
        let equal2 = checker(selectedSkills, currentUserSkills)
        if(equal1 && equal2){
          this.setState({
            addCurrentUserInGroup: true
          })
          window.setTimeout(()=>{
            this.checkIfGroupPresent(e)
          },200)
        }else{
          this.setState({
            showCreatorWantInGroupPopup: true
          })
        }
      }else{
        this.setState({
          showCreatorWantInGroupPopup: true
        })
      }
    }else if(homeClubSelectedLocations?.length !== 0 && selectedSkills?.length === 0){
      if (currentUserHomeClubs?.length != 0){
        let checker = (arr, target) => target.some(v => arr.includes(v));
        let equal = checker(homeClubSelectedLocations, currentUserHomeClubs)
        if(equal){
          this.setState({
            addCurrentUserInGroup: true
          })
          window.setTimeout(()=>{
            this.checkIfGroupPresent(e)
          },200)
        }else{
          this.setState({
            showCreatorWantInGroupPopup: true
          })
        }
      }else{
        this.setState({
          showCreatorWantInGroupPopup: true
        })
      }
    }else if(homeClubSelectedLocations?.length === 0 && selectedSkills?.length !== 0){
      if (currentUserSkills?.length != 0){
        let checker = (arr, target) => target.some(v => arr.includes(v));
        let equal = checker(selectedSkills, currentUserSkills)
        if(equal){
          this.setState({
            addCurrentUserInGroup: true
          })
          window.setTimeout(()=>{
            this.checkIfGroupPresent(e)
          },200)
        }else{
          this.setState({
            showCreatorWantInGroupPopup: true
          })
        }
      }else{
        this.setState({
          showCreatorWantInGroupPopup: true
        })
      }
    }else if(homeClubSelectedLocations?.length === 0 && selectedSkills?.length === 0){
      this.setState({
        addCurrentUserInGroup: true
      })
      window.setTimeout(()=>{
        this.checkIfGroupPresent(e)
      },200)
    }
    // if (selectedSkills?.length != 0){
    //   if (currentUserSkills?.length != 0){
    //     let checker = (arr, target) => target.some(v => arr.includes(v));
    //     let equal = checker(selectedSkills, currentUserSkills)
    //     if(equal){
    //       this.setState({
    //         addCurrentUserInGroup: true
    //       })
    //       window.setTimeout(()=>{
    //         this.checkIfGroupPresent(e)
    //       },200)
    //     }else{
    //       this.setState({
    //         showCreatorWantInGroupPopup: true
    //       })
    //     }
    //   }else{
    //     this.setState({
    //       showCreatorWantInGroupPopup: true
    //     })
    //   }
    // }else{
    //   this.setState({
    //     addCurrentUserInGroup: true
    //   })
    //   window.setTimeout(()=>{
    //     this.checkIfGroupPresent(e)
    //   },200)
    // }
  }

  checkIfGroupPresent = (e) => {
    this.setState({createGroupBtnDisabled: true})
    const { selectedPeople, selectedLocations, selectedSkills, groupId, addCurrentUserInGroup, homeClubSelectedLocations } = this.state

    let params = {
      category_ids: selectedSkills,
      location_ids: selectedLocations,
      receivers: selectedPeople,
      stream_id: groupId,
      add_admin: addCurrentUserInGroup,
      home_club_ids: homeClubSelectedLocations
    }

    checkGroupPresent(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), params).then(      
			response => response.json()
		)
		.then(result => {
      if(result.status === 200){
        if(this.state.formType == 'edit'){
          this.handleProcessEditGroupChat(e)
        }else{
				  this.handleProcessCreateGroup(e)
        }
			}else{
				this.setState({
					showConfirmGroupPresentPopup: true,
          createGroupBtnDisabled: false
				})
			}
    })
  }

  handleProcessCreateGroup = (e) => {
		this.setState({createGroupBtnDisabled: true})
		const { groupName, groupDescription, selectedPeople, selectedLocations, selectedSkills, rolesCanPost, groupImage, selectedPostType, groupImageFile, addCurrentUserInGroup, homeClubSelectedLocations } = this.state
		let message = {
			"title": groupName.trim(),
			"description": groupDescription,
		}

    const formData = new FormData()

    formData.append('group_data[title]', groupName.trim());
    formData.append('group_image', groupImageFile);
    formData.append('group_data[description]', groupDescription);
    formData.append('group_data[type]', selectedPostType);
    formData.append('group_data[category_ids]', selectedSkills);
    formData.append('group_data[location_ids]', selectedLocations);
    formData.append('group_data[receivers]', selectedPeople);
    formData.append('group_data[acknowledged_roles]', rolesCanPost);
    formData.append('group_data[is_private]', false);
    formData.append('group_data[add_admin]', addCurrentUserInGroup);
    formData.append('group_data[home_club_ids]', homeClubSelectedLocations);
		
		createGroup(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), formData).then(      
			response => response.json()
		)
		.then(result => {
      // this.setState({sendButtonDisabled: false})
			if(result.status === 200){
				this.setState({
          redirectAtGroupPage: `/messenger/chat-room/${result?.id}`,
          alert_message: result?.message,
          alert_type: 'success',
          show: true,
          // discard: true
				})
        this.setState({redirectAfterCreateGroup: false},()=>{
          window.setTimeout(()=>{
            this.setState({createGroupBtnDisabled: false, redirectAfterCreateGroup: true})
          },1500)
        });
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,
          createGroupBtnDisabled: false
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
	}

  handleEditGroupChat = (e) => {
    const { currentUserSkills, selectedSkills } = this.state
    if (selectedSkills?.length != 0){
      if (currentUserSkills?.length != 0){
        let checker = (arr, target) => target.some(v => arr.includes(v));
        let equal = checker(selectedSkills, currentUserSkills)
        if(equal){
          this.setState({
            addCurrentUserInGroup: true
          })
          window.setTimeout(()=>{
            this.checkIfGroupPresent(e)
          },200)
        }else{
          this.setState({
            showCreatorWantInGroupPopup: true
          })
        }
      }else{
        this.setState({
          showCreatorWantInGroupPopup: true
        })
      }
    }else{
      this.setState({
        addCurrentUserInGroup: true
      })
      window.setTimeout(()=>{
        this.checkIfGroupPresent(e)
      },200)
    }
  }

  handleProcessEditGroupChat = (e) => {
    this.setState({editButtonDisabled: true})
		const { groupName, groupDescription, selectedPeople, selectedLocations, selectedSkills, rolesCanPost, groupImage, selectedPostType, groupImageFile, uploadedGroupImage, groupId, selectedNonManagedLocations, addCurrentUserInGroup, homeClubSelectedLocations } = this.state

    let selLocs = [...selectedLocations, ...selectedNonManagedLocations]

    const formData = new FormData()

    formData.append('group_data[stream_id]', groupId);
    formData.append('image', uploadedGroupImage);
    formData.append('group_data[title]', groupName.trim());
    formData.append('group_image', groupImageFile);
    formData.append('group_data[description]', groupDescription);
    formData.append('group_data[type]', selectedPostType);
    formData.append('group_data[category_ids]', selectedSkills);
    formData.append('group_data[location_ids]', selLocs);
    formData.append('group_data[receivers]', selectedPeople);
    formData.append('group_data[acknowledged_roles]', rolesCanPost);
    formData.append('group_data[is_private]', false);
    formData.append('group_data[add_admin]', addCurrentUserInGroup);
    formData.append('group_data[home_club_ids]', homeClubSelectedLocations);
		
		updateGroup(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), formData).then(      
			response => response.json()
		)
		.then(result => {
      // this.setState({sendButtonDisabled: false})
			if(result.status === 200){
				this.setState({
          redirectAtGroupPage: `/messenger/chat-room/${result?.id}`,
          alert_message: result?.message,
          alert_type: 'success',
          show: true,
          // discard: true
				})
        this.setState({redirectAfterCreateGroup: false},()=>{
          window.setTimeout(()=>{
            this.setState({editButtonDisabled: false, redirectAfterCreateGroup: true})
          },3000)
        });
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,
          editButtonDisabled: false
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },5000)
        });
			}
		})
  }

  handleCreateDM = (e) => {
    this.setState({createDmBtnDisabled: true})
    const { selectedPeople, selectedPostType, dmMessage } = this.state

    const formData = new FormData()

    formData.append('group_data[message]', dmMessage);
    formData.append('group_data[type]', selectedPostType);
    formData.append('group_data[receivers]', selectedPeople);
    formData.append('group_data[is_private]', true);
		
		createGroup(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), formData).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
          redirectAtGroupPage: `/messenger/chat-room/${result?.id}`,
          alert_message: result?.message,
          alert_type: 'success',
          show: true,
				})
        this.setState({createDmBtnDisabled: false},()=>{
          window.setTimeout(()=>{
            this.setState({createDmBtnDisabled: false, redirectAfterCreateGroup: true})
          },1500)
        });
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,
          createDmBtnDisabled: false
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})

  }

  searchDmUsers = (e) => {
    let searchTerm = e.target.value.trim()
    if(searchTerm === ''){
      this.serachDmData(e.target.value)
    }else{
      this.serachDmData(e.target.value)
    }
    this.setState({searchDmValue: e.target.value})
  }

  serachDmData = debounce(val => {
		this.performSearchDmUsers(val)
	}, 800);

  performSearchDmUsers = (search) => {
    searchDmAllUser(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), search).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
          dmUsers: result?.users,
          focused: undefined
				})
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

  selectAllDmUsers = () => {
    let users = this.state.dmUsers
    let selectedPeople = this.state.selectedPeople
    let users_ids = users.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(selectedPeople, users_ids)
    return equal
  }

  handleSelectAllDmUsers = (e) => {
    let users = this.state.dmUsers
    let selected_people = this.state.selectedPeople
    var result = users.map(function(a) {return a.id;});
    if(e.target.checked){
      for(var i=0; i<users?.length;i++){ 
				if(!selected_people.includes(users[i]['id'])){
          selected_people.push(users[i]['id'])  
        }  
      }
      this.setState({
        selectedPeople: this.shortUersList(selected_people)
      })
    }else{
			let new_peoples = selected_people.filter(item => !result.includes(item))    
      this.setState({
        selectedPeople: this.shortUersList(new_peoples)
      })
    }
  }

  handleOnBlurDmSearch = (e) => {
    this.setState({showPeoplelist: true})
  }

  handleOnCLickDmSearch = (e) => {
    this.setState({showPeoplelist: true})
    this.performSearchDmUsers(e.target.value)
  }

  handleKeyDownDmSearch = (event) => {
    let dmUsers = this.state.dmUsers
    let prevFocused = this.state.focused
    if(dmUsers?.length > 0){
      let suggetionBox = document.getElementsByClassName('suggesion_box_dm_search')
      let activeItem =  []
      var elHeight = 0
      if (event.key === 'ArrowDown') {
        if (prevFocused === undefined || dmUsers === undefined){
          this.setFocused(1)
          suggetionBox[0].scrollTop = elHeight
        }else{
          activeItem =  document.getElementsByClassName('active-suggesion-item')
          elHeight = activeItem[0].clientHeight
          if(prevFocused === dmUsers.length){ 
            this.setFocused(1)
            suggetionBox[0].scrollTop = elHeight*(1 - 1);
          }else{
            this.setFocused(prevFocused + 1)
            suggetionBox[0].scrollTop = elHeight*((prevFocused + 1) - 1);
          }
        }
      } else if (event.key === 'ArrowUp') {
        if (prevFocused === undefined || dmUsers === undefined){
          this.setFocused(1)
          suggetionBox[0].scrollTop = elHeight
        }else{
          activeItem =  document.getElementsByClassName('active-suggesion-item')
          elHeight = activeItem[0].clientHeight
          if(prevFocused === 1){ 
            this.setFocused(dmUsers.length)
            suggetionBox[0].scrollTop = elHeight*((dmUsers.length) - 1);
          }else{
            this.setFocused(prevFocused - 1)
            suggetionBox[0].scrollTop = elHeight*((prevFocused - 1) - 1);
          }
        }
      } else if (event.key === 'Enter') {
        event.preventDefault();

        if (dmUsers !== undefined && prevFocused !== undefined) {
          const usersToCheck = dmUsers[prevFocused-1];
          this.selectKeyDownAudience(usersToCheck?.id)
          // this.setState({
          //   dmUsers: [], 
          //   searchDmValue: '', 
          //   focused: undefined
          // })
        }
      }
    }
  }

  setFocused = (value) => {
    this.setState({focused: value})
  }

  selectKeyDownAudience = (userId) => {
    let val = parseInt(userId)
    var __FOUND = this.state.selectedPeople.includes(val)
    if(!__FOUND){
      let newPeoples = [...this.state.selectedPeople, val]
      this.setState(state => ({ selectedPeople: this.shortUersList(newPeoples)}));
    }else if(__FOUND){
      const { selectedPeople } = this.state;
      let newPeoples = selectedPeople.filter((tag, index) => tag !== val)  
      this.setState({
        selectedPeople: this.shortUersList(newPeoples)      
      }); 
    }      
  }

  handleSelectDays = (day) => {
    const { selectedPermanentClassDays } = this.state
    let val = day
    var __FOUND = selectedPermanentClassDays.includes(val)
    if(!__FOUND){
      let newDays = [...selectedPermanentClassDays, val]
      this.setState(state => ({ selectedPermanentClassDays: newDays }));
    }if(__FOUND){
      let newDays = selectedPermanentClassDays.filter((tag, index) => tag !== val)  
      this.setState({
        selectedPermanentClassDays: newDays        
      });
    }
  }
    
  closeCustomPopup = () => {
    this.setState({
      showCreatorWantInGroupPopup: false,
      showConfirmGroupPresentPopup: false
    })
  }

  handleAddUserInAudience = (e) => {
    this.setState({
      addCurrentUserInGroup: false,
      showCreatorWantInGroupPopup: false
    })
    window.setTimeout(()=>{
      this.checkIfGroupPresent(e)
    },200)
  }

  handleCloseCustomPopup = (e) => {
    this.setState({
      addCurrentUserInGroup: false,
      showCreatorWantInGroupPopup: false
    })
    // window.setTimeout(()=>{
    //   this.checkIfGroupPresent(e)
    // },200)
  }

  handleConfirmCreateGroup = (e) => {
    this.setState({showConfirmGroupPresentPopup: false})
    if(this.state.formType === 'edit'){
      this.handleProcessEditGroupChat(e)
    }else{
      this.handleProcessCreateGroup(e)
    }
  }

  handleCloseConfirmCreateGroup = (e) => {
    this.setState({showConfirmGroupPresentPopup: false})
  }

  selectHomeClubLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = this.state.homeClubSelectedLocations.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newlocs = [...this.state.homeClubSelectedLocations, val]
        this.setState(state => ({ homeClubSelectedLocations: newlocs }));
        this.checkHomeClubRegionVisibility(region, newlocs)
      }
    }else{
      if(__FOUND){
        const { homeClubSelectedLocations } = this.state;
        let newLocs = homeClubSelectedLocations.filter((tag, index) => tag !== val)  
        this.setState({
          homeClubSelectedLocations: newLocs        
        });
        this.checkHomeClubRegionVisibility(region, newLocs)  
      }      
    }
  }

  checkHomeClubRegionVisibility = (region, locations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(locations, location_ids)
    let val = region.region?.name

    let regions = this.state.homeClubSelectedRegions
    var __FOUND = regions.indexOf(val) > -1;
    if(equal){
      if(!__FOUND){
        this.setState(state => ({ homeClubSelectedRegions: [...state.homeClubSelectedRegions, val] }))
      }
    }else{
      if(__FOUND){
        
        const { homeClubSelectedRegions } = this.state;
        this.setState({
          homeClubSelectedRegions: homeClubSelectedRegions.filter((tag, index) => tag !== val)          
        });
      }
    }
		this.performGetAudience('', [], this.state.selectedSkills, true, locations)
  }

  selectHomeClubRegions = (e, region) => {
    let val = e.target.value
    let regions = this.state.homeClubSelectedRegions
   
    var __FOUND = regions.indexOf(val) > -1;
    var result = region.locations.map(function(a) {return a.id;});
    let selected_locations = this.state.homeClubSelectedLocations;
    
    if(e.target.checked){
      if(!__FOUND){
        this.setState(state => ({ homeClubSelectedRegions: [...state.homeClubSelectedRegions, val] }));
        let final_result = []
        for(let i = 0; i < result?.length; i++){
          if(!selected_locations.includes(result[i])){
            final_result.push(result[i])
          }
        }
        selected_locations = Array.prototype.push.apply(selected_locations, final_result)
      }
			this.performGetAudience('', [], this.state.selectedSkills, true, this.state.homeClubSelectedLocations)
    }else{
      if(__FOUND){
        
        const { homeClubSelectedRegions } = this.state;
        this.setState({
          homeClubSelectedRegions: homeClubSelectedRegions.filter((tag, index) => tag !== val)          
        });
        selected_locations = selected_locations.filter(item => !result.includes(item));
        this.setState({
          homeClubSelectedLocations: selected_locations
        })
				this.performGetAudience('', [], this.state.selectedSkills, true, selected_locations)
      }
    }
  }

  selectedAllHomeClubRegLoc = () => {
    let locationsIds = this.state.homeClubLocations.map(item => item.id)
    let check = locationsIds.every(v => this.state.homeClubSelectedLocations.includes(v))
    return check
  }

  selectedAllHomeClubRegionsLocations = (e) => {
    if(e.target.checked){
      let all = this.state.homeClubRegionsAndLocations
      let selected_regions = []
      let selected_locations = []
      for(var i=0; i<all.length;i++){     
        selected_regions.push(all[i]['region']['name'])
        for(var j=0; j<all[i]['locations'].length; j++){
          selected_locations.push(all[i]['locations'][j]['id'])
        }
      }
      this.setState({
        homeClubSelectedLocations: selected_locations,
        homeClubSelectedRegions: selected_regions
      })
			this.performGetAudience('', [] , this.state.selectedSkills, true, selected_locations)
    }else{
      this.setState({
        homeClubSelectedRegions: [],
        homeClubSelectedLocations: []
      })
			this.performGetAudience('', [], this.state.selectedSkills, true, [])
    }
  }

  homeClubLocationName = () => {
    let locations = this.state.homeClubLocations
    let selected_locations = this.state.homeClubSelectedLocations
    let locationName = []
    let ctr = 0
    for(let i = 0; i < locations?.length; i++){
      if(selected_locations.includes(locations[i].id)){
        if(ctr < 2){
          locationName.push(locations[i].name)
          ctr++
        }
        else{
          break;
        }
      }
    }
    return this.chekLocationNameCount(locationName.join(", "))
  }

  checkShowCrossButton = () => {
    if(this.state.selectedPostType !== 'group_chat'){
      if(this.state.selectedPostType === 'direct_message'){
        if(this.state.formType === 'edit'){
          if(this.state.selectedPeople?.length > 1){
            return true
          }else{
            return false
          }
        }else{
          return true
        }
      }else{
        return true
      }
    }else{
      return false
    }
  }

  handleEditDirectMessage = () => {
    this.setState({editButtonDisabled: true})
    const { selectedPeople, selectedPostType, groupId } = this.state
    let params = {
      receivers: selectedPeople,
      stream_id: groupId,
      is_private: true
    }
    editDirectMesage(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), params).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
          redirectAtGroupPage: `/messenger/chat-room/${result?.id}`,
          alert_message: result?.message,
          alert_type: 'success',
          show: true,
				})
        this.setState({editButtonDisabled: false},()=>{
          window.setTimeout(()=>{
            this.setState({editButtonDisabled: false, redirectAfterCreateGroup: true})
          },1500)
        });
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,
          editButtonDisabled: false
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

  checkDmUsersCanRemove = (userId) => {
    const { dmOldAudience } = this.state

    return (dmOldAudience?.length > 0 && dmOldAudience?.includes(userId))
  }

  hideTheAudienceCancelButton = (userId) => {
    const { selectedPostType, formType } = this.state
    
    return (
      (selectedPostType !== 'direct_message' && this.checkShowCrossButton()) || (selectedPostType === 'direct_message' && ((formType === 'add' && this.checkShowCrossButton()) || (formType !== 'add' && !this.checkDmUsersCanRemove(userId)))
      )
    )
  }

  render() {
    const { show, loaded, selectedPostType, postType, alert_type, alert_message, allowComment, setReminder, message, locations, subject, due_date, every, showDiscardModal, regions_and_locations, roles_array, skills, show_others, selectedRegions, selectedLocations, selectedSkills, users, all_users, selectedPeople, searchValue, acknowledgeRole, reminderForm, discard, selectedCCPeople, ccPeople, attachments, totalUsers, formType, postId, updated, showDeleteModal, deleted, messageExpired, eventSelectedLocation, eventStartDate, eventEndDate, help_range, start_hour_range, end_hour_range, eventStartTime, eventEndTime, locationType, showEndTime, locationFilterTooltipMessage, showSelectedPeopleModal, showPeoplelist, searchAudience, all_selectedPeople, draftedPost, draftButtonDisabled, sendButtonDisabled, editButtonDisabled, editPostConfModal, redirectAt ,redirectAfterSend, totalResult, dueDateMinValue, eventStartDateMinValue, showDragDropError, disableAttachBtn, archivePostUpdated, groupName, groupImage, groupDescription, createGroupBtnDisabled, rolesCanPost, groupChatRooms, groupNameAlreadyPresent, redirectAfterCreateGroup, redirectAtGroupPage, dmMessage, createDmBtnDisabled, searchDmValue, dmUsers, focused, groupImageFile, uploadedGroupImage, filterUsersLoaded, permanentClassDaysList, selectedPermanentClassDays, showCreatorWantInGroupPopup, addCurrentUserInGroup, showConfirmGroupPresentPopup, allowEmojis, homeClubRegionsAndLocations, homeClubShowOthers, homeClubSelectedLocations, homeClubSelectedRegions, homeClubLocations, homeClubTooltipMessage, disabledRoles, enabledRoles, higerSavedRoles, dmOldAudience } = this.state
    const { user, permissions, business, loadingMessage, setCurrent } = this.props
    
    return (
      <React.Fragment>
        {redirectAfterCreateGroup && 
          <Redirect to={redirectAtGroupPage} />
        }
        {redirectAfterSend && 
          <Redirect to={redirectAt === '' ? `/messenger` : `/messenger/show-post/${redirectAt}`} />
        }
        {showDragDropError &&
          <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
            <div className="text-sm text-red-700 font-medium flex items-center">
              Click on the <img className='w-4 h-4 mx-1.5' src='/assets/icon_image.svg' /> icon to upload your image
            </div>
            <a onClick={() => this.setState({showDragDropError: false})} className="ml-auto">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </a>
          </div> 
        }
        {deleted && 
          <Redirect to={this.throwAfterDeletePost()} />
        }
        {updated &&
					<Redirect to={this.handleBackUrl()} />
				}
        {archivePostUpdated &&
          <Redirect to={this.checkWhereNeedToRedirect()} />
        }
        {discard &&
					<Redirect to={`/messenger`} />
				}
				{show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
				{!loaded && 
					<div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
						<p className="mb-1 text-center px-5">{loadingMessage}</p>
						<img className = "w-9" src="/assets/business/loading.gif" />
					</div>
				}
				{loaded &&
					<div className="w-full min-h-screen bg-gray-50">
						<div className="pt-20 md:pt-8 h-full">
							<main className="h-full">
								<div className="max-w-8xl mx-auto px-6 lg:px-8 h-full pb-8">
                  {formType === 'add'
                    ?
                      <Link to={`/messenger`} className="bg-white text-xs py-2 px-2.5 inline-flex mb-4 rounded text-gray-700 border-solid border-2 border-gray-200">Back</Link>
                    :
                    draftedPost ?
                      <Link to={`/messenger`} className="bg-white text-xs py-2 px-2.5 inline-flex mb-4 rounded text-gray-700 border-solid border-2 border-gray-200">Back</Link>
                    :
                      <Link to={this.handleBackUrl()} className="bg-white text-xs py-2 px-2.5 inline-flex mb-4 rounded text-gray-700 border-solid border-2 border-gray-200">Back</Link>
                  }
                  <div>
                    <div className="bg-white rounded-md shadow px-6">
                      <div className="border-0">
                        <div className="text-lg flex items-center views-dd text-left border-0 w-full font-medium py-6 cursor-default">
                          {formType === 'add' ? 
                            (selectedPostType === '' 
                            ? 
                            "Create a post"
                            : (selectedPostType === 'compliance' && "Create a compliance post") ||
                            (selectedPostType === 'announcement' && "Create an announcement") ||
                            (selectedPostType === 'event' && "Create a new event post") || 
                            (selectedPostType === 'permanent_class_opening' && "Create a permanent class opening post") || 
                            (selectedPostType === 'group_chat' && "New Group") ||
                            (selectedPostType === 'direct_message' && "Create a conversation")
                            )
                          : 
                          draftedPost ?
                            (selectedPostType === '' 
                            ? 
                              "Create a post"
                            : (selectedPostType === 'compliance' && "Create a compliance post") ||
                            (selectedPostType === 'announcement' && "Create an announcement") ||
                            (selectedPostType === 'event' && "Create a new event post") || 
                            (selectedPostType === 'permanent_class_opening' && "Create a permanent class opening post") || (selectedPostType === 'group_chat' && "New Group")
                            )
                          :
                            ((selectedPostType !== 'group_chat' && selectedPostType !== 'direct_message') ?
                              'Edit Post'
                            :
                              (selectedPostType === 'group_chat' &&
                                'Edit Group') 
                                ||
                              (selectedPostType === 'direct_message' && "Edit conversation")
                            )
                          }
                        </div>
                        <div>
                          <div className="p-0">
                            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                              <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                Type*
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <div className="relative lg:w-80 md:w-80">
                                  <Menu as="div" className="relative block text-left">
                                    <div>
                                      <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-gray-100 text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-not-allowed">
                                        
                                        {selectedPostType === '' 
                                        ? 
                                        "Select a post type"
                                        : <p className = "text-gray-400">{this.checkTypeName(selectedPostType)}</p>
                                        }
                                        <ChevronDownIcon className="ml-auto text-gray-400 h-5 w-5" aria-hidden="true" />
                                      </Menu.Button>
                                    </div>
                                  </Menu>
                                </div>
                              </div>
                            </div>
                            {selectedPostType !== '' &&
                              <React.Fragment>
                                {selectedPostType === 'group_chat' &&
                                  <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                      Group name*
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                      <div className="relative filters_box w-full lg:w-80 md:w-80">
                                        <div className='flex items-center'>
                                          <input type='text' placeholder='Type something...' className={`h-10 flex justify-center w-full rounded border font-normal shadow-sm pl-4 pr-2 py-2 bg-white text-sm  ${groupNameAlreadyPresent ? 'text-red-900 border-red-500' : 'border-gray-300'} focus:outline-none`} name="groupName" value={groupName} onChange={(e) => this.handleChangeGroupName(e)}/>
                                          {/* {groupNameAlreadyPresent &&
                                            <svg width="17" height="16" className="absolute right-3" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path fillRule="evenodd" clipRule="evenodd" d="M16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8ZM9.5 12C9.5 12.5523 9.05229 13 8.5 13C7.94772 13 7.5 12.5523 7.5 12C7.5 11.4477 7.94772 11 8.5 11C9.05229 11 9.5 11.4477 9.5 12ZM8.5 3C7.94772 3 7.5 3.44772 7.5 4V8C7.5 8.55228 7.94772 9 8.5 9C9.05229 9 9.5 8.55228 9.5 8V4C9.5 3.44772 9.05229 3 8.5 3Z" fill="#EF4444"/>
                                            </svg>
                                          } */}

                                        </div>
                                      </div>
                                      {/* {groupNameAlreadyPresent &&
                                        <div className='text-red-500 mt-1 text-sm font-medium -mb-2'>
                                          That name is already taken by another group chat
                                        </div>
                                      } */}
                                    </div>
                                  </div>
                                }
                                <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                    {selectedPostType !== 'direct_message' ?
                                      <div className="flex items-center gap-1">
                                        Select audience* 
                                        <div className='text-gray-300'> 
                                          {selectedPeople?.length > 0 ? `(${selectedPeople?.length} people)` : ''}
                                        </div>
                                        {selectedPostType !== 'group_chat' && 
                                          <div data-tip data-for='locationFilterTooltip' className=''>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-500 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                                            </svg>
                                            <ReactTooltip className='max-w-xs' id="locationFilterTooltip" place="top" effect="solid">
                                              {locationFilterTooltipMessage}
                                            </ReactTooltip>
                                          </div>
                                        }
                                        {!filterUsersLoaded &&
                                          <div>
                                            <svg className="animate-spin h-4 w-4 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                          </div>
                                        }
                                      </div>
                                      :
                                      <div className="flex items-center gap-1">
                                        To*
                                      </div>
                                    }
                                  </label>
                                  <div className="mt-1 sm:mt-0 sm:col-span-2 md:col-span-3">
                                    <div className='flex flex-wrap gap-2 relative'>
                                      {selectedPostType !== 'direct_message' &&
                                        <React.Fragment>
                                          <div className="relative filters_box w-full md:w-80 lg:w-64">
                                            {((business?.home_club_feature) && selectedPostType === 'group_chat' && homeClubSelectedLocations?.length > 0) &&
                                              <Menu as="div" className="relative block text-left">
                                                <div>
                                                  <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-gray-100 text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-not-allowed">
                                                    Select location(s)
                                                    <ChevronDownIcon className="ml-auto text-gray-400 h-5 w-5" aria-hidden="true" />
                                                  </Menu.Button>
                                                </div>
                                              </Menu>
                                            }
                                            {(selectedPostType !== 'group_chat' || (selectedPostType === 'group_chat' && (((business?.home_club_feature) && homeClubSelectedLocations?.length == 0) || (!business?.home_club_feature)))) &&
                                              <Menu as="div" className="relative block text-left">
                                                <div>
                                                  <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 location_items_button">
                                                    {selectedLocations?.length === locations?.length && <p className = "text-gray-900">All Locations Selected</p>
                                                    }
                                                    {selectedLocations?.length < locations?.length && 
                                                      <React.Fragment>
                                                        {(selectedLocations?.length === 1 && selectedLocations?.length !== 0) && 
                                                          <p className = "text-gray-900">{this.locationName()}</p>
                                                        }
                                                        {(selectedLocations?.length > 1 && selectedLocations?.length < locations?.length) && 
                                                          <div className = "flex text-gray-900">{this.locationName()} {(selectedLocations?.length - this.locationName()?.split(',')?.length) > 0 && <p>+{selectedLocations?.length - this.locationName()?.split(',')?.length}</p>}</div>
                                                        }
                                                      </React.Fragment>
                                                    }
                                                    {(selectedLocations?.length === 0  && selectedLocations?.length !== locations?.length) && <p>Select location(s)</p>} 
                                                    <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                                                  </Menu.Button>
                                                </div>
                                                <Transition
                                                  as={Fragment}
                                                  show={true}
                                                  enter="transition ease-out duration-100"
                                                  enterFrom="transform opacity-0 scale-95"
                                                  enterTo="transform opacity-100 scale-100"
                                                  leave="transition ease-in duration-75"
                                                  leaveFrom="transform opacity-100 scale-100"
                                                  leaveTo="transform opacity-0 scale-95">
                                                  <Menu.Items className="origin-top-right z-20 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden location_items filter_items">
                                                    <div className="flex items-center p-2 border-b">
                                                      <div className=" mr-auto">
                                                        <label className="inline-flex items-center text-gray-400 text-sm mb-2 gap-1">
                                                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300" checked = {this.selectedAllRegLoc()} onChange = {(e) => this.selectedAllRegionsLocations(e)}/>
                                                          Select all/Clear all
                                                          </label>
                                                      </div>
                                                    </div>
                                                    <div className="p-2 max-h-60 overflow-y-auto">
                                                      {regions_and_locations.map((region, i) =>
                                                        <div className="py-1" key = {i}>
                                                          {show_others &&
                                                            <Menu.Item>
                                                              {({ active }) => (
                                                                <label className="flex items-center text-gray-900 text-sm mb-2">
                                                                  <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => this.selectRegions(e, region)} value={region?.region?.name} checked={selectedRegions.includes(region.region.name)}/>
                                                                  {region.region.name}
                                                                </label>
                                                                  
                                                              )}
                                                            </Menu.Item>
                                                          }
                                                          <div className="ml-5">
                                                            {region.locations.map((location, j) =>
                                                            <Menu.Item key = {j}>
                                                              {({ active }) => (
                                                                <label className="flex items-center text-gray-900 text-sm mb-2">
                                                                  <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={selectedLocations.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)}/>
                                                                  {location.name}
                                                                </label>
                                                                  
                                                              )}
                                                            </Menu.Item>
                                                            )}
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </Menu.Items>
                                                </Transition>
                                              </Menu>
                                            }
                                          </div>
                                          {((business?.home_club_feature) && selectedPostType === 'group_chat') &&
                                            <React.Fragment>
                                              <div className='text-gray-300 flex items-center md:w-80 lg:w-5'>Or</div>
                                              <div data-tip data-for='homeClubMessageTooltip' className='mt-3'>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-500 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                                                </svg>
                                                <ReactTooltip className='max-w-xs' id="homeClubMessageTooltip" place="top" effect="solid">
                                                  {homeClubTooltipMessage}
                                                </ReactTooltip>
                                              </div>
                                              <div className="relative filters_box w-full md:w-80 lg:w-64">
                                                {selectedLocations?.length > 0 &&
                                                  <Menu as="div" className="relative block text-left">
                                                    <div>
                                                      <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-gray-100 text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-not-allowed">
                                                        Select home club(s)
                                                        <ChevronDownIcon className="ml-auto text-gray-400 h-5 w-5" aria-hidden="true" />
                                                      </Menu.Button>
                                                    </div>
                                                  </Menu>
                                                }
                                                {selectedLocations?.length == 0 &&
                                                  <Menu as="div" className="relative block text-left">
                                                    <div>
                                                      <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 instructor_items_button">
                                                        {homeClubSelectedLocations?.length === homeClubLocations?.length && <p className = "text-gray-900">All Home Clubs Selected</p>
                                                        }
                                                        {homeClubSelectedLocations?.length < homeClubLocations?.length && 
                                                          <React.Fragment>
                                                            {(homeClubSelectedLocations?.length === 1 && homeClubSelectedLocations?.length !== 0) && 
                                                              <p className = "text-gray-900">{this.homeClubLocationName()}</p>
                                                            }
                                                            {(homeClubSelectedLocations?.length > 1 && homeClubSelectedLocations?.length < homeClubLocations?.length) && 
                                                              <div className = "flex text-gray-900">{this.homeClubLocationName()} {(homeClubSelectedLocations?.length - this.homeClubLocationName()?.split(',')?.length) > 0 && <p>+{homeClubSelectedLocations?.length - this.homeClubLocationName()?.split(',')?.length}</p>}</div>
                                                            }
                                                          </React.Fragment>
                                                        }
                                                        {(homeClubSelectedLocations?.length === 0  && homeClubSelectedLocations?.length !== homeClubLocations?.length) && <p>Select home club(s)</p>} 
                                                        <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                                                      </Menu.Button>
                                                    </div>
                                                    <Transition
                                                      as={Fragment}
                                                      show={true}
                                                      enter="transition ease-out duration-100"
                                                      enterFrom="transform opacity-0 scale-95"
                                                      enterTo="transform opacity-100 scale-100"
                                                      leave="transition ease-in duration-75"
                                                      leaveFrom="transform opacity-100 scale-100"
                                                      leaveTo="transform opacity-0 scale-95">
                                                      <Menu.Items className="origin-top-right z-20 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden instructor_items filter_items">
                                                        <div className="flex items-center p-2 border-b">
                                                          <div className=" mr-auto">
                                                            <label className="inline-flex items-center text-gray-400 text-sm mb-2 gap-1">
                                                              <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300" checked = {this.selectedAllHomeClubRegLoc()} onChange = {(e) => this.selectedAllHomeClubRegionsLocations(e)}/>
                                                              Select all/Clear all
                                                              </label>
                                                          </div>
                                                        </div>
                                                        <div className="p-2 max-h-60 overflow-y-auto">
                                                          {homeClubRegionsAndLocations.map((region, i) =>
                                                            <div className="py-1" key = {i}>
                                                              {homeClubShowOthers &&
                                                                <Menu.Item>
                                                                  {({ active }) => (
                                                                    <label className="flex items-center text-gray-900 text-sm mb-2">
                                                                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => this.selectHomeClubRegions(e, region)} value={region?.region?.name} checked={homeClubSelectedRegions.includes(region.region.name)}/>
                                                                      {region.region.name}
                                                                    </label>
                                                                      
                                                                  )}
                                                                </Menu.Item>
                                                              }
                                                              <div className="ml-5">
                                                                {region.locations.map((location, j) =>
                                                                <Menu.Item key = {j}>
                                                                  {({ active }) => (
                                                                    <label className="flex items-center text-gray-900 text-sm mb-2">
                                                                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={homeClubSelectedLocations.includes(location?.id)} value={location.id} onChange={(e) => this.selectHomeClubLocation(e, region)}/>
                                                                      {location.name}
                                                                    </label>
                                                                      
                                                                  )}
                                                                </Menu.Item>
                                                                )}
                                                              </div>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </Menu.Items>
                                                    </Transition>
                                                  </Menu>
                                                }
                                              </div>
                                            </React.Fragment>
                                          }
                                          <div className='text-gray-300 flex items-center md:w-80 lg:w-7'>And</div>
                                          <div className="relative filters_box w-full md:w-80 lg:w-64">
                                            <Menu as="div" className="relative block text-left">
                                              <div>
                                                <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 days_items_button">
                                                  {selectedSkills?.length === skills?.length && <p className = "text-gray-900">All Skills Selected</p>
                                                  }
                                                  {selectedSkills?.length < skills?.length && 
                                                    <React.Fragment>
                                                      {(selectedSkills?.length <= 2 && selectedSkills?.length !== 0) && 
                                                        <p className = "text-gray-900">{this.checkSelectedSkillsName()}</p>
                                                      }
                                                      {(selectedSkills?.length > 2 && selectedSkills?.length < skills?.length) && 
                                                        <p className = "text-gray-900">{this.checkSelectedSkillsName()} +{selectedSkills?.length-2}</p>
                                                      }
                                                    </React.Fragment>
                                                  }
                                                  {(selectedSkills?.length === 0  && selectedSkills?.length !== skills?.length) && <p>Select skill(s)</p>}
                                                  <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                                                </Menu.Button>
                                              </div>

                                              <Transition
                                                as={Fragment}
                                                show={true}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95">
                                                <Menu.Items className="origin-top-right z-20 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden days_items filter_items">
                                                <div className="flex items-center p-2 border-b">
                                                  <div className="mr-auto">
                                                    <label className="inline-flex items-center text-gray-400 text-sm mb-2 gap-1">
                                                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300" checked = {this.selectAllSkills()} onChange = {(e) => this.handleSelectAllSkills(e)}/>
                                                      Select all/Clear all
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="p-2 max-h-60 overflow-y-auto">
                                                  <div className="py-1">
                                                    <div>
                                                      {skills.map((skill, i) => 
                                                        <Menu.Item key = {i}>
                                                          {({ active }) => (
                                                            <label className="flex items-center text-gray-900 text-sm mb-2">
                                                              <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked = {selectedSkills.includes(skill?.id)} value={skill.id} onChange = {(e) => this.handleSelectSkills(e)}/>
                                                                {skill.name}
                                                            </label>
                                                              
                                                          )}
                                                        </Menu.Item>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                                </Menu.Items>
                                              </Transition>
                                            </Menu>
                                          </div>
                                        </React.Fragment>
                                      }
                                      {(selectedPostType !== 'group_chat' && selectedPostType !== 'direct_message') &&
                                        <React.Fragment>
                                          <div className='text-gray-300 flex items-center md:w-80 lg:w-5'>Or</div>
                                          <div className="hidden relative filters_box w-full md:w-80 lg:w-64">
                                            <Menu as="div" className="relative block text-left">
                                              <div>
                                                {all_users?.length === 0 ?
                                                  <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-gray-100 cursor-not-allowed text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                    <p className = "text-gray-900">No User Found</p>
                                                    <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                                                  </Menu.Button>
                                                  :
                                                  <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-none start_time_button">
                                                    Search people
                                                    <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                                                  </Menu.Button>
                                                }
                                              </div>

                                              <Transition
                                                as={Fragment}
                                                show={true}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95">
                                                <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none start_time_items hidden filter_items">
                                                  <div className="flex items-center p-2 border-b">
                                                    <div className="relative w-36">
                                                      <div className="bg-gray-50 rounded-lg h-8">
                                                        <input type="text" className="bg-gray-50 pl-2 pr-8 py-1.5 w-full rounded-lg focus:outline-none text-sm text-gray-900 placeholder-gray-500"  placeholder="Search" value = {searchValue} onChange = {(e) => this.searchUsers(e)}/>
                                                        <button className="absolute top-1.5 right-1.5">
                                                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                                          </svg>
                                                        </button>
                                                      </div>
                                                    </div>
                                                    {users?.length > 0 &&
                                                      <div className="ml-auto">
                                                        <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                                          Select All
                                                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.selectAllUsers()} onChange = {(e) => this.handleSelectAllUsers(e)}/>
                                                        </label>
                                                      </div>
                                                    }
                                                  </div>
                                                  <div className="p-2 max-h-60 overflow-y-auto">
                                                    <div className="py-1">
                                                      <div>
                                                        {users.map((user, i) => 
                                                          <Menu.Item key = {i}>
                                                            {({ active }) => (
                                                              <label className="flex items-center text-gray-900 text-sm mb-2">
                                                                <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" name="selectedPeople" checked = {selectedPeople.includes(user?.id)} value={user?.id} onChange = {(e) => this.selectAudience(e)}/>
                                                                {user?.image !== '' ?
                                                                  <div className="relative w-6 h-6">
                                                                    <img src={`${user?.image}`} className='rounded-full' />
                                                                  </div>
                                                                  :
                                                                  <div className='w-6 h-6 rounded-full bg-gray-500 flex items-center justify-center relative'>
                                                                    <div className='text-white text-xs'>{user?.name_image}</div>
                                                                  </div>
                                                                }
                                                                  <div className='pl-1'>{user?.full_name}</div>
                                                              </label>
                                                                
                                                            )}
                                                          </Menu.Item>
                                                        )}
                                                        {users?.length === 0 &&
                                                          <div className='text-gray-400 text-center'>No user found</div>
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Menu.Items>
                                              </Transition>
                                            </Menu>
                                          </div>
                                          <div className="relative w-full md:w-80 lg:w-64">
                                            <div id='showPeopleListInput' className=''>
                                              <input type='text' placeholder='Search people' className='h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-8 py-2 bg-white text-sm focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500' name="searchValue" value={searchValue} onChange={(e) => this.searchUsers(e)} onBlur={(e) => this.handleOnBlur(e)} onClick={(e) => this.handleOnCLick(e)}/>
                                              <button className="absolute top-2.5 right-2.5">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                  <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                                </svg>
                                              </button>
                                            </div>
                                            {/* {(users?.length > 0 && searchValue !== '' && showPeoplelist) && */}
                                            {(users?.length > 0 && showPeoplelist) &&
                                              <div id='targetShowpeopleList1' className="relative block text-left">
                                                <div className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none py-2">
                                                  <div className="flex items-center p-2 border-b">
                                                    {users?.length > 0 &&
                                                      <div className="ml-auto">
                                                        <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                                          Select All
                                                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.selectAllUsers()} onChange = {(e) => this.handleSelectAllUsers(e)}/>
                                                        </label>
                                                      </div>
                                                    }
                                                  </div>
                                                  <div className="py-2 px-3 max-h-60 overflow-y-auto">
                                                    <div>
                                                      {users.map((user, i) => 
                                                        <div key = {i}>
                                                          <label className="flex items-center text-gray-900 text-sm mb-2">
                                                            <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" name="selectedPeople" checked = {selectedPeople.includes(user?.id)} value={user?.id} onChange = {(e) => this.selectAudience(e)}/>
                                                            {user?.image !== '' ?
                                                              <div className="relative w-6 h-6">
                                                                <img src={`${user?.image}`} className='rounded-full' />
                                                              </div>
                                                              :
                                                              <div className='w-6 h-6 rounded-full bg-gray-500 flex items-center justify-center relative'>
                                                                <div className='text-white text-xs'>{user?.name_image}</div>
                                                              </div>
                                                            }
                                                              <div className='pl-1'>{user?.full_name}</div>
                                                          </label>
                                                        </div>
                                                      )}
                                                      {users?.length === 0 &&
                                                        <div className='text-gray-400 text-center'>No user found</div>
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            }
                                          </div>
                                        </React.Fragment>
                                      }
                                      {selectedPostType === 'direct_message' &&
                                        <div className="relative w-full md:w-80 lg:w-80">
                                          <div id='showPeopleListInput' className=''>
                                            <button className="absolute top-2.5 left-2.5">
                                              <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                              </svg>
                                            </button>
                                            <input type='text' placeholder='Search people' className='h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pr-4 pl-8 py-2 bg-white text-sm focus:outline-none' name="searchDmValue" value={searchDmValue} onChange={(e) => this.searchDmUsers(e)} onBlur={(e) => this.handleOnBlurDmSearch(e)} onClick={(e) => this.handleOnCLickDmSearch(e)} onKeyDown={(e) => this.handleKeyDownDmSearch(e)}/>
                                          </div>
                                          {(dmUsers?.length > 0 && showPeoplelist) &&
                                            <div id='targetShowpeopleList1' className="relative block text-left">
                                              <div className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none py-2">
                                                <div className="py-2 max-h-60 overflow-y-auto suggesion_box_dm_search">
                                                  <div>
                                                    {dmUsers.map((user, i) => 
                                                      <div key = {i}>
                                                        <label className={`flex items-center text-gray-900 text-sm pb-1 pt-1 px-3 ${focused !== undefined && focused -1 === i ? 'bg-gray-200 active-suggesion-item' : ''} ${this.checkDmUsersCanRemove(user?.id) ? 'opacity-75' : ''}`}>
                                                          <input type="checkbox" className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1 ${this.checkDmUsersCanRemove(user?.id) ? 'cursor-not-allowed opacity-75' : ''}`} name="selectedPeople" checked = {selectedPeople.includes(user?.id)} value={user?.id} onChange = {(e) => {this.selectAudience(e); this.setState({
                                                            // dmUsers: [], 
                                                            // searchDmValue: '', 
                                                            focused: undefined});}} disabled={this.checkDmUsersCanRemove(user?.id)}/>
                                                          {user?.image !== '' ?
                                                            <div className="relative w-6 h-6">
                                                              <img src={`${user?.image}`} className='rounded-full' />
                                                            </div>
                                                            :
                                                            <div className='w-6 h-6 rounded-full bg-gray-500 flex items-center justify-center relative'>
                                                              <div className='text-white text-xs'>{user?.name_image}</div>
                                                            </div>
                                                          }
                                                            <div className='pl-1'>{user?.full_name}</div>
                                                        </label>
                                                      </div>
                                                    )}
                                                    {dmUsers?.length === 0 &&
                                                      <div className='text-gray-400 text-center px-3'>No user found</div>
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      }
                                    </div>
                                    {(selectedPeople?.length == 0 && (selectedLocations?.length > 0 || selectedSkills?.length > 0 || homeClubSelectedLocations?.length > 0)) &&
                                      <div className='mt-2 -mb-2 ml-2'>
                                        <span className="error text-xs">*No instructors match the criteria</span>
                                      </div>
                                    }
                                    {selectedPeople?.length > 0 &&
                                      <div className='flex items-center mt-2 gap-2 flex-wrap'>
                                        {selectedPeople.map((user, i) => 
                                          (i < 10 &&
                                            <div className='bg-purple-100 text-purple-800 py-0.5 px-2.5 flex items-center rounded-xl' key={i}>
                                              <div className='text-sm pr-1 font-medium'>{this.peopleName(user)}</div>
                                              {(this.hideTheAudienceCancelButton(user)) &&
                                                <a onClick={() => this.removeSelectedPeople(user)}>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-violet-800  h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                  </svg>
                                                </a>
                                              }
                                            </div>
                                        )
                                        )}
                                      </div>
                                    }
                                    {selectedPeople?.length > 10 &&
                                      <div className="relative inline-block text-left mt-4">
                                        <button type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-1.5 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true" onClick={() => this.toggleShowAllAudience()}>
                                          <svg className={`${showSelectedPeopleModal ? 'rotated' : ''} mr-2.5 h-5 w-5`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                          </svg>
                                          {`${showSelectedPeopleModal ? 'Hide' : 'View'} ${selectedPeople?.length -10} more`}
                                        </button>
                                      </div>
                                    }
                                  </div>
                                </div>
                                {selectedPostType === 'group_chat' &&
                                  <React.Fragment>
                                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                        Who can post in the group?*
                                      </label>
                                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div className="relative filters_box w-full md:w-80 lg:w-84">
                                          <Menu as="div" className="relative block text-left">
                                            <div>
                                              <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 end_time_button">
                                                {rolesCanPost?.length === 0 ? 'Select role(s)' : <p className='text-gray-800'>{this.rolesName(rolesCanPost)}</p>}
                                                <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                                              </Menu.Button>
                                            </div>

                                            <Transition
                                              as={Fragment}
                                              show={true}
                                              enter="transition ease-out duration-100"
                                              enterFrom="transform opacity-0 scale-95"
                                              enterTo="transform opacity-100 scale-100"
                                              leave="transition ease-in duration-75"
                                              leaveFrom="transform opacity-100 scale-100"
                                              leaveTo="transform opacity-0 scale-95">
                                              <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none end_time_items hidden filter_items">
                                                <div className="flex items-center p-2 border-b">
                                                  <div className="mr-auto">
                                                    <label className="flex items-center text-gray-400 text-sm gap-1">
                                                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300" checked = {this.checkRolesCanPost()} onChange = {(e) => this.handleSelectAllRolesCanPost(e)}/>
                                                      Select all/Clear all
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="p-2 max-h-60 overflow-y-auto">
                                                  <div className="py-1">
                                                    <div>
                                                      {roles_array.map((type, i) => 
                                                        <Menu.Item key = {i}>
                                                          {({ active }) => (
                                                            <label className={`flex items-center text-gray-900 text-sm mb-2 ${disabledRoles.includes(type) ? 'opacity-50 cursor-not-allowed' : ''}`}>
                                                              <input type="checkbox" className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1 ${disabledRoles.includes(type) ? 'cursor-not-allowed' : ''}`} name="rolesCanPost" checked = {rolesCanPost.includes(type)} value={type} onChange = {(e) => this.handleRolesCanPost(e)} disabled = {disabledRoles.includes(type)}/>
                                                                {type}
                                                            </label>
                                                              
                                                          )}
                                                        </Menu.Item>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </Menu.Items>
                                            </Transition>
                                          </Menu>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                        Group image
                                      </label>
                                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div className="flex items-center gap-5">
                                          <div className='w-14 h-14 flex items-center justify-center'>
                                            {groupImageFile === '' && uploadedGroupImage === '' ?
                                              <svg width="51" height="49" viewBox="0 0 51 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M0.300781 6.99961C0.300781 3.46499 3.12139 0.599609 6.60078 0.599609H28.6508C32.1302 0.599609 34.9508 3.46499 34.9508 6.99961V19.7996C34.9508 23.3342 32.1302 26.1996 28.6508 26.1996H22.3508L12.9008 35.7996V26.1996H6.60078C3.12139 26.1996 0.300781 23.3342 0.300781 19.7996V6.99961Z" fill="#111827"/>
                                              <path d="M41.2508 13.3996V28.5996C41.2508 30.8088 39.4599 32.5996 37.2508 32.5996H24.9603L19.3952 38.2531C20.2766 38.7296 21.2827 38.9996 22.3508 38.9996H28.6508L38.1008 48.5996V38.9996H44.4008C47.8802 38.9996 50.7008 36.1342 50.7008 32.5996V19.7996C50.7008 16.265 47.8802 13.3996 44.4008 13.3996H41.2508Z" fill="#111827"/>
                                              </svg>
                                              :
                                                <img className='max-w-full rounded-full' style={{width: '50px', height: '50px', flexBasis: `${50}px`, objectFit: 'cover',}} src={groupImageFile !== '' ? URL.createObjectURL(groupImageFile) : uploadedGroupImage}/>
                                            }
                                          </div>

                                          <div className={`border rounded-md border-gray-300 shadow-sm text-gray-700 text-sm py-2 px-4 relative h-9 leading-4 items-center justify-center w-48 flex gap-1 hover:bg-gray-100`}>
                                            {`${formType === 'add' ? 'Add' : 'Change'} group image`}
                                            <input type="file" className={`absolute w-full top-0 left-0 bottom-0 opacity-0`} accept=".jpg, .jpeg, .png" onChange={(e) => this.handleUploadGroupImage(e)}></input>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                        Group description
                                      </label>
                                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div>
                                          <textarea ref={this.callRef} rows="5" className='block w-full max-w-lg rounded-md px-4 py-2 border border-gray-300 shadow-sm resize-none focus:outline-none sm:text-sm overflow-y-hidden' placeholder='What is this group about?' name='groupDescription' value={groupDescription} onChange={(e) => this.handleChange(e)}></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                }
                                {(selectedPostType === 'direct_message' && formType === 'add') &&
                                  <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                      Your message*
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-3">
                                      <div>
                                        <textarea rows="10" className='block w-full rounded-md px-4 py-2 border border-gray-300 shadow-sm resize-none focus:outline-none  sm:text-sm overflow-y-hidden' placeholder='Type your text' name='dmMessage' value={dmMessage} onChange={(e) => this.handleChange(e)}></textarea>
                                      </div>
                                    </div>
                                  </div>
                                }
                                {selectedPostType === 'compliance' &&
                                  <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                      Who should acknowledge*
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                      <div className="relative filters_box w-full md:w-80 lg:w-84">
                                        <Menu as="div" className="relative block text-left">
                                          <div>
                                            <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 end_time_button">
                                              {acknowledgeRole?.length === 0 ? 'Select role(s)' : <p className='text-gray-800'>{this.rolesName(acknowledgeRole)}</p>}
                                              <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                                            </Menu.Button>
                                          </div>

                                          <Transition
                                            as={Fragment}
                                            show={true}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95">
                                            <Menu.Items className="origin-top-right z-20 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none end_time_items hidden filter_items">
                                              <div className="flex items-center p-2 border-b">
                                                <div className="mr-auto">
                                                  <label className="inline-flex items-center text-gray-400 text-sm mb-2 gap-1">
                                                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300" checked = {this.checkSelectedRolesForAcnowledge()} onChange = {(e) => this.handleSelectAllWhoAcknowledge(e)}/>
                                                    Select all/Clear all
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="p-2 max-h-60 overflow-y-auto">
                                                <div className="py-1">
                                                  <div>
                                                    {roles_array.map((type, i) => 
                                                      <Menu.Item key = {i}>
                                                        {({ active }) => (
                                                          <label className="flex items-center text-gray-900 text-sm mb-2">
                                                            <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" name="acknowledgeRole" checked = {acknowledgeRole.includes(type)} value={type} onChange = {(e) => this.handleWhoAcknowledge(e)}/>
                                                              {type}
                                                          </label>
                                                            
                                                        )}
                                                      </Menu.Item>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </Menu.Items>
                                          </Transition>
                                        </Menu>
                                      </div>
                                    </div>
                                  </div>
                                }
                                {selectedPostType === 'event' ?
                                  <React.Fragment>
                                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                        Event name*
                                      </label>
                                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div className="relative filters_box w-full lg:w-80 md:w-80">
                                          <div className=''>
                                            <input type='text' placeholder='Type something...' className='h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500' name="subject" value={subject} onChange={(e) => this.handleChange(e)}/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                        Location*
                                      </label>
                                      <div className="mt-1 sm:mt-0 sm:col-span-2 md:col-span-3">
                                        <div className='flex flex-wrap gap-2'>
                                          <div className="relative w-full md:w-80 lg:w-80">
                                            <Menu as="div" className="relative block text-left">
                                              <div>
                                                <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                  {locationType === '' ?
                                                    <p>Location type</p>
                                                  :
                                                  (locationType === 'virtual' ?
                                                    <p className = "text-gray-900">Virtual
                                                    </p>
                                                    : locationType === 'studio_list' ?
                                                      <p className = "text-gray-900">
                                                        Select from studio list
                                                      </p>
                                                    : locationType === 'custom_address' &&
                                                      <p className = "text-gray-900">
                                                        Custom address
                                                      </p>
                                                  )
                                                  }
                                                  <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                                                </Menu.Button>
                                              </div>

                                              <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95">
                                                <Menu.Items className="origin-top-right z-20 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                  <div className="p-2 max-h-60 overflow-y-auto">
                                                    <div className="py-1">
                                                      <Menu.Item>
                                                        {({ active }) => (
                                                          <a className='flex justify-between cursor-pointer' onClick={(e) => this.handleLocationType(e, 'virtual')}>
                                                            <label className="flex items-center text-gray-900 text-sm mb-2">
                                                              Virtual <p className='text-gray-400 ml-2'>(add link)</p>
                                                            </label>
                                                            {locationType === 'virtual' &&
                                                              <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                                              </svg>
                                                            }
                                                          </a>
                                                        )}
                                                      </Menu.Item>
                                                      <Menu.Item>
                                                        {({ active }) => (
                                                          <a className='flex justify-between cursor-pointer' onClick={(e) => this.handleLocationType(e, 'studio_list')}>
                                                            <label className="flex items-center text-gray-900 text-sm mb-2">
                                                              Select from location list
                                                            </label>
                                                            {locationType === 'studio_list' &&
                                                              <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                                              </svg>
                                                            }
                                                          </a>
                                                        )}
                                                      </Menu.Item>
                                                      <Menu.Item>
                                                        {({ active }) => (
                                                          <a className='flex justify-between cursor-pointer' onClick={(e) => this.handleLocationType(e, 'custom_address')}>
                                                            <label className="flex items-center text-gray-900 text-sm mb-2">
                                                              Custom address
                                                            </label>
                                                            {locationType === 'custom_address' &&
                                                              <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                                              </svg>
                                                            }
                                                          </a>
                                                        )}
                                                      </Menu.Item>
                                                    </div>
                                                  </div>
                                                </Menu.Items>
                                              </Transition>
                                            </Menu>
                                          </div>
                                          {locationType === 'studio_list' &&
                                            <div className="relative w-full md:w-80 lg:w-80">
                                              <Menu as="div" className="relative block text-left">
                                                <div>
                                                  <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                    {eventSelectedLocation === '' ?
                                                      <p>Select location</p>
                                                    :
                                                      <p className = "text-gray-900">{this.showEventLocationName(eventSelectedLocation)}</p>
                                                    }
                                                    <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                                                  </Menu.Button>
                                                </div>

                                                <Transition
                                                  as={Fragment}
                                                  enter="transition ease-out duration-100"
                                                  enterFrom="transform opacity-0 scale-95"
                                                  enterTo="transform opacity-100 scale-100"
                                                  leave="transition ease-in duration-75"
                                                  leaveFrom="transform opacity-100 scale-100"
                                                  leaveTo="transform opacity-0 scale-95">
                                                  <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="p-2 max-h-60 overflow-y-auto">
                                                      {regions_and_locations.map((region, i) =>
                                                        <div className="py-1" key = {i}>
                                                          {show_others &&
                                                            <Menu.Item>
                                                              {({ active }) => (
                                                                <label className="flex items-center text-gray-900 text-sm mb-2">
                                                                  {region.region.name}
                                                                </label>
                                                                  
                                                              )}
                                                            </Menu.Item>
                                                          }
                                                          <div className="ml-5">
                                                            {region.locations.map((location, j) =>
                                                            <Menu.Item key = {j}>
                                                              {({ active }) => (
                                                                <label className="flex items-center text-gray-900 text-sm mb-2">
                                                                  <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={eventSelectedLocation === location?.id} value={location.id} onChange={(e) => this.handleEventLocation(e)}/>
                                                                  {location.name}
                                                                </label>
                                                                  
                                                              )}
                                                            </Menu.Item>
                                                            )}
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </Menu.Items>
                                                </Transition>
                                              </Menu>
                                            </div>
                                          }
                                          {locationType === 'virtual' &&
                                            <div className="relative w-full md:w-80 lg:w-80">
                                              <div className='relative'>
                                                <input type='text' placeholder='Paste link' className='h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-9 py-2 bg-white text-sm focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500' name="eventSelectedLocation" value={eventSelectedLocation} onChange={(e) => this.handleChange(e)}/>
                                                <div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clipRule="evenodd" />
                                                  </svg>
                                                </div>
                                              </div>
                                            </div>
                                          }
                                          {locationType === 'custom_address' &&
                                            <div className="relative w-full md:w-80 lg:w-80">
                                              <div className=''>
                                                <input type='text' placeholder='Type address' className='h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500' name="eventSelectedLocation" value={eventSelectedLocation} onChange={(e) => this.handleChange(e)}/>
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                      Date & Time*
                                      </label>
                                      <div className="mt-1 sm:mt-0 sm:col-span-2  md:col-span-3">
                                        <div className='flex flex-wrap gap-2'>
                                          <div className='relative w-full md:w-80 lg:w-80 messenger-box'>
                                            {/* <DatePicker
                                              value={eventStartDate}
                                              className="h-10 w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                                              autoFocus={false}
                                              minDate={formType === 'add' ? new Date() : (messageExpired ? eventStartDateMinValue : new Date() )}
                                              format="M/d/yyyy"
                                              onChange={(e) => this.handleEventDateChange(e, 'eventStartDate')} 
                                              calendarType="US"
                                              clearIcon={null}                    
                                            /> */}
                                            <DatePicker
                                              selected={eventStartDate}
                                              className="h-10 w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-pointer relative z-10 bg-transparent"
                                              autoFocus={false}
                                              minDate={new Date()}
                                              // isClearable
                                              dateFormat="M/d/yyyy"
                                              onChange={(e) => this.handleEventDateChange(e, 'eventStartDate')} 
                                              calendarType="US"
                                            />
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 absolute top-2.5 right-2.5">
                                              <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                                            </svg>
                                          </div>
                                          <div className="relative w-full md:w-80 lg:w-48">
                                            <Menu as="div" className="relative block text-left">
                                              <div>
                                                <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                  {eventStartTime === '' ? 
                                                    <p>Select start time</p>
                                                  :
                                                    <p className='text-gray-900'>{eventStartTime}</p>
                                                  } 
                                                  <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                                                </Menu.Button>
                                              </div>

                                              <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95">
                                                <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                  <div className="p-2 max-h-60 overflow-y-auto">
                                                    {start_hour_range.map((time, i) =>
                                                      <div className="py-1" key = {i}>
                                                        <Menu.Item>
                                                          {({ active }) => (
                                                            <label className="flex items-center text-gray-900 text-sm mb-2">
                                                              <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" name='eventStartTime' checked={eventStartTime === time} value={time} onChange={(e) => this.handleEventTime(e)}/>
                                                              {time}
                                                            </label>
                                                              
                                                          )}
                                                        </Menu.Item>
                                                      </div>
                                                    )}
                                                  </div>
                                                </Menu.Items>
                                              </Transition>
                                            </Menu>
                                          </div>
                                          {!showEndTime &&
                                            <a className='text-gray-300 flex cursor-pointer items-center' onClick={() => this.handleShowEndTime(true)}>+ End Time</a>
                                          }
                                        </div>
                                        {showEndTime &&
                                          <div className='flex flex-wrap gap-2 mt-4'>
                                            <div className='relative w-full md:w-80 lg:w-80 messenger-box'>
                                              {/* <DatePicker
                                                value={eventEndDate}
                                                className="h-10 w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                                                autoFocus={false}
                                                minDate={new Date(Date.parse(eventStartDate))}
                                                format="M/d/yyyy"
                                                onChange={(e) => this.handleEventDateChange(e, 'eventEndDate')} 
                                                calendarType="US"
                                                clearIcon={null}                    
                                              /> */}
                                              <DatePicker
                                                selected={eventEndDate}
                                                className="h-10 w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-pointer relative z-10 bg-transparent"
                                                autoFocus={false}
                                                minDate={new Date(Date.parse(eventStartDate))}
                                                dateFormat="M/d/yyyy"
                                                onChange={(e) => this.handleEventDateChange(e, 'eventEndDate')} 
                                                calendarType="US"
                                              />
                                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 absolute top-2.5 right-2.5">
                                              <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                                              </svg>
                                            </div>
                                            <div className="relative w-full md:w-80 lg:w-48">
                                              <Menu as="div" className="relative block text-left">
                                                <div>
                                                  <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                    {eventEndTime === '' ? 
                                                      <p>Select end time</p>
                                                    :
                                                      <p className='text-gray-900'>{eventEndTime}</p>
                                                    } 
                                                    <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                                                  </Menu.Button>
                                                </div>

                                                <Transition
                                                  as={Fragment}
                                                  enter="transition ease-out duration-100"
                                                  enterFrom="transform opacity-0 scale-95"
                                                  enterTo="transform opacity-100 scale-100"
                                                  leave="transition ease-in duration-75"
                                                  leaveFrom="transform opacity-100 scale-100"
                                                  leaveTo="transform opacity-0 scale-95">
                                                  <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="p-2 max-h-60 overflow-y-auto">
                                                      {end_hour_range.map((time, i) =>
                                                        <div className="py-1" key = {i}>
                                                          <Menu.Item>
                                                            {({ active }) => (
                                                              <label className="flex items-center text-gray-900 text-sm mb-2">
                                                                <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" name='eventEndTime' checked={eventEndTime === time} value={time} onChange={(e) => this.handleEventTime(e)}/>
                                                                {time}
                                                              </label>
                                                                
                                                            )}
                                                          </Menu.Item>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </Menu.Items>
                                                </Transition>
                                              </Menu>
                                            </div>
                                            <a className='text-gray-300 flex cursor-pointer items-center' onClick={() => this.handleShowEndTime(false)}>- Remove</a>
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  </React.Fragment>
                                :
                                  ((selectedPostType !== 'group_chat' && selectedPostType !== 'direct_message') &&
                                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                        {selectedPostType == 'permanent_class_opening' ? "Class Name*" : "Subject*"}
                                      </label>
                                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div className="relative filters_box w-full lg:w-80 md:w-80">
                                          <div className=''>
                                            <input type='text' placeholder='Type something...' className='h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500' name="subject" value={subject} onChange={(e) => this.handleChange(e)}/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }
                                {selectedPostType === 'compliance' &&
                                  <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                    Due Date*
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                      <div className="relative messenger-box w-full lg:w-80 md:w-80">
                                        {/* <DatePicker
                                          value={due_date}
                                          className="h-10 w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                                          autoFocus={false}
                                          minDate={formType === 'add' ? new Date() : (messageExpired ? dueDateMinValue : new Date() )}
                                          format="M/d/yyyy"
                                          onChange={(e) => this.handleDateChange(e)} 
                                          calendarType="US"
                                          clearIcon={null}                    
                                        /> */}
                                        <DatePicker
                                          selected={due_date}
                                          className="h-10 w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-pointer relative z-10 bg-transparent"
                                          autoFocus={false}
                                          minDate={formType === 'add' ? new Date() : (messageExpired ? dueDateMinValue : new Date() )}
                                          dateFormat="M/d/yyyy"
                                          onChange={(e) => this.handleDateChange(e)} 
                                          calendarType="US"
                                        />
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 absolute top-2.5 right-2.5">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                                        </svg>
                                      </div>
                                      {/* {acknowledgeRole?.length > 0 && */}
                                        <div>
                                          <div className='items-center gap-1.5 mt-2.5'>
                                            <div className='flex items-center gap-1.5'>
                                              <Switch
                                                checked={setReminder}
                                                name="setReminder"
                                                onChange={(e) => this.handleSwitch(e, 'setReminder')}
                                                className={classNames(
                                                  setReminder ? 'bg-gray-900' : 'bg-gray-200',
                                                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                                                )}
                                              >
                                                <span className="sr-only">Use setting</span>
                                                <span
                                                  aria-hidden="true"
                                                  className={classNames(
                                                    setReminder ? 'translate-x-5' : 'translate-x-0',
                                                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                  )}
                                                />
                                              </Switch>
                                              <div className='text-sm text-gray-500 flex items-center gap-1'>
                                                Auto-Reminder
                                                <div data-tip data-for='autoReminderTooltip' className=''>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-500 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                                                  </svg>
                                                  <ReactTooltip className='max-w-xs react-tooltip-z-index-max' id="autoReminderTooltip" place="top" effect="solid">
                                                    The audience members who are required to acknowledge will automatically receive an email based on the number of days set below until they acknowledge the post.
                                                  </ReactTooltip>
                                                </div>
                                              </div>
                                              {setReminder &&
                                                <div className='flex gap-1.5 items-center'>
                                                  <div className='text-sm text-gray-500'>Every</div>
                                                  <div>
                                                    <input type="number" min={1} max={20} className='border border-gray-300 rounded-md bg-white text-gray-600 py-2.5 w-20 px-1 h-10 shadow-sm text-center' name="every.count" value={every?.count} onChange={(e) => this.handleReminderDayChange(e)} onKeyDown={(e) => this.handleReminderDayKeyDown(e)} onBlur={(e) => this.handleReminderDayOnBlur(e)}/>
                                                  </div>
                                                  <div className='text-sm text-gray-500'>{every?.count > 1 ? 'Days' : 'Day'}</div>
                                                </div>  
                                              }
                                            </div>
                                            {/* {setReminder &&
                                              <div className='flex items-center gap-1.5 mt-2.5'>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                  <div className="relative filters_box w-full lg:w-80">
                                                    <Menu as="div" className="relative block text-left">
                                                      <div>
                                                        <Menu.Button className="flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 reminder_button h-10">
                                                          {every?.notifyType === '' ? 'Select notification type' : 
                                                            <p className='text-gray-900'>{every?.notifyType}</p>
                                                          }
                                                          <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                                                        </Menu.Button>
                                                      </div>

                                                      <Transition
                                                        as={Fragment}
                                                        show={true}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95">
                                                        <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none reminder_items hidden filter_items">
                                                          <div className="p-2 max-h-60 overflow-y-auto">
                                                            <div className="py-1">
                                                              <div>
                                                                {reminderForm.map((type, i) => 
                                                                  <Menu.Item key = {i}>
                                                                    {({ active }) => (
                                                                      <label className="flex items-center text-gray-900 text-sm mb-2">
                                                                        <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" name="every.notifyType" checked = {every?.notifyType === type} value={type} onChange = {(e) => this.handleChange(e)}/>
                                                                          {type}
                                                                      </label>
                                                                        
                                                                    )}
                                                                  </Menu.Item>
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Menu.Items>
                                                      </Transition>
                                                    </Menu>
                                                  </div>
                                                </div>
                                                <div className='text-sm text-gray-500'>Every</div>
                                                <div>
                                                  <input type="number" min={3} max={20} className='border rounded-md bg-white text-gray-600 py-2.5 w-20 px-1 h-10' name="every.count" value={every?.count} onChange={(e) => this.handleChange(e)} onKeyDown={(e) => {e.preventDefault()}}/>
                                                </div>
                                                <div className='text-sm text-gray-500'>
                                                  <select className='border rounded-md bg-white text-gray-600 py-2.5 w-32 px-1' name="every.type" value={every?.type} onChange={(e) => this.handleChange(e)} disabled>
                                                    <option value="hours">Hours</option>
                                                    <option value="days">Days</option>
                                                    <option value="weeks">Weeks</option>
                                                  </select>
                                                </div>
                                              </div>
                                            } */}
                                          </div>
                                        </div>
                                      {/* } */}
                                    </div>
                                  </div>
                                }
                                {selectedPostType == 'permanent_class_opening' &&
                                  <Fragment>
                                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                        Location*
                                      </label>
                                      <div className="mt-1 sm:mt-0 sm:col-span-2 md:col-span-3">
                                        <div className='flex flex-wrap gap-2'>
                                          <div className="relative w-full md:w-80 lg:w-80">
                                            <Menu as="div" className="relative block text-left">
                                              <div>
                                                <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                  {eventSelectedLocation === '' ?
                                                    <p>Select location</p>
                                                  :
                                                    <p className = "text-gray-900">{this.showEventLocationName(eventSelectedLocation)}</p>
                                                  }
                                                  <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                                                </Menu.Button>
                                              </div>

                                              <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95">
                                                <Menu.Items className="origin-top-right z-20 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                  <div className="p-2 max-h-60 overflow-y-auto">
                                                    {regions_and_locations.map((region, i) =>
                                                      <div className="py-1" key = {i}>
                                                        {show_others &&
                                                          <Menu.Item>
                                                            {({ active }) => (
                                                              <label className="flex items-center text-gray-900 text-sm mb-2">
                                                                {region.region.name}
                                                              </label>
                                                                
                                                            )}
                                                          </Menu.Item>
                                                        }
                                                        <div className="ml-5">
                                                          {region.locations.map((location, j) =>
                                                          <Menu.Item key = {j}>
                                                            {({ active }) => (
                                                              <label className="flex items-center text-gray-900 text-sm mb-2">
                                                                <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={eventSelectedLocation === location?.id} value={location.id} onChange={(e) => this.handleEventLocation(e)}/>
                                                                {location.name}
                                                              </label>
                                                                
                                                            )}
                                                          </Menu.Item>
                                                          )}
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                </Menu.Items>
                                              </Transition>
                                            </Menu>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                        Start Date & Time*
                                      </label>
                                      <div className="mt-1 sm:mt-0 sm:col-span-2  md:col-span-3">
                                        <div className='flex flex-wrap gap-2'>
                                          <div className='relative w-full md:w-80 lg:w-80 messenger-box'>
                                            <DatePicker
                                              selected={eventStartDate}
                                              className="h-10 w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-pointer relative z-10 bg-transparent"
                                              autoFocus={false}
                                              minDate={new Date()}
                                              // isClearable
                                              dateFormat="M/d/yyyy"
                                              onChange={(e) => this.handleEventDateChange(e, 'eventStartDate')} 
                                              calendarType="US"
                                            />
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 absolute top-2.5 right-2.5">
                                              <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                                            </svg>
                                          </div>
                                          <div className="relative w-full md:w-80 lg:w-48">
                                            <Menu as="div" className="relative block text-left">
                                              <div>
                                                <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                  {eventStartTime === '' ? 
                                                    <p>Select start time</p>
                                                  :
                                                    <p className='text-gray-900'>{eventStartTime}</p>
                                                  } 
                                                  <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                                                </Menu.Button>
                                              </div>

                                              <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95">
                                                <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                  <div className="p-2 max-h-60 overflow-y-auto">
                                                    {start_hour_range.map((time, i) =>
                                                      <div className="py-1" key = {i}>
                                                        <Menu.Item>
                                                          {({ active }) => (
                                                            <label className="flex items-center text-gray-900 text-sm mb-2">
                                                              <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" name='eventStartTime' checked={eventStartTime === time} value={time} onChange={(e) => this.handlePermanentClassTime(e)}/>
                                                              {time}
                                                            </label>
                                                              
                                                          )}
                                                        </Menu.Item>
                                                      </div>
                                                    )}
                                                  </div>
                                                </Menu.Items>
                                              </Transition>
                                            </Menu>
                                          </div>
                                          <div className="relative w-full md:w-80 lg:w-48">
                                            <Menu as="div" className="relative block text-left">
                                              <div>
                                                <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                  {eventEndTime === '' ? 
                                                    <p>Select end time</p>
                                                  :
                                                    <p className='text-gray-900'>{eventEndTime}</p>
                                                  } 
                                                  <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                                                </Menu.Button>
                                              </div>

                                              <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95">
                                                <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                  <div className="p-2 max-h-60 overflow-y-auto">
                                                    {end_hour_range.map((time, i) =>
                                                      <div className="py-1" key = {i}>
                                                        <Menu.Item>
                                                          {({ active }) => (
                                                            <label className="flex items-center text-gray-900 text-sm mb-2">
                                                              <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" name='eventEndTime' checked={eventEndTime === time} value={time} onChange={(e) => this.handlePermanentClassTime(e)}/>
                                                              {time}
                                                            </label>
                                                              
                                                          )}
                                                        </Menu.Item>
                                                      </div>
                                                    )}
                                                  </div>
                                                </Menu.Items>
                                              </Transition>
                                            </Menu>
                                          </div>
                                          <div className="relative items-center flex gap-2 w-full md:w-80 lg:w-80 h-9">
                                            <label htmlFor="first-name" className="block text-xs font-medium text-gray-500">
                                              Select Days
                                            </label>
                                            <div className='flex gap-2 items-center'>
                                              {permanentClassDaysList.map((day, i) => 
                                                <button key={i} className={`w-6 h-6 rounded-full flex items-center justify-center relative ${selectedPermanentClassDays.includes(day?.value) ? "bg-gray-700" : "bg-gray-400"}`} onClick={() => this.handleSelectDays(day?.value)}>
                                                  <div className='text-white text-xs'>{day?.label}</div>
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Fragment>
                                }
                                {(selectedPostType !== 'group_chat' && selectedPostType !== 'direct_message') &&
                                  <Fragment>
                                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                        Allow comments
                                      </label>
                                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div className="relative flex filters_box w-full lg:w-80 items-center">
                                          <Switch
                                            checked={allowComment}
                                            name="allowComment"
                                            onChange={(e) => this.handleSwitch(e, 'allowComment')}
                                            className={classNames(
                                              allowComment ? 'bg-gray-900' : 'bg-gray-200',
                                              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                                            )}
                                          >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                              aria-hidden="true"
                                              className={classNames(
                                                allowComment ? 'translate-x-5' : 'translate-x-0',
                                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                              )}
                                            />
                                          </Switch>
                                          {allowComment ?
                                            <div className="block text-sm font-medium text-gray-500 sm:mt-px pl-2">
                                              Yes
                                            </div>
                                            :
                                            <div className="block text-sm font-medium text-gray-500 sm:mt-px pl-2">
                                              No
                                            </div>
                                          }
                                          <div data-tip data-for='allowCommentTooltip' className='ml-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-500 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                                            </svg>
                                            <ReactTooltip className='max-w-xs' id="allowCommentTooltip" place="top" effect="solid">
                                              Users without create post permissions will be able to comment on this post if you turn them on. Users with create post permissions have the ability to comment on all posts even if comments are turned off.
                                            </ReactTooltip>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                        Allow emojis
                                      </label>
                                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div className="relative flex filters_box w-full lg:w-80 items-center">
                                          <Switch
                                            checked={allowEmojis}
                                            name="allowEmojis"
                                            onChange={(e) => this.handleSwitch(e, 'allowEmojis')}
                                            className={classNames(
                                              allowEmojis ? 'bg-gray-900' : 'bg-gray-200',
                                              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                                            )}
                                          >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                              aria-hidden="true"
                                              className={classNames(
                                                allowEmojis ? 'translate-x-5' : 'translate-x-0',
                                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                              )}
                                            />
                                          </Switch>
                                          {allowEmojis ?
                                            <div className="block text-sm font-medium text-gray-500 sm:mt-px pl-2">
                                              Yes
                                            </div>
                                            :
                                            <div className="block text-sm font-medium text-gray-500 sm:mt-px pl-2">
                                              No
                                            </div>
                                          }
                                          <div data-tip data-for='allowEmojisTooltip' className='ml-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-500 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                                            </svg>
                                            <ReactTooltip className='max-w-xs' id="allowEmojisTooltip" place="top" effect="solid">
                                              When this is turned on, users who see your post will be able to react using an emoji.
                                            </ReactTooltip>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Fragment>
                                }
                                {selectedPostType === 'announcement' &&
                                  <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                    Expiration Date (optional)
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                      <div className="relative messenger-box w-full lg:w-80 md:w-80">
                                        {/* {(due_date === '' || due_date === null) &&
                                          <div className='absolute text-gray-400 bg-white text-sm z-2 top-1 left-2.5 cursor-pointer py-1.5 expiredate-placeholder'>
                                            Select the expiration date
                                          </div>
                                        } */}
                                        {/* <DatePicker
                                          value={due_date}
                                          className="h-10 w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-pointer"
                                          autoFocus={false}
                                          minDate={formType === 'add' ? new Date() : (messageExpired ? dueDateMinValue : new Date() )}
                                          format="M/d/yyyy"
                                          onChange={(e) => this.handleDateChange(e)}
                                          calendarType="US" 
                                          clearIcon={due_date === '' ? null : <CustomOption />}                    
                                        /> */}
                                        <DatePicker
                                          selected={due_date}
                                          className="h-10 w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-pointer relative z-10 bg-transparent"
                                          autoFocus={false}
                                          minDate={formType === 'add' ? new Date() : (messageExpired ? dueDateMinValue : new Date() )}
                                          dateFormat="M/d/yyyy"
                                          onChange={(e) => this.handleDateChange(e)}
                                          calendarType="US" 
                                          isClearable={due_date === '' ? false : true}
                                          // clearIcon={due_date === '' ? null : <CustomOption />}
                                          placeholderText="Select the expiration date"                    
                                        />
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 absolute top-2.5 right-2.5">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                                        </svg>

                                      </div>
                                    </div>
                                  </div>
                                } 
                                {(selectedPostType !== 'group_chat' && selectedPostType !== 'direct_message') &&
                                  <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                                      Your message*
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-3">
                                      <div className="relative filters_box w-full rounded-sm border-gray-200 resize-y">
                                        <TextEditor onChange={this.getTextMessage} handleAttachments = {this.handleAttachments} uploadCallback = {this.uploadCallback} message = {message} showDragDropError={this.showDragDropError} disableAttachBtn={false}/>
                                      </div>
                                      {attachments?.length > 0 &&
                                        <div className="inline-flex flex-wrap flex-col gap-2 pt-2 mt-2">
                                          {attachments.map((attachement, i) => 
                                            <div key = {i} className='inline-flex bg-gray-200 rounded-md px-2.5 py-1.5 fs-13 flex-col'>
                                              <div className='flex items-center gap-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-900 h-3.5 w-3.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                                </svg>
                                                <div className='text-gray-900'>{this.trimAttachmentNames(attachement?.fileName)}</div>
                                                <a className="cursor-pointer" onClick={() => this.handleCrossAttachments(attachement?.id)}>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                  </svg>
                                                </a>
                                              </div>
                                              {attachement?.uploading && 
                                                <div className='loading-dots flex items-center italic text-xs ml-5'>
                                                  Uploading
                                                  <img className='w-5 ml-1' src="/assets/loading-dots.gif" />
                                                </div>
                                              }
                                            </div>
                                          )}
                                          
                                        </div>
                                      }
                                    </div>
                                  </div>
                                }
                              </React.Fragment>
                            }
                            {formType === 'add'
                            ? 
                              (selectedPostType !== 'group_chat' && selectedPostType !== 'direct_message') ? 
                                <div className='flex flex-wrap pb-6 justify-between'>
                                  {selectedPostType !== '' &&  
                                    <a className='text-red-600 cursor-pointer flex items-center gap-1 justify-start' onClick={(e) => this.handleDiscard(e)}>
                                      {/* <svg xmlns="http://www.w3.org/2000/svg" className="text-red-600 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                                      </svg> */}
                                      <svg xmlns="http://www.w3.org/2000/svg" className="text-red-600 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                      </svg>
                                      Discard
                                    </a>
                                  }
                                  <div className='flex flex-wrap items-center justify-end gap-3'>
                                    {selectedPostType !== '' &&
                                      <React.Fragment>
                                        <button className={`flex items-center px-6 py-3 gap-1 border border-gray-300 shadow-sm font-medium rounded-md ${draftButtonDisabled ? 'button-disabled' : ''}`} onClick={(e) => this.handleSaveDraft(e)} disabled={draftButtonDisabled}>
                                          <div className='text-gray-900 text-base'>Save as draft</div>
                                        </button>
                                        {this.checkDisableSendButto() === true ?
                                          <button className={`bg-gray-800 flex items-center px-6 py-3 gap-1 border border-gray-300 shadow-sm font-medium rounded-md ${sendButtonDisabled ? 'button-disabled' : ''}`} onClick={(e) => this.handleSend(e)} disabled={sendButtonDisabled}>
                                            <div className="flex items-center gap-1 relative">
                                              {sendButtonDisabled && 
                                                <div className="flex items-center -left-4 justify-center absolute">
                                                  <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                  </svg>
                                                </div>
                                              }
                                              <svg xmlns="http://www.w3.org/2000/svg" className=" text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                                              </svg>
                                              <div className='text-white text-base'>Send</div>
                                            </div>
                                          </button>
                                          :
                                          <button className='bg-gray-400 flex items-center px-6 py-3 gap-1 border border-gray-300 cursor-not-allowed shadow-sm font-medium rounded-md'>
                                            <div className="flex items-center gap-1">
                                              <svg xmlns="http://www.w3.org/2000/svg" className=" text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                                              </svg>
                                              <div className='text-white text-base'>Send</div>
                                            </div>
                                          </button>
                                        }
                                      </React.Fragment>
                                    }
                                  </div>
                                </div>
                              :
                                (selectedPostType === 'group_chat' && 
                                  <div className='flex flex-wrap pb-6 justify-between'>
                                    <div>
                                    </div>
                                    <div className='flex flex-wrap items-center justify-end gap-3'>
                                      <button className={`flex items-center px-6 py-3 gap-1 border border-gray-300 shadow-sm font-medium rounded-md`} onClick={(e) => this.setState({redirectAfterSend: true, redirectAt: ''})}>
                                        <div className='text-gray-900 text-base'>Cancel</div>
                                      </button>
                                      {this.checkDisableSendButto() === true ?
                                        <button className={` flex items-center px-7 py-3 gap-1 border border-gray-300 shadow-sm font-medium rounded-md ${createGroupBtnDisabled ? 'button-disabled bg-gray-400' : 'bg-gray-800'}`} onClick={(e) => this.handleCreateGroup(e)} disabled={createGroupBtnDisabled}>
                                          <div className="flex items-center gap-2.5 relative">
                                            {createGroupBtnDisabled && 
                                              <div className="flex items-center -left-5 justify-center absolute">
                                                <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                              </div>
                                            }
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="text-white h-4 w-4">
                                              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                            </svg>
                                            <div className='text-white text-base'>Create group</div>
                                          </div>
                                        </button>
                                      :
                                        <button className={`bg-gray-400 flex items-center px-7 py-3 gap-1 border border-gray-300 cursor-not-allowed shadow-sm font-medium rounded-md`}>
                                          <div className="flex items-center gap-2.5 relative">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="text-white h-4 w-4">
                                              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                            </svg>
                                            <div className='text-white text-base'>Create group</div>
                                          </div>
                                        </button>
                                      }
                                    </div>        
                                  </div>
                                )
                                ||
                                (selectedPostType === 'direct_message' &&
                                  <div className='flex flex-wrap pb-6 justify-between'>
                                    <div>
                                    </div>
                                    <div className='flex flex-wrap items-center justify-end gap-3'>
                                      <button className={`flex items-center px-6 py-3 gap-1 border border-gray-300 shadow-sm font-medium rounded-md`} onClick={(e) => this.setState({redirectAfterSend: true, redirectAt: ''})}>
                                        <div className='text-gray-900 text-base'>Cancel</div>
                                      </button>
                                      {this.checkDisableSendButto() === true ?
                                        <button className={` flex items-center px-7 py-3 gap-1 border border-gray-300 shadow-sm font-medium rounded-md ${createDmBtnDisabled ? 'button-disabled bg-gray-400' : 'bg-gray-800'}`} onClick={(e) => this.handleCreateDM(e)} disabled={createDmBtnDisabled}>
                                          <div className="flex items-center gap-2.5 relative">
                                            {createDmBtnDisabled && 
                                              <div className="flex items-center -left-5 justify-center absolute">
                                                <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                              </div>
                                            }
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="text-white h-4 w-4">
                                              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                            </svg>
                                            <div className='text-white text-base'>Start a conversation</div>
                                          </div>
                                        </button>
                                      :
                                        <button className={`bg-gray-400 flex items-center px-7 py-3 gap-1 border border-gray-300 cursor-not-allowed shadow-sm font-medium rounded-md`}>
                                          <div className="flex items-center gap-2.5 relative">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="text-white h-4 w-4">
                                              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                            </svg>
                                            <div className='text-white text-base'>Start a conversation</div>
                                          </div>
                                        </button>
                                      }
                                    </div>        
                                  </div>
                                )
                            :
                              draftedPost ?
                                <div className='flex flex-wrap pb-6 justify-between'>
                                  {selectedPostType !== '' &&  
                                    <a className='text-red-600 cursor-pointer flex items-center gap-1 justify-start' onClick={(e) => this.handleDiscard(e)}>
                                      {/* <svg xmlns="http://www.w3.org/2000/svg" className="text-red-600 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                                      </svg> */}
                                      <svg xmlns="http://www.w3.org/2000/svg" className="text-red-600 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                      </svg>
                                      Discard
                                    </a>
                                  }
                                  <div className='flex flex-wrap items-center justify-end gap-3'>
                                    {selectedPostType !== '' &&
                                      <React.Fragment>
                                        <button className={`flex items-center px-6 py-3 gap-1 border border-gray-300 shadow-sm font-medium rounded-md ${draftButtonDisabled ? 'button-disabled' : ''}`} onClick={(e) => this.handleSaveDraft(e)} disabled={draftButtonDisabled}>
                                          <div className='text-gray-900 text-base'>Save as draft</div>
                                        </button>
                                        {this.checkDisableSendButto() === true ?
                                          <button className={`bg-gray-800 flex items-center px-6 py-3 gap-1 border border-gray-300 shadow-sm font-medium rounded-md ${sendButtonDisabled ? 'button-disabled' : ''}`} onClick={(e) => this.handleSend(e)} disabled={sendButtonDisabled}>
                                            <div className="flex items-center gap-1">
                                              <svg xmlns="http://www.w3.org/2000/svg" className=" text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                                              </svg>
                                              <div className='text-white text-base'>Send</div>
                                            </div>
                                          </button>
                                          :
                                          <button className='bg-gray-400 flex items-center px-6 py-3 gap-1 border border-gray-300 cursor-not-allowed shadow-sm font-medium rounded-md'>
                                            <div className="flex items-center gap-1">
                                              <svg xmlns="http://www.w3.org/2000/svg" className=" text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                                              </svg>
                                              <div className='text-white text-base'>Send</div>
                                            </div>
                                          </button>
                                        }
                                      </React.Fragment>
                                    }
                                  </div>
                                </div>
                              :
                              (selectedPostType !== 'group_chat' && selectedPostType !== 'direct_message') ?
                                <div className='flex flex-wrap pb-6 justify-between'>
                                  {selectedPostType !== '' &&   
                                    <a className='text-red-600 cursor-pointer flex items-center gap-1 justify-start' onClick={(e) => this.handleDeletePost(e)}>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="text-red-600 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                      </svg>
                                      Delete
                                    </a>
                                  }
                                  <div className='flex flex-wrap items-center justify-end gap-3'>
                                    {selectedPostType !== '' &&
                                      <React.Fragment>
                                        <Link to={this.handleBackUrl()} className='flex items-center px-6 py-3 gap-1 border border-gray-300 shadow-sm font-medium rounded-md'>
                                          <div className='text-gray-900 text-base'>Cancel</div>
                                        </Link>
                                        {this.checkDisableSendButto() === true ?
                                          <button className={`bg-gray-800 flex items-center px-6 py-3 gap-1 border border-gray-300 shadow-sm font-medium rounded-md ${editButtonDisabled ? 'button-disabled' : ''}`} onClick={(e) => this.handleEditPost(e)} disabled={editButtonDisabled}>
                                            <div className="flex items-center gap-1 relative">
                                              {editButtonDisabled && 
                                                <div className="flex items-center -left-4 justify-center absolute">
                                                  <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                  </svg>
                                                </div>
                                              }
                                              <svg xmlns="http://www.w3.org/2000/svg" className=" text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                                              </svg>
                                              <div className='text-white text-base'>Save</div>
                                            </div>
                                          </button>
                                          :
                                          <button className='bg-gray-400 flex items-center px-6 py-3 gap-1 border border-gray-300 cursor-not-allowed shadow-sm font-medium rounded-md'>
                                            <div className="flex items-center gap-1">
                                              <svg xmlns="http://www.w3.org/2000/svg" className=" text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                                              </svg>
                                              <div className='text-white text-base'>Save</div>
                                            </div>
                                          </button>
                                        }
                                      </React.Fragment>
                                    }
                                  </div>
                                </div>
                              :
                                (selectedPostType === 'group_chat' && 
                                  <div className='flex flex-wrap pb-6 justify-between'>
                                    <div></div>
                                    <div className='flex flex-wrap items-center justify-end gap-3'>
                                      {selectedPostType !== '' &&
                                        <React.Fragment>
                                          <Link to={this.handleBackUrl()} className='flex items-center px-6 py-3 gap-1 border border-gray-300 shadow-sm font-medium rounded-md'>
                                            <div className='text-gray-900 text-base'>Cancel</div>
                                          </Link>
                                          {this.checkDisableSendButto() === true ?
                                            <button className={`flex items-center px-6 py-3 gap-1 border border-gray-300 shadow-sm font-medium rounded-md ${editButtonDisabled ? 'button-disabled bg-gray-400' : 'bg-gray-800'}`} onClick={(e) => this.handleEditGroupChat(e)} disabled={editButtonDisabled}>
                                              <div className="flex items-center gap-1 relative">
                                                {editButtonDisabled && 
                                                  <div className="flex items-center -left-4 justify-center absolute">
                                                    <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                  </div>
                                                }
                                                <svg xmlns="http://www.w3.org/2000/svg" className=" text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                                                </svg>
                                                <div className='text-white text-base'>Save</div>
                                              </div>
                                            </button>
                                            :
                                            <button className='bg-gray-400 flex items-center px-6 py-3 gap-1 border border-gray-300 cursor-not-allowed shadow-sm font-medium rounded-md'>
                                              <div className="flex items-center gap-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" className=" text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                                                </svg>
                                                <div className='text-white text-base'>Save</div>
                                              </div>
                                            </button>
                                          }
                                        </React.Fragment>
                                      }
                                    </div>
                                  </div>
                                )
                                ||
                                (selectedPostType === 'direct_message' &&
                                  <div className='flex flex-wrap pb-6 justify-between'>
                                    <div></div>
                                    <div className='flex flex-wrap items-center justify-end gap-3'>
                                      {selectedPostType !== '' &&
                                        <React.Fragment>
                                          <Link to={this.handleBackUrl()} className='flex items-center px-6 py-3 gap-1 border border-gray-300 shadow-sm font-medium rounded-md'>
                                            <div className='text-gray-900 text-base'>Cancel</div>
                                          </Link>
                                          {this.checkDisableSendButto() === true ?
                                            <button className={`flex items-center px-6 py-3 gap-1 border border-gray-300 shadow-sm font-medium rounded-md ${editButtonDisabled ? 'button-disabled bg-gray-400' : 'bg-gray-800 '}`} onClick={(e) => this.handleEditDirectMessage(e)} disabled={editButtonDisabled}>
                                              <div className="flex items-center gap-1 relative">
                                                {editButtonDisabled && 
                                                  <div className="flex items-center -left-4 justify-center absolute">
                                                    <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                  </div>
                                                }
                                                <svg xmlns="http://www.w3.org/2000/svg" className=" text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                                                </svg>
                                                <div className='text-white text-base'>Save</div>
                                              </div>
                                            </button>
                                            :
                                            <button className='bg-gray-400 flex items-center px-6 py-3 gap-1 border border-gray-300 cursor-not-allowed shadow-sm font-medium rounded-md'>
                                              <div className="flex items-center gap-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" className=" text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                                                </svg>
                                                <div className='text-white text-base'>Save</div>
                                              </div>
                                            </button>
                                          }
                                        </React.Fragment>
                                      }
                                    </div>
                                  </div> 
                                )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
								</div>
							</main>
						</div>
					</div>
				}
        {showCreatorWantInGroupPopup &&
          <CustomPopup show={showCreatorWantInGroupPopup} close={this.closeCustomPopup} title={"Confirmation"}>
            <div className="mt-3 border-t border-gray-200 mb-2">
              <div className='flex justify-between sm:gap-4 sm:items-center px-7 py-3 text-center text-base font-normal'>
                {homeClubSelectedLocations?.length > 0 ? `Heads up! You do not currently meet the audience criteria for this group chat. This means you will not see it once created unless you update your profile with the audience attributes. Do you want to proceed?` : `Heads up! You are ${formType === 'edit' ? "editing" : "creating"} a group chat with an audience that you are not a part of. Are you sure you want to ${formType === 'edit' ? "edit" : "create"} this chat?`}
              </div>
              <div className='flex sm:gap-4 sm:items-center px-7 justify-center pt-4'>
                <button className={`flex items-center px-6 py-2.5 gap-1 border border-gray-300 shadow-sm font-normal rounded-md bg-gray-800 `} onClick={(e) => this.handleAddUserInAudience(e)}>
                  <div className="flex items-center gap-1 relative">
                    <div className='text-white text-sm'>Yes</div>
                  </div>
                </button>
                <button className={`flex items-center px-6 py-2.5 gap-1 border border-gray-300 shadow-sm font-normal rounded-md bg-gray-800 `} onClick={(e) => this.handleCloseCustomPopup(e)}>
                  <div className="flex items-center gap-1 relative">
                    <div className='text-white text-sm'>No</div>
                  </div>
                </button>
              </div>
            </div>
          </CustomPopup>
        }
        {showConfirmGroupPresentPopup &&
          <CustomPopup show={showConfirmGroupPresentPopup} close={this.closeCustomPopup} title={"Confirm create group"}>
            <div className="mt-3 border-t border-gray-200 mb-2">
              <div className='flex justify-between sm:gap-4 sm:items-center px-7 py-3 text-center text-base font-normal'>
                Heads up! You already have a group chat with this same audience. Are you sure you want to {formType === 'edit' ? "edit" : "create"} this chat?
              </div>
              <div className='flex sm:gap-4 sm:items-center px-7 justify-center pt-4'>
                <button className={`flex items-center px-6 py-2.5 gap-1 border border-gray-300 shadow-sm font-normal rounded-md bg-gray-800 `} onClick={(e) => this.handleConfirmCreateGroup(e)}>
                  <div className="flex items-center gap-1 relative">
                    <div className='text-white text-sm'>Yes</div>
                  </div>
                </button>
                <button className={`flex items-center px-6 py-2.5 gap-1 border border-gray-300 shadow-sm font-normal rounded-md bg-gray-800 `} onClick={(e) => this.handleCloseConfirmCreateGroup(e)}>
                  <div className="flex items-center gap-1 relative">
                    <div className='text-white text-sm'>No</div>
                  </div>
                </button>
              </div>
            </div>
          </CustomPopup>
        }
        <Transition.Root show={showDiscardModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeDiscardModal(e)}>
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
									<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
											onClick={(e) => this.closeDiscardModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
										</div>
										<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
											<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
												
                        {draftedPost ? 'Discard draft' : 'Discard changes'}
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-sm text-gray-500">
                          {draftedPost ? 'Are you sure you want to discard draft?' : 'Are you sure you want to discard changes?'}
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    {draftedPost ? 
                      <button
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={(e) => this.processDeletePost(e)}
                      >
                        Discard
                      </button>
                    :
                      <Link
                        to = {`/messenger`}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        
                      >
                        Discard
                      </Link>
                    }
										<button
											type="button"
											className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
											onClick={(e) => this.closeDiscardModal(e)}
										>
											Cancel
										</button>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
        <Transition.Root show={showDeleteModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeDeleteModal(e)}>
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
									<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
											onClick={(e) => this.closeDeleteModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
										</div>
										<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
											<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
												Delete post
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-sm text-gray-500">
													Are you sure you want to delete post?
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
										<button
											className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
											onClick={(e) => this.processDeletePost(e)}
										>
											Delete
										</button>
										<button
											type="button"
											className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
											onClick={(e) => this.closeDeleteModal(e)}
										>
											Cancel
										</button>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
        <Transition.Root show={showSelectedPeopleModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeAudienceModal(e)}>
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
									<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
											onClick={(e) => this.closeAudienceModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:items-start">
                    <div className='flex flex-wrap gap-3 items-center mb-3'>
                      <div className='item-center'>
                        <a className={`flex items-center gap-x-1.5 text-sm px-1 rounded-md ${'text-gray-700'}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                          </svg>
                          View Audience ({selectedPeople?.length})
                        </a>
                      </div>
                      <div className='relative'>
                        <div className='absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none h-10'>
                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                          </svg>
                        </div>
                        <input type='text' placeholder='Search audience' className='h-10 flex justify-center w-52 rounded border border-gray-300 shadow-sm pl-7 pr-2 py-2 bg-white text-sm focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500' name="searchAudience" value={searchAudience} onChange={(e) => this.handleSearchAudience(e)}/>
                      </div>
                    </div>
                    {all_selectedPeople?.length > 0 ?
                      <div className='justify-between pt-3 max-h-72 overflow-y-auto pr-4 min-h-388'>
                        {totalUsers.map((user, i) =>
                          (all_selectedPeople.includes(user?.id) &&
                            <div key={i} className='flex justify-between pb-3'>
                              <div className='flex items-center gap-x-1.5'>
                                {user?.image !== '' ?
                                  <div className="relative w-6 h-6">
                                    <img src={`${user?.image}`} className='rounded-full' />
                                  </div>
                                  :
                                  <div className='w-6 h-6 rounded-full bg-gray-500 flex items-center justify-center relative'>
                                    <div className='text-white text-xs'>{user?.name_image}</div>
                                  </div>
                                }
                                <div className='bg-purple-100 text-purple-800 py-0.5 px-2.5 flex items-center rounded-xl'>
                                  <div className='text-sm pr-1'>{user?.full_name}</div>
                                  {(this.hideTheAudienceCancelButton(user?.id)) &&
                                    <a onClick={() => this.removeSelectedPeople(user?.id)}>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="text-violet-800  h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                      </svg>
                                    </a>
                                  }
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                      :
                      <div className='text-center text-sm pt-3 justify-center items-center flex h-64 min-h-388 text-gray-500'>
                        No audience found.
                      </div>
                    }
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
        <Transition.Root show={editPostConfModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeDiscardModal(e)}>
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
									<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500"
											onClick={(e) => this.closeDiscardModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										{/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
										</div> */}
										<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
											<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Notify audience
											</Dialog.Title>
											<div className="mt-3">
												<p className="text-sm text-gray-700">
													Do you want to notify the audience that your post has been updated?
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-3">
                    <button
                      className={`bg-dark-blue flex items-center px-4 py-2 gap-1 border border-gray-300 shadow-sm font-medium rounded-md hover:bg-gray-800`}
                      onClick={(e) => this.performEditPost(e, true)}
                    >
                      <div className="flex items-center gap-1">
                        <svg xmlns="http://www.w3.org/2000/svg" className=" text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                        </svg>
                        <div className='text-white text-base'>Send</div>
                      </div>
                    </button>
										<button
											type="button"
											className="w-full inline-flex items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 sm:mt-0 sm:w-auto sm:text-sm"
											onClick={(e) => this.performEditPost(e, false)}
										>
											Don't Send
										</button>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
			</React.Fragment>
    )
  }
}

class CustomOption extends Component {
  render() {
    return (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-900 hover:text-blue-600" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </div>
    );
  }
}