import React, { Fragment, useState, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { AngleIcon } from './../../../../assets';

export default function YearTypeRangeSelectorComponent(props) {
  const { selectedYear, handleChangeYear } = props
  const [ isOpen, setIsOpen ] = useState(false);

  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  const handleViewDate = (value) => {
    let date = new Date(value);
    let formattedDate = date.toLocaleString("en-US", {
      year: 'numeric',
    });
    return `${formattedDate}`
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
    const handleOnClick = () => {
      if(!isOpen) {
        onClick()
      }
    }

    return (
      <button className="" onClick={handleOnClick} ref={ref}>
        <div className="text-2xl font-semibold flex items-center justify-between font-open-sans">{handleViewDate(value) || value}
          <div>
            <AngleIcon classNames={'ml-2 text-gray-900 h-5 w-5'}/>
          </div>
        </div>
      </button>
    )
  });

  const handleCalendarOpen = () => {
    setIsOpen(true)
  }

  const handleCalendarClose = () => {
    setIsOpen(false)
  }

  
  return(
    <Fragment>
      <div className='week-selector-datepicker'>
        <DatePicker
          className=""
          selected={selectedYear}
          renderYearContent={renderYearContent}
          showYearPicker
          onChange={(e) => handleChangeYear(e)}
          customInput={<ExampleCustomInput />}
          onCalendarOpen={handleCalendarOpen}
          onCalendarClose={handleCalendarClose}
        />
      </div>
    </Fragment>
  )
}