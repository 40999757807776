import React, { Fragment } from "react";

export default function SearchIcon({classNames}) {
  return (
    <Fragment>
      <svg className={classNames} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.58268 17.5C13.9549 17.5 17.4993 13.9556 17.4993 9.58334C17.4993 5.21108 13.9549 1.66667 9.58268 1.66667C5.21043 1.66667 1.66602 5.21108 1.66602 9.58334C1.66602 13.9556 5.21043 17.5 9.58268 17.5Z" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.3327 18.3333L16.666 16.6667" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </Fragment>
  )
}