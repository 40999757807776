import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ChannelList, useChatContext } from 'stream-chat-react'
import ChannelListContainer from './channelListContainer';
import { Link, Redirect } from 'react-router-dom';
import { CustomChannelPreview } from './customChannelPreview';
import ChannelSearch from './channelSearch';
import { ChannelListEmptyState } from './channelListEmptyState';

const Container = styled.div`
    .str-chat {
      height: max-content;
      &.str-chat-channel-list {
        float: none;
      }
    }

    .channel-list {
      width: 100%;
      &__message {
        color: white;
      }
    }
  `;

const randomStr = () => Math.random().toString(36).substring(7);

export default function CustomChannelList(props) {
  const { activateChannel, setUnreadMessges, onClickOnEmptyState, dataFormat, business } = props
  const { client } = useChatContext()
  const [ redirect, setRedirect ] = useState(false)
  const [ redirectionUrl, setRedirectionUrl ] = useState(null)

  const getTodayISO = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today.toISOString();
  };

  const filtersByType = {
    $or: [
      {
        type: 'team',
        members: { $in: [client.user.id] },
        disabled: {$eq: false}
      },
      {
        type: 'messaging',
        members: { $in: [client.user.id] },
        disabled: {$eq: false},
        last_message_at: { $exists: true }
      },
    ],
  };

  useEffect(() => {
    client.on((event) => {
      if (event.total_unread_count !== undefined) {
        setUnreadMessges(event.total_unread_count);
      }
    })
  }, [client])

  const getClickOnList = (channel) => {
    if(!location?.pathname.includes(`/messenger/chat-room`)){
      setRedirectionUrl(`/messenger/chat-room/${channel?.id}`)
      setRedirect(true)
    }else{
      setRedirect(false)
    }
    activateChannel(channel)
  }

  const handleEmptyBannerClick = () => {
    onClickOnEmptyState()
  }

  return (
    <React.Fragment>
      {redirect && redirectionUrl !== null && <Redirect push to={redirectionUrl} />}
      <Container>
        <ChannelSearch clickOnChannel={getClickOnList} business={business}/>
        <ChannelList 
          EmptyStateIndicator={() => <ChannelListEmptyState onBannerClick={handleEmptyBannerClick}/>} 
          setActiveChannelOnMount={false}
          List={(listProps) => <ChannelListContainer {...listProps}/>}
          filters={filtersByType}
          Preview={(previewProps) => (
            <CustomChannelPreview
              {...previewProps}
              dataFormat={dataFormat}
              clickOnChannel={getClickOnList}
            />
          )}
        />
      </Container>
    </React.Fragment>
  )
}
