import React, { Fragment, useState } from 'react';
import { sendConfirmation } from './../../../helpers'
import { getSudomain } from '../../../../../helpers'
import Cookies from 'js-cookie';

export default function ActivationEmailSingleComponent(props){
  const { emailUser, closeConfirmationPopup, showAlert, doneSendSingleActivationEmail  } = props
  const [ disabledSendAllBtn, setDisabledSendAllBtn ] = useState(false)

  const processSendActivationEmail = (e) => {
    e.preventDefault()
    setDisabledSendAllBtn(true)
    sendConfirmation(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), emailUser).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        showAlert(true, 'success', result.success)
        doneSendSingleActivationEmail()
      }else{
        showAlert(true, 'danger', result.error)
        setDisabledSendAllBtn(false)
      }
    })
  }

  return(
    <Fragment>
      <div className="mt-3 border-t border-gray-200">
        <div className='flex flex-wrap sm:gap-4 sm:items-center pt-2 px-7 mb-3'>
          {emailUser?.activation_sent_at !== "" &&
            <p className="text-sm text-gray-500">Do you want to send another Activation Email to {emailUser?.full_name}?</p> 
          }
          {emailUser?.activation_sent_at === "" &&
            <p className="text-sm text-gray-500">Do you want to send an Activation Email to {emailUser?.full_name}?</p>    
          }
        </div>
        <div className='flex sm:gap-4 sm:items-center px-7 pt-3.5 justify-start sm:justify-center sm:flex-row-reverse'>
          <button
            type="button"
            className={`cursor-pointer w-full h-10 items-center inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm ${disabledSendAllBtn ? 'button-disabled bg-gray-400' : 'bg-dark-blue'}`}
            onClick={(e) => processSendActivationEmail(e)}
            disabled={disabledSendAllBtn}
          >
            <div className='text-white text-base flex items-center gap-2.5 relative px-1'>
              {disabledSendAllBtn &&
                <div className="flex items-center -left-3.5 justify-center absolute">
                  <svg className="animate-spin h-3.5 w-3.5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              }
              Yes
            </div>
          </button>
          <button
            type="button"
            className="mt-3 w-full h-10 items-center inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={(e) => closeConfirmationPopup(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  )
}