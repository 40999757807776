import React, { Fragment } from "react";

export default function ClassesComponent(props) {
  const { newSubRequest } = props;

  const getSortedData = (data) => {
    if(data?.length == 0 || !data) return []
    return data.sort((a, b) => new Date(a.start_time.substring(0, 19)) - new Date(b.start_time.substring(0, 19)))
  }
  
  return(
    <Fragment>
      <div className="flex flex-col gap-2 w-full mb-4">
        <div className="flex text-base md:text-lg text-black font-semibold">
          Classes ({newSubRequest?.schedules?.length})
        </div>
        <div className="max-h-56 overflow-auto border border-gray-300 rounded-2xl">
          <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
              <tr>
                <th className={`px-3 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider`}>
                  <div className='flex items-center'>
                    <span className=''>Class</span> &nbsp; &nbsp;
                  </div>
                </th>
                <th className={`px-3 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider`}>
                  <div className='flex items-center'>
                    <span className=''>Date</span> &nbsp; &nbsp;
                  </div>
                </th>
                <th className={`px-3 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider`}>
                  <div className='flex items-center'>
                    <span className=''>Time</span> &nbsp; &nbsp;
                  </div>
                </th>
                <th className={`px-3 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider`}>
                  <div className='flex items-center'>
                    <span className=''>Duration</span> &nbsp; &nbsp;
                  </div>
                </th>
                <th className={`px-3 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider`}>
                  <div className='flex items-center'>
                    <span className=''>Location</span> &nbsp; &nbsp;
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200'>
              {getSortedData(newSubRequest?.schedules)?.map((schedule, i) => 
                <tr className='bg-white relative' key={i}>
                  <td className='px-3 py-3'>
                    <div className='text-sm font-medium text-gray-900'>
                      {schedule?.title}
                    </div>
                  </td>
                  <td className='px-3 py-3'>
                    <div className='text-sm text-gray-900 font-medium whitespace-nowrap'>
                      {schedule?.date}
                    </div>
                  </td>
                  <td className='px-3 py-3'>
                    <div className='text-sm text-gray-900 font-medium whitespace-nowrap'>
                      {schedule?.time?.trim()}
                    </div>
                  </td>
                  <td className='px-3 py-3'>
                    <div className='text-sm font-medium text-gray-900'>
                      {schedule?.duration}
                    </div>
                  </td>
                  <td className='px-3 py-3'>
                    <div className='text-sm font-medium text-gray-900'>
                      {schedule?.location}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  )
}