import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { getSudomain, setTitle, checkSettingsPermissios } from './../../../components/helpers'
import { snakeCase } from "./helpers";
import ReactTooltip from "react-tooltip";

export default class Permissions extends Component {

  componentDidMount = () => {
		setTitle('permissions')
    if(Object.keys(this.props.user).length !== 0){
      checkSettingsPermissios('permissions', this.props)
    }
	}

  render() {
    return (
      <React.Fragment>
        <div className='w-full min-h-screen bg-gray-50'>
          <div className='h-full'>
            <div className='max-w-8xl mx-auto h-full'>
              <div className='hidden'>
                <div className='bg-white break-words p-3 border border-gray-200 rounded-lg'>
                  <p className="text-gray-500 text-sm font-normal">
                    Here you can set your default permissions for each role within your NetGym account. If you have questions about permissions, please don’t hesitate to reach out so we can help! <a className="text-decoration-none text-sm font-medium text-gray-700" href="mailto:help@netgym.com"> Email Us</a>
                  </p>
                </div>
              </div>
              <div className='mt-3 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4'>
                <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                  <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                    <table className='min-w-full divide-y divide-gray-200'>
                      <thead className='bg-gray-50'>
                        <tr>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Permission Description</th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-10"></th>
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200'> 
                          <tr className='bg-white'>
                            <td className='px-6 py-4 text-sm font-medium text-gray-900'>
                              <div className='text-sm text-gray-900'>Business Admin</div>
                            </td>
                            <td className='px-6 py-4'>
                              <div className='text-sm font-medium text-gray-500'>
                                Default permissions for all admins within the account.<br/>Admins have access to entire NetGym functionality.
                              </div>
                            </td>
                            <td className='px-6 py-4'>
                              <div className="justify-end">
                                <Link to={`/settings-v4/permissions/form/business_admin`} className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' data-tip data-for={`edit_Business_Admin_button`}>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                  </svg>
                                </Link>
                                <ReactTooltip className='max-w-xs whitespace-nowrap' id={`edit_Business_Admin_button`} place="top" effect="solid">
                                  Edit Business Admin Permissions
                                </ReactTooltip>
                              </div>
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='px-6 py-4 text-sm font-medium text-gray-900'>
                              <div className='text-sm text-gray-900'>Regional Admin</div>
                            </td>
                            <td className='px-6 py-4'>
                              <div className='text-sm font-medium text-gray-500'>
                              Default permissions for all regional admins within the account.<br/>
                              Enable or disable certain functionality for all regional admins.
                              </div>
                            </td>
                            <td className='px-6 py-4'>
                              <div className="justify-end">
                                <Link to={`/settings-v4/permissions/form/regional_admin`} className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' data-tip data-for={`edit_Regional_Admin_button`}>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                  </svg>
                                </Link>
                                <ReactTooltip className='max-w-xs whitespace-nowrap' id={`edit_Regional_Admin_button`} place="top" effect="solid">
                                  Edit Regional Admin Permissions
                                </ReactTooltip>
                              </div>
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='px-6 py-4 text-sm font-medium text-gray-900'>
                              <div className='text-sm text-gray-900'>Fitness Manager</div>
                            </td>
                            <td className='px-6 py-4'>
                              <div className='text-sm font-medium text-gray-500'>
                              Default permissions for all fitness managers within the account.<br/>
                              Enable or disable certain functionality for all fitness managers.
                              </div>
                            </td>
                            <td className='px-6 py-4'>
                              <div className="justify-end">
                                <Link to={`/settings-v4/permissions/form/fitness_manager`} className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' data-tip data-for={`edit_Fitness_Manager_button`}>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                  </svg>
                                </Link>
                                <ReactTooltip className='max-w-xs whitespace-nowrap' id={`edit_Fitness_Manager_button`} place="top" effect="solid">
                                  Edit Fitness Manager Permissions
                                </ReactTooltip>
                              </div>
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='px-6 py-4 text-sm font-medium text-gray-900'>
                              <div className='text-sm text-gray-900'>Fitness Instructor</div>
                            </td>
                            <td className='px-6 py-4'>
                              <div className='text-sm font-medium text-gray-500'>
                              Default permissions for all fitness instructor within the account.<br/>
                              Enable or disable certain functionality for all fitness instructor.
                              </div>
                            </td>
                            <td className='px-6 py-4'>
                              <div className="justify-end">
                                <Link to={`/settings-v4/permissions/form/instructor`} className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' data-tip data-for={`edit_Fitness_Instructor_button`}>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                  </svg>
                                </Link>
                                <ReactTooltip className='max-w-xs whitespace-nowrap' id={`edit_Fitness_Instructor_button`} place="top" effect="solid">
                                  Edit Fitness Instructor Permissions
                                </ReactTooltip>
                              </div>
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='px-6 py-4 text-sm font-medium text-gray-900'>
                              <div className='text-sm text-gray-900'>Ops</div>
                            </td>
                            <td className='px-6 py-4'>
                              <div className='text-sm font-medium text-gray-500'>
                              Default permissions for all ops users within the account.<br/>
                              Ops User can view all pages.
                              </div>
                            </td>
                            <td className='px-6 py-4'>
                              <div className="justify-end">
                                <Link to={`/settings-v4/permissions/form/ops`} className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' data-tip data-for={`edit_Ops_button`}>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                  </svg>
                                </Link>
                                <ReactTooltip className='max-w-xs whitespace-nowrap' id={`edit_Ops_button`} place="top" effect="solid">
                                  Edit Ops Permissions
                                </ReactTooltip>
                              </div>
                            </td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
