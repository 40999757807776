import React, { Fragment, useState, useEffect } from "react";
import TashIcon from './../../../../assets/trashIcon';
import ReactTooltip from "react-tooltip";
import CancelOptionsPopupComponent from './../../../../../subRequestDetails/components/topButtonsComponent/moreActionComponent/cancelOptionsPopupComponent';

export default function CancelButtonComponent(props) {
  const { event, handleSetAlert, handleUpdateEvent, handleReloadData, sentBucket, handleDeleteEvent } = props
  const [ showCancelPopup, setShowCancelPopup ] = useState(false)
  const cancelOptions = ['rescind_class', 'delete_class', 'delete_without_sync', 'cancel_sub_request', 'crunch_event_delete', 'delete_class_duplicate', 'delete_class_no_longer', 'delete_class_other', 'delete_class_original_instructor']
  const [ optionListKeys, setOptionListKeys ] = useState(event?.more_action_detail?.map(item => item?.key) || [])
  const [ cancelOptionsOnly, setCancelOptionsOnly ] = useState(event?.more_action_detail?.filter(item => cancelOptions.includes(item?.key)) || [])

  useEffect(() => {
    if(event){
      setOptionListKeys(event?.more_action_detail?.map(item => item?.key) || [])
      setCancelOptionsOnly(event?.more_action_detail?.filter(item => cancelOptions.includes(item?.key)) || [])
    }
  }, [event])

  const handleShowCancelPopup = () => {
    setShowCancelPopup(true)
  }

  const closeCancelPopup = (data) => {
    setShowCancelPopup(data)
  }

  const handleUpdateEventData = (data) => {
    if(data == 'deleted'){
      setShowCancelPopup(false)
      handleDeleteEvent(event)
    }else{
      handleUpdateEvent(data)
    }
  }
  return(
    <Fragment>
      {showCancelPopup &&
        <CancelOptionsPopupComponent eventData={event} showCancelOptionsPopup={showCancelPopup} handleSetAlert={handleSetAlert} closeShowCancelOptionsPopup={closeCancelPopup} handleUpdateEvent={handleUpdateEventData} optionListKeys={optionListKeys} cancelOptionKeys={cancelOptions} backPath={''} type={'preview'} sentBucket={sentBucket} calendarEvent={false} handleUpdateCalendarEvent={() => {}}/>
      }
      {cancelOptionsOnly.length > 0 ?
        <button className='flex relative z-20 justify-center items-center border border-gray-300 rounded-full min-h-8 min-w-8' onClick={() => handleShowCancelPopup()} data-tip data-for={`cancel_button_`+event?.id.toString()}>
          <TashIcon classNames={'text-black wc-14 hc-14'}/>
          <ReactTooltip className='max-w-xs whitespace-nowrap' id={`cancel_button_`+event?.id.toString()} place="top" effect="solid">
            Cancel
          </ReactTooltip>
        </button>
      : 
        null
      }
    </Fragment>
  )
}