import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { CalendarIcon } from '../../../../../assets';
import DatePicker from "react-datepicker";

export default function DateTimeDropDownComponent(props) {
  const { eventDateTime, eventStartDateTime, eventEndDateTime, handleSaveDateTime, newClass, i, businessDateFormat } = props
  const menuButtonRef = useRef(null);

  const handleChangeDateOnlyInTime = (oldDateTime, newDate) => {
    return (new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), oldDateTime.getHours(), oldDateTime.getMinutes(), oldDateTime.getSeconds()))
  }

  const handleSelect = (e) => {
    let startTime = handleChangeDateOnlyInTime(eventStartDateTime, e)
    let endTime = handleChangeDateOnlyInTime(eventEndDateTime, e)
    handleSelectedTimes(e, startTime, endTime)
  }

  const handleTimeChange = (e, name) => {
    let startTime = eventStartDateTime
    let endTime = eventEndDateTime
    if (name == 'startTime') {
      if(e == null) {
        startTime = ''
      }else{
        startTime = handleChangeDateOnlyInTime(e, eventDateTime)
        let newEndTime = new Date(startTime)
        newEndTime.setMinutes(newEndTime.getMinutes() + 45);
        endTime = handleChangeDateOnlyInTime(newEndTime, eventDateTime)
      }
    } else {
      if(e == null) {
        endTime = ''
      }else{
        endTime = handleChangeDateOnlyInTime(e, eventDateTime)
      }
    }
    handleSelectedTimes(eventDateTime, startTime, endTime)
  }

  const handleSelectedTimes = (eventDate, startTime, endTime) => {
    handleSaveDateTime(eventDate, startTime, endTime)
    menuButtonRef.current.click()
  }

  const setRef = node => {
    if (node) {
      $(`.date-time-need-border-${i}`).addClass('filter-dynamic-border')
      let div = $('.special-container-class')
      if(!div.hasClass('pb-12')){
        div.addClass('pb-12')
      }
    }else if (node === null) {
      $(`.date-time-need-border-${i}`).removeClass('filter-dynamic-border')
      let div = $('.special-container-class')
      if(div.hasClass('pb-12')){
        div.removeClass('pb-12')
      }
    }
  };

  const dateToShow = (date) => {
    let day = String(date.getDate())
    let month = String(date.getMonth() + 1)
    let year = String(date.getFullYear()).slice(-2);
    
    return (businessDateFormat == 'dd/mm/yy' ? `${day}/${month}/${year}` : `${month}/${day}/${year}`)
  }

  return(
    <Fragment>
      <div className={`relative filters_box w-full`}> 
        <Menu as="div" className="relative block text-left">
          <div>
            <Menu.Button className={`flex justify-center w-full rounded-2xl pl-4 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 h-11 gap-1 items-center border-2 border-transparent date-time-need-border-${i}`} ref={menuButtonRef}>
              <CalendarIcon classNames={'w-5 h-5'}/>
              <p className="truncate filter-text-field text-left">{dateToShow(eventDateTime)}</p>
              <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            // show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items ref={setRef} className="origin-top-right z-30 right-0 w-full rounded-2xl bg-white shadow-md border bordar-gray-200 focus:outline-none mt-2 absolute">
              <Fragment>
                <div className='flex flex-col'>
                  <div className='pt-2 flex justify-center mb-2 create-sub-request-date-picker'>
                    <DatePicker
                      selected={eventDateTime}
                      onChange={(date) => handleSelect(date)}
                      minDate={newClass?.selectedDateTime?.minDate}
                      maxDate={newClass?.selectedDateTime?.maxDate}
                      excludeDates={newClass?.blackOutDates}
                      inline
                    />
                  </div>
                </div>
              </Fragment>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}