import React, { Fragment, useState, useEffect } from 'react';
import XCircleIcon from './../chatComponents/assets/xCircleIcon'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { Redirect } from "react-router-dom";

export default function CreatePostChatModal(props){
  const { showCreatePostChatModal, closeCreatePostChatModal, permissions, business, user } = props
  const [ selectedType, setSelectedType ] = useState('')
  const [ redirect, setRedirect ] = useState(false)
  const [ redirectAtUrl, setRedirectAtUrl ] = useState('')
  const postMessageType = business?.permanent_class_opening_feature ? [{label: "Compliance", value: 'compliance'}, {label:"Announcement", value: 'announcement'}, {label: "Event", value: 'event'}, {label: "Permanent Class Opening", value: 'permanent_class_opening'}] : [{label: "Compliance", value: 'compliance'}, {label:"Announcement", value: 'announcement'}, {label: "Event", value: 'event'}]
  const groupType = [{label: "Group Chat", value: 'group_chat'}]
  const dmType = [{label: "Direct message", value: 'direct_message'}]
  const postType = (props?.user?.role_display !== "Fitness Instructor" ? 
      (props?.business?.group_chat_feature ?
        (props?.permissions?.business_messages?.write && props.permissions?.business_messages?.groups && props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee" ? 
            [...postMessageType, ...groupType, ...dmType] 
          : 
        props?.permissions?.business_messages?.write && props.permissions?.business_messages?.groups && !props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee" ? 
            [...postMessageType, ...groupType] 
          : 
        props?.permissions?.business_messages?.write && !props.permissions?.business_messages?.groups && props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee" ? 
            [...postMessageType, ...dmType] 
          :
        props?.permissions?.business_messages?.write && !props.permissions?.business_messages?.groups && !props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee" ?
            [...postMessageType]
          :
        !props?.permissions?.business_messages?.write && props.permissions?.business_messages?.groups && props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee" ?
            [...groupType, ...dmType]
          :
        !props?.permissions?.business_messages?.write && !props.permissions?.business_messages?.groups && props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee" ?
            [...dmType]
          :
        !props?.permissions?.business_messages?.write && props.permissions?.business_messages?.groups && !props.permissions?.business_messages?.direct_messages && props.user?.role_display !== "Netgym Employee" ?
            [...groupType]
          :
        [...postMessageType]
        )
        :
        [...postMessageType]
      )
      :
      (props?.business?.group_chat_feature ?
        (this.props?.permissions?.business_messages?.direct_messages_to_instructors || this.props?.permissions?.business_messages?.direct_messages_to_managers) && [...dmType]
        :
        [...dmType]
      )
    )

  useEffect(() =>{
    if(selectedType !== ''){
      setRedirectAtUrl(`/messenger/create-post?type=${selectedType}`)
      setRedirect(true)
    }
  }, [selectedType])

  const closeCreateTypeModal = () => {
    closeCreatePostChatModal(false)
  }

  const checkTypeName = (type) => {
    let classes =  postType;
    let result = classes.filter((item) => {return item?.value.toString() === type})
    return result[0]?.label;
  }

  const handleChangePostType = (e, val) => {
    setSelectedType(val)
  }

  return(
    <React.Fragment>
      {(redirect && redirectAtUrl !== '') && <Redirect push to={redirectAtUrl} />}
      <Transition.Root show={showCreatePostChatModal} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => closeCreateTypeModal(e)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-2xl pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-6">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                    onClick={(e) => closeCreateTypeModal(e)}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon classNames={"h-8 w-8 text-black"} />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900 px-7">
                      Create a message
                    </Dialog.Title>
                    <div className="mt-3">
                      <div className='sm:gap-4 sm:items-start border-t border-gray-200 py-5 px-7 h-80'>
                        <label htmlFor="first-name" className="block text-sm text-gray-500 sm:mt-px pb-2">
                          Type
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="relative"> 
                            <Menu as="div" className="relative block text-left">
                              <div>
                                <Menu.Button className="flex justify-center w-full rounded-xl border-2 border-gray-300 pl-4 pr-2 py-2.5 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                  
                                  {selectedType === '' 
                                  ? 
                                  "Select a message type"
                                  : <p className = "text-gray-900">{checkTypeName(selectedType)}</p>
                                  }
                                  <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="origin-top-right z-10 right-0 w-full rounded-2xl bg-white ring-2 ring-gray-500 focus:outline-none mt-2">
                                  <div className="py-2 max-h-60 overflow-y-auto">
                                    <div>
                                      {postType.map((type, i) => 
                                        <Menu.Item key = {i}>
                                          {({ active }) => (
                                            <a className="flex items-center text-gray-600 text-sm cursor-pointer pl-4 hover:bg-gray-100 hover:text-gray-900 py-1.5 rounded-xl mx-2 font-medium" name="selectedType" onClick = {(e) => handleChangePostType(e, type?.value)}>
                                              {type?.label}
                                            </a>
                                              
                                          )}
                                        </Menu.Item>
                                      )}
                                    </div>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  )
}