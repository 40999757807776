import React, { Fragment, useEffect, useState } from 'react';
import CommentCustomPopupComponent from './../commentCustomPopupComponent'
import { MentionsInput, Mention } from 'react-mentions'
import { StylesViaJss } from "substyle-jss";
import { axiosGetRequest, axiosRequest, saveComment, simplifyStringAndTags, simplifyUserTags } from './../../../helpers/helpers'
import { SendIcon, AtTagIcon } from './../../../assets'

export default function CreateCommentComponent(props){
  const { eventId, show, close, user, handleSetAlert, processFetchEventData, sentBucket } = props

  const [ commentString, setCommentString ] = useState('')
  const [ allTags, setallTags ] = useState([])
  const [ disableCommentBtn, setDisableCommentBtn ] = useState(false)

  useEffect(() => {
    if(eventId && eventId !== ''){
      processFetchAllTags()
    }
  }, [eventId])

  const processFetchAllTags = () => {
    let url = `/api/v3/business_events/${parseInt(eventId)}/fetch_comment_users`
    axiosGetRequest(url).then((response) => {
      if(response?.status === 200){
        let tagsList = []
        tagsList.push({'id': 0, 'display': 'no-response', 'image': '', 'description': '', 'value': '', 'userTag': false})
        let tagsResponse = response?.users.map((item) => {return {'id': item?.id, 'display': item?.full_name, 'image': item?.image, 'description': '', 'value': '', 'userTag': true} })
        tagsList = [...tagsList, ...tagsResponse]
        setallTags(tagsList)
      }else{
        setallTags([])
      }
    })
  }

  const handleCommentChange = (e) => {
    setCommentString(e.target.value)
  }

  const handleSavecomment = (comment) => {
    setDisableCommentBtn(true)
    let commentText = comment.trim() === '@' ? comment.trim() : simplifyStringAndTags(comment.trim())
    let usersId = comment.trim() === '@' ? [] : simplifyUserTags(comment.trim())
    let taged_user = usersId?.length > 0 ? (usersId[usersId?.length-1].toString() !== '0' ? usersId[usersId?.length-1].toString() : '') : ''
    
    let params = {
      business_comment: {
        comment: commentText, 
        business_event_id: `${eventId}`,
        tagged_users: taged_user
      },
      comment: commentText,
      gql_event_details: true,
      bucket_type: sentBucket
    }

    saveComment(params).then((response) => {
      if(!Object.keys(response).includes('error')){
        setDisableCommentBtn(false)
        setCommentString('')
        handleSetAlert(true, "Comment saved successfully", 'success')
        close(false)
        processFetchEventData(response?.event)
      }else{
        setDisableCommentBtn(false)
        handleSetAlert(true, response?.error, 'danger')
      }
      setTimeout(() => {
        handleSetAlert(false, '', '') 
      }, 3000)
    })

  }

  return(
    <Fragment>
      <CommentCustomPopupComponent show={show} close={close} title={''} customWidth={'sm:max-w-md'}>
        <div className='p-3'>
          <div className='flex bg-gray-200 px-3 rounded-2xl items-center gap-2 sub-request-create-comment-container'>
            <div className='w-10 shrink-0'>
              <img className='w-8 h-8 rounded-full' src={user?.image || "/assets/default-profile.jpg"}/>
            </div>
            <div className='w-full'>
              <div id='' className='relative border rounded-md bg-transparent create-comment-popup'>
                <StylesViaJss>
                  <div className="w-full flex-1">
                    <div
                      className="comment-input-container row col-12"
                    >
                      <div className="col-11 px-0">
                        <MentionsInput
                          value={commentString}
                          onChange={(e) => handleCommentChange(e)}
                          className="mentions"
                          // classNames={Style}
                          allowSpaceInQuery={true}
                          placeholder="Write a comment"
                          allowSuggestionsAboveCursor={true}
                          customSuggestionsContainer={(data) => (
                            <React.Fragment>
                              {data?.props?.children?.map((item, i) =>
                                <li id={item.props.id} role="option" aria-selected="true" className={`example-module__mentions__suggestions__item___3qw61 css-7-0-1-8 flex gap-2 cursor-pointer ${item.props.focused ? 'example-module__mentions__suggestions__item--focused___3JpKM' : ''}`} key={i} onClick={item.props.onClick}>
                                  <div className='w-6 h-6 bg-gray-100 rounded flex items-center justify-center'>
                                    {!item.props.suggestion.userTag ?
                                      <AtTagIcon classNames={'font-bold h-5 w-5'}/>
                                    :
                                      (item.props.suggestion?.image === '' ? <p className='text-xs'>{`AB`}</p> : <img className="rounded w-6 h-6" src={item.props.suggestion?.image} />)
                                    }
                                  </div>
                                  <div className='flex items-center ml-2'>
                                    <div className='text-gray-900 font-bold flex items-center css-2-0-1-3'>
                                      <AtTagIcon classNames={'font-bold h-4 w-4'} />
                                      <div className="text-sm">{item.props.suggestion?.display}</div>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </React.Fragment>
                          )}
                        >
                          <Mention 
                            data={allTags} 
                            className='mentions__mention'
                            appendSpaceOnAdd={true}
                            displayTransform={(id, display) => `@${display}:`}
                            markup='@[__display__](email:__id__):'
                          />
                        </MentionsInput>
                      </div>
                    </div>
                  </div>
                </StylesViaJss>
              </div>
            </div>
            <button className={`w-9 h-9 shrink-0 rounded-full flex items-center justify-center bg-white shadow ${(disableCommentBtn || commentString == '') ? 'cursor-not-allowed opacity-50' : ''}`} onClick={() => handleSavecomment(commentString)} disabled={disableCommentBtn || commentString == ''}>
              <SendIcon classNames={'w-5 h-5 transform -rotate-45 mb-0.5 ml-0.5'}/>
            </button>
          </div>
        </div>
      </CommentCustomPopupComponent>
    </Fragment>
  )
}