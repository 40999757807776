import React, { Fragment, useState } from "react";
import CustomPopupContainerComponent from '../../../../../../../subRequestDetails/components/customPopupContainerComponent';
import StatusFilterComponent from '../../statusFilterComponent';
import { handleSaveMyRequestFilters, seprateOutLocations } from '../../../../../../helpers/helpers';
import LocationFilterComponent from '../locationFilterComponent';

export default function AvailableFilterModalComponent(props) {
  const { showFilterModal, handleCloseModal, business, user, pendingCount, acceptCount, declineCount, statusValues, processApplyFilter, statusList, regionLocationsList, showRegions, selectedLocations, selectedRegions } = props
  const [ selectedStatus, setSelectedStatus ] = useState(statusValues || [])
  const [ selectedFilterLocations, setSelectedFilterLocations ] = useState(selectedLocations || [])
  const [ selectedFilterRegions, setSelectedFilterRegions ] = useState(selectedRegions || [])

  const handleApplyFilter = () => {
    let statusState = (selectedStatus.length === statusList.length ? 'all_selected' : selectedStatus?.length > 0 ? 'some_selected' : 'no_selected')
    let regions = regionLocationsList.map(function(a) {return a.region?.name;});
    let locationState = (regions.every(v => selectedFilterRegions.includes(v)) && regionLocationsList?.length > 0) ? 'all_selected' : selectedFilterLocations?.length > 0 ? 'some_selected' : 'no_selected'
    let data = {
      selectedStatus: selectedStatus,
      statusState: statusState,
      locations: selectedFilterLocations,
      regions: selectedFilterRegions,
      locationsState: locationState,
    }
    handleSaveMyRequestFilters(data, true, user?.id, 'available')
    processApplyFilter(data)
  }

  const hanldleSelectFilterLocations = (newRegs, newLocs) => {
    setSelectedFilterRegions(newRegs)
    setSelectedFilterLocations(newLocs)
  }

  const handleResetFilter = () => {
    let regions = regionLocationsList.map(function(a) {return a.region?.name;});
    let locations = seprateOutLocations(regionLocationsList).map(function(a) {return a.id;});
    let defaultStatusValues = statusList.map((item) => item.value)
    setSelectedFilterRegions(regions)
    setSelectedFilterLocations(locations)
    setSelectedStatus(defaultStatusValues)
    let data = {
      selectedStatus: defaultStatusValues,
      statusState: 'all_selected',
      locations: locations,
      regions: regions,
      locationsState: 'all_selected'
    }
    handleSaveMyRequestFilters(data, true, user?.id, 'available')
    processApplyFilter(data)
  }

  const handleProcessCloseModal = (data) => {
    setSelectedStatus(statusValues || [])
    handleCloseModal(data)
  }

  return(
    <Fragment>
      <CustomPopupContainerComponent show={showFilterModal} close={handleProcessCloseModal} title={''} customWidth={'sm:max-w-md'}>
        <div className="w-full px-8 pt-3 font-open-sans">
          <div className="text-center w-full text-gray-900 font-bold text-2xl">Filter Available</div>
          <StatusFilterComponent business={business} managerCount={0} pendingCount={pendingCount} acceptedCount={acceptCount} approvedCount={declineCount} reopenCount={0} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} statusList={statusList} bucketType={'available'}/>
          <LocationFilterComponent regionLocationsList={regionLocationsList || []} selectedFilterLocations={selectedFilterLocations} hanldleSelectFilterLocations={hanldleSelectFilterLocations} selectedFilterRegions={selectedFilterRegions} showRegions={showRegions || true}/>
          <div className="flex items-center pt-4 pb-2">
            <div className="flex justify-center items-center w-full gap-3">
              <button className="w-full bg-white text-black text-center text-sm font-semibold rounded-2xl px-5 py-3 border border-gray-300 shadow-md hover:bg-gray-100" onClick={() => handleResetFilter()}>
                Reset
              </button>
              <button className='w-full bg-dark-blue text-white text-center text-sm font-semibold rounded-2xl px-5 py-3 border border-gray-900 shadow-md hover:bg-gray-700' onClick={(e) => handleApplyFilter()}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  )
}