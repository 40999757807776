import React, { Fragment } from "react";
import ReactTooltip from "react-tooltip";
import { InfoIcon } from "../../../subRequestDetails/assets";

export default function TooltipComponent(props) {
  const { componentId, classNames, tooltipMessage } = props
  return(
    <Fragment>
      <div data-tip data-for={componentId}>
        <InfoIcon classNames={classNames}/>
      </div>
      <ReactTooltip className='max-w-xs' id={componentId} place="top" effect="solid">
        {tooltipMessage}
      </ReactTooltip>
    </Fragment>
  )
}