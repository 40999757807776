import React, { Fragment, Item } from 'react'
import { Button } from 'react-bootstrap';
import { Accordion, Card } from "react-bootstrap";
import screenfull from 'screenfull'
import { findDOMNode } from 'react-dom'
import { getSudomain, setTitle } from './../helpers.js'
import ReactPlayer from 'react-player'
import { showFile, getResourceData, saveComment, saveFile, saveReactions, removeExtensionFromName, snakeCase, getName } from './helpers.js'
import Cookies from 'js-cookie';
import { Link, Redirect } from "react-router-dom";
import ReactTooltip from "react-tooltip";
export default class ViewResourceDetail extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      url: null,
      pip: false,
      playing: false,
      controls: false,
      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      progresBarWidth: "0%",
      runningTime: "",
      durationTime: "",
      allData: [],
      alert_message: '',
      alert_type: '', 
      show: false,           
      loadedData: false,
      fileToRun: '',
      itemToView: {},
      folderPathNames: [],
      currentPath: '',
      comentText: '',
      FileNotFound: false,
      itemNotification: false,
      reactions: {
        emoji_clicked: "",
        clap_count: null,
        fist_count: null,
        heart_count: null
      },
      showVideoButton: false,
      showEdit: false,
      showView: false
    }
  }

  componentDidMount = () => {
    this.checkAvailability()
		this.props.setCurrent('Resource Hub')
    if(this.props.pageLoaded){
      //this.getAll()
      this.getFileId()
		}
    setTitle('resource_hub')
  }

  checkAvailability = () => { 
    const { business, user } = this.props   
    if (Object.keys(business).length != 0) {
      if(business?.resource_hub === false){
        let role = snakeCase(user?.role)        
        if(role){
          let url = `/business_user#/${getName(role)}_home`
          window.location.href = url
        }        
      }
    }
  }

  getFileId = () => {
    let filePath = location.pathname
    let files = filePath?.split('/')
    let file = files.pop()
    let currentPath = files.join('/')
    let folderPathName = []
    for(let i = 3; i < files?.length-1; i++){
      folderPathName.push(files[i])
    }
    let folder_path = folderPathName.join('/')
    this.setState({
      folderPathNames: folderPathName,
      currentPath: currentPath,
      fileToRun: parseInt(file)
    })
    getResourceData(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), file, folder_path).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
					itemToView: result.link,
          itemNotification: result.link?.notifications,
          reactions: result.link?.reactions,
          allData: result.resource_data,
					loadedData: true,
          FileNotFound: false,
          showEdit: result.show_edit,
          showView: result.show_view
				})
        $(document).scrollTop(0,0);
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
					// loadedData: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false, FileNotFound: true})
          },7000)
        });
			}
		})
  }

  getAll = () => {

		showFile(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
					count: result.count,
					allData: result.link,
					loadedData: true
				})
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
					loadedData: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  load = url => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false
    })
  }

  handleDuration = (duration) => {
    //console.log('onDuration', duration)
    let durationTime = this.secondsToHms(Math.round(duration))
    this.setState({ 
      duration,
      durationTime: durationTime 
    })
  }

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
  }

  handleStop = () => {
    this.setState({ url: null, playing: false })
  }

  handleToggleControls = () => {
    const url = this.state.url
    this.setState({
      controls: !this.state.controls,
      url: null
    }, () => this.load(url))
  }

  handleToggleLight = () => {
    this.setState({ light: !this.state.light })
  }

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop })
  }

  handleVolumeChange = e => {
    this.setState({ volume: parseFloat(e.target.value) })
  }

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted })
  }

  handleSetPlaybackRate = e => {
    this.setState({ playbackRate: parseFloat(e.target.value) })
  }

  handleOnPlaybackRateChange = (speed) => {
    this.setState({ playbackRate: parseFloat(speed) })
  }

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip })
  }

  handlePlay = () => {
    //console.log('onPlay')
    this.setState({ playing: true })
  }

  handleEnablePIP = () => {
    //console.log('onEnablePIP')
    this.setState({ pip: true })
  }

  handleDisablePIP = () => {
    //console.log('onDisablePIP')
    this.setState({ pip: false })
  }

  handlePause = () => {
    //console.log('onPause')
    this.setState({ playing: false })
  }

  handleSeekMouseDown = e => {
    this.setState({ seeking: true })
  }

  handleSeekChange = e => {
    //console.log(e.target.value)
    this.setState({ played: parseFloat(e.target.value) })
  }

  handleSeekBackward = () => {
    let played = this.state.played
    let duration = this.state.duration
    let total = played - ((1/ parseInt(duration, 10))*5)
    this.setState({ played: parseFloat(total) })
  }

  handleBackwardMouseUp = () => {
    this.setState({ seeking: false })

    let played = this.state.played
    let duration = this.state.duration
    let total = played - ((1/ parseInt(duration, 10))*5)

    this.player.seekTo(parseFloat(total))

  }

  handleSeekForward = () => {
    let played = this.state.played
    let duration = this.state.duration
    let total = played + ((1/ parseInt(duration, 10))*5)
    this.setState({ played: parseFloat(total) })
  }

  handleForwardMouseUp = () => {
    this.setState({ seeking: false })

    let played = this.state.played
    let duration = this.state.duration
    let total = played + ((1/ parseInt(duration, 10))*5)

    this.player.seekTo(parseFloat(total))

  }

  handleSeekMouseUp = e => {
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e.target.value))
  }

  secondsToHms(value) {
    const sec = parseInt(value, 10); 
    let hours = Math.floor(sec / 3600); 
    let minutes = Math.floor((sec - hours * 3600) / 60); 
    let seconds = sec - hours * 3600 - minutes * 60;
    if (hours < 10) {      hours = '0' + hours;    }
    if (minutes < 10) {      minutes = '0' + minutes;    }
    if (seconds < 10) {      seconds = '0' + seconds;    }
    if (hours == 0) {
      return +minutes + ':' + seconds; // Return in MM:SS format
    } else {
      return hours + ':' + minutes + ':' + seconds; // Return in HH:MM:SS format
    }
  }

  handleProgress = state => {
    //console.log('onProgress', state)
    //console.log(this.secondsToHms(Math.round(state.playedSeconds)))
    let width = (Math.round((state.playedSeconds/this.state.duration)*100)).toString()+"%"
    let startTime = this.secondsToHms(Math.round(state.playedSeconds))
    this.setState({
      progresBarWidth: width,
      runningTime: startTime
    })
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state)
    }
  }

  handleEnded = () => {
    //console.log('onEnded')
    this.setState({ playing: this.state.loop })
  }

  handleClickFullscreen = () => {
    const element = document.getElementById('target');
    screenfull.request(findDOMNode(element))
    if(screenfull.isFullscreen){
      screenfull.toggle(findDOMNode(element))
    }
  }

  renderLoadButton = (url, label) => {
    return (
      <button onClick={() => this.load(url)}>
        {label}
      </button>
    )
  }

  ref = player => {
    this.player = player
  }

  onError = e => {
    console.log(e, "error in file-viewer");
  };

  toggleItems = (type) => {
    let allData = this.state.allData
    let newItemIndex = null
    let current_file = this.state.fileToRun
    for(let i = 0; i <= allData?.length-1; i++){
      if(allData[i].id === current_file){
        if(type === 'prev'){
          if(i === 0){
            newItemIndex = allData?.length-1
          }
          else{
            newItemIndex = i-1
          }
        }else if (type === 'next'){
          if(i === allData?.length-1){
            newItemIndex = 0
          }
          else{
            newItemIndex = i+1
          }
        }
      }
    }

    let file = allData[newItemIndex]?.id
    return file

    // getResourceData(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), file, "").then(      
		// 	response => response.json()
		// )
		// .then(result => {
		// 	if(result.status === 200){
		// 		this.setState({
		// 			itemToView: result.link,
    //       allData: result.resource_data,
		// 			loadedData: true
		// 		})
		// 	}else{
		// 		this.setState({
		// 			alert_message: result.error,
		// 			alert_type: 'danger', 
		// 			show: true,           
		// 			loadedData: true
		// 		})
		// 		this.setState({show:true},()=>{
    //       window.setTimeout(()=>{
    //         this.setState({show:false})
    //       },3000)
    //     });
		// 	}
		// })

    // this.setState({
    //   fileToRun: parseInt(file)
    // })
    
  }

  chooseIconName = (type) => {
		let checkType = type?.split('/')[0]
		switch(checkType){
			case 'document' :
				let checkExtention = type?.split('/')[1]
				switch(checkExtention){
					case 'docx' :
						return ('docx')
					case 'pdf' :
						return ('docx')
					case 'ppt' :
						return ('ppt')
					case 'pptx' :
						return ('ppt')
					case 'xcl' :
						return ('xcl')
					case 'csv' :
						return ('xcl')
					default:
						return ('docx')
				}
			case 'image':
				return('img')
			case 'video':
				return('video')
			case 'audio':
				return('aiff')
			default:
				return('')
		}
	}

	chooseIcon = (type) => {
		let checkType = type?.split('/')[0]
		switch(checkType){
			case 'document' :
				let checkExtention = type?.split('/')[1]
				switch(checkExtention){
					case 'docx' :
						return (
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
							</svg>
						)
					case 'doc' :
						return (
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
							</svg>
						)
					case 'pdf' :
						return (
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
							</svg>
						)
					case 'ppt' :
						return(
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clipRule="evenodd" />
							</svg>
						)
					case 'pptx' :
						return(
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clipRule="evenodd" />
							</svg>
						)
					case 'xls' :
						return(
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clipRule="evenodd" />
							</svg>
						)
					case 'xlsx' :
						return(
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clipRule="evenodd" />
							</svg>
						)
					case 'csv' :
						return(
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z" clipRule="evenodd" />
							</svg>
						)
					default:
						return (
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
							</svg>
						)
				}
				
			case 'aiff':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z" />
					</svg>
				)
			case 'image':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
					</svg>
				)
			case 'video':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
					</svg>
				)
			case 'audio':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z" />
					</svg>
				)
			case 'ppt':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clipRule="evenodd" />
					</svg>
				)
			case 'xcl':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path fillRule="evenodd" d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z" clipRule="evenodd" />
					</svg>
				)
			default:
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
					</svg>
				)
		}
	}

  checkPrevUrl = (index) => {
    let folderPathName = this.state.folderPathNames
    let path = []
    for(let i = 0; i < folderPathName?.length; i++){
      if(i <= index){
        path.push(folderPathName[i])
      }
    }
    return path.join('/')
  }

  countNameCharectors = (name) => {
		let shortName = []
		for(let i = 0; i < name?.length; i++){
			if(i < name?.length){
				shortName.push(name[i])
			}
		}
	
		return shortName.join('')
	}

  countBreadCrumbsNameCharectors = (name) => {
		let shortName = []
		for(let i = 0; i < name?.length; i++){
			if(i < 36){
				shortName.push(name[i])
			}else{
				shortName.push('...')
				break
			}
		}
	
		return shortName.join('').replace(/%20|%2|%/gi, ' ')
	}

  countBreadCrumbsFileNameCharectors = (name) => {
		let shortName = []
		for(let i = 0; i < name?.length; i++){
			if(i < name?.length){
				shortName.push(name[i])
			}
		}
	
		return shortName.join('').replace(/%20|%2|%/gi, ' ')
	}

  onChange = (e) => {
    this.setState({comentText: e.target.value})
  }

  onKeyDown = (e) => {
    if (e.key === "Enter"){
      e.target.blur();
      let fileId = this.state.itemToView?.id
      let folder_path = this.state.folderPathNames?.join('/')
      saveComment(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), fileId, folder_path, e.target.value).then(      
        response => response.json()
      )
      .then(result => {
        if(result.status === 200){
          this.setState({
            alert_message: result.message,
            alert_type: 'success', 
            show: true,
            comentText: '',
          	itemToView: result.link,
            // allData: result.resource_data,
          })
          this.setState({show:true},()=>{
            window.setTimeout(()=>{
              this.setState({show:false})
            },3000)
          });
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger', 
            show: true,           
          })
          this.setState({show:true},()=>{
            window.setTimeout(()=>{
              this.setState({show:false})
            },3000)
          });
        }
      })
    } else if ( e.key === "Escape") {
      e.target.blur();
    }
  };

  onBlur = (e) => {
    if (e.target.value.trim() === "") {
      this.setState({comentText: e.target.value})
    } else {
      this.setState({comentText: e.target.value})
    }
  };

  throwBackUrl = () => {
    let folderPathNames = this.state.folderPathNames
    if(folderPathNames && folderPathNames?.length > 0){
      return '/resource_hub/folder/'+folderPathNames.join('/')
    } else {
      return '/resource_hub'
    }
  }

  handleNotification = (e) => { 
    this.setState({itemNotification: !this.state.itemNotification})
    this.processPublishFile(e, !this.state.itemNotification)
  }

  processPublishFile = (e, notification) => {
		e.preventDefault();
		let itemToUpdate = this.state.itemToView
		let updatedName = itemToUpdate?.name 
    let skillTags = []
    let locationTags = []
    for(let i = 0; i < itemToUpdate?.skill_tags?.length; i++){
      skillTags.push(itemToUpdate?.skill_tags[i]?.id)
    }
    for(let j = 0; j < itemToUpdate?.location_tags?.length; j++){
      locationTags.push(itemToUpdate?.location_tags[j]?.id)
    }
		let data = {
			'id': itemToUpdate?.id,
			'name': updatedName,
			'location_tags': locationTags,
			'skill_tags': skillTags,
			'visitors': itemToUpdate?.visitors,
			'drafted': itemToUpdate?.drafted,
      'comment': itemToUpdate?.comments_show,
      'notification': notification
		}
		let folder = this.state.folderPathNames.join('/')
		saveFile(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), itemToUpdate?.id, folder, data).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
					alert_message: 'Notification turned '+(notification ? 'On' : 'Off'),
					alert_type: 'success', 
					show: true,
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
        this.getFileId()
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
	}

  addHttps = (url) => {
    if(!url.includes('https')){
      return url.replace('http', 'https')
    }else{
      return url
    }   
  }

  addReaction = (e, type) => {
    let reactions = this.state.reactions
    reactions.emoji_clicked = type
    // reactions.clap_count = null
    // reactions.fist_count = null
    // reactions.heart_count = null
    this.setState({
      reactions: reactions
    })
    this.processSaveReactions(e, type)
  }

  processSaveReactions = (e, reaction) => {
    e.preventDefault();
    let itemToView = this.state.itemToView
    let folder = this.state.folderPathNames.join('/')
    saveReactions(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), itemToView?.id, folder, reaction).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
          reactions: result.data,
				})
        // this.getFileId()
			}
		})
  }

  loadedVideoPercentage = (loaded) => {
    let percentValue = Math.round(loaded *100)
    return (percentValue.toString()+'%')
  }

  // checkReactionText = (reaction, count, type) => {
  //   // reaction = true/false
  //   // count = 0,1,2,3,...
  //   // type = 'clap', 'fist', 'heart'
  //   let returnString = ''
  //   if(count > 0){
  //     if(count === 1){
  //       if( reaction === type ){
  //         returnString = 'You'
  //       }else {
  //         returnString = count.toString()
  //       }
  //     } else if (count === 2){
  //       if( reaction === type ){
  //         returnString = `You and ${count - 1} other`
  //       }else {
  //         returnString = count.toString()
  //       }
  //     } else if (count > 2){
  //       if( reaction === type ){
  //         returnString = `You and ${count - 1} others`
  //       }else {
  //         returnString = count.toString()
  //       }
  //     }

  //   } else {
  //     returnString = ''
  //   }

  //   return returnString
  // }

  changeMouseIn = (x) => {
    // x.target.style.backgroundImage = 'linear-gradient(to right, #4639a7, #78019c)';
    this.setState({showVideoButton: true})
  };

  changeMouseOut = (x) => {
    // x.target.style.background = 'rgb(37, 45, 73)';
    this.setState({showVideoButton:false}, ()=>{
      window.setTimeout(()=>{
        this.setState({})
      },3000)
    });
  };

  whereIsMouse = () => {
    let showVideoButton = this.state.showVideoButton
    if(showVideoButton){
      return showVideoButton
    }else{
      return showVideoButton
    }
  }

  render(){
    const { url, playing, controls, light, volume, muted, loop, played, loadedData, duration, playbackRate, pip, progresBarWidth, runningTime, durationTime, itemIndex, allData, show, alert_type, alert_message, fileToRun, itemToView, folderPathNames, currentPath, comentText, FileNotFound, itemNotification, loaded, reactions, showVideoButton, showEdit, showView } = this.state
    const { user, business, permissions, loadingMessage } = this.props
    return(
      <React.Fragment>
        {FileNotFound &&
          <Redirect to={`${this.throwBackUrl()}`} />
        }
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
        <div className="w-full min-h-screen bg-gray-50">
          {!loadedData &&
            <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
              <p className="mb-1  text-center px-5">{loadingMessage}</p>
              <img className = "w-9" src="/assets/business/loading.gif" />
            </div>
          }
          {loadedData &&
            <div className="pt-16 md:pt-8 h-full">
              <main className="h-full">
                <div>
                  <div className="mx-auto grid grid-cols-1 px-6 lg:grid-flow-col-dense">
                    <div className="t-breadcrumbs">
                      <nav className="flex" aria-label="Breadcrumb">
                        <ol role="list" className="flex items-center flex-wrap">
                          <li>
                            <div className="flex items-center">
                              <Link to="/resource_hub" className="md:text-xl text-sm font-medium text-gray-500 hover:text-gray-700">Resource hub</Link>
                            </div>
                          </li>

                          {folderPathNames && folderPathNames.map((name, i) =>
                            <li key = {i}>
                              <div className="flex items-center" key = {i}>
                                <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                </svg>
                                <Link  to = {`/resource_hub/folder/${this.checkPrevUrl(i)}`} className="md:text-xl text-sm font-medium text-gray-500 hover:text-gray-700" aria-current="page">{this.countBreadCrumbsNameCharectors(name)}</Link>
                              </div>
                            </li> 
                          )}
                          <li>
                            <div className="flex items-start">
                              <svg className="flex-shrink-0 h-5 w-5 text-gray-400 mt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                              </svg>
                              <a className="md:text-xl text-sm font-medium text-gray-700 hover:text-gray-700 break-all" aria-current="page">{this.countBreadCrumbsFileNameCharectors(removeExtensionFromName(itemToView?.name))}</a>
                            </div>
                          </li>
                        </ol>
                      </nav>
                    </div>
                    {allData?.length > 0 &&
                      <div className="prev-next flex items-center gap-2 mt-3 lg:mt-0">
                        <Link to = {`${currentPath}/${this.toggleItems('prev')}`} className="bg-white shadow rounded w-8 h-8 flex items-center justify-center cursor-pointer">
                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-900 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                          </svg>
                        </Link>
                        <Link to = {`${currentPath}/${this.toggleItems('next')}`} className="bg-white shadow rounded w-8 h-8 flex items-center justify-center cursor-pointer">
                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-900 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                          </svg>
                        </Link>
                      </div>
                    }
                  </div>
                  {itemToView && 
                    <div>
                      { itemToView.id === fileToRun && 
                        <div className="mt-4 mx-auto grid grid-cols-1 px-6 gap-6 lg:grid-flow-col-dense lg:grid-cols-3 pb-10">
                          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                            {itemToView.file_type?.split('/')[0] === 'image' &&
                              <div className="detail-img flex items-center justify-center">
                                <img src={itemToView.link} />
                              </div>
                            }
                            {itemToView.file_type?.split('/')[0] === 'document' &&
                              <div>
                                {['pdf'].includes(itemToView.file_type?.split('/')[1]) &&
                                  <React.Fragment>
                                    <iframe className='hidden md:block' src={`${this.addHttps(itemToView.link)}`+"#toolbar=0"} width='100%' height='550px' frameBorder='0'> </iframe> 
                                    <embed className='block md:hidden' src={`${this.addHttps(itemToView.link)}`} type="application/pdf" style={{width:"100%", height:"100%"}}></embed>
                                    <div className="block md:hidden mt-2">
                                      <a href={`${this.addHttps(itemToView.link)}`} target="_blank" className="bg-gray-900 inline-flex items-center px-4 py-2 border shadow rounded-md">
                                        <div className="text-white text-sm ml-1.5">View</div>
                                      </a>
                                    </div>
                                  </React.Fragment>
                                }
                                {['xlsx', 'xls', 'pptx', 'ppt', 'doc', 'docx'].includes(itemToView.file_type?.split('/')[1]) &&
                                  <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${this.addHttps(itemToView.link)}`+"#toolbar=0"} width='100%' height='550px' frameBorder='0'> </iframe>
                                }
                                {['csv'].includes(itemToView.file_type?.split('/')[1]) &&
                                  <iframe src={`https://docs.google.com/viewer?embedded=true&url=${itemToView.link}`+"#toolbar=0"} width='100%' height='550px' frameBorder='0'> </iframe>
                                }
                                {/* 
                                {['xlsx', 'xls'].includes(itemToView.file_type?.split('/')[1]) &&
                                  <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${itemToView.link}`+"#toolbar=0"} width='100%' height='550px' frameBorder='0'> </iframe>
                                } */}
                              </div>
                            }
                            {(itemToView.file_type?.split('/')[0] === 'video' || itemToView.file_type?.split('/')[0] === 'audio') &&
                              <div id='target' className = "media-bg rounded-md relative player-wrapper" onMouseEnter={(e) => this.changeMouseIn(e)} onMouseLeave={(e) => this.changeMouseOut(e)}>
                                <ReactPlayer
                                  className='react-player'
                                  ref = {this.ref}
                                  url = {itemToView.link}
                                  playing = {playing}
                                  width= '100%'
                                  height='100%'
                                  pip={pip}
                                  
                                  controls={controls}
                                  light={light}
                                  loop={loop}
                                  playbackRate={playbackRate}
                                  volume={volume}
                                  muted={muted}
                                  onReady={() => console.log('onReady')}
                                  onStart={() => console.log('onStart')}
                                  onPlay={this.handlePlay}
                                  onEnablePIP={this.handleEnablePIP}
                                  onDisablePIP={this.handleDisablePIP}
                                  onPause={this.handlePause}
                                  onBuffer={() => console.log('onBuffer')}
                                  
                                  onSeek={e => console.log('onSeek', e)}
                                  onEnded={this.handleEnded}
                                  onError={e => console.log('onError', e)}
                                  onProgress={this.handleProgress}
                                  onDuration={this.handleDuration}
                                />
                                {itemToView.file_type?.split('/')[0] === 'video' &&
                                  <div className="video-b">
                                    <div className="flex items-center w-full absolute bottom-6">
                                      <div className="flex items-center">
                                        <Button onClick={(e) => this.setState({playing: true})}> 
                                          <svg xmlns="http://www.w3.org/2000/svg" className="hidden rewind h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M8.445 14.832A1 1 0 0010 14v-2.798l5.445 3.63A1 1 0 0017 14V6a1 1 0 00-1.555-.832L10 8.798V6a1 1 0 00-1.555-.832l-6 4a1 1 0 000 1.664l6 4z" />
                                          </svg>
                                        </Button>
                                        {playing && this.whereIsMouse() && 
                                        <Button className="ml-5" onClick={(e) => this.setState({playing: false})}>
                                          <svg xmlns="http://www.w3.org/2000/svg" className="text-white h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                                          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                          </svg>
                                        </Button>
                                        }
                                        <Button onClick={(e) => this.setState({playing: true})}> 
                                          <svg xmlns="http://www.w3.org/2000/svg" className="hidden forward h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M4.555 5.168A1 1 0 003 6v8a1 1 0 001.555.832L10 11.202V14a1 1 0 001.555.832l6-4a1 1 0 000-1.664l-6-4A1 1 0 0010 6v2.798l-5.445-3.63z" />
                                          </svg>
                                        </Button>
                                      </div>
                                      <div className="flex w-full ml-4 mr-6">
                                        {playing && this.whereIsMouse() &&
                                          <div className="w-full">
                                            <div className="hidden absolute left-0 -ml-12 -mt-2 cool-gray-300">{runningTime}</div>
                                            <div className="hidden absolute right-0 -mr-12 -mt-2 cool-gray-300">{durationTime}</div>
                                            <div className="relative pt-1">
                                              <input
                                                className="custom-progressbar cursor-pointer"
                                                type='range' min={0} max={0.999999} step='any'
                                                value={played}
                                                onMouseDown={this.handleSeekMouseDown}
                                                onChange={this.handleSeekChange}
                                                onMouseUp={this.handleSeekMouseUp}
                                              />
                                              <div>
                                                <div className="overflow-hidden h-1 text-xs flex rounded bg-gray-400">
                                                </div>
                                                <div
                                                  style={{width: `${this.loadedVideoPercentage(loaded)}`}}
                                                  className="
                                                    h-1
                                                    -mt-1
                                                    shadow-none
                                                    flex flex-col
                                                    text-center
                                                    whitespace-nowrap
                                                    text-gray-800
                                                    justify-center
                                                    bg-gray-500
                                                  "
                                                ></div>
                                                <div
                                                  style={{width: `${progresBarWidth}`}}
                                                  className="
                                                    h-1
                                                    -mt-1
                                                    shadow-none
                                                    flex flex-col
                                                    text-center
                                                    whitespace-nowrap
                                                    text-white
                                                    justify-center
                                                    bg-white
                                                  "
                                                ></div>
                                                <div style={{left: `${progresBarWidth}`}} className={`cursor-pointer absolute w-3 h-3 bg-white rounded-full -mt-2`}>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        }
                                      </div>
                                      <div>
                                        {playing && this.whereIsMouse() &&
                                          <div className="mr-5">
                                          {!screenfull.isFullscreen &&
                                            <img className="w-8 cursor-pointer" src="/assets/icon_fullscreen.png" onClick={() => this.handleClickFullscreen()}/>
                                          }
                                          {screenfull.isFullscreen &&
                                            <img className="w-8 cursor-pointer" src="/assets/icon_exitscreen.png" onClick={() => this.handleClickFullscreen()}/>
                                          }
                                          </div>
                                        }
                                      </div>
                                    </div>
                                    {playing && this.whereIsMouse() &&
                                      <div className='absolute md:left-20 left-16 bottom-3'>
                                        <div className='flex '>
                                          <input type = "range" min={0} max={1} step='any' value={volume} onChange={this.handleVolumeChange}/>
                                          <div className='text-gray-300 text-xs font-light ml-1.5'>
                                          {runningTime} / {durationTime} 
                                          </div>
                                        </div>
                                          
                                      </div>
                                    }
                                    {!playing &&
                                      <React.Fragment>
                                        <div className="absolute left-0 bottom-6 flex items-center justify-center z-10">
                                          <Button className="ml-5" onClick={(e) => this.setState({playing: true})}> 
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-white h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
                                            </svg>
                                          </Button>
                                        </div>
                                        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
                                          <Button className="ml-5" onClick={(e) => this.setState({playing: true})}> 
                                            <div className='flex items-center justify-center h-16 w-16 rounded-full bg-black'>
                                              <img src="/assets/play-button-arrowhead.png" className="h-5 w-5"/>
                                            </div>
                                          </Button>
                                        </div>
                                      </React.Fragment>  
                                    }  
                                  </div>
                                }
                                {itemToView.file_type?.split('/')[0] === 'audio' &&
                                  <div className="audio-b">
                                    <div className='flex'>
                                      <div className='invisible md:visible absolute md:left-19 xl:left-20 lg:left-18 left-8 bottom-14 z-10'>
                                        <div className='flex '>
                                          <input type = "range" min={0} max={1} step='any' value={volume} onChange={this.handleVolumeChange}/>
                                        </div> 
                                      </div>
                                      <div className="flex items-center justify-center absolute bottom-6 left-0 right-0">
                                        <Button onClick={(e) => this.handleSeekBackward()} onMouseDown={this.handleSeekMouseDown} onMouseUp={this.handleBackwardMouseUp}> 
                                          <svg xmlns="http://www.w3.org/2000/svg" className="rewind h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M8.445 14.832A1 1 0 0010 14v-2.798l5.445 3.63A1 1 0 0017 14V6a1 1 0 00-1.555-.832L10 8.798V6a1 1 0 00-1.555-.832l-6 4a1 1 0 000 1.664l6 4z" />
                                          </svg>
                                        </Button>
                                        {!playing &&
                                        <Button onClick={(e) => this.setState({playing: true})}> 
                                          <svg xmlns="http://www.w3.org/2000/svg" className="text-white h-20 w-20" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
                                          </svg>
                                        </Button>
                                        }
                                        {playing && 
                                        <Button onClick={(e) => this.setState({playing: false})}>
                                          <svg xmlns="http://www.w3.org/2000/svg" className="text-white h-20 w-20" viewBox="0 0 20 20" fill="currentColor">
                                          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                          </svg>
                                        </Button>
                                        }
                                        <Button onClick={(e) => this.handleSeekForward()} onMouseDown={this.handleSeekMouseDown} onMouseUp={this.handleForwardMouseUp}> 
                                          <svg xmlns="http://www.w3.org/2000/svg" className="forward h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M4.555 5.168A1 1 0 003 6v8a1 1 0 001.555.832L10 11.202V14a1 1 0 001.555.832l6-4a1 1 0 000-1.664l-6-4A1 1 0 0010 6v2.798l-5.445-3.63z" />
                                          </svg>
                                        </Button>
                                      </div>
                                    </div>
                                    <div className="flex">
                                      <div className="absolute sm:w-4/5 w-1/2 left-0 right-0 ml-auto mr-auto top-56">
                                        <div className="absolute left-0 -ml-12 -mt-2 cool-gray-300">{runningTime}</div>
                                        <div className="absolute right-0 -mr-12 -mt-2 cool-gray-300">{durationTime}</div>
                                        <div className="relative pt-1">
                                            <input
                                              className="custom-progressbar cursor-pointer"
                                              type='range' min={0} max={0.999999} step='any'
                                              value={played}
                                              onMouseDown={this.handleSeekMouseDown}
                                              onChange={this.handleSeekChange}
                                              onMouseUp={this.handleSeekMouseUp}
                                            />
                                          <div>
                                            <div className="overflow-hidden h-1 text-xs flex rounded bg-gray-400">
                                            </div>
                                            <div
                                              style={{width: `${this.loadedVideoPercentage(loaded)}`}}
                                              className="
                                                h-1
                                                -mt-1
                                                shadow-none
                                                flex flex-col
                                                text-center
                                                whitespace-nowrap
                                                text-gray-800
                                                justify-center
                                                bg-gray-500
                                              "
                                            ></div>
                                            <div
                                              style={{width: `${progresBarWidth}`}}
                                              className="
                                                h-1
                                                -mt-1
                                                shadow-none
                                                flex flex-col
                                                text-center
                                                whitespace-nowrap
                                                text-white
                                                justify-center
                                                bg-white
                                              "
                                            ></div>
                                            <div style={{left: `${progresBarWidth}`}} className={`cursor-pointer absolute w-3 h-3 bg-white rounded-full -mt-2`}>
                                                </div>
                                          </div>
                                          {/* <div className="overflow-hidden h-1 text-xs flex rounded bg-gray-500">
                                            <div
                                              style={{width: `${progresBarWidth}`}}
                                              className="
                                                shadow-none
                                                flex flex-col
                                                text-center
                                                whitespace-nowrap
                                                text-white
                                                justify-center
                                                bg-white
                                              "
                                            ></div>
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </div>
                            }
                            {!['image', 'document', 'video', 'audio'].includes(itemToView.file_type?.split('/')[0]) &&
                              <div className="bg-white rounded-lg">
                                <div className="flex items-center justify-center flex-col gap-y-6 py-52">
                                  <div className="text-gray-700 font-bold">Sorry, we can’t read this file !</div>
                                  <div>
                                    <a href={`${itemToView.link}`} className="bg-dark-blue text-white inline-flex items-center rounded-md text-sm py-2.5 px-5 gap-x-2 font-medium">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="text-white h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                                      </svg>
                                      Download
                                    </a>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                          <div className="lg:col-start-3 lg:col-span-1">
                            <div className="bg-white rounded-md shadow p-4 mb-4">
                              <div className="flex items-center">
                                <div className="p-1.5 rounded bg-gray-900 inline-flex items-center justify-center">
                                  {this.chooseIcon(itemToView.file_type)}
                                  {/* <div className="text-xs text-white ml-1">{this.chooseIconName(itemToView.file_type)}</div> */}
                                </div>
                                {/* <div className="cursor-pointer bg-gray-200 p-2 rounded ml-auto" onClick={(e) => this.handleNotification(e)}>
                                  {itemNotification ?
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-800 h-5 w-5" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                    </svg>
                                  }
                                </div> */}
                                {/*<div className="ml-auto">
                                  { <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path></svg> }
                                </div>*/}
                              </div>
                              <div className="flex items-center mt-4">
                                <div className="text-base font-bold break-all">
                                {this.countNameCharectors(removeExtensionFromName(itemToView.name))}
                                </div>
                              </div>
                              <div className="flex items-center gap-2 flex-wrap mt-2">
                                {itemToView.location_tags_show?.map((location, j) =>
                                  <div className="red-tag text-gray-400 text-xs px-2.5 py-0.5 rounded-xl font-medium" key = {j}>
                                    {location.name}
                                  </div>
                                )}
                                {itemToView.skill_tags_show?.map((skill, j) => 
                                  <div className="bg-purple-100 text-purple-800 text-xs px-2.5 py-0.5 rounded-xl font-medium" key = {j}>
                                    {skill.name}
                                  </div>
                                )}
                              </div>
                              <div className="text-gray-400 text-xs mt-3.5">
                                Updated {itemToView.update_time} - By {itemToView.edited_by}
                              </div>
                              <div className="flex items-center mt-3.5">
                                <div>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                    <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                  </svg>
                                </div>
                                <div className="text-gray-700 text-xs ml-2">{itemToView.visitors}</div>
                              </div>
                              <div className="flex flex-wrap items-start mt-6 gap-y-5">
                                <div className="flex flex-wrap items-center gap-3">
                                  <div>
                                    <a className={`h-9 flex items-center justify-center border-transparent rounded shadow block mx-auto cursor-pointer px-3 ${reactions?.emoji_clicked === 'clap' ? `border-2 border-black` : ``} m-button tooltip-m`} onClick={(e) => this.addReaction(e, 'clap')} data-tip data-for='clapReactionTooltip'>
                                      <img className="w-6" src='/assets/icon_resource_clap.png' />
                                      {reactions?.clap_count > 0 &&
                                        <div className='text-center pl-2 text-base text-gray-900'>{reactions?.clap_count}</div>
                                      }
                                      {reactions?.clapped_response_users?.length > 0 ?
                                        <ReactTooltip className='max-w-xs' id="clapReactionTooltip" place="top" effect="solid">
                                          {reactions?.clapped_response_users.map((usr, i) => 
                                            <p className='text-xs whitespace-nowrap overflow-hidden text-ellipsis
                                            ' key={i}>{usr}</p>
                                          )}
                                        </ReactTooltip>
                                        :
                                        <span className='tooltiptext'>
                                          <p className='text-xs whitespace-nowrap overflow-hidden text-ellipsis '>Clap</p>
                                        </span>
                                      }
                                    </a>
                                  </div>
                                  <div>
                                    <a className={`h-9 flex items-center justify-center border-transparent rounded shadow block mx-auto cursor-pointer px-3 ${reactions?.emoji_clicked === 'fist' ? `border-2 border-black` : ``} m-button tooltip-m`} onClick={(e) => this.addReaction(e, 'fist')} data-tip data-for='fistReactionTooltip'>
                                      <img className="w-6" src='/assets/icon_resource_fist.png' />
                                      {reactions?.fist_count > 0 &&
                                        <div className='text-center pl-2 text-base text-gray-900'>{reactions?.fist_count}</div>
                                      }
                                      {reactions?.fist_bumped_response_users?.length > 0 ?
                                        <ReactTooltip className='max-w-xs' id="fistReactionTooltip" place="top" effect="solid">
                                          {reactions?.fist_bumped_response_users.map((usr, i) => 
                                            <p className='text-xs whitespace-nowrap overflow-hidden text-ellipsis' key={i}>{usr}</p>
                                          )}  
                                        </ReactTooltip>
                                      :
                                        <span className='tooltiptext'>
                                          <p className='text-xs whitespace-nowrap overflow-hidden text-ellipsis '>Fist bump</p>
                                        </span>
                                      }
                                    </a>
                                  </div>
                                  <div>
                                    <a className={`h-9 flex items-center justify-center border-transparent rounded shadow block mx-auto cursor-pointer px-3 ${reactions?.emoji_clicked === 'heart' ? `border-2 border-black` : ``} m-button tooltip-m`} onClick={(e) => this.addReaction(e, 'heart')} data-tip data-for='heartReactionTooltip'>
                                      <img className="w-6" src='/assets/icon_resource_heart.png' />
                                      {reactions?.heart_count > 0 &&
                                        <div className='text-center pl-2 text-base text-gray-900'>{reactions?.heart_count}</div>
                                      }
                                      {reactions?.heart_response_users?.length > 0 ?
                                        <ReactTooltip className='max-w-xs' id="heartReactionTooltip" place="top" effect="solid">
                                          {reactions?.heart_response_users.map((usr, i) => 
                                            <p className='text-xs whitespace-nowrap overflow-hidden text-ellipsis' key={i}>{usr}</p>
                                          )}
                                        </ReactTooltip>
                                        :
                                        <span className='tooltiptext'>
                                          <p className='text-xs whitespace-nowrap overflow-hidden text-ellipsis '>Heart</p>
                                        </span>
                                      }
                                    </a>
                                  </div>
                                </div>
                                <a href="" className="hidden bg-white flex items-center px-4 py-2 border shadow rounded-md">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-900 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                                  </svg>
                                  <div className="text-gray-900 text-sm ml-1.5">Download</div>
                                </a>
                                {itemToView?.drafted ? 
                                  <Link to = {`publish/${fileToRun}`} className="bg-dark-blue flex items-center px-4 py-2 ml-auto border shadow rounded-md">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                    </svg>
                                    <div className="text-white text-sm ml-1.5">Edit file</div>
                                  </Link> : 
                                (showEdit ?
                                  <Link to = {`publish/${fileToRun}`} className="bg-dark-blue flex items-center px-4 py-2 ml-auto border shadow rounded-md">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                    </svg>
                                    <div className="text-white text-sm ml-1.5">Edit file</div>
                                  </Link>
                                  : null
                                )}
                              </div>
                            </div>
                            {(showView) &&
                              <div className="mb-4">
                                  <Accordion className="bg-white rounded-md shadow p-4">
                                      <Card className="border-0">
                                          <Accordion.Toggle eventKey="0" className="text-sm flex items-center views-dd text-left border-0 w-full font-bold">
                                              Views <div className="ml-2 text-sm text-gray-900">({itemToView.views})</div>
                                              <div className="ml-auto">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-900 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                                </svg>
                                              </div>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse eventKey="0">
                                              <Card.Body className="p-0">
                                                <div className="flex items-center mt-4">
                                                    <div className="text-sm text-gray-900">User(s) who’ve viewed this file</div>
                                                    {/* <div className="text-sm text-gray-400 ml-auto">({itemToView.views})</div> */}
                                                </div>
                                                <div className="mt-4 max-h-80 overflow-auto">
                                                  {itemToView.views_show.map((user, i) =>
                                                    <div className="mb-4" key = {i}>
                                                      <div className="flex items-center">
                                                        <div><img className="w-6 h-6 rounded-full" src={`${user.image}`} /></div>
                                                        <div className="text-gray-900 text-sm font-medium ml-1.5">{user.name}</div>
                                                        <div className="text-gray-400 text-xs ml-1.5">{user.created}</div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </Card.Body>
                                          </Accordion.Collapse>
                                      </Card>
                                  </Accordion>
                              </div>
                            }
                            {/* {itemToView?.comments_show &&
                              <div className="bg-white rounded-md shadow p-4">
                                <span className="flex w-full font-bold mb-3">Comments</span>
                                <textarea 
                                  className="w-full rounded-md border resize-none shadow-sm p-2 text-sm" rows="4"
                                  value = {comentText}
                                  onBlur={(e) => this.onBlur(e)}
                                  onChange={(e) => this.onChange(e)}
                                  onKeyDown={(e) => this.onKeyDown(e)}
                                  // onInput={(event) => onInput(event.target)}
                                  // ref={textareaRef}
                                ></textarea>
                                <div className="user-comments mt-2 max-h-96 overflow-y-auto">
                                  {itemToView?.comments.map((comment, i) =>  
                                    <div className=" mt-4" key = {i}>
                                      <div className="flex items-center mb-2">
                                        <div>
                                          <img className="w-8 h-8 rounded-full mr-1.5" src={`${comment.profile_pic}`} />
                                        </div>
                                        <div className="font-sm text-gray-900 font-medium">{comment.name}</div>
                                        <div className="text-xs text-gray-400 ml-1.5 mt-1">{comment.created}</div>
                                      </div>
                                      <div>
                                        <div className="bg-gray-50 p-3 rounded-lg">
                                          <p className="text-sm text-gray-500">{comment.comment}</p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            } */}
                          </div>
                        </div>
                      }
                    </div>  
                  }
                </div>
              </main>
            </div>
          }
        </div>
      </React.Fragment>
    )
  }
}