import React, { Fragment, useState } from "react";
import CustomPopupContainerComponent from '../../../../../../subRequestDetails/components/customPopupContainerComponent';
import StatusFilterComponent from '../statusFilterComponent';
import { handleSaveMyRequestFilters } from '../../../../../helpers/helpers';

export default function SentFilterModalComponent(props) {
  const { showFilterModal, handleCloseModal, business, user, managerCount, pendingCount, acceptedCount, approvedCount, reopenCount, statusValues, processApplyFilter, statusList } = props
  const [ selectedStatus, setSelectedStatus ] = useState(statusValues || [])

  const handleApplyFilter = () => {
    let statusState = (selectedStatus.length === statusList.length ? 'all_selected' : selectedStatus?.length > 0 ? 'some_selected' : 'no_selected')
    let data = {
      selectedStatus: selectedStatus,
      statusState: statusState
    }
    handleSaveMyRequestFilters(data, true, user?.id, 'sent')
    processApplyFilter(data)
  }

  const handleResetFilter = () => {
    let defaultStatusValues = statusList.map((item) => item.value)
    setSelectedStatus(defaultStatusValues)
    let data = {
      selectedStatus: defaultStatusValues,
      statusState: 'all_selected'
    }
    handleSaveMyRequestFilters(data, true, user?.id, 'sent')
    processApplyFilter(data)
  }

  const handleProcessCloseModal = (data) => {
    setSelectedStatus(statusValues || [])
    handleCloseModal(data)
  }

  return(
    <Fragment>
      <CustomPopupContainerComponent show={showFilterModal} close={handleProcessCloseModal} title={''} customWidth={'sm:max-w-md'}>
        <div className="w-full px-8 pt-3 font-open-sans">
          <div className="text-center w-full text-gray-900 font-bold text-2xl">Filter Sent</div>
          <StatusFilterComponent business={business} managerCount={managerCount} pendingCount={pendingCount} acceptedCount={acceptedCount} approvedCount={approvedCount} reopenCount={reopenCount} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} statusList={statusList} bucketType={'sent'}/>
          <div className="flex items-center pt-4 pb-2">
            <div className="flex justify-center items-center w-full gap-3">
              <button className="w-full bg-white text-black text-center text-sm font-semibold rounded-2xl px-5 py-3 border border-gray-300 shadow-md hover:bg-gray-100" onClick={() => handleResetFilter()}>
                Reset
              </button>
              <button className='w-full bg-dark-blue text-white text-center text-sm font-semibold rounded-2xl px-5 py-3 border border-gray-900 shadow-md hover:bg-gray-700' onClick={(e) => handleApplyFilter()}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  )
}