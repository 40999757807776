import React from "react";

export default function SpinnerWithMessage(props){
  const { loadingMessage } = props

  return(
    <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
      <p className="mb-1 text-center px-5">{loadingMessage}</p>
      <img className = "w-9" src="/assets/business/loading.gif" />
    </div>
  )
}