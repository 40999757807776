import React, { Component, Fragment } from 'react'
import Cookies from 'js-cookie';
import { getRegions, destroyRegion, getSuggestions, createRegion, updateRegion } from './helpers'
import { getSudomain, setTitle, checkSettingsPermissios } from './../../../components/helpers'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'
import ReactTooltip from "react-tooltip";

export default class Regions extends Component {
  constructor(props){
    super(props);
    this.state = {
      regions: [],
      show: false,
      alert_type: '',
      alert_message: '',
      deletable_region: {},
      showDeleteModal: false,
      showAddModal: false,
      tags: [],
      suggestions: [],
      region_name: '',
      loaded: false,
      all_regions: [],
      editable_region: {},
      editModal: false,
      dataLoaded: false,
      manegers: [],
      searchValue: ''
    }
    this.handleDelete = this.handleDelete.bind(this);    
    this.handleAddition = this.handleAddition.bind(this);    
  }

  getAll = () => {
    getRegions(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          regions: result.results,
          all_regions: result.results,
          deletable_region: {},
          showDeleteModal: false,
          showAddModal: false,
          loaded: true,
          dataLoaded: true,
          editModal: false
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  componentDidMount = () => {
    setTitle('regions')
    if(Object.keys(this.props?.user).length !== 0 && Object.keys(this.props?.permissions).length !== 0){
      checkSettingsPermissios('regions', this.props)
    }
    if(this.props.pageLoaded){
      this.getAll()
    }

    document.body.addEventListener('click', this.handleClosepeopleList)
  }

  handleClosepeopleList = (e) => {
    const target = $(e.target) // $(this).parents().children()
    const manegers = this.state.manegers

    if(!target.parents('div#targetShowpeopleList1').length && !target.parents('div#showPeopleListInput').length && manegers?.length > 0){
      this.setState({manegers: []})
    }
  }

  componentWillUnmount() {    
    this.setState = (state,callback)=>{
      return;
    };
    document.body.removeEventListener('click', this.handleClosepeopleList)
  }
  
  setDelete = (e, id, name) => {
    e.preventDefault()
    this.setState({
      deletable_region: {
        id: id,
        name: name
      },
      showDeleteModal: true
    })
  }

  closeModal = (e) => {
    this.setState({
      showDeleteModal: false,
      deletable_region: {},
      showAddModal: false,
      editModal: false,
      manegers: [],
			searchValue: ''
    })
  }

  handleDelete(tagId) {
    const { tags } = this.state;
    this.setState({
     tags: tags.filter((tag, index) => tag.id !== tagId),
    });
  }

  handleAddition(tag) {
    var __FOUND = this.state.suggestions.find(function(post, index) {
      if(post.text == tag.text)
        return true;
    });

    if(__FOUND == undefined){
      return;
    }

    this.setState(state => ({ tags: [...state.tags, tag] }));
    this.setState({
			manegers: [],
			searchValue: ''
		})
  }

  processDelete = (e) => {
    e.preventDefault()
    destroyRegion(Cookies.get(`currentUser.${getSudomain()}`),getSudomain(), this.state.deletable_region).then(      
      response => response.json()
      .then(result => {        
        if(result.status === 200){
          this.setState({
            alert_message: 'Region successfully deleted!',
            alert_type: 'success',
            loaded: false
          })
          this.getAll()
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger'
          })
        }
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      })
    )
  }

  setAdd = (e) => {
    e.preventDefault()
    getSuggestions(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          suggestions: result.results,
          showDeleteModal: false,
          regionFormType: 'add',
          showAddModal: true,
          tags: []          
        })
      }else{
        this.setState({
          showAddModal: false,
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  processAdd = (e) => {  
    e.preventDefault()
    createRegion(Cookies.get(`currentUser.${getSudomain()}`),getSudomain(), this.state.region_name, this.state.tags).then(      
      response => response.json()
      .then(result => {
        if(result.status === 200){
          this.setState({
            alert_message: 'Region successfully created!',
            alert_type: 'success',
            loaded: false
          })
          this.getAll()
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger'
          })
        }
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      })
    )
  }

  search = (e) => {
    let searchTerm = e.target.value.trim()
    let regions = this.state.all_regions
    if(searchTerm === ''){
      this.setState({ regions })
    }else{
      var foundRegions = regions.filter(function (entry) {
        return (entry.name.toLowerCase().includes(searchTerm.toLowerCase()));
      });
      this.setState({
        regions: foundRegions
      })
    }
  }

  setEdit = (e, region) => {
    e.preventDefault()
    let tags = []
    let regionalAdmins = region.regional_admin_displays
    regionalAdmins.forEach(function (elem) {
      let h = {}
      h['id'] = elem.id.toString();
      h['text'] = elem.name
      tags.push(h)
    });
    
    getSuggestions(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          suggestions: result.results,
          showDeleteModal: false,
          showAddModal: true,
          regionFormType: 'edit',
          tags: tags,
          // editModal: true,
          editable_region: region
        })
      }else{
        this.setState({
          showAddModal: false,
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })    
  }

  handleChange = (e) => {
    this.setState({
      editable_region: {
        ...this.state.editable_region,
        name: e.target.value
      }
    })
  }

  processEdit = (e) => {
    e.preventDefault()
    updateRegion(Cookies.get(`currentUser.${getSudomain()}`),getSudomain(), this.state.editable_region, this.state.tags).then(      
      response => response.json()
      .then(result => {
        if(result.status === 200){
          this.setState({
            alert_message: 'Region successfully updated!',
            alert_type: 'success',            
            loaded: false
          })
          this.getAll()
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger'
          })
        }
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      })
    )
  }

  searchUsers = (e) => {
    let searchTerm = e.target.value.trim()
    let classes = this.state.suggestions
		let tags = this.state.tags
    if(searchTerm === ''){
      this.setState({ manegers: []})
    }else{
      var foundClasses = classes.filter(function (entry) {
        return (entry.text.toLowerCase().includes(searchTerm.toLowerCase()) && !tags.find(function(post, index) {
					return (post.text === entry.text)
				}));
      });
      this.setState({
        manegers: foundClasses
      })
    }
    this.setState({searchValue: e.target.value})
  }

  render() {
    const { show, alert_type, alert_message, regions, deletable_region, showDeleteModal, tags, suggestions, showAddModal, region_name, loaded, editModal, editable_region, dataLoaded, regionFormType, searchValue, manegers } = this.state
    const { user, permissions, loadingMessage } = this.props

    return (
      <React.Fragment>
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
        <div className='w-full min-h-screen bg-gray-50'>
          <div className='h-full'>
            {!loaded && 
              <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                <p className="mb-1 text-center px-5">{loadingMessage}</p>
                <img className = "w-9" src="/assets/business/loading.gif" />
              </div>
            }
            {loaded &&
              <div className='max-w-8xl mx-auto h-full'>
                <div className='hidden mt-2 mb-5'>
                  <div className='bg-white break-words p-3 border border-gray-200 rounded-lg'>
                    <p className="text-gray-500 text-sm font-normal">
                    If you have locations throughout the state, country, or world, you can group your locations and instructors by Region! (Ex: San Francisco Bay Area, New York, SoCal, NorCal, etc.)
                    </p>
                  </div>
                </div>
                <div className='grid items-center'>
                  <div className='w-full md:pt-2 px-0 lg:flex flex items-center gap-2 flex-wrap'>
                    <div className="w-full lg:w-44 relative bg-gray-300 rounded-md">
                      <div className='w-full bg-white border border-gray-300 shadow-sm rounded-md h-9 flex items-center pr-1.5'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4 ml-3.5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                        </svg>
                        <input type="text" className="text-sm w-full border-0 h-10 ml-1.5 focus:outline-none bg-transparent" placeholder="Search for region" onChange = {(e) => this.search(e)}/>
                      </div>
                    </div>
                    {(permissions?.business_regions?.write || user.role_display === "Netgym Employee") &&
                      <div className=''>
                        <a className='bg-dark-blue text-white hover:bg-gray-800 text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={(e) => this.setAdd(e)}>Add New Region</a>
                      </div>
                    }
                  </div>
                </div>
                <div className='mt-3 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4'>
                  <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                    {!dataLoaded &&
                      <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                        <p className="mb-1 text-center px-5">{loadingMessage}</p>
                        <img className = "w-9" src="/assets/business/loading.gif" />
                      </div>
                    }
                    {dataLoaded &&
                      <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                        {regions.length > 0 &&
                          <table className='min-w-full divide-y divide-gray-200'>
                            <thead className='bg-gray-50'>
                              <tr>
                                <th scope="col" className="hidden lg:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-10">#</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Regional Admins</th>
                                {(permissions?.business_regions?.write || user.role_display === "Netgym Employee") &&
                                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-10"></th>
                                }
                              </tr>
                            </thead>
                            <tbody className='divide-y divide-gray-200'>
                              {regions.map((region, i) => 
                                <tr key={i} className='bg-white'>
                                  <td className='hidden lg:table-cell px-6 py-4 text-sm font-medium text-gray-900'>
                                    <div className='text-sm text-gray-500'>{i + 1}</div>
                                  </td>
                                  <td className='px-6 py-4'>
                                    <div className='text-sm font-medium text-gray-900'>{region.name}</div>
                                  </td>
                                  <td className='px-6 py-4'>
                                    <div className='text-sm font-medium text-gray-900'>{region.regional_admin_displays.map(a => a.name).join(", ")}</div>
                                  </td>
                                  {(permissions?.business_regions?.write || user.role_display === "Netgym Employee") &&
                                    <td className='px-6 py-4'>
                                      <div className="justify-end">
                                        <div className=' flex gap-3'>
                                          <div className='bg-gray-100 w-8 h-8 hover:bg-gray-200 rounded flex items-center justify-center cursor-pointer'  onClick={(e) => this.setEdit(e, region)} data-tip data-for={`edit_button_`+region?.id.toString()}>
                                            <a className='cursor-pointer'>
                                              <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                              </svg>
                                            </a>
                                            <ReactTooltip className='max-w-xs whitespace-nowrap' id={`edit_button_`+region?.id.toString()} place="top" effect="solid">
                                              Edit region
                                            </ReactTooltip>
                                          </div>
                                          <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => this.setDelete(e, region.id, region.name)} data-tip data-for={`delete_button_`+region?.id.toString()}>
                                            <a className='cursor-pointer'>
                                              <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                              </svg>
                                            </a>
                                            <ReactTooltip className='max-w-xs whitespace-nowrap' id={`delete_button_`+region?.id.toString()} place="top" effect="solid">
                                              Delete region
                                            </ReactTooltip>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  }
                                </tr>
                              )}
                            </tbody>
                          </table>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <Transition.Root show={showDeleteModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeModal(e)}>
						<div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6 mt-20">
									<div className="absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
											onClick={(e) => this.closeModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										<div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left w-full">
											<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 border-b pb-2">
                        Delete Region
											</Dialog.Title>
											<div className="mt-4 mb-2 flex gap-4 items-center">
                        <p className='text-gray-700 w-36 text-left'>Region Name</p>
                        <p className='text-sm text-gray-500'>{deletable_region.name}</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-blue text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={(e) => this.processDelete(e)}
                    >
                      Delete Region
                    </button>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
        <Transition.Root show={showAddModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeModal(e)}>
						<div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-20 sm:align-middle sm:max-w-4xl w-full sm:p-6 opacity-100 translate-y-0 sm:scale-100 mt-20">
									<div className="absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
											onClick={(e) => this.closeModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										<div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left w-full">
											<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 border-b pb-2">
                        {regionFormType == 'add' ? 'New Region Form' : 'Edit Region Form'}
											</Dialog.Title>
                      <div className='min-h-64 relative'>
                        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-4'>
                          <label htmlFor="location-name" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Region Name</label>
                          <div className='mt-1 sm:mt-0 sm:col-span-1'>
                            {regionFormType == 'add' ?
                              <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" onChange = {(e) => this.setState({region_name: e.target.value})} placeholder="Enter Region Name"/>
                              :
                              <input type="text" className="lg:w-80 text-sm h-9 border rounded-md px-2 py-1.5 w-full shadow-sm" value = {editable_region.name} placeholder="Enter Region Name" onChange = {(e) => this.handleChange(e)}/>
                            }
                          </div>
                        </div>
                        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                          <label htmlFor="street-address" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Regional Admin</label>
                          <div className='mt-1 sm:mt-0 sm:col-span-2'>
                            <div id='showPeopleListInput' className='w-full lg:w-80 bg-white border border-gray-300 shadow-sm rounded-md h-10 flex items-center'>
                              <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4 ml-3.5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                              </svg>
                              <input type="text" className='text-sm w-full border-0 h-10 ml-1.5 focus:outline-none bg-transparent' placeholder='Search for regional admin' name="searchValue" value={searchValue} onChange={(e) => this.searchUsers(e)} onBlur={(e) => (e)} onClick={(e) => this.searchUsers(e)}/>
                            </div>
                            {manegers?.length > 0 &&
                              <div id='targetShowpeopleList1' className="relative block text-left">
                                <div className="origin-top-right z-10 absolute left-0 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none py-1">
                                  <div className="max-h-32 overflow-y-auto">
                                    <div>
                                      {manegers.map((user, i) => 
                                        <div key = {i}>
                                          <a className="flex items-center text-gray-900 text-sm  cursor-pointer hover:bg-blue-50 py-1.5 -mb-1" onClick={(e) => this.handleAddition(user)}>
                                            <div className='pl-1'>{user?.text}</div>
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                            {tags?.length > 0 &&
                              <div className='flex items-center mt-2 gap-2 flex-wrap mb-11'>
                                {tags.map((tag, i) => 
                                  <div key={i} className='bg-gray-100 rounded-xl flex items-center px-2.5 h-6'>
                                    <div className='text-sm text-gray-800 mr-1.5 font-medium'>{tag.text}</div>
                                    <a className='cursor-pointer' onClick={(e) => this.handleDelete(tag.id)}>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                      </svg>
                                    </a>
                                  </div>
                                )}
                              </div>
                            }
                          </div>
                        </div>
                        <div className='flex mt-4 gap-3 justify-end  relative bottom-0 right-0'>
                          <a className='bg-white sm:text-base text-sm hover:bg-gray-100 rounded-md text-gray-700 font-medium border border-gray-200 px-6 py-3 cursor-pointer' onClick={(e) => this.closeModal(e)}>Cancel</a>
                          {regionFormType == 'add' &&
                            <button className={`flex sm:text-base text-sm hover:bg-gray-800 font-medium rounded-md text-white bg-dark-blue px-6 py-3 gap-2 ${region_name === '' ? 'btn-disabled' : ''}`} onClick={(e) => this.processAdd(e)} disabled={region_name === '' ? true : false}>Add New Region</button>
                          }
                          {regionFormType == 'edit' &&
                            <button className={`flex sm:text-base text-sm hover:bg-gray-800 font-medium rounded-md text-white bg-dark-blue px-6 py-3 gap-2 ${(editable_region.name === '') ? 'btn-disabled' : ''}`} onClick={(e) => this.processEdit(e)} disabled={editable_region.name === '' ? true : false}>Update Region</button>
                          }
                        </div>
                      </div>
										</div>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
      </React.Fragment>
    )
  }
}
