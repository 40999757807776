import React, { Fragment, useState } from "react";
import { getSudomain } from '../../../../../helpers'
import Cookies from 'js-cookie';
import { updatePassword } from './../../../helpers'

export default function ChangePasswordComponent(props){
  const { passwordUser, closeConfirmationPopup, showAlert, doneDeleteUserProcess } = props
  const [ disabledSendAllBtn, setDisabledSendAllBtn ] = useState(false)
  const [ showPassword, setShowPassword ] = useState(false)
  const [ newPass, setNewPass ] = useState('')
  const [ showConfirmPassword, setShowConfirmPassword ] = useState(false)
  const [ confirmNewPass, setConfirmNewPass ] = useState('')
  const [ submitted, setSubmitted ] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == 'newPass'){
      setNewPass(value)
    }else if(name == 'confirmNewPass'){
      setConfirmNewPass(value)
    }
  }

  const checkConfirmPassword = () => {
    if(newPass?.split("")?.length >= 6 && confirmNewPass?.split("")?.length >= 6 && newPass !== confirmNewPass){
      return true
    } else {
      return false
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (newPass !== "" && confirmNewPass !== "") {
      if(newPass?.split("")?.length >= 6 && confirmNewPass?.split("")?.length >= 6){
        if(!checkConfirmPassword()){
          processUpdatePassword()
        }
      } else {
        console.log("Error editing My-profile");
      }
    } else {
      console.log("Error editing My-profile");
    }
  }

  const processUpdatePassword = () => {
    setDisabledSendAllBtn(true)
    updatePassword(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), newPass, confirmNewPass, passwordUser).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){        
        showAlert(true, 'success', result.success)
        doneDeleteUserProcess()
      }else{
        setDisabledSendAllBtn(false)
        showAlert(true, 'danger', result.error)
      }
    })
  }

  return(
    <Fragment>
      <div className="mt-3 border-t border-gray-200">
        <div className='flex flex-wrap sm:gap-4 sm:items-center pt-2 px-7'>
          <React.Fragment>
            <div className="mb-2 w-full">
              <div className='text-center items-center mb-3'>
                <p className='text-gray-700 w-full ite'>Change {passwordUser?.full_name}'s Password</p>
              </div>
              <div className='flex flex-wrap gap-x-4 mb-2 items-center sm:justify-between'>
                <p className='text-sm font-medium text-gray-500 text-left w-36'>New Password</p>
                <div className="mt-1 sm:mt-0 w-full sm:w-64">
                  <div className="relative flex w-full sm:w-64">
                    <input type= {showPassword ? "text" : "password"} name="newPass" onChange={(e)=> handleChange(e)} placeholder="Enter New Password" className='h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500' />
                    <div className='absolute right-0 pr-2 py-2.5'>
                      {/* Clicking this will show password */}
                      {!showPassword &&
                        <React.Fragment>
                          <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer h-5 w-5" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => setShowPassword(true)}>
                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                            <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                          </svg>
                        </React.Fragment>
                      }
                      {/* Clicking this will hide password */}
                      {showPassword &&
                        <React.Fragment>
                          <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer h-5 w-5" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => setShowPassword(false)}>
                            <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
                            <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                          </svg>                     
                        </React.Fragment>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex gap-4 mb-2 items-center sm:justify-between'>
                <div className='text-gray-700 w-36'></div>
                <div className="relative">
                  {(newPass !== "" && newPass?.split("")?.length < 6) && 
                    <span className="error text-xs">*Password should be minimum 6 characters long.</span>
                  }
                  {submitted && !newPass && (
                    <span className="error text-xs">*New Password is required</span>
                  )}
                </div>
              </div>
              <div className='flex flex-wrap gap-x-4 mb-2 items-center sm:justify-between'>
                <p className='text-sm font-medium text-gray-500 text-left w-36'>Confirm Password</p>
                <div className="mt-1 sm:mt-0 w-full sm:w-64">
                  <div className="relative flex w-full sm:w-64">
                    <input type={showConfirmPassword ? "text" : "password"} name="confirmNewPass" onChange={(e)=> handleChange(e)} placeholder="Confirm New Password" className='h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500' />
                    <div className='absolute right-0 pr-2 py-2.5'>
                      {/* Clicking this will show confirm password */}
                      {!showConfirmPassword &&
                        <React.Fragment>
                          <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer h-5 w-5" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => setShowConfirmPassword(true)}>
                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                            <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                          </svg>                      
                        </React.Fragment>
                      }
                      {/* Clicking this will hide confirm password */}
                      {showConfirmPassword &&
                        <React.Fragment>
                          <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer h-5 w-5" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => setShowConfirmPassword(false)}>
                            <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
                            <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                          </svg>                     
                        </React.Fragment>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex gap-4 mb-2 items-center sm:justify-between'>
                <div className='text-gray-700 w-36'></div>
                <div className="relative">
                  {(confirmNewPass !== "" && confirmNewPass?.split("")?.length < 6) &&                   
                    <span className="error text-xs">*Password should be minimum 6 characters long.</span>
                  }
                  {submitted && !confirmNewPass && (
                    <span className="error text-xs">*Password Confirmation is required</span>
                  )}
                  {submitted && checkConfirmPassword() && (
                    <span className="error text-xs">*Passwords do not match</span>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
        <div className='flex sm:gap-4 sm:items-center px-7 py-3.5 justify-start sm:justify-center sm:flex-row-reverse'>
          <button
            type="button"
            className={`cursor-pointer w-full h-10 items-center inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm ${disabledSendAllBtn ? 'button-disabled bg-gray-400' : 'bg-dark-blue'}`}
            onClick={(e) => handleSubmit(e)}
            disabled={disabledSendAllBtn}
          >
            <div className='text-white text-base flex items-center gap-2.5 relative px-1'>
              {disabledSendAllBtn &&
                <div className="flex items-center -left-3.5 justify-center absolute">
                  <svg className="animate-spin h-3.5 w-3.5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              }
              Update Password
            </div>
          </button>
          <button
            type="button"
            className="mt-3 w-full h-10 items-center inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={(e) => closeConfirmationPopup(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  )
}