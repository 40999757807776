import React from 'react';

export default function MyProfileNavBar(props){
  const { myProfilePath, setMyProfilePath } = props

  const handleTabClick = (tab) =>{
    setMyProfilePath(tab)
  }

  return(
    <div className='bg-white h-11 shadow rounded-lg my-5 inline-flex'>
      <button className={`sm:text-sm text-xs flex items-center border-b-2 font-medium sm:px-14 px-8 ${myProfilePath === 'information' ? 'text-gray-900 border-gray-900 rounded-bl' : 'text-gray-300 border-transparent'}`} onClick={(e) => handleTabClick('information')}>Information</button>

      <button className={`sm:text-sm text-xs flex items-center border-b-2 font-medium sm:px-14 px-8 ${myProfilePath === 'notifications' ? 'text-gray-900 border-gray-900 rounded-br' : 'text-gray-300 border-transparent'}`} onClick={(e) => handleTabClick('notifications')}>Notifications</button>
    </div>
  )
}