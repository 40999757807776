import React, { Component } from 'react';
import MyProfileNavBar from './navbar';
import Information from './pages/information';
import Notifications from './pages/notifications';
import { axiosGetRequest, checkReloadUserAvailable, removeReloaduser } from './helpers'
import { setTitle } from './../helpers'

export default class MyProfileLayout extends Component {
  constructor(props){
    super(props);
    this.state = {
      myProfilePath: 'information',
      userSettingsLocation: {},
      loaded: false
    }
  }

  componentDidMount(){
    this.getPageUrl()
    if (this.props.pageLoaded){
      this.getUserData()
    }
    setTitle('my_profile')
    this.props.setCurrent('My Profile')
  }

  getPageUrl = () => {
    let params = new URLSearchParams(window.location.search)
    let type = (params.has('page') && params.get('page') !== '') ? params.get('page') : ''
    if(type === '' || ![ 'information', 'notifications' ].includes(type)){
      window.history.replaceState(`path:my-profile?page=information`, '', `/my-profile?page=information`)
      this.setState({myProfilePath: 'information'})
    }else{
      if(type == 'notifications'){
        this.setState({myProfilePath: 'notifications'})
      }else{
        this.setState({myProfilePath: 'information'})
      }
    }
    let reload = checkReloadUserAvailable()
    if (reload != '' && (reload === 'true' || reload === true)){
      removeReloaduser()
    }
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  getUserData = () => {
    axiosGetRequest("/api/v3/my_profile/get_locations_categories").then(result => {
      if(result.status === 200){
        this.setState({
          userSettingsLocation: result?.result,
          loaded: true
        })
      }
    })
  }

  handleChangePage = (tab) => {
    let reload = checkReloadUserAvailable()
    window.history.replaceState(`path:my-profile?page=${tab}`, '', `/my-profile?page=${tab}`)
    this.setState({
      myProfilePath: tab
    })
    if (reload != '' && (reload === 'true' || reload === true)){
      this.props.getCurrentUser()
    }
  }

  renderSwitch(){    
    switch(this.state.myProfilePath){
      case 'information':
        return <Information user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} handleUpdateUser={this.props.handleUpdateUser} userSettingsLocation={this.state.userSettingsLocation}/>
      case 'notifications':
        return <Notifications user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} userSettingsLocation={this.state.userSettingsLocation} getUserData={this.getUserData} handleUpdateUser={this.props.handleUpdateUser} getCurrentUser={this.props.getCurrentUser}/>
    }
  }

  render(){
    const { myProfilePath, loaded } = this.state
    const { user, business, permissions, loadingMessage, pageLoaded } = this.props

    return(
      <React.Fragment>
        {(!pageLoaded || !loaded) &&
          <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
            <p className="mb-1">{loadingMessage}</p>
            <img className = "w-9" src="/assets/business/loading.gif" />
          </div>
        }
        {(pageLoaded && loaded) &&
          <div className="w-full min-h-screen bg-gray-50">
            <div className="pt-20 md:pt-8 pb-8 h-full">
              <div className='max-w-8xl mx-auto px-6 lg:px-8 h-full'>
                <div className='justify-between items-center'>
                  <div className='flex text-2xl'>
                    My Profile
                  </div>
                  <MyProfileNavBar myProfilePath = {myProfilePath} setMyProfilePath = {this.handleChangePage}/>
                </div>
                <div>
                  {this.renderSwitch()}
                </div>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}