import React from 'react'
import clsx from 'clsx';
import { useMemo } from 'react';
import {
  AttachmentPreviewList,
  ChatAutoComplete,
  EmojiPicker,
  SendButton,
  useChannelStateContext, useComponentContext,
  useMessageInputContext,
} from 'stream-chat-react';
import { useMessageInputCompositionControls } from './../hooks/useMessageInputCompositionControls';
// import { useDropzone } from 'react-dropzone';
import { ImageDropzone, FileUploadButton } from "react-file-utils";
import MessageInputControlButton from './../assets/messageInputControlButton'
import CustomSendButton from './customSendButton'
import FileUploadButtonIcon from './../assets/fileUploadButtonIcon'

export default function CustomMessageInput(){
  const {TypingIndicator} = useComponentContext();

  const {
    acceptedFiles,
    channel,
    maxNumberOfFiles,
    multipleUploads,
    thread
  } = useChannelStateContext();

  const {
    handleSubmit,
    numberOfUploads,
    text,
    uploadNewFiles,
    maxFilesLeft,
    isUploadEnabled,
    openEmojiPicker,
    closeEmojiPicker,
    emojiPickerIsOpen,
    messageInput
  } = useMessageInputContext();

  // const { isComposingGiphyMessage } = useGiphyInMessageContext();

  const {
    formatting,
    handleBoldButtonClick,
    handleCodeButtonClick,
    handleItalicsButtonClick,
    handleStrikeThroughButtonClick,
    onChange,
    placeholder,
  } = useMessageInputCompositionControls();

  // const accept = useMemo(
  //   () =>
  //     acceptedFiles.reduce((mediaTypeMap, mediaType) => {
  //       mediaTypeMap[mediaType] ??= [];
  //       return mediaTypeMap;
  //     }, {}),
  //   [acceptedFiles],
  // );

  // const { getRootProps, isDragActive, isDragReject } = useDropzone({
  //   accept,
  //   disabled: !isUploadEnabled || maxFilesLeft === 0,
  //   multiple: multipleUploads,
  //   noClick: true,
  //   onDrop: uploadNewFiles,
  // });

  return(
    <div className={`team-message-input__wrapper relative`}>
      {/* {isDragActive && (
        <div
          className={clsx('str-chat__dropzone-container', {
            'str-chat__dropzone-container--not-accepted': isDragReject,
          })}
        >
          {!isDragReject && <p>Drag your files here</p>}
          {isDragReject && <p>Some of the files will not be accepted</p>}
        </div>
      )} */}
      <ImageDropzone
        accept={acceptedFiles}
        handleFiles={uploadNewFiles}
        multiple={multipleUploads}
        disabled={
          (maxNumberOfFiles !== undefined &&
            messageInput.numberOfUploads >= maxNumberOfFiles) //||
          //giphyState
        }
      >
        <div className='team-message-input__input'>
          <div className='team-message-input__bottom'>
            <FileUploadButton 
              accept={acceptedFiles}
              handleFiles={uploadNewFiles}
              multiple={false}
              disabled={false}
              children={<FileUploadButtonIcon />}
            />
            <MessageInputControlButton type='emoji' onClick={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker} />
          </div>
          <div className='team-message-input__top'>
            {!!numberOfUploads && <AttachmentPreviewList />}
            <div className='team-message-input__form'>
              {/* {isComposingGiphyMessage() && !numberOfUploads && <GiphyBadge />} */}
              <ChatAutoComplete onChange={onChange} placeholder={placeholder} />
              <CustomSendButton sendMessage={handleSubmit} disabled={!numberOfUploads && !text.length}/>
            </div>
          </div>
        </div>
      </ImageDropzone>
      <EmojiPicker />
    </div>
  )
}