import React, { Fragment, useEffect, useState } from "react";
import { axiosGetRequest, axiosRequest } from '../../../subRequests/helpers/helpers'
import { checkSelctedRegionsWithSome, checkTheSignUpPagePath } from '../../helpers';
import LocationRegionSelectorComponent from './locationRegionSelectorComponent';

export default function LocationsComponent(props) {
  const { user, setAlert, handleUpdateUser } = props
  const [ regionLocations, setRegionLocations ] = useState([])
  const [ selectedLocations, setSelectedLocations ] = useState([])
  const [ selectedRegions, setSelectedRegions ] = useState([])
  const [ showRegion, setShowRegion ] = useState(true)
  const [ loading, setLoading ] = useState(true)
  const [ btnDisabled, setBtnDisabled ] = useState(false)

  useEffect(() => {
    processFetchLocations()
  }, [])

  const processFetchLocations = () => {
    let url = '/api/v3/get_regions_and_locations'
    axiosGetRequest(url).then(result => {
      if (result.status === 200) {
        setRegionLocations(result.regions_and_locations)
        setSelectedLocations(result.instructor_location_ids)
        setSelectedRegions(checkSelctedRegionsWithSome(result.regions_and_locations, result.instructor_location_ids))
        setShowRegion(result.show_others)
      }
      setLoading(false)
    })
  }

  const handleSaveLocations = (e) => {
    e.preventDefault()
    setBtnDisabled(true)
    let url = '/api/v3/my_profile/save_locations'
    let params = {
      user_data: {
        business_location_ids: selectedLocations
      }
    }
    axiosRequest(url, 'POST', params, 'data').then(result => {
      if (result.status === 200) {
        setAlert(true, 'success', result?.message)
        setTimeout(() => {
          setAlert(false, '', '')
          setBtnDisabled(false);
        }, 3000);
        handleUpdateUser(result?.user, checkTheSignUpPagePath(result?.user))
      }else{
        setBtnDisabled(false);
      }
    })
  }
  return(
    <Fragment>
      {loading &&
        <div className="msg-loading flex flex-col items-center w-full justify-center h-96">
          <img className = "w-9" src="/assets/business/loading.gif" />
        </div>
      }
      {!loading &&
        <div className="w-full flex flex-col items-center justify-center mt-4 px-2">
          <div className="flex items-center justify-center text-center text-lg text-gray-900 font-normal">
            Where do you want to receive sub requests?
          </div>
          <div className="flex justify-center mt-7">
            <LocationRegionSelectorComponent
              regionLocations={regionLocations}
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
              selectedRegions={selectedRegions}
              setSelectedRegions={setSelectedRegions}
              showRegion={showRegion}
            />
          </div>
          <div className='flex flex-col items-center justify-center w-full mt-7 gap-2'>
            <button
              className={`bg-gray-900 rounded-md text-white flex items-center justify-center px-8 py-2 text-sm w-1/2 md:w-1/5 ${btnDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={(e) => handleSaveLocations(e)}
              disabled={btnDisabled}
            >
              Next
            </button>
          </div>
        </div>
      }
    </Fragment>
  )
}