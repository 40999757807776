import React, { Fragment, useState } from "react";
import { TrashIcon } from './../../assets'
import CardViewCreateCommentComponent from './../../../subRequests/pages/components/cardViewComponents/cardComponent/commentsComponent/cardViewCreateCommentComponent';
import CommentDeleteConfirmationComponent from './commentDeleteConfirmationComponent';
import PublicCommentToggleComponent from './publicCommentToggleComponent';
import { checkMgrEventWithRole } from './../../helpers';
import ReactHtmlParser from 'react-html-parser';
import DOMPurify from 'dompurify';

export default function CommentSectionComponent (props) {
  const { eventData, business, user, handleSetAlert, handleUpdateEvents } = props
  const [ deleteComment, setDeleteComment ] = useState({})
  const [ showDeleteCommentPopup, setShowDeleteCommentPopup ] = useState(false)

  const handleDeleteComment = (comment) => {
    setDeleteComment(comment)
    setShowDeleteCommentPopup(true)
  }

  const closeDeleteCommentPopup = (data) => {
    setDeleteComment({})
    setShowDeleteCommentPopup(data)
  }

  const handleCommentTagBold = (comment) => {
    let replacedText = comment.replace(/@([^:]+):/g, '<b>@$1:</b>')
    return(DOMPurify.sanitize(replacedText))
  }

  return(
    <Fragment>
      {showDeleteCommentPopup &&
        <CommentDeleteConfirmationComponent eventData={eventData} comment={deleteComment} handleSetAlert={handleSetAlert} handleUpdateEvents={handleUpdateEvents} showDeleteCommentPopup={showDeleteCommentPopup} closeDeleteCommentPopup={closeDeleteCommentPopup}/>
      }
      <div className={`${(!eventData?.show_invites_section && !(eventData?.manager_gate_event && checkMgrEventWithRole(eventData, user))) ? 'lg:ml-2' : 'lg:mt-4 lg:mr-2'} h-full w-full`}>
        <div className="lg:rounded-2xl lg:border-2 lg:border-gray-300 h-auto w-full bg-white p-4">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center font-semibold text-xl text-gray-900">
              Comments
            </div>
            {eventData?.show_manger_request_buttons ? 
              <PublicCommentToggleComponent eventData={eventData} handleSetAlert={handleSetAlert} handleUpdateEvents={handleUpdateEvents}/>
            :
              <div></div>
            }
          </div>
          <div className="mb-2">
            {eventData?.comments?.map((comment, i) =>
              <div key={i} className="mb-4 flex">
                <div className="w-7 mr-1 mt-0.5">
                  <img
                    src={comment?.user?.image}
                    className="w-6 h-6 rounded-full"
                  />
                </div>
                <div className="w-full">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-1.5 mb-1.5">
                      <div className="text-base font-semibold">{comment?.user?.name}</div>
                      <div className="text-sm font-semibold text-neutral-600 mt-0.5">{`${comment?.created?.trim()}`}</div>
                    </div>
                    {((eventData?.show_invites_section && comment?.can_delete) || (!eventData?.show_invites_section && user?.id == comment?.user?.id)) &&
                      <button className={`w-6 h-6 rounded-full border-neutral-500-custom flex items-center justify-center opacity-75 hover:opacity-100`} onClick={() => handleDeleteComment(comment)}>
                        <TrashIcon classNames={"w-4 h-4"} colour={''}/>
                      </button>
                    }
                  </div>
                  <div className="text-sm font-medium text-gray-900 comment-container msg-dec break-all leading-loose table clear-both pr-6">
                    {ReactHtmlParser(handleCommentTagBold(comment?.comment))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {business?.comments_feature &&
            <CardViewCreateCommentComponent eventId={eventData?.id} setShowCreateCommentSection={() => {}} user={user} handleSetAlert={handleSetAlert} handleUpdateEvents={handleUpdateEvents}/>
          }
        </div>
      </div>
    </Fragment>
  )
}