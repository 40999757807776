import React, { Fragment, useState } from "react";
import LoadingIcon from '../../../../subRequests/assets/loadingIcon'

export default function LoomVideoEmbed({videoLink}) {
  const [isLoading, setIsLoading] = useState(true);
  const handleIframeLoad = () => {
    setIsLoading(false);
  };
  return (
    <Fragment>
      <div style={{ position: "relative", paddingBottom: "54.70383275261324%", height: 0 }}>
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 10,
              textAlign: "center",
            }}
          >
            <LoadingIcon classNames={'animate-spin h-7 w-7 text-black'}/>
          </div>
        )}
        <iframe
          src={videoLink}
          frameBorder="0"
          allowFullScreen
          style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
          onLoad={handleIframeLoad}
          className="rounded-xl"
        ></iframe>
      </div>
    </Fragment>
  )
}