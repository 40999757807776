import React, { Fragment } from "react";

export default function UserIcon({classNames, customColour}) {
  return(
    <Fragment>
      <svg className={classNames} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.99935 1.66667C7.81602 1.66667 6.04102 3.44167 6.04102 5.62501C6.04102 7.76667 7.71602 9.50001 9.89935 9.57501C9.96602 9.56667 10.0327 9.56667 10.0827 9.57501C10.0993 9.57501 10.1077 9.57501 10.1243 9.57501C10.1327 9.57501 10.1327 9.57501 10.141 9.57501C12.2743 9.50001 13.9493 7.76667 13.9577 5.62501C13.9577 3.44167 12.1827 1.66667 9.99935 1.66667Z" fill={customColour ? customColour : "#737373"}/>
        <path d="M14.2328 11.7917C11.9078 10.2417 8.11615 10.2417 5.77448 11.7917C4.71615 12.5 4.13281 13.4583 4.13281 14.4833C4.13281 15.5083 4.71615 16.4583 5.76615 17.1583C6.93281 17.9417 8.46615 18.3333 9.99948 18.3333C11.5328 18.3333 13.0661 17.9417 14.2328 17.1583C15.2828 16.45 15.8661 15.5 15.8661 14.4667C15.8578 13.4417 15.2828 12.4917 14.2328 11.7917Z" fill={customColour ? customColour : "#737373"}/>
      </svg>
    </Fragment>
  )
}