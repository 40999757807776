import React, { Component, Fragment } from 'react';
import { Switch } from '@headlessui/react'
import PersionalInfoShow from './persionalInformation/persionalInfoShow'
import PersionalInfoEdit from './persionalInformation/persionalInfoEdit';
import FitnessProfileInfo from './fitnessProfileInformation'
import { updateDisplayInDirectory, updateUserDetails, axiosGetRequest, axiosRequest } from './../../helpers'
import Cookies from 'js-cookie';
import { getSudomain, setTitle, timeZones } from '../../../helpers'
import { getStates } from './../../../settings-v4/locations/helpers'
import AlertPopup from './../../../messenger/pages/chatComponents/assets/alertPopup';
import CustomPopup from './../../assets/customPopup';
import CustomAlertPopupWithHeader from '../../../messenger/pages/chatComponents/assets/customAlertPopupWithHeader';

const MemoizedPersionalInfoShow = React.memo(PersionalInfoShow)
const MemoizedPersionalInfoEdit = React.memo(PersionalInfoEdit)


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default class Information extends Component{
  constructor(props){
    super(props);
    this.state = {
      showEmailInDirectory: props.user.show_email || false,
      editPersionalInfo: false,
      alert_message: '',
      alert_type: '',
      show: false,           
			loaded: true,
      timeZones: timeZones(),
      states: [],
      imageUploading: false,
      fitnessProfileInfoData: props.userSettingsLocation || {},
      fitnessProfileInfoDataLoaded: true,
      showPhoneInDirectory: props.user.show_phone || false,
      showConfirmationEmailVerifyPopup: false,
      unconfirmedEmail: '',
      showUnconfirmedAlertPopup: false,
      userData: {},
      alert_message_header: '',
      show_header_alert: false
    }
  }

  componentDidMount(){
    this.getInformationData()
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  getInformationData = () => {
    getStates(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          states: result.states,
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  setInformationData = (params, type) => {
    this.setState({
      loaded: false,
      imageUploading: type == 'pic' ? true : false
    })
    updateUserDetails(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), params).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
        let message = ""
        if(Object.keys(result).includes('pic') && result?.pic){
          message = "Profile Image Updated."
        }else{
          message = "Profile Successfully Updated."
        }
        let header_message = ''
        if(Object.keys(result).includes('header')){
          header_message = result?.header
        }
        this.setState({
          alert_message: result?.message,
          alert_type: 'success',
          loaded: true,
          imageUploading: false,
          alert_message_header: header_message
        })
        if(header_message !== ""){
          this.setState({
            show_header_alert: true,
          })
        }else{
          this.setState({
            show: true,
          })
        }
        if(type == 'persional_info'){
          this.setState({
            editPersionalInfo: false
          })
        }
        if(result?.email){
          this.setState({
            unconfirmedEmail: result?.email,
            showUnconfirmedAlertPopup: true,
            userData: result?.user
          })
        }else{
          window.setTimeout(()=>{
            this.props.handleUpdateUser(result?.user)
          },1000)
        }
        
			}else{
        this.setState({
          show: true,
          alert_message: result.error,
          alert_type: 'danger',
          loaded: true,
          imageUploading: false
        })
			}
      this.setState({},()=>{
        window.setTimeout(()=>{
          this.setState({show:false, show_header_alert: false})
        },3000)
      });
		})
  }

  setShowEmailInDirectory = (data) => {
    this.setState({
      showEmailInDirectory: data,
      alert_message: "Display email in directory updated successfully.",
    })
    const params = {show_email: data, show_phone: this.state.showPhoneInDirectory}
    this.handleUpdateDisplayInDirectory(params)
  }

  handleUpdateDisplayInDirectory = (params) => {
    axiosRequest('/api/v3/my_profile/update_display_in_directory', 'PATCH', params, 'data').then(result => {
			if(result.status === 200){
        if(this.state.alert_message !== ""){
          this.showPopup(true, 'success', this.state.alert_message)
        }
			}else{
			}
      window.setTimeout(()=>{
        this.setState({show: false})
      }, 3000)
		})
  }

  handleEditPersionalInfo = (data) => {
    this.setState({
      editPersionalInfo: data
    })
  }

  closeAlert = () => {
    this.setState({
      show: false,
      show_header_alert: false
    })
  }

  showPopup = (show, alert_type, alert_message) => {
    this.setState({
      alert_message: alert_message,
      alert_type: alert_type,
      show: show
    })
  }

  setShowPhoneInDirectory = (data) => {
    this.setState({
      showPhoneInDirectory: data,
      alert_message: "Display phone number in directory updated successfully.",
    })
    const params = {show_email: this.state.showEmailInDirectory, show_phone: data}
    this.handleUpdateDisplayInDirectory(params)
  }

  showEmailVerifyConfirmationPopup = (data) => {
    this.setState({
      showConfirmationEmailVerifyPopup: data
    })
  }

  handleProcessVerificationEmail = (e) => {
    let url = `/api/v3/my_profile/resend_verification_mail?id=${this.props.user?.id}`
    axiosGetRequest(url).then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: result?.success,
          alert_type: 'success',
          showConfirmationEmailVerifyPopup: false,
          show: true
        })
      }else{
        this.setState({
          alert_message: result?.error,
          alert_type: 'danger',
          showConfirmationEmailVerifyPopup: false,
          show: true
        })
      }
      window.setTimeout(() =>{
        this.setState({show: false})
      }, 3000)
    })
  }

  closeUnconfirmedAlertPopup = (data) => {
    this.setState({
      showUnconfirmedAlertPopup: data,
    })
    if(Object.keys(this.state.userData).length !== 0){
      window.setTimeout(()=>{
        this.props.handleUpdateUser(this.state.userData)
      },100)
    }
  }

  render(){
    const { showEmailInDirectory, editPersionalInfo, timeZones, states, alert_type, alert_message, show, loaded, imageUploading, fitnessProfileInfoData, showPhoneInDirectory, showConfirmationEmailVerifyPopup, showUnconfirmedAlertPopup, unconfirmedEmail, show_header_alert, alert_message_header } = this.state
    const { user, business } = this.props
    
    return(
      <React.Fragment>
        {show_header_alert &&
          <CustomAlertPopupWithHeader alert_type={alert_type} alert_message={alert_message} closeAlert={this.closeAlert} header_mesage ={alert_message_header}/>
        }
        {showConfirmationEmailVerifyPopup &&
          <CustomPopup show={showConfirmationEmailVerifyPopup} close={this.showEmailVerifyConfirmationPopup} title={"Email Verification"}>
            <Fragment>
              <div className="mt-3 border-t border-gray-200">
                <div className="py-5 px-7 text-center">
                  Do you want to send another Verification Email to {user?.email}?
                </div>
              </div>
              <div className='flex sm:gap-4 sm:items-center px-7 justify-center mb-4'>
                <button className={`flex items-center px-6 py-2.5 gap-1 border border-gray-300 shadow-sm font-medium rounded-md bg-gray-800 `} onClick={(e) => this.handleProcessVerificationEmail(e)}>
                  <div className="flex items-center gap-1 relative">
                    <div className='text-white text-base'>Yes</div>
                  </div>
                </button>
              </div>
            </Fragment>
          </CustomPopup>
        }
        {showUnconfirmedAlertPopup &&
          <CustomPopup show={showUnconfirmedAlertPopup} close={this.closeUnconfirmedAlertPopup} title={"Update Email Address"}>
            <Fragment>
              <div className="mt-3 border-t border-gray-200">
                <div className="py-5 px-7 text-center">
                  An email has been sent to {unconfirmedEmail} to make sure it is a valid address.
                </div>
              </div>
              <div className='flex sm:gap-4 sm:items-center px-7 justify-center mb-4'>
                <button className={`flex items-center px-6 py-2.5 gap-1 border border-gray-300 shadow-sm font-medium rounded-md bg-gray-800 `} onClick={(e) => this.closeUnconfirmedAlertPopup(false)}>
                  <div className="flex items-center gap-1 relative">
                    <div className='text-white text-base'>Close</div>
                  </div>
                </button>
              </div>
            </Fragment>
          </CustomPopup>
        }
        {show &&
          <AlertPopup alert_type={alert_type} alert_message={alert_message} closeAlert={this.closeAlert}/>
        }
        {!editPersionalInfo ?
            <MemoizedPersionalInfoShow user={user} business={business} showEmailInDirectory={showEmailInDirectory} setShowEmailInDirectory={this.setShowEmailInDirectory} handleEditPersionalInfo={this.handleEditPersionalInfo} showPopup={this.showPopup} showEmailVerifyConfirmationPopup={this.showEmailVerifyConfirmationPopup} showPhoneInDirectory={showPhoneInDirectory} setShowPhoneInDirectory={this.setShowPhoneInDirectory}/>
          :
            <MemoizedPersionalInfoEdit user={user} business={business} handleEditPersionalInfo={this.handleEditPersionalInfo} timeZones={timeZones} states={states} setInformationData = {this.setInformationData} loaded={loaded} imageUploading={imageUploading}/>
        }
        <FitnessProfileInfo user={user} business={business} fitnessProfileInfoData = {fitnessProfileInfoData}/>
      </React.Fragment>
    )
  }
}