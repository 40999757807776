import React, { Fragment, useEffect, useState } from "react";
import CustomPopupContainerComponent from '../../../../customPopupContainerComponent';
import { SearchIcon } from '../../../../../../subRequests/assets';
import { InfoIcon, TextNotificationIcon, MailNotificationIcon, PushNotificationIcon } from '../../../../../assets';
import { axiosGetRequest, axiosRequest } from '../../../../../../subRequests/helpers/helpers';
import { debounce } from "lodash";
import ReactTooltip from "react-tooltip";

export default function InviteMoreUserListComponent(props){
  const { eventId, showInviteMorePopup, closeInviteMorePopup, handleUpdateEvent, handleSetAlert, type, sentBucket } = props
  const [ searchText, setSearchText ] = useState("")
  const [ inviteMoreUsersData, setInviteMoreUsersData ] = useState([]);
  const [ inviteMoreUsers, setInviteMoreUsers ] = useState([]);
  const [ selectedUser, setSelectedUser ] = useState([]);
  const [ disableConfirmBtn, setDisableConfirmBtn ] = useState(false)
  const [ loaded, setLoaded ] = useState(false)
  const [ usersIds, setUsersIds ] = useState([])

  useEffect(() => {
    processFetchInviteMoreUsers()
  }, [])

  const processFetchInviteMoreUsers = () => {
    let url= `/api/v3/business_events/${eventId}/get_invite_more_instructors`
    axiosGetRequest(url).then((response) => {
      if (response?.status === 200) {
        setInviteMoreUsersData(response?.users);
        setInviteMoreUsers(response?.users);
        setUsersIds(response.users?.map(item => item.id))
        setLoaded(true);
      } else {
        setInviteMoreUsersData([]);
        setInviteMoreUsers([]);
        setLoaded(true);
        closeInviteMorePopup(false);
      }
    });
  }
  
  const handleConfirm = () => {
    let url = `/api/v2/invite_more_instructors`
    let params = {
      invited_instructors: selectedUser,
      id: eventId,
      gql_event_details: true,
      preview: type !== 'detail',
      bucket_type: type !== 'detail' ? sentBucket : ''
    }
    setDisableConfirmBtn(true);
    axiosRequest(url, 'POST', params, 'data').then((result) => {
      if(result?.status == 200){
        setDisableConfirmBtn(false);
        handleSetAlert(true, "Boom! Your sub request has been sent", 'success');
        handleUpdateEvent(result?.event);
        closeInviteMorePopup(false);
      }else{
        setDisableConfirmBtn(false);
        handleSetAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleSetAlert(false, '', ''); 
      }, 3000)
    });
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    serachUsers(e.target.value);
  }

  const serachUsers = debounce((val) => {
    processSearchInviteData(val.trim());
  }, 800);

  const processSearchInviteData = (text) => {
    if (text && text !== "") {
      let users = inviteMoreUsersData.filter((user) => {
        return (
          user?.full_name.toLowerCase().includes(text.toLowerCase()) ||
          user?.email.toLowerCase().includes(text.toLowerCase())
        );
      });
      setUsersIds(users?.map(item => item.id))
      setInviteMoreUsers(users);
    } else {
      setUsersIds(inviteMoreUsersData?.map(item => item.id))
      setInviteMoreUsers(inviteMoreUsersData);
    }
  };

  const checkAllPeopleSelect = () => {
    let check = (usersIds.every(v => selectedUser.includes(v)) && usersIds?.length !== 0)
    return check
  }

  const handleSelectAllPeople = (e) => {
    var result = usersIds
    let selected_people = selectedUser
    
    if(e.target.checked){
      setSelectedUser([...new Set([...selected_people, ...result])])
    }else{
			let new_peoples = selected_people.filter(item => !result.includes(item))    
      setSelectedUser(new_peoples)
    }
  }

  const handleSelectPeople = (e) => {
    let val = parseInt(e.target.value)
    var __FOUND = selectedUser.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newUsers = [...selectedUser, val]
        setSelectedUser(newUsers)
      }
    }else{
      if(__FOUND){
        let newUsers = selectedUser.filter((tag, index) => tag !== val)  
        setSelectedUser(newUsers)
      }      
    }
  }

  return(
    <Fragment>
      <CustomPopupContainerComponent show={showInviteMorePopup} close={closeInviteMorePopup} title={''} customWidth={'sm:max-w-4xl'}>
        {!loaded && (
          <Fragment>
            <div className="flex flex-col items-center w-full justify-center h-72">
              <img className="w-9" src="/assets/business/loading.gif" />
            </div>
          </Fragment>
        )}
        {loaded && (
          <div className="px-7 pt-3 font-open-sans">
            <div className="text-center font-bold text-xl font-open-sans">
              Invite Instructor to Sub
            </div>
            <div className="flex text-lg items-center font-semibold text-gray-900 gap-2 mt-5">
              Invite Instructor to Sub 
              <div data-tip data-for={'Invite_more_info_icon'}>
                <InfoIcon classNames={"h-4 w-4"}/>
              </div>
              <ReactTooltip className='max-w-xs' id={'Invite_more_info_icon'} place="top" effect="solid">
                These are the instructors who will be invited to sub based on skill, locations, and availability of their profile for this sub request
              </ReactTooltip>
            </div>
            <div className="bg-gray-100 rounded-xl py-3 px-3.5 mb-4 mt-2">
              <div className="flex items-center">
                <SearchIcon classNames="h-5 w-5 text-gray-400" />
                <input
                  type="text"
                  className="ml-1.5 focus:outline-none w-full bg-transparent text-sm"
                  placeholder="Search for user accross any location"
                  value={searchText}
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div>
            <div className="max-h-72 shadow border overflow-auto border-b border-gray-200 sm:rounded-lg">
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div>
                        <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={checkAllPeopleSelect() == true ? true : false} value={'all'} onChange={(e) => handleSelectAllPeople(e)}/>
                      </div>
                    </th>
                    <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                      <div className='flex items-center'>
                        <span className=''>Instructors</span> &nbsp; &nbsp;
                      </div>
                    </th>
                    <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                      <div className='flex items-center'>
                        <span className=''>Matched Skills</span> &nbsp; &nbsp;
                      </div>
                    </th>
                    <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                      <div className='flex items-center'>
                        <span className=''>Location</span> &nbsp; &nbsp;
                      </div>
                    </th>
                    <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                      <div className='flex items-center'>
                        <span className=''>Notification Preference</span> &nbsp; &nbsp;
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                  {inviteMoreUsers.map((usr, i) => 
                    <tr key={i} className='bg-white relative'>
                      <td className='px-3 py-4 text-sm font-medium text-gray-900'>
                        <input type="checkbox" className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1`} value={usr?.id} checked={selectedUser.includes(usr?.id)} onChange={(e) => handleSelectPeople(e)}/>
                      </td>
                      <td className='px-3 py-3'>
                        <div className="flex items-center gap-2">
                          <div className="w-6 h-6 rounded-full bg-gray-300">
                            <img className="w-full h-full rounded-full" src={usr?.image ||"/assets/default-profile.jpg"}/>
                          </div>
                          <div className='text-sm font-medium text-gray-900'>
                            {usr.full_name}
                          </div>
                        </div>
                      </td>
                      <td className='px-3 py-3'>
                        <div className='text-sm text-gray-900 font-medium whitespace-nowrap'>
                          {usr?.user_matched_skills}
                        </div>
                      </td>
                      <td className='px-3 py-3'>
                        <div className='text-sm font-medium text-gray-900'>
                          {usr?.location_name}
                        </div>
                      </td>
                      <td className='px-3 py-3'>
                        <div className='flex gap-x-2 items-center text-gray-900'>
                          <div data-tip data-for={usr.id.toString()+'user_notification_preference'}>
                          <InfoIcon classNames={"h-3 w-3"}/>
                          </div>
                          <ReactTooltip className='max-w-xs' id={usr.id.toString()+'user_notification_preference'} place="top" effect="solid">
                            {usr?.daily_notifications == 'Immediate' ? 'Receiving every sub request invite immediately at the time it was sent.' : 'Receiving sub request invites once per day as a daily summary at 6pm.'}
                          </ReactTooltip>
                          <div className='text-sm font-medium'>
                            {usr?.daily_notifications}
                          </div>
                          {usr?.push_notifications &&
                            <Fragment>
                              <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-300" data-tip data-for={usr.id.toString()+'user_push_notification_preference'}>
                                <PushNotificationIcon classNames={`w-3 h-3`}/>
                              </div>
                              <ReactTooltip className='max-w-xs' id={usr.id.toString()+'user_push_notification_preference'} place="top" effect="solid">
                                {'Push Notifications'}
                              </ReactTooltip>
                            </Fragment>
                          }
                          {usr?.sms_notifications &&
                            <Fragment>
                              <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-300" data-tip data-for={usr.id.toString()+'user_text_notification_preference'}>
                                <TextNotificationIcon classNames={`w-3 h-3`}/>
                              </div>
                              <ReactTooltip className='max-w-xs' id={usr.id.toString()+'user_text_notification_preference'} place="top" effect="solid">
                                {'Text Notifications'}
                              </ReactTooltip>
                            </Fragment>
                          }
                          {usr?.email_notifications &&
                            <Fragment>
                              <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-300" data-tip data-for={usr.id.toString()+'user_mail_notification_preference'}>
                                <MailNotificationIcon classNames={`w-3 h-3`}/>
                              </div>
                              <ReactTooltip className='max-w-xs' id={usr.id.toString()+'user_mail_notification_preference'} place="top" effect="solid">
                                {'Mail Notifications'}
                              </ReactTooltip>
                            </Fragment>
                          }
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="mt-5 mb-3 flex gap-3 justify-center">
              <button 
                className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-40 py-2 text-sm font-semibold ${disableConfirmBtn ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={() => handleConfirm()}
                disabled={disableConfirmBtn}
              >
                Confirm
              </button>
            </div>
          </div>
        )}
        
      </CustomPopupContainerComponent>
    </Fragment>
  )
}