import React, { Fragment, useState } from "react";
import { axiosPostRequestWithoutData, axiosRequest } from './../../../helpers/helpers'
import CustomPopupComponent from './../customPopupComponent/customPopupComponent';

export default function CancelConfirmationPopupComponent(props){
  const { eventId, showCancelPopup, closeCancelPopup, handleSetAlert, handleCloseAndReloadData, type, eventData, calendarEvent, handleUpdateCalendarEvent } = props
  const [ disableContinueBtn, setDisableContinueBtn ] = useState(false)

  const handleContinueCancel = () => {
    setDisableContinueBtn(true)
    let url = ''
    let params = {
      key: 'undo_cancel',
      preview: false
    }
    if(type == 'detail'){
      url = `/api/v3/business_events/${eventId}/perform_more_actions`
    }else{
      url = `/api/v2/cancel_sub_request?id=${eventId}&gql_event_details=true&preview=${type !== 'detail'}&calendar_event=${calendarEvent}`
    }
    (type == 'detail' ? axiosRequest(url, 'PATCH', params, 'data') : axiosPostRequestWithoutData(url)).then((result) => {
      if(Object.keys(result).includes('status') && result?.status === 200){
        setDisableContinueBtn(false);
        handleSetAlert(true, result?.success, 'success');
        closeCancelPopup(false);
        handleCloseAndReloadData(result?.event);
        if(calendarEvent) handleUpdateCalendarEvent(result?.calendar_event, 'updated');
      }else{
        setDisableContinueBtn(false);
        handleSetAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleSetAlert(false, '', ''); 
      }, 3000)
    })
  }

  return(
    <Fragment>
      <CustomPopupComponent show={showCancelPopup} close={closeCancelPopup} title={!eventData?.cancelled ? 'Cancel Sub Request' : 'Undo Cancellation'}>
        <div className="px-4 pt-3 font-open-sans">
          <div className='text-sm px-2 font-semibold text-gray-700 mt-3 text-center'>
            {!eventData?.cancelled ? `Ah bummer! Please confirm this specific class is cancelled because nobody could sub. Upon confirmation, we'll mark it red on the Sub Schedule and update Insights. Make sure to also cancel this class in your scheduling system.` : `Please confirm this specific class is NO longer cancelled because nobody could sub. Upon confirmation, we'll mark it as back open on the Sub Schedule and update Insights. Make sure to also update this class in your scheduling system.`}
          </div>
          <div className="mt-5 flex gap-3 flex-row-reverse">
            <button 
              className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-40 py-2 text-sm font-semibold ${disableContinueBtn ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => handleContinueCancel()}
              disabled={disableContinueBtn}
            >
              Confirmed!
            </button>
            <button 
              className={`bg-white border rounded-xl text-gray-800 flex items-center justify-center w-40 py-2 text-sm hover:bg-gray-100 font-semibold shadow`}
              onClick={() => closeCancelPopup(false)}
            >
              Opps, never mind!
            </button>
          </div>
        </div>
      </CustomPopupComponent>
    </Fragment>
  )
}