import React, { useState } from "react";
import CustomPopupComponent from "../../../../customPopupComponent/customPopupComponent";

export default function AutoApproveConfirmationComponent(props){
  const { showAutoApproveModal, setShowAutoApproveModal, handleProcessUpdate } = props
  const [ disableContinueBtn, setDisableContinueBtn ] = useState(false)
  const handleContinueReopen = () => {
    setDisableContinueBtn(true)
    handleProcessUpdate('accept')
    setShowAutoApproveModal(false)
  }
  return(
    <CustomPopupComponent show={showAutoApproveModal} close={setShowAutoApproveModal} title={'Auto Approve Confirmation'}>
      <div className="px-4 pt-3 font-open-sans">
        <div className='text-sm px-2 font-semibold text-gray-700 mt-3 text-center'>If you are the first to accept this request, you will be automatically approved to sub.</div>
        <div className="mt-5 flex gap-3 flex-row-reverse">
          <button 
            className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-32 py-2 text-sm ${disableContinueBtn ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => handleContinueReopen()}
            disabled={disableContinueBtn}
          >
            Confirm
          </button>
          <button 
            className={`bg-white border rounded-xl text-gray-800 flex items-center justify-center w-32 py-2 text-sm hover:bg-gray-100 shadow`}
            onClick={() => setShowAutoApproveModal(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </CustomPopupComponent>
  )
}