import React, { Fragment } from 'react'

export default function ResetSelectionComponent (props){
  const { selectedPeople, setTheState } = props

  const handleReset = () => {
    setTheState('selectedPeople', [])
  }


  return(
    <Fragment>
      <div className="relative filters_box">
        <button className={`border rounded-md border-gray-300 shadow-sm text-gray-700 text-sm py-2 px-4 h-10 block cursor-pointer flex items-center bg-white hover:bg-gray-100`} onClick={(e) => handleReset(e)}>
          Reset
        </button>
      </div>
    </Fragment>
  )
}