import React, { Fragment, useState } from "react";
import { axiosRequest } from '../../../../subRequests/helpers/helpers'
import { checkTheSignUpPagePath } from '../../../helpers';

export default function ConfirmSmsNotificationsComponent(props) {
  const { user, setAlert, handleUpdateUser, setShowGetNotifications, notifications } = props
  const [ btnDisabled, setBtnDisabled ] = useState(false)

  const handleSaveNotifications = (e) => {
    e.preventDefault()
    setBtnDisabled(true)
    let params = {
      notification_value: notifications
    }
    let url = '/api/v3/update_mobile_notifications'
    axiosRequest(url, 'POST', params, 'data').then(result => {
      if(result.status == 200){
        setAlert(true, 'success', result?.success)
        setTimeout(() => {
          setAlert(false, '', '')
          setBtnDisabled(false);
        }, 3000);
        let currentUser = {
          ...user,
          away: notifications == 'away' ? true : user?.away,
          daily_notifications: 'daily',
          set_mobile_notifications: true
        }
        handleUpdateUser(currentUser, checkTheSignUpPagePath(currentUser))
      }else{
        setAlert(true, 'danger', result?.error)
        setTimeout(() => {
          setAlert(false, '', '')
        }, 3000);
        setBtnDisabled(false);
      }
    })
  }
  
  return(
    <Fragment>
      <div className="w-full flex flex-col items-center justify-center mt-4 px-2">
        <div className="flex flex-col sm:flex-row items-start justify-center w-full md:w-3/5 lg:w-3/6 xl:w-2/5 gap-2 sm:gap-4">
          <div className="flex flex-col items-center w-full sm:w-4/5 gap-2 sm:gap-4">
            <div className="flex items-center justify-center text-center text-lg text-gray-900 font-normal">
              Can you please confirm you want to receive SMS notifications? Reminder, receiving SMS notifications is optional.
            </div>
            <div className="font-light text-center text-gray-600 text-sm">
              You'll be able to receive, accept, decline, or approve sub requests via text. No more mass email or texts and their reply-alls!
            </div>
          </div>
          <div className="flex flex-col items-center w-full sm:w-1/5">
            <figure>
							<img src="/assets/notification-msg .png" alt="" className="mobile-pic" />
						</figure>
          </div>
        </div>
        <div className='flex items-center justify-center w-full mt-7 gap-2'>
          <button className={`bg-white border hover:bg-gray-100 shadow rounded-md text-black flex items-center justify-center px-1 py-2 text-sm w-1/2 md:w-1/3 lg:w-1/5 ${btnDisabled ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={(e) => setShowGetNotifications(false)} disabled={btnDisabled}>
            Oops, never mind
          </button>
          <button
            className={`bg-gray-900 rounded-md text-white flex items-center justify-center px-1 py-2 text-sm w-1/2 md:w-1/3 lg:w-1/5 ${btnDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={(e) => handleSaveNotifications(e)}
            disabled={btnDisabled}
          >
            Yes, let's do it
          </button>
        </div>
      </div>
    </Fragment>
  )
}