import React, { useState, Fragment } from 'react';
import AttendanceCardComponent from './../attendanceCardComponent';
import { AttendanceUpdatePopupComponent } from '../attendanceUpdatePopupComponent';
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import LoaderComponent from '../../../subRequests/pages/components/loaderComponent/loaderComponent';
import Pagination from "react-js-pagination";

export default function AttendanceListComponent(props) {
  const { abcClasses, handleAlert, page, pageSize, total, handlePageChange, getAttendance, paginatonLoader, loadingMessage, setLoaded } = props;
  const [showPopup, setShowPopup] = useState(false);
  const [abcClass, setAbcClass] = useState(null);
  const [disableConfirmBtn, setDisableConfirmBtn] = useState(false);

  const handleAttendanceProcess = (e, cls) => {
    e.preventDefault();
    setAbcClass(cls);
    setShowPopup(true);
  }

  const handleClose = (data) => {
    setShowPopup(data);
    setAbcClass(null);
  }

  const handleUpdateAttendance = (e, cls, attendees) => {
    e.preventDefault();
    setDisableConfirmBtn(true)
    
    UpdateAttendance(cls, attendees)
  }

  const UpdateAttendance = (cls, attendees) => {
    let url = '/api/v2/update_attendance'
    let params = {
      id: cls?.id,
      member_count_from_instructor: (['', undefined, null].includes(attendees) ? '' : parseInt(attendees))
    }
    axiosRequest(url, 'POST', params, 'data').then(result => {
      if(result?.status == 200){
        setDisableConfirmBtn(false)
        handleAlert(true, 'success', result?.message)
        handleClose(false)
        setLoaded(false)
        getAttendance(1)
      }else{
        setDisableConfirmBtn(false)
        handleAlert(true, 'danger', (result?.error || 'There is an issue in updating class attendance.'))
        handleClose(false)
      }

      setTimeout(() => {
        handleAlert(false, '', ''); 
      }, 3000)
    })
  }

  return (
    <Fragment>
      {(showPopup &&
        <AttendanceUpdatePopupComponent show={showPopup} close={handleClose} cls={abcClass} handleUpdateAttendance={handleUpdateAttendance} disableConfirmBtn={disableConfirmBtn} />
      )}
      {!paginatonLoader && 
        <LoaderComponent loadingMessage={loadingMessage} />
      }
      <div className='max-w-xl'>
        {paginatonLoader &&
          <div className='mt-5 mx-auto gap-x-4 gap-y-8 lg:mx-0 lg:max-w-none overflow-x-auto'>
            {abcClasses.map((cls, i) =>
              <div key={i}>
                <AttendanceCardComponent cls={cls} handleAttendanceProcess={handleAttendanceProcess} />
              </div>
            )}
            {abcClasses.length == 0 &&
              <div className='bg-white shadow-md items-center justify-between rounded-lg px-3 py-5 mb-2 text-center text-sm text-gray-500'>
                You have no classes requiring attendance!
              </div>
            }
          </div>
        }
        {(total > pageSize) && (
          <div className="flex justify-center w-full flex-wrap mt-6 pb-2 mb-6">
            <div className="custom-pagination order-first sm:order-last sm:mb-0 mr-7 list-view-pagination">
              <Pagination
                activePage={page}
                itemsCountPerPage={pageSize}
                totalItemsCount={total}
                pageRangeDisplayed={9}
                onChange={handlePageChange}
                itemClassPrev='previous'
                itemClassNext='next'
                itemClassFirst='first'
                itemClassLast='last'
                hideDisabled={true}
                activeClass={'pagination-active-tab'}
              />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  )
}
    
