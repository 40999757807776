import React, { Fragment, useState } from 'react';
import { integratedBusiness, showCancelButton, showResendButton, axiosGetRequest } from './../../../../../helpers/helpers';
import { UserIcon, MessageIcon, TashIcon, SendIcon } from './../../../../../assets';

export default function OpenEventsButtonsComponent(props){
  const { event, business, handleShowAssignPopup, handleAddComment, user, handleShowCancelPopup, handleAlert, closeAndReloadData, path, cancelOptionsOnly } = props
  const [ disableResendButton, setDisableResendButton ] = useState(false)

  const handleResendInvite = () => {
    setDisableResendButton(true)
    let url = `/api/v2/resend_invites?id=${event?.id}&inst_id=&gql_event_details=true`
    axiosGetRequest(url).then((result) => {
      if(Object.keys(result).includes('success')){
        handleAlert(true, result?.success, 'success');
      }else{
        setDisableResendButton(false);
        handleAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleAlert(false, '', ''); 
        closeAndReloadData(result?.event);
      }, 3000)
    })
  }

  return(
    <Fragment>
      <div className='flex gap-1.5 mt-3'>
        {(['all-requests']?.includes(path)) &&
          <Fragment>
            {event?.show_assign_button &&
              <button className='w-full tc-11 font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5' onClick={() => handleShowAssignPopup('assign')}>
                <UserIcon classNames={'wc-14 hc-14'}/>
                Assign
              </button>
            }
          </Fragment>
        }
        {event?.show_resend_invite_button &&
          <button className={`w-full tc-11 font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5 px-1.5`} onClick={() => handleResendInvite()}>
            <SendIcon classNames={'wc-14 hc-14 transform -rotate-45 mb-0.5 ml-0.5'}/>
            {['all-requests']?.includes(path) ? 'Resend' : 'Resend invite'}
          </button>
        }
        {business?.comments_feature &&
          <button className='w-full tc-11 font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5 px-1.5' onClick={() => handleAddComment()}>
            <MessageIcon classNames={'wc-14 hc-14'}/>
            Comment
          </button>
        }
        {(event?.show_cancel_button && cancelOptionsOnly?.length > 0) &&
          ((['all-requests']?.includes(path)) ?
            <button className='flex justify-center items-center border border-gray-300 rounded-full min-h-8 min-w-8' onClick={() => handleShowCancelPopup()}>
              <TashIcon classNames={'text-black wc-14 hc-14'}/>
            </button>
          :
            <button className='w-full tc-11 font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5' onClick={() => handleShowCancelPopup()}>
              <TashIcon classNames={'text-black wc-14 hc-14'}/>
              Cancel
            </button>
          )
        }
      </div>
    </Fragment>
  )
}