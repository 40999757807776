import React, { Fragment, useState } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import DayViewEventComponent from './dayViewEventComponent';
import { hourlySortedEvents, modifyDayData, dayWeekViewTimeGridTime } from './../../../../helpers/helpers';
import DayViewPlusMorePopupComponent from './dayViewPlusMorePopupComponent';

export default function DayViewCalendarComponent(props){
  const { events, calendarRef, currentDate, handleEventClick, hanldeClickOnErrorEvent, showAllEvents } = props
  const allSortedEvents = hourlySortedEvents(events)
  const groupedEvents = modifyDayData(events)
  const [ plusMoreEvents, setPlusMoreEvents ] = useState([])
  const [ showEventsPopup, setShowEventsPopup ] = useState(false)
  const totalEvents = showAllEvents? events : Object.values(groupedEvents).flat();

  const handleClickAllEvents = (info) => {
    if(info?.event?._def?.extendedProps?.more_button){
      let allHourEvents = allSortedEvents[info?.event?._def?.extendedProps?.varKey]
      setPlusMoreEvents(allHourEvents)
      setShowEventsPopup(true)
    }else{
      handleEventClick(info)
    }
  }

  const closeEventsPopup = (data) => {
    setPlusMoreEvents([])
    setShowEventsPopup(data)
  }

  const timeGridDayViewOptions = {
    slotLabelContent: ({ date }) => dayWeekViewTimeGridTime(date)
  };

  const views = {
    timeGridDay: timeGridDayViewOptions,
  };
  
  return(
    <Fragment>
      {showEventsPopup &&
        <DayViewPlusMorePopupComponent events={plusMoreEvents} showEventsPopup={showEventsPopup} closeEventsPopup={closeEventsPopup} hanldeClickOnErrorEvent={hanldeClickOnErrorEvent} type={"day"} moreEventsDate={''}/>
      }
      <FullCalendar
        ref={calendarRef}
        height = {'auto'}
        plugins={[dayGridPlugin, timeGridPlugin]}
        initialView="timeGridDay"
        initialDate={currentDate}
        events={totalEvents}
        eventContent={DayViewEventComponent}
        views={views}
        eventTimeFormat= { {
          hour12: true,
          hour: 'numeric',
          minute: 'numeric',
          meridiem: true
        } }
        eventClick={(info) => {handleClickAllEvents(info)}}
        slotDuration="00:15:00"
        slotLabelInterval="00:30:00"
      />
    </Fragment>
  )
}