import React, { Fragment, useState, useRef } from "react";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { CalendarIcon } from '../../../../../assets';
import DatePicker from "react-datepicker";
import { formatTimeRange } from '../../../../../helpers';

export default function TimeDropDownComponent(props) {
  const { eventDateTime, eventStartDateTime, eventEndDateTime, handleSaveDateTime, newClass, i } = props
  const menuButtonRef = useRef(null);
  const datePicker1Ref = useRef(null);
  const datePicker2Ref = useRef(null);
  const closeButtonRef = useRef(null);
  const [focusedElement, setFocusedElement] = useState(null)

  const handleChangeDateOnlyInTime = (oldDateTime, newDate) => {
    return (new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), oldDateTime.getHours(), oldDateTime.getMinutes(), oldDateTime.getSeconds()))
  }

  const handleTimeChange = (e, name) => {
    let startTime = eventStartDateTime
    let endTime = eventEndDateTime
    if (name == 'startTime') {
      if(e == null) {
        startTime = ''
      }else{
        startTime = handleChangeDateOnlyInTime(e, eventDateTime)
        let newEndTime = new Date(startTime)
        newEndTime.setMinutes(newEndTime.getMinutes() + 45);
        endTime = handleChangeDateOnlyInTime(newEndTime, eventDateTime)
      }
    } else {
      if(e == null) {
        endTime = ''
      }else{
        endTime = handleChangeDateOnlyInTime(e, eventDateTime)
      }
    }
    handleSelectedTimes(eventDateTime, startTime, endTime)
  }

  const handleSelectedTimes = (eventDate, startTime, endTime) => {
    handleSaveDateTime(eventDate, startTime, endTime)
    // menuButtonRef.current.click()
  }

  const setRef = node => {
    if (node) {
      $(`.time-need-border-${i}`).addClass('filter-dynamic-border')
    }else if (node === null) {
      $(`.time-need-border-${i}`).removeClass('filter-dynamic-border')
      setFocusedElement(null)
    }
  };

  const dateToShow = () => {
    let timeFormat = formatTimeRange(eventStartDateTime, eventEndDateTime)
    return (timeFormat)
  }

  const handleApplyFilter = () => {
    menuButtonRef.current.click()
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      if (focusedElement === null || focusedElement === '') {
        datePicker1Ref.current.setFocus();
        setFocusedElement('datePicker1');
      } else if (focusedElement === 'datePicker1') {
        datePicker2Ref.current.setFocus();
        setFocusedElement('datePicker2');
      } else if (focusedElement === 'datePicker2') {
        closeButtonRef.current.focus();
        setFocusedElement('closeButton');
      } else if (focusedElement === 'closeButton') {
        datePicker1Ref.current.setFocus();
        setFocusedElement('datePicker1');
      }
    }
  }
  
  return(
    <Fragment>
      <div className={`relative filters_box ${i == 0 ? 'w-full' : 'date-time-selector-width'}`}> 
        <Menu as="div" className="relative block text-left">
          <div>
            <Menu.Button className={`flex justify-center w-full rounded-2xl pl-4 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 h-11 gap-1 items-center border-2 border-transparent time-need-border-${i}`} ref={menuButtonRef}>
              <CalendarIcon classNames={'w-5 h-5'}/>
              <p className="truncate filter-text-field text-left">{dateToShow()}</p>
              <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            // show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items ref={setRef} className="origin-top-right z-30 right-0 w-full rounded-2xl bg-white shadow-md border bordar-gray-200 focus:outline-none mt-2 absolute" onKeyDown={handleKeyDown}>
              <Fragment>
                <div className='flex flex-col w-full p-4'>
                  <div className='grid grid-cols-1 sm:grid-cols-2 mb-4 gap-2 time-picker-wrapper'>
                    <div className='flex flex-col gap-2'>
                      <div className='font-sm font-semibold'>Start time</div>
                      <div className={`flex justify-start w-full rounded-2xl pl-4 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 h-11 gap-1 items-center create-sub-request-time-picker border-2 ${focusedElement === 'datePicker1' ? 'filter-dynamic-border' : 'border-transparent'}`} tabIndex="0" >
                        <CalendarIcon classNames={'w-5 h-5 shrink-0'}/>
                        <div className='w-32'>
                          <DatePicker
                            ref={datePicker1Ref}
                            className='text-neutral-500 font-medium font-open-sans fontc-15'
                            selected={eventStartDateTime}
                            onChange={(date) => handleTimeChange(date, 'startTime')}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={5}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                      {(eventStartDateTime == '' || (newClass?.selectedDateTime?.blockBeforeHours > 0 && eventStartDateTime <= newClass?.selectedDateTime?.minDate)) && 
                        <div className='text-red-500 text-xs font-semibold'>Start time is invalid</div>
                      }
                    </div>
                    <div className='flex flex-col gap-2'>
                      <div className='font-sm font-semibold'>End time</div>
                      <div className={`flex justify-start w-full rounded-2xl pl-4 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 h-11 gap-1 items-center create-sub-request-time-picker border-2 ${focusedElement === 'datePicker2' ? 'filter-dynamic-border' : 'border-transparent'}`} tabIndex="0">
                        <CalendarIcon classNames={'w-5 h-5 shrink-0'}/>
                        <div className='w-32'>
                          <DatePicker
                            ref={datePicker2Ref}
                            className='text-neutral-500 font-medium font-open-sans fontc-15'
                            selected={eventEndDateTime}
                            onChange={(date) => handleTimeChange(date, 'endTime')}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={5}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                      {(eventEndDateTime == '' || eventEndDateTime <= eventStartDateTime) && 
                        <div className='text-red-500 text-xs font-semibold'>End time is invalid</div>
                      }
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <button ref={closeButtonRef} className={`w-full bg-gray-900 text-white text-center text-base font-semibold rounded-2xl px-5 h-12 border-2 ${focusedElement === 'closeButton' ? 'filter-dynamic-border' : 'border-transparent'}`} onClick={(e) => handleApplyFilter(e)}>
                      Okay
                    </button>
                  </div>
                </div>
              </Fragment>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}