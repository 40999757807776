import React, { Fragment } from 'react';
import { PlusIcon } from './../../../assets';
import { Link } from 'react-router-dom';

export default function CreateSubRequestButtonComponent(props){
  const { user, permissions, business } = props
  const isDisabled = (!user?.user_activated || !user?.enable_request_sub)
  
  const urlAsPerRole = (e) => {
    if (isDisabled){
      e.preventDefault()
    }
  }
  
  return(
    <Fragment>
      {(!['ops', 'Ops'].includes(user?.role) && permissions?.sub_request?.write) && 
        <Fragment>
          <Link to={'/create-sub-request'} className={`bg-dark-blue text-sm text-white rounded-2xl flex items-center justify-center px-5 py-3 gap-x-2 font-medium ${(isDisabled) ? 'cursor-not-allowed opacity-50' : ''}`} onClick={(e) => urlAsPerRole(e)} disabled={isDisabled}>
            <PlusIcon classNames={'w-4 h-4'}/>
            Create sub request
          </Link>
        </Fragment>
      }
    </Fragment>
  )
}