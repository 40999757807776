import React, { Fragment, useState, useRef } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { locationName } from './../../../helpers'
import { seprateOutLocations } from '../../../../../../subRequests/helpers/helpers'

export default function LocationComponent(props){
  const { locationSettings, showFilterOther, selectedRegions, selectedLocations, setTheState, setSelLocationsHandler } = props
  const menuButtonRef = useRef(null);
  const [ allSelectedLocations, setAllSelectedLocations ] = useState(selectedLocations)
  const [ allSelectedRegions, setAllSelectedRegions ] = useState(selectedRegions)
  const locationList = seprateOutLocations(locationSettings) || []

  const selectedAllRegLoc = () => {
    let check = allSelectedRegions?.length === locationSettings?.length
    return check
  }

  const selectAll = (e) => {
    if(e.target.checked){
      let all = locationSettings
      let selected_regions = []
      let selected_locations = []
      for(var i=0; i<all.length;i++){     
        selected_regions.push(all[i]['region']['id'])
        for(var j=0; j<all[i]['locations'].length; j++){
          selected_locations.push(all[i]['locations'][j]['id'])
        }
      }
      setAllSelectedLocations(selected_locations)
      setAllSelectedRegions(selected_regions)
    }else{
      setAllSelectedLocations([])
      setAllSelectedRegions([])
    }
  }

  const setSelLocations = () => {
    setTheState('selectedLocations', allSelectedLocations)
    setTheState('selectedRegions', allSelectedRegions)
    setTimeout(()=>{
      setSelLocationsHandler()
    }, 100)
  }

  const cancelLocationFilter = () => {
    menuButtonRef.current.click()
  }

  const selectRegions = (e, region) => {
    let val = parseInt(e.target.value)

    let regions = allSelectedRegions
   
    var __FOUND = regions.indexOf(val) > -1;
    var result = region.locations.map(function(a) {return a.id;});
    
    let selected_locations = allSelectedLocations;
    
    if(e.target.checked){
      if(!__FOUND){
        setAllSelectedRegions([...allSelectedRegions, val])
        selected_locations = [...new Set([...allSelectedLocations, ...result])];
        setAllSelectedLocations(selected_locations)
        
      }
    }else{
      if(__FOUND){
        setAllSelectedRegions(allSelectedRegions.filter((tag, index) => tag !== val))
        selected_locations = selected_locations.filter(item => !result.includes(item));
        setAllSelectedLocations(selected_locations)
      }
    }
  }

  const selectLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = allSelectedLocations.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newlocs = [...allSelectedLocations, val]
        setAllSelectedLocations(newlocs)
        checkRegionVisibility(region, newlocs)
      }
    }else{
      if(__FOUND){
        let newLocs = allSelectedLocations.filter((tag, index) => tag !== val)  
        setAllSelectedLocations(newLocs)
        checkRegionVisibility(region, newLocs)  
      }      
    }
    
  }

  const checkRegionVisibility = (region, locations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(locations, location_ids)
    let val = region.region?.id

    let regions = allSelectedRegions
    var __FOUND = regions.indexOf(val) > -1;
    if(equal){
      if(!__FOUND){
        setAllSelectedRegions([...allSelectedRegions, val])
      }
    }else{
      if(__FOUND){
        setAllSelectedRegions(allSelectedRegions.filter((tag, index) => tag !== val))
      }
    }
  }

  const setRef = node => {
    if (node) {
      
    }else if (node === null) {
      processOpenLocationsFilter()
    }
  };

  const processOpenLocationsFilter = () => {
    setAllSelectedLocations(selectedLocations)
    setAllSelectedRegions(selectedRegions)
  }

  return(
    <Fragment>
      <div className="relative filters_box w-60">
        <Menu as="div" className="relative block text-left ">
          <div>
            <Menu.Button className="inline-flex items-center h-10 justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" ref={menuButtonRef}>
              {allSelectedLocations?.length === locationList?.length && <p className = "text-gray-900">All Locations</p>
              }
              {allSelectedLocations?.length < locationList?.length &&
                <React.Fragment>
                  {(allSelectedLocations?.length === 1 && allSelectedLocations?.length !== 0) && 
                    <p className = "text-gray-900">{locationName(locationList, allSelectedLocations)}</p>
                  }
                  {(allSelectedLocations?.length > 1 && allSelectedLocations?.length < locationList?.length) && 
                    <div className = "flex text-gray-900">{locationName(locationList, allSelectedLocations)} {(allSelectedLocations?.length - locationName(locationList, allSelectedLocations)?.split(',')?.length) > 0 && <p>+{allSelectedLocations?.length - locationName(locationList, allSelectedLocations)?.split(',')?.length}</p>}</div>
                  }
                </React.Fragment>
              }
              {(allSelectedLocations?.length === 0  && allSelectedLocations?.length !== locationList?.length) && <p>Select location(s)</p>}
              <ChevronDownIcon className="ml-auto w-4 mr-0.5 text-gray-400" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            // show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items ref={setRef} className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none absolute">
              <div className="flex items-center p-2 border-b">
                <div className=" ml-auto">
                  <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                    Select All
                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {selectedAllRegLoc()} onChange = {(e) => selectAll(e)}/>
                  </label>
                </div>
              </div>
              <div className="p-2 max-h-60 overflow-y-auto">
                {locationSettings.map((region, i) =>
                  <div className="py-1" key = {i}>
                    {showFilterOther &&
                      <div>
                        <label className="flex items-center text-gray-900 text-sm mb-2">
                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => selectRegions(e, region)} value={region?.region?.id} checked={allSelectedRegions.includes(region.region.id)}/>
                          {region.region.name}
                        </label>
                      </div>
                    }
                    <div className="ml-5">
                      {region.locations.map((location, j) =>
                      <div key = {j}>
                        <label className="flex items-center text-gray-900 text-sm mb-2">
                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={allSelectedLocations.includes(location?.id)} value={location.id} onChange={(e) => selectLocation(e, region)}/>
                          {location.name}
                        </label>
                      </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="flex items-center p-2 border-t">
                <div className="flex justify-center items-center w-full px-3 gap-3">
                  <div className=''>
                    <a className='bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={(e) => setSelLocations()}>Save</a>
                  </div>
                  <div className=''>
                    <a className='bg-white text-gray-700 border border-gray-200 text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={() => cancelLocationFilter()}>Cancel</a>
                  </div>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}