import React, { Fragment, useState } from 'react'

export default function BehaviorColumnSetting(props){
  const { behaviorColumns, behaviorTheCol1Part1, behaviorTheCol1Part2, behaviorTheCol2Part1, behaviorTheCol2Part2, behaviorTheCol3Part1, behaviorTheCol3Part2, behaviorTheCol4, handleSaveColumns } = props
  const [ editColumn, setEditColumn ] = useState(false)
  const [ editColumnIndex, setEditColumnIndex ] = useState('')
  const [ behaviorCol1Part1, setBehaviorCol1Part1 ] = useState(behaviorTheCol1Part1 || 0)
  const [ behaviorCol1Part2, setBehaviorCol1Part2 ] = useState(behaviorTheCol1Part2 || 0)
  const [ behaviorCol2Part1, setBehaviorCol2Part1 ] = useState(behaviorTheCol2Part1 || 0)
  const [ behaviorCol2Part2, setBehaviorCol2Part2 ] = useState(behaviorTheCol2Part2 || 0)
  const [ behaviorCol3Part1, setBehaviorCol3Part1 ] = useState(behaviorTheCol3Part1 || 0)
  const [ behaviorCol3Part2, setBehaviorCol3Part2 ] = useState(behaviorTheCol3Part2 || 0)
  const [ behaviorCol4, setBehaviorCol4 ] = useState(behaviorTheCol4 || 0)

  const handleEditClick = (e, index) => {
    setEditColumnIndex(index)
    setEditColumn(true)
  }

  const handleSubmit = () => {
    let columnArr = [`${behaviorCol1Part1}-${behaviorCol1Part2}`, `${behaviorCol2Part1}-${behaviorCol2Part2}`, `${behaviorCol3Part1}-${behaviorCol3Part2}`, `${behaviorCol4}`]

    handleSaveColumns(columnArr)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === "behaviorCol1Part1"){
      setBehaviorCol1Part1(value)
    }else if(name === "behaviorCol1Part2"){
      setBehaviorCol1Part2(value)
    }else if(name === "behaviorCol2Part1"){
      setBehaviorCol2Part1(value)
    }else if(name === "behaviorCol2Part2"){
      setBehaviorCol2Part2(value)
    }else if(name === "behaviorCol3Part1"){
      setBehaviorCol3Part1(value)
    }else if(name === "behaviorCol3Part2"){
      setBehaviorCol3Part2(value)
    }else if(name === "behaviorCol4"){
      setBehaviorCol4(value)
    }
  }

  const handleOnBlur = (e) => {
    const { name, value } = e.target
    if(name === "behaviorCol1Part1" && value === ''){
      setBehaviorCol1Part1(0)
    }else if(name === "behaviorCol1Part2" && value === ''){
      setBehaviorCol1Part2(0)
    }else if(name === "behaviorCol2Part1" && value === ''){
      setBehaviorCol2Part1(0)
    }else if(name === "behaviorCol2Part2" && value === ''){
      setBehaviorCol2Part2(0)
    }else if(name === "behaviorCol3Part1" && value === ''){
      setBehaviorCol3Part1(0)
    }else if(name === "behaviorCol3Part2" && value === ''){
      setBehaviorCol3Part2(0)
    }else if(name === "behaviorCol4" && value === ''){
      setBehaviorCol4(0)
    }
  }

  const handleCancelEdit = (e) => {
    setEditColumnIndex('')
    setEditColumn(false)
  }

  return(
    <Fragment>
      <div className="mt-3 border-t border-gray-200 mb-2">
        <div className='flex border-b border-gray-200 justify-between sm:gap-4 sm:items-center px-7 py-3'>
          {(editColumn && editColumnIndex === 1 ) ?
            <Fragment>
              <div className="flex text-center items-center mt-1 sm:mt-0 sm:col-span-2 text-xs sm:text-sm gap-2">
                <div>
                  <input type="number" min="0" id ={`behaviorCol1Part1`} className="border rounded-md px-1 py-1.5 w-10 shadow-sm" name={`behaviorCol1Part1`} value = {behaviorCol1Part1} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}/>
                </div>
                -
                <div>
                  <input type="number" min="0" id ={`behaviorCol1Part2`} className="border rounded-md px-1 py-1.5 w-10 shadow-sm" name={`behaviorCol1Part2`} value = {behaviorCol1Part2} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}/>
                </div>
                  Days Notice
              </div>
              <div className='flex gap-3'>
                <button className={`flex text-xs sm:text-sm items-center px-2 py-1 gap-1 border border-gray-300 shadow-sm font-normal rounded-md text-white bg-gray-800`} onClick={(e) => handleCancelEdit(e)}>
                  Done
                </button>
              </div>
            </Fragment>
            :
            <Fragment>
              <div className="mt-1 sm:mt-0 sm:col-span-2 text-sm">
                {behaviorCol1Part1} - {behaviorCol1Part2} Days Notice
              </div>
              {!editColumn &&
                <button onClick={(e) => handleEditClick(e, 1)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                </button>
              }
            </Fragment>
          }
        </div>
        <div className='flex border-b border-gray-200 justify-between sm:gap-4 sm:items-center px-7 py-3'>
          {(editColumn && editColumnIndex === 2) ?
            <Fragment>
              <div className="flex text-center items-center mt-1 sm:mt-0 sm:col-span-2 text-xs sm:text-sm gap-2">
                <div>
                  <input type="number" min="0" id ={`behaviorCol2Part1`} className="border rounded-md px-1 py-1.5 w-10 shadow-sm" name={`behaviorCol2Part1`} value = {behaviorCol2Part1} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}/>
                </div>
                -
                <div>
                  <input type="number" min="0" id ={`behaviorCol2Part2`} className="border rounded-md px-1 py-1.5 w-10 shadow-sm" name={`behaviorCol2Part2`} value = {behaviorCol2Part2} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}/>
                </div>
                  Days Notice
              </div>
              <div className='flex gap-3'>
                <button className={`flex text-xs sm:text-sm items-center px-2 py-1 gap-1 border border-gray-300 shadow-sm font-normal rounded-md text-white bg-gray-800`} onClick={(e) => handleCancelEdit(e)}>
                  Done
                </button>
              </div>
            </Fragment>
            :
            <Fragment>
              <div className="mt-1 sm:mt-0 sm:col-span-2 text-sm">
                {behaviorCol2Part1} - {behaviorCol2Part2} Days Notice
              </div>
              {!editColumn &&
                <button onClick={(e) => handleEditClick(e, 2)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                </button>
              }
            </Fragment>
          }
        </div>
        <div className='flex border-b border-gray-200 justify-between sm:gap-4 sm:items-center px-7 py-3'>
          {(editColumn && editColumnIndex === 3) ?
            <Fragment>
              <div className="flex text-center items-center mt-1 sm:mt-0 sm:col-span-2 text-xs sm:text-sm gap-2">
                <div>
                  <input type="number" min="0" id ={`behaviorCol3Part1`} className="border rounded-md px-1 py-1.5 w-10 shadow-sm" name={`behaviorCol3Part1`} value = {behaviorCol3Part1} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}/>
                </div>
                -
                <div>
                  <input type="number" min="0" id ={`behaviorCol3Part2`} className="border rounded-md px-1 py-1.5 w-10 shadow-sm" name={`behaviorCol3Part2`} value = {behaviorCol3Part2} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}/>
                </div>
                  Days Notice
              </div>
              <div className='flex gap-3'>
                <button className={`flex text-xs sm:text-sm items-center px-2 py-1 gap-1 border border-gray-300 shadow-sm font-normal rounded-md text-white bg-gray-800`} onClick={(e) => handleCancelEdit(e)}>
                  Done
                </button>
              </div>
            </Fragment>
            :
            <Fragment>
              <div className="mt-1 sm:mt-0 sm:col-span-2 text-sm">
                {behaviorCol3Part1} - {behaviorCol3Part2} Days Notice
              </div>
              {!editColumn &&
                <button onClick={(e) => handleEditClick(e, 3)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                </button>
              }
            </Fragment>
          }
        </div>
        <div className='flex border-b border-gray-200 justify-between sm:gap-4 sm:items-center px-7 py-3'>
          {(editColumn && editColumnIndex === 4) ?
            <Fragment>
              <div className="flex text-center items-center mt-1 sm:mt-0 sm:col-span-2 text-xs sm:text-sm gap-2">
                <div>
                  <input type="number" min="0" id ={`behaviorCol4`} className="border rounded-md px-1 py-1.5 w-10 shadow-sm" name={`behaviorCol4`} value = {behaviorCol4} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}/>
                </div>
                  Days Notice
              </div>
              <div className='flex gap-3'>
                <button className={`flex text-xs sm:text-sm items-center px-2 py-1 gap-1 border border-gray-300 shadow-sm font-normal rounded-md text-white bg-gray-800`} onClick={(e) => handleCancelEdit(e)}>
                  Done
                </button>
              </div>
            </Fragment>
            :
            <Fragment>
              <div className="mt-1 sm:mt-0 sm:col-span-2 text-sm">
                {behaviorCol4} Days Notice
              </div>
              {!editColumn &&
                <button onClick={(e) => handleEditClick(e, 4)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                </button>
              }
            </Fragment>
          }
        </div>
        <div className='flex sm:gap-4 sm:items-center px-7 justify-center pt-4'>
          <button className={`flex items-center px-6 py-2.5 gap-1 border border-gray-300 shadow-sm font-medium rounded-md bg-gray-800 `} onClick={(e) => handleSubmit(e)}>
            <div className="flex items-center gap-1 relative">
              <div className='text-white text-base'>Save</div>
            </div>
          </button>
        </div>
      </div>
    </Fragment>
  )
}