import React, { Component, Fragment } from 'react';
import InstructorHistory from './pages/instructor_history'
import Behavior from './pages/behavior'
import Responsiveness from './pages/responsiveness'
import { axiosGetRequest } from './helpers'
import FiltersComponent from './components/filtersComponent.js'
import InsightLayout from './components/navbar'
import AvailabilitySearch from './../availability/pages/search'
import AlertPopup from './../messenger/pages/chatComponents/assets/alertPopup'
import CustomPopup from './assets/customPopup'
import BehaviorColumnSetting from './components/behaviorColumnSetting'
import { updateBehaviorColumns } from './helpers'
import Cookies from 'js-cookie';
import { getSudomain } from './../helpers'
import HomeClubReport from './pages/home_club_report'

export default class AnalyticsLayout extends Component{
  constructor(props){
    super(props)
    const date = new Date();
    date.setMonth(date.getMonth() - 1, 1);
    this.state = {
      activeTab: 'instructor_history',
      analyticsInstructors: {},
      loaded: false,
      analyticsRegionLocations: {},
      historyUserId: '',
      historyStartDate: date,
      historyEndDate: new Date(),
      historyType: 'sent',
      selectedLocations: [],
      selectedRegions: [],
      behaviourAllData: {},
      regionLocations: {},
      showFilterOther: false,
      searchText: '',
      loadTableData: true,
      responsivenessAllData: {},
      show: false,
      alert_type: '',
      alert_message: '',
      behaviorCol1Part1: 0,
      behaviorCol1Part2: 1,
      behaviorCol2Part1: 1,
      behaviorCol2Part2: 3,
      behaviorCol3Part1: 3,
      behaviorCol3Part2: 7,
      behaviorCol4: 7,
      sortType: 'total',
      sortReverse: true,
      showBehaviorColumSettingPopup: false,
      behaviorColumns: [],
      historyUserName: ''
    }
  }

  componentDidMount(){
    this.getPageUrl()
    this.props.setActive('Insights')
    if (this.props.pageLoaded){
      this.get_data()
    }
  }

  getPageUrl = () => {
    let params = new URLSearchParams(window.location.search)
    let type = (params.has('page') && params.get('page') !== '') ? params.get('page') : ''
    if(type === '' || ![ 'instructor_history', 'availability', 'behavior', 'responsiveness', 'home_club_report' ].includes(type)){
      window.history.pushState(`path:analytics?page=instructor_history`, '', `/analytics?page=instructor_history`)
      this.setState({activeTab: 'instructor_history'})
    }else{
      if(type == 'availability'){
        this.setState({activeTab: 'availability'})
      }else if(type == 'behavior'){
        this.setState({activeTab: 'behavior'})
      }else if(type == 'responsiveness'){
        this.setState({activeTab: 'responsiveness'})
      }else if(type == 'home_club_report'){
        // if(this.props?.business?.reports){
          this.setState({activeTab: 'home_club_report'})
        // }else{
        //   window.history.pushState(`path:analytics?page=instructor_history`, '', `/analytics?page=instructor_history`)
        //   this.setState({activeTab: 'instructor_history'})
        // }
      }else{
        let id = (params.has('id') && params.get('id') !== '') ? params.get('id') : ''
        let name = (params.has('name') && params.get('name') !== '') ? params.get('name') : ''
        if(name !== ''){
          if(name.includes('%20')){
            name = name.replace('%20', ' ')
          }
        }
        this.setState({
          historyUserName: name,
          historyUserId: id,
          activeTab: 'instructor_history',
        })
      }
    }
  }

  get_data(){
    axiosGetRequest("/api/v3/analytics/get_analytics_instructors").then(result => {
      if(result.status === 200){
        this.setState({
          analyticsInstructors: result?.results,
          // loaded: true
        })
        this.getRegionsLocations()
      }
    })    
  }

  getRegionsLocations(){
    axiosGetRequest("/api/v3/get_analytics_regions_locations").then(result => {
      if(result.status === 200){
        this.setState({
          analyticsRegionLocations: result,
          selectedLocations: result?.business_location_ids,
          selectedRegions: result?.business_region_ids,
          regionLocations: result?.regions_and_locations,
          showFilterOther: result?.show_others,
          loaded: true
        })
        if (this.state.activeTab == 'behavior'){
          this.setState({loadTableData: false})
          this.handleFetchBehaviourData(result?.business_region_ids, result?.business_location_ids, this.state.historyStartDate, this.state.historyEndDate, '', 1, 20, 'total', true)
          this.getBehaviorColumns()
        }
        else if(this.state.activeTab == 'responsiveness'){
          this.setState({loadTableData: false})
          this.handleFetchResponsivenessData(result?.business_region_ids, result?.business_location_ids, this.state.historyStartDate, this.state.historyEndDate, '', 1, 20, 'total', true)
        }
      }
    })
  } 

  getBehaviorColumns = () => {
    axiosGetRequest("/api/v3/analytics/get_behavior_columns").then(result => {
      if(result.status === 200){
        this.setState({
          behaviorCol1Part1: parseInt(result?.behavior_col1_part1),
          behaviorCol1Part2: parseInt(result?.behavior_col1_part2),
          behaviorCol2Part1: parseInt(result?.behavior_col2_part1),
          behaviorCol2Part2: parseInt(result?.behavior_col2_part2),
          behaviorCol3Part1: parseInt(result?.behavior_col3_part1),
          behaviorCol3Part2: parseInt(result?.behavior_col3_part2),
          behaviorCol4: parseInt(result?.behavior_col4),
          behaviorColumns: result?.behavior_columns
        })
      }
    })
  }

  componentWillUnmount(){
    this.setState = (state,callback)=>{
      return;
    };
  }

  setSelectedRegions = (data) => {
    this.setState({selectedRegions: data})
  }

  setSelectedLocations = (data) => {
    this.setState({selectedLocations: data})
  }

  setSearchText = (data) => {
    this.setState({searchText: data})
  }

  setHistoryStartDate = (data) => {
    this.setState({historyStartDate: data})
  }

  setHistoryEndDate = (data) => {
    this.setState({ historyEndDate: data})
  }

  handleBehaviourUserClick = (user_id, user_name) => {
    window.history.pushState(`path:analytics?page=instructor_history`, '', `/analytics?page=instructor_history`)
    this.setState({
      historyUserId: user_id,
      historyUserName: user_name,
      historyType: 'sent',
      activeTab: 'instructor_history'
    })
  }

  handleResponsiveUserClick = (user_id, user_name) => {
    window.history.pushState(`path:analytics?page=instructor_history`, '', `/analytics?page=instructor_history`)
    this.setState({
      historyUserId: user_id,
      historyUserName: user_name,
      historyType: 'subbed',
      activeTab: 'instructor_history'
    })
  }

  handleClickOnSearch = (locations_ids, region_ids, start_date, end_date, search) => {
    this.setState({
      selectedLocations: locations_ids,
      selectedRegions: region_ids,
      historyStartDate: start_date,
      historyEndDate: end_date,
      searchText: search
    })
    if (this.state.activeTab == 'behavior'){
      this.setState({loadTableData: false})
      this.handleFetchBehaviourData(region_ids, locations_ids, start_date, end_date, search, 1, 20, 'total', true)
    }else if(this.state.activeTab == 'responsiveness'){
      this.setState({loadTableData: false})
      this.handleFetchResponsivenessData(region_ids, locations_ids, start_date, end_date, search, 1, 20, 'total', true)
    }
  }

  handleFetchBehaviourData = (selectedRegions, selectedLocations, startDate, endDate, searchText, activePage, pageSize, sortType, sortReverse) => {
    axiosGetRequest(`/api/v3/analytics/index?region_ids=${selectedRegions}&location_ids=${selectedLocations}&start_date=${startDate}&end_date=${endDate}&text=${searchText}&page=${activePage}&page_size=${pageSize}&sort_type=${sortType}&sort_reverse=${sortReverse}`).then(result => {
      if(result.status === 200){
        this.setState({
          behaviourAllData: result,
          loadTableData: true
        })
      }
    })
  }

  handleFetchResponsivenessData = (selectedRegions, selectedLocations, startDate, endDate, searchText, activePage, pageSize, sortType, sortReverse) => {
    axiosGetRequest(`/api/v3/analytics/responsiveness?region_ids=${selectedRegions}&location_ids=${selectedLocations}&start_date=${startDate}&end_date=${endDate}&text=${searchText}&page=${activePage}&page_size=${pageSize}&sort_type=${sortType}&sort_reverse=${sortReverse}`).then(result => {
      if(result.status === 200){
        this.setState({
          responsivenessAllData: result,
          loadTableData: true
        })
      }
    })
  }

  handleFetchSortResponsiveData = (activePage, pageSize, sortType, sortReverse) => {
    this.setState({
      loadTableData: false,
      sortType: sortType,
      sortReverse: sortReverse
    })
    this.handleFetchResponsivenessData(this.state.selectedRegions, this.state.selectedLocations, this.state.historyStartDate, this.state.historyEndDate, '', activePage, pageSize, sortType, sortReverse)
  }

  handleFetchSortBehaviourData = (activePage, pageSize, sortType, sortReverse) => {
    this.setState({
      loadTableData: false,
      sortType: sortType,
      sortReverse: sortReverse
    })
    this.handleFetchBehaviourData(this.state.selectedRegions, this.state.selectedLocations, this.state.historyStartDate, this.state.historyEndDate, '', activePage, pageSize, sortType, sortReverse)
  }

  handleTabChange = (data) => {
    window.history.pushState(`path:analytics?page=${data}`, '', `/analytics?page=${data}`)
    this.setState({activeTab: data})
    if (data == 'behavior'){
      this.setState({
        loadTableData: false,
        sortType: 'total',
        sortReverse: true,
        searchText: ''
      })
      this.handleFetchBehaviourData(this.state.selectedRegions, this.state.selectedLocations, this.state.historyStartDate, this.state.historyEndDate, '', 1, 20, 'total', true)
      this.getBehaviorColumns()
    }
    else if(data == 'responsiveness'){
      this.setState({
        loadTableData: false,
        sortType: 'total',
        sortReverse: true,
        searchText: ''
      })
      this.handleFetchResponsivenessData(this.state.selectedRegions, this.state.selectedLocations, this.state.historyStartDate, this.state.historyEndDate, '', 1, 20, 'total', true)
    }
  }

  closeAlert = () => {
    this.setState({
      show: false
    })
  }

  showPopup = (show, alert_type, alert_message) => {
    this.setState({
      alert_message: alert_message,
      alert_type: alert_type,
      show: show
    })
  }

  closeBehaviorColumSettingPopup = () => {
    this.setState({showBehaviorColumSettingPopup: false})
  }

  handleOpenBehaviorColumSettingPopup = () => {
    this.setState({showBehaviorColumSettingPopup: true})
  }

  handleSaveColumns = (data) => {
    updateBehaviorColumns(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), "all", data).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
        this.setState({
          alert_message: result?.message,
          alert_type: "success",
          show: true,
          showBehaviorColumSettingPopup: false
        })
        this.handleFetchBehaviourData(this.state.selectedRegions, this.state.selectedLocations, this.state.historyStartDate, this.state.historyEndDate, '', 1, 20, this.state.sortType, this.state.sortReverse)
        this.getBehaviorColumns()
			}else{
        this.setState({
          alert_message: result?.error,
          alert_type: "danger",
          show: true
        })
			}
      window.setTimeout(() => {
        this.setState({show: false})
      }, 3000)
		})
  }

  render(){
    const { activeTab, analyticsInstructors, loaded, analyticsRegionLocations, historyUserId, historyUserName, historyStartDate, historyEndDate, historyType, selectedLocations, selectedRegions, behaviourAllData, loadTableData, responsivenessAllData, show, alert_message, alert_type, behaviorCol1Part1, behaviorCol1Part2, behaviorCol2Part1, behaviorCol2Part2, behaviorCol3Part1, behaviorCol3Part2, behaviorCol4, sortType, sortReverse, showBehaviorColumSettingPopup, behaviorColumns } = this.state
    const { user, business, loadingMessage, pageLoaded } = this.props
    return(
      <Fragment>
        {showBehaviorColumSettingPopup &&
          <Fragment>
            <CustomPopup show={showBehaviorColumSettingPopup} close={this.closeBehaviorColumSettingPopup} title={"Column Setting"}>
              <BehaviorColumnSetting behaviorColumns={behaviorColumns} behaviorTheCol1Part1={behaviorCol1Part1} behaviorTheCol1Part2={behaviorCol1Part2} behaviorTheCol2Part1={behaviorCol2Part1} behaviorTheCol2Part2={behaviorCol2Part2} behaviorTheCol3Part1={behaviorCol3Part1} behaviorTheCol3Part2={behaviorCol3Part2} behaviorTheCol4={behaviorCol4} handleSaveColumns={this.handleSaveColumns}/>
            </CustomPopup>
          </Fragment>
        }
        {show &&
          <AlertPopup alert_type={alert_type} alert_message={alert_message} closeAlert={this.closeAlert}/>
        }
        {(!loaded) &&
          <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
            <p className="mb-1 text-center">{loadingMessage}</p>
            <img className = "w-9" src="/assets/business/loading.gif" />
          </div>
        }
        {loaded &&
          <Fragment>
            <div className={`flex-1 flex flex-col insights-page-height overflow-auto ${activeTab == 'availability' && 'overflow-hidden'}  bg-gray-50 pt-20 md:pt-8`}>
              <div className='w-full mx-auto sm:px-6 lg:px-8 px-4'>
                {/* Navbar */}
                <InsightLayout handleTabChange={this.handleTabChange} activeTab={activeTab} user={user} business={business}/>

                {/* Instructor History */}
                {activeTab == 'instructor_history' && <InstructorHistory analyticsInstructors = {analyticsInstructors} historyUserId={historyUserId} historyUserName={historyUserName} historyStartDate={historyStartDate} historyEndDate={historyEndDate} historyType={historyType} setHistoryStartDate={this.setHistoryStartDate} setHistoryEndDate={this.setHistoryEndDate} user={user}/>}

                {/* Availability */}
                {activeTab == 'availability' &&
                  <AvailabilitySearch setInsightsOpen={this.props.setInsightsOpen} user = {user} business = {business} loadingMessage={loadingMessage} setCurrent={this.props.setCurrent} pageLoaded = {pageLoaded}/>
                }

                {/* Behavior And Responsiveness */}
                {['behavior', 'responsiveness'].includes(activeTab) &&
                  <React.Fragment>
                    {/* Filters Component */}
                    <FiltersComponent analyticsRegionLocations={analyticsRegionLocations} analyticsInstructors={analyticsInstructors} historyUserId={historyUserId} historyUserName={historyUserName} historyStartDate={historyStartDate} historyEndDate={historyEndDate} handleClickOnSearch={this.handleClickOnSearch} setTheSelectedRegions={this.setSelectedRegions} setTheSelectedLocations={this.setSelectedLocations} setTheSearchText={this.setSearchText} setHistoryStartDate={this.setHistoryStartDate} setHistoryEndDate={this.setHistoryEndDate} activeTab={activeTab} showPopup={this.showPopup} behaviorTheCol1Part1={behaviorCol1Part1} behaviorTheCol1Part2={behaviorCol1Part2} behaviorTheCol2Part1={behaviorCol2Part1} behaviorTheCol2Part2={behaviorCol2Part2} behaviorTheCol3Part1={behaviorCol3Part1} behaviorTheCol3Part2={behaviorCol3Part2} behaviorTheCol4={behaviorCol4} sortType={sortType} sortReverse={sortReverse} handleOpenBehaviorColumSettingPopup={this.handleOpenBehaviorColumSettingPopup}/>

                    {/* Behavior */}
                    {activeTab == 'behavior' && <Behavior handleBehaviourUserClick={this.handleBehaviourUserClick} behaviourAllData = {behaviourAllData} loadTableData={loadTableData} handleFetchSortBehaviourData={this.handleFetchSortBehaviourData} behaviorTheCol1Part1={behaviorCol1Part1} behaviorTheCol1Part2={behaviorCol1Part2} behaviorTheCol2Part1={behaviorCol2Part1} behaviorTheCol2Part2={behaviorCol2Part2} behaviorTheCol3Part1={behaviorCol3Part1} behaviorTheCol3Part2={behaviorCol3Part2} behaviorTheCol4={behaviorCol4}/> }

                    {/* responsiveness */}
                    {activeTab == 'responsiveness' && <Responsiveness handleResponsiveUserClick={this.handleResponsiveUserClick} responsivenessAllData = {responsivenessAllData} loadTableData={loadTableData} handleFetchSortResponsiveData={this.handleFetchSortResponsiveData}/> }
                  </React.Fragment>
                }

                {activeTab == 'home_club_report' &&
                  <HomeClubReport pageLoaded = {pageLoaded}/>
                }
              </div>
            </div>
          </Fragment>
        }
      </Fragment>
    )
  }
}