import React from "react";
import { IntercomProvider } from 'react-use-intercom';
import IntercomManager from '../intercomManager';

export default function IntercomWrapper({ children, isLoggedIn, user, appId }) {
  return (
    <IntercomProvider appId={appId}>
      <IntercomManager isLoggedIn={isLoggedIn} user={user} />
      {children}
    </IntercomProvider>
  );
};