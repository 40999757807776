import React, { Fragment } from "react";
import CustomPopupComponent from './../../../customPopupComponent/customPopupComponent';
import EventsContentComponent from './../../yearViewCalendarComponent/dayEventsPopup/eventsContentComponent';
import DateComponent from './../../yearViewCalendarComponent/dayEventsPopup/dateComponent';

export default function DayViewPlusMorePopupComponent(props){
  const { events, showEventsPopup, closeEventsPopup, hanldeClickOnErrorEvent, type, moreEventsDate } = props

  const handleDayEventClick = (e, id, redirect_url) => {
    e.preventDefault()
    hanldeClickOnErrorEvent(e, id, redirect_url)
    closeEventsPopup(false)
  }

  return(
    <Fragment>
      <CustomPopupComponent show={showEventsPopup} close={closeEventsPopup} title={''}>
        {type == 'week' &&
          <DateComponent clickDate={moreEventsDate}/>
        }
        <div className="mt-2">
          <EventsContentComponent events={events} handleEventClick={handleDayEventClick} />
        </div>
      </CustomPopupComponent>
    </Fragment>
  )
}