import React, { Fragment, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import ResetPasswordFormComponent from '../resetPasswordFormComponent';
import { setCurrentUserToken } from '../../../signIn/helpers';
import LoaderComponent from '../../../subRequests/pages/components/loaderComponent/loaderComponent';

export default function ConfirmResetPasswordComponent(props) {
  const { handleReIntializeAllApiCalls, setAlert, updateUserData, loadingMessage } = props
  const [ redirect, setRedirect ] = useState(false);
  const [ redirectToUrl, setRedirectToUrl ] = useState('');
  const [ showRestPasswordForm, setShowResetPasswordForm ] = useState(false);
  const [ userData, setUserData ] = useState({});
  const [ loaded, setLoaded ] = useState(false)

  const checkUserToken = () => {
    let searchParams = new URLSearchParams(window.location.search)
    let reset_password_token = (searchParams.has('reset_password_token') && searchParams.get('reset_password_token') !== '') ? searchParams.get('reset_password_token') : ''
    if(reset_password_token == ''){
      setRedirectToUrl('/signin')
      setRedirect(true)
      setTimeout(()=>{
        handleReIntializeAllApiCalls()
      }, 500)
      return
    }
    let params = {
      reset_password_token: reset_password_token
    }
    let url = '/api/v3/update_forget_password_token'
    axiosRequest(url, 'POST', params, 'data').then(result => {
      if(result.status == 200){
        setCurrentUserToken(result?.user?.api_token)
        setShowResetPasswordForm(true)
        setUserData(result?.user)
        setLoaded(true)
      }else{
        setRedirectToUrl('/signin')
        setRedirect(true)
        setLoaded(true)
        setTimeout(()=>{
          handleReIntializeAllApiCalls()
        }, 500)
      }
    })
  }

  useEffect(() => {
    checkUserToken()
  }, [])

  return (
    <Fragment>
      {!loaded &&
        <LoaderComponent loadingMessage={loadingMessage} />
      }
      {redirect &&
        <Redirect to={redirectToUrl} />
      }
      {showRestPasswordForm &&
        <ResetPasswordFormComponent
          setAlert={setAlert}
          updateUserData={updateUserData}
          userData={userData}
        />
      }
    </Fragment>
  )
}