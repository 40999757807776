import React, { Fragment, useState, useEffect } from "react";
import CardComponent from './../cardComponent';
import { oldControllerAxiosGetRequest, seprateOutLocations } from './../../../../helpers/helpers';
import EmptyCardsMessageComponent from '../emptyCardsMessageComponent';
import Pagination from "react-js-pagination";
import AvailableFilterModalComponent from '../filterComponents/availableFilterComponents/availableFilterModalComponent';
import { ButtonSpinnerIcon, FilterIcon } from '../../../../assets';

export default function AvailableEventsComponent(props) {
  const { availableEvents, user, business, handleAlert, path, getEventDataWithoutLoad, userHideDeclinedRequests, handleUpdateEvents, handleDeleteEvent, disabledSubBoardPage, page, pageSize, availableEventsTotalCount, handleChangePage, paginationLoader, availableSelectedStatus, pendingCount, acceptCount, declineCount, saveAndApplyFilters, regionLocationsList, showRegions, selectedLocations, selectedRegions, availableBucketLoader } = props;
  const [ hideDeclineRequest, setHideDeclineRequest ] = useState(userHideDeclinedRequests)
  const [ loader, setLoader ] = useState(false)
  const [ showFilterModal, setShowFilterModal ] = useState(false);
  const statusList  = [
    {value: 'pending', label: 'No response'},
    {value: 'accept', label: 'Accepted'},
    {value: 'decline', label: 'Declined'}
  ]
  const [ locationList, setLocationList ] = useState(seprateOutLocations(regionLocationsList) || [])

  useEffect(() => {
    setLocationList(seprateOutLocations(regionLocationsList) || [])
  }, [regionLocationsList])

  const checkStatusValues = (selectedStatus, statusList) => {
    let possibleValues = statusList.map((item) => item.value)
    return selectedStatus.filter((item) => possibleValues.includes(item))
  }
  const [ statusValues, setStatusValues ] = useState(checkStatusValues(availableSelectedStatus, statusList) || availableSelectedStatus)

  useEffect(() => {
    setStatusValues(checkStatusValues(availableSelectedStatus, statusList) || availableSelectedStatus)
  }, [availableSelectedStatus])

  const handlePageChange = (page) => {
    handleChangePage(page)
  }

  const handleCloseModal = (data) => {
    setShowFilterModal(data);
  }

  const processApplyFilter = (data) => {
    saveAndApplyFilters(data, 'available');
    setShowFilterModal(false)
  }

  const checkFilterSelected = () => {
    return ((statusValues?.length > 0 && statusList?.length !== statusValues?.length) || showFilterModal || (selectedLocations?.length > 0 && locationList?.length !== selectedLocations?.length))
  }

  return (
    <Fragment>
      <div className="w-full mb-7 lg:mb-0 relative">
        {showFilterModal &&
          <AvailableFilterModalComponent
            showFilterModal={showFilterModal}
            handleCloseModal={handleCloseModal}
            business={business}
            user={user}
            pendingCount={pendingCount}
            acceptCount={acceptCount}
            declineCount={declineCount}
            statusValues={statusValues}
            processApplyFilter={processApplyFilter}
            statusList={statusList}
            regionLocationsList={regionLocationsList}
            showRegions={showRegions}
            selectedLocations={selectedLocations}
            selectedRegions={selectedRegions}
          />
        }
        {(paginationLoader || loader) &&
          <div className="bg-gray-400 bg-opacity-10 absolute bottom-0 z-10 w-full h-full">
            <div className="msg-loading flex flex-col items-center w-full mx-auto md:ml-20 ml-0 left-0 right-0 fixed top-1/2 z-10">
              <img className = "w-9" src="/assets/business/loading.gif" />
            </div>
          </div>
        }
        <div className="bg-white shadow-md rounded-lg px-3">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex text-black text-sm font-medium py-4">
              Available{" "}
              {!disabledSubBoardPage && availableEvents?.length > 0 ? `(${availableEventsTotalCount})` : ""}
            </div>
            <div className="flex items-center flex-wrap gap-2 relative">
              {availableBucketLoader &&
                <div className="flex items-center -left-6 top-2 justify-center absolute">
                  <ButtonSpinnerIcon classNames={'h-4 w-4 text-black'}/>
                </div>
              }
              {!disabledSubBoardPage &&
                <div className="flex gap-1">
                  <button className={`w-8 h-8 rounded-full flex items-center justify-center ${checkFilterSelected() ? 'bg-dark-blue hover:bg-gray-700' : 'bg-gray-100 hover:bg-gray-200'}`} onClick={() => setShowFilterModal(true)}>
                    <FilterIcon classNames={`w-5 h-5 ${checkFilterSelected() ? 'text-white' : 'text-gray-500'}`} />
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="mt-3">
          {!disabledSubBoardPage &&
            <Fragment>
              {availableEvents?.length > 0 ?
                <Fragment>
                  {availableEvents?.map((event, i) => (
                    <div key={i}>
                      <CardComponent event={event} listType={"Available"} user={user} business={business} handleAlert={handleAlert} processGetEventData={getEventDataWithoutLoad} path={path} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent} setLoader={setLoader} />
                    </div>
                  ))}
                </Fragment>
              :
                <EmptyCardsMessageComponent listType={"Available"}/>
              }
            </Fragment>
          }
        </div>
        {['my-requests'].includes(path) && availableEventsTotalCount > pageSize &&
          <div className="flex justify-center w-full flex-wrap mt-4 pb-2">
            <div className="custom-pagination order-first sm:order-last mb-3 sm:mb-0 mr-7 list-view-pagination">
              <Pagination
                activePage={page}
                itemsCountPerPage={pageSize}
                totalItemsCount={availableEventsTotalCount}
                pageRangeDisplayed={9}
                onChange={handlePageChange}
                itemClassPrev='previous'
                itemClassNext='next'
                itemClassFirst='first'
                itemClassLast='last'
                hideDisabled={true}
                activeClass={'pagination-active-tab'}
              />
            </div>
          </div>
        }
      </div>
    </Fragment>
  );
}