import React, { Fragment, useState, useRef, useEffect } from "react";
import { NotepadIcon } from './../../../../assets';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import ReactTooltip from "react-tooltip";
import { InfoIcon } from '../../../../../subRequestDetails/assets';

export default function ReasonComponent(props) {
  const { newSubRequest, setNewSubRequest, business, businessIntegration } = props
  const menuButtonRef = useRef(null);
  const reasons = ["Vacation", "Sick/Illness/Injury", "Family Emergency" ,"Covering for another teacher - harder class to get covered", "Day Job Conflict / Travel", "Apartment / Home issue (e.g. water leak, etc.)", "Swap", "Other"]

  const handleChangeReason = (e) => {
    handleApplyFilter(e.target.value, newSubRequest.reason?.otherReason)
  }

  const handleClick = (cls) => {
    let div = $('.special-container-class')
    if(div.hasClass(cls)){
      div.removeClass(cls)
    }else{
      div.addClass(cls)
    }
  }

  const handleRemoveExtraPadding = (cls) => {
    let div = $('.special-container-class')
    if(div.hasClass(cls)){
      div.removeClass(cls)
    }
  }

  const setRef = node => {
    if (node) {
      $('.reason-need-border').addClass('filter-dynamic-border')
      if (newSubRequest.reason?.reason !== 'Other') handleClick('pb-16')
    }else if (node === null) {
      $('.reason-need-border').removeClass('filter-dynamic-border')
      handleRemoveExtraPadding('pb-16')
    }
  };

  const handleApplyFilter = (selectedReason, otherReason) => {
    setNewSubRequest(prevNewSubRequest => ({
      ...prevNewSubRequest,
      reason: {reason: selectedReason, otherReason: otherReason}
    }))
    menuButtonRef.current.click()
  }

  return(
    <Fragment>
      <div className="flex flex-col gap-2 filter-column justify-end">
        <div className="flex text-base md:text-lg text-black font-semibold items-center gap-2 flex-wrap">
          Reason for Request
          <div className="flex items-center gap-2">
            {!business?.request_reason_optional && <span className="text-black text-sm font-semibold">(*Required)</span>}
            <div data-tip="true" data-for={`tooltip_for_sub-request_reason`}>
              <InfoIcon classNames={"h-4 w-4"} customColour={"#4b5563"}/>
              <ReactTooltip className='max-w-xs text-center' id={`tooltip_for_sub-request_reason`} place="top" effect="solid">
                <p>{"The reason is only visible to management"}</p>
              </ReactTooltip>
            </div>
          </div>
        </div>
        <div className="relative filters_box"> 
          <Menu as="div" className="relative block text-left">
            <div>
              <Menu.Button className="flex justify-center w-full rounded-2xl pl-4 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 h-11 gap-1 items-center opacity-c-100 border-2 border-transparent reason-need-border" ref={menuButtonRef}>
                <NotepadIcon classNames={'w-5 h-5 shrink-0'} />
                {/* <p className="truncate filter-text-field text-left" style={{width: `0px`}}> */}
                <p className="truncate filter-text-field text-left">
                  {newSubRequest.reason?.reason == '' ?
                    'Select Reason for sub request'
                  :
                    newSubRequest.reason?.reason
                  }
                </p>
                <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              // show={true}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items ref={setRef} className="origin-top-right z-30 right-0 w-full rounded-2xl bg-white shadow-md border bordar-gray-200 focus:outline-none mt-2 filter_items absolute">
                <div className="my-4 px-4 max-h-60 overflow-y-auto">
                  {reasons?.map((reason, i) => 
                    <label key = {i} className="flex text-gray-900 text-sm mb-2 justify-between cursor-pointer">
                      <div className="flex text-left font-medium text-base text-gray-900 pr-2">
                        {reason}
                      </div>
                      <div className="assign-checkbox flex mt-1">
                        <input
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer"
                          value={reason}
                          checked={newSubRequest.reason?.reason == reason}
                          onChange={(e) => handleChangeReason(e)}
                        />
                      </div>
                    </label>
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </Fragment>
  )
}