import React, { useEffect, useState } from "react";
import { Avatar, useChatContext } from "stream-chat-react";
import TypingIndicator from './assets/channelListTypingIndicator';
import UnReadBadgeIndecator from './assets/unReadBadgeIndicator';
import { RenderLastMessageText, RenderLastMessageUserName } from './assets/getLastMessageDetails'
import ChannelPreviewTimeStamp from './channelPreviewTimeStamp/channelPreviewTimeStamp'

export const CustomChannelPreview = (props) => {
  const [ typing, setTyping ] = useState(false)
  const [ whoIsTyping, setWhoIsTyping ] = useState('')
  const { channel, setActiveChannel, clickOnChannel, type, dataFormat } = props;
  const [ lastMessageTime, setLastMessageTime ] = useState(channel.state?.last_message_at ? ChannelPreviewTimeStamp(channel.state?.last_message_at, dataFormat) : '')

  const { channel: activeChannel, client } = useChatContext();

  const onTypingStart = (event) => {
    if(event.user.id !== client.user.id){
      setWhoIsTyping(event.user.name)
      setTyping(true)
    }
  }

  const onTypingStop = (event) => {
    if(event.user.id !== client.user.id){
      setTyping(false)
      setWhoIsTyping('')
    }
  }

  useEffect(() => {
    if(channel.state?.last_message_at){
      setLastMessageTime(ChannelPreviewTimeStamp(channel.state?.last_message_at, dataFormat))
    }
    channel.on('typing.start', event => onTypingStart(event));
    channel.on('typing.stop', event => onTypingStop(event));
    return () => {
      channel.off('typing.start', event => onTypingStart(event));
      channel.off('typing.stop', event => onTypingStop(event));
    }
  }, [channel])

  const ChannelPreview = () => (
    <React.Fragment>
      <div className="str-chat__channel-preview-messenger--left">
        <Avatar
          image={channel?.data.image || undefined}
          name={channel?.data.name || channel?.data.id}
          size={40}
        />
      </div>
      <div className={`str-chat__channel-preview-messenger--right str-chat__channel-preview-end`}>
        <div className='str-chat__channel-preview-messenger--right'>
          <div className='str-chat__channel-preview-end-first-row'>
            <div className='str-chat__channel-preview-messenger--name'>
              <span className="cut-text pr-0.5">{(channel.data?.name?.length > 25 ? `${channel.data?.name?.slice(0, 25)}...` : channel.data?.name) || 'Channel'}</span>
              <div className="relative min-h-20 flex items-center">
                {lastMessageTime !== '' &&
                  <div className={`msg-time ${channel.state.unreadCount > 0 && 'font-bold'}`}>
                    {lastMessageTime}
                  </div>
                }
                <UnReadBadgeIndecator channel={channel}/>
              </div>
            </div>
            <p className='channel-preview__content-name'>{channel.data?.subtitle}</p>
          </div>
          {typing ?
            (<TypingIndicator whoIsTyping={whoIsTyping}/>)
          :
            <React.Fragment>
              <RenderLastMessageUserName channel={channel} client={client}/>
              <div className='str-chat__channel-preview-messenger--last-message'>
                <p><RenderLastMessageText channel={channel}/></p>
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    </React.Fragment>
  );

  const renderDmName = (members) => {
    let names = members.map((usr) => {return usr.user.name})
    let name = names.join(', ')
    let len = 25
    let shortName = []
    for(let i = 0; i < name?.length; i++){
      if(i < len){
        shortName.push(name[i])
      }else{
        shortName.push('...')
        break
      }
    }
    let groupName = shortName.join('') 
    
    return(<span className="cut-text pr-0.5">{groupName}</span>)
  }

  const DirectPreview = () => {
    const members = Object.values(channel.state.members).filter(
      ({ user }) => user.id !== client.userID
    );
    members.sort((a, b) => a.user.name.localeCompare(b.user.name, 'es', {sensitivity: 'base'}))
    const defaultName = client.user.name;

    if (!members.length || members.length === 1) {
      return (
        <React.Fragment>
          <div className="str-chat__channel-preview-messenger--left single">
            <Avatar
              image={members[0]?.user.image || undefined}
              name={members[0]?.user.name || members[0]?.user.id}
              size={40}
            />
            {/* <TeamTypingIndicator type="list" /> */}
          </div>
          <div className={`str-chat__channel-preview-messenger--right str-chat__channel-preview-end`}>
            <div className='str-chat__channel-preview-messenger--right'>
              <div className='str-chat__channel-preview-end-first-row'>
                <div className='str-chat__channel-preview-messenger--name'>
                  <span className="cut-text pr-0.5">{members[0]?.user.name || members[0]?.user.id || defaultName}</span>
                  <div className="relative min-h-20 flex items-center">
                    {lastMessageTime !== '' &&
                      <div className={`msg-time ${channel.state.unreadCount > 0 && 'font-bold'}`}>
                        {lastMessageTime}
                      </div>
                    }
                    <UnReadBadgeIndecator channel={channel}/>
                  </div>
                </div>
                <p className='channel-preview__content-name'>{channel.data?.subtitle}</p>
              </div>
              {typing ?
                (<TypingIndicator whoIsTyping={whoIsTyping}/>)
              :
                <div className='str-chat__channel-preview-messenger--last-message'>
                  <p><RenderLastMessageText channel={channel}/></p>
                </div>
              }
            </div>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className="str-chat__channel-preview-messenger--left multi">
          {channel?.data.image && channel?.data.image !== '' ?
              <Avatar
                image={channel?.data.image || members[0].user?.image}
                name={members[0].user?.name || members[0].user?.id}
                size={40}
              />
            :
              <Avatar
                image={members[0].user?.image}
                name={members[0].user?.name || members[0].user?.id}
                size={40}
              />
          }
          {/* <span>
            <span className="bg-gray-300 p-1 py-0 text-xs rounded-md">{members.length}</span>
          </span> */}
          {/* <TeamTypingIndicator type="list" /> */}
        </div>
        <div className={`str-chat__channel-preview-messenger--right str-chat__channel-preview-end`}>
          <div className='str-chat__channel-preview-messenger--right'>
            <div className='str-chat__channel-preview-end-first-row'>
              <div className='str-chat__channel-preview-messenger--name'>
                {renderDmName(members)}
                <div className="relative min-h-20 flex items-center">
                  {lastMessageTime !== '' &&
                    <div className={`msg-time ${channel.state.unreadCount > 0 && 'font-bold'}`}>
                      {lastMessageTime}
                    </div>
                  }
                  <UnReadBadgeIndecator channel={channel}/>
                </div>
              </div>
            </div>
            {typing ?
              (<TypingIndicator whoIsTyping={whoIsTyping}/>)
              :
              <React.Fragment>
                <RenderLastMessageUserName channel={channel} client={client}/>
                <div className='str-chat__channel-preview-messenger--last-message'>
                  <p><RenderLastMessageText channel={channel}/></p>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      </React.Fragment>
    )
  };

  return (
    <button aria-label={`Select Channel: ${channel.data?.name || 'Channel'}`} aria-selected={`${channel?.id === activeChannel?.id ?"true" : "false"}`} role="option" className={`${channel?.id === activeChannel?.id ? "str-chat__channel-preview-messenger--active" : ""} str-chat__channel-preview-messenger str-chat__channel-preview ${channel.state.unreadCount > 0 && 'str-chat__channel-preview-messenger--unread'}`} onClick={() => {setActiveChannel(channel); clickOnChannel(channel);}}>
      {channel?.type === "team" ? <ChannelPreview /> : <DirectPreview />}
    </button>
  );
};
