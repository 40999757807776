import React, { Fragment, useState } from 'react';
import { Switch } from '@headlessui/react'
import EditPassword from './editPasswordComponent'
import { axiosRequest, classNames } from './../../../helpers'
import Cookies from 'js-cookie';
import { getSudomain } from '../../../../helpers'
import ReactTooltip from "react-tooltip";

export default function PersionalInfoShow(props){
  const { user, business, showEmailInDirectory, setShowEmailInDirectory, handleEditPersionalInfo, showPopup, showEmailVerifyConfirmationPopup, showPhoneInDirectory, setShowPhoneInDirectory } = props
  const [ editPassword, setEditPassword ] = useState(false)

  const handleSwitch = (e, name) => {
    if(name == 'showEmailInDirectory'){
      setShowEmailInDirectory(!showEmailInDirectory)
    }else if(name == 'showPhoneInDirectory'){
      setShowPhoneInDirectory(!showPhoneInDirectory)
    }
  }

  const processUpdatePassword = (data) => {
    axiosRequest('/api/v3/my_profile/update_password', 'POST', data, 'data').then(result => {
      if(result.status === 200){
        showPopup(true, 'success', result.success)
        setEditPassword(false)
      }else{
        showPopup(true, 'danger', result.error)
      }
      window.setTimeout(()=>{
        showPopup(false, '', '')
      },3000)
    })
  }

  const resendEmailVerification = () => {
    showEmailVerifyConfirmationPopup(true)
  }

  return(
    <React.Fragment>
      {editPassword &&
        <EditPassword editPassword={editPassword} setEditPassword={setEditPassword} processUpdatePassword={processUpdatePassword}/>
      }
      <div className='bg-white rounded-md shadow px-6 py-7 mb-5'>
        <div className="flex items-center mb-4 pb-1">
          <div className="text-lg font-medium flex-auto">Personal Information</div>
          <button className="flex bg-dark-blue text-sm text-white py-2 px-4 rounded-md h-10 items-center" onClick={() => handleEditPersionalInfo(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
            </svg>
            Edit
          </button>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Profile Pic
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
            <div>
              <img src={user?.image} className="w-16 h-16 rounded-full"/>
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Name
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
            <div className='text-sm font-normal text-gray-900'>
              {user?.full_name}
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Email Address
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2'>
            {(user?.unconfirmed_email != null && user?.unconfirmed_email != '') && 
              <div className='mt-1'>
                <svg xmlns="http://www.w3.org/2000/svg" className="text-red-600 h-4 w-4" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={`pending_email_verification`}>
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
                <ReactTooltip className='max-w-xs' id={`pending_email_verification`} place="top" effect="solid">
                  Pending Verification
                </ReactTooltip>
              </div>
            }
            <div>
              <div className='text-sm font-normal'>
                {user?.email}
              </div>
              {(user?.unconfirmed_email != null && user?.unconfirmed_email != '') && 
                <a className='text-sm font-normal text-blue-500 cursor-pointer' onClick={() => resendEmailVerification()}>
                  Resend Verification
                </a>
              }
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Display Email in Directory
          </label>
          <div className='mt-1 sm:col-span-2 flex'>
            <div className='text-sm font-normal'>
              <Switch
                id={`showEmailInDirectory-1`}
                checked={showEmailInDirectory}
                name="showEmailInDirectory"
                onChange={(e) => handleSwitch (e, "showEmailInDirectory")}
                className={classNames(
                  showEmailInDirectory ? 'bg-gray-900' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    showEmailInDirectory ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Phone Number 
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2'>
            <div>
              <div className='text-sm font-normal'>
                {user?.directory_phone_number}
              </div>
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Display Phone Number in Directory
          </label>
          <div className='mt-1 sm:col-span-2 flex'>
            <div className='text-sm font-normal flex gap-2'>
              <Switch
                id={`showPhoneInDirectory-1`}
                checked={showPhoneInDirectory}
                name="showPhoneInDirectory"
                onChange={(e) => handleSwitch (e, "showPhoneInDirectory")}
                className={classNames(
                  showPhoneInDirectory ? 'bg-gray-900' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                )}
                disabled={!user?.directory_phone_number || user?.directory_phone_number === ""}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    showPhoneInDirectory ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
              {(!user?.directory_phone_number || user?.directory_phone_number === "") &&
                <div className='mt-1'>
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-600 h-4 w-4" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={`user_phone_number_tooltip`}>
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                  <ReactTooltip className='max-w-xs whitespace-nowrap' id={`user_phone_number_tooltip`} place="top" effect="solid">
                    Phone number is not present.
                  </ReactTooltip>
                </div>
              }
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Password
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
            <div className='text-sm font-normal'>
              <a className='text-sm font-normal text-blue-500 cursor-pointer' onClick={() => setEditPassword(true)}>
                Edit Password
              </a>
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Time Zone
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
            <div className='text-sm font-normal'>
              {user?.zone}
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            Address
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
            <div className='text-sm font-normal'>
              {user?.full_address}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}