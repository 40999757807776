import React from 'react'
import { useMessageContext } from 'stream-chat-react'

export default function ChannelPreviewTimeStamp(messageCreatedAt, dataFormat){
  // const { messageCreatedAt, customClass,  } = props

  const messageCreatedAtString = messageCreatedAt.toDateString()
  const todaysDateTimeStamp = new Date()
  const todaysDateString = todaysDateTimeStamp.toDateString()

  function getDayName(dateStr, locale){
    var date = new Date(dateStr);
    return date.toLocaleDateString(undefined, { weekday: 'long' });        
  }

  function isDateInThisWeek(date) {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();
  
    // get first date of week
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));
  
    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
  
    // if date is equal or within the first and last dates of the week
    return date >= firstDayOfWeek && date <= lastDayOfWeek;
  }

  const letsTalkAboutDate = () => {
    if(todaysDateString === messageCreatedAtString){
      return messageCreatedAt?.toLocaleTimeString(undefined, {hour12: true, hour: 'numeric', minute: 'numeric'})
    }else{
      const yesterdayDate = new Date(todaysDateString)
      yesterdayDate.setDate(yesterdayDate.getDate() - 1);
      const yseterdayDateString = yesterdayDate.toDateString()
      if(yseterdayDateString === messageCreatedAtString){
        return 'Yesterday'
      }else{
        if(isDateInThisWeek(messageCreatedAt)){
          return getDayName(messageCreatedAt, "nl-NL");
        }else{
          let dateText = messageCreatedAt.toLocaleDateString('en-US')
          let finalDate = dateText
          if(dataFormat == 'D/M/YY'){
            let splitDate = dateText.split('/')
            finalDate = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`
          }
          return finalDate
        }
      }
    }
    
  }
  
  return ( letsTalkAboutDate()
  );
} 