import React, { Component, Fragment } from 'react'
import { getSkills, createSkill, updateSkill, destroySkill, uploadSkills, downloadABCdata } from './helpers'
import Cookies from 'js-cookie';
import { getSudomain, setTitle, checkSettingsPermissios } from './../../../components/helpers'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'
import ReactTooltip from "react-tooltip";

export default class Skills extends Component {
  constructor(props){
    super(props);
    this.state = {
      skills: [],
      show: false,
      alert_type: '',
      alert_message: '',
      loaded: false,
      business_category: {
        name: ''
      },
      showError: false,
      edit: false,
      editSkill: 0,
      edit_category: {
        name: ''
      },
      delete_category: {
        name: ''
      },
      deleteSkill: 0,
      showModal: false,
      dataLoaded: false,
      showEditSkillError: false,
      integrationSkills: []
    }
  }

  getAll = () => {
    getSkills(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          skills: result.results,
          // show: false,
          integrationSkills: result?.integration_skills,
          business_category: {
            name: ''
          },
          edit_category: {
            name: ''
          },
          edit: false,
          editSkill: 0,
          deleteSkill: 0,
          delete_category: {
            name: ''
          },
          showModal: false,
          loaded: true,
          dataLoaded: true
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  componentDidMount = () => {
    setTitle('skills')
    if(Object.keys(this.props?.user).length !== 0 && Object.keys(this.props?.permissions).length !== 0){
      checkSettingsPermissios('skills', this.props)
    }
    if(this.props.pageLoaded){
      this.getAll()
    }
  }

  componentWillUnmount() {    
    this.setState = (state,callback)=>{
      return;
    };
  }

  handleChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;    
    if(value === ''){
      this.setState({
        showError: true
      })
    }else{
      this.setState({
        showError: false
      })
    }
    this.setState({
      business_category: {
        ...this.state.business_category,
        [name]: value
      }
    })    
  }

  addNew = (e) => {
    e.preventDefault()
    createSkill(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.business_category).then(      
      response => response.json()
      .then(result => {        
        if(result.status === 200){
          this.setState({
            alert_message: 'Skill successfully created!',
            alert_type: 'success',            
            loaded: false
          })
          this.getAll()
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger',            
          })
        }
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      })
    )
  }

  setEdit = (e, id, name) => {
    e.preventDefault()
    this.setState({
      edit: true,
      editSkill: id,
      business_category: {
        name: ''
      },
      edit_category: {
        name: name
      }
    })
  }

  stopEdit = (e) => {
    e.preventDefault()
    this.setState({
      edit: false,
      editSkill: 0,
      business_category: {
        name: ''
      },
      edit_category: {
        name: ''
      }
    })
  }

  editSkill = (e, id) => {
    e.preventDefault();
    if(this.state.edit_category.name === ''){
      this.setState({showEditSkillError: true})
      return
    }
    updateSkill(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), id, this.state.edit_category).then(      
      response => response.json()
      .then(result => {        
        if(result.status === 200){
          this.setState({
            alert_message: 'Skill successfully updated!',
            alert_type: 'success',            
            loaded: false
          })
          this.getAll()
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger',            
          })
        }
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      })
    )
  }

  setEditCategory = (e) => {
    e.preventDefault()
    this.setState({
      edit_category: {
        name: e.target.value
      }
    })
    if(e.target.value === ''){
      // this.setState({showEditSkillError: true})
    }else{
      this.setState({showEditSkillError: false})
    }
  }

  handleKeyDown = (e, id) => {
    let { name, value } = e.target;
    if (e.key === "Enter"){
      if (value !== ''){
        e.target.blur();
        this.editSkill(e, id)
      }else{
        this.setState({showEditSkillError: true})
      }
    }
  }

  setDelete = (e, id, name) => {
    e.preventDefault()
    this.setState({
      edit: true,
      deleteSkill: id,
      delete_category: {
        name: name
      },
      showModal: true
    })
  }

  closeModal = (e) => {
    this.setState({
      showModal: false
    })
  }

  processDelete = (e) => {
    e.preventDefault()
    destroySkill(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.deleteSkill).then(      
      response => response.json()
      .then(result => {        
        if(result.status === 200){
          this.setState({
            alert_message: 'Skill successfully deleted!',
            alert_type: 'success',            
            loaded: false
          })
          this.getAll()
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger'            
          })
        }
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      })
    )
  }

  upload = (e) => {
    e.preventDefault()
    uploadSkills(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), e.target.files[0]).then(      
      response => response.json()
      .then(result => { 
        if(result.status === 200){
          this.setState({
            alert_message: 'Skills successfully uploaded!',
            alert_type: 'success',
            loaded: false
          })
          this.getAll()
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger'
          })
        }
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      })
    )
  }

  processDownload = (e) => {
    e.preventDefault()          
    downloadABCdata(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.blob()
      .then(result => { 
        const url = window.URL.createObjectURL(result)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', getSudomain() + "_skills.csv")
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)        
      })
    )
    
  }

  toDisableEditDelete = (skillId) => {
    if((this.props?.business?.fisikal_integration || this.props?.business?.clubready_integration) && this.state.integrationSkills.includes(skillId)){
      return false
    }else{
      return true
    }
  }

  render() {
    const { skills, show, alert_type, alert_message, showError, edit, editSkill, deleteSkill, showModal, loaded, dataLoaded, showEditSkillError, integrationSkills } = this.state
    const categoryName = this.state.business_category.name
    const editName = this.state.edit_category.name
    const deleteName = this.state.delete_category.name
    const { user, business, permissions, loadingMessage } = this.props
    
    return (
      <React.Fragment>
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
        <div className='w-full min-h-screen bg-gray-50'>
          <div className='h-full'>
            {!loaded && 
              <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                <p className="mb-1 text-center px-5">{loadingMessage}</p>
                <img className = "w-9" src="/assets/business/loading.gif" />
              </div>
            }
            {loaded &&
              <div className='max-w-8xl mx-auto h-full'>
                <div className='hidden mt-2 mb-5'>
                  <div className='bg-white break-words p-3 border border-gray-200 rounded-lg'>
                    <p className="text-gray-500 text-sm font-normal">
                      Add the required skills to teach all the classes on your schedule (Yoga, Pilates, Cycling, Conditioning, etc.). Every user in your account will be assigned a set of skills. Along with location, skills will determine who can be invited to sub a specific type of class. For example, if an instructor sends out a sub request for Yoga, then only Yoga instructors can be invited to sub.
                    </p>
                  </div>
                </div>
                <div className='flex flex-wrap justify-center gap-4'>
                  {user.role_display === "Netgym Employee" &&
                    <div>
                      <div className='flex gap-4'>
                        <div className='border rounded-md border-gray-300 hover:bg-gray-100 shadow-sm text-gray-700 text-sm py-2 px-4 relative h-9 flex items-center w-32 cursor-pointer'>Upload CSV
                        <input type="file" className='absolute w-full top-0 left-0 bottom-0 opacity-0 cursor-pointer' required="required" accept=".csv" onChange={(e) => this.upload(e)}/>
                        </div>
                        <div className='grid items-center'>
                          <div className='w-full px-0 lg:flex flex items-center gap-2 flex-wrap'>
                            {(business?.abc_integration) &&
                              <div>
                                <a className='border rounded-md border-gray-300 shadow-sm text-gray-700 text-sm py-2 px-4 h-9 block cursor-pointer' onClick={(e) => this.processDownload(e)}>Download ABC Data</a>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {(permissions?.business_categories?.write || user.role_display === "Netgym Employee") &&
                    <div className='gap-4 flex'>
                      <div className='items-center gap-3'>
                        <input type="text" name="name" onChange={(e) => this.handleChange(e)} value={categoryName} className="lg:w-96 text-sm border rounded-md px-2 h-9 py-1.5 w-full shadow-sm" placeholder="Add Skill"
                        />
                        {showError &&
                          <span className="error text-xs block mt-0.5">*Please enter the skill name</span>
                        }
                      </div>
                      <div className=''>
                        <a className={`bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 flex items-center w-32 cursor-pointer hover:bg-gray-800 ${!categoryName.replace(/\s/g, '').length ? 'btn-disabled' : ''}`} onClick={(e) => this.addNew(e)} disabled={!categoryName.replace(/\s/g, '').length ? true : false}>Add New Skill</a>
                      </div>
                    </div>
                  }
                </div>
                
                <div className='mt-3 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4'>
                  <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                    {!dataLoaded &&
                      <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                        <p className="mb-1 text-center px-5">{loadingMessage}</p>
                        <img className = "w-9" src="/assets/business/loading.gif" />
                      </div>
                    }
                    {dataLoaded &&
                      <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                        {skills.length > 0 &&
                          <table className='min-w-full divide-y divide-gray-200'>
                            <thead className='bg-gray-50'>
                              <tr>
                                <th scope="col" className="hidden lg:table-cell pl-4 pr-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-10">#</th>
                                <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                {(permissions.business_categories?.write || user.role_display === "Netgym Employee") &&
                                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-10"></th>
                                }
                              </tr>
                            </thead>
                            <tbody className='divide-y divide-gray-200'>
                              {skills.map((skill, i) => 
                                <tr key={i} className='bg-white'>
                                  <td className='hidden lg:table-cell pl-4 pr-3 py-4 text-sm font-medium text-gray-900'>
                                    <div className='text-sm text-gray-500'>{i + 1}</div>
                                  </td>
                                  {(edit === true && editSkill === skill.id ) &&
                                    <td className='px-3 py-4 text-sm flex gap-4 items-center'>
                                      <input type="text" 
                                        name="name"
                                        onChange={(e) => this.setEditCategory(e)}
                                        onKeyDown={(e) => this.handleKeyDown(e, skill.id)}
                                        value={editName}
                                        className="lg:w-52 border rounded-md px-2 py-1.5 w-full shadow-sm"                            
                                      />
                                        {showEditSkillError &&
                                          <span className="error text-xs block">*Please enter the skill name</span>
                                        }
                                    </td>
                                  }
                                  {editSkill !== skill.id &&
                                    <td className='px-3 py-4'>
                                      <div className='text-sm font-medium text-gray-900'>{skill.name}</div>
                                    </td>
                                  }
                                  {(permissions.business_categories?.write || user.role_display === "Netgym Employee") &&
                                    <td className='px-3 py-4'>
                                      {this.toDisableEditDelete(skill?.id) &&
                                        <div className="justify-end">
                                          {(edit === true && editSkill === skill.id ) &&
                                            <div className=' flex gap-3'>
                                              <div className='bg-gray-100 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => this.editSkill(e, skill.id)} data-tip data-for={`save_button_`+skill?.id.toString()}>
                                                <a className='cursor-pointer'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                                                  </svg>
                                                </a>
                                                <ReactTooltip className='max-w-xs whitespace-nowrap' id={`save_button_`+skill?.id.toString()} place="top" effect="solid">
                                                  Save skill
                                                </ReactTooltip>
                                              </div>
                                              <div className='bg-gray-100 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => this.stopEdit(e)} data-tip data-for={`cancel_button_`+skill?.id.toString()}>
                                                <a className='cursor-pointer'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                  </svg>
                                                </a>
                                                <ReactTooltip className='max-w-xs whitespace-nowrap' id={`cancel_button_`+skill?.id.toString()} place="top" effect="solid">
                                                  Cancel
                                                </ReactTooltip>
                                              </div>
                                            </div>
                                          }
                                          
                                          {editSkill !== skill.id &&
                                            <div className=' flex gap-3'>
                                              <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer'  onClick={(e) => this.setEdit(e, skill.id, skill.name)} data-tip data-for={`edit_button_`+skill?.id.toString()}>
                                                <a className='cursor-pointer'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                  </svg>
                                                </a>
                                                <ReactTooltip className='max-w-xs whitespace-nowrap' id={`edit_button_`+skill?.id.toString()} place="top" effect="solid">
                                                  Edit skill
                                                </ReactTooltip>
                                              </div>
                                              
                                              <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer'  onClick={(e) => this.setDelete(e, skill.id, skill.name)} data-tip data-for={`delete_button_`+skill?.id.toString()}>
                                                <a className='cursor-pointer'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                                  </svg>
                                                </a>
                                                <ReactTooltip className='max-w-xs whitespace-nowrap' id={`delete_button_`+skill?.id.toString()} place="top" effect="solid">
                                                  Delete skill
                                                </ReactTooltip>
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      }
                                    </td>
                                  }
                                </tr>
                              )}
                            </tbody>
                          </table>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <Transition.Root show={showModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeModal(e)}>
						<div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6 mt-20">
									<div className="absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
											onClick={(e) => this.closeModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										<div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left w-full">
											<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 border-b pb-2">
                        Delete Skill
											</Dialog.Title>
											<div className="mt-4 mb-2 flex gap-4 items-center">
                        <p className='text-gray-700 w-36 text-left'>Skill Name</p>
                        <p className='text-sm text-gray-500'>{deleteName}</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-blue text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={(e) => this.processDelete(e)}
                    >
                      Delete Skill
                    </button>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
      </React.Fragment>
    )
  }
}
