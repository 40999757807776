import React, { Fragment, useState } from 'react';
import ListIcon from '../../assets/list_icon';
import CalendarIcon from '../../assets/calendar_icon';
import CardIcon from '../../assets/card_icon';

export default function AllRequestTabs(props){
  const { activeTab, setActiveTab, path } = props

  const handleSetActivePage = (data) => {
    setActiveTab(data)
  }

  return(
    <Fragment>
      <div className='flex items-center justify-center border border-gray-300 rounded-2xl gap-x-6 px-1 py-1 bg-white'>
        {['my-requests'].includes(path) &&
          <a className={`text-sm py-2 px-4 flex items-center gap-x-1 justify-center text-dark-blue font-medium  cursor-pointer ${activeTab === 'card' ? 'rounded-2xl tab-gray shadow-md' : ''}`} onClick={() => handleSetActivePage('card')}>
            <div>
              <CardIcon classNames={'w-4 h-4'}/>
            </div>
            Card
          </a>
        }
        <a className={`flex items-center text-sm gap-x-1 py-2 px-4 justify-center text-dark-blue font-medium cursor-pointer ${activeTab === 'calendar' ? 'rounded-2xl tab-gray shadow-md' : ''}`} onClick={() => handleSetActivePage('calendar')}>
          <div>
            <CalendarIcon classNames={'w-4 h-4'}/>
          </div>
          Calendar
        </a>
        <a className={`flex items-center text-sm gap-x-1 py-2 px-4 justify-center text-dark-blue font-medium cursor-pointer ${activeTab === 'list' ? 'rounded-2xl tab-gray shadow-md' : ''}`} onClick={() => handleSetActivePage('list')}>
          <div>
            <ListIcon classNames={'w-4 h-4'}/>
          </div>
          List
        </a>
      </div>
    </Fragment>
  )
}