import React, { Fragment, useEffect, useState } from "react";
import AssignPopupComponent from "./../assignPopupComponent";
import SearchIcon from "./../../../assets/searchIcon";
import { axiosGetRequest, axiosRequest } from "./../../../helpers/helpers";
import { debounce } from "lodash";
import MultipleClassesMessageComponent from '../../../../subRequestCreate/components/secondStepComponents/multipleClassesMessageComponent';
import { formattedErrorMessage } from '../../../../subRequestCreate/helpers';

export default function AssignPopupContentComponent(props) {
  const { show, eventId, approvedUserId, close, assignPopupType, handleSetAlert, processFetchEventData, type, calendarEvent, handleUpdateCalendarEvent } = props;
  const [loaded, setLoaded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [assignUserData, setAssignUserData] = useState([]);
  const [assignUser, setAssignUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [disableConfirmBtn, setDisableConfirmBtn] = useState(false)
  const [showErrorPopup, setShowErrorPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorVideoLink, setErrorVideoLink] = useState('')

  useEffect(() => {
    if (eventId && eventId !== "") {
      processFetchAssignData("");
    }
  }, [eventId]);

  const processFetchAssignData = (text) => {
    let url = "";
    if (assignPopupType === "assign") {
      url = `/api/v3/business_events/${parseInt(
        eventId
      )}/get_assign_instructors?text=${text}`;
    } else if (assignPopupType === "replace") {
      url = `/api/v2/get_replace_instructors?event_id=${parseInt(eventId)}`;
    } else {
      url = "";
      close(false);
    }
    if (url !== "") {
      axiosGetRequest(url).then((response) => {
        if (response?.status === 200) {
          setAssignUserData(response?.results);
          setAssignUser(response?.results);
          setLoaded(true);
        } else {
          setAssignUserData([]);
          setAssignUser([]);
          setLoaded(true);
          close(false);
        }
      });
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    serachUsers(e.target.value);
  };

  const serachUsers = debounce((val) => {
    processSearchAssignData(val.trim());
  }, 800);

  const processSearchAssignData = (text) => {
    if (text && text !== "") {
      let users = assignUserData.filter((user) => {
        return (
          user?.full_name?.toLowerCase()?.includes(text.toLowerCase()) ||
          user?.email?.toLowerCase()?.includes(text.toLowerCase())
        );
      });
      setAssignUser(users);
    } else {
      setAssignUser(assignUserData);
    }
  };

  const handleSelectUser = (e) => {
    if(selectedUser === e.target.value) {
      setSelectedUser('');
    }else{
      setSelectedUser(e.target.value);
    }
  }

  const handleAssign = () => {
    setDisableConfirmBtn(true);
    let url = ''
    let params = {}
    let method = 'POST'
    if(type == 'detail'){
      url = `/api/v3/business_events/${eventId}/perform_more_actions`;
      params = { preview: false }
      if (assignPopupType == 'assign'){
        params = {
          ...params,
          key: 'assign_sub',
          assigned_sub: selectedUser
        }
      }else if(assignPopupType == 'replace'){
        params = {
          ...params,
          key: 'replace_class',
          replaced_instructor: selectedUser,
          old_instructor: approvedUserId,
          reason: 1
        }
      }
      method = 'PATCH'
    }else{
      if (assignPopupType == 'assign'){
        url = `/api/v2/assign_subs?id=${eventId}&assigned_sub=${selectedUser}&gql_event_details=true&preview=${type !== 'detail'}&calendar_event=${calendarEvent}`;
      }else if(assignPopupType == 'replace'){
        url = `/api/v2/replace_sub_instructor?id=${eventId}&replaced_instructor=${selectedUser}&old_instructor=${approvedUserId}&reason=1&gql_event_details=true&preview=${type !== 'detail'}&calendar_event=${calendarEvent}`;
      }
    }
    if (url === '') return
    axiosRequest(url, method, params, 'data').then((result) => {
      if(result?.status == 200){
        setDisableConfirmBtn(false);
        handleSetAlert(true, result?.success, 'success');
        processFetchEventData(result?.event);
        if(calendarEvent) handleUpdateCalendarEvent(result?.calendar_event, 'updated');
        close(false);
        setTimeout(() => {
          handleSetAlert(false, '', ''); 
        }, 3000)
      }else{
        if(result?.video_link && result?.video_link !== '') {
          setShowErrorPopup(true)
          setErrorMessage(formattedErrorMessage(result?.error))
          setErrorVideoLink(result?.video_link)
        }else{
          setDisableConfirmBtn(false);
          handleSetAlert(true, result?.error, 'danger');
          setTimeout(() => {
            handleSetAlert(false, '', ''); 
          }, 3000)
        }
      }
    });
  }

  const closeErrorPopup = (data) => {
    setShowErrorPopup(data)
    setErrorMessage('')
    setErrorVideoLink('')
    setDisableConfirmBtn(false);
  }

  return (
    <Fragment>
      <AssignPopupComponent show={show} close={close} title={""}>
        {showErrorPopup &&
          <MultipleClassesMessageComponent showMultipleClassPopup={showErrorPopup} closeMultipleClassPopup={closeErrorPopup} message={''} showPopupWithError={showErrorPopup} showErrorMessage={errorMessage} errorVideoLink={errorVideoLink}/>
        }
        {!loaded && (
          <Fragment>
            <div className="flex flex-col items-center w-full justify-center h-72">
              <img className="w-9" src="/assets/business/loading.gif" />
            </div>
          </Fragment>
        )}
        {loaded && (
          <Fragment>
            <div className="px-4 pt-3 font-open-sans">
              <div className="text-center font-bold text-xl font-open-sans">
                {(type == 'detail' && assignPopupType == 'replace') ? 'Replace' : 'Assign'} a sub
              </div>
              <div className="bg-gray-100 rounded-xl py-2 px-3.5 mx-3 mb-4 mt-2">
                <div className="flex items-center">
                  <SearchIcon classNames="h-5 w-5 text-gray-400" />
                  <input
                    type="text"
                    className="hidden sm:flex ml-1.5 focus:outline-none w-full bg-transparent text-sm"
                    placeholder="Search for user across any location"
                    value={searchText}
                    onChange={(e) => handleSearch(e)}
                  />
                  <input
                    type="text"
                    className="flex sm:hidden ml-1.5 focus:outline-none w-full bg-transparent text-sm"
                    placeholder="Search for user"
                    value={searchText}
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </div>
              <div className="max-h-72 overflow-y-auto h-52">
                {assignUser?.map((user, i) => (
                  <div
                    key={i}
                    className="flex items-center justify-between px-3 mb-3"
                  >
                    <div className="flex items-center gap-2">
                      <div className="w-7 rounded-full">
                        <img
                          className="w-6 h-6 rounded-full"
                          src={user?.image}
                        />
                      </div>
                      <div className="text-sm">{user?.full_name}</div>
                    </div>
                    <div className="assign-checkbox flex items-center">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                        value={user?.id}
                        checked={selectedUser == user?.id}
                        onChange={(e) => handleSelectUser(e)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-4">
                <button 
                  className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-full py-2 text-sm ${(selectedUser == '' || disableConfirmBtn) ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={() => handleAssign()}
                  disabled={selectedUser == '' || disableConfirmBtn}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Fragment>
        )}
      </AssignPopupComponent>
    </Fragment>
  );
}
