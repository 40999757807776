import React from 'react';
import { useTranslationContext } from 'stream-chat-react/dist/context/TranslationContext';
import { getDateString } from 'stream-chat-react/dist/i18n/utils';

const defaultTimestampFormat = 'M/D/YYYY';

const UnMemoizedDateSeparator = (props) => {
  const { date: messageCreatedAt, formatDate, position = 'right', unread, dataFormat } = props;
  const format = (dataFormat && dataFormat !== '') ? `${dataFormat}YY` : defaultTimestampFormat

  const { t, tDateTimeParser } = useTranslationContext('DateSeparator');

  const messageCreatedAtString = messageCreatedAt.toDateString()
  const todaysDateTimeStamp = new Date()
  const todaysDateString = todaysDateTimeStamp.toDateString()
  const yesterdayDate = new Date(todaysDateString)
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  const yseterdayDateString = yesterdayDate.toDateString()

  const formattedDate = getDateString({
    calendar: (messageCreatedAtString === todaysDateString || messageCreatedAtString ===  yseterdayDateString),
    format,
    formatDate,
    messageCreatedAt,
    tDateTimeParser,
  });

  return (
    <div className='str-chat__date-separator'>
      {(position === 'right' || position === 'center') && (
        <hr className='str-chat__date-separator-line' />
      )}
      <div className='str-chat__date-separator-date'>
        {unread ? `${t('New')} - ${formattedDate.includes(' at') ? formattedDate.replace(' at', '') : formattedDate}` : (formattedDate.includes(' at') ? formattedDate.replace(' at', '') : formattedDate)}
      </div>
      {(position === 'left' || position === 'center') && (
        <hr className='str-chat__date-separator-line' />
      )}
    </div>
  );
};

/**
 * A simple date separator between messages.
 */
export const DateSeparator = React.memo(UnMemoizedDateSeparator);