import React, { Fragment } from "react";
import { Switch } from '@headlessui/react'
import DatePicker from "react-datepicker";
import { TrashIcon } from '@heroicons/react/outline'
import { dateFormats } from '../../../helpers'
import { countryOptions} from '../helpers'
import TooltipComponent from '../tooltipComponent';

export default function AboutSectionComponent(props) {
  const { business, handleChange, upload, businessLogoFileName, businessLogoFileShortName, defaultPreviewImg, removeAttachmentHandler, thumbnailUploading, setThumbnailData, dateFormat, getPreview, handleSubmit, thumbnailRef, deleteThumbnailHandler } = props;

  return(
    <Fragment>
      <div className='bg-white rounded-md shadow px-6 mb-10'>
        <header className='py-7 flex items-center gap-2'>
          <h1 className="text-2xl font-semibold leading-tight text-gray-900">About</h1>
          <TooltipComponent componentId={'account-info-about-section-tooltip-message'} classNames={'w-4 h-4'} tooltipMessage={'This section can be edited by the Account Owner(s) and Business Admin(s).'}/>
        </header>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-5'>
          <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Company Name</label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <input type="text" className="lg:w-80 border text-sm h-10 rounded-md px-2 py-1.5 w-full shadow-sm" name="name" defaultValue={business?.name} onChange={(e) => handleChange(e)}/>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Start Date</label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <DatePicker
              selected={Object.keys(business).length === 0  ? new Date() : business?.start_date === '' ? new Date() : new Date(Date.parse(business?.start_date))}
              name="hire_date"
              className="lg:w-80 border text-sm h-10 rounded-md px-2 py-1.5 w-full shadow-sm cursor-not-allowed"
              dateFormat={business?.date_format == "mm/dd/yy" ? "M/d/yyyy" : "d/M/yyyy"}
              disabled={true}
            />
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Company Logo</label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <div>
              {business?.custom_logo &&
                <div className='bg-gray-200 mb-2 py-1 px-2 max-w-28 rounded-sm'>
                  <img className="w-28" src={business?.profile_url} alt="logo" />
                </div>
              }
              {!business?.custom_logo &&
                <img src={window.location.origin + '/assets/logo-black.png'} className="w-28 mb-2" alt="logo" />
              }
            </div>
            <div>
              {business?.custom_logo &&
                <div className="items-center flex gap-1">
                  <div className={`shadow-sm text-black relative p-1 py-1.5 leading-4 items-center justify-center flex gap-1 wc-102 choose-file-button-class choose-file-button`}>
                  {`Choose file`}
                    <input type="file" className={`absolute w-full top-0 left-0 bottom-0 opacity-0`} accept="image/*" onChange={(e) => upload(e)}></input>
                  </div>
                  {businessLogoFileName !== '' &&
                    <div className='choose-file-button-div relative'>
                      <div className="">
                        {businessLogoFileShortName}
                      </div>
                      <div className="title shadow-md border border-black absolute top-7 text-sm px-1 bg-white whitespace-nowrap">
                        {businessLogoFileName}
                      </div>
                    </div>
                  }
                </div>
              }
              {!business?.custom_logo &&
                <input type="file" onChange={(e) => upload(e)} accept="image/*"/>
              }
            </div>
          </div>
        </div>
        {business?.resource_hub && (
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Resources Automated Thumbnail</label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <div className='relative'>
                {(() => {
                  if (business?.resource_thumbnail && (!Boolean(defaultPreviewImg))) {
                    return (
                      <img className="w-28 mb-2" src={business?.resource_thumbnail} alt="thumbnail" />
                    )
                  } else if (Boolean(defaultPreviewImg)) {
                    return (
                      <div className='border border-2 border-gray-400 shadow w-48 pl-3 rounded relative'>
                        <p className='mb-2 text-gray-400'>Preview</p>
                        <img className='mb-2 w-32' src={defaultPreviewImg} />
                        {!thumbnailUploading && (
                          <div className='absolute -top-2 right-0 p-2 text-gray-400 cursor-pointer' title="Remove attachment" 
                            onClick={(e) => removeAttachmentHandler()}
                          >x</div>
                        )}
                      </div>
                    )
                  } else {
                    return (
                      <></>
                    )
                  }	
                })()}
                {thumbnailUploading && (
                  <div className="inline-flex items-center px-4 pl-0 py-2 font-semibold leading-6 text-sm text-white bg-white" disabled="">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span className='text-gray-800'>
                      uploading...
                    </span>
                  </div>
                )}
                {business?.resource_thumbnail && !Boolean(defaultPreviewImg) && (
                  <div className='absolute right-0 cursor-pointer' title="Delete Thumbnail">
                    <TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" onClick={(e) => deleteThumbnailHandler()} />
                  </div>
                )}		                
              </div>
              <div>
                <input ref={thumbnailRef} type="file" onChange={(e) => setThumbnailData(e)} name="attachment" accept="image/*"/>
              </div>
            </div>
          </div>
        )}
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Schedule Coordinator
            <span className='block text-xs'>*Schedule Coordinator will be CC'd on all Approved Sub Requests receipts</span>
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full text-sm h-10 shadow-sm" name="coordinator_email" defaultValue={business.coordinator_email} placeholder="Enter Schedule Coordinator Email" onChange={(e) => handleChange(e)}/>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Urgent Sub Requests - CC
            <span className='block text-xs'>*This person will be CC'd on all urgent sub requests.</span>
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <input type="text" className="lg:w-80 text-sm h-10 border rounded-md px-2 py-1.5 w-full shadow-sm" name="urgent_request_email" defaultValue={business.urgent_request_email} placeholder="Add Email Address" onChange={(e) => handleChange(e)}/>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Class Syncing Errors
            <span className='block text-xs'>*NetGym will notify these folks when there's an issue with syncing a class.</span>
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <input type="text" className="lg:w-80 text-sm h-10 border rounded-md px-2 py-1.5 w-full shadow-sm" name="class_syncing_errors_email" defaultValue={business.class_syncing_errors_email} placeholder="Add Email Address" onChange={(e) => handleChange(e)}/>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Send Monthly Summary
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <div>
              <div className='relative flex flex-wrap items-start gap-3'>
                <div className='flex items-center'>
                  <div className='flex items-center h-5'>
                    <input id="owner_monthly_summary" aria-describedby="comments-description" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" name="owner_monthly_summary" checked={business?.owner_monthly_summary} onChange={(e) => handleChange(e)}/>
                  </div>
                  <div className='ml-1.5 text-sm'>
                    <label htmlFor="owner_monthly_summary" className="font-medium text-gray-700">Account Owner</label>
                  </div>
                </div>
                <div className='flex items-center'>
                  <div className='flex items-center h-5'>
                    <input id="admin_monthly_summary" aria-describedby="comments-description" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" name="admin_monthly_summary" checked={business?.admin_monthly_summary} onChange={(e) => handleChange(e)}/>
                  </div>
                  <div className='ml-1.5 text-sm'>
                    <label htmlFor="admin_monthly_summary" className="font-medium text-gray-700">Business Admin</label>
                  </div>
                </div>
                <div className='flex items-center'>
                  <div className='flex items-center h-5'>
                    <input id="regional_monthly_summary" aria-describedby="comments-description" name="regional_monthly_summary" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" checked={business?.regional_monthly_summary} onChange={(e) => handleChange(e)}/>
                  </div>
                  <div className='ml-1.5 text-sm'>
                    <label htmlFor="regional_monthly_summary" className="font-medium text-gray-700">Regional Admin</label>
                  </div>
                </div>
                <div className='flex items-center'>
                  <div className='flex items-center h-5'>
                    <input id="manager_monthly_summary" aria-describedby="comments-description" name="manager_monthly_summary" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" checked={business?.manager_monthly_summary} onChange={(e) => handleChange(e)}/>
                  </div>
                  <div className='ml-1.5 text-sm'>
                    <label htmlFor="manager_monthly_summary" className="font-medium text-gray-700">Fitness Manager</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
            Date format
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
            <select defaultValue={business?.date_format || dateFormat} onChange={(e) => getPreview(e)} className='form-select lg:w-80 bg-white block w-full pl-3 pr-10 py-2 h-10 text-base border border-gray-300  sm:text-sm rounded-md shadow-sm' name='date_format'>
              <option value="">Select a Format</option>
              {dateFormats().map((format, i) =>
                <option key={i} value={format.key}>{format.value}</option>
              )}
            </select>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
            Country
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
            <select name="country" defaultValue={business?.country} onChange={(e) => handleChange(e)} className='form-select lg:w-80 bg-white block w-full pl-3 pr-10 py-2 h-10 text-base border border-gray-300  sm:text-sm rounded-md shadow-sm'>
              {countryOptions().map((item, i) => 
                <option key={i} value={item?.value}>{item?.label}</option>
              )}
            </select>  
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2"></label>
          <div className='mt-1 sm:mt-0'>
            <div>
              <button className={`flex bg-dark-blue hover:bg-gray-800 text-white inline-block px-6 py-2 border shadow rounded-md ${thumbnailUploading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`} onClick={(e) => handleSubmit(e)} disabled={thumbnailUploading}>
                <div className="flex items-center justify-center gap-1 relative">
                  {thumbnailUploading && 
                    <div className="flex items-center -left-5 justify-center absolute">
                      <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </div>
                  }
                  <div className='text-white text-base'>Update Settings</div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}