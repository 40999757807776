import React, { Fragment, useEffect, useState, useRef, useCallback } from "react";
import { UserIcon, SearchIcon } from './../../../../assets';
import { debounce } from "lodash";
import { axiosGetRequest } from './../../../../../subRequests/helpers/helpers'
import { getTheUsersHash, customSearchDropDownStyles } from '../../../../helpers';
import Autocomplete from 'react-autocomplete';

export default function AssignSubComponent(props) {
  const { integratedBusiness, newSubRequest, setNewSubRequest, business } = props
  const [ selectedAssignUser, setSelectedAssignUser ] = useState(newSubRequest?.approvedUser?.id || '')
  const [ assignInstructorsList, setAssignInstructorsList ] = useState([])
  const [ selectedLocationId, setSelectedLocationId ] = useState(newSubRequest?.locationId)
  const [ selectedRequestingInstructorId, setSelectedRequestingInstructorId ] = useState('')
  const [ allOptions, setAllOptions ] = useState([])
  const [ selectedOption, setSelectedOption ] = useState(newSubRequest?.approvedUser?.id !== '' ? {value: newSubRequest?.approvedUser?.id, label: newSubRequest?.approvedUser?.full_name, image: newSubRequest?.approvedUser?.image} : null)
  const [inputValue, setInputValue] = useState(newSubRequest?.approvedUser?.id !== '' ? newSubRequest?.approvedUser?.full_name : '');
  const [loading, setLoading] = useState(false);
  const [ menuOpen, setMenuOpen ] = useState(false);
  const [ showNoInstructorFound, setShowNoInstructorFound ] = useState(false);
  const [ focused, setFocused ] = useState(false);

  useEffect(() => {
    if(newSubRequest?.approvedUser?.id !== selectedOption?.value){
      if(newSubRequest?.approvedUser?.id !== ''){
        setSelectedOption({value: newSubRequest?.approvedUser?.id, label: newSubRequest?.approvedUser?.full_name, image: newSubRequest?.approvedUser?.image})
      }else{
        setSelectedOption('')
      }
    }

  }, [newSubRequest])

  useEffect(() => {
    if(newSubRequest?.approvedUser?.id !== '' && newSubRequest?.requestingInstructor?.id !== '' && newSubRequest?.requestingInstructor?.id === newSubRequest?.approvedUser?.id){
      setSelectedAssignUser('')
      setSelectedOption('')
      setInputValue('');
      handleSelectAssignUserId('', '', '')
      setShowNoInstructorFound(false)
      setAllOptions([])
    }
  }, [newSubRequest])

  const fetchAssignInstructors = (page, text, locationId) => {
    let requestingInstructorId = newSubRequest?.requestingInstructor?.id
    setSelectedRequestingInstructorId(requestingInstructorId)
    setSelectedLocationId(locationId)

    setLoading(true);
    setShowNoInstructorFound(false);
    axiosGetRequest(`/api/v2/get_approved_instructors?page=${page}&requesting_instructor_id=${requestingInstructorId}&text=${text}&location_id=${locationId}`).then(result => {
      setLoading(false);
      if(result?.status == 200){
        setAssignInstructorsList(result?.results)
        setAllOptions(getTheUsersHash(result?.results) || [])
        setShowNoInstructorFound(result?.results?.length == 0)
      }
    })
  }

  const serachAssignUsers = useCallback(
    debounce((val, locationId) => {
      performSearchAssignUsers(val.trim(), locationId)
    }, 300),
    [newSubRequest?.requestingInstructor?.id]
  );
  
  const performSearchAssignUsers = (text, locationId) => {
    fetchAssignInstructors(1, text, locationId)
  }

  const handleSelectAssignUserId = (userId, userName, userImage) => {
    setNewSubRequest(prevNewSubRequest => ({
      ...prevNewSubRequest,
      approvedUser: {id: parseInt(userId) || '', full_name: userName, image: userImage},
    }))
  }

  const handleMenuOpen = (e) => {
    setFocused(true)
    $('.assign-instructor-need-border').addClass('filter-dynamic-border')
  }

  const handleMenuClose = (e) => {
    focusFirstItem();
    setMenuOpen(false);
    setFocused(false)
    $('.assign-instructor-need-border').removeClass('filter-dynamic-border')
  }

  const handleOnChange = (e, newValue) => {
    if(newValue.length > 0){
      serachAssignUsers(newValue.trim(), newSubRequest?.locationId)
    }else{
      setShowNoInstructorFound(false)
      setAllOptions([])
    }
    setMenuOpen(newValue.length > 0)
    setInputValue(newValue)
    if(selectedOption?.label !== newValue.trim()){
      setSelectedOption('')
      handleSelectAssignUserId('', '', '')
      setSelectedAssignUser('')
    }
    setFocused(true)
  }

  const handleOnSelect = (val, item) => {
    setInputValue(val);
    setSelectedOption(item);
    handleSelectAssignUserId(item.value, item.label, item.image)
    setSelectedAssignUser(item.value)
    setMenuOpen(false);
  }

  const itemComponent = (item, isHighlighted) => {
    return (
      <div 
        key={item.value} 
        className={`item ${selectedOption?.value == item.value ? 'selected-item' : isHighlighted ? 'item-hover approve-sub-item' : ''}`}
      >
        {item.label}
      </div>
    );
  }

  const focusFirstItem = () => {
    if (!$('.item.item-hover.approve-sub-item').length) $('.item').first().trigger('click')
  }

  return(
    <Fragment>
      <div className="flex flex-col gap-2 filter-column justify-end">
        <div className="flex text-base md:text-lg text-black font-semibold">
          {`Assign Sub`}
        </div>
        <div className={`relative filters_box sub-reuqest-requesting-instructor-filter`}>
          <UserIcon classNames={`w-5 h-5 absolute top-3 left-3 z-10 ${(newSubRequest?.requestingInstructor?.id == '' || ((business?.clubready_integration || business?.crunch_integration) && newSubRequest?.locationId == '')) ? 'opacity-50' : ''}`}/>
          <Autocomplete
            value={inputValue}
            items={allOptions}
            getItemValue={item => item.label}
            shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value?.trim()?.toLowerCase()) > -1}
            inputProps={{
              className: `flex justify-center w-full rounded-2xl pl-8 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 h-11 gap-1 items-center border-2 border-transparent assign-instructor-need-border class-container ${(newSubRequest?.requestingInstructor?.id == '' || ((business?.clubready_integration || business?.crunch_integration) && newSubRequest?.locationId == '')) ? 'cursor-not-allowed opacity-50 select-disabled' : ''}`,
              placeholder: "Select User",
              onClick: () => handleMenuOpen(),
              onBlur: () => handleMenuClose(),
              disabled: (newSubRequest?.requestingInstructor?.id == '' || ((business?.clubready_integration || business?.crunch_integration) && newSubRequest?.locationId == ''))
            }}
            defaultHighlightedIndex={0}
            renderMenu={items => (
              items.length > 0 ? 
                <div className="dropdown">
                  {items}
                </div>
              :
              <div></div>
            )}
            renderItem={(item, isHighlighted, isFocused) => itemComponent(item, isHighlighted, isFocused)}
            onChange={(event, val) => handleOnChange(event, val)}
            onSelect={(event, val) => handleOnSelect(event, val)}
            selectOnBlur={true}
            onBlur={() => {
              setMenuOpen(false);
            }}
            open={menuOpen && allOptions?.length > 0}
          />
          {loading &&
            <div className="custom-dots-loader absolute top-5 right-3 z-10"></div>
          }
          {inputValue !== '' && newSubRequest?.approvedUser?.id == '' &&
            <div className='text-red-500 text-xs absolute top-12 left-1'>{(!loading && allOptions?.length == 0 && showNoInstructorFound) ? 'No instructor found' : (!focused && `Assigned Instructor is invalid`)}</div>
          }
        </div>
      </div>
    </Fragment>
  )
}