import React, { Fragment } from "react";
import { setBusinessSubdomainInUrl } from '../../helpers';

export default function SelectBusinessComponent(props) {
  const { businesses } = props;

  const handleSelectBusiness = (business) => {
    setBusinessSubdomainInUrl(business.subdomain)
  }

  return (
    <Fragment>
      <div className="md:max-w-3xl w-full">
        <div className="flex flex-col gap-4">
          <h2 className="text-base md:text-3xl text-center mb-2">
            Select your gym/studio:
          </h2>
          <div className="gap-1 grid grid-cols-1 md:grid-cols-3">
            {businesses.map((business, i) => 
              <a key={i} className="w-full select-business-box gap-2 flex flex-col justify-center items-center min-hc-150 rounded-md cursor-pointer" onClick={() => handleSelectBusiness(business)}>
                <img className="select-business-box-image" src={business?.profile_url}/>
                <p className="select-business-box-name">{business?.name}</p>
              </a>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  )
}