import React from "react";
import { getSudomain } from '../../helpers'
import { getHeaders, userToken, getName } from '../../subRequests/helpers/helpers';

export const getParseDate = (date) => {
  let dateDate = new Date(date);
  return dateDate;
};

export const axiosRequestNoData = async(url, method) => {
  return await fetch(url, {
    method: method,
    headers: getHeaders(`${userToken()}`, `${getSudomain()}`)
  }).then(      
    response => response.json()
  )
}

export const getBackPathUrl = (user) => {
  let params = new URLSearchParams(window.location.search)
  let userRole = getName(user?.role)
  if(params.has('backpath') && params.get('backpath') == 'schedule_path'){
    return (userRole == 'instructor' ? '/my-requests' : '/all-requests')
  }else if(params.has('backpath') && params.get('backpath') == 'my_schedule_path'){
    return ('/my-requests')
  }else{
    return (`/my-requests`)
  }
}

export const checkMgrEventWithRole = (eventData, user) => {
  return (eventData?.mrg_reopened && user?.role_display !== 'Fitness Instructor') || (!eventData?.mrg_reopened)
}