import React, { Fragment, useState, useRef, useEffect } from "react";
import { LocationPinIcon } from '../../../../../../subRequests/assets';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { SearchIcon } from '../../../../../assets';
import { debounce } from "lodash";

export default function LocationDropDownComponent(props) {
  const { instructorLocations, handleSaveLocation, selectedLocationId, selectedLocationName, i, loading } = props
  const menuButtonRef = useRef(null);
  const [ searchValue, setSearchValue ] = useState('')
  const [ locationsList, setLocationsList] = useState(instructorLocations)
  const [ parentButtonWidth, setParentButtonWidth ] = useState(0)

  useEffect(() => {
    setLocationsList(instructorLocations)
  }, [instructorLocations])

  const handleChangeLocation = (e, locationName) => {
    handleSelectLocation(e.target.value, locationName)
    menuButtonRef.current.click()
  }

  const handleLocationSearch = (e) => {
    let val = e.target.value
    if (e.key === ' ') {
      e.preventDefault();
      val += ' ';
    }
    searchLocation(val)
    setSearchValue(val)
  }

  const searchLocation = debounce(val => {
		performSearchLocation(val.trim())
	}, 800);

  const performSearchLocation = (text) => {
    let newLocationsList = instructorLocations.filter(function (location) {
      return location?.name?.toLowerCase().includes(text?.toLowerCase())
    })
    setLocationsList(newLocationsList);
  }

  const handleSelectLocation = (locationId, locationName) => {
    handleSaveLocation(parseInt(locationId, 10), locationName)
  }

  const processFillBackFilter = () => {
    setSearchValue('')
    setLocationsList(instructorLocations)
  }

  const instructorLocationName = (loc) => {
    return (instructorLocations.filter((i) => i.id == loc)[0]?.name || '' )
  }

  const handleClick = () => {
    let div = $('.special-container-class')
    if(div.hasClass('pb-12')){
      div.removeClass('pb-12')
    }else{
      div.addClass('pb-12')
    }
  }

  const handleRemoveExtraPadding = () => {
    let div = $('.special-container-class')
    if(div.hasClass('pb-12')){
      div.removeClass('pb-12')
    }
  }

  const setRef = node => {
    if (node) {
      $(`.location-need-border-${i}`).addClass('filter-dynamic-border')
      let locationDiv = $('.basic-business-location') 
      if(locationDiv?.length > 0){
        handleClick()
      }
    }else if (node === null) {
      $(`.location-need-border-${i}`).removeClass('filter-dynamic-border')
      processFillBackFilter()
      let locationDiv = $('.basic-business-location') 
      if(locationDiv?.length > 0){
        handleRemoveExtraPadding()
      }
    }
  };

  useEffect(() => {
    let locationDiv = $('.basic-business-location')
    if(locationDiv?.length > 0){
      setParentButtonWidth($(menuButtonRef.current).width())
    }
  }, [])

  return(
    <Fragment>
      <div className="relative filters_box"> 
        <Menu as="div" className="relative block text-left">
          <div>
            <Menu.Button className={`flex justify-center w-full rounded-2xl pl-4 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 h-11 gap-1 items-center border-2 border-transparent location-need-border-${i}`} ref={menuButtonRef}>
              <LocationPinIcon classNames={'w-5 h-5 text-neutral-500'}/>
              {/* <p className="truncate filter-text-field text-left" style={{width: `${parentButtonWidth-50}px`}}> */}
              <p className="truncate filter-text-field text-left">
                {selectedLocationId === '' ?
                  'Select location'
                :
                instructorLocationName(selectedLocationId)
                }
              </p>
              <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
            {loading &&
              <div className="custom-dots-loader absolute top-5 right-9 z-10"></div>
            }
          </div>
          <Transition
            as={Fragment}
            // show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items ref={setRef} className="origin-top-right z-30 right-0 w-full rounded-2xl bg-white shadow-md border bordar-gray-200 focus:outline-none mt-2 absolute">
              <div className="my-4 px-4 max-h-60 overflow-y-auto">
                {locationsList?.map((location, i) => 
                  <label key={i} className="flex text-gray-900 text-sm mb-2 justify-between cursor-pointer">
                    <div className="flex text-left font-medium text-base text-gray-900">
                      {location?.name}
                    </div>
                    <div className="assign-checkbox flex mt-1">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer"
                        value={location?.id}
                        checked={selectedLocationId == location?.id}
                        onChange={(e) => handleChangeLocation(e, location?.name)}
                      />
                    </div>
                  </label>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}