import React, { Component, Fragment } from 'react'
import { getSudomain, setTitle, timeZones, checkSettingsPermissios } from '../../helpers'
import { getLocations, filterLocations, deleteLocation, exportLocations, uploadCSV, syncLocations, processSyncLocations, getStates, getSuggestions, getRegions, createLocation, updateLocation, downloadData, hideItems } from './helpers'
import Cookies from 'js-cookie';
import { debounce } from "lodash";
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'
import Pagination from "react-js-pagination";
import LocationForm from './locationForm';
import ReactTooltip from "react-tooltip";
import ApprovalSettingComponent from './approvalSettingComponent';
import AlertPopup from '../../messenger/pages/chatComponents/assets/alertPopup';

const initial_location = {
  name: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  location_coordiates: [],
  time_zone: '',
  club_type: '',
  fitness_managers: "",
  business_region: {},
  business_region_id: "",
  mbo_site_id: '',
  mindbody_id: '',
  external_id: '',
  added_in_users: false,
  approval_style: '',
}

export default class Locations extends Component {
  constructor(props){
    super(props);
    this.state = {
      locations: [],
      loaded: false,
      activePage: 1,
      total: 0,
      baseCount: 0,
      selectedFilterSiteId: [],
      alert_message: '',
      alert_type: '',
      show: false,
      sortType: '',
      sortReverse: false,
      search: '',
      search_location: "",
      dataLoaded: false,
      deleteLocation: {},
      deleteModal: false,
      pageSize: 30,
      fetching: false,
      syncType: '',
      site_ids: [], 
      selectedSiteId: '',
      remote_classes: [],
      syncModal: false,
      classesToDownload: [],
      activeCheck: [],
      allSelected: false,
      syncing: false,
      selectedActive: false,
      showModal: false,
      time_zones: timeZones(),
      location: {...initial_location, approval_style: this.props.business?.default_approval_style},
      states: [],
      fitnessManagers: [],
      regions: [],
      tags: [],
      dataReady: false,
      loadingCount: 0,
      updatingLocations: false
    }
  }

  componentDidMount = () => {
    if(Object.keys(this.props?.user).length !== 0 && Object.keys(this.props?.permissions).length !== 0){
      checkSettingsPermissios('locations', this.props)
    }
		setTitle('locations')
    if(this.props.pageLoaded){
      this.getAll(1)
    }
	}

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  getAll = (pageNumber) => {
    getLocations(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), pageNumber).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          locations: result.results,
          dataLoaded: true,
          loaded: true,
          activePage: parseInt(result.page),
          total: result.total,
          baseCount: (pageNumber-1)*30,
          selectedFilterSiteId: [],
          updatingLocations: false
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          loaded: true
        })
      }
    })
  }

  sort = (e, param, type) => {
    e.preventDefault()
    let sort_desc = false
    if(type === 'asc'){
      sort_desc = false
    }else{
      let sortRev =  this.state.sortReverse
      if(sortRev === true){
        sort_desc = false
      }
      else{
        sort_desc = true
      }
    }

    this.setState({
      sortType: param,
      sortReverse: sort_desc
    })
    this.performSearch(this.state.search_location, param, sort_desc)
  }

  filter = (search) => {
    this.setState({
      search_location: search
    })
    this.debounceTest(search)
  }

  debounceTest = debounce(val => {
    this.performSearch(val, this.state.sortType, this.state.sortReverse)
  }, 800);

  performSearch = (search, sortType, sortReverse) => {
    this.setState({dataLoaded: false})
    filterLocations(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), 1, search, sortType, sortReverse, this.state.selectedFilterSiteId).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          locations: result.results,
          dataLoaded: true,
          total: result.total,
          baseCount: (result.page-1)*30,
          activePage: result.page,
          updatingLocations: false          
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  closeModal = (e) => {
    this.setState({
      deleteModal: false,
      showModal: false,
      syncModal: false,
      remote_classes: [],
      syncType: '',
      site_ids: [],
      selectedSiteId: '',
      fetching: false,
      regions: [],
      syncing: false
    })
  }

  setDelete = (e, loc) => {
    e.preventDefault()
    this.setState({
      deleteModal: true,
      deleteLocation: loc
    })
  }

  processDelete = (e) => {
    e.preventDefault()
    deleteLocation(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.deleteLocation).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: 'Location successfully Deleted',
          alert_type: 'success',
          loaded: false,
          deleteModal: false
        })

        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });

        this.getAll(this.state.activePage)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      dataLoaded: false
    });
    this.paginatedResults(pageNumber)
  }

  paginatedResults = (pageNumber) => {
    filterLocations(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), pageNumber, this.state.search_location, this.state.sortType, this.state.sortReverse, this.state.selectedFilterSiteId).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          locations: result.results,
          dataLoaded: true,
          total: result.total,
          baseCount: (result.page-1)*30,
          activePage: result.page         
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  export = (e) => {
    e.preventDefault()
    exportLocations(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.blob()
      .then(result => { 
        const url = window.URL.createObjectURL(result)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', "locations.csv")
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)        
      })
    )
  }

  upload = (e) => {
    e.preventDefault()
    this.setState({
      dataLoaded: false
    })
    uploadCSV(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), e.target.files[0]).then(      
      response => response.json()
      .then(result => { 
        if(result.status === 200){
          this.setState({
            alert_message: 'Locations successfully uploaded!',
            alert_type: 'success',            
            // dataLoaded: true
          })
          this.getAll(this.state.activePage)
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger',            
            dataLoaded: true
          })
        }
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      })
    )
  }

  checkSelectedAllSiteIds = () => {
    let check = this.state.selectedFilterSiteId?.length === this.props.user.assigned_mbo_sites?.length
    return check
  }

  selectedAllSiteIds = (e) => {
    if(e.target.checked){
      let all = this.props.user.assigned_mbo_sites
      let selectedFilterSiteId = []
      for(var i=0; i<all.length;i++){     
        selectedFilterSiteId.push(all[i]['site_id'])
      }
      this.setState({
        selectedFilterSiteId: selectedFilterSiteId
      })
    }else{
      this.setState({
        selectedFilterSiteId: []
      })
    }
  }

  selectSiteIds = (e) => {
    let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedFilterSiteId.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newSite = [...this.state.selectedFilterSiteId, val]
        this.setState(state => ({ selectedFilterSiteId: newSite }));
      }
    }else{
      if(__FOUND){
        const { selectedFilterSiteId } = this.state;
        let newSite = selectedFilterSiteId.filter((tag, index) => tag !== val)  
        this.setState({
          selectedFilterSiteId: newSite        
        }); 
      }      
    }
  }

  setSelSites = (e) => {
    this.setState({loaded: false})
    filterLocations(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), 1, this.state.search_location, this.state.sortType, this.state.sortReverse, this.state.selectedFilterSiteId).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          locations: result.results,
          loaded: true,
          total: result.total,
          baseCount: (result.page-1)*30,
          activePage: result.page          
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  cancelLocationFilter = () => {
    hideItems('location')
  }

  sync_locations = (e, syncType) => {
    e.preventDefault()
    let site_ids = []
    e.target.classList.add("btn-disabled");
    const elem = e.target
    this.setState({
      fetching: true
    })

    switch(syncType){
      case 'mbo':
        site_ids = this.props.user.assigned_mbo_site_ids
      break
      case 'marinatek':
        site_ids = ['']
      break;
    }
    this.setState({ site_ids, syncType })

    if(site_ids.length > 1){
      this.setState({
        direct_sync: false,
        syncModal: true,
        fetching: false
      })
      elem.classList.remove("btn-disabled");
    }
    else{
      this.setState({
        selectedSiteId: site_ids[0]
      })
      syncLocations(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), syncType, site_ids[0]).then(      
        response => response.json()
      )
      .then(result => {   
        elem.classList.remove("btn-disabled");
        if(result.status === 200){
          this.setLocations(result.locations)
          this.setState({
            remote_classes: result.locations,
            syncModal: true,
            fetching: false
          })
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger',
            show: true,
            syncModal: false
          })
        }
      })
    }

  }

  setLocations = (locations) => {
    let req_locations = []
    let down_classes = []
    let check_active = []
    for(var i=0; i < locations.length; i++){
      var o = {}
      var obj = {}
      var o_check = {}
      switch(this.state.syncType){
        case 'mbo':
          if(locations[i].existing !== true){
            o[locations[i].mindbody_id] = locations[i].existing;
            obj[locations[i].mindbody_id] = locations[i].existing;
          }else{
            o[locations[i].mindbody_id] = locations[i].existing;
          }          
          break;
        case 'marinatek':
          o_check['active'] = locations[i].active;
          if(locations[i].existing !== true){
            o[locations[i].marianatek_id] = locations[i].existing;
            obj[locations[i].marianatek_id] = locations[i].existing;
            o_check[locations[i].marianatek_id] = locations[i].existing;
          }else{
            o[locations[i].marianatek_id] = locations[i].existing;
            o_check[locations[i].marianatek_id] = locations[i].existing;
          }          
          break;        
      }
      down_classes.push(obj)
      req_locations.push(o)
      check_active.push(o_check)
    }
    this.setState({
      classestoAdd: req_locations,
      classesToDownload: down_classes,
      activeCheck: check_active
    })
  }

  selectMboSite = (e) => {
    this.setState({
      abcClassesLoading: true,
      classestoAdd: [],
      remote_classes: [],
      selectedSiteId: e.target.value
    })
    syncLocations(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), 'mbo', e.target.value).then(      
      response => response.json()
    )
    .then(result => {   
      if(result.status === 200){
        this.setLocations(result.locations)
        this.setState({
          remote_classes: result.locations,
          syncModal: true,
          abcClassesLoading: false
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          syncModal: false
        })
      }
    })
  }

  checkAll = (e, val) => {
    let existingClasses = this.state.classestoAdd;
    for(var i=0; i < existingClasses.length; i++){
      Object.keys(existingClasses[i]).map((property) => {
        existingClasses[i][property] = val;
      });
    }
    this.setState({
      classestoAdd: existingClasses,
      allSelected: val
    })
  }

  objectChecked = (cls) => {
    let existingClasses = this.state.classestoAdd;
    let checked = false
    for(var i=0; i < existingClasses.length; i++){
      if(cls.existing === true){
        checked = true
      }else{
        switch(this.state.syncType){
          case 'mbo':
            if (typeof existingClasses[i][cls.mindbody_id] !== 'undefined' ) {
              checked = existingClasses[i][cls.mindbody_id] === true
            }
            break;
          case 'marinatek':
            if (typeof existingClasses[i][cls.marianatek_id] !== 'undefined' ) {
              checked = existingClasses[i][cls.marianatek_id] === true
            }
            break;
        }
      }
    }
    return checked
  }

  toggleRequestClass = (e, cls) => {
    if(cls.existing){
      return;
    }
    let existingClasses = this.state.classestoAdd;
    let existingDownload = this.state.classesToDownload
    let checked = e.target.checked;
    
    for(var i=0; i < existingClasses.length; i++){
      switch(this.state.syncType){
        case 'mbo':
          if (typeof existingClasses[i][cls.mindbody_id] !== 'undefined' ) {
            existingClasses[i][cls.mindbody_id] = checked
            existingDownload[i][cls.mindbody_id] = checked
          }
          break;
        case 'marinatek':
          if (typeof existingClasses[i][cls.marianatek_id] !== 'undefined' ) {
            existingClasses[i][cls.marianatek_id] = checked
            existingDownload[i][cls.marianatek_id] = checked
          }
          break;        
      }
    }
    this.setState({
      classestoAdd: existingClasses,
      classesToDownload: existingDownload
    })
  }

  processSync = (e) => {
    e.preventDefault()
    e.target.classList.add("btn-disabled");
    const elem = e.target
    let site_ids = this.state.selectedSiteId
    this.setState({
      syncing: true
    })
    
    processSyncLocations(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.syncType, site_ids, this.state.classestoAdd).then(      
      response => response.json()
    )
    .then(result => {
      elem.classList.remove("btn-disabled");
      if(result.status === 200){
        this.setState({
          alert_message: 'Locations Synced Successfully',
          alert_type: 'success',
          syncModal: false,
          syncType: '',
          site_ids: [],
          selectedSiteId: '',
          remote_classes: [],
          syncing: false
        })
        this.getAll(this.state.activePage)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          syncModal: false,
          syncing: false
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  selectActive = (e) => {
    let existingClasses = this.state.classestoAdd;
    let check_active = this.state.activeCheck;
    let val = e.target.checked
    for(var i=0; i < check_active.length; i++){
      if(check_active[i].active === true){
        Object.keys(existingClasses[i]).map((property) => {
          existingClasses[i][property] = val;
        });
      }
    }
    this.setState({
      classestoAdd: existingClasses,
      selectedActive: val
    })
  }

  componentDidUpdate(prevProps, prevState){
    if (prevState.loadingCount > 0 && this.state.loadingCount === 0) {
      this.setState({ dataReady: true });
    }
  }

  fetchStates = () => {
    getStates(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          states: result.states
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    }).finally(() => {
      this.setState(prevState => ({ loadingCount: prevState.loadingCount - 1 }));
    });
  }

  fetchSuggestions = () => {
    getSuggestions(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          fitnessManagers: result.results
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    }).finally(() => {
      this.setState(prevState => ({ loadingCount: prevState.loadingCount - 1 }));
    });
  }

  fetchRegions = () => {
    getRegions(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          regions: result.results
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    }).finally(() => {
      this.setState(prevState => ({ loadingCount: prevState.loadingCount - 1 }));
    });
  }

  setAdd = (e) => {
    e.preventDefault()
    
    this.setState({
      loadingCount: 3,
      dataReady: false,
      updatingLocations: false
    })

    // This will fetch all the states
    this.fetchStates()

    // This will fetch all the fitness managers suggestions
    this.fetchSuggestions()

    // Fetch the regions list
    this.fetchRegions()

    this.setState({
      showModal: true,
      location: {...initial_location, approval_style: this.props.business?.default_approval_style},
      formType: 'add',
    },()=>{
      window.setTimeout(()=>{
        this.setState({showModal: true})
      },1000)
    })

  }

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
     tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
    var __FOUND = this.state.fitnessManagers.find(function(post, index) {
      if(post.text == tag.text)
        return true;
    });

    if(__FOUND == undefined){
      return;
    }

    this.setState(state => ({ tags: [...state.tags, tag] }));
  }

  addLocation = (location) => {
    this.setState({
      updatingLocations: true
    })
    createLocation(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), location).then(
      response => response.json()
    )
    .then(result => {
      this.setState({
        updatingLocations: false
      })
      if(result.status === 200){
        this.setState({
          alert_message: result.error,
          alert_type: 'success',
          loaded: false,
          deleteModal: false,
          showModal: false
        })

        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });

        this.getAll(this.state.activePage)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  editLocation = (location) => {
    this.setState({
      updatingLocations: true
    })
    updateLocation(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), location).then(
      response => response.json()
    )
    .then(result => {
      this.setState({
        updatingLocations: false
      })
      if(result.status === 200){
        this.setState({
          alert_message: result.error,
          alert_type: 'success',
          loaded: false,
          deleteModal: false,
          showModal: false
        })

        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });

        this.getAll(this.state.activePage)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  setEdit = (e, loc) => {
    e.preventDefault()
    
    this.setState({
      loadingCount: 3,
      dataReady: false,
      updatingLocations: false
    })

    // This will fetch all the states
    this.fetchStates()

    // This will fetch all the fitness managers suggestions
    this.fetchSuggestions()

    // Fetch the regions list
    this.fetchRegions()

    this.setState({
      showModal: true,
      location: loc,
      formType: 'edit',
    },()=>{
      window.setTimeout(()=>{
        this.setState({showModal: true})
      },1000)
    })

  }

  download = (e, type) => {
    e.preventDefault()
    let filename = ""
    switch(type){
      case "abc":
        filename = "abc_locations.csv"
        break;
      case "marina":
        filename = "marina_locations.csv"
        break;
    }
    downloadData(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), type).then(      
      response => response.blob()
      .then(result => { 
        const url = window.URL.createObjectURL(result)
        const link = document.createElement('a')
        link.href = url
        
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)        
      })
    )
  }

  setAlert = (show, type, message) => {
    this.setState({
      show: show,
      alert_message: message,
      alert_type: type
    })
  }

  closeAlert = (data) => {
    this.setState({
      show: data
    })
  }

  getAllLocationsData = () => {
    this.performSearch(this.state.search_location, this.state.sortType, this.state.sortReverse)
  }

  render() {
    const { locations, loaded, activePage, total, baseCount, selectedFilterSiteId, alert_message, alert_type, show, sortType, sortReverse, search_location, search, dataLoaded, deleteModal, deleteLocation, pageSize, fetching, syncType, site_ids, selectedSiteId, remote_classes, syncModal, classesToDownload, activeCheck, abcClassesLoading, allSelected, syncing, selectedActive, showModal, time_zones, location, states, fitnessManagers, regions, tags, formType, dataReady, updatingLocations } = this.state
    const {user, business, loadingMessage, permissions} = this.props
    
    return (
      <React.Fragment>
        {show && <AlertPopup alert_message={alert_message} alert_type={alert_type} closeAlert={this.closeAlert} />}
        {!loaded &&
          <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
            <p className="mb-1 text-center px-5">{loadingMessage}</p>
            <img className = "w-9" src="/assets/business/loading.gif" />
          </div>
        }
        {loaded &&
          <div className='w-full min-h-screen bg-gray-50'>
            <div className='h-full'>
              <div className='max-w-8xl mx-auto h-full'>
                <div className='hidden mt-2 mb-5'>
                  <div className='bg-white break-words p-3 border border-gray-200 rounded-lg'>
                    <p className="text-gray-500 text-sm font-normal">
                      Add each gym or studio within your account! Every user in your account will be assigned locations they’re eligible to teach at. Along with skills, location will determine who can be invited to sub a specific class. For example, if an instructor sends out a sub request for Yoga at Location #1, then only Yoga instructors who can teach at Location #1 can be invited to sub.
                    </p>
                  </div>
                </div>
                <div className='grid items-center'>
                  <div className='w-full md:pt-3 px-0 lg:flex flex items-center gap-2 flex-wrap'>
                    <div className='relative w-full lg:w-44'>
                      <div className='relative block text-left'>
                        <div className='w-full lg:w-44 relative bg-gray-300 rounded-md'>
                          <div className='w-full bg-white border border-gray-300 shadow-sm rounded-md h-9 flex items-center pr-1.5'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4 ml-3.5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                            <input type="text" className="text-sm w-full border-0 h-9 ml-1.5 focus:outline-none bg-transparent" placeholder="Search for locations" onChange = {(e) => this.filter(e.target.value)} value={search_location}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    {((permissions?.business_locations?.write && (!business?.marianatek_integration || !business?.marianatek_is_franchise)) || user.role_display === "Netgym Employee") &&
                      <div>
                        {(business?.marianatek_integration || business?.mbo_integration) ?
                          <a className={`bg-dark-blue text-white text-sm rounded-md py-2 px-7 font-medium h-9 block cursor-pointer relative hover:bg-gray-800`} onClick={(e) => this.sync_locations(e, business?.mbo_integration ? 'mbo' : business?.marianatek_integration ? 'marinatek' : business?.abc_integration ? 'abc' : '')}>
                            {fetching &&
                              <React.Fragment>
                                <div className="flex items-center justify-center relative">
                                  <svg className="animate-spin -right-5 h-4 w-4 text-white absolute" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                  {/* <p className="text-center">{window.getStartSyncSlogan()}</p> */}
                                  <p className="text-center">Add Location from {business?.mbo_integration ? 'MBO' : business?.marianatek_integration ? 'MT' : business?.abc_integration ? 'ABC' : ''}</p>
                                </div>
                              </React.Fragment>
                            }
                            {!fetching &&
                              <React.Fragment>
                                Add Location from {business?.mbo_integration ? 'MBO' : business?.marianatek_integration ? 'MT' : business?.abc_integration ? 'ABC' : ''}
                              </React.Fragment>
                            }
                          </a>
                        :
                          ((!business?.clubready_integration && !business?.fisikal_integration && (!business?.crunch_integration || (business?.crunch_integration && user.role_display === "Netgym Employee"))) &&
                            <a className='bg-dark-blue hover:bg-gray-800 text-white text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={(e) => this.setAdd(e)}>
                              Add New Location
                            </a>
                          )
                        }
                      </div>
                    }
                    <div>
                      <a className='border rounded-md border-gray-300 hover:bg-gray-100 shadow-sm text-gray-700 text-sm py-2 px-4 h-9 block cursor-pointer' onClick={(e) => this.export(e)}>
                        Export Locations
                      </a>
                    </div>
                    {user.role_display === "Netgym Employee" &&
                      <React.Fragment>
                        <div>
                          <div className='border rounded-md border-gray-300 hover:bg-gray-100 shadow-sm text-gray-700 text-sm py-2 px-4 relative h-9 block'>
                            Upload CSV
                            <input type="file" className="absolute w-full top-0 left-0 bottom-0 opacity-0" required="" accept=".csv" onChange={(e) => this.upload(e)}></input>
                          </div>
                        </div>
                      </React.Fragment>
                    }
                    {(business?.marianatek_integration || business?.mbo_integration) &&
                      <React.Fragment>
                        {(permissions.business_locations?.write || user.role_display === "Netgym Employee") &&
                          <div>
                            <a className='border rounded-md border-gray-300 shadow-sm text-gray-700 hover:bg-gray-100 text-sm py-2 px-4 h-9 block cursor-pointer' onClick={(e) => this.setAdd(e)}>
                              + Location (manual)
                            </a>
                          </div>
                        }
                      </React.Fragment>
                    }
                    {(business?.abc_integration || business?.marianatek_integration) &&
                      <React.Fragment>
                        {(!business?.mbo_integration && user.role_display === "Netgym Employee") && 
                          <div>
                            <a className='border hover:bg-gray-100 rounded-md border-gray-300 shadow-sm text-gray-700 text-sm py-2 px-4 h-9 block cursor-pointer' onClick={(e) => this.download(e, business?.marianatek_integration ? 'marina' : business?.abc_integration ? 'abc' : '')}>
                              Download Locations from {business?.mbo_integration ? 'MBO' : business?.marianatek_integration ? 'MT' : business?.abc_integration ? 'ABC' : ''}
                            </a>
                          </div>
                        }
                      </React.Fragment>
                    }
                    {(business.mbo_integration && user.assigned_mbo_sites?.length > 1) &&
                      <div className="relative filters_box  w-full sm:w-60">
                        <Menu as="div" className="relative block text-left">
                          <div>
                            <Menu.Button className="inline-flex items-center h-9 justify-center w-full rounded-md border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-none focus:ring-offset-2 hover:bg-gray-50 focus:ring-offset-gray-100 focus:ring-indigo-500 location_items_button">
                              Select MBO Site
                              <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5" aria-hidden="true" />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            show={true}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95">
                            <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden location_items filter_items">
                              <div className="flex items-center p-2 border-b">
                                <div className=" ml-auto">
                                  <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                    Select All
                                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.checkSelectedAllSiteIds()} onChange = {(e) => this.selectedAllSiteIds(e)}/>
                                  </label>
                                </div>
                              </div>
                              <div className="p-2 max-h-60 overflow-y-auto">
                                {user.assigned_mbo_sites.map((detail, i) =>
                                  <div className="py-1" key = {i}>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <label className="flex items-center text-gray-900 text-sm mb-2">
                                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => this.selectSiteIds(e)} value={detail?.site_id} checked={selectedFilterSiteId.includes(detail.site_id)}/>
                                          {`${detail.site_name} (${detail.site_id})`}
                                        </label>
                                          
                                      )}
                                    </Menu.Item>
                                  </div>
                                )}
                              </div>
                              <div className="flex items-center p-2 border-t">
                                <div className="flex justify-center items-center w-full px-3 gap-3">
                                  <div className=''>
                                    <a className='bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={(e) => this.setSelSites(e)}>Save</a>
                                  </div>
                                  <div className=''>
                                    <a className='bg-white text-gray-700 border border-gray-200 text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={() => this.cancelLocationFilter()}>Cancel</a>
                                  </div>
                                </div>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    }
                    {['Netgym Employee', 'Account Owner'].includes(user.role_display) &&
                      <ApprovalSettingComponent business={business} setAlert={this.setAlert} getAllLocationsData={this.getAllLocationsData}/>
                    }
                  </div>
                </div>
                <div className='mt-3 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4'>
                  <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                    {!dataLoaded &&
                      <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                        <p className="mb-1 text-center px-5">{loadingMessage}</p>
                        <img className = "w-9" src="/assets/business/loading.gif" />
                      </div>
                    }
                    {dataLoaded &&
                      <React.Fragment>
                        <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                          <table className='min-w-full divide-y divide-gray-200'>
                            <thead className='bg-gray-50'>
                              <tr>
                                <th className='hidden lg:table-cell pl-4 pr-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                  #
                                </th>
                                <th className='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                  <div className='flex items-center'>
                                    <span className='cursor-pointer' onClick={(e) => this.sort(e, 'name', 'desc')}>Name</span> &nbsp; &nbsp;
                                    {sortType !== 'name' &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'name', 'desc')}>
                                        <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                      </svg>
                                    }
                                    {(sortType === 'name' && sortReverse) &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'name', 'asc')}>
                                        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                      </svg>
                                    }
                                    {(sortType === 'name' && !sortReverse) &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'name', 'desc')}>
                                        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                      </svg>
                                    }
                                  </div>
                                </th>
                                <th className='hidden lg:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                  ADDRESS
                                </th>
                                {(business.mbo_integration && !business?.single_mbo_id) &&
                                  <th className='hidden lg:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'>
                                    MBO Site Name
                                  </th>
                                }
                                <th className='hidden lg:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                  <div className='flex items-center'>
                                    <span className='cursor-pointer' onClick={(e) => this.sort(e, 'city', 'desc')}>CITY</span> &nbsp; &nbsp;
                                    {sortType !== 'city' &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'city', 'desc')}>
                                        <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                      </svg>
                                    }
                                    {(sortType === 'city' && sortReverse) &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'city', 'asc')}>
                                        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                      </svg>
                                    }
                                    {(sortType === 'city' && !sortReverse) &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'city', 'desc')}>
                                        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                      </svg>
                                    }
                                  </div>
                                </th>
                                <th className='hidden lg:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                  <div className='flex items-center'>
                                    <span className='cursor-pointer' onClick={(e) => this.sort(e, 'state', 'desc')}>STATE</span> &nbsp; &nbsp;
                                    {sortType !== 'state' &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'state', 'desc')}>
                                        <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                      </svg>
                                    }
                                    {(sortType === 'state' && sortReverse) &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'state', 'asc')}>
                                        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                      </svg>
                                    }
                                    {(sortType === 'state' && !sortReverse) &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'state', 'desc')}>
                                        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                      </svg>
                                    }
                                  </div>
                                </th>
                                <th className='hidden lg:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                  Approval Setting
                                </th>
                                <th className='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                  GROUP FITNESS MANAGERS
                                </th>
                                <th className='hidden lg:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                  REGION
                                </th>
                                <th className='hidden lg:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                  <div className='flex items-center'>
                                    <span className='cursor-pointer' onClick={(e) => this.sort(e, 'created_at', 'desc')}>CREATED DATE</span> &nbsp; &nbsp;
                                    {sortType !== 'created_at' &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'created_at', 'desc')}>
                                        <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                      </svg>
                                    }
                                    {(sortType === 'created_at' && sortReverse) &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'created_at', 'asc')}>
                                        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                      </svg>
                                    }
                                    {(sortType === 'created_at' && !sortReverse) &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'created_at', 'desc')}>
                                        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                      </svg>
                                    }
                                  </div>
                                </th>
                                {(permissions?.business_locations?.write || user.role_display === "Netgym Employee") &&
                                  <th></th>
                                }
                              </tr>
                            </thead>
                            <tbody className='divide-y divide-gray-200'>
                              {locations.map((location, i) =>  
                                <tr key={i} className='bg-white'>
                                  <td className='hidden lg:table-cell pl-4 pr-3 py-4 text-sm font-normal text-gray-500'>{baseCount+i+1}</td>
                                  <td className='w-full max-w-0 sm:w-auto sm:max-w-none px-3 py-4 text-sm font-normal'>
                                    <div className="text-gray-900 truncate">{location.name}</div>
                                    <dl className='lg:hidden'>
                                      {(business.mbo_integration && !business?.single_mbo_id) &&
                                        <dd className='mt-1 truncate'>
                                          <div className='text-sm font-normal text-gray-500'>{location.site_name}</div>
                                        </dd>
                                      }
                                      <dd className='mt-1 truncate '>
                                        <div className='text-gray-500 truncate'>{location.city}</div>
                                      </dd>
                                      <dd className='mt-1 truncate '>
                                        <div className='text-gray-500'>{location.state}</div>
                                      </dd>
                                    </dl>
                                  </td>
                                  <td className='hidden lg:table-cell px-3 py-4 text-sm font-normal text-gray-500'>{location.address}</td>
                                  {(business.mbo_integration && !business?.single_mbo_id) &&
                                    <td className='hidden lg:table-cell px-4 py-4 text-sm font-normal text-gray-500'>{location.site_name}</td>
                                  }
                                  <td className='hidden lg:table-cell px-3 py-4 text-sm font-normal text-gray-500'>{location.city}</td>
                                  <td className='hidden lg:table-cell px-3 py-4 text-sm font-normal text-gray-500'>{location.state}</td>
                                  <td className='hidden lg:table-cell px-3 py-4 text-sm font-normal text-gray-500'>{location.approval_style || business?.default_approval_style}</td>
                                  <td className='px-3 py-4 text-sm font-normal text-gray-500'>{location.manager_names}</td>
                                  <td className='hidden lg:table-cell px-3 py-4 text-sm font-normal text-gray-500'>{location.business_region === null ? "" : location.business_region.name}</td>
                                  <td className='hidden lg:table-cell px-3 py-4 text-sm font-normal text-gray-500'>{location.created}</td>
                                  <td className='px-3 py-4 text-sm font-normal text-gray-500'>
                                    <div className='flex gap-x-2'>
                                      {(permissions?.business_locations?.write || user.role_display === "Netgym Employee") &&
                                        <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => this.setEdit(e, location)} data-tip data-for={`edit_button_`+location?.id.toString()}>
                                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
                                          <ReactTooltip className='max-w-xs whitespace-nowrap' id={`edit_button_`+location?.id.toString()} place="top" effect="solid">
                                            Edit location
                                          </ReactTooltip>
                                        </div>
                                      }
                                      {(permissions?.business_locations?.write || user.role_display === "Netgym Employee") &&
                                        <React.Fragment>
                                          {(!business?.clubready_integration && !business?.fisikal_integration) ?
                                            <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => this.setDelete(e, location)} data-tip data-for={`delete_button_`+location?.id.toString()}>
                                              <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                                              <ReactTooltip className='max-w-xs whitespace-nowrap' id={`delete_button_`+location?.id.toString()} place="top" effect="solid">
                                                Delete location
                                              </ReactTooltip>
                                            </div>
                                          :
                                            (user.role_display === "Netgym Employee" &&
                                              <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => this.setDelete(e, location)} data-tip data-for={`delete_button_`+location?.id.toString()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                                                <ReactTooltip className='max-w-xs whitespace-nowrap' id={`delete_button_`+location?.id.toString()} place="top" effect="solid">
                                                  Delete location
                                                </ReactTooltip>
                                              </div>
                                            )
                                          }
                                        </React.Fragment>
                                      }
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="flex justify-center w-full flex-wrap mt-4">
                          <div></div>
                          <div className="custom-pagination order-first sm:order-last mb-3 sm:mb-0 mr-7 pb-6">
                            {total > pageSize && 
                              <Pagination
                                activePage={activePage}
                                itemsCountPerPage={pageSize}
                                totalItemsCount={total}
                                pageRangeDisplayed={9}
                                onChange={this.handlePageChange.bind(this)}
                                itemClassPrev='previous'
                                itemClassNext='next'
                                itemClassFirst='first'
                                itemClassLast='last'
                                hideDisabled={true}
                              />
                            }
                          </div>
                      </div>
                      </React.Fragment>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {showModal &&
          <LocationForm
            showModal={showModal}
            states={states}
            time_zones={time_zones}
            tags={tags}
            fitnessManagers={fitnessManagers}
            regions={regions}
            addLocation={this.addLocation}
            formType={formType}
            location={location}
            editLocation={this.editLocation}
            business={business}
            setShowModal={this.closeModal}
            currentUser={user}
            dataReady={dataReady}
            updatingLocations={updatingLocations}
          />
        }
        <Transition.Root show={deleteModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeModal(e)}>
						<div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6 mt-20">
									<div className="absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
											onClick={(e) => this.closeModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										{/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
										</div> */}
										<div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left">
											<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Delete Location
											</Dialog.Title>
											<div className="mt-5">
                        <div className='flex gap-4'>
                          <p className='text-gray-700 w-36 text-left'>Name</p>
                          <p className="text-sm text-gray-500">{deleteLocation.name}</p>
                        </div>
                        <div className='flex gap-4'>
                          <p className='text-gray-700 w-36 text-left'>Street Address</p>
                          <p className="text-sm text-gray-500">{deleteLocation.address}</p>
                        </div>
                        <div className='flex gap-4'>
                          <p className='text-gray-700 w-36 text-left'>City</p>
                          <p className="text-sm text-gray-500">{deleteLocation.city}</p>
                        </div>
                        <div className='flex gap-4'>
                          <p className='text-gray-700 w-36 text-left'>State</p>
                          <p className="text-sm text-gray-500">{deleteLocation.state}</p>
                        </div>
                        <div className='flex gap-4'>
                          <p className='text-gray-700 w-36 text-left'>Zipcode</p>
                          <p className="text-sm text-gray-500">{deleteLocation.zipcode}</p>
                        </div>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-blue text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={(e) => this.processDelete(e)}
                    >
                      Delete Location
                    </button>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
        <Transition.Root show={syncModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeModal(e)}>
						<div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block ">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl w-full sm:p-6 min-h-515 mt-20">
									<div className="absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
											onClick={(e) => this.closeModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										{/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
										</div> */}
										<div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
											<Dialog.Title as="h4" className="text-lg leading-6 font-medium text-gray-900 border-b">
                        {syncType === 'mbo' &&
                          <div className='flex'>
                            <p className='text-sm py-3.5'>MBO Locations Preview</p>
                            <a className="powered-by-mindbody-logo focus:outline-none flex items-center ml-2 mb-1" href="https://company.mindbodyonline.com/" target="_blank">
                              <img className="w-28" src="/assets/powered-by-mindbody-new.png" />
                            </a>
                          </div>
                        }
                        {syncType === 'marinatek' &&
                          <React.Fragment>
                            Mariana Tek Locations Preview
                          </React.Fragment>
                        }
											</Dialog.Title>
											<div className="mt-5">
                        {/* <div className='flex gap-4'>
                          <p className='text-gray-700 w-36'>Name</p>
                          <p className="text-sm text-gray-500">Haha</p>
                        </div> */}
                        {syncType === 'mbo' &&
                          <div className='flex'>
                            {site_ids.length > 1 &&
                              <div className="relative filters_box  w-full lg:w-64">
                                <Menu as="div" className="relative block text-left">
                                  <div>
                                    <Menu.Button className="inline-flex items-center justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 mb-3">
                                      {selectedSiteId === '' ?
                                        'Select MBO Site'
                                      :
                                        (user.assigned_mbo_sites.map((detail, i) => 
                                          <div key={i}>
                                            {detail?.site_id == selectedSiteId &&
                                              <p className='text-gray-700'>
                                                {detail?.site_name}
                                              </p>
                                            }
                                          </div>
                                        ))
                                      }
                                      <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95">
                                    <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="p-2 max-h-60 overflow-y-auto">
                                        {user.assigned_mbo_sites.map((detail, i) =>
                                          <div className="py-1" key = {i}>
                                            <Menu.Item>
                                              {({ active }) => (
                                                <label className="flex items-center text-gray-900 text-sm mb-2">
                                                  <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => this.selectMboSite(e)} value={detail?.site_id} checked={selectedSiteId == detail.site_id}/>
                                                  {`${detail.site_name} (${detail.site_id})`}
                                                </label>
                                                  
                                              )}
                                            </Menu.Item>
                                          </div>
                                        )}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            }
                          </div>
                        }
                        <div>
                          {abcClassesLoading &&
                            <div className="sync-abc-preview-loading text-center">
                                <div className="loading-gif flex items-center justify-center flex-col my-4">
                                <p className="mb-1">{window.getSettingSlogan()}</p>
                                <img className='w-8 inline-block' src="/assets/business/loading.gif" />
                                </div>
                            </div>
                          }
                          {!abcClassesLoading &&
                            <div className="flex flex-col">
                              {remote_classes.length > 0 &&
                                <React.Fragment>
                                  {syncType === 'marinatek' &&  
                                    <div className="custom-checkbox d-flex mb-3 mt-3">
                                      <label className="custom-check">
                                        <input type="checkbox"
                                          checked={selectedActive}
                                          onChange = {(e) => this.selectActive(e)}
                                        />
                                        <span className= "checkmark green top-0"></span>
                                      </label>
                                      <div>Active</div>
                                    </div>
                                  }
                                  <div className="flex-grow overflow-auto max-h-64 md:max-h-96 ">
                                    <table className="relative w-full border">
                                      <thead>
                                        <tr>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm bg-white">
                                            <div>
                                              {allSelected &&
                                                <img className='w-5' onClick={(e) => this.checkAll(e, false)} src="/assets/icon_select_all.png" />
                                              }
                                              {!allSelected &&
                                                <img className='w-5' onClick={(e) => this.checkAll(e, true)} src="/assets/icon_select_blank.png" />
                                              }
                                            </div>
                                          </th>
                                          {syncType === 'mbo' &&
                                            <React.Fragment>
                                              <th className="sticky top-0 px-2 py-3 font-semibold text-sm bg-white whitespace-nowrap">MBO ID</th>
                                              <th className="sticky top-0 px-2 py-3 font-semibold text-sm bg-white whitespace-nowrap">MBO Site ID</th>
                                            </React.Fragment>
                                          }
                                          {syncType === 'marinatek' &&
                                            <th className="sticky top-0 px-2 py-3 font-semibold text-sm bg-white whitespace-nowrap">Marianatek ID</th>
                                          }
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm bg-white whitespace-nowrap">Location Name</th>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm bg-white whitespace-nowrap">Location Address</th>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm bg-white">City</th>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm bg-white">State</th>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm bg-white">Zipcode</th>
                                          {syncType === 'marinatek' &&
                                            <th className="sticky top-0 px-2 py-3 font-semibold text-sm bg-white">Active</th>
                                          }
                                        </tr>
                                      </thead>
                                      <tbody className="divide-y">
                                        {remote_classes.map((cls, i) =>
                                          <tr key={i}>
                                            <td className="px-2 py-4 text-center text-sm">
                                              <div>
                                                <label>
                                                  <input type="checkbox"
                                                    checked={this.objectChecked(cls)}
                                                    onChange = {(e) => this.toggleRequestClass(e, cls)}
                                                  />
                                                </label>
                                              </div>
                                            </td>
                                            {syncType === 'mbo' &&
                                              <React.Fragment>
                                                <td className="px-2 py-4 text-sm">{cls.mindbody_id}</td>
                                                <td className="px-2 py-4 text-sm">{this.state.selectedSiteId}</td>
                                              </React.Fragment>
                                            }
                                            {syncType === 'marinatek' &&
                                              <td className="px-2 py-4 text-sm">{cls.marianatek_id}</td>
                                            }
                                            <td className="px-2 py-4 text-sm">{cls.name}</td>
                                            <td className="px-2 py-4 text-sm">{cls.address}</td>
                                            <td className="px-2 py-4 text-sm">{cls.city}</td>
                                            <td className="px-2 py-4 text-sm">{cls.state}</td>
                                            <td className="px-2 py-4 text-sm">{cls.zipcode}</td>
                                            {syncType === 'marinatek' &&
                                              <td className="px-2 py-4 text-sm">{cls.active ? 'true' : 'false'}</td>
                                            }
                                          </tr>
                                        )}
                                      </tbody>
                                      
                                    </table>
                                  </div>
                                </React.Fragment>
                              }
                            </div>
                          }
                        </div>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    {remote_classes.length > 0 &&
                      <button
                        type="button"
                        className="cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-blue text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={(e) => this.processSync(e)}
                      >
                        {syncing &&
                          <React.Fragment>
                            <div className="flex items-center justify-center">
                              <svg className="animate-spin -ml-1 mr-1.5 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                              <p className="text-center">{window.getSaveSyncSlogan()}</p>
                            </div>
                          </React.Fragment>
                        }
                        {!syncing &&
                          <React.Fragment>
                            Sync Data
                          </React.Fragment>
                        }
                      </button>
                    }
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
      </React.Fragment>
    )
  }
}
