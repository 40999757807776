import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    .loading-text {
      background: #333;
      padding: 20px;
      font-size: 14px;
    }

    .loading-text {
      color: #ccc;
    }
  `;

export default function ChannelListContainer({loading, children, clickOnChannel}) {
  const loadingText = (<img className = "w-9 mx-auto mt-5" src="/assets/business/loading.gif" />)

  return (
    <Container>
      {loading ? <div className=''>{loadingText}</div> : children}
    </Container>
  )
}
