import React, { Fragment, useState } from "react";
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import { checkTheSignUpPagePath } from '../../helpers';

export default function DailyNotificationsComponent(props) {
  const { user, setAlert, handleUpdateUser } = props
  const [ btnDisabled, setBtnDisabled ] = useState(false)
  const [ notificationPrefrence, setNotificationPreference ] = useState(user?.daily_notifications || 'daily')
  const allPrefrences = [
    {value: 'daily', label: 'Daily Summary', helpText: 'once per day'},
    {value: 'immediately', label: 'Send me every invite immediately', helpText: ''}
  ]

  const handleCheckBox = (e) => {
    let value = e.target.value
    setNotificationPreference(value)
  }

  const handleSaveDailyNotifications = (e) => {
    e.preventDefault()
    setBtnDisabled(true)
    let params = {
      daily_notifications: notificationPrefrence
    }
    let url = '/api/v3/update_notifications'
    axiosRequest(url, 'PATCH', params, 'data').then(result => {
      if(result.status == 200){
        setAlert(true, 'success', result?.success)
        setTimeout(() => {
          setAlert(false, '', '')
          setBtnDisabled(false);
        }, 3000);
        handleUpdateUser(result?.user, checkTheSignUpPagePath(result?.user))
      }else{
        setAlert(true, 'danger', result?.error)
        setTimeout(() => {
          setAlert(false, '', '')
        }, 3000);
        setBtnDisabled(false);
      }
    })
  }

  return(
    <Fragment>
      <div className="w-full flex flex-col items-center justify-center mt-4 px-2">
        <div className="flex items-center justify-center text-center text-lg text-gray-900 font-normal">
          How often do you want to receive sub request invites from your team?
        </div>
        <div className="flex flex-col w-full md:w-3/5 lg:w-3/6 xl:w-2/5 px-0 sm:px-5 md:px-10 lg:px-5 xl:px-14 justify-between mt-7">
          <div className="flex flex-col gap-4">
            {allPrefrences.map((item, i) => 
              <label key={i} className={`flex items-center gap-1 text-sm assign-checkbox`}>
                <input type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer" checked={notificationPrefrence == item?.value} value={item?.value} onChange={(e) => handleCheckBox(e)}/>
                {item?.label}
                {item?.helpText !== '' ? ` (${item?.helpText})` : ''}
              </label>
            )}
          </div>
        </div>
        <div className="w-full md:w-3/5 lg:w-3/6 xl:w-2/5 flex flex-col items-center justify-center text-center mt-7 gap-1">
          <p className="text-xs text-gray-600 text-center">*You’ll receive an individual notification for any sub request sent to you within 24 hours of the start time of the class to ensure the class gets covered.</p>
        </div>
        <div className='flex flex-col items-center justify-center w-full mt-7 gap-2'>
          <button
            className={`bg-gray-900 rounded-md text-white flex items-center justify-center px-8 py-2 text-sm w-1/2 md:w-1/5 ${btnDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={(e) => handleSaveDailyNotifications(e)}
            disabled={btnDisabled}
          >
            Next
          </button>
        </div>
      </div>
    </Fragment>
  )
}