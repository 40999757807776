import React, { Fragment } from "react";
import ReactTooltip from "react-tooltip";
import ExclamationIcon from './../../../../../../assets/exclamationIcon';
import ReactHtmlParser from 'react-html-parser';

export default function EventsContentComponent(props) {
  const { events, handleEventClick } = props;

  const showEventTime = (dateString) => {
    let date = new Date(dateString);
    let options = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedDate = date.toLocaleString('en-US', options);
    return (formattedDate)
  }

  const checkDateIsPast = (date) => {
    let today = new Date();
    let eventDate = new Date(date);
    if(eventDate < today){
      return true
    }
    return false
  }
  
  return(
    <Fragment>
      <div className="fc-popover-body px-5 py-3">
        {events?.map((eventInfo, i) =>
          <div key={i} className="fc-daygrid-event-harness">
            <a href={eventInfo?.redirect_url} className="fc-event fc-event-start fc-event-end fc-event-past fc-daygrid-event fc-daygrid-dot-event" onClick={(e) => handleEventClick(e, eventInfo?.id, eventInfo?.redirect_url)}>
              <div className='lg:flex items-center rounded-md p-1 w-full event-border' style={{background:`${eventInfo?.background}`, borderColor: `${eventInfo?.border}`}}>
                <div className=''>
                  <span className='inline-block leading-3'>
                    {eventInfo?.extra_html !== '' &&
                      <span className='mr-1'>
                        <Fragment>
                          {ReactHtmlParser(eventInfo?.extra_html)}
                        </Fragment>
                      </span>
                    }
                    <span className={`event-time mr-1 ${checkDateIsPast(eventInfo.start) && 'opacity-50'}`}>{showEventTime(eventInfo.start).toLowerCase()}</span>
                    {eventInfo?.key &&
                      <span className={`event-time mr-1 ${checkDateIsPast(eventInfo.start) && 'opacity-50'}`}>{eventInfo?.tz}</span>
                    }
                    <span className={`event-tile ${checkDateIsPast(eventInfo.start) && 'opacity-50'}`}>{eventInfo.title}</span>
                    {(eventInfo?.unavailable_error_message !== '' || eventInfo?.api_sync_error_message !== '') &&
                      <span data-tip data-for={eventInfo?.id.toString()}>
                        <ExclamationIcon classNames={"text-red-600 h-3.5 w-3.5 inline-block ml-1"} componentId={eventInfo?.id.toString()}/>
                      </span>
                    }
                  </span>
                  {(eventInfo?.unavailable_error_message !== '' || eventInfo?.api_sync_error_message !== '') &&
                    <Fragment>
                      <ReactTooltip className='max-w-xs text-center' id={eventInfo?.id.toString()} place="top" effect="solid">
                      {eventInfo?.unavailable_error_message || eventInfo?.api_sync_error_message}
                      </ReactTooltip>
                    </Fragment>
                  }
                </div>
              </div>
            </a>
          </div>
        )}
      </div>
    </Fragment>
  )
}