import React, { Fragment, useState } from "react";
import CustomPopupContainerComponent from './../../customPopupContainerComponent';
import { axiosRequestNoData } from '../../../helpers';

export default function CommentDeleteConfirmationComponent(props){
  const { eventData, handleSetAlert, handleUpdateEvents, comment, showDeleteCommentPopup, closeDeleteCommentPopup } = props
  const [ disabledDeleteBtn, setDisabledDeleteBtn ] = useState(false)

  const handleContinueDelete = (comment) => {
    setDisabledDeleteBtn(true)
    let url = `/api/v2/business_comments/destroy/${comment.id}`
    axiosRequestNoData(url, 'DELETE').then((result) => {
      if(result?.status == 200){
        let new_comments = eventData?.comments?.filter((c) => c.id !== comment.id)
        handleUpdateEvents({...eventData, comments: new_comments})
        handleSetAlert(true, result?.success, 'success')
        setDisabledDeleteBtn(false)
        closeDeleteCommentPopup(false)
      }else{
        handleSetAlert(true, result?.error, 'danger')
        setDisabledDeleteBtn(false)
      }
      setTimeout(() => {
        handleSetAlert(false, '', '') 
      }, 3000)
    })

  }

  return(
    <Fragment>
      <CustomPopupContainerComponent show={showDeleteCommentPopup} close={closeDeleteCommentPopup} title={'Delete Comment'} customWidth={''}>
        <div className="px-4 pt-3 font-open-sans">
          <div className='text-sm px-2 font-semibold text-gray-700 text-center'>
            {'Are you sure you want to delete this comment?'}
          </div>
          <div className="mt-5 flex gap-3 flex-row-reverse justify-center">
            <button 
              className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-40 py-2 text-sm font-semibold ${disabledDeleteBtn ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => handleContinueDelete(comment)}
              disabled={disabledDeleteBtn}
            >
              Okay
            </button>
            <button 
              className={`bg-white border rounded-xl text-gray-800 flex items-center justify-center w-40 py-2 text-sm hover:bg-gray-100 font-semibold shadow`}
              onClick={() => closeDeleteCommentPopup(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  )
}