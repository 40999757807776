import React, { Component } from 'react'
import Cookies from 'js-cookie';
import { getSudomain, setTitle, getHeaders } from '../helpers'
import Pagination from "react-js-pagination";
import ReactTooltip from "react-tooltip";

export default class Notifications extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      alert_message: '',
      alert_type: '',
      show: false,
      users: [],
      selectedUser:  '',
      notifications: [],
      pageSize: 30,
      page: 1,
      totalAudits: 0     
    }
  }

  componentDidMount = () => {
    setTitle('notification_logs')
    this.props.setCurrent('Notification Logs')
    if(this.props.pageLoaded){
      this.getUsersList(this.props?.business?.id)
    }
  }

  getUsersList = (businessId) => {
    const requestOptions = {
      method: 'GET',
      headers: getHeaders(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()),
    };
    fetch(`/api/v3/business/${businessId}/users`, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status === 200){
        this.setState({
          users: result?.data
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  handleSelectChange = (e) => {
    let val = e.target.value
    this.setState({selectedUser: val})
    this.getNotificationsList(val, 1)
  }

  getNotificationsList = (userId, page) => {
    const requestOptions = {
      method: 'GET',
      headers: getHeaders(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()),
    };
    fetch(`/api/v3/notifications/user/${userId}?page=${page}`, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status === 200){
        this.setState({
          notifications: result?.data,
          pageSize: parseInt(result?.page_size),
          page: parseInt(result?.page_number),
          totalAudits: parseInt(result?.total_audits)
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  handleClick = (e, id, type) => {
    e.preventDefault()
    let url = `/api/v3/notifications/${id}/${type}`
    const requestOptions = {
      method: 'GET',
      headers: getHeaders(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()),
    };
    fetch(url, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status === 200){ 
        let url = result?.data
        window.open(url, '_blank');
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  handlePageChange(pageNumber) {
    this.setState({
      page: pageNumber, 
    });
    this.getNotificationsList(this.state.selectedUser, pageNumber)
  }

  render(){
    const { users, selectedUser, notifications, pageSize, page, totalAudits } = this.state

    return(
      <div className='w-full pt-20 md:pt-8 h-screen'>
        <div className='p-5'>
          <div className='mt-1 sm:mt-0 sm:col-span-1'>
            <select className='form-select lg:w-80 bg-white mt-1 block h-10 w-full pl-2 pr-10 py-2 border border-gray-300 shadow-sm text-sm rounded-md focus:outline-none focus:ring focus:ring-gray-300' value={selectedUser} name="state" onChange={(e)=>this.handleSelectChange(e)} >
              <option value="">Select a user</option>
              {users.map((user,i) =>
                <option key={i} value={user.id}>{user.name}</option>
              )}
            </select>
          </div>
          {notifications?.length > 0 &&
            <div className='m-3'>
              <div className={`flex-grow overflow-auto ${totalAudits > pageSize ? 'notification-audits-table-pagination-max-h' : 'notification-audits-table-max-h'}`}>
                <table className="relative w-full border">
                  <thead className='bg-white'>
                    <tr className='bg-white'>
                      <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white">User Name</th>
                      <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white">Push Notification</th>
                      <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white">Email</th>
                      <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white">SMS</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y">
                    {notifications.map((item, i) => 
                      <tr key={i}>
                        <td className="px-2 py-4 text-center text-sm">{item?.user}</td>
                        <td className="px-2 py-4 text-center text-sm gap-2 flex justify-center">
                          {item?.message ?
                              <label>
                                <input type="checkbox" className="w-4 h-4 form-checkbox text-green-600 border rounded border-gray-300 focus:outline-none focus:border-gray-300 cursor-pointer" defaultChecked = {true} disabled={true}/>
                              </label>
                            :
                              <label>
                                <input type="checkbox" className="w-4 h-4 form-checkbox text-red-600 border rounded border-gray-300 focus:outline-none focus:border-gray-300 cursor-pointer" defaultChecked = {true} disabled={true}/>
                              </label>
                          }
                          <div data-tip data-for={`notificationLogMessageTooltip${item?.id}`} className=''>
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-500 h-5 w-5 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                            </svg>
                            <ReactTooltip className='max-w-xs' id={`notificationLogMessageTooltip${item?.id}`} place="top" effect="solid">
                              {item?.message || 'N/A'}
                            </ReactTooltip>
                          </div>
                          <div data-tip data-for={`notificationLogSendTimeTooltip${item?.id}`} className=''>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-gray-500 h-5 w-5 cursor-pointer">
                              <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z" clipRule="evenodd" />
                            </svg>
                            <ReactTooltip className='max-w-xs' id={`notificationLogSendTimeTooltip${item?.id}`} place="top" effect="solid">
                              {item?.time  || 'N/A'}
                            </ReactTooltip>
                          </div>
                        </td>
                        <td className="px-2 py-4 text-center text-sm">
                          {item?.email_subject !== '' ?
                              <button onClick={(e) => this.handleClick(e, item?.id, 'email')}>
                                <input type="checkbox" className="w-4 h-4 form-checkbox text-green-600 border rounded border-gray-300 focus:outline-none focus:border-gray-300 cursor-pointer" defaultChecked = {true}/>
                              </button>
                            :
                              <button disabled={true}>
                                <input type="checkbox" className="w-4 h-4 form-checkbox text-red-600 border rounded border-gray-300 focus:outline-none focus:border-gray-300 cursor-pointer" defaultChecked = {true} disabled={true}/>
                              </button>
                          }
                        </td>
                        <td className="px-2 py-4 text-center text-sm">
                          {item?.sms_text !== '' ?
                              <button onClick={(e) => this.handleClick(e, item?.id, 'sms')}>
                                <input type="checkbox" className="w-4 h-4 form-checkbox text-green-600 border rounded border-gray-300 focus:outline-none focus:border-gray-300 cursor-pointer" defaultChecked = {true}/>
                              </button>
                            :
                              <button disabled={true}>
                                <input type="checkbox" className="w-4 h-4 form-checkbox text-red-600 border rounded border-gray-300 focus:outline-none focus:border-gray-300 cursor-pointer" defaultChecked = {true} disabled={true}/>
                              </button>
                          }
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {totalAudits > pageSize &&
                <div className="py-3 mt-4 flex items-center justify-center">
                          
                  <div className="flex justify-center w-full flex-wrap">
                    <div className="custom-pagination order-first sm:order-last mb-3 sm:mb-0">
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={pageSize}
                        totalItemsCount={totalAudits}
                        pageRangeDisplayed={9}
                        onChange={this.handlePageChange.bind(this)}
                        itemClassPrev='previous'
                        itemClassNext='next'
                        itemClassFirst='first'
                        itemClassLast='last'
                        hideDisabled={true}
                      />
                    </div>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      </div>
    )
  }

}