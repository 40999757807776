import React, { Fragment, useState } from "react";
import InputMask from 'react-input-mask';
import { axiosRequest } from '../../../../subRequests/helpers/helpers'

export default function ConfirmVerificationCodeComponent(props) {
  const { user, setAlert, setVerificationCodeSent, handleUpdateUser } = props
  const [ verificationCode, setVerificationCode ] = useState('');
  const [ disabledNext, setDisabledNext ] = useState(false)

  const handleVerifyCode = (e) => {
    setDisabledNext(true)
    let params = {
      user_data: {
        code: verificationCode
      }
    }
    axiosRequest('/api/v3/my_profile/confirm_verification_code', 'POST', params, 'data').then(result => {
      if(result.status === 200){
        setAlert(true, 'success', result?.success)
        handleUpdateUser(result?.user, '/notifications')
      }else{
        setAlert(true, 'danger', result?.error)
      }
      setDisabledNext(false)
      window.setTimeout(() => {
        setAlert(false, '', '')
      }, 3000)
    })
  }

  const handleBlur = (e) => {
    if(checkButtonDisabled(e.target.value)){
      setVerificationCode('')
    }
  }

  const hanelChange = (e) => {
    let val = e.target.value
    if(val == '' || val == '______'){
      setVerificationCode('')
    }else{
      setVerificationCode(val)
    }
  }

  const checkButtonDisabled = (code) => {
    let val = code.replace(/_/g, '')
    return (val == '' || (val !== "" && val.length !== 6))
  }

  return (
    <Fragment>
      <div className="flex flex-wrap items-center justify-between gap-5">
        <div className="md:w-64 rounded-md w-full text-sm shadow-sm">
          <InputMask
            mask="999999"
            maskChar="_"
            value={verificationCode}
            className="border rounded-md px-3 py-1.5 w-full h-10 focus:outline-none"
            onChange={hanelChange}
            onBlur={handleBlur}
          >
            {() => (
              <input
                type="text"
                placeholder="Text Code"
                className="border rounded-md px-3 py-1.5 w-full h-10 focus:outline-none"
                name="code"
                required
              />
            )}
          </InputMask>
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-5 mt-4">
        <button className={`bg-white border hover:bg-gray-100 shadow rounded-md text-black flex items-center justify-center px-2 py-2 text-sm w-full md:w-40 h-9`} onClick={(e) => setVerificationCodeSent(false)}>
          Resend Code
        </button>
        <button className={`bg-gray-900 rounded-md text-white flex items-center justify-center px-2 py-2 text-sm w-full md:w-40 h-9 ${(checkButtonDisabled(verificationCode) || disabledNext) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={(e) => handleVerifyCode(e)} disabled={checkButtonDisabled(verificationCode) || disabledNext}>
          Next
        </button>
      </div>
    </Fragment>
  )
}