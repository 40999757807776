import React, { Fragment, useState } from "react";
import SkipConfirmationPopupComponent from './skipConfirmationPopupComponent';
import { checkTheSignUpPagePath } from '../../../helpers';
import { axiosRequest } from '../../../../subRequests/helpers/helpers';

export default function SkipVerificationComponent(props) {
  const { user, setAlert, handleUpdateUser, verificationCodeSent, updateUserDataAndRedirect } = props
  const [ showConfirmationPopup, setShowConfirmationPopup ] = useState(false)
  const [ disableConfirmBtn, setDisableConfirmBtn ] = useState(false)

  const handleSkip = () => {
    if (user?.allow_phone_verification){
      setShowConfirmationPopup(true)
    } else {
      processSkip()
    }
  }

  const handleClosePopup = (data) => {
    setShowConfirmationPopup(data)
  }

  const processSkip = () => {
    setDisableConfirmBtn(true)
    let url = '/api/v3/skip_phone_verification'
    axiosRequest(url, 'POST', {}, 'data').then(result => {
      if(result.status == 200){
        if(result.user?.active){
          updateUserDataAndRedirect(result?.user, '/my-requests')
        } else {
          handleUpdateUser(result?.user, checkTheSignUpPagePath(result?.user))
        }
      }else{
        setAlert(true, 'danger', result?.error)
        setTimeout(() => {
          setAlert(false, '', '')
        }, 3000);
      }
      setDisableConfirmBtn(false)
    })
  }

  return(
    <Fragment>
      {showConfirmationPopup &&
        <SkipConfirmationPopupComponent
          showConfirmationPopup={showConfirmationPopup}
          handleClosePopup={handleClosePopup}
          processSkip={processSkip}
          disableConfirmBtn={disableConfirmBtn}
        />
      }
      <div className="flex w-full justify-center mt-7">
        {!verificationCodeSent &&
          <button className={`custom-a-tag-blue-color font-normal text-base ${disableConfirmBtn ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleSkip()} disabled={disableConfirmBtn}>Skip this step</button>
        }
        {verificationCodeSent &&
          <Fragment>
            <div className="flex flex-col w-full items-center gap-2">
              <p className="flex gap-2 items-center text-center font-light tc-13 text-gray-600">Still not receiving it? <button className={`custom-a-tag-blue-color font-light tc-13 cursor-pointer opacity-90 ${disableConfirmBtn ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => setShowConfirmationPopup(true)} disabled={disableConfirmBtn}>Skip SMS set up for now.</button></p>
              <p className="flex gap-2 text-center font-light tc-13 text-gray-600">Once you activate your account, start a live chat with our support team to help troubleshoot.</p>
            </div>
          </Fragment>
        }
      </div>
    </Fragment>
  )
}