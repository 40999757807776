import React from "react";
import { capitalizeFirstLetter } from './../../../helpers'

export default function FitnessProfileInfo(props){
  const { user, fitnessProfileInfoData } = props

  return(
    <React.Fragment>
      <div className='bg-white rounded-md shadow px-6 pt-7 mb-5'>
        <div className="items-center mb-4 pb-1">
          <div className="text-lg font-medium flex-auto">Fitness Profile</div>
          <div className="text-sm font-normal flex-auto text-gray-500">
            {user.role !== 'Fitness Instructor' && user.role !== 'Ops' &&
              "If you need to update this information, go to Settings > Users > Edit your user profile."
            }
            {user.role === 'Fitness Instructor' && !user.can_instructor_modify_locations &&
              "Contact your manager if you need to update your skills or locations."
            }
            {user.role === 'Fitness Instructor' && user.can_instructor_modify_locations && 
              "Contact your manager if you need to update your skills. Head to notifications to update your locations."
            }
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-normal text-gray-500">
            My Role
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
            <div className='text-sm font-normal text-gray-900'>
              {user?.role}
            </div>
          </div>
        </div>
        {user?.business_skills?.length > 0 &&
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-normal text-gray-500">
              {user?.business_skills?.length === 1 ? 'My Skill Set' : 'My Skill Sets'}
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
              <div className='text-sm font-normal text-gray-900 max-h-72 overflow-x-auto'>
                {user?.business_skills.map((skil, i) =>
                  (i == user?.business_skills?.length - 1 ?
                    <p key = {i}>{capitalizeFirstLetter(skil)}</p>
                    :
                    <p key = {i}>{capitalizeFirstLetter(skil)}</p>
                  )
                )}
              </div>
            </div>
          </div>
        }
        {fitnessProfileInfoData?.locations_count > 0 &&
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-normal text-gray-500">
              {fitnessProfileInfoData?.locations_count == 1 ? "My Location" : "My Locations"}
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
              <div className='text-sm font-normal text-gray-900'>
                {fitnessProfileInfoData?.locations.map((region, i) =>
                  (fitnessProfileInfoData?.show_others_teaching ?
                    <div key = {i}>{region?.region+": "+region?.locations}</div>
                    :
                    <div key = {i}>{region?.locations}</div>
                  )
                )}
              </div>
            </div>
          </div>
        }
        {fitnessProfileInfoData?.assigned_location_count > 0 &&
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-normal text-gray-500">
              {fitnessProfileInfoData?.assigned_location_count == 1 ? "Location I Manage" : "Locations I Manage"}
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
              <div className='text-sm font-normal text-gray-900'>
                {fitnessProfileInfoData?.assigned_locations.map((region, i) =>
                  (fitnessProfileInfoData?.show_others_manage ?
                    <div key = {i}>{region?.region+": "+region?.locations}</div>
                    :
                    <div key = {i}>{region?.locations}</div>
                  )
                )}
              </div>
            </div>
          </div>
        }
      </div>
    </React.Fragment>
  )
}