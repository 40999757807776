import React, { useEffect, useState } from 'react';
import { axiosRequest } from './../../../helpers'

export default function SubRequestLocationsEdit (props){
  const { subRequestRegionAndLocations, setEditSubRequestLocations, setSubRequestLocations } = props
  const [ regionLocations, setRegionLocations ] = useState(subRequestRegionAndLocations?.regions_and_locations || {})
  const [ selectedLocations, setSelectedLocations ] = useState(subRequestRegionAndLocations?.business_location_ids || [])
  const [ selectedRegions, setSelectedRegions ] = useState(subRequestRegionAndLocations?.business_region_ids || [])
  const [ checkOther, setCheckOther ] = useState(subRequestRegionAndLocations?.check_for_others || false)

  const selectRegions = (e, region) => {
    let val = parseInt(e.target.value)
    let regions = selectedRegions
   
    var __FOUND = regions.indexOf(val) > -1;
    var result = region.locations.map(function(a) {return a.id;});
    let selected_locations = selectedLocations;
    
    if(e.target.checked){
      if(!__FOUND){
        setSelectedRegions([...selectedRegions, val]);
        let final_result = []
        for(let i = 0; i < result?.length; i++){
          if(!selected_locations.includes(result[i])){
            final_result.push(result[i])
          }
        }
        setSelectedLocations([...selected_locations, ...final_result])
      }
    }else{
      if(__FOUND){
        setSelectedRegions(selectedRegions.filter((tag, index) => tag !== val));
        selected_locations = selected_locations.filter(item => !result.includes(item));
        setSelectedLocations(selected_locations)
      }
    }
  }

  const selectLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = selectedLocations.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newLocs = [...selectedLocations, val]
        setSelectedLocations(newLocs)
        checkRegionVisibility(region, newLocs)
      }
    }else{
      if(__FOUND){
        let newLocs = selectedLocations.filter((tag, index) => tag !== val)  
        setSelectedLocations(newLocs)
        checkRegionVisibility(region, newLocs)  
      }      
    }
  }

  const checkRegionVisibility = (region, locations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(locations, location_ids)
    let val = region.region?.id

    let regions = selectedRegions
    var __FOUND = regions.indexOf(val) > -1;
    if(equal){
      if(!__FOUND){
        setSelectedRegions([...selectedRegions, val])
      }
    }else{
      if(__FOUND){
        setSelectedRegions(selectedRegions.filter((tag, index) => tag !== val) )
      }
    }
  }

  const handleEditSubRequestLocations = (data) => {
    setEditSubRequestLocations(data)
  }

  const handleSaveInfo = () => {
    let params = {
      business_location_ids: selectedLocations
    }
    setSubRequestLocations(params)
  }

  return(
    <div className='bg-white flex flex-col rounded-md shadow px-6 py-7'>
      <div className="flex items-center mb-3 pb-5 border-b border-gray-200">
        <div className="text-lg font-medium flex-auto">Update Settings</div>
      </div>
      <div className={`sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3`}>
        <label className="block text-sm font-normal text-gray-500">
          Where would you like to receive sub requests?
        </label>
        <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
          <div className='text-sm font-normal text-gray-900'>
          </div>
        </div>
      </div>
      <div className={`sm:gap-4 sm:items-start py-3 ml-10`}>
        {regionLocations.map((region, i) =>
          <div className="py-1" key = {i}>
            {!checkOther &&
              <div>
                <label className="flex items-center text-gray-900 text-sm mb-2">
                  <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => selectRegions(e, region)} value={region?.region?.id} checked={selectedRegions.includes(region.region.id)}/>
                  {region.region.name}
                </label>
              </div>
            }
            <div className="ml-5 flex gap-3 flex-wrap">
              {region.locations.map((location, j) =>
                <div key = {j} className="flex">
                  <label className="flex items-center text-gray-900 text-sm mb-2">
                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={selectedLocations?.includes(location?.id)} value={location.id} onChange={(e) => selectLocation(e, region)}/>
                    {location.name}
                  </label>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="flex mt-4 gap-3 md:justify-end">
        <button className="text-base rounded-md text-gray-700 font-medium border border-gray-200 px-6 py-3" onClick={() => handleEditSubRequestLocations(false)}>
          Cancel
        </button>
        <button className={`flex text-base font-medium rounded-md text-white px-4 py-3 gap-2 items-center ${ 'bg-dark-blue'}`} onClick={(e) => handleSaveInfo(e)}>
          <svg viewBox="0 0 14 10" className="h-4 w-4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.4226 0.244078C13.7481 0.569515 13.7481 1.09715 13.4226 1.42259L5.0893 9.75592C4.76386 10.0814 4.23622 10.0814 3.91078 9.75592L0.577452 6.42259C0.252015 6.09715 0.252015 5.56951 0.577452 5.24408C0.902889 4.91864 1.43053 4.91864 1.75596 5.24408L4.50004 7.98816L12.2441 0.244078C12.5696 -0.0813592 13.0972 -0.0813592 13.4226 0.244078Z" fill="#ffffff"/>
          </svg>
          Save
        </button>
      </div>
    </div>
  )
}