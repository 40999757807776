import React, { Fragment } from "react";

export default function TextNotificationIcon({ classNames, customColour }){

  return(
    <Fragment>
      <svg className={classNames} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.795 6.2V8.235C7.795 8.415 7.775 8.585 7.73 8.74C7.545 9.475 6.935 9.935 6.095 9.935H4.735L3.225 10.94C3 11.095 2.7 10.93 2.7 10.66V9.935C2.19 9.935 1.765 9.765 1.47 9.47C1.17 9.17 1 8.745 1 8.235V6.2C1 5.25 1.59 4.595 2.5 4.51C2.565 4.505 2.63 4.5 2.7 4.5H6.095C7.115 4.5 7.795 5.18 7.795 6.2Z" fill={customColour ? customColour : "#525252"}/>
        <path d="M8.875 7.8C9.51 7.8 10.045 7.59 10.415 7.215C10.79 6.845 11 6.31 11 5.675V3.125C11 1.95 10.05 1 8.875 1H4.625C3.45 1 2.5 1.95 2.5 3.125V3.5C2.5 3.64 2.61 3.75 2.75 3.75H6.095C7.45 3.75 8.545 4.845 8.545 6.2V7.55C8.545 7.69 8.655 7.8 8.795 7.8H8.875Z" fill={customColour ? customColour : "#525252"}/>
      </svg>

    </Fragment>
  )
}