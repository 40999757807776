import React, { Fragment } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMobileScreenButton, faCircleMinus } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
library.add( faMobileScreenButton, faEnvelope, faCircleMinus )

export default function ImageComponent(props) {
  const { notifications } = props

  return(
    <Fragment>
      <div className="flex items-center gap-2 sm:gap-3 md:gap-4 lg:gap-8">
        <div className="relative">
          <FontAwesomeIcon icon="fa-solid fa-mobile-screen-button" className={`text-5xl sm:text-7xl border-2 border-gray-900 rounded-md py-1 px-2.5 ${['email', 'away'].includes(notifications) ? 'opacity-30' : ''}`} />
          {['email', 'away'].includes(notifications) &&
            <div>
              <FontAwesomeIcon icon="fa-solid fa-circle-minus" className="text-2xl sm:text-3xl absolute right-0 left-0 top-0 bottom-0 text-center m-auto"/>
            </div>
          }
        </div>
        <div className="relative">
          <FontAwesomeIcon icon="fa-regular fa-envelope" className={`text-5xl sm:text-7xl border-2 border-gray-900 rounded-md py-1 px-2.5 ${['sms', 'away'].includes(notifications) ? 'opacity-30' : ''}`} />
          {['sms', 'away'].includes(notifications) &&
            <div>
              <FontAwesomeIcon icon="fa-solid fa-circle-minus" className="text-2xl sm:text-3xl absolute right-0 left-0 top-0 bottom-0 text-center m-auto"/>
            </div>
          }
        </div>
      </div>
    </Fragment>
  )
}