import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'

export default class SettingsNavbar extends Component {
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    const { user, business, permissions, path } = this.props
    return (
      <div>
        <div className="relative filters_box  w-full md:hidden mb-1">
          <Menu as="div" className="relative block text-left">
            <div className='mb-3'>
              <Menu.Button className="inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-none focus:ring-offset-2 hover:bg-gray-50 focus:ring-offset-gray-100 focus:ring-indigo-500">
                <p className='text-gray-900'>{['users', 'locations', 'skills', 'classes', 'skills', 'regions', 'permissions',].includes(path) ? this.capitalizeFirstLetter(path) : path === 'permissionsForm' ? 'Permissions' : path === 'account' && 'Account Info' }</p>
                <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-2 max-h-60 overflow-y-auto">
                  {(permissions?.business_users?.read || user.role_display === "Netgym Employee") &&
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link to={`/settings-v4/users`} className={`text-sm flex rounded-bl items-center font-medium px-8 py-1 ${path === 'users' ? ' bg-gray-200' : ''}  text-gray-900`}>Users</Link>
                        )}
                      </Menu.Item>
                    </div>
                  }
                  {(permissions?.business_locations?.read || user.role_display === "Netgym Employee") &&
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link to={`/settings-v4/locations`} className={`text-sm flex items-center font-medium px-8 py-1 ${path === 'locations' ? ' bg-gray-200' : ''}  text-gray-900`}>Locations</Link>
                        )}
                      </Menu.Item>
                    </div>
                  }
                  {(permissions?.business_categories?.read || user.role_display === "Netgym Employee") &&
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link to={`/settings-v4/skills`} className={`text-sm flex items-center font-medium px-8 py-1 ${path === 'skills' ? ' bg-gray-200' : ''}  text-gray-900`}>Skills</Link>
                        )}
                      </Menu.Item>
                    </div>
                  }
                  {(permissions?.business_classes?.read || user.role_display === "Netgym Employee") &&
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link to={`/settings-v4/classes`} className={`text-sm flex items-center font-medium px-8 py-1 ${path === 'classes' ? ' bg-gray-200' : ''}  text-gray-900`}>Classes</Link>
                        )}
                      </Menu.Item>
                    </div>
                  }
                  {(permissions?.business_regions?.read || user.role_display === "Netgym Employee") &&
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link to={`/settings-v4/regions`} className={`text-sm flex items-center font-medium px-8 py-1 ${path === 'regions' ? ' bg-gray-200' : ''}  text-gray-900`}>Regions</Link>
                        )}
                      </Menu.Item>
                    </div>
                  }
                  {(['business_admin', 'account_owner'].includes(user?.role_name) || user.role_display === "Netgym Employee") &&
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link to={`/settings-v4/permissions`} className={`text-sm flex items-center font-medium px-8 py-1 ${(path === 'permissions' || path === 'permissionsForm') ? ' bg-gray-200' : ''} text-gray-900`}>Permissions</Link>
                        )}
                      </Menu.Item>
                    </div>
                  }
                  {(['business_admin', 'account_owner'].includes(user?.role_name) || user.role_display === "Netgym Employee") &&
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                        <Link to={`/settings-v4/account`} className={`text-sm flex rounded-br items-center font-medium px-8 py-1 ${path === 'account' ? ' bg-gray-200' : ''} text-gray-900`}>Account Info</Link>
                        )}
                      </Menu.Item>
                    </div>
                  }
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      
        <div className='hidden md:inline-flex bg-white h-11 max-w-4xl w-full whitespace-nowrap overflow-x-auto justify-between shadow rounded-lg mb-5'>
          {(permissions?.business_users?.read || user.role_display === "Netgym Employee") &&
            <Link to={`/settings-v4/users`} className={`text-sm flex rounded-bl items-center border-b-2 font-medium px-8 ${path === 'users' ? 'text-gray-900 border-gray-900' : 'text-gray-300 hover:text-gray-400 border-transparent'}`}>Users</Link>
          }
          {(permissions?.business_locations?.read || user.role_display === "Netgym Employee") &&
            <Link to={`/settings-v4/locations`} className={`text-sm flex items-center border-b-2 font-medium px-8 ${path === 'locations' ? 'text-gray-900 border-gray-900' : 'hover:text-gray-400 text-gray-300 border-transparent'}`}>Locations</Link>
          }
          {(permissions?.business_categories?.read || user.role_display === "Netgym Employee") &&
            <Link to={`/settings-v4/skills`} className={`text-sm flex items-center border-b-2 font-medium px-8 ${path === 'skills' ? 'text-gray-900 border-gray-900' : 'hover:text-gray-400 text-gray-300 border-transparent'}`}>Skills</Link>
          }
          {(permissions?.business_classes?.read || user.role_display === "Netgym Employee") &&
            <Link to={`/settings-v4/classes`} className={`text-sm flex items-center border-b-2 font-medium px-8 ${path === 'classes' ? 'text-gray-900 border-gray-900' : 'hover:text-gray-400 text-gray-300 border-transparent'}`}>Classes</Link>
          }
          {(permissions?.business_regions?.read || user.role_display === "Netgym Employee") &&
            <Link to={`/settings-v4/regions`} className={`text-sm flex items-center border-b-2 font-medium px-8 ${path === 'regions' ? 'text-gray-900 border-gray-900' : 'hover:text-gray-400 text-gray-300 border-transparent'}`}>Regions</Link>
          }
          {(['business_admin', 'account_owner'].includes(user?.role_name) || user.role_display === "Netgym Employee") &&
            <Link to={`/settings-v4/permissions`} className={`text-sm flex items-center border-b-2 font-medium px-8 ${(path === 'permissions' || path === 'permissionsForm') ? 'text-gray-900 border-gray-900' : 'hover:text-gray-400 text-gray-300 border-transparent'}`}>Permissions</Link>
          }
          {(['business_admin', 'account_owner'].includes(user?.role_name) || user.role_display === "Netgym Employee") &&
            <Link to={`/settings-v4/account`} className={`text-sm flex rounded-br items-center border-b-2 font-medium px-8 ${path === 'account' ? 'text-gray-900 border-gray-900' : 'hover:text-gray-400 text-gray-300 border-transparent'}`}>Account Info</Link>
          }
        </div>
      </div>
    )
  }
}
