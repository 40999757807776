import React, { Fragment, useContext } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { CloseIcon } from "../messenger/pages/chatComponents/assets/closeIcon";
import UserContext from '../userComponents/userContext';

export default function MobileWebBanner() {
  const { showMobileWebBanner, setShowMobileWebBanner } = useContext(UserContext);

  if((showMobileWebBanner) && (isAndroid || isIOS)) {
    return (
      <Fragment>
        <div className="sticky top-0 z-10 flex lg:hidden items-center justify-between tab-gray w-full h-20 gap-2 p-2 px-3 border-gray-200 border-b">
          <div className="flex items-center gap-2">
            <button className="bg-gray-200 h-5 w-5 rounded-full flex items-center justify-center shrink-0" onClick={() => setShowMobileWebBanner(false)}>
              <CloseIcon size={"4"} />
            </button>
            <div>
              <img src="/assets/app_logo.png" className="h-16 w-16 rounded-2xl" />
            </div>
            <div className="flex flex-col gap-1">
              <div className="text-sm font-bold">Netgym</div>
              <div className="text-xs">NetGym Corp.</div>
              <div className="text-xs font-medium">FREE - {isAndroid ? 'In Google Play' : `On the App Store`}</div>
            </div>
          </div>
          <a className="font-medium custom-a-tag-blue-color" href={window._branch_io_deeplink} target="_blank">
            View
          </a>
        </div>
      </Fragment>
    )
  }else{
    return null
  }
}