import React, { Fragment } from "react";

export default function ShowPastEventsButtonComponent(props) {
  const { hidePastEvents, handleSetHidePastEvents } = props

  return(
    <Fragment>
      <button className="flex justify-center items-center rounded-2xl px-3 py-4 h-11 w-40 bg-gray-custom-50 text-sm text-gray-900 hover:bg-gray-100" onClick={() => handleSetHidePastEvents(!hidePastEvents)}>
        {`${hidePastEvents ? 'Show' : `Hide`} past requests`}
      </button>
    </Fragment>
  )
}