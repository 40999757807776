import React, { Fragment } from "react";

export default function ClassesSelectorIcon({classNames, customColour}) {

  return(
    <Fragment>
      <svg className={classNames} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.3333 4.04167V13.95C18.3333 14.7583 17.675 15.5 16.8667 15.6L16.6083 15.6333C15.2417 15.8167 13.3167 16.3833 11.7667 17.0333C11.225 17.2583 10.625 16.85 10.625 16.2583V4.66667C10.625 4.35833 10.8 4.075 11.075 3.925C12.6 3.1 14.9083 2.36667 16.475 2.23333H16.525C17.525 2.23333 18.3333 3.04167 18.3333 4.04167Z" fill={customColour ? customColour : "#737373"}/>
        <path d="M8.92487 3.925C7.39987 3.1 5.09154 2.36667 3.52487 2.23333H3.46654C2.46654 2.23333 1.6582 3.04167 1.6582 4.04167V13.95C1.6582 14.7583 2.31654 15.5 3.12487 15.6L3.3832 15.6333C4.74987 15.8167 6.67487 16.3833 8.22487 17.0333C8.76654 17.2583 9.36654 16.85 9.36654 16.2583V4.66667C9.36654 4.35 9.19987 4.075 8.92487 3.925ZM4.16654 6.45H6.04154C6.3832 6.45 6.66654 6.73333 6.66654 7.075C6.66654 7.425 6.3832 7.7 6.04154 7.7H4.16654C3.82487 7.7 3.54154 7.425 3.54154 7.075C3.54154 6.73333 3.82487 6.45 4.16654 6.45ZM6.66654 10.2H4.16654C3.82487 10.2 3.54154 9.925 3.54154 9.575C3.54154 9.23333 3.82487 8.95 4.16654 8.95H6.66654C7.0082 8.95 7.29154 9.23333 7.29154 9.575C7.29154 9.925 7.0082 10.2 6.66654 10.2Z" fill={customColour ? customColour : "#737373"}/>
      </svg>

    </Fragment>
  )
}