import React, { Fragment } from 'react';

export default function CalendarViewTabs(props){
  const { myRequestsTab, handleMyRequestsTab, sentCount, availableCount, approvedCount } = props
  return (
    <Fragment>
      <div className='flex items-center gap-3'>
        <button className={`${myRequestsTab == 'sent' ? 'bg-dark-blue text-white rounded-2xl' : 'text-gray-600'} text-base font-medium flex items-center justify-center px-4 py-2 gap-x-2 min-wc-150`} onClick={() => handleMyRequestsTab('sent')}>
          {`Sent ${sentCount > 0 ? `(${sentCount})` : ''}`}
        </button>
        <button className={`${myRequestsTab == 'available' ? 'bg-dark-blue text-white rounded-2xl' : 'text-gray-600'} text-base font-medium flex items-center justify-center px-4 py-2 gap-x-2 min-wc-150`} onClick={() => handleMyRequestsTab('available')}>
          {`Available ${availableCount > 0 ? `(${availableCount})` : ''}`}
        </button>
        <button className={`${myRequestsTab == 'approved' ? 'bg-dark-blue text-white rounded-2xl' : 'text-gray-600'} text-base font-medium flex items-center justify-center px-4 py-2 gap-x-2 min-wc-150`} onClick={() => handleMyRequestsTab('approved')}>
          {`Approved ${approvedCount > 0 ? `(${approvedCount})` : ''}`}
        </button>
      </div>
    </Fragment>
  )
}