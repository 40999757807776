import React, { Fragment, Item } from 'react'
import { Alert, Table, Modal, Button, InputGroup, FormControl, Form } from 'react-bootstrap'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { SearchIcon } from '@heroicons/react/solid'
import { ChevronDownIcon, ExclamationIcon, XIcon, DotsVerticalIcon  } from '@heroicons/react/outline'
import { getSudomain, setTitle } from '../helpers.js'
import { getFilterSkills, dragAndMoveFile, getRegionsLocations, getFilterResource, getFolerData, updateFolderName, createFolder, destroyFolder, removeExtensionFromName, snakeCase, getName } from './helpers.js'
import { debounce } from "lodash";
import Cookies from 'js-cookie';
import { Link, Redirect } from "react-router-dom";
import ModalPopup from "../../components/common/modal";
import ReactTooltip from "react-tooltip";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default class ViewFolderDetail extends React.Component {
  constructor(props){
    super();
    this.state = {
			files: [],
			selectedSkills: [],
			searchData: "",
			locations: [],
			skills: [],
      all_data: [],
			data: [],
      files_count: null,
      all_folders: [],
      folders: [],
			count: 0,
			total_data_count: null,
			loaded: false,
			show: false,
			alert_message: '',
			alert_type: '',
			view: 'card',
			pageToView: 'show',
			itemToView: {},
			regions_and_locations: [],
			selectedLocations: [],
			selectedRegions: [],
			itemIndex: null,
      folderName: null,
      path: "",
      folderPathNames: '',
      filterDataLoaded: false,
      visibleOption: false,
			anchorPoint: {x: 0, y:0},
			editFolder: false,
			editFolderName: '',
			redirect: false,
			editingValue: '',
      showDeleteFolderModal: false,
      drafts: [],
      showUpload: false,
      parentFolderName: "",
      openModal: false,
			elementIdUsedForDrop: null,
			elementIdUsedForDrag: null,
			enableDrop: false,
      dragStart: false,
      sortType: 'name',
			sortReverse: false,
			sortDataLoaded: true,
      draftSortType: 'name',
			draftSortReverse: false,
      data1: [],
      data2: [],
      draft1: [],
      draft2: [],
    }
  }

  componentDidMount = () => {
    this.checkAvailability()
		this.props.setCurrent('Resource Hub')
    if(this.props.pageLoaded){
      this.getfolderName()
      this.getAll()
    }
    setTitle('resource_hub')
    document.addEventListener('click', this.handleContextItemsCloseByBody);
    this.scrollToTop()
  }

  scrollToTop = () => {
    $(document).scrollTop(0,0);
  }

  checkAvailability = () => { 
    const { business, user } = this.props   
    if (Object.keys(business).length != 0) {
      if(business?.resource_hub === false){
        let role = snakeCase(user?.role)        
        if(role){
          let url = `/business_user#/${getName(role)}_home`
          window.location.href = url
        }        
      }
    }
  }

  getfolderName = () => {
    let folderPath = location.pathname?.split('/')
    let data = []
    for(let i = 0; i < folderPath?.length-1; i++){
      data.push(folderPath[i])
    }
    let path = data.join('/')
    let folderPathName = []
    for(let i = 0; i < folderPath?.length; i++){
      if(i > 2){
        folderPathName.push(folderPath[i])
      }
    }
    let folderName = folderPath[folderPath?.length-1]
    let all_folder_path = folderPathName?.join('/')
    this.setState({
      folderPathNames: folderPathName,
      folderName: folderName,
      path: path,
      loaded: false
    })

    getFilterResource(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), '', [], [], all_folder_path, 'name', false, 'name', false).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
        let totalData = result.link
        let halfData1 = []
        let halfData2 = []
        if(totalData?.length%2 == 0){
          halfData1 = totalData?.slice(0, totalData?.length/2)
          halfData2 = totalData?.slice(totalData?.length/2)
        }else{
          halfData1 = totalData?.slice(0, (totalData?.length/2) +1)
          halfData2 = totalData?.slice((totalData?.length/2) + 1)
        }

        let totalDrafts = result.drafts
        let halfDraft1 = []
        let halfDradt2 = []
        if(totalDrafts?.length%2 == 0){
          halfDraft1 = totalDrafts?.slice(0, totalDrafts?.length/2)
          halfDradt2 = totalDrafts?.slice(totalDrafts?.length/2)
        }else{
          halfDraft1 = totalDrafts?.slice(0, (totalDrafts?.length/2) +1)
          halfDradt2 = totalDrafts?.slice((totalDrafts?.length/2) + 1)
        }
				this.setState({
					count: result.count,
					data: result.link,
          data1: halfData1,
          data2: halfData2,
          draft1: halfDraft1,
          draft2: halfDradt2,
          files_count: result.files_count,
          folders: result.folders,
          all_folders: result.folders,
          all_data: result.link,
					total_data_count: result.total,
					loaded: true,
          filterDataLoaded: true,
          drafts: result.drafts,
          showUpload: result.show_upload
				})
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
					loaded: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
    
  }

  getAll = () => {
		// showFile(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
		// 	response => response.json()
		// )
		// .then(result => {
		// 	if(result.status === 200){
		// 		this.setState({
		// 			count: result.count,
		// 			data: result.link,
		// 			total_data_count: result.total,
		// 			loaded: true
		// 		})
		// 	}else{
		// 		this.setState({
		// 			alert_message: result.error,
		// 			alert_type: 'danger', 
		// 			show: true,           
		// 			loaded: true
		// 		})
		// 		this.setState({show:true},()=>{
    //       window.setTimeout(()=>{
    //         this.setState({show:false})
    //       },3000)
    //     });
		// 	}
		// })

		getFilterSkills(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
					locations: result.locations,
					skills: result.skills,
					loaded: true
				})
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
					loaded: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})

		getRegionsLocations(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
					regions_and_locations: result.regions_and_locations,
					loaded: true
				})
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
					loaded: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
	}

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
    document.addEventListener('click', this.handleContextItemsCloseByBody);
  }

  performFilterResource = (search, locations, skills, sortType, sortReverse, draftSortType, draftSortReverse) => {
    this.setState({filterDataLoaded: false})
		this.getPerformFilterResource(search, locations, skills, sortType, sortReverse, draftSortType, draftSortReverse)
	}

  getPerformFilterResource = (search, locations, skills, sortType, sortReverse, draftSortType, draftSortReverse) => {
		getFilterResource(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), search, locations, skills, this.state.folderPathNames.join('/'), sortType, sortReverse).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
        let totalData = result.link
        let halfData1 = []
        let halfData2 = []
        if(totalData?.length%2 == 0){
          halfData1 = totalData?.slice(0, totalData?.length/2)
          halfData2 = totalData?.slice(totalData?.length/2)
        }else{
          halfData1 = totalData?.slice(0, (totalData?.length/2) +1)
          halfData2 = totalData?.slice((totalData?.length/2) + 1)
        }

        let totalDrafts = result.drafts
        let halfDraft1 = []
        let halfDradt2 = []
        if(totalDrafts?.length%2 == 0){
          halfDraft1 = totalDrafts?.slice(0, totalDrafts?.length/2)
          halfDradt2 = totalDrafts?.slice(totalDrafts?.length/2)
        }else{
          halfDraft1 = totalDrafts?.slice(0, (totalDrafts?.length/2) +1)
          halfDradt2 = totalDrafts?.slice((totalDrafts?.length/2) + 1)
        }
				this.setState({
					count: result.count,
					data: result.link,
          data1: halfData1,
          data2: halfData2,
          draft1: halfDraft1,
          draft2: halfDradt2,
          files_count: result.files_count,
          folders: result.folders,
          all_folders: result.folders,
          all_data: result.link,
					total_data_count: result.total,
					filterDataLoaded: true,
          drafts: result.drafts,
          sortDataLoaded: true
				})
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
					loaded: true,
          sortDataLoaded: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
	}

  changeView = (type) => {
		this.setState({view: type})
	}

  handleSearch = (e) => {
		this.setState({
			searchData: e.target.value
		})
		
		this.performSearch(e.target.value)
	}

  performSearch = debounce(val => {
		this.performFilterResource(val, this.state.selectedLocations, this.state.selectedSkills, this.state.sortType, this.state.sortReverse, this.state.draftSortType, this.state.draftSortReverse)
	}, 800);

  search = (val) => {
    let searchTerm = val.trim()
		let all_data = this.state.all_data
		let all_folders = this.state.all_folders
		if(all_data?.length > 0){
			if(searchTerm === ''){
				this.setState({ 
					data: all_data,
					count: all_data?.length,
					folders: all_folders,
          loaded: true
				})
			}else{
				var foundData = all_data.filter(function (entry) {
					return (removeExtensionFromName(entry.name).toLowerCase().includes(searchTerm.toLowerCase()));
				});
				this.setState({
					data: foundData,
					count: foundData?.length,
					folders: all_folders,
          loaded: true
				})
			}
		}
		else {
			if(searchTerm === ''){
				this.setState({ 
					folders: all_folders,
          loaded: true
				})
			}else{
				var foundData = all_folders.filter(function (entry) {
					return (entry.name.toLowerCase().includes(searchTerm.toLowerCase()));
				});
				this.setState({
					folders: foundData,
          loaded: true
				})
			}
		}
  }

	handleResetFilter = (e) => {
		this.setState({
			selectedSkills: "",
			searchData: "",
			selectedLocations: [], 
			selectedRegions: [],
		})
		this.performFilterResource([], [], [], this.state.sortType, this.state.sortReverse, this.state.draftSortType, this.state.draftSortReverse)
    //this.search('')
	}

  handleSelectSkills = (e) => {
		let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedSkills.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newSkills = [...this.state.selectedSkills, val]
        this.setState(state => ({ selectedSkills: newSkills }));
				this.performFilterResource(this.state.searchData, this.state.selectedLocations, newSkills, this.state.sortType, this.state.sortReverse, this.state.draftSortType, this.state.draftSortReverse)
      }
    }else{
      if(__FOUND){
        const { selectedSkills } = this.state;
        let newSkills = selectedSkills.filter((tag, index) => tag !== val)  
        this.setState({
          selectedSkills: newSkills        
        }); 
				this.performFilterResource(this.state.searchData, this.state.selectedLocations, newSkills, this.state.sortType, this.state.sortReverse, this.state.draftSortType, this.state.draftSortReverse)
      }      
    }
	}

  chooseIconName = (type) => {
		let checkType = type?.split('/')[0]
		switch(checkType){
			case 'document' :
				let checkExtention = type?.split('/')[1]
				switch(checkExtention){
					case 'docx' :
						return ('docx')
					case 'pdf' :
						return ('docx')
					case 'ppt' :
						return ('ppt')
					case 'pptx' :
						return ('ppt')
					case 'xcl' :
						return ('xcl')
					case 'csv' :
						return ('xcl')
					default:
						return ('docx')
				}
			case 'image':
				return('img')
			case 'video':
				return('video')
			case 'audio':
				return('aiff')
			default:
				return('')
		}
	}

	chooseIcon = (type) => {
		let checkType = type?.split('/')[0]
		switch(checkType){
			case 'document' :
				let checkExtention = type?.split('/')[1]
				switch(checkExtention){
					case 'docx' :
						return (
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
							</svg>
						)
					case 'doc' :
						return (
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
							</svg>
						)
					case 'pdf' :
						return (
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
							</svg>
						)
					case 'ppt' :
						return(
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clipRule="evenodd" />
							</svg>
						)
					case 'pptx' :
						return(
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clipRule="evenodd" />
							</svg>
						)
					case 'xls' :
						return(
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clipRule="evenodd" />
							</svg>
						)
					case 'xlsx' :
						return(
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clipRule="evenodd" />
							</svg>
						)
					case 'csv' :
						return(
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z" clipRule="evenodd" />
							</svg>
						)
					default:
						return (
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
							</svg>
						)
				}
				
			case 'aiff':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z" />
					</svg>
				)
			case 'image':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
					</svg>
				)
			case 'video':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
					</svg>
				)
			case 'audio':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z" />
					</svg>
				)
			case 'ppt':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clipRule="evenodd" />
					</svg>
				)
			case 'xcl':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path fillRule="evenodd" d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z" clipRule="evenodd" />
					</svg>
				)
			default:
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
					</svg>
				)
		}
	}

	handlePageToview = (type, item, index) => {
		this.setState({
			pageToView: type,
			itemToView: item,
			itemIndex: index
		})
	}

	countNameCharectors = (name) => {
		let shortName = []
		for(let i = 0; i < name?.length; i++){
			if(i < 50){
				shortName.push(name[i])
			}else{
				shortName.push('...')
				break
			}
		}
	
		return shortName.join('')
	}

	checkSelectedSkillsName = () => {
		let skills = this.state.skills
		let selectedSkills = this.state.selectedSkills
		let skillName = []
		let ctr = 0
		for(let i = 0; i < skills?.length; i++){
			if(selectedSkills.includes(skills[i].id)){
				if(ctr < 2){
					skillName.push(skills[i].name)
					ctr++
				}else{
					break;
				}
			}
		}
	
		return skillName.join(', ')
	}

	selectAllSkills = () => {
    let check = this.state.skills?.length === this.state.selectedSkills?.length
    return check
  }

	handleSelectAllSkills = (e) => {
		if(e.target.checked){
      let allSkills = this.state.skills
      let selected_skills = []
      for(var i=0; i<allSkills.length;i++){ 
				selected_skills.push(allSkills[i]['id'])    
      }
      this.setState({
        selectedSkills: selected_skills,
      })
			this.performFilterResource(this.state.searchData, this.state.selectedLocations, selected_skills, this.state.sortType, this.state.sortReverse, this.state.draftSortType, this.state.draftSortReverse)
    }else{
      this.setState({
        selectedSkills: []
      })
			this.performFilterResource(this.state.searchData, this.state.selectedLocations, [], this.state.sortType, this.state.sortReverse, this.state.draftSortType, this.state.draftSortReverse)
    }
	}

	selectedAllRegLoc = () => {
    let check = this.state.selectedRegions?.length === this.state.regions_and_locations?.length
    return check
  }

	selectedAllRegionsLocations = (e) => {
    if(e.target.checked){
      let all = this.state.regions_and_locations
      let selected_regions = []
      let selected_locations = []
      for(var i=0; i<all.length;i++){     
        selected_regions.push(all[i]['region']['name'])
        for(var j=0; j<all[i]['locations'].length; j++){
          selected_locations.push(all[i]['locations'][j]['id'])
        }
      }
      this.setState({
        selectedLocations: selected_locations,
        selectedRegions: selected_regions
      })
			this.performFilterResource(this.state.searchData, selected_locations, this.state.selectedSkills, this.state.sortType, this.state.sortReverse, this.state.draftSortType, this.state.draftSortReverse)
    }else{
      this.setState({
        selectedRegions: [],
        selectedLocations: []
      })
			this.performFilterResource(this.state.searchData, [], this.state.selectedSkills, this.state.sortType, this.state.sortReverse, this.state.draftSortType, this.state.draftSortReverse)
    }
  }

  selectRegions = (e, region) => {
    let val = e.target.value
    let regions = this.state.selectedRegions
   
    var __FOUND = regions.indexOf(val) > -1;
    var result = region.locations.map(function(a) {return a.id;});
    let selected_locations = this.state.selectedLocations;
    
    if(e.target.checked){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }));
        let final_result = []
        for(let i = 0; i < result?.length; i++){
          if(!selected_locations.includes(result[i])){
            final_result.push(result[i])
          }
        }
        selected_locations = Array.prototype.push.apply(selected_locations, final_result)
      }
			this.performFilterResource(this.state.searchData, this.state.selectedLocations, this.state.selectedSkills, this.state.sortType, this.state.sortReverse, this.state.draftSortType, this.state.draftSortReverse)
    }else{
      if(__FOUND){
        
        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)          
        });
        selected_locations = selected_locations.filter(item => !result.includes(item));
        this.setState({
          selectedLocations: selected_locations
        })
				this.performFilterResource(this.state.searchData, selected_locations, this.state.selectedSkills, this.state.sortType, this.state.sortReverse, this.state.draftSortType, this.state.draftSortReverse)
      }
    }
  }

  selectLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedLocations.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newlocs = [...this.state.selectedLocations, val]
        this.setState(state => ({ selectedLocations: newlocs }));
        this.checkRegionVisibility(region, newlocs)
      }
    }else{
      if(__FOUND){
        const { selectedLocations } = this.state;
        let newLocs = selectedLocations.filter((tag, index) => tag !== val)  
        this.setState({
          selectedLocations: newLocs        
        });
        this.checkRegionVisibility(region, newLocs)  
      }      
    }
  }

  checkRegionVisibility = (region, locations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(locations, location_ids)
    let val = region.region?.name

    let regions = this.state.selectedRegions
    var __FOUND = regions.indexOf(val) > -1;
    if(equal){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }))
      }
    }else{
      if(__FOUND){
        
        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)          
        });
      }
    }
		this.performFilterResource(this.state.searchData, locations, this.state.selectedSkills, this.state.sortType, this.state.sortReverse, this.state.draftSortType, this.state.draftSortReverse)
  }

	locationName = () => {
    let locations = this.state.locations
    let selected_locations = this.state.selectedLocations
    let locationName = []
    let ctr = 0
    for(let i = 0; i < locations?.length; i++){
      if(selected_locations.includes(locations[i].id)){
        if(ctr < 2){
          locationName.push(locations[i].name)
          ctr++
        }
        else{
          break;
        }
      }
    }
    return locationName.join(", ")
  }

  checkPrevUrl = (index) => {
    let folderPathName = this.state.folderPathNames
    let path = []
    for(let i = 0; i < folderPathName?.length; i++){
      if(i <= index){
        path.push(folderPathName[i])
      }
    }
    return path.join('/')
  }

  countBreadCrumbsNameCharectors = (name) => {
		let shortName = []
		for(let i = 0; i < name?.length; i++){
			if(i < 36){
				shortName.push(name[i])
			}else{
				shortName.push('...')
				break
			}
		}
		return shortName.join('').replace(/%20|%2|%/gi, ' ')
	}

  showDocumentImage = (type) => {
		let path = ''
		if(type?.split('/')[0] === 'document'){
			if(type?.split('/')[1] === 'pdf'){
				path = '/assets/pdf.png'
			}
			else if (type?.split('/')[1] === 'docx' || type?.split('/')[1] === 'doc'){
				path = '/assets/docx-file.png'
			}
			else if (type?.split('/')[1] === 'pptx' || type?.split('/')[1] === 'ppt'){
				path = '/assets/powerpoint.png'
			}
			else if (type?.split('/')[1] === 'xlsx' || type?.split('/')[1] === 'xls') {
				path ='/assets/xls.png'
			}
      else if (type?.split('/')[1] === 'csv' || type?.split('/')[1] === 'csv') {
				path ='/assets/csv.png'
			}
			else{
				path = '/assets/img_default_files.png'
			}
		}
		else{
			path = '/assets/img_default_files.png'
		}
		return path
	}

  handleContextItems = (e, folderName) => {
    if(this.state.showUpload){
			e.preventDefault();
      this.setState({
        anchorPoint: { x: e.pageX, y: e.pageY },
        visibleOption: true,
        editFolderName: folderName
      })
    }
	}

	handleContextItemsCloseByBody = (e, folderName) => {
		const { visibleOption } = this.state;
		if (visibleOption){
			e.preventDefault();
			this.setState({
				anchorPoint: { x: 0, y: 0 },
				visibleOption: false,
			})
		}
	}

	handleContextItemsClose = (e, folderName) => {
		const { visibleOption } = this.state;
		if (visibleOption){
			e.preventDefault();
			this.setState({
				anchorPoint: { x: 0, y: 0 },
				visibleOption: false,
				// editFolderName: '',
				// editFolder: false
			})
		}else if(folderName !== '' && this.state.editFolder === false){
			this.setState({
				redirect: true,
				editFolderName: folderName
			})
		}
	}
  
	setEditFolder = (e, name) => {
		e.preventDefault();
		this.setState({editFolderName: name}, () => {
			this.setState({
				editFolder: true,
				redirect: false,
				editingValue: this.state.editFolderName.length <= 20 ? this.state.editFolderName : this.state.editFolderName.slice(0,20)
			})
		})
	}


	onEditFolderName = (e) => {
		let folderName = this.state.editFolderName
    if (folderName === "") {
			this.setState({
				editingValue: folderName,
				editFolder: false
			})
      // setEditingValue(value);
    } else {
			this.setState({
				editingValue: e.target.value,
				editFolder: false,
				filterDataLoaded: false
			})
			this.updateName(this.state.editingValue, folderName)
      // setValue(e.target.value);
    }
  }	

	onEditFolderNameCancel = (e) => {
		this.setState({editFolder: false, editingValue: ""})
  }		

	onChange = (e) => {
    this.setState({
      editingValue: e.target.value.length <= 20 ? e.target.value : e.target.value.slice(0,20)
    })
		// setEditingValue(e.target.value);
	}

	onKeyDown = (e) => {
    if (e.key === "Enter" || e.key === "Escape") {
      e.target.blur();
    }
  }

	onBlur = (e) => {
		let folderName = this.state.editFolderName
    if (e.target.value.trim() === "") {
			this.setState({
				editingValue: folderName,
				editFolder: false
			})
      // setEditingValue(value);
    } else {
			this.setState({
				editingValue: e.target.value,
				editFolder: false,
				filterDataLoaded: false
			})
			this.updateName(e.target.value, folderName)
      // setValue(e.target.value);
    }
  }

	updateName = (folderName, folder) => {
    let all_folders = this.state.folderPathNames?.join('/')+'/'+folder
		updateFolderName(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), folderName, all_folders).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
					alert_message: result.error,
					alert_type: 'success', 
					show: true,
					filterDataLoaded: true
				})
				this.getfolderName()
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
					filterDataLoaded: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
	}

  createFolderNameHandler = (state, folderPath) => {
    this.setState({openModal: state, parentFolderName: folderPath })
  }


  popStateHandler = (popState) => {
    this.setState({openModal: popState});
  }

  folderNameStateHandler = (folderName) => {
		this.createFolderName(folderName);
  }	

  createFolderName = (folder) => {
		this.setState({filterDataLoaded: false})
		createFolder(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.parentFolderName + "&newFolderName="+folder).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
					alert_message: result.error,
					alert_type: 'success', 
					show: true,
					filterDataLoaded: true
				})
				this.getfolderName()
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
					filterDataLoaded: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
	}

	setDeleteFolder = (e, folderName) => {
		e.preventDefault();
		this.setState({
			showDeleteFolderModal: true,
			redirect: false,
			editFolderName: folderName
		})
	}  

	closeDeleteModal = (e) => {
		this.setState({
			showDeleteFolderModal: false,
			redirect: false,
			editFolder: false,
		},()=>{
			window.setTimeout(()=>{
				this.setState({editFolderName: '', editingValue: ''})
			},1000)
		})
	}

  processDeleteFolder = (e) => {
    this.setState({filterDataLoaded: false})
    this.closeDeleteModal()
		let folderName = this.state.editFolderName
		let folder = this.state.folderPathNames?.join('/')+'/'+folderName
		destroyFolder(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), folderName, folder).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
					alert_message: result.error,
					alert_type: 'success', 
					show: true,
					filterDataLoaded: true
				})
				this.getfolderName()
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
					filterDataLoaded: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
	}


	// when file is dragged first
	dragStart = (e, item_id) => {
		this.setState({ elementIdUsedForDrag: item_id, dragStart: true });
		e.dataTransfer.setData("file_id", e.target.id);
	};

	// when a dragged file enters a folder div
	handleDragEnter = (e) => {
		e.preventDefault()
		this.setState({elementIdUsedForDrop: e.target.id, enableDrop: true})
	}

	// when leaving a folder, disable css effects on the folder
	handleDragLeave = (e) => {
		e.preventDefault()
		this.setState({enableDrop: false})
	}
	
	handleDragOver = (e) => {
		e.preventDefault()
		this.setState({enableDrop: true, elementIdUsedForDrop: e.target.id})	
	}

	// when file is dropped
	drop = (e, folder_path) => {
		let file_to_move = e.dataTransfer.getData("file_id");
		this.callMoveToFolderApi(e, file_to_move, this.state.elementIdUsedForDrop, folder_path)
	};	

	// when dragged file is left/dropped
	handleDragEnd = (e, folder) => {
		this.setState({enableDrop: false, elementIdUsedForDrop: null})
	}

	callMoveToFolderApi = debounce((e, file_id, destination_folder_name, folder_path) => {
		dragAndMoveFile(e, Cookies.get(`currentUser.${getSudomain()}`),file_id,destination_folder_name, folder_path).then(      
      response => response.json()
      .then(result => {
        if(result.status === 200){
          this.setState({
            show: true,
            alert_message: result.message,
            alert_type: 'success',            
            loaded: true,
						elementIdUsedForDrop: null,
						itemFileData: {}
          })
        }else{
          this.setState({
            alert_message: result?.error ? result?.error : "File moving failed.Try again",
            alert_type: 'danger', 
						show: true,           
            loaded: true
          })
        }
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
						// window.location.reload(true);
            this.performFilterResource(this.state.searchData, this.state.selectedLocations, this.state.selectedSkills, this.state.sortType, this.state.sortReverse, this.state.draftSortType, this.state.draftSortReverse)
          },3000)
        });
      })
    )
	}, 800);	

  handleScrollUp = (e) => {
		let scrollVal = document.documentElement.scrollTop
		if (scrollVal !== 0){
			window.scrollTo({
				top: parseInt(scrollVal - 20),
				behavior: 'smooth'
			});
		}
	}

  sort = (e, param, type) => {
    e.preventDefault()
    let sort_desc = false
    if(type === 'asc'){
      sort_desc = false
    }else{
      let sortRev =  this.state.sortReverse
      if(sortRev === true){
        sort_desc = false
      }
      else{
        sort_desc = true
      }
    }

    this.setState({
      sortType: param,
      sortReverse: sort_desc
    }, () => {
      this.performSortResource(this.state.searchData, this.state.selectedLocations, this.state.selectedSkills, param, sort_desc, this.state.draftSortType, this.state.draftSortReverse)
    });
  }

  performSortResource = (search, locations, skills, sortType, sortReverse, draftSortType, draftSortReverse) => {
		this.setState({sortDataLoaded: false})
    this.getPerformFilterResource(search, locations, skills, sortType, sortReverse, draftSortType, draftSortReverse)
  }

  sortDraft = (e, param, type) => {
		e.preventDefault()
    let sort_desc = false
    if(type === 'asc'){
      sort_desc = false
    }else{
      let sortRev =  this.state.draftSortReverse
      if(sortRev === true){
        sort_desc = false
      }
      else{
        sort_desc = true
      }
    }

    this.setState({
      draftSortType: param,
      draftSortReverse: sort_desc
    }, () => {
      this.performSortResource(this.state.searchData, this.state.selectedLocations, this.state.selectedSkills, this.state.sortType, this.state.sortReverse, param, sort_desc)
    });
	}

  render(){
    const { files, selectedSkills, searchData, locations, skills, files_count, folders, data, count, loaded, show, alert_message, alert_type, view, pageToView, itemToView, regions_and_locations, selectedLocations, selectedRegions, total_data_count, itemIndex, folderName, path, folderPathNames, filterDataLoaded, visibleOption, anchorPoint, editFolder, editFolderName, editingValue, redirect, showDeleteFolderModal, drafts, showUpload, openModal, elementIdUsedForDrop, enableDrop, dragStart, sortType, sortReverse, sortDataLoaded, draftSortType, draftSortReverse, data1, data2, draft1, draft2 } = this.state
    const { user, permissions, loadingMessage } = this.props
    return(
      <React.Fragment>
        {(redirect && editFolderName) &&
					<Redirect push to={`${location.pathname}/${editFolderName}`} />
				}
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
        {dragStart &&
					<div className="w-4/5 flex items-center bg-opacity-100 rounded-md px-4 py-14 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto" onDragOver={e => this.handleScrollUp(e)}>
					</div>
				}

        <Transition.Root show={showDeleteFolderModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeDeleteModal(e)}>
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
									<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
											onClick={(e) => this.closeDeleteModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
										</div>
										<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
											<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
												Delete Folder
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-sm text-gray-500">
													Are you sure you want to delete folder : {editFolderName} ?
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
										<button
											type="button"
											className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
											onClick={(e) => this.processDeleteFolder(e)}
										>
											Delete
										</button>
										<button
											type="button"
											className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
											onClick={(e) => this.closeDeleteModal(e)}
										>
											Cancel
										</button>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>

        <div className="w-full min-h-screen bg-gray-50">
            {!loaded &&
              <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                <p className="mb-1 text-center px-5">{loadingMessage}</p>
                <img className = "w-9" src="/assets/business/loading.gif" />
              </div>
            }
            {loaded &&
            <div className="pt-20 md:pt-8 h-full">
              <header>
                <div className="max-w-8xl mx-auto px-6 lg:px-8">
                  <div className="flex items-center">
                    <div className="t-breadcrumbs">
                      <nav className="flex" aria-label="Breadcrumb">
                        <ol role="list" className="flex items-center flex-wrap">
                          <li>
                            <div className="flex items-center">
                              <Link to = "/resource_hub" className="md:text-xl text-sm font-medium text-gray-500 hover:text-gray-700">Resource hub</Link>
                            </div>
                          </li>
                          {folderPathNames && folderPathNames.map((name, i) => 
                            <React.Fragment key ={i} >
                              {(i !== folderPathNames?.length-1 && folderPathNames?.length > 1) &&
                                <li>
                                  <div className="flex items-center">
                                    <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                      <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <Link to = {`/resource_hub/folder/${this.checkPrevUrl(i)}`} className="md:text-xl text-sm font-medium text-gray-500 hover:text-gray-700" aria-current="page">{this.countBreadCrumbsNameCharectors(name)}</Link>
                                  </div>
                                </li>
                              }
                              {i === folderPathNames?.length-1 &&
                              <li>
                                <div className="flex items-center">
                                  <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                  </svg>
                                  <a className="md:text-xl text-sm font-medium text-gray-700 hover:text-gray-700" aria-current="page">{this.countBreadCrumbsNameCharectors(name)}</a>
                                </div>
                              </li>
                              }
                            </React.Fragment>
                          )}
                        </ol>
                      </nav>
                    </div>
                    {(count > 0 || drafts?.length > 0) &&
                      <div className="bg-gray-100 rounded ml-auto py-1 px-2.5">
                        {view === 'card' &&
                          <div className="for-card-view flex items-center gap-3.5">
                            <div className="bg-white p-2 rounded-md">
                              <a>
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                  <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                                </svg>
                              </a>
                            </div>
                            <a className="p-2 cursor-pointer" onClick={() => this.changeView('table')}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-900 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                              </svg>
                            </a>
                          </div>
                        }
                        {view === 'table' &&
                          <div className="for-table-view flex items-center gap-3.5">
                            <a className="p-2 cursor-pointer" onClick={() => this.changeView('card')}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                              </svg>
                            </a>
                            <div className="bg-white p-2 rounded-md">
                              <a>
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-900 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                </svg>
                              </a>
                            </div>
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
              </header>
              
              <main className="h-full">
                <div className="max-w-8xl mx-auto px-6 lg:px-8 h-full">
                  
                  {total_data_count > 0 &&
                    <div>
                      <div className="grid items-center t-filter">
                        <div className="w-full pt-5 px-0 flex flex-wrap">
                          <div className="w-full flex items-center gap-2 flex-wrap">
                            <div className="w-full lg:w-72 relative bg-gray-300 rounded-md">
                              <div className="w-full lg:w-72 bg-white border border-gray-300 shadow-sm rounded-md h-9 flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4 ml-3.5" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                </svg>
                                <input 
                                type="text" 
                                className="text-sm w-full border-0 h-9 ml-1.5 focus:outline-none bg-transparent" 
                                placeholder="Search for a resource"
                                value={searchData}
                                onChange={(e) => this.handleSearch(e)}
                                />
                              </div>
                            </div>
                            {showUpload &&
                              <Menu as="div" className=" md:visible invisible relative lg:ml-auto lg:mx-0 md:mx-auto lg:mt-0 md:mt-3">
                              <div>
                                <Menu.Button className="bg-dark-blue text-white flex items-center rounded-md font-sm ml-auto px-3 h-9">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                                  </svg>
                                  Add file(s) or folder
                                  
                                </Menu.Button>
                              </div>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="origin-top-right z-2 absolute right-0 mt-2 w-56 rounded-md shadow-sm bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      
                                      <Menu.Item>
                                        {({ active }) => (
                                          
                                          <a
                                            // onClick = {(e) => this.createFolderName(folderPathNames?.join('/'))}
                                            onClick = {(e) => this.createFolderNameHandler(true, folderPathNames?.join('/'))}
                                            className={classNames(
                                              active ? 'font-semibold text-gray-900' : 'text-gray-700',
                                              'px-4 py-2 text-sm flex items-center cursor-pointer'
                                            )}
                                          >
                                            <div className="bg-gray-100 w-8 h-8 flex items-center justify-center rounded mr-2">
                                              <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                                              </svg>
                                            </div>
                                            New Folder
                                          </a>
                                        )}
                                      </Menu.Item>
                                      
                                        <Menu.Item>
                                          {({ active }) => (
                                            
                                            <Link to = {`${location.pathname}/add-file`}
                                              className={classNames(
                                                active ? 'font-semibold text-gray-900' : 'text-gray-700',
                                                'block w-full text-left px-4 py-2 text-sm flex items-center'
                                              )}
                                            >
                                              <div className="bg-gray-100 w-8 h-8 flex items-center justify-center rounded mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                                                </svg>
                                              </div>
                                              <div className="hover:font-semibold">Add File (s)</div>
                                            </Link>
                                            
                                          )}
                                        </Menu.Item>
                                      
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            }
                          </div>
        
                        </div>
                      </div>
                      {showUpload &&
                        <Menu as="div" className="md:invisible visible bg-dark-blue w-14 h-14 flex fixed z-10 right-24 bottom-5 items-center justify-center rounded-md sm:block lg:hidden">
                          <div>
                            <Menu.Button className="bg-dark-blue text-white flex items-center rounded-md font-sm mx-auto px-3 h-14">
                              <svg xmlns="http://www.w3.org/2000/svg" className="text-white h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                              </svg>
                              
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="origin-top-right z-2 absolute -top-32 -right-20 mt-2 w-56 rounded-md shadow-sm bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                
                                <Menu.Item>
                                  {({ active }) => (
                                    
                                    <a
                                    onClick = {(e) => this.createFolderName(folderPathNames?.join('/'))}
                                      className={classNames(
                                        active ? 'font-semibold text-gray-900' : 'text-gray-700',
                                        'px-4 py-2 text-sm flex items-center cursor-pointer'
                                      )}
                                    >
                                      <div className="bg-gray-100 w-8 h-8 flex items-center justify-center rounded mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                          <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                                        </svg>
                                      </div>
                                      New Folder
                                    </a>
                                  )}
                                </Menu.Item>
                                
                                  <Menu.Item>
                                    {({ active }) => (
                                      
                                      <Link to = {`${location.pathname}/add-file`}
                                        type="submit"
                                        className={classNames(
                                          active ? 'font-semibold text-gray-900' : 'text-gray-700',
                                          'block w-full text-left px-4 py-2 text-sm flex items-center'
                                        )}
                                      >
                                        <div className="bg-gray-100 w-8 h-8 flex items-center justify-center rounded mr-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                                          </svg>
                                        </div>
                                        <div className="hover:font-semibold">Add File (s)</div>
                                      </Link>
                                      
                                    )}
                                  </Menu.Item>
                                
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      }
                      {filterDataLoaded &&
                        <React.Fragment>
                          {folders?.length > 0 &&
                            <div className="t-folder">
                              <div className="mt-5 flex items-center">
                                <div className="text-gray-900 font-normal text-base">Folders</div>
                                {/* <a href="#" className="text-gray-400 text-xs flex items-center ml-auto">
                                  See all
                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-900 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                  </svg>
                                </a> */}
                              </div>
                              <div className={`mt-3 grid grid-cols-1 gap-y-3 sm:grid-cols-2 lg:grid-cols-2 gap-x-4 xl:gap-x-4`}>
																{folders.map((folder, i) =>
																	<div className={`bg-white rounded-md py-3 pl-4 pr-3 flex items-center justify-between ${Boolean(elementIdUsedForDrop === folder?.name && enableDrop) ? 'bg-gray-200 border border-1 border-gray-800' : ''}`} key = {i}>
																		<div className={`flex items-center cursor-pointer w-full cursor-pointer`} key={i} onClick={(e) => this.handleContextItemsClose(e, folder?.name)} onContextMenu={(e) => this.handleContextItems(e, folder.name)} 
																			onDragEnter={(e) => this.handleDragEnter(e)} onDragLeave={(e) => this.handleDragLeave(e)} onDragOver={e => this.handleDragOver(e)} onDrop={(e) => this.drop(e, folder?.folder_path)} id={folder?.name}>
																			<div className = "h-9 w-9">
																				<svg xmlns="http://www.w3.org/2000/svg" className="text-gray-900 h-9 w-9" viewBox="0 0 20 20" fill="currentColor">
																						<path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
																				</svg>
																			</div>
																			<div className="pl-4">
																				{(editFolder && editFolderName) ? ((editFolderName === folder?.name) ? 
																				    <div>
                                              <div className='flex gap-2 items-center'>
																							  <input className = {`${editFolder ? 'max-w-lg block border border-2 bg-gray-100 text-sm p-1 shadow-sm border rounded-md' : ''}`}
																									type="text"
																									aria-label="Field name"
																									value={editingValue}
																									onChange={(e) => this.onChange(e)}
																									// onKeyDown={(e) => this.onKeyDown(e)}
																									// onBlur={(e) => this.onBlur(e)}
                                                  maxLength={20}
																								/>
                                                {(editingValue !== '' && !editingValue.match(/^[0-9A-Za-z\s]*$/)) &&
                                                  <React.Fragment>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-red-600" data-tip data-for={`status_${i}`}>
                                                      <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                                                    </svg>
                                                    <ReactTooltip className='max-w-xs text-center' id={`status_${i}`} place="top" effect="solid">
                                                      <p className=''>Special characters are not allowed in folder name.</p>
                                                    </ReactTooltip>
                                                  </React.Fragment>
                                                }
                                              </div>
                                              <div className='flex items-center justify-end'>
                                                <button type="button" onClick={(e) => this.onEditFolderName(e)} className={`text-sm my-2 bg-gray-300 px-2 mr-2 flex flex-right  shadow ${editingValue == "" ? 'cursor-not-allowed' : 'cursor-pointer'}`} disabled={editingValue == "" || (editingValue !== '' && !editingValue.match(/^[0-9A-Za-z\s]*$/))}>Save</button>
                                                <button type="button" onClick={(e) => this.onEditFolderNameCancel(e)} className="text-sm my-2 px-2 flex flex-right shadow">Cancel</button>
                                              </div>	
																						</div>
																							: <div className="text-gray-700 text-sm">{folder?.name}</div>) :
																					<div className="text-gray-700 text-sm">{folder?.name}</div>
																				}
																				<div className="text-gray-400 text-xs">Updated {folder?.updated_at}</div>
																			</div>
																		</div>
                                    {showUpload &&
                                      <Menu as="div" className="relative inline-block text-left">
                                        <div>
                                        <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                          <span className="sr-only">Open options</span>
                                          <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                        </Menu.Button>				
                                        </div>
                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                            {/* {(visibleOption && showUpload) && ( */}
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <button
                                                    type="button"
                                                    onClick={(e) => this.setEditFolder(e, folder?.name)}
                                                    className={classNames(
                                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                      'block px-4 py-2 text-sm block w-full text-left'
                                                    )}
                                                  >
                                                    Rename
                                                  </button>
                                                )}	
                                              </Menu.Item>
                                              {/* )} */}
                                              {/* {(visibleOption && showUpload) && ( */}
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <button
                                                    type="button"
                                                    onClick={(e) => this.setDeleteFolder(e, folder?.name)}
                                                    className={classNames(
                                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                      'block px-4 py-2 text-sm block w-full text-left'
                                                    )}
                                                  >
                                                    Delete
                                                  </button>
                                                )}
                                              </Menu.Item>
                                              {/* )} */}
                                            </div>
                                          </Menu.Items>
                                        </Transition>
                                      </Menu>	
                                    }
																																															
																	</div>
																)}
																{/* {(visibleOption && showUpload) &&
																	<ul
																		className="rename-menu grid gap-y-2 p-3 z-10 text-sm rounded-lg shadow"
																		style={{
																			top: anchorPoint.y,
																			left: anchorPoint.x
																		}}
																	>
																		<li> <a className='cursor-pointer' onClick={(e) => this.setEditFolder(e)}>Rename</a></li>
																		<li><a className='cursor-pointer' onClick={(e) => this.setDeleteFolder(e)}>Delete</a></li>
																	</ul>
																} */}
															</div>
                            </div>
                          }
                          {data?.length > 0 &&
                            <React.Fragment>
                              {view === 'table' &&
                                <div className="table-view">
                                  <div className="flex flex-col t-table-view">
                                    <div className="mt-4">
                                      <div className="flex items-center">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-800 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z" clipRule="evenodd" />
                                        </svg> */}
                                        <div className="text-gray-900 font-normal text-base pl-2">Files</div>
                                        <div className="bg-blue-100 text-blue-800 py-0.5 px-2.5 text-xs rounded-lg ml-2">{count}</div>
                                      </div>
                                    </div>
                                    <div className="mt-3 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                          <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                              <tr>
                                                {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                                                  </svg>
                                                </th> */}
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                  <div className='flex items-center'>
																										<span className='cursor-pointer' onClick={(e) => this.sort(e, 'name', 'desc')}>Name</span> &nbsp; &nbsp;
																										{sortType !== 'name' &&
																											<svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'name', 'desc')}>
																												<path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
																											</svg>
																										}
																										{(sortType === 'name' && sortReverse) &&
																											<svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'name', 'asc')}>
																												<path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
																											</svg>
																										}
																										{(sortType === 'name' && !sortReverse) &&
																											<svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'name', 'desc')}>
																												<path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
																											</svg>
																										}
																									</div>
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                  TAGS
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                  LAST UPDATE
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                  VIEWS
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                  VISIBILITY
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {data?.map((item, i) =>
                                                <tr className="bg-white" key={i}>
                                                  {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                                                    </svg>
                                                  </td> */}
                                                  <td className="px-6 py-4 text-sm font-medium text-gray-900">
                                                    <div className="flex items-center">
                                                      {/* <div className="mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-800 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                          <path fillRule="evenodd" d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z" clipRule="evenodd" />
                                                        </svg>
                                                      </div> */}
                                                      <Link to = {`${location.pathname}/view-resource-detail/${item.id}`} 
                                                        className="text-gray-700 text-sm font-bold cursor-pointer max-w-xs break-words" >
                                                        {removeExtensionFromName(item.name)}
                                                      </Link>
                                                    </div>
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <div className="flex flex-wrap items-center gap-x-2">
                                                      {item.location_tags?.map((location, j) =>
                                                        <div className="red-tag text-xs px-2.5 py-0.5 rounded-xl font-medium mb-2" key={j}>{location.name}</div>
                                                      )}
                                                      {item.skill_tags?.map((skill, j) =>
                                                        <div className="bg-purple-100 text-purple-800 text-xs px-2.5 py-0.5 rounded-xl font-medium mb-2" key={j}>{skill.name}</div>
                                                      )}
                                                    </div>
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <div className="text-gray-400 text-xs">
                                                      Updated {item.update_time}
                                                    </div>
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                                    <div className="text-gray-800 text-xs">
                                                      {item.views}
                                                    </div>
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                                    <div className="text-gray-800 text-xs">
                                                      {item.visitors}
                                                    </div>
                                                  </td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                              {view === 'card' &&
                                <div className="card-view">
                                  <div className="t-files">
                                    <div className="flex items-center mt-6 gap-2">
                                      <div className="text-gray-900 font-normal text-base ml-0.5">Files</div>
                                      <div className="bg-blue-100 text-blue-800 py-0.5 px-2.5 text-xs rounded-lg">{count}</div>
                                      <div className='flex items-center mt-1'>
                                        {sortType !== 'name' &&
                                          <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'name', 'desc')}>
                                            <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                          </svg>
                                        }
                                        {(sortType === 'name' && sortReverse) &&
                                          <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'name', 'asc')}>
                                            <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                          </svg>
                                        }
                                        {(sortType === 'name' && !sortReverse) &&
                                          <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'name', 'desc')}>
                                            <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                          </svg>
                                        }
                                      </div>
                                    </div>
                                    <div className="my-3 grid grid-cols-1 gap-x-4 sm:grid-cols-1 lg:grid-cols-2 xl:gap-x-4">
                                      <div className="">
                                        {data1?.map((item, i) =>
                                          <Link to = {`${location.pathname}/view-resource-detail/${item.id}`} className="group flex relative bg-white rounded-md p-2 pb-3 mb-3" key ={i} 
                                          draggable={showUpload}  onDragStart={(e) => this.dragStart(e, item.id)} id={item.id}>
                                            <div className="relative flex items-center jusfity-center rounded-md min-w-32 max-w-32 w-32 h-24 overflow-hidden flex-shrink-0">
                                              {['image', 'video', 'audio'].includes(item.file_type?.split('/')[0]) &&
                                                  <Link to = {`/resource_hub/view-resource-detail/${item.id}`} className="w-full h-full flex items-center justify-center bg-gray-300">	
                                                    <img src={Boolean(item.default_thumbnail) ? item.default_thumbnail : Boolean(item.account_default_thumbnail) ? item.account_default_thumbnail : Boolean(item.thumbnail) ? `${item.thumbnail}` : (item.file_type.split('/')[0] === 'audio' ? "/assets/img_audio.png"  : "/assets/img_default_files.png")} className="ml-auto mr-auto max-h-full object-cover w-full h-full cursor-pointer block rounded-md"/>
                                                  </Link>	
                                              }
                                              {['document'].includes(item.file_type?.split('/')[0]) &&
                                                <Link to = {`/resource_hub/view-resource-detail/${item.id}`} className="flex items-center justify-center w-full h-full bg-gray-300">	
                                                  <img src= {Boolean(item.default_thumbnail) ? item.default_thumbnail : Boolean(item.account_default_thumbnail) ? item.account_default_thumbnail : this.showDocumentImage(item.file_type)} className=" ml-auto mr-auto w-16 object-cover w-full h-full cursor-pointer block rounded-md"/>
                                                </Link>	
                                              }
                                              {!['image', 'document', 'video', 'audio'].includes(item.file_type?.split('/')[0]) &&
                                                <Link to = {`/resource_hub/view-resource-detail/${item.id}`} className="bg-gray-300">
                                                  <img src={Boolean(item.default_thumbnail) ? item.default_thumbnail : Boolean(item.account_default_thumbnail) ? item.account_default_thumbnail : '/assets/img_default_files.png'} className="w-full h-full object-cover w-full h-full block cursor-pointer rounded-md p-6"/>
                                                </Link>
                                              }
                                              <div className="absolute top-2 right-2 p-1.5 rounded bg-gray-900 flex items-center justify-center">
                                                {this.chooseIcon(item.file_type)}
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                  <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                                                </svg> */}
                                                  {/* <div className="text-gray-400 text-xs ml-1.5">{this.chooseIconName(item.file_type)}</div> */}
                                              </div>
                                            </div>
                                            <div className="pl-4">
                                              <div className="flex items-center">
                                                  {/* <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-800 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                      <path fillRule="evenodd" d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z" clipRule="evenodd" />
                                                  </svg> */}
                                                  <div className="text-sm text-gray-700 font-bold p-2 pl-0 break-all cursor-pointer">{this.countNameCharectors(removeExtensionFromName(item.name))}</div>
                                              </div>
                                              <div className="flex items-center gap-2 flex-wrap">
                                                {item.location_tags?.map((location, j) => 
                                                  <div className="red-tag text-gray-400 text-xs px-2.5 py-0.5 rounded-xl font-medium" key = {j}>{location.name}</div>
                                                )}
                                                {item.skill_tags?.map((skill, j) => 
                                                  <div className="bg-purple-100 text-purple-800 text-xs px-2.5 py-0.5 rounded-xl font-medium" key = {j}>{skill.name}</div>
                                                )}
                                              </div>
                                              <div className="flex items-center mt-2.5 flex-wrap">
                                                <div className="text-gray-400 text-xs mr-1.5 mb-0.5">
                                                  Updated {item.update_time}
                                                </div>
                                                <div className="flex items-center text-gray-700 text-xs">
                                                  Views : 
                                                  <div className='bg-blue-100 text-blue-900 py-0.5 px-2.5 text-xs rounded-lg ml-2'>{item.views}</div>
                                                </div>
                                              </div>
                                              <div className="flex items-center mt-1.5">
                                                <div>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                                    <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                                  </svg>
                                                </div>
                                                <div className="text-gray-700 text-xs ml-1.5">{item.visitors}</div> 
                                              </div>
                                            </div>
                                          </Link>
                                        )}
                                      </div>
                                      <div className="">
                                        {data2?.map((item, i) =>
                                          <Link to = {`${location.pathname}/view-resource-detail/${item.id}`} className="group flex relative bg-white rounded-md p-2 pb-3 mb-3" key ={i} 
                                          draggable={showUpload}  onDragStart={(e) => this.dragStart(e, item.id)} id={item.id}>
                                            <div className="relative flex items-center jusfity-center rounded-md min-w-32 max-w-32 w-32 h-24 overflow-hidden flex-shrink-0">
                                              {['image', 'video', 'audio'].includes(item.file_type?.split('/')[0]) &&
                                                  <Link to = {`/resource_hub/view-resource-detail/${item.id}`} className="w-full h-full flex items-center justify-center bg-gray-300">	
                                                    <img src={Boolean(item.default_thumbnail) ? item.default_thumbnail : Boolean(item.account_default_thumbnail) ? item.account_default_thumbnail : Boolean(item.thumbnail) ? `${item.thumbnail}` : (item.file_type.split('/')[0] === 'audio' ? "/assets/img_audio.png"  : "/assets/img_default_files.png")} className="ml-auto mr-auto max-h-full object-cover w-full h-full cursor-pointer block rounded-md"/>
                                                  </Link>	
                                              }
                                              {['document'].includes(item.file_type?.split('/')[0]) &&
                                                <Link to = {`/resource_hub/view-resource-detail/${item.id}`} className="flex items-center justify-center w-full h-full bg-gray-300">	
                                                  <img src= {Boolean(item.default_thumbnail) ? item.default_thumbnail : Boolean(item.account_default_thumbnail) ? item.account_default_thumbnail : this.showDocumentImage(item.file_type)} className=" ml-auto mr-auto w-16 object-cover w-full h-full cursor-pointer block rounded-md"/>
                                                </Link>	
                                              }
                                              {!['image', 'document', 'video', 'audio'].includes(item.file_type?.split('/')[0]) &&
                                                <Link to = {`/resource_hub/view-resource-detail/${item.id}`} className="bg-gray-300">
                                                  <img src={Boolean(item.default_thumbnail) ? item.default_thumbnail : Boolean(item.account_default_thumbnail) ? item.account_default_thumbnail : '/assets/img_default_files.png'} className="w-full h-full object-cover w-full h-full block cursor-pointer rounded-md p-6"/>
                                                </Link>
                                              }
                                              <div className="absolute top-2 right-2 p-1.5 rounded bg-gray-900 flex items-center justify-center">
                                                {this.chooseIcon(item.file_type)}
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                  <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                                                </svg> */}
                                                  {/* <div className="text-gray-400 text-xs ml-1.5">{this.chooseIconName(item.file_type)}</div> */}
                                              </div>
                                            </div>
                                            <div className="pl-4">
                                              <div className="flex items-center">
                                                  {/* <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-800 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                      <path fillRule="evenodd" d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z" clipRule="evenodd" />
                                                  </svg> */}
                                                  <div className="text-sm text-gray-700 font-bold p-2 pl-0 break-all cursor-pointer">{this.countNameCharectors(removeExtensionFromName(item.name))}</div>
                                              </div>
                                              <div className="flex items-center gap-2 flex-wrap">
                                                {item.location_tags?.map((location, j) => 
                                                  <div className="red-tag text-gray-400 text-xs px-2.5 py-0.5 rounded-xl font-medium" key = {j}>{location.name}</div>
                                                )}
                                                {item.skill_tags?.map((skill, j) => 
                                                  <div className="bg-purple-100 text-purple-800 text-xs px-2.5 py-0.5 rounded-xl font-medium" key = {j}>{skill.name}</div>
                                                )}
                                              </div>
                                              <div className="flex items-center mt-2.5 flex-wrap">
                                                <div className="text-gray-400 text-xs mr-1.5 mb-0.5">
                                                  Updated {item.update_time}
                                                </div>
                                                <div className="flex items-center text-gray-700 text-xs">
                                                  Views : 
                                                  <div className='bg-blue-100 text-blue-900 py-0.5 px-2.5 text-xs rounded-lg ml-2'>{item.views}</div>
                                                </div>
                                              </div>
                                              <div className="flex items-center mt-1.5">
                                                <div>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                                    <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                                  </svg>
                                                </div>
                                                <div className="text-gray-700 text-xs ml-1.5">{item.visitors}</div> 
                                              </div>
                                            </div>
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                            </React.Fragment>
                          } 
                          {drafts?.length > 0 &&
                            <React.Fragment>
                              {view === 'table' &&
                                <div className="table-view">
                                  <div className="flex flex-col t-table-view">
                                    <div className="mt-4">
                                      <div className="flex items-center">
                                        <div className="text-gray-900 font-normal text-base pl-2">Drafts</div>
                                        <div className="bg-blue-100 text-blue-800 py-0.5 px-2.5 text-xs rounded-lg ml-2">{drafts?.length}</div>
                                      </div>
                                    </div>
                                    <div className="mt-3 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                          <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                              <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                  <div className='flex items-center'>
																										<span className='cursor-pointer' onClick={(e) => this.sortDraft(e, 'name', 'desc')}>Name</span> &nbsp; &nbsp;
																										{draftSortType !== 'name' &&
																											<svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortDraft(e, 'name', 'desc')}>
																												<path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
																											</svg>
																										}
																										{(draftSortType === 'name' && draftSortReverse) &&
																											<svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortDraft(e, 'name', 'asc')}>
																												<path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
																											</svg>
																										}
																										{(draftSortType === 'name' && !draftSortReverse) &&
																											<svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortDraft(e, 'name', 'desc')}>
																												<path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
																											</svg>
																										}
																									</div>
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                  TAGS
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                  LAST UPDATE
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                  VIEWS
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                  VISIBILITY
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {drafts?.map((item, i) =>
                                                <tr className="bg-white" key={i}>
                                                  <td className="px-6 py-4 text-sm font-medium text-gray-900">
                                                    <div className="flex items-center">
                                                      <Link to = {`${location.pathname}/view-resource-detail/${item.id}`} 
                                                        className="text-gray-700 text-sm font-bold cursor-pointer max-w-xs break-words" >
                                                        {removeExtensionFromName(item.name)}
                                                      </Link>
                                                    </div>
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <div className="flex flex-wrap items-center gap-x-2">
                                                      {item.location_tags?.map((location, j) =>
                                                        <div className="red-tag text-xs px-2.5 py-0.5 rounded-xl font-medium mb-2" key={j}>{location.name}</div>
                                                      )}
                                                      {item.skill_tags?.map((skill, j) =>
                                                        <div className="bg-purple-100 text-purple-800 text-xs px-2.5 py-0.5 rounded-xl font-medium mb-2" key={j}>{skill.name}</div>
                                                      )}
                                                    </div>
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <div className="text-gray-400 text-xs">
                                                      Updated {item.update_time}
                                                    </div>
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                                    <div className="text-gray-800 text-xs">
                                                      {item.views}
                                                    </div>
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                                    <div className="text-gray-800 text-xs">
                                                      {item.visitors}
                                                    </div>
                                                  </td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                              {view === 'card' &&
                                <div className="card-view">
                                  <div className="t-files">
                                    <div className="flex items-center mt-6 gap-2">
                                      <div className="text-gray-900 font-normal text-base ml-0.5">Drafts</div>
                                      <div className="bg-blue-100 text-blue-800 py-0.5 px-2.5 text-xs rounded-lg">{drafts?.length}</div>
                                      <div className='flex items-center mt-1'>
                                        {draftSortType !== 'name' &&
                                          <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortDraft(e, 'name', 'desc')}>
                                            <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                          </svg>
                                        }
                                        {(draftSortType === 'name' && draftSortReverse) &&
                                          <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortDraft(e, 'name', 'asc')}>
                                            <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                          </svg>
                                        }
                                        {(draftSortType === 'name' && !draftSortReverse) &&
                                          <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sortDraft(e, 'name', 'desc')}>
                                            <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                          </svg>
                                        }
                                      </div>
                                    </div>
                                    <div className="my-3 grid grid-cols-1 gap-x-4 sm:grid-cols-1 lg:grid-cols-2 xl:gap-x-4">
                                      <div>
                                        {draft1?.map((item, i) =>
                                          <Link to = {`${location.pathname}/view-resource-detail/${item.id}`} className="group flex relative bg-white rounded-md p-2 pb-3 mb-3" key ={i} draggable={false}>
                                            <div className="relative flex items-center jusfity-center rounded-md min-w-32 max-w-32 w-32 h-24 overflow-hidden flex-shrink-0">
                                              {['image', 'video', 'audio'].includes(item.file_type?.split('/')[0]) &&
                                                  <Link to = {`/resource_hub/view-resource-detail/${item.id}`} className="w-full h-full flex items-center justify-center bg-gray-300">	
                                                    <img src={Boolean(item.default_thumbnail) ? item.default_thumbnail : Boolean(item.account_default_thumbnail) ? item.account_default_thumbnail : Boolean(item.thumbnail) ? `${item.thumbnail}` : (item.file_type.split('/')[0] === 'audio' ? "/assets/img_audio.png"  : "/assets/img_default_files.png")} className="ml-auto mr-auto max-h-full object-cover w-full h-full cursor-pointer block rounded-md"/>
                                                  </Link>	
                                              }
                                              {['document'].includes(item.file_type?.split('/')[0]) &&
                                                <Link to = {`/resource_hub/view-resource-detail/${item.id}`} className="flex items-center justify-center w-full h-full bg-gray-300">	
                                                  <img src= {Boolean(item.default_thumbnail) ? item.default_thumbnail : Boolean(item.account_default_thumbnail) ? item.account_default_thumbnail : this.showDocumentImage(item.file_type)} className=" ml-auto mr-auto w-16 object-cover w-full h-full cursor-pointer block rounded-md"/>
                                                </Link>	
                                              }
                                              {!['image', 'document', 'video', 'audio'].includes(item.file_type?.split('/')[0]) &&
                                                <Link to = {`/resource_hub/view-resource-detail/${item.id}`} className="bg-gray-300">
                                                  <img src={Boolean(item.default_thumbnail) ? item.default_thumbnail : Boolean(item.account_default_thumbnail) ? item.account_default_thumbnail : '/assets/img_default_files.png'} className="w-full h-full object-cover w-full h-full block cursor-pointer rounded-md p-6"/>
                                                </Link>
                                              }
                                              <div className="absolute top-2 right-2 p-1.5 rounded bg-gray-900 flex items-center justify-center">
                                                {this.chooseIcon(item.file_type)}
                                                  {/* <div className="text-gray-400 text-xs ml-1.5">{this.chooseIconName(item.file_type)}</div> */}
                                              </div>
                                            </div>
                                            <div className="pl-4">
                                              <div className="flex items-center">
                                                <div className="text-sm text-gray-700 font-bold p-2 pl-0 break-all cursor-pointer">{this.countNameCharectors(removeExtensionFromName(item.name))}</div>
                                              </div>
                                              <div className="flex items-center gap-2 flex-wrap">
                                                {item.location_tags?.map((location, j) => 
                                                  <div className="red-tag text-gray-400 text-xs px-2.5 py-0.5 rounded-xl font-medium" key = {j}>{location.name}</div>
                                                )}
                                                {item.skill_tags?.map((skill, j) => 
                                                  <div className="bg-purple-100 text-purple-800 text-xs px-2.5 py-0.5 rounded-xl font-medium" key = {j}>{skill.name}</div>
                                                )}
                                              </div>
                                              <div className="flex items-center mt-2.5 flex-wrap">
                                                <div className="text-gray-400 text-xs mr-1.5 mb-0.5">
                                                  Updated {item.update_time}
                                                </div>
                                                <div className="flex items-center text-gray-700 text-xs">
                                                  Views : 
                                                  <div className='bg-blue-100 text-blue-900 py-0.5 px-2.5 text-xs rounded-lg ml-2'>{item.views}</div>
                                                </div>
                                              </div>
                                              <div className="flex items-center mt-1.5">
                                                <div>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                                    <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                                  </svg>
                                                </div>
                                                <div className="text-gray-700 text-xs ml-1.5">{item.visitors}</div> 
                                              </div>
                                            </div>
                                          </Link>
                                        )}
                                      </div>
                                      <div>
                                        {draft2?.map((item, i) =>
                                          <Link to = {`${location.pathname}/view-resource-detail/${item.id}`} className="group flex relative bg-white rounded-md p-2 pb-3 mb-3" key ={i} draggable={false}>
                                            <div className="relative flex items-center jusfity-center rounded-md min-w-32 max-w-32 w-32 h-24 overflow-hidden flex-shrink-0">
                                              {['image', 'video', 'audio'].includes(item.file_type?.split('/')[0]) &&
                                                  <Link to = {`/resource_hub/view-resource-detail/${item.id}`} className="w-full h-full flex items-center justify-center bg-gray-300">	
                                                    <img src={Boolean(item.default_thumbnail) ? item.default_thumbnail : Boolean(item.account_default_thumbnail) ? item.account_default_thumbnail : Boolean(item.thumbnail) ? `${item.thumbnail}` : (item.file_type.split('/')[0] === 'audio' ? "/assets/img_audio.png"  : "/assets/img_default_files.png")} className="ml-auto mr-auto max-h-full object-cover w-full h-full cursor-pointer block rounded-md"/>
                                                  </Link>	
                                              }
                                              {['document'].includes(item.file_type?.split('/')[0]) &&
                                                <Link to = {`/resource_hub/view-resource-detail/${item.id}`} className="flex items-center justify-center w-full h-full bg-gray-300">	
                                                  <img src= {Boolean(item.default_thumbnail) ? item.default_thumbnail : Boolean(item.account_default_thumbnail) ? item.account_default_thumbnail : this.showDocumentImage(item.file_type)} className=" ml-auto mr-auto w-16 object-cover w-full h-full cursor-pointer block rounded-md"/>
                                                </Link>	
                                              }
                                              {!['image', 'document', 'video', 'audio'].includes(item.file_type?.split('/')[0]) &&
                                                <Link to = {`/resource_hub/view-resource-detail/${item.id}`} className="bg-gray-300">
                                                  <img src={Boolean(item.default_thumbnail) ? item.default_thumbnail : Boolean(item.account_default_thumbnail) ? item.account_default_thumbnail : '/assets/img_default_files.png'} className="w-full h-full object-cover w-full h-full block cursor-pointer rounded-md p-6"/>
                                                </Link>
                                              }
                                              <div className="absolute top-2 right-2 p-1.5 rounded bg-gray-900 flex items-center justify-center">
                                                {this.chooseIcon(item.file_type)}
                                                  {/* <div className="text-gray-400 text-xs ml-1.5">{this.chooseIconName(item.file_type)}</div> */}
                                              </div>
                                            </div>
                                            <div className="pl-4">
                                              <div className="flex items-center">
                                                <div className="text-sm text-gray-700 font-bold p-2 pl-0 break-all cursor-pointer">{this.countNameCharectors(removeExtensionFromName(item.name))}</div>
                                              </div>
                                              <div className="flex items-center gap-2 flex-wrap">
                                                {item.location_tags?.map((location, j) => 
                                                  <div className="red-tag text-gray-400 text-xs px-2.5 py-0.5 rounded-xl font-medium" key = {j}>{location.name}</div>
                                                )}
                                                {item.skill_tags?.map((skill, j) => 
                                                  <div className="bg-purple-100 text-purple-800 text-xs px-2.5 py-0.5 rounded-xl font-medium" key = {j}>{skill.name}</div>
                                                )}
                                              </div>
                                              <div className="flex items-center mt-2.5 flex-wrap">
                                                <div className="text-gray-400 text-xs mr-1.5 mb-0.5">
                                                  Updated {item.update_time}
                                                </div>
                                                <div className="flex items-center text-gray-700 text-xs">
                                                  Views : 
                                                  <div className='bg-blue-100 text-blue-900 py-0.5 px-2.5 text-xs rounded-lg ml-2'>{item.views}</div>
                                                </div>
                                              </div>
                                              <div className="flex items-center mt-1.5">
                                                <div>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                                    <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                                  </svg>
                                                </div>
                                                <div className="text-gray-700 text-xs ml-1.5">{item.visitors}</div> 
                                              </div>
                                            </div>
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                            </React.Fragment>
                          }
                          {(files_count > 0 && count === 0) &&
                            <div className="text-gray-500 flex items-center justify-center py-16">
                              No Files Found
                            </div>
                          }  
                        </React.Fragment>
                      }
                      {!filterDataLoaded &&
												<div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
													<p className="mb-1">{loadingMessage}</p>
													<img className = "w-9" src="/assets/business/loading.gif" />
												</div>
											}
                    </div>
                  }
                  {total_data_count === 0 &&
                    <div className="grid justify-center items-center h-full t-zero-state">
                      <div className="flex items-center">
                        <div>
                          <img src="/assets/img_default_files.png" className="w-32" />
                        </div>
                        <div className="ml-3">
                          <div className="text-gray-400 font-semibold mb-3">
                            No documents
                          </div>
                          <div className="relative hidden">
                            <input type="file" name="file" className="bg-transparent textIindent absolute cursor-pointer w-40 h8 -top-0.5" required="required" multiple={true} onChange={(e) => this.handleUploadFile(e)}/>
                          </div>
                          <a href="" className="bg-white hidden py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">Add file(s) or folder</a>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </main>
            </div>
            }
        </div>
				{openModal && (
					<ModalPopup openState={openModal} stateHandler={this.popStateHandler.bind(this)} nameHandler={this.folderNameStateHandler.bind(this)} />
				)}        
      </React.Fragment>
    )
  }
}