import React from "react";

export const SendButtonIcon = () => {

  return(
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#3784FB"/>
      <path d="M15.3999 14.32L23.8899 11.49C27.6999 10.22 29.7699 12.3 28.5099 16.11L25.6799 24.6C23.7799 30.31 20.6599 30.31 18.7599 24.6L17.9199 22.08L15.3999 21.24C9.68993 19.34 9.68993 16.23 15.3999 14.32Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.1101 21.6501L21.6901 18.0601" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}