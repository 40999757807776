import React from 'react';

export default function UnReadBadgeIndecator (props) {
  const { channel } = props

  // if(channel?.type === 'team'){
  //   if(channel.countUnreadMentions() === 0){
  //     return null
  //   }
  // }else{
    if(channel.state.unreadCount === 0){
      return null
    }
  // }

  const channelUnreadCount = channel?.type === 'team' ? (channel.countUnreadMentions() > 0 ? channel.countUnreadMentions() : (channel.state.unreadCount > 0 && '')) : channel.state.unreadCount
  return( 
    <div className={`str-chat__channel-preview-unread-badge ${channelUnreadCount === '' ? 'empty-unread-badge' : ''}`} data-testid="unread-badge">
      {channelUnreadCount}
    </div>
  )
}