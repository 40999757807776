import React, { useMemo, useRef } from 'react';
import clsx from 'clsx';
import { TranslationLanguages } from 'stream-chat';
import {
  areMessageUIPropsEqual,
  Avatar,
  EditMessageForm,
  ErrorIcon,
  isOnlyEmojis,
  MESSAGE_ACTIONS,
  MessageActions,
  MessageContextValue,
  MessageDeleted,
  MessageInput,
  MessageRepliesCountButton,
  MessageStatus,
  MessageTimestamp,
  MessageUIComponentProps,
  // QuotedMessage,
  ReactEventHandler,
  ReactionIcon,
  ReactionSelector,
  renderText as defaultRenderText,
  showMessageActionsBox,
  SimpleReactionsList,
  ThreadIcon,
  useComponentContext,
  useMessageContext,
  useReactionClick,
  useTranslationContext,
} from 'stream-chat-react';
import { MessageSimpleWithContext } from './messageTeamWithContext';

const MemoizedMessageTeam = React.memo(MessageSimpleWithContext, areMessageUIPropsEqual)


export default function CustomMessage (props){
  const messageContext = useMessageContext('MessageSimple');
  const reactionSelectorRef = useRef(null)
  const messageWrapperRef = useRef(null)
  const message = props.message || messageContext.message;

  const { isReactionEnabled, onReactionListClick, showDetailedReactions } = useReactionClick(
    message,
    reactionSelectorRef,
    messageWrapperRef,
  );

  const handleOpenThreadOverride = (event) => {
    messageContext.handleOpenThread(event);
  };

  return <MemoizedMessageTeam {...messageContext} {...props} />;
}