import React, { Fragment } from "react";
import { Menu, Transition } from '@headlessui/react';
import MoreOptionDotsIcon from '../assets/moreOptionDotsIcon';
import { Link } from 'react-router-dom';

export default function ChannelMoreActionComponent(props) {
  const { channel, loadingInProgress, handlefetchChannelData, setShowInfoComponent, showEditButton, showDeleteButton, setShowNotificationList, handleShowDeleteComfirmation, processHideChannel, notification, notificationDuration } = props

  return(
    <Fragment>
      <div className="relative filters_box"> 
        <Menu as="div" className="">
          <div>
            <Menu.Button className="text-white items-center rounded-md ml-auto inline-flex p-2 text-sm font-medium text-center text-gray-900 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" onClick={() => handlefetchChannelData()}>
              <MoreOptionDotsIcon classNames={'w-4 h-4'} />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right z-2 absolute right-0 mt-1 w-52 rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {loadingInProgress &&
                  <div className="flex items-center justify-center w-full h-14">
                    <svg className="animate-spin h-3.5 w-3.5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                }
                {!loadingInProgress &&
                  <Fragment>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          onClick = {(e) => {setShowInfoComponent(true)}}
                          className={`${active ? 'bg-gray-100' : ''} text-gray-900 px-4 py-2 text-sm flex items-center cursor-pointer`}
                        >
                          {`View ${channel?.type === 'team' ? 'Group Details' : 'Conversation Details'}`}
                        </a>
                      )}
                    </Menu.Item>
                    {showEditButton &&
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={`/messenger/edit-chat-room/${channel?.id}`}
                            className={`${active ? 'bg-gray-100' : ''} text-gray-900 px-4 py-2 text-sm flex items-center cursor-pointer`}
                          >
                            Edit Settings
                          </Link>
                        )}
                      </Menu.Item>
                    }
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          onClick = {(e) => {setShowNotificationList(true)}}
                          className={`${active ? 'bg-gray-100' : ''} text-gray-900 px-4 py-2 text-sm flex flex-col justify-center cursor-pointer`}
                        >
                          Notifications
                          {notification !== 'do_not_mute' && 
                            <p className="tc-11 text-gray-700 font-light -mt-0.5">{`${notificationDuration}`}</p>
                          }
                        </a>
                      )}
                    </Menu.Item>
                    {(channel?.type === 'team' && showDeleteButton) &&
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick = {(e) => {handleShowDeleteComfirmation(true)}}
                            className={`${active ? 'bg-gray-100' : ''} text-gray-900 px-4 py-2 text-sm flex items-center cursor-pointer`}
                          >
                            Delete
                          </a>
                        )}
                      </Menu.Item>
                    }
                    {channel?.type !== 'team' &&
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick = {(e) => {processHideChannel()}}
                            className={`${active ? 'bg-gray-100' : ''} text-gray-900 px-4 py-2 text-sm flex items-center cursor-pointer`}
                          >
                            Hide
                          </a>
                        )}
                      </Menu.Item>
                    }
                  </Fragment>
                }
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}