import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class ConfigureNavbar extends Component {
  render() {
    const { user, business, permissions, path } = this.props
    return (
      <div className='bg-white h-11 shadow rounded-lg mb-5 inline-flex'>
        {user?.role_display === "Netgym Employee" &&
          <Link to={`/configure/netgym-settings`} className={`sm:text-sm text-xs flex items-center border-b-2 font-medium sm:px-8 px-2 ${path === 'netgym-settings' ? 'text-gray-900 border-gray-900' : 'text-gray-300 border-transparent'}`}>Netgym Settings</Link>
        }
        {(business?.abc_integration && user.role_display === "Netgym Employee") &&
          <Link to={`/configure/abc-details`} className={`sm:text-sm text-xs flex items-center border-b-2 font-medium sm:px-8 px-2 ${path === 'abc-details' ? 'text-gray-900 border-gray-900' : 'text-gray-300 border-transparent'}`}>ABC Details</Link>
        }
        {(business?.marianatek_integration && user.role_display === "Netgym Employee") &&
          <Link to={`/configure/mariana-details`} className={`sm:text-sm text-xs flex items-center border-b-2 font-medium sm:px-8 px-2 ${path === 'mariana-details' ? 'text-gray-900 border-gray-900' : 'text-gray-300 border-transparent'}`}>Mariana Tek Details</Link>
        }
        {(business?.mbo_integration && user.role_display === "Netgym Employee") &&
          <Link to={`/configure/mbo-details`} className={`sm:text-sm text-xs flex items-center border-b-2 font-medium sm:px-8 px-2 ${(path === 'mbo-details' || path === 'permissionsForm') ? 'text-gray-900 border-gray-900' : 'text-gray-300 border-transparent'}`}>MBO Details</Link>
        }
        {(business?.crunch_integration && user.role_display === "Netgym Employee") &&
          <Link to={`/configure/crunch-details`} className={`sm:text-sm text-xs flex items-center border-b-2 font-medium sm:px-8 px-2 ${path === 'crunch-details' ? 'text-gray-900 border-gray-900' : 'text-gray-300 border-transparent'}`}>Crunch Details</Link>
        }
        {(business?.fisikal_integration && user.role_display === "Netgym Employee") &&
          <Link to={`/configure/fisikal-details`} className={`sm:text-sm text-xs flex items-center border-b-2 font-medium sm:px-8 px-2 ${path === 'fisikal-details' ? 'text-gray-900 border-gray-900' : 'text-gray-300 border-transparent'}`}>Fisikal Details</Link>
        }
        {(business?.clubready_integration && user.role_display === "Netgym Employee") &&
          <Link to={`/configure/club-ready-details`} className={`sm:text-sm text-xs flex items-center border-b-2 font-medium sm:px-8 px-2 ${path === 'club-ready-details' ? 'text-gray-900 border-gray-900' : 'text-gray-300 border-transparent'}`}>Club Ready Details</Link>
        }
      </div>
    )
  }
}
