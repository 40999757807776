import React, { Fragment } from "react";

export default function HeadingComponent (props) {
  return(
    <Fragment>
      <div className="px-8 lg:px-0 mb-7">
        <header>
          <h1 className="text-2xl font-bold leading-tight text-black">Sub Request Details</h1>
        </header>
      </div>
    </Fragment>
  )
}