import React, { Fragment, useState, useEffect } from "react";
import CardComponent from './../cardComponent';
import EmptyCardsMessageComponent from '../emptyCardsMessageComponent';
import SentFilterModalComponent from '../filterComponents/sentFilterModalComponent';
import { ButtonSpinnerIcon, FilterIcon, EditIcon } from '../../../../assets';

export default function SentEventsComponent(props) {
  const { sentEvents, user, business, handleAlert, processGetEventData, path, getEventDataWithoutLoad, handleUpdateEvents, handleDeleteEvent, disabledSubBoardPage, sentEventsTotalCount, selectedStatus, managerCount, pendingCount, acceptedCount, approvedCount, reopenCount, saveAndApplyFilters, sentBucketLoader } = props;
  const [ showFilterModal, setShowFilterModal ] = useState(false);
  const allStatusList  = [
    {value: 'pending_mgr', label: 'Pending Manger Board Approval'},
    {value: 'pending', label: 'Open'},
    {value: 'reopen', label: 'Re-opened'},
    {value: 'accepted', label: 'Pending Approval'},
    {value: 'confirm', label: 'Approved'},
  ]
  const withoutMGR = allStatusList.filter((item) => item.value !== 'pending_mgr')
  const statusList = business?.manager_request_gate ? allStatusList : withoutMGR
  const checkStatusValues = (selectedStatus, statusList) => {
    let possibleValues = statusList.map((item) => item.value)
    return selectedStatus.filter((item) => possibleValues.includes(item))
  }
  const [ statusValues, setStatusValues ] = useState(checkStatusValues(selectedStatus, statusList) || selectedStatus)

  const handleCloseModal = (data) => {
    setShowFilterModal(data);
  }

  const processApplyFilter = (data) => {
    saveAndApplyFilters(data, 'sent');
    setShowFilterModal(false)
  }

  useEffect(() => {
    setStatusValues(checkStatusValues(selectedStatus, statusList) || selectedStatus)
  }, [selectedStatus])

  const checkFilterSelected = () => {
    return ((statusValues?.length > 0 && statusList?.length !== statusValues?.length) || showFilterModal)
  }

  return (
    <Fragment>
      <SentFilterModalComponent
        showFilterModal={showFilterModal}
        handleCloseModal={handleCloseModal}
        business={business}
        user={user}
        managerCount={managerCount}
        pendingCount={pendingCount}
        acceptedCount={acceptedCount}
        approvedCount={approvedCount}
        reopenCount={reopenCount}
        statusValues={statusValues}
        processApplyFilter={processApplyFilter}
        statusList={statusList}
      />
      <div className="w-full mb-7 lg:mb-0">
        <div className="bg-white shadow-md flex items-center justify-between rounded-lg px-3">
          <div className="flex text-black text-sm font-medium py-4">
            Sent {!disabledSubBoardPage && sentEvents?.length > 0 ? `(${sentEventsTotalCount})` : ""}
          </div>
          <div className="flex gap-1 relative">
            <div className="hidden w-8 h-8 rounded-full flex items-center justify-center bg-gray-100">
              <EditIcon classNames={"w-4 h-4 text-gray-500"} />
            </div>
            {sentBucketLoader &&
              <div className="flex items-center -left-6 top-2 justify-center absolute">
                <ButtonSpinnerIcon classNames={'h-4 w-4 text-black'}/>
              </div>
            }
            {!disabledSubBoardPage &&
              <button className={`w-8 h-8 rounded-full flex items-center justify-center ${checkFilterSelected() ? 'bg-dark-blue hover:bg-gray-700' : 'bg-gray-100 hover:bg-gray-200'}`} onClick={() => setShowFilterModal(true)}>
                <FilterIcon classNames={`w-5 h-5 ${checkFilterSelected() ? 'text-white' : 'text-gray-500'}`} />
              </button>
            }
          </div>
        </div>
        <div className="mt-3">
          {!disabledSubBoardPage &&
            <Fragment>
              {sentEvents?.length > 0 ?
                <Fragment>
                  {sentEvents?.map((event, i) => (
                    <div key={i}>
                      <CardComponent event={event} listType={"Sent"} user={user} business={business} handleAlert={handleAlert} processGetEventData={getEventDataWithoutLoad} path={path} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent}/>
                    </div>
                  ))}
                </Fragment>
              :
                <EmptyCardsMessageComponent listType={"Sent"}/>
              }
            </Fragment>
          }
        </div>
      </div>
    </Fragment>
  );
}