import React, { Fragment, useEffect, useState } from "react";
import EyeIcons from '../../../signIn/assets/eyeIcons';
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import { isAndroid, isIOS } from "react-device-detect";

export default function ResetPasswordFormComponent(props) {
  const { setAlert, userData, updateUserData } = props;
  const [ newPassword, setNewPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState('');
  const [ isNewPasswordTouched, setIsNewPasswordTouched ] = useState(false);
  const [ isConfirmPasswordTouched, setIsConfirmPasswordTouched ] = useState(false);
  const [ showNewPassword, setShowNewPassword ] = useState(false);
  const [ showConfirmPassword, setShowConfirmPassword ] = useState(false);
  const [ submited, setSubmited ] = useState(false);
  const [ disableSubmit, setDisableSubmit ] = useState(false);

  const handleChange = (e) => {
    let name = e.target.name
    if(name == 'new_password'){
      setNewPassword(e.target.value);
    }else if(name == 'confirm_password'){
      setConfirmPassword(e.target.value);
    }
  };

  const handleBlur = (e) => {
    let name = e.target.name
    if(name == 'new_password'){
      setIsNewPasswordTouched(true);
    }else if(name == 'confirm_password'){
      setIsConfirmPasswordTouched(true);
    }
  };

  const checkConfirmPassword = () => {
    if(newPassword?.split("")?.length >=6 && newPassword !== confirmPassword){
      return true
    } else {
      return false
    }
  }

  const checkDisabledConditions = () => {
    return (disableSubmit || checkConfirmPassword() || (newPassword == '' || (newPassword !== "" && newPassword?.split("")?.length < 6)) || confirmPassword == '')
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmited(true);
    if (newPassword !== '' && confirmPassword !== '') {
      setDisableSubmit(true);
      let params = {
        new_password: newPassword,
        confirm_password: confirmPassword
      }
      let url = '/api/v3/reset_password'
      axiosRequest(url, 'POST', params, 'data').then(result => {
        if(result.status == 200){
          setAlert(true, 'success', result?.success)
          setTimeout(() => {
            setAlert(false, '', '')
            setDisableSubmit(false);
          }, 3000);
          if (isAndroid || isIOS) {
            location.href = result?.page_link
          }
          setTimeout(()=>{
            updateUserData(userData, '/my-requests')
          }, 800)
        }else{
          setAlert(true, 'danger', result?.error)
          setTimeout(() => {
            setAlert(false, '', '')
          }, 3000);
          setDisableSubmit(false);
        }
      })
    }
  }

  return (
    <Fragment>
      <div className="w-full md:w-1/2 rounded-xl bg-white px-5 md:px-8 py-10 flex items-center justify-center shadow-md">
        <div className="flex flex-col items-center gap-4 w-full">
          <h1 className="text-2xl text-center">Please create a new password</h1>
          <div className="flex items-center w-full max-w-lg">
            <div className='w-full sm:mt-0 relative'>
              <input
                type={showNewPassword ? 'text' : 'password'}
                className="w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 px-4 pr-8"
                id="new_password"
                name="new_password"
                placeholder="New Password"
                value={newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <EyeIcons open={showNewPassword} classNames={'w-5 h-5 absolute right-3 top-2 cursor-pointer'} setOpen={setShowNewPassword}/>
              {(submited || isNewPasswordTouched) && newPassword == '' &&
                <span className="text-xs text-red-500 mt-1">Please enter Password.</span>
              }
              {(newPassword !== "" && newPassword?.split("")?.length < 6) && 
                <span className="text-xs text-red-500 mt-1">Password should be minimum 6 characters long.</span>
              }
            </div>
          </div>
          <div className="flex items-center w-full max-w-lg">
            <div className='w-full sm:mt-0 relative'>
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                className="w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 px-4 pr-8"
                id="confirm_password"
                name="confirm_password"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <EyeIcons open={showConfirmPassword} classNames={'w-5 h-5 absolute right-3 top-2 cursor-pointer'} setOpen={setShowConfirmPassword}/>
              {(submited || isConfirmPasswordTouched) && confirmPassword == '' &&
                <span className="block text-xs text-red-500 mt-1">Please enter confirm Password.</span>
              }
              {(submited || isConfirmPasswordTouched) && checkConfirmPassword() && (
                <span className="block text-xs text-red-500 mt-1">Passwords do not match. Please try again.</span>
              )}
            </div>
          </div>
          <div className='flex items-center justify-center w-full mt-4'>
          <button
            className={`bg-gray-900 rounded-xl text-white flex items-center justify-center px-8 py-2 text-sm w-1/2 md:w-1/3 ${(checkDisabledConditions()) ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={(e) => handleSubmit(e)}
            disabled={(checkDisabledConditions())}
          >
            Login
          </button>
        </div>
        </div>
      </div>
    </Fragment>
  )
}