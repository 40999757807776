function getHeaders(token, subdomain){  
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj
  }  
}

export function getSudomain() {
  let subdomain = ''
  let host = window.location.host;
  if (host.split('.').length < 3){
    subdomain = null
  } else {
    subdomain = host.split('.')[0];
  }
  return subdomain
}

export const getInstructors = async (user_token, subdomain, text, skills, locations, page, pageSize) => {    
  return await fetch(`/api/v3/get_instructors?text=${text}&category_ids=${skills}&location_ids=${locations}&page=${page}&pageSize=${pageSize}`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const getLocationsSkills = async (user_token, subdomain) => {    
  return await fetch(`/api/v3/regions_and_locations`,{
    headers: getHeaders(user_token, subdomain)
  });  
}