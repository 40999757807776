import React, { Fragment } from "react";
import { LocationPinIcon } from './../../../../assets'

export default function LocationComponent(props) {
  const { eventData } = props
  return(
    <Fragment>
      <div className="flex items-center mb-1">
        <div>
          <LocationPinIcon classNames={'w-5 h-5 text-neutral-400'}/>
        </div>
        <a href={eventData?.location_map_url} target="_blank" className='custom-blue-200 text-xs font-semibold cursor-pointer ml-2'>{eventData?.location_name}</a>
      </div>
    </Fragment>
  )
}