import React, { Fragment, useState, useEffect } from "react";
import MgrEventButtonsComponent from './../../mgrEventButtonsComponent';
import { ClockIcon, LocationPinIcon, MessageIcon, AngleIcon } from './../../../../assets';
import PendingEventsButtonsComponent from './pendingEventsButtonsComponent';
import ApprovedEevntsButtonsComponent from './approvedEevntsButtonsComponent';
import AccordionComponent from './accordionComponent';
import CommentsComponent from './commentsComponent';
import AssignPopupContentComponent from './../../assignPopupContentComponent';
import OpenEventsButtonsComponent from './openEventsButtonsComponent';
import CardViewCreateCommentComponent from './commentsComponent/cardViewCreateCommentComponent'
import InstructorDetailComponent from './instructorDetailComponent';
import AvailableEventsButtonsComponent from './availableEventsButtonsComponent';
import { eventDetailUrl, savePageViewInLocalStorage } from './../../../../helpers/helpers';
import SubbingUserComponent from './../../eventDetailsComponent/subbingUserComponent';
import RequestingInstructorComponent from './../../eventDetailsComponent/requestingInstructorComponent';
import CancelOptionsPopupComponent from './../../../../../subRequestDetails/components/topButtonsComponent/moreActionComponent/cancelOptionsPopupComponent';
import { Link } from 'react-router-dom';

export default function CardComponent(props) {
  const { event, listType, user, business, handleAlert, processGetEventData, path, handleUpdateEvents, handleDeleteEvent, setLoader = false } = props;
  const [ comments, setComments ] = useState(event?.comments)
  const [ commentsToShow, setCommentsToShow ] = useState(Array(event?.comments[0]))
  const [ showAllComments, setShowAllComents ] = useState(false)
  const [ showAssignPopup, setShowAssignPopup ] = useState(false)
  const [ assignPopupType, setAssignPopupType ] = useState('')
  const [ approvedUserId, setApprovedUserId ] = useState('')
  const [ showCreateCommentSection, setShowCreateCommentSection ] = useState(false)
  const [ showCancelPopup, setShowCancelPopup ] = useState(false)
  const [ showInstructorDetail, setShowInstructorDetail ] = useState(false)
  const [ detailInstructor, setDetailInstructor ] = useState({})
  const cancelOptions = ['rescind_class', 'delete_class', 'delete_without_sync', 'cancel_sub_request', 'crunch_event_delete', 'delete_class_duplicate', 'delete_class_no_longer', 'delete_class_other', 'delete_class_original_instructor']
  const [ optionListKeys, setOptionListKeys ] = useState(event?.more_action_detail?.map(item => item?.key) || [])
  const [ cancelOptionsOnly, setCancelOptionsOnly ] = useState(event?.more_action_detail?.filter(item => cancelOptions.includes(item?.key)) || [])
  const sentBucket = (['my-requests'].includes(path) && listType == 'Sent') ? 'sent' : ''

  useEffect(() => {
    if (showAllComments){
      setCommentsToShow(comments)
    }else{
      setCommentsToShow(comments.slice(0, 1))
    }
  }, [showAllComments])

  useEffect(() => {
    if(event){
      setComments(event?.comments)
      if (showAllComments){
        setCommentsToShow(event?.comments)
      }else{
        setCommentsToShow(Array(event?.comments[0]))
      }
    }
  }, [event])

  useEffect(() => {
    if(event){
      setOptionListKeys(event?.more_action_detail?.map(item => item?.key) || [])
      setCancelOptionsOnly(event?.more_action_detail?.filter(item => cancelOptions.includes(item?.key)) || [])
    }
  }, [event])

  const handleCloseAndReloadData = () => {
    handleCloseAssignPopup(false)
    processGetEventData()
  }

  const handleAddComment = () => {
    setShowCreateCommentSection(!showCreateCommentSection)
  }

  const handleShowAssignPopup = (type) => {
    setAssignPopupType(type)
    if(type == 'replace'){
      setApprovedUserId(event?.approved_user?.id)
    }
    setShowAssignPopup(true)
  }

  const handleCloseAssignPopup = (data) => {
    setShowAssignPopup(data)
    setShowAssignPopup('')
    setApprovedUserId('')
  }

  const handleShowComments = (data) => {
    setShowAllComents(data)
  }

  const handleShowCancelPopup = () => {
    setShowCancelPopup(true)
  }

  const closeCancelPopup = (data) => {
    setShowCancelPopup(data)
  }

  const handleShowInstructorDetails = (data) => {
    setShowInstructorDetail(true)
    setDetailInstructor(data)
  }

  const handleCloseInstructorDetails = () => {
    setShowInstructorDetail(false)
    setDetailInstructor({})
  }

  const handleUpdateTheEvents = (data) => {
    if(data == 'deleted'){
      handleDeleteEvent(event)
    }else{
      handleUpdateEvents(data)
    }
  }

  const handleLinkClick = () => {
    savePageViewInLocalStorage('card', 'my-requests')
  }

  return (
    <Fragment>
      {showAssignPopup &&
        <AssignPopupContentComponent show={showAssignPopup} eventId={event?.id} close={handleCloseAssignPopup} assignPopupType={assignPopupType} handleSetAlert={handleAlert} approvedUserId={approvedUserId} processFetchEventData={handleUpdateEvents} type={'preview'} calendarEvent={false} handleUpdateCalendarEvent={() => {}}/>
      }
      {showCancelPopup &&
        <CancelOptionsPopupComponent eventData={event} showCancelOptionsPopup={showCancelPopup} handleSetAlert={handleAlert} closeShowCancelOptionsPopup={closeCancelPopup} handleUpdateEvent={handleUpdateTheEvents} optionListKeys={optionListKeys} cancelOptionKeys={cancelOptions} backPath={''} type={'preview'} sentBucket={sentBucket} calendarEvent={false} handleUpdateCalendarEvent={() => {}}/>
      }
      {showInstructorDetail &&
        <InstructorDetailComponent showInstructorDetail={showInstructorDetail} detailInstructor={detailInstructor} close={handleCloseInstructorDetails} handleAlert={handleAlert}/>
      }
      <div
        className={`bg-white shadow-sm items-center justify-between rounded-r-lg px-3 pl-4 py-3 mb-2 relative`}
      >
        <div className={`absolute w-1 h-full left-0 top-0 bg-gray-800 rounded-full
          ${
            ['all-requests']?.includes(path) 
            ? (listType === "Open"
              ? "bg-open-event"
              : listType === "Pending"
              ? "bg-pending-event"
              : listType === "Approved"
              ? "bg-green-custom"
              : listType === "No Show"
              ? "bg-no-show-event"
              : listType === "Cancelled"
              ? "bg-cancelled-event"
              : listType === "MGR"
              ? "bg-mgr-event"
              : "") 
            : 
              (['Open', 'Response Needed', 'Re-Opened'].includes(event?.status_content)
              ? "bg-open-event"
              : event?.status_content.toLowerCase().includes('pending')
              ? (event?.status == 'Open' ? 
                  ((['Pending Manager Approval'].includes(event?.status_content) && event?.manager_gate_event) ?
                    "bg-mgr-event"
                  :
                    "bg-open-event" 
                  )
                : (event?.status == 'Approved' && event?.mrg_reopened) ?  "bg-green-custom" : "bg-pending-event"
              )
              : event?.status_content.toLowerCase().includes('approved')
              ? "bg-green-custom"
              : ['Declined', 'Cancelled'].includes(event?.status_content)
              ? "bg-cancelled-event"
              : "")
          }`}></div>
        <div className="flex w-full">
          <div className="w-6 h-full flex flex-col items-center mt-1">
            <div className="text-xs font-medium">{event?.month_name}</div>
            <div className="text-base font-semibold">{event?.day}</div>
            <div className="text-xs font-medium">{event?.week_day}</div>
          </div>
          <div className="w-full ml-3">
            <div className="relative">
              <div>
                <Link to={eventDetailUrl(event, path)} className=' text-base font-semibold cursor-pointer absolute right-0 inset-y-1/2' onClick={() => handleLinkClick()}>
                  <AngleIcon classNames={'-rotate-90 w-7 transform'}/>
                </Link>  
              </div>
              <div className="flex justify-between mb-2">
                <Link to={eventDetailUrl(event, path)} className="font-base font-semibold text-gray-900" onClick={() => handleLinkClick()}>
                  {event?.class_name}
                </Link>
                <div
                  className={`${
                    ['all-requests']?.includes(path) 
                      ?
                        (listType === "Open"
                        ? "text-yellow-500"
                        : listType === "Pending"
                        ? "pending-text-color"
                        : listType === "Approved"
                        ? "approved-text-color"
                        : listType === "No Show"
                        ? "no-show-text-color"
                        : listType === "Cancelled"
                        ? "cancelled-text-color"
                        : listType === "MGR"
                        ? "mgr-text-color"
                        : "")
                      :
                        (['Open', 'Response Needed', 'Re-Opened'].includes(event?.status_content)
                        ? "text-yellow-500"
                        : event?.status_content.toLowerCase().includes('pending')
                        ? (event?.status == 'Open' ? 
                            ((['Pending Manager Approval'].includes(event?.status_content) && event?.manager_gate_event) ?
                              "mgr-text-color"
                            :
                              "text-yellow-500" 
                            )
                          : 
                            (event?.status == 'Approved' && event?.mrg_reopened) ?  
                              "approved-text-color" 
                            : 
                              "pending-text-color"
                          )
                        : event?.status_content.toLowerCase().includes('approved')
                        ? "approved-text-color"
                        : ['Declined', 'Cancelled'].includes(event?.status_content)
                        ? "declined-text-color"
                        : "")
                  } text-sm font-medium`}
                >
                  {['all-requests']?.includes(path) ? (listType === "Cancelled" ? listType : event?.status_content) : event?.status_content}
                </div>
              </div>
              <RequestingInstructorComponent eventData={event} handleShowInstructorDetails={handleShowInstructorDetails} type={'card'} user={user} bucketType={listType} path={path}/>
              {event?.status_content.toLowerCase().includes('approved') &&
                Object.keys(event?.approved_user).length > 0 && (
                  <SubbingUserComponent eventData={event} handleShowInstructorDetails={handleShowInstructorDetails} type={'card'} path={path} bucketType={listType}/>
                )}
              <div className="flex items-center mb-1">
                <ClockIcon classNames={"w-5 h-5 text-neutral-400"} />
                <div className="text-xs font-semibold ml-2">{event?.event_time} {user?.show_tz ? `${event?.tz_display}` : ''} {event?.duration_display !== '' ? `(${event?.duration_display})` : ''}</div>
              </div>
              <div className="flex items-center mb-1">
                <LocationPinIcon classNames={"w-5 h-5 text-neutral-400"} />
                <a href={event?.location_map_url} target="_blank" className="custom-blue-200 text-xs ml-2 cursor-pointer font-semibold">
                  {event?.location_name}
                </a>
              </div>
            </div>
            {['Sent', 'Open'].includes(listType) && !event?.status_content.toLowerCase().includes('approved') &&
              <OpenEventsButtonsComponent event={event} business={business} handleShowAssignPopup={() => handleShowAssignPopup('assign')} handleAddComment={() => handleAddComment()} user={user} handleShowCancelPopup={() => handleShowCancelPopup()} handleAlert={handleAlert} closeAndReloadData={handleUpdateEvents} path={path} cancelOptionsOnly={cancelOptionsOnly}/>
            }
            {listType === "MGR" &&
              <div className='flex gap-1.5 mt-3'>
                {event?.show_manger_request_buttons &&
                  <Fragment>
                    <MgrEventButtonsComponent user={user} eventId={event?.id} handleSetAlert={handleAlert} handleCloseAndReloadData={handleUpdateEvents} type={'list'} calendarEvent={false} handleUpdateCalendarEvent={() => {}}/>
                  </Fragment>
                }
                {business?.comments_feature &&
                  <div className='flex justify-center items-center border border-gray-400 rounded-full min-h-8 min-w-8 cursor-pointer' onClick={() => handleAddComment()}>
                    <MessageIcon classNames={'w-3.5 h-3.5'}/>
                  </div>
                }
              </div>
            }
            {(listType === "Pending" && event?.status !== 'Approved') &&
              <Fragment>
                <PendingEventsButtonsComponent event={event} business={business} handleShowAssignPopup={() => handleShowAssignPopup('assign')} handleAddComment={() => handleAddComment()} user={user} handleShowCancelPopup={() => handleShowCancelPopup()}/>
              </Fragment>
            }
            {(listType === "Available" && !(event?.status_content.toLowerCase().includes('approved') && event?.status == 'Approved')) &&
              <AvailableEventsButtonsComponent event={event} business={business} handleAddComment={() => handleAddComment()} handleAlert={handleAlert} closeAndReloadData={handleUpdateEvents} setLoader={setLoader}/>
            }
            {(event?.status_content.toLowerCase().includes('approved') || (event?.status_content.toLowerCase().includes('pending') && event?.status == 'Approved' && event?.mrg_reopened)) &&
              <Fragment>
                <ApprovedEevntsButtonsComponent event={event} business={business} user={user} handleShowAssignPopup={() => handleShowAssignPopup('replace')} handleAddComment={() => handleAddComment()} handleShowCancelPopup={() => handleShowCancelPopup()} handleAlert={handleAlert} closeAndReloadData={handleUpdateEvents} listType={listType} path={path} sentBucket={sentBucket}/>
              </Fragment>
            }
            {((['Pending Approval', 'Pending Your Approval', 'Pending Manager Approval', 'Open'].includes(event?.status_content) || event?.status_content.toLowerCase().includes('pending')) && (!['Available', 'Approved'].includes(listType) && !(listType == 'Sent' && event?.status_content == 'Open')) && !(listType == 'Sent' && event?.manager_gate_event && !event?.mrg_reopened)) &&
              <Fragment>
                <AccordionComponent event={event} user={user} handleAlert={handleAlert} handleCloseAndReloadData={processGetEventData} handleUpdateEvents={handleUpdateEvents} listType={listType}/>
              </Fragment>
            }
            {comments?.length > 0 && 
              <Fragment>
                <CommentsComponent 
                  comments={comments} 
                  commentsToShow={commentsToShow} 
                  showAllComments={showAllComments} 
                  handleShowComments={handleShowComments}
                  event={event}
                  handleUpdateEvent={handleUpdateEvents}
                  handleAlert={handleAlert}
                />
              </Fragment>
            }
            {showCreateCommentSection &&
              <div className={`${comments?.length == 0 ? 'border-t border-gray-300 mt-2' : ''} pt-2`}>
                <CardViewCreateCommentComponent eventId={event?.id} setShowCreateCommentSection={setShowCreateCommentSection} user={user} handleSetAlert={handleAlert} handleUpdateEvents={handleUpdateEvents}/>
              </div>
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
}
