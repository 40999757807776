import React from 'react';
import { ChannelPreviewInfoParams, useChannelPreviewInfo, StreamMessage, useChannelStateContext, useTranslationContext } from 'stream-chat-react';
import XCircleIcon from '../assets/xCircleIcon';

export default function CustomThreadHeader(props){
  const { closeThread, thread, overrideImage, overrideTitle, } = props
  const { t } = useTranslationContext('ThreadHeader');
  const { channel } = useChannelStateContext('');
  const { displayTitle } = useChannelPreviewInfo({ channel, overrideImage, overrideTitle });

  return(
    <div className='str-chat__thread-header'>
      <div className='str-chat__thread-header-details'>
        <div className='str-chat__thread-header-title'>{t('Thread')}</div>
        <div className='str-chat__thread-header-subtitle'>{displayTitle}</div>
      </div>
      <button
        aria-label='Close thread'
        // className='str-chat__square-button str-chat__close-thread-button'
        data-testid='close-button'
        onClick={(event) => closeThread(event)}
      >
        <XCircleIcon classNames={"h-8 w-8 text-black"} />
      </button>
    </div>
  )
}