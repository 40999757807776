import React, { Component } from 'react'
import MessengerSidebar from './pages/messengerSidebar';
import AllPage from './pages/allPage';
import { getMessages, getMessageTypeIcone, searchPost } from './pages/helpers'
import Cookies from 'js-cookie';
import { getSudomain, setTitle } from '../helpers'
import { isAndroid, isIOS } from "react-device-detect";
import { StreamChat } from 'stream-chat'
import { Chat, Channel } from 'stream-chat-react'
import ChannelBody from './pages/chatComponents/channelBody'
import CustomChannel from './pages/chatComponents/customChannel'
import CreatePostChatModal from './pages/createPostChat/createPostChatTypeModal'
import { ChannelListEmptyScreen } from './pages/chatComponents/channelListEmptyState'

export default class Messenger extends Component {
  constructor(props){
    super(props);
    this.state = {
      alert_message: '',
      alert_type: '',
      show: false,
      messages: [],
      all_message: [],
      total: 0,
      all_total: 0,
      sideBarCounts: {
        archive_unread_count: 0,
        permanent_class_opening_count: 0,
        unread_announcement_count: 0,
        unread_compliance_count: 0,
        unread_count: 0,
        unread_event_count: 0,
        unread_permanent_class_opening_count: 0,
        messages: [],
        total: 0,
      },
      loaded: false,
      sorting: 'latest',
      filters: [],
      sendersFilter: [],
      chatClient: {},
      channel: {},
      groupFilter: {},
      groupChannelsList: [],
      dmFilter: {},
      dmChannelsList: [],
      channelSort: { last_message_at: -1 },
      channelOptions: { limit: 10 },
      allChannelsList: [],
      totalForUser: 0,
      showCreatePopup: false,
      showEmptyChannelState: false,
      pagePath: 'messenger',
      pageDataLoaded: true,
      firtTime: true
    }
  }

  componentDidMount() {
    setTitle('messenger')
    this.props.setCurrent('Messages')
    if(Object.keys(this.props?.user).length !== 0){
      if (isAndroid || isIOS) {
        const url = this.props?.user.messenger_index_url
        // 'intent:#Intent;scheme=netgym;package=com.netgym.netgym;end';
        window.location.replace(url);
      } 
    }
    // else if (isIOS) {
    //   window.location.replace("netgym://");

    //   setTimeout(() => {
    //     window.location.replace(
    //       // "itms-appss://apps.apple.com/app/netgym/id1001008465"
    //       "https://netgym.app.link/NYl9wlgvo7"
    //       // "https://apps.apple.com/us/app/instagram/id389801252"
    //     );
    //   }, 10000);
    // } 
    // else {
    //   // window.location.replace("https://instagram.com");
      
    // }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.business?.mailbox_feature){
      if(!nextProps.business?.new_messenger){
        location.href = `${location.origin}/business_user#/messenger`
      }
    }
    if(nextProps.pageLoaded){
      if(this.state.firtTime){
        if(nextProps.business?.group_chat_feature){
          this.initChat()
        }
        if(this.props.path === 'messenger' || this.props.path === 'inbox'){
          this.getInboxMesseges(false, '', '', '', '', '', [])
        }
        else if(this.props.path === 'archive'){
          this.getInboxMesseges(true, '', '', '', '', '', [])
        }
        else{
          this.getInboxMesseges(false, '', this.props.path, '',  '', '', [])
        }
        this.setState({pagePath: this.props.path, firtTime: false})
      }
    }
  }

  initChat = async () => {
    const { business, user } = this.props
    
    if(user && user.stream_token !== ""){
      const { channelFilters, channelSort, channelOptions } = this.state
      const client = StreamChat.getInstance(business?.stream_app_id)
      if (client.user) {
        if(client.user.id !== user?.id?.toString()){
          await client.disconnectUser();
          await client.connectUser(
            {
              id: user?.id?.toString(),
              name: user?.full_name,
            }, 
            user?.stream_token
          )
        }
      }else{
        await client.connectUser(
          {
            id: user?.id?.toString(),
            name: user?.full_name,
          }, user?.stream_token)
      }
      const groupFilter = business?.multi_tenant_enabled ? { type: 'team', members: { $in: [client.user.id] }, disabled: { $eq: false }, team: { $eq: business?.subdomain } } : { type: 'team', members: { $in: [client.user.id] }, disabled: { $eq: false } }
      const dmFilter = business?.multi_tenant_enabled ? { type: 'messaging', members: { $in: [client.user.id] }, disabled: { $eq: false }, team: { $eq: business?.subdomain } } : { type: 'messaging', members: { $in: [client.user.id] }, disabled: { $eq: false } }
      const filter = business?.multi_tenant_enabled ? { members: { $in: [client.user.id] }, disabled: { $eq: false }, team: { $eq: business?.subdomain } } : { members: { $in: [client.user.id] }, disabled: { $eq: false } }
      const groupChannels = await client.queryChannels(groupFilter);
      const dmChannels = await client.queryChannels(dmFilter);
      const channels = await client.queryChannels(filter);
  
      let channel = {}
      if(location?.pathname.includes(`/messenger/chat-room`)){
        if(groupChannels?.length !== 0 || dmChannels?.length !== 0){
          let pathName = location?.pathname
          let groupId = pathName.split('/').pop()
          const chnls = await client.queryChannels(business?.multi_tenant_enabled ? { members: { $in: [client.user.id] }, disabled: { $eq: false }, id: { $eq: groupId }, team: { $eq: business?.subdomain } } : { members: { $in: [client.user.id] }, disabled: { $eq: false }, id: { $eq: groupId } });
          channel = chnls?.length > 0 ? chnls[0] : {}
          // channel = client.channel(groupChannels[0]?.type, groupId, {})
          // if(Object.keys(channel?.state.members)?.length == 0){
          //   channel = {}
          // }else if(!Object.keys(channel?.state.members).includes(client.user.id)){
          //   channel = {}
          // }

          // if(Object.keys(channel).length === 0){
          //   channel = client.channel(dmChannels[0]?.type, groupId, {})
          //   if(Object.keys(channel?.state.members)?.length == 0){
          //     channel = {}
          //   }else if(!Object.keys(channel?.state.members).includes(client.user.id)){
          //     channel = {}
          //   }
          // }
        }
      }
      if(channels && channels?.length > 0 && Object.keys(channel).length === 0){          
          channel = client.channel(channels[0]?.type, channels[0]?.id, {})
          await channel.watch();
          channel.addMembers([user?.id?.toString()])
        }

      this.setState({
        groupFilter: groupFilter,
        dmFilter: dmFilter,
        chatClient: client,
        groupChannelsList: groupChannels,
        dmChannelsList: dmChannels,
        allChannelsList: channels,
        channel: channel,
      })
      if(location?.pathname.includes(`/messenger/chat-room`) && channel && Object.keys(channel).includes('id') && channel?.id){
        let locPath = location?.pathname
        let locId = locPath.split('/')[locPath.split('/')?.length -1]
        if(locId !== channel?.id){
          window.history.replaceState(`path:messenger/chat-room/${channel?.id}`, '', `/messenger/chat-room/${channel?.id}`)
        }
      }
    }
   
    
  }

  getChannels = async (serverClient, fltr) => {
    let offset = 0;
    let lastFetch = 30;

    let channels = [];
    // const filter = { type: 'team' }; 
    const filter = fltr;
    const sort = [{ last_message_at: -1 }]; 
    const options = {limit: 30, offset: 0}
    while (lastFetch === 30) {
      await serverClient.queryChannels(filter, sort, options).then(r => {
        options.offset = options.offset+30;
        lastFetch = r.length;
        channels = ([...channels, ...r])
      }); 
    }
    return channels;
  }

  getInboxMesseges = (archive, short_by, message_type, search, sortName, sortFalg, user_ids) => {
    getMessages(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), archive, short_by, message_type, search, sortName, sortFalg, user_ids, 1).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
        // this.props.getSideBarCounts(result)
				this.setState({
          messages: result?.messages,
          total: result?.total_count,
          totalForUser: result?.from_count,
          all_message: result?.messages,
          sideBarCounts: result,
          sorting: result.sorting,
          filters: result?.filters,
          sendersFilter: result?.senders,
          loaded: true,
          pageDataLoaded: true
				})
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
					loaded: true,
          pageDataLoaded: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
    // this.state.chatClient.disconnectUser();
  }

  serachData = (val) => {
    let searchResult = searchPost(val, this.state.all_message) 
    this.setState({
      messages: searchResult,
      // total: searchResult?.length
    })
  }

  setChannel = (channel) => {
    this.setState({channel: channel})
    window.history.pushState(`path:messenger/chat-room/${channel?.id}`, '', `/messenger/chat-room/${channel?.id}`)
  }

  setShowCreatePopup = (value) => {
    this.setState({showCreatePopup: value})
  }

  setShowEmptyChannelState = (value) => {
    this.setState({showEmptyChannelState: value})
  }

  setPagePath = (data) => {
    if(location?.pathname.includes(`/messenger/chat-room`)){
      this.setState({loaded: false})
    }
    this.setState({pagePath: data, pageDataLoaded: false})
    if (data == 'messenger'){
      window.history.pushState(`path:messenger`, '', `/messenger`)
      this.getInboxMesseges(false, '', '', '', '', '', [])
    }else if(data === 'archive'){
      window.history.pushState(`path:messenger/archive`, '', `/messenger/archive`)
      this.getInboxMesseges(true, '', '', '', '', '', [])
    }else{
      window.history.pushState(`path:messenger/${data}`, '', `/messenger/${data}`)
      this.getInboxMesseges(false, '', data, '',  '', '', [])
    }
  }

  render() {
    const { alert_message, alert_type, show, loaded, sideBarCounts, messages, total, all_message, all_total, sorting, filters, sendersFilter, chatClient, allChannelsList, channel, groupFilter, dmFilter, groupChannelsList, dmChannelsList, totalForUser, showCreatePopup, showEmptyChannelState, pagePath, pageDataLoaded } = this.state
    const { user, permissions, business, loadingMessage, setCurrent, path } = this.props
    
    return (
      <React.Fragment>
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
        {!loaded && 
					<div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
						<p className="mb-1 text-center px-5">{loadingMessage}</p>
						<img className = "w-9" src="/assets/business/loading.gif" />
					</div>
				}
        {loaded && 
        <div className='w-full'>
          {business?.group_chat_feature && user?.stream_token !== "" ? 
            (Object.keys(chatClient)?.length !== 0  &&
              <Chat client={chatClient}>
                <div className={`flex w-full pt-10 md:pt-0 -mb-22px h-full ${pagePath === 'chat-room' ? '' : 'min-h-screen'}`}>
                  <MessengerSidebar user={user} permissions={permissions} business={business} sideBardAccordion = {pagePath} sideBarCounts={sideBarCounts} chatClient={chatClient} groupChannelsList={groupChannelsList} dmChannelsList={dmChannelsList} channel={channel} groupFilter={groupFilter} dmFilter={dmFilter} setChannel={this.setChannel} allChannelsList={allChannelsList} setShowCreatePopup={this.setShowCreatePopup} setShowEmptyChannelState={this.setShowEmptyChannelState} setPagePath={this.setPagePath}/>
                  {['messenger', 'inbox', 'compliance', 'announcement', 'event', 'archive', 'permanent_class_opening'].includes(pagePath) &&
                    (!showEmptyChannelState ?
                      <AllPage user={user} permissions={permissions} business={business} loaded = {loaded} loadingMessage = {loadingMessage} messages={messages} total={total} getInboxMesseges={this.getInboxMesseges} path={pagePath} all_message={all_message} serachData={this.serachData} all_total={all_total} sorting={sorting} filters = {filters} sideBarCounts={sideBarCounts} sendersFilter={sendersFilter} pageDataLoaded={pageDataLoaded}/>
                    :
                      <ChannelListEmptyScreen />
                    )
                  }
                  {pagePath === 'chat-room' &&
                    <div className="w-full lg:px-8 px-5 py-5 h-full">
                      {(allChannelsList?.length > 0) ?
                        <CustomChannel chatClient={chatClient} selChannel={channel} user={user} permissions={permissions}/>
                      :
                        <div>Loading ...</div>
                      }
                    </div>
                  }
                </div>
              </Chat>
            )
          
            :
            <div className='flex w-full pt-10 md:pt-0 -mb-22px min-h-screen'>
              <MessengerSidebar user={user} permissions={permissions} business={business} sideBardAccordion = {pagePath} sideBarCounts={sideBarCounts} chatClient={chatClient}groupChannelsList={groupChannelsList} dmChannelsList={dmChannelsList} channel={channel} groupFilter={groupFilter} dmFilter={dmFilter} setChannel={this.setChannel} allChannelsList={allChannelsList} setShowCreatePopup={this.setShowCreatePopup} setShowEmptyChannelState={this.setShowEmptyChannelState} setPagePath={this.setPagePath}/>
              {['messenger', 'inbox', 'compliance', 'announcement', 'event', 'archive', 'permanent_class_opening'].includes(pagePath) &&
                <AllPage user={user} permissions={permissions} business={business} loaded = {loaded} loadingMessage = {loadingMessage} messages={messages} total={total} getInboxMesseges={this.getInboxMesseges} path={pagePath} all_message={all_message} serachData={this.serachData} all_total={all_total} sorting={sorting} filters = {filters} sideBarCounts={sideBarCounts} sendersFilter={sendersFilter} pageDataLoaded={pageDataLoaded}/>
              }
            </div>
          }
        </div>
        }
        {showCreatePopup && <CreatePostChatModal showCreatePostChatModal={showCreatePopup} closeCreatePostChatModal={this.setShowCreatePopup} user={user} permissions={permissions} business={business}/>}
      </React.Fragment>
    )
  }
}
