import React from 'react';

export function statusListByPageType(type, business) {
  if (type === 'sent') {
    let list = [
      {value: 'pending_mgr', label: 'Pending Manger Board Approval'},
      {value: 'pending', label: 'Open'},
      {value: 'reopen', label: 'Re-opened'},
      {value: 'accepted', label: 'Pending Approval'},
      {value: 'confirm', label: 'Approved'},
    ]
    if(!business?.manager_request_gate) list = list.filter((item) => item.value !== 'pending_mgr')
    return list
  }
  if (type === 'available') {
    return [
      {value: 'pending', label: 'No response'},
      {value: 'accept', label: 'Accepted'},
      {value: 'decline', label: 'Declined'}
    ]
  }
  return []
}

export const getSentBucketDefaultSelection = (mrgType) => {
  return(mrgType ? ['pending_mgr', 'pending', 'reopen', 'accepted', 'confirm'] : ['pending', 'reopen', 'accepted', 'confirm'])
}

export const getAvaialbleBucketDefaultSelection = (Filter) => {
  return(Filter?.length > 0 ? Filter : ['pending', 'accept', 'decline'])
}

export const getAffectedVaraibles = (allEvents, newEvent) => {
  let reduceVar = ''
  let increaseVar = ''
  if (!newEvent || !allEvents) return [reduceVar, increaseVar];
  let oldEvent = allEvents?.find(event => event.id === newEvent.id);
  if (!oldEvent) return [reduceVar, increaseVar];
  let oldEventState = oldEvent?.user_status;
  let newEventState = newEvent?.user_status;
  const statusCounts = {
    Accepted: 'acceptCount',
    Declined: 'declineCount',
    Pending: 'pendingCount'
  };
  reduceVar = statusCounts[oldEventState] || '';
  increaseVar = statusCounts[newEventState] || '';
  return [reduceVar, increaseVar];
}

export const getSentAffectedVaraibles = (allEvents, newEvent) => {
  if (!newEvent || !allEvents) return ['', ''];
  const existingEvent = allEvents?.find(event => event.id === newEvent.id);
  if (!existingEvent) return ['', ''];
  const reduceVar = getVariableNameByStatus(existingEvent) || '';
  const increaseVar = getVariableNameByStatus(newEvent) || '';
  return [reduceVar, increaseVar];
}

export const getVariableNameByStatus = ({ status, status_content: statusContent }) => {
  if (status === 'Accepted' && ['Pending Your Approval', 'Pending Manager Approval'].includes(statusContent)) {
    return 'acceptedCount';
  } else if (status === 'Open' && ['Re-Opened', 'Open'].includes(statusContent)) {
    return statusContent === 'Re-Opened' ? 'reopenCount' : 'pendingCount';
  } else if (status === 'Approved' && ['Response Needed (Re-opened)', 'Approved'].includes(statusContent)) {
    return 'approvedCount'
  }
  return ''
}